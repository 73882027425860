type Id = number;

interface State {
  data: any[];
  activeItemId: number;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      data: [],
      activeItemId: null,
    };
  }

  constructor(state) {
    this.state = state;
  }

  public changeActiveItemId(id: Id) {
    this.state = {
      ...this.state,
      activeItemId: id,
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
