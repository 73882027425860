import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'redux';

import { fetchPackages } from './fetchPackages';
import { selectPackageThunk } from './selectPackage.thunk';

import { httpFetchPackages } from '../fetchPackages.http';

export class FetchPackagesThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: string }) {
    try {
      const result = await httpFetchPackages({});
      this.dispatchFetchSucceed(result);

      if (isEmpty(result.packages)) {
        return;
      }

      let idFromUrlParams = null;
      if (params.id) {
        idFromUrlParams = Number(params.id);
      }

      const sourceId = result.packages[0].id;
      selectPackageThunk(this.dispatch, { idFromUrlParams, sourceId });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      packages: result.packages,
    };

    this.dispatch(fetchPackages.createAction(actionData));
  }

}

export function fetchPackagesThunk(dispatch, params) {
  const thunk = new FetchPackagesThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchPackagesThunk;
