import { connect } from 'react-redux';

import { selectPackageThunk } from './redux/selectPackage.thunk';

import { Item } from './item';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectPackage() {
    selectPackageThunk(dispatch, { sourceId: ownProps.id });
  },
});

export const ItemConnect = connect(
  null,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
