import React, { useState } from 'react';

import { CommentaryConnect } from '@layouts/bookmarks/components/commentary.connect';

import { ItemSettings } from '@layouts/bookmarks/components/itemSettings';

import { Bookmark } from '../redux/state';

import s from './item.style';

export const Item = (props: Bookmark) => {

  const [hovered, setHovered] = useState(false);
  const [menuIsVisible, setMenuVisible] = useState(false);

  const href = `#/${props.path}`;

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleSetMenuVisible = (visible) => {
    setMenuVisible(visible);
  };

  const renderMenu = () => {
    const shouldRender = menuIsVisible || hovered;

    if (!shouldRender) {
      return null;
    }

    return (
      <s.Menu>
        <ItemSettings
          paragraphId={props.id}
          onMenuVisible={handleSetMenuVisible}
        />
      </s.Menu>
    );
  };

  return (
    <s.Root
      hovered={hovered}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <s.Item>
        <s.Title
          target="_blank"
          href={href}
        >
          {props.title}
        </s.Title>
        <s.Text>{props.text}</s.Text>
        <CommentaryConnect paragraphId={props.id} commentary={props.commentary} />
      </s.Item>
      {renderMenu()}
    </s.Root>
  );
};

export default Item;
