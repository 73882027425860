import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { DownloadConnect } from './download.connect';

import s from './layoutSettings.style';

interface ViewType {
  id: string;
  title: string;
}

interface Props {
  onLevelChange: (params: { wide: boolean; level: number }) => void;
  toggleLayoutType(): void;
  closePopover: () => void;
  viewType: ViewType;
}

enum Level {
  main,
  download,
}

export class LayoutSettings extends React.PureComponent<Props> {

  state = {
    level: Level.main,
  };

  setLevel(level: number) {
    this.setState({
      level,
    });
  }

  handleSetLevelToMain = () => {
    this.setLevel(Level.main);
    this.props.onLevelChange({ wide: false, level: Level.main });
  };

  handleSetLevelToDownload = () => {
    this.setLevel(Level.download);
    this.props.onLevelChange({ wide: false, level: Level.download });
  };

  renderArrow = () => {
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
    const points = iconsPath.get('arrowRight');

    return (
      <s.ArrowItem>
        <Icon
          points={points}
          transform={transform}
          color="currentColor"
        />
      </s.ArrowItem>
    );
  };

  renderDownload() {
    return (
      <s.Item
        onClick={this.handleSetLevelToDownload}
      >
        <s.ItemTitle>
          Скачать
        </s.ItemTitle>
        {this.renderArrow()}
      </s.Item>
    );
  }

  renderMainLevel() {
    return (
      <s.FilterItems>
        {this.renderDownload()}
      </s.FilterItems>
    );
  }

  renderDownloadLevel() {
    return (
      <DownloadConnect
        onGoBack={this.handleSetLevelToMain}
        onClosePopover={this.props.closePopover}
      />
    );
  }

  renderLevel() {
    const mainLevel = this.state.level === Level.main;
    if (mainLevel) {
      return (
        <s.List>
          {this.renderMainLevel()}
        </s.List>
      );
    }

    return this.renderDownloadLevel();
  }

  render() {
    return (
      <>
        {this.renderLevel()}
      </>
    );
  }

}

export default LayoutSettings;
