import { StateBuilder } from './state';

export const uncheckProjectsReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.uncheckProjects();
  const projects = newState.getState();

  return {
    ...state,
    projects,
  };
};

export default uncheckProjectsReduce;
