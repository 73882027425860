import {
  ActionData,
  fetchEventsReduce,
} from './fetchEvents.reduce';

export const actionType = 'REGULATION/EVENTS/FETCH_EVENTS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchEvents = {
  actionType,
  createAction,
  reduce: fetchEventsReduce,
};
