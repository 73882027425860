import {
  ActionData,
  addCalendarReduce,
} from './addCalendar.reduce';

export const actionType = 'REGULATION/EVENTS/ADD_CALENDAR';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addCalendar = {
  actionType,
  createAction,
  reduce: addCalendarReduce,
};
