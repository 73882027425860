import styled from 'styled-components';

const style: any = {};

style.BlockType = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  height: 100%;
  min-width: 0;

  padding-left: 16px;

`;

export default style;
