export const changePageNumberReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const {
    pageNumber,
  } = action.data;

  return {
    ...state,
    documents: {
      ...state.documents,
      currentPageNumber: pageNumber,
    },
  };
};

export default changePageNumberReduce;
