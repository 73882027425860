import { connect } from 'react-redux';

import { Item } from './item';

const mapStateToProps = (state, ownProps) => {
  const {
    currentPageNumber,
    pages,
  } = state.chronology;

  const item = pages[currentPageNumber].itemsMap.get(ownProps.chronologyDocumentId);

  return {
    item,
  };
};

export const ItemConnect = connect(
  mapStateToProps,
)(Item);

export default ItemConnect;
