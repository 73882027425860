import { connect } from 'react-redux';

import { totalCheckStatus } from '@pages/searchResult/documents/list/list.connect';

import { List } from './list';

const mapStateToProps = (state) => {
  const {
    currentPageNumber,
    pages,
    selection,
    totalDocuments,
  } = state.subscriptions.documents;

  const hasPage = pages[currentPageNumber];

  if (!hasPage) {
    return {
      items: [],
    };
  }

  const items = pages[currentPageNumber].items;

  return {
    items,
    totalCheckStatus: totalCheckStatus(selection, totalDocuments),
  };

};

export const ListConnect = connect(
  mapStateToProps,
)(List);

export default ListConnect;
