import React from 'react';

import { MenuItem } from '../menu.items';

import s from './navigation.style';

function renderMenuItem(item: MenuItem): JSX.Element {
  return (
    <s.Item
      key={item.id}
      to={item.link}
      activeClassName="active"
      isActive={item.isActive}
    >
      {item.icon}
      <s.ItemText>{item.text}</s.ItemText>
    </s.Item>
  );
}

export const Navigation = (props: { menuItems: MenuItem[] }): JSX.Element => (
  <s.Root>
    {props.menuItems.map(renderMenuItem)}
  </s.Root>
);

export default Navigation;
