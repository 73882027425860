import { updateTableSettingsReduce } from './updateTableSettings.reduce';

const actionType = 'SEARCH/RESULT/CASE_TYPE_ID/SETTINGS/UPDATE_TABLE_SETTINGS';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const updateTableSettings = {
  actionType,
  createAction,
  reduce: updateTableSettingsReduce,
};

export default updateTableSettings;
