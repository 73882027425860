import { StateBuilder } from './state';

export interface ActionData {
  id: number;
  name: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const addPackageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.packages);
  const data = {
    id: action.data.id,
    name: action.data.name,
    documents_count: 0,
    type: 'private',
    description: null,
    is_owner: true,
    information: {
      status: null,
      has_changes: false,
    },
  };

  newState.addPackage(data);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default addPackageReduce;
