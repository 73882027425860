import React from 'react';

import { Tabs } from './tabs';

import s from './information.style';

interface TabProps {
  viewOnly: boolean;
}

interface Tab {
  id: string;
  title: string;
}

interface InformationProps {
  Tab: new() => React.Component<TabProps>;
  tabs: Tab[];
  activeTabId: string;
  viewOnly: boolean;
  onUpdateActiveTab(tabId: string): void;
}

export class Information extends React.PureComponent<InformationProps> {

  render() {
    return (
      <s.Root>
        <s.Header>
          <Tabs
            tabs={this.props.tabs}
            activeId={this.props.activeTabId}
            onClick={this.props.onUpdateActiveTab}
          />
        </s.Header>
        <s.Content>
          <this.props.Tab viewOnly={this.props.viewOnly} />
        </s.Content>
      </s.Root>
    );
  }

}

export default Information;
