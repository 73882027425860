import React from 'react';

import {
  iconsPath,
  Icon,
} from '@components/icons';

import s from './control.style';

export interface ControlProps {
  isActive: boolean;
  isDefault: boolean
}

export class Control extends React.PureComponent<ControlProps> {

  render() {
    return (
      <s.Root
        isActive={this.props.isActive}
        isDefault={this.props.isDefault}
      >
        <Icon path={iconsPath.get('packageFilter')} color="#6c7b89" />
      </s.Root>
    );
  }

}

export default Control;
