import {
  DownloadFormat,
  Format,
  RESTPath,
} from '@router/path.rest';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import http from '@http';

export function buildRequisitesForClipboard(documentId, documents) {
  const {
    pages,
    currentPageNumber,
  } = documents;

  const document = pages[currentPageNumber].itemsMap.get(documentId);

  if (!document) {
    return null;
  }

  // eslint-disable-next-line max-len
  return `${document.title || document.documentType} ${document.departmentNameGenitive} от ${document.date} по делу № ${document.instanceNumber}`;
}

enum Title {
  original = 'Оригинал документа',
  withMarkers = 'Документ с маркерами',
  docx = 'Документ DOCX',
}

interface DownloadItemParam {
  documentId: number;
  source: string;
  sourceId: number;
}

interface DownloadChronologyItemParam {
  documentId: number;
  chronologyDocumentId: number;
}

export function buildDownloadChronologyItems(params: DownloadChronologyItemParam) {
  const downloadFormats = [{
    format: Format.docx,
    title: DownloadFormat.docx,
  }, {
    format: Format.pdf,
    title: DownloadFormat.original,
  }];

  return downloadFormats.map((item) => {
    const url = RESTPath.chronology.download({ ...params, format: item.format });

    return {
      title: Title[item.title],
      onClick: () => {
        sendAmplitudeData('chronology: document download', { type: item.format });
        Userpilot.track('chronology: document download', { type: item.format });
        window.open(`${window.location.origin}${url}`);
        const request = http.get({
          url,
        });

        return http.fetch(request)
          .then(() => ({
            title: Title[item.title],
            onClick: () => window.open(`${window.location.origin}${url}`),
          }))
          .catch(http.handlePaymentRequired);
      },
    };
  });
}

export function buildDownloadSimilarItems(similarDocumentId: number) {
  const downloadFormats = [{
    format: Format.docx,
    title: DownloadFormat.docx,
  }, {
    format: Format.pdf,
    title: DownloadFormat.original,
  }];

  return downloadFormats.map((item) => {
    const url = RESTPath.similar.download({ similarDocumentId, format: item.format });

    return {
      title: Title[item.title],
      onClick: () => {
        sendAmplitudeData('similar: document download', { type: item.format });
        Userpilot.track('similar: document download', { type: item.format });
        window.open(`${window.location.origin}${url}`);
        const request = http.get({
          url,
        });

        return http.fetch(request)
          .then(() => ({
            title: Title[item.title],
            onClick: () => window.open(`${window.location.origin}${url}`),
          }))
          .catch(http.handlePaymentRequired);
      },
    };
  });
}

export function buildDownloadItems(params: DownloadItemParam) {
  const downloadFormats = [DownloadFormat.docx, DownloadFormat.original, DownloadFormat.withMarkers];

  return downloadFormats.map((item) => {
    const url = RESTPath.document.download({ ...params, format: item });

    return {
      title: Title[item],
      onClick: () => {
        window.open(`${window.location.origin}${url}`);
        const request = http.get({
          url,
        });

        return http.fetch(request)
          .then(() => ({
            title: Title[item],
            onClick: () => window.open(`${window.location.origin}${url}`),
          }))
          .catch(http.handlePaymentRequired);
      },
    };
  });

}

export function prepareHighlightIds(documents) {
  return documents.reduce((acc, item) => {
    if (item.hasHighlight) {
      acc.push(item.id);
    }

    return acc;
  }, []);
}

export default buildRequisitesForClipboard;
