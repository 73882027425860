import styled from 'styled-components';

const style = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 10px 16px 11px 16px;
  border-bottom: 1px solid #eef3f4;
  color: #4183d7;
`;

style.AnchorItem = styled.a`
  text-decoration: none;
  margin-bottom: 8px;
  padding-bottom: 8px;
`;

style.Number = styled.span`
  color: #4183d7;
  line-height: 1.23;
`;

style.Title = styled.span`
  color: #1C2935;
  line-height: 1.23;
`;

export default style;
