import React from 'react';

import { DateBlockConnect } from '../filters/date/dateBlock.connect';
import { TitleConnect } from '../title/title.connect';

import s from './header.style';

export const Header = () => (
  <s.Header>
    <TitleConnect />
    <DateBlockConnect />
  </s.Header>
);

export default Header;
