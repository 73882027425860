import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateCaseResult } from './redux/updateCaseResult';

import { CaseResultBlock } from './block';

const mapStateToProps = (state) => {
  const { caseResult } = state.search.presets;

  return {
    caseResults: caseResult,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateCaseResult(data) {
    dispatch(updateCaseResult.createAction(data));
  },
});

export const CaseResultBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaseResultBlock);

export default CaseResultBlockConnect;
