import {
  RepresentativeBlock, RepresentativeBlockData,
} from '../../form/filterBlocks/representative/redux/representative';

export abstract class RepresentativeBlockHistory extends RepresentativeBlock {

  static transform(data: RepresentativeBlockData): string {
    return data.title || '';
  }

}

export default RepresentativeBlockHistory;
