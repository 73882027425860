import { StateBuilder } from './state';

export interface ActionData {
  projects: Record<string, string>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateFoldersCountReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.updateFoldersCount(action.data.projects);

  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default updateFoldersCountReduce;
