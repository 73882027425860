import React from 'react';

import { MenuButton } from '../../../components/header';

import { BreadcrumbsConnect } from './breadcrumbs.connect';

import { ToolsConnect } from './tools.connect';

import s from './header.style';

interface HeaderProps {
  isReal: boolean;
  isAuthorPackage: boolean;
  isChronologyDocument: boolean;
  isSimilarDocument: boolean;
}

export class Header extends React.PureComponent<HeaderProps> {

  renderMenu() {
    if (!this.props.isReal) {
      return null;
    }

    return (
      <s.Menu>
        <MenuButton />
      </s.Menu>
    );
  }

  render() {
    return (
      <s.Root
        id="header"
      >
        {this.renderMenu()}
        <s.Breadcrumbs>
          <BreadcrumbsConnect />
        </s.Breadcrumbs>
        <ToolsConnect
          isAuthorPackage={this.props.isAuthorPackage}
          isChronologyDocument={this.props.isChronologyDocument}
          isSimilarDocument={this.props.isSimilarDocument}
        />
      </s.Root>
    );
  }

}

export default Header;
