import { increasePackageCountReduce } from '@pages/packages/redux/documents/increasePackageCount.reduce';

import type { ActionData } from '@pages/packages/redux/documents/increasePackageCount.reduce';

const actionType = 'AUTHOR_PACKAGES/DOCUMENTS/DOCUMENT__INCREASE_PACKAGE_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const increasePackageCount = {
  actionType,
  createAction,
  reduce: increasePackageCountReduce,
};

export default increasePackageCount;
