import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { httpSubscriptionCreate } from '@pages/subscriptions/redux/subscriptionCreate.http';
import { httpSubscriptionDelete } from '@pages/subscriptions/redux/subscriptionDelete.http';
import { subscriptionCreate } from './subscriptionCreate';
import { subscriptionDelete } from './subscriptionDelete';
import { subscriptionProcessStart } from './subscriptionProcessStart';

export class SubscriptionCreateThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { title: string }) {
    const { id } = this.getState().search.subscription;

    if (!id) {

      await this.createSubscription(params.title);

      return;
    }

    await this.deleteSubscription(id);
  }

  private async createSubscription(title) {
    sendAmplitudeData('create subscription');
    Userpilot.track('create subscription');
    this.dispatchProcessStart();

    try {
      const searchId = this.getState().searchResult.id;

      const requestParams = {
        searchId,
        title,
      };

      const response = await httpSubscriptionCreate(requestParams);
      this.dispatchCreateSucceed(response.id);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async deleteSubscription(id) {
    this.dispatchProcessStart();

    try {
      const requestParams = {
        id,
      };

      await httpSubscriptionDelete(requestParams);
      this.dispatch(subscriptionDelete.createAction());
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchProcessStart() {
    this.dispatch(subscriptionProcessStart.createAction());
  }

  private dispatchCreateSucceed(id) {
    const actionData = {
      id,
    };

    this.dispatch(subscriptionCreate.createAction(actionData));
  }

}

export function subscriptionTriggerThunk(dispatch, params) {
  const thunk = new SubscriptionCreateThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SubscriptionCreateThunk;
