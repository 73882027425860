import styled from 'styled-components';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  isActive: boolean;
  bold: boolean;
}

const style: any = {};

export const width = {
  date: '96px',
  document: '800px',
};

style.Root = styled.div`
  font-size: 13px;
  background-color: ${(props: Props) => props.theme.colors.white};
`;

style.List = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
`;

style.Group = styled.div`
  display: table-column-group;
`;

style.ColumnWidthDocument = styled.div`
  width: ${width.document};
`;

style.ColumnWidthDate = styled.div`
  width: ${width.date};
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

style.BodyCellContainer = styled(style.CellContainer)`
  padding: 13px 0;
`;

style.ColumnDocument = styled(style.ColumnWidthDocument)`
  display: table-column;
`;

style.ColumnDate = styled(style.ColumnWidthDate)`
  display: table-column;
`;

style.Body = styled.div`
  display: table-row-group;
`;

export default style;
