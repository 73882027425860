import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  height: 32px;
  width: 100%;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  background: #ff9933;
  color: ${(props) => props.theme.colors.white};
  line-height: 16px;
  letter-spacing: 0.2px;
  justify-content: space-between;
  padding: 0 16px 0 8px;
`;

style.Icon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

style.Title = styled.div`
  display: flex;
  align-items: center;
`;

style.Text = styled.div`
  padding-left: 8px;
  font: 13px Roboto-Regular;
`;

style.Action = styled.div`
  font: 13px Roboto-Medium;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default style;
