import {
  DisputeCategoryBlock, DisputeCategoryBlockData,
} from '../../form/filterBlocks/disputeCategory/redux/disputeCategory';

export abstract class DisputeCategoryBlockHistory extends DisputeCategoryBlock {

  static transform(data: DisputeCategoryBlockData) {
    return data.title || '';
  }

}

export default DisputeCategoryBlockHistory;
