import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

export const RouteSubscriptionSimilarDocument = (
  <AuthRouteConnect
    path={RoutePath.subscriptionSimilarDocument}
    component={DocumentConnect}
    exact
  />
);

export default RouteSubscriptionSimilarDocument;
