import { StateBuilder } from './state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  const subscriptions = {
    ...StateBuilder.createState(),
    loading: state.subscriptions.loading,
  };

  return {
    ...state,
    subscriptions,
  };
};

export default resetToInitialReduce;
