import { Map } from 'immutable';

export interface FolderDTO {
  id: number;
  title: string;
  contain_project: boolean;
}

export interface Folder {
  id: number;
  title: string;
  selected: boolean;
}

export const prepareFolder = (item: FolderDTO): Folder => ({
  id: item.id,
  title: item.title,
  selected: item.contain_project,
});

const prepareState = (fetchedFolders: FolderDTO[]) => {
  const itemsList = [];
  let itemsMap = Map();

  fetchedFolders.forEach((item) => {
    itemsList.push(item.id);
    itemsMap = itemsMap.set(item.id, prepareFolder(item));
  });

  return {
    itemsList,
    itemsMap,
  };
};

export interface ActionData {
  folders: FolderDTO[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchFoldersForProjectReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = prepareState(action.data.folders);

  return {
    ...state,
    searchResult: {
      ...state.searchResult,
      ...newState,
      loading: false,
    },
  };
};

export default fetchFoldersForProjectReduce;
