import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { FetchSearchResultDocumentTypeSettingsThunk } from './redux/fetchSettings.thunk';
import { FetchSearchDocumentTypeThunk } from './redux/fetchDocumentType.thunk';

import { DocumentType } from './documentType';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSearchResultDocumentTypeSettingsThunk(dispatch);
    thunk.invoke();
  },
  onFetchData() {
    const thunk = new FetchSearchDocumentTypeThunk(dispatch);

    const { sourceId } = getParamsFromUrl(ownProps);
    thunk.invoke(sourceId);

    return thunk;
  },
});

export const DocumentTypeConnect = connect(
  null,
  mapDispatchToProps,
)(DocumentType);

export default DocumentTypeConnect;
