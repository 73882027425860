import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchFolders } from './fetchFolders';
import { fetchUnderControlCounter } from './fetchUnderControlCounter';
import { selectFolder } from './selectFolder';
import { addFolder } from './addFolder';
import { removeFolder } from '../remove/redux/removeFolder';
import { updateFolderTitle } from '../updateTitle/redux/updateFolderTitle';
import { increaseProjectsCount } from './increaseProjectsCount';
import { decreaseProjectsCount } from './decreaseProjectsCount';
import { resetToInitial } from './resetToInitial';

export const reducerBuilder = new ReducerBuilder([
  fetchFolders,
  fetchUnderControlCounter,
  selectFolder,
  addFolder,
  removeFolder,
  updateFolderTitle,
  increaseProjectsCount,
  decreaseProjectsCount,
  resetToInitial,
]);

export default reducerBuilder;
