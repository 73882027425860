import { connect } from 'react-redux';

import {
  getTitle,
  buildHistoryItem,
} from '@utils/utils';

import { Header } from './header';

const mapStateToProps = (state) => {
  const path = [];
  path.push(buildHistoryItem(1, 'Подписки', false, '#/subscriptions'));

  if (state.subscriptions.subscriptions.loading) {
    path.push(buildHistoryItem(2, null, true));
  }

  const title = getTitle(state.subscriptions.subscriptions);
  const hasTitle = !state.subscriptions.subscriptions.loading && !!title;
  if (hasTitle) {
    path.push(buildHistoryItem(2, title, false));
  }

  return {
    path,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
