import { StateBuilder } from './state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    packages: {
      ...StateBuilder.createState(),
      loading: state.packages.loading,
    },
  };
};

export default resetToInitialReduce;
