import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  active: boolean;
  theme: any;
}

style.Root = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 0 8px;
  border-bottom: 1px solid ${(props:Props) => props.theme.colors.globalLines};
  background-color: ${(props: Props) => props.theme.colors.white};
  width: 100%;
`;

style.Tab = styled.div`
  text-transform: uppercase;
  font: 13px Roboto-Medium;
  height: 47px;
  display: flex;
  margin: 0 8px;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.stone};
  position: relative;
  cursor: pointer;
  font-family: "Roboto-Medium", Helvetica, Arial, sans-serif;

  transition: color 0.2s ease;

  &:hover {
    color: ${(props: Props) => props.theme.colors.defaultText};
  }

  ${(props: Props) => props.active && css`
    color: ${props.theme.colors.defaultText};
    ::after {
      content: '';
      background: ${props.theme.colors.orange};
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
    }
  `}

  :nth-child(0) {
    margin: 0 15px 0 8px;
  }

  :nth-child(2) {
    margin: 0 15px 0 14px;
  }
`;

export default style;
