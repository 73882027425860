import React from 'react';

import { HeaderNotificationConnect } from '@layouts/notification/headerNotification.connect';

import s from './withHeaderNotification.style';

export const WithHeaderNotification: React.FC = (props) => (
  <s.Container>
    <HeaderNotificationConnect />
    {props.children}
  </s.Container>
);

export default WithHeaderNotification;
