import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { fetchFoldersThunk } from './sidebar/folders/redux/fetchFolders.thunk';
import { fetchFiltersThunk } from './sidebar/filters/redux/fetchFilters.thunk';
import { selectFolderThunk } from './sidebar/folders/redux/selectFolder.thunk';
import { selectFilterThunk } from './sidebar/filters/redux/selectFilter.thunk';
import { fetchLayoutSettingsThunk } from './workspace/redux/fetchLayoutSettings.thunk';
import { resetToInitial } from './redux/resetToInitial';

import { Regulation } from './regulation';
import { FolderType } from './sidebar/folders/redux/state';

const mapDispatchToProps = (dispatch, ownProps) => ({
  async onInit() {
    fetchLayoutSettingsThunk(dispatch);
    await fetchFoldersThunk(dispatch);
    await fetchFiltersThunk(dispatch);

    const params = getParamsFromUrl(ownProps);

    if (params.previewId) {
      selectFolderThunk(dispatch, { id: null, previewId: Number(params.previewId) });

      return;
    }

    if (params.filterId) {
      selectFilterThunk(dispatch, { id: Number(params.filterId) });

      return;
    }

    let folderId = params.folderId;
    const isWorkingFolder = folderId === FolderType.underControl || folderId === FolderType.calendar;
    if (!isWorkingFolder) {
      folderId = Number(folderId);
    }

    selectFolderThunk(dispatch, { id: folderId });

  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
  },
});

export const RegulationConnect = connect(
  null,
  mapDispatchToProps,
)(Regulation);

export default RegulationConnect;
