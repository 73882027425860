import { connect } from 'react-redux';

import { Familiarize } from './familiarize';
import { toggleFamiliarizeThunk } from '../../redux/toggleFamiliarize.thunk';

const mapStateToProps = (state, ownProps) => {
  const { familiarize } = state.regulation.projects.projects.itemsMap.get(ownProps.id);

  return {
    familiarize,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onToggle() {
    toggleFamiliarizeThunk(dispatch, { id: ownProps.id });
  },
});

export const FamiliarizeConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Familiarize);

export default FamiliarizeConnect;
