import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';
import { AnalysisConnect } from './analysis.connect';
import { withFetchSearch } from '../withFetchSearch';

export const RouteSearchAnalysis = (
  <AuthRouteConnect
    path={RoutePath.analysis}
    component={withFetchSearch(AnalysisConnect)}
  />
);

export default RouteSearchAnalysis;
