import React from 'react';

import s from './item.style';

export interface Props {
  cell: {
    id: string;
    title: string;
  }
  onClick: () => void;
}

export const Cell = (props: Props) => {
  const isTitle = props.cell.id === 'title';
  if (isTitle) {
    return (
      <s.CellTitle
        key={props.cell.id}
      >
        <s.ColumnWidthTitle>
          <s.CellContainer>
            <s.BodyText
              isTitle
              isInner
            >
              {props.cell.title}
            </s.BodyText>
          </s.CellContainer>
        </s.ColumnWidthTitle>
      </s.CellTitle>
    );
  }

  const isEmpty = props.cell.title === '0' || props.cell.title === '0%';
  const itemClick = (e) => {
    e.stopPropagation();

    if (isEmpty) {
      return;
    }

    props.onClick();
  };

  return (
    <s.CellItem
      key={props.cell.id}
      isEmpty={isEmpty}
    >
      <s.ColumnWidthItem>
        <s.CellContainer>
          <s.BodyText
            textAlign="end"
            isEmpty={isEmpty}
            onClick={itemClick}
          >
            {props.cell.title}
          </s.BodyText>
        </s.CellContainer>
      </s.ColumnWidthItem>
    </s.CellItem>
  );
};

export default Cell;
