import { unNotifyAllSubscriptionsReduce } from './unNotifyAllSubscriptions.reduce';

const actionType = 'SUBSCRIPTIONS/TOGGLE_ALL_NOTIFICATION_OFF';

const createAction = () => ({
  type: actionType,
});

export const unNotifyAllSubscriptions = {
  actionType,
  createAction,
  reduce: unNotifyAllSubscriptionsReduce,
};

export default unNotifyAllSubscriptions;
