import { RESTPath } from '@router/path.rest';
import http from '@http';

import { SortType } from '../../components/sortIcon/sortIcon';

export interface GetCourtsSettings {
  searchId: number;
  filterType: string;
  valueId: string;
  sortDirection: SortType;
  pageNumber: number;
  perPage?: number;
}

export function httpFetchSearchCourts(settings: GetCourtsSettings) {
  const request = http.get({
    url: RESTPath.search.analytic.courts.getCourts(settings),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSearchCourts;
