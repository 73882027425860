import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { HeaderConnect } from './header/header.connect';

import { SidebarConnect } from './sidebar/sidebar.connect';
import { WorkspaceConnect } from './workspace/workspace.connect';

import s from './packages.style';

interface PackagesProps {
  onInit(): void;
  onResetToInitial(): void;
}

export class Packages extends React.PureComponent<PackagesProps> {

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('collection view');
    Userpilot.track('collection view');
    this.props.onResetToInitial();
    this.props.onInit();
  }

  componentWillUnmount() {
    this.props.onResetToInitial();
  }

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <HeaderConnect />
          <s.Content>
            <SidebarConnect />
            <WorkspaceConnect />
          </s.Content>
        </s.Root>
      </WithHeaderNotification>
    );
  }

}

export default Packages;
