import React from 'react';

import { Loader } from '@components/loader';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { Header } from '../components/header/header';

import type { SearchId } from '../redux/state';

import { DocumentTypeConnect } from './documentType/documentType.connect';
import { CaseTypeConnect } from './caseType/caseType.connect';
import { DefinitionContentTypeConnect } from './definitionContentType/definitionContentType.connect';
import { DisputeCategoryConnect } from './disputeCategory/disputeCategory.connect';
import { DateConnect } from './date/date.connect';

import s from './analysis.style';

interface Props {
  id: SearchId;
  shouldRender: boolean;
  onBootstrap(): void;
}

export class Analysis extends React.PureComponent<Props> {

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('results', { tab: 'analysis' });
    Userpilot.track('results', { tab: 'analysis' });
    this.props.onBootstrap();
  }

  renderLoader() {
    if (this.props.shouldRender) {
      return null;
    }

    // @ts-ignore
    return <Loader />;
  }

  renderContent() {
    return (
      <s.Content
        shouldRender={this.props.shouldRender}
      >
        <s.ContentContainer>
          <DocumentTypeConnect {...this.props} />
          <DefinitionContentTypeConnect {...this.props} />
          <DateConnect {...this.props} />
          <CaseTypeConnect {...this.props} />
          <DisputeCategoryConnect {...this.props} />
        </s.ContentContainer>
      </s.Content>
    );
  }

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <Header sourceId={this.props.id} />
          {this.renderLoader()}
          {this.renderContent()}
        </s.Root>
      </WithHeaderNotification>
    );
  }

}

export default Analysis;
