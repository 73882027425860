import { connect } from 'react-redux';

import { SimilarItemPromo } from './similarItemPromo';

const mapStateToProps = (state, ownProps) => {
  const { pages } = state.similar;

  const item = pages[ownProps.currentPageNumber].itemsMap.get(ownProps.similarDocumentId);

  return {
    baseHref: `#/${ownProps.source}/${ownProps.sourceId}/documents/${ownProps.documentId}`,
    item,
  };
};

export const SimilarItemPromoConnect = connect(
  mapStateToProps,
)(SimilarItemPromo);

export default SimilarItemPromoConnect;
