import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { toggleAnnotation } from '@layouts/documentHelpers/annotation/redux/toggleAnnotation';
import { updateAnnotationRatingThunk } from '@layouts/documentHelpers/annotation/redux/updateAnnotationRating.thunk';
import { Annotation } from '@layouts/documentHelpers/annotation/annotation';
import { fetchAnnotationThunk } from '@layouts/documentHelpers/annotation/redux/fetchAnnotation.thunk';

import { UserRoleType } from '@pages/userPages/redux/state';

const mapStateToProps = (state, ownProps) => {
  const isReal = state.currentUser.role !== UserRoleType.guest;
  const annotation = state.annotations.items.get(ownProps.documentId);

  const hasError = annotation && annotation.fetchError;
  const inProgress = annotation && annotation.inProgress;
  const isExpanded = annotation && annotation.isExpanded;
  const rating = annotation && annotation.rating;

  return {
    id: ownProps.documentId,
    rating,
    isReal,
    shouldRender: !!annotation,
    inProgress,
    isExpanded,
    hasError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate() {
    fetchAnnotationThunk(dispatch, { documentId: ownProps.documentId });

    sendAmplitudeData('caselook:annotation create', { documentId: ownProps.documentId });
    Userpilot.track('caselook:annotation create', { documentId: ownProps.documentId });
  },
  onRatingUpdate(data) {
    updateAnnotationRatingThunk(dispatch, { documentId: ownProps.documentId, value: data.value });

    sendAmplitudeData('caselook:annotation rating update', { documentId: ownProps.documentId, rating: data.value });
    Userpilot.track('caselook:annotation rating update', { documentId: ownProps.documentId, rating: data.value });
  },
  onToggle() {
    dispatch(toggleAnnotation.createAction({ documentId: ownProps.documentId }));

    sendAmplitudeData('caselook:annotation open', { documentId: ownProps.documentId });
    Userpilot.track('caselook:annotation open', { documentId: ownProps.documentId });
  },
});

export const AnnotationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Annotation);

export default AnnotationConnect;
