import React from 'react';

import {
  TextInput,
  TextInputProps,
} from '@components/textInput';

interface NumberFieldProps extends TextInputProps {
  value: string
}

export const NumberField: React.FC<NumberFieldProps> = ({
  placeholder = '',
  disabled = false,
  value = '',
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
}) => {

  const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value.replace(/[^\d]/g, '');

    if (value === result) {
      return;
    }

    // @ts-ignore
    onChange(result);
  };

  return (
    <TextInput
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChangeFilter}
    />
  );
};

export default TextInput;
