import http from '@http';
import { RESTPath } from '@router/path.rest';

interface RequestParams {
  id: string;
  commentary: string;
}

export function httpAddCommentary(requestParams: RequestParams) {
  const request = {
    url: RESTPath.bookmarks.addCommentary({ id: requestParams.id }),
    data: { commentary: requestParams.commentary },
  };

  return http
    .put(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpAddCommentary;
