import { Dispatch } from 'redux';

import { AnalysisSettings } from '../../redux/settings';

import { definitionContentType } from '../../redux/state';

import { saveLayout } from './saveLayout';

import { httpSaveLayout } from './saveLayout.http';

export class SaveLayoutThunk {

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(layout) {

    const settings = new AnalysisSettings();
    const dto = settings.undecorate(layout);

    try {
      await httpSaveLayout(dto);
    } catch (error) {
      throw Error(error.status);
    }
  }

  dispatchSaveSucceeded() {
    this.dispatch(saveLayout.createAction());
  }

}

export function saveLayoutThunk(dispatch) {
  const thunk = new SaveLayoutThunk(dispatch);

  dispatch((_, getState) => {
    const { searchResult } = getState();
    thunk.invoke(searchResult.analysis.itemsMap[definitionContentType.getId()].layout);
  });

}
