import { removeBlockReduce } from './removeBlock.reduce';
import { SEARCH_FORM_BLOCK } from '../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/REMOVE_BLOCK`;

const createAction = (data) => ({
  type: actionType,
  data,
});

export const removeBlock = {
  actionType,
  createAction,
  reduce: removeBlockReduce,
};

export default removeBlock;
