import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  animation: boolean;
  withShadow: boolean;
}

style.Wrapper = styled.div`
  z-index: 101;
  animation-duration: 300ms;
  animation-fill-mode: both;
  box-shadow: ${(props: Props) => (props.withShadow ?
    '0 2px 8px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15)' :
    'none')};

  ${(props: Props) => props.animation && css`
      transition: height .2s, width .3s cubic-bezier(0.0,0.0,0.2,1);
  `}
`;

export default style;
