import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import {
  Roles,
  RoleId,
  User as UserState,
} from '@pages/regulation/redux/users/state';

import { Select } from '@pages/search/form/components/select/select';

import s from './user.style';

interface UserMethods {
  onChangeRole(roleId: RoleId): void;
}

type UserProps = UserState & UserMethods;

export class User extends React.PureComponent<UserProps> {

  static roles = [
    {
      id: 'owner',
      title: Roles.owner,
    },
    {
      id: 'editor',
      title: Roles.editor,
    },
    {
      id: 'visitor',
      title: Roles.visitor,
    },
    {
      id: 'none',
      title: Roles.none,
    },
  ];

  state = {
    isAvatarHeightDominant: true,
  };

  handleChangeRole = (role) => {
    this.props.onChangeRole(role.id);
  };

  handleAvatarLoad = ({ target: img }) => {
    const height = img.offsetHeight;
    const width = img.offsetWidth;

    if (height >= width) {
      this.setState({ isAvatarHeightDominant: true });
    } else {
      this.setState({ isAvatarHeightDominant: false });
    }
  };

  renderAvatar() {
    if (!this.props.avatar) {
      const avatarPath = iconsPath.get('avatar');

      return <s.AvatarIcon><Icon path={avatarPath} size={32} viewBoxSize={32} color="#dfe2e6" /></s.AvatarIcon>;
    }

    return <img onLoad={this.handleAvatarLoad} src={this.props.avatar} alt="avatar" />;
  }

  renderSelect() {

    const roles = [...User.roles];

    if (this.props.role !== User.roles[0].id) {
      roles.shift();
    }

    const disabled = this.props.role === User.roles[0].id;

    const activeItem = User.roles.find((role) => role.id === this.props.role);

    return (
      <Select
        disabled={disabled}
        useAnchorWidth
        activeItem={activeItem}
        list={roles}
        onChange={this.handleChangeRole}
      />
    );
  }

  render() {

    return (
      <s.Root>
        <s.Photo
          isAvatarHeightDominant={this.state.isAvatarHeightDominant}
        >
          {this.renderAvatar()}
        </s.Photo>
        <s.Info>
          <s.Name>
            {this.props.name}
          </s.Name>
          <s.Email>
            {this.props.email}
          </s.Email>
        </s.Info>
        <s.Status>
          {this.props.status}
        </s.Status>
        <s.Role>
          {this.renderSelect()}
        </s.Role>
      </s.Root>
    );
  }

}

export default User;
