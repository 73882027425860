import EventEmitter from 'wolfy87-eventemitter';

enum Events {
  addPending = 'pending',
  addDone = 'done',
  addFail = 'fail',
  remove = 'remove',
}

class NotifierManager extends EventEmitter {

  pending(...params: any[]) {
    this.emitEvent(Events.addPending, params);
  }

  done(...params: any[]) {
    this.emitEvent(Events.addDone, params);
  }

  fail(...params: any[]) {
    this.emitEvent(Events.addFail, params);
  }

  remove(...params: { id: number | string; }[]) {
    this.emitEvent(Events.remove, params);
  }

  subscribePending(handler: Function) {
    this.addListener(Events.addPending, handler);

    return () => this.removeListener(Events.addPending, handler);
  }

  subscribeDone(handler: Function) {
    this.addListener(Events.addDone, handler);

    return () => this.removeListener(Events.addDone, handler);
  }

  subscribeFail(handler: Function) {
    this.addListener(Events.addFail, handler);

    return () => this.removeListener(Events.addFail, handler);
  }

  subscribeRemove(handler: Function) {
    this.addListener(Events.remove, handler);

    return () => this.removeListener(Events.remove, handler);
  }

}

export const notifierManager = new NotifierManager();
export default notifierManager;
