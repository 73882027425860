import isEmpty from 'lodash/isEmpty';

export interface ItemDTO {
  id: string;
  body: string;
}

export interface DocumentDTO {
  document: ItemDTO[];
  resolution: ItemDTO[];
  documentId: number;
  title: string;
}

export interface Paragraph {
  id: number;
  body: string;
  hasHighlight: boolean;
}

interface DocumentItem {
  id: string;
  body: string;
  hasHighlight: boolean;
}

export type Document = DocumentItem[];

export type Resolution = DocumentItem[];

export interface BuilderResult {
  document: Document;
  resolution: Resolution;
  documentId: number;
}

export class DocumentBuilder {

  // @ts-ignore
  private dto: DocumentDTO = null;

  private data = {
    document: [],
    resolution: [],
    documentId: null,
  };

  constructor(props: DocumentDTO) {
    this.dto = props;
  }

  private buildDocument() {
    if (!isEmpty(this.dto.document)) {
      // @ts-ignore
      this.data.document = this.dto.document.map((item) => {
        const paragraph = {
          id: item.id,
          body: item.body,
          hasHighlight: false,
        };

        if (item.body.includes('g-highlight')) {
          paragraph.hasHighlight = true;
        }

        return paragraph;
      });
    }
  }

  private buildResolution() {
    if (!isEmpty(this.dto.resolution)) {
      // @ts-ignore
      this.data.resolution = this.dto.resolution.map((item) => {
        const paragraph = {
          id: item.id,
          body: item.body,
          hasHighlight: false,
        };

        if (item.body.includes('g-highlight')) {
          paragraph.hasHighlight = true;
        }

        return paragraph;
      });
    }
  }

  public buildSate() {
    // @ts-ignore
    this.data.documentId = this.dto.documentId;
    this.buildDocument();
    this.buildResolution();
  }

  public getState(): BuilderResult {
    // @ts-ignore
    return this.data;
  }

}

export default DocumentBuilder;
