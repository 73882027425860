import {
  DocumentId,
  Id,
  RESTPath,
  Source,
} from '@router/path.rest';

import http from '@http';

export interface RequestParams {
  documentId: DocumentId;
  sourceId: Id;
  source: Source,
}

export function httpFetchAttributes(requestParams: RequestParams) {
  const request = http.get({
    url: RESTPath.document.getAttributes(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchAttributes;
