import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchSimilarDocuments } from './fetchSimilarDocuments';
import { changePageNumber } from './changePageNumber';

import { resetSimilarDocuments } from './resetSimilarDocuments';

import { httpFetchSimilarDocuments } from './fetchSimilarDocuments.http';

import { loadingEnd } from './loading';
import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export class FetchSimilarDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(documentId?: number, pageNumber?: number) {
    const state = this.getState();
    const similarDocumentId = state.similar.documentId;
    const fetchDocumentId = documentId || similarDocumentId;
    let fetchPageNumber = pageNumber;

    const sameDocument = similarDocumentId === fetchDocumentId;

    if (!sameDocument) {
      this.dispatchResetSimilarState();
    }

    if (!fetchPageNumber) {
      sendAmplitudeData('similar: open tab');
      Userpilot.track('similar: open tab');
      fetchPageNumber = this.getState().similar.currentPageNumber;
    }

    sendAmplitudeData('similar: change page', { page: fetchPageNumber });
    Userpilot.track('similar: change page', { page: fetchPageNumber });

    const hasPage = this.getState().similar.pages[fetchPageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber({ page: fetchPageNumber });

      return;
    }

    this.fetchDocuments(fetchDocumentId, fetchPageNumber);
  }

  private async fetchDocuments(documentId, pageNumber) {
    this.dispatch(pageLoadingStart.createAction());

    try {
      const result = await httpFetchSimilarDocuments({ documentId, pageNumber });

      this.dispatchGetDocumentsSucceed(documentId, result);
      this.dispatch(loadingEnd.createAction());
      this.dispatch(pageLoadingEnd.createAction());
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchResetSimilarState() {
    this.dispatch(resetSimilarDocuments.createAction());
  }

  private dispatchGetDocumentsSucceed(documentId, params) {
    const actionData = {
      documentId,
      ...params,
    };

    this.dispatch(fetchSimilarDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchSimilarDocumentsThunk(dispatch, documentId?, pageNumber?) {
  const thunk = new FetchSimilarDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(documentId, pageNumber);
}

export default fetchSimilarDocumentsThunk;
