import React from 'react';

import s from './title.style';

interface TitleProps {
  title: string;
}

export class Title extends React.PureComponent<TitleProps> {

  renderTitle() {
    if (!this.props.title) {
      return 'Поисковые запросы за все время';
    }

    return this.props.title;
  }

  render() {

    return (
      <s.Root>
        {this.renderTitle()}
      </s.Root>
    );

  }

}

export default Title;
