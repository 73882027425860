import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

export const RouteSubscriptionDocument = (
  <AuthRouteConnect
    path={RoutePath.subscriptionDocument}
    component={DocumentConnect}
    exact
  />
);

export default RouteSubscriptionDocument;
