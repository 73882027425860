import React from 'react';

import { Button } from '@components/button';

import s from './actions.style';

interface ActionsProps {
  isAuthorPackage: boolean;
  onSubmit(): void;
  onClose(): void;
}

export class Actions extends React.PureComponent<ActionsProps> {

  renderSubmitTitle() {
    if (this.props.isAuthorPackage) {
      return 'Сохранить';
    }

    return 'Опубликовать';
  }

  render() {
    return (
      <s.Root>
        <s.ActionCancel>
          <Button
            onClick={this.props.onClose}
          >
            Отмена
          </Button>
        </s.ActionCancel>
        <s.Action>
          <Button
            onClick={this.props.onSubmit}
          >
            {this.renderSubmitTitle()}
          </Button>
        </s.Action>
      </s.Root>
    );
  }

}

export default Actions;
