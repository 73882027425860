import { connect } from 'react-redux';

import { fetchNotificationsThunk } from '../redux/fetchNotifications.thunk';

import { NotificationsButton } from './notificationsButton';

const mapStateToProps = (state) => ({
  loading: state.notificationsBuffer.loading,
  hasUnread: state.notificationsBuffer.hasUnread,
  itemsCount: state.notificationsBuffer.queue.length,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchNotifications() {
    fetchNotificationsThunk(dispatch);
  },
});

export const NotificationsButtonConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsButton);

export default NotificationsButtonConnect;
