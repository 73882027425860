import { createPackageSucceedReduce } from './createPackageSucceed.reduce';

import type { ActionData } from './createPackageSucceed.reduce';

const actionType = 'PACKAGE/CREATE__SUCCEED';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const createPackageSucceed = {
  actionType,
  createAction,
  reduce: createPackageSucceedReduce,
};

export default createPackageSucceed;
