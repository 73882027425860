import { StateBuilder } from './state';

export const searchPackagesResultResetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    searchResult: StateBuilder.createState(),
  };
};

export default searchPackagesResultResetToInitialReduce;
