import { connect } from 'react-redux';

import { AddPackage } from '@components/addPackage/addPackage';
import { searchPackagesResultResetToInitial }
  from '../../packages/redux/searchPackages/searchPackagesResultResetToInitial';

import { fetchPackagesThunk } from './redux/fetchPackages.thunk';
import { createPackageThunk } from './redux/createPackage.thunk';
import { addDocumentToPackageThunk } from './redux/addDocumentToPackage.thunk';

const mapStateToProps = (state) => {
  const { packagesCount } = state.document;
  const count = packagesCount > 99 ?
    '99+' :
    packagesCount;

  const packages = state.packages.searchResult;

  return {
    count,
    packages,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInit() {
    fetchPackagesThunk(dispatch);
  },
  onResetToInitial() {
    dispatch(searchPackagesResultResetToInitial.createAction());
  },
  onPackageCreate(searchQuery: string) {
    const query = searchQuery.trim();
    createPackageThunk(dispatch, { query });
  },
  onPackageToggle(packageId: number) {
    const params = {
      packageId,
    };

    addDocumentToPackageThunk(dispatch, params);
  },
});

export const AddPackageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPackage);

export default AddPackageConnect;
