import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { httpSaveLastname } from './saveLastname.http';

import { saveLastname } from './saveLastname';

class SaveLastnameThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { text: string }) {
    sendAmplitudeData('user: changeLastname');
    Userpilot.track('user: changeLastname');
    this.dispatchSetLastname(params);
    await this.httpSaveLastname(params);

  }

  private dispatchSetLastname(data) {
    this.dispatch(saveLastname.createAction(data));
  }

  private async httpSaveLastname(params) {
    try {
      await httpSaveLastname(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export async function saveLastnameThunk(dispatch, params) {
  const thunk = new SaveLastnameThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default SaveLastnameThunk;
