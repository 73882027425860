import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateCourt } from './redux/updateCourt';

import { CourtBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateCourt(data) {
    dispatch(updateCourt.createAction(data));
  },
});

export const CourtBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourtBlock);

export default CourtBlockConnect;
