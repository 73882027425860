import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateParticipantReduce } from './updateParticipant.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/ANOTHER_PERSON/UPDATE_PARTICIPANT`;

export interface Data {
  id: string;
  data: {
    id?: string;
    address?: string;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateParticipant = {
  actionType,
  createAction,
  reduce: updateParticipantReduce,
};

export default updateParticipant;
