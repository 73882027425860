import React from 'react';

import { CommentaryText } from './commentaryText';

import s from './commentary.style';

interface CommentaryProps {
  renderFromRight?: boolean;
  onAddCommentary?: (comment: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  commentary: string;
}

export class Commentary extends React.PureComponent<CommentaryProps> {

  state = {
    isEditable: false,
  };

  handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  private handleLabelClick = () => {
    this.setState({
      isEditable: true,
    });
  };

  // eslint-disable-next-line
  onAddComment = (commentary) => {
    this.setState({
      isEditable: false,
    });

    if (this.props.commentary === commentary) {
      return;
    }

    this.props.onAddCommentary(commentary);
  };

  renderComment() {
    if (!this.props.commentary) {
      return (
        <CommentaryText
          autofocus
          onBlur={this.onAddComment}
          onFocus={this.props.onFocus}
          value={this.props.commentary}
        />
      );
    }

    return (
      <CommentaryText
        onBlur={this.onAddComment}
        onFocus={this.props.onFocus}
        value={this.props.commentary}
      />
    );
  }

  render() {
    const shouldRenderLabel = !this.props.commentary && !this.state.isEditable;
    if (shouldRenderLabel) {
      return (
        <s.RootLabel
          onClick={this.handleStopPropagation}
        >
          <s.Label
            onClick={this.handleLabelClick}
          >
            Добавить комментарий
          </s.Label>
        </s.RootLabel>
      );
    }

    return (
      <s.Root
        onClick={this.handleStopPropagation}
      >
        {this.renderComment()}
      </s.Root>
    );
  }

}

export default Commentary;
