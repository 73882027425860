import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.a`
  align-items: baseline;
  text-decoration: none;
  position: relative;
  display: flex;
  margin-left: -4px;
  margin-bottom: 6px;
`;

style.Icon = styled.div``;

style.Name = styled.div`
  font: 13px Roboto-Regular;
  color: ${(props) => props.theme.colors.blue};
  padding-left: 8px;
`;

export default style;
