import React from 'react';

import { Loader } from '@components/loader';

import type { Phase as PhaseProps } from '../../redux/state';

import { Phase } from './phase';

import s from './phasesTab.style';

interface PhasesTabProps {
  items: PhaseProps[];
  loading: boolean;
}

export class PhasesTab extends React.PureComponent<PhasesTabProps> {

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderItem = (item: PhaseProps, index) => <Phase key={index} item={item} />;

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return (
      <s.Root>
        <s.Container>
          {this.props.items.map(this.renderItem)}
        </s.Container>
      </s.Root>
    );
  }

}

export default PhasesTab;
