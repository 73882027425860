import { v4 as uuid } from 'uuid';

type Id = string;

interface Relation {
  title: string;
  body: string;
  block_ids: Id[];
  children?: Relation[];
}

export interface ActionData {
  relations: Relation[];
}

interface Action {
  type: string;
  data: ActionData;
}

function prepareRelation(item, data, isParent = false) {
  const relation = {
    id: uuid(),
    blockIds: [],
    body: null,
    title: null,
    isChildren: !isParent,
  };

  if (item.title) {
    relation.title = item.title;
  }

  if (item.body) {
    relation.body = item.body;
  }

  if (item.block_ids) {
    relation.blockIds = item.block_ids;
  }

  data.push(relation);

  const hasChildren = item.children && item.children.length;
  if (hasChildren) {
    item.children.forEach((children) => prepareRelation(children, data));
  }
}

function prepareRelations(relations) {
  const data = [];
  relations.forEach((item) => prepareRelation(item, data, true));

  return data;
}

export const fetchRelationsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    relations: {
      ...state.relations,
      data: prepareRelations(action.data.relations),
      loading: false,
    },
  };
};

export default fetchRelationsReduce;
