import React from 'react';

import list from '@pages/packages/workspace/documents/list/list.style';
import s from '@pages/packages/workspace/documents/list/cellTag.style';

import { AddTagConnect } from './addTag.connect';

interface CellMoreProps {
  id: number;
}

export const CellTag = (props: CellMoreProps) => (
  <s.CellTag>
    <list.BodyCellContainer>
      <AddTagConnect
        disabled
        documentId={props.id}
      />
    </list.BodyCellContainer>
  </s.CellTag>
);

export default CellTag;
