import { ReducerBuilder } from '@redux/reducer.builder';

import { resetToInitial } from './resetToInitial';
import { reducerBuilder as documentsReducer } from '../documents/redux/reducer';
import { reducerBuilder as courtsReducer } from '../courts/redux/reducer';
import { reducerBuilder as lawReducer } from '../law/redux/reducer';
import { reducerBuilder as analysisReducer } from '../analysis/redux/reducer';

import { processingStatus } from './processingStatus';

import { initial } from './state';

export const reducerBuilder = new ReducerBuilder([
  processingStatus,
  resetToInitial,
]);

reducerBuilder.merge(documentsReducer);
reducerBuilder.merge(courtsReducer);
reducerBuilder.merge(lawReducer);
reducerBuilder.merge(analysisReducer);

const initialState = initial();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
