import { connect } from 'react-redux';

import { UserRoleType } from '@pages/userPages/redux/state';

import { Tools } from './tools';

const mapStateToProps = (state) => {
  const disabled = !state.document.id;
  const source = state.document.source;
  const isReal = state.currentUser.role !== UserRoleType.guest;

  return {
    source,
    disabled,
    isReal,
  };
};

export const ToolsConnect = connect(
  mapStateToProps,
)(Tools);

export default ToolsConnect;
