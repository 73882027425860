import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchHistory } from './fetchHistory';
import { selectHistoryItem } from './selectHistoryItem';
import { resetActiveHistoryItem } from './resetActiveHistoryItem';
import { dispatchRemovingStatus } from './dispatchRemovingStatus';
import { resetToInitial } from './resetToInitial';
import { updateFilter } from './updateFilter';
import { changePageNumber } from './changePageNumber';
import { changeTotalPages } from './changeTotalPages';

export const reducerBuilder = new ReducerBuilder([
  fetchHistory,
  selectHistoryItem,
  resetActiveHistoryItem,
  dispatchRemovingStatus,
  resetToInitial,
  updateFilter,
  changePageNumber,
  changeTotalPages,
]);

export default reducerBuilder;
