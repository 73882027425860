import {
  Id,
  StateBuilder,
} from '../../../redux/state';

import { PhasesDTO } from './fetchPhases.http';

export interface ActionData {
  id: Id;
  result: PhasesDTO;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchPhasesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.buildPreviewPhases(action.data.id, action.data.result.result);

  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default fetchPhasesReduce;
