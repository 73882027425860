export type Id = number;

export interface NotificationItem {
  id: Id;
  title: string;
  isVisited: boolean;
  date: string;
}

interface State {
  queue: NotificationItem[];
  hasUnread: boolean;
  page: number;
  totalPages: number;
  loading: boolean;
}

export class StateBuilder {

  queue: NotificationItem[] = [];

  page = 0;

  totalPages = 1;

  loading = true;

  hasUnread = false;

  static createState(): State {
    return {
      queue: [],
      hasUnread: false,
      page: 0,
      totalPages: 1,
      loading: true,
    };
  }

  public createState(state: State) {
    this.queue = [...state.queue];
    this.hasUnread = state.hasUnread;
    this.page = state.page;
    this.totalPages = state.totalPages;
    this.loading = state.loading;
  }

  public setLoading(value: boolean) {
    this.loading = value;
  }

  public setHasUnread(value: boolean) {
    this.hasUnread = value;
  }

  public setPage(value: number) {
    this.page = value;
  }

  public setTotalPages(value: number) {
    this.totalPages = value;
  }

  public addItems(items: NotificationItem[]) {
    this.queue = [...this.queue, ...items];
  }

  public setVisited(id: Id) {
    this.queue = this.queue.map((item: NotificationItem) => {
      if (item.id === id) {
        return {
          ...item,
          isVisited: true,
        };
      }

      return item;
    });
  }

  public getState() {
    return {
      queue: this.queue,
      page: this.page,
      hasUnread: this.hasUnread,
      totalPages: this.totalPages,
      loading: this.loading,
    };
  }

}

export default StateBuilder;
