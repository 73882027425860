import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import {
  resolveRouteRoles,
  resolveRoutePermissions,
  LicenseType,
} from '@router/pathPermissions';

export interface User {
  id: number;
  name: string;
  role: string;
  accessTo: string[];
  isActive: boolean;
}

export const userAuthenticated = (user: User) => {
  const { id } = user;

  return id ? id > 0 : false;
};

export const userIsActive = (user: User) => {
  const { isActive } = user;

  return isActive;
};

export const userAuthorized = (user: User, location: string | { pathname: string }) => {
  const { role } = user;
  const roles = resolveRouteRoles(location);

  return roles.includes(role);
};

export const userHasPermission = (user: User, location: string | { pathname: string }) => {
  const { accessTo } = user;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  let license;

  if (caselookLicense) {
    license = LicenseType.caselook;
  }

  if (monitoringLicense) {
    license = LicenseType.monitoring;
  }

  if (caselookLicense && monitoringLicense) {
    license = LicenseType.multi;
  }

  const permissions = resolveRoutePermissions(location);

  return permissions.includes(license);
};

export function formatName(user: User) {
  return isEmpty(user) || isNull(user.id) ? 'unnamed' : user.name;
}

export default userAuthenticated;
