import { removeProjectFromFolderReduce } from './removeProjectFromFolder.reduce';

import type { ActionData } from './removeProjectFromFolder.reduce';

const actionType = 'REGULATION/FOLDER/REMOVE_PROJECT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const removeProjectFromFolder = {
  actionType,
  createAction,
  reduce: removeProjectFromFolderReduce,
};

export default removeProjectFromFolder;
