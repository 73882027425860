import React from 'react';

import { ListItem } from '@components/menu/listItem';

import { copyLinkThunk } from './copyLink/redux/copyLink.thunk';

import s from './packageMenu.style';

export type PackageId = number;

interface Props {
  id: PackageId;
  closePopover: () => void;
}

export class PackageMenu extends React.PureComponent<Props> {

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  handleCopyLink = () => {
    copyLinkThunk(this.props.id);
    this.props.closePopover();
  };

  renderCopyLink() {
    const props = {
      title: 'Скопировать ссылку на подборку',
      onClick: this.handleCopyLink,
      hasArrow: false,
    };

    return <ListItem {...props} />;
  }

  render() {
    return (
      <s.Root onClick={this.stopPropagation}>
        <s.List>
          {this.renderCopyLink()}
        </s.List>
      </s.Root>
    );
  }

}

export default PackageMenu;
