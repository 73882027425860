import React from 'react';
import { ListItem } from '@components/menu/listItem';
import { ListItemConnect } from './listItem.connect';
import { createPackageHoc } from './createPackage.hoc';

interface MenuProps {
  id: number;
  isAuthorPackage: boolean;
  onClose(): void;
  onUnpublish(): void;
}

export class Menu extends React.PureComponent<MenuProps> {

  handleUnpublish = () => {
    this.props.onUnpublish();
    this.props.onClose();
  };

  renderCreate() {
    const Component = createPackageHoc(
      ListItemConnect,
      { id: this.props.id, onClose: this.props.onClose },
    );

    return <Component />;
  }

  renderUnpublish() {
    const updateData = {
      title: 'Снять подборку с публикации',
      onClick: this.handleUnpublish,
      hasArrow: false,
    };

    if (!this.props.isAuthorPackage) {
      return null;
    }

    return <ListItem {...updateData} />;
  }

  render() {
    return (
      <>
        {this.renderCreate()}
        {this.renderUnpublish()}
      </>
    );
  }

}

export default Menu;
