import React, { PureComponent } from 'react';

import s from './commentaryText.style';

interface CommentTextProps {
  autofocus?: boolean;
  value: string;
  renderFromRight?: boolean;
  onBlur(value: string): void;
  onFocus: () => void;
}

interface CommentTextState {
  value: string;
  height: number;
  heightAssigned: boolean;
}

export class CommentaryText extends PureComponent<CommentTextProps, CommentTextState> {

  static defaultProps = {
    renderFromRight: false,
    autofocus: false,
    // eslint-disable-next-line react/default-props-match-prop-types
    onBlur: Function.prototype,
    // eslint-disable-next-line react/default-props-match-prop-types
    onFocus: Function.prototype,
    // eslint-disable-next-line react/default-props-match-prop-types
    inputRef: Function.prototype,
  };

  state = {
    value: this.props.value,
    height: 16,
    heightAssigned: false,
  };

  /**
   * @private
   */
  visualizeDescriptor = null;

  /**
   * @private
   */
  refTextArea = null;

  /**
   * @private
   */
  refTextMeasure = null;

  /**
   * @private
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  tooltipRef = null;

  componentDidMount() {
    this.ensureTextAreaHeightOnMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setTextAreaText(this.props.value);
    }

    this.ensureTextAreaTopPosition();
    this.ensureTextAreaHeight();
  }

  componentWillUnmount() {
    clearTimeout(this.visualizeDescriptor);
  }

  private setFocus = () => {
    if (!this.props.autofocus) {
      return;
    }

    if (this.refTextArea) {
      this.refTextArea.focus();
    }
  };

  private setTextAreaText(value) {
    this.setState({
      value,
      heightAssigned: false,
    });
  }

  private setTextAreaHeight(height) {
    this.setState({
      height,
      heightAssigned: true,
    });
  }

  private handleRefTextArea = (textarea) => {
    this.refTextArea = textarea;
  };

  private handleRefTextMeasure = (div) => {
    this.refTextMeasure = div;
  };

  private handleTextAreaChange = () => {
    const text = this.refTextArea.value;
    this.setTextAreaText(text);
  };

  private handleBlur = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const value = this.state.value.trim();

    this.setTextAreaText(value);

    setTimeout(() => {
      this.props.onBlur(value);
    }, 0);
  };

  private ensureTextAreaTopPosition() {
    const topStyleParam = this.refTextArea.parentElement.parentElement.style.top;
    if (topStyleParam) {
      this.refTextArea.parentElement.parentElement.style.top = '';
    }

    const bottom = this.refTextArea.parentElement.parentElement.getBoundingClientRect().bottom;
    const windowHeight = window.innerHeight;

    if (bottom > windowHeight) {
      this.refTextArea.parentElement.parentElement.style.top = `-${(bottom - windowHeight + 8)}px`;
    }
  }

  private ensureTextAreaHeightOnMount() {
    this.visualizeDescriptor = setTimeout(() => {
      this.ensureTextAreaHeight();
    }, 5);

    setTimeout(() => {
      this.setFocus();
    }, 5);

  }

  private ensureTextAreaHeight() {
    const { heightAssigned } = this.state;
    if (!heightAssigned) {
      this.assignTextAreaHeight();
    }
  }

  private assignTextAreaHeight() {
    const height = this.refTextMeasure.clientHeight + 2;
    this.setTextAreaHeight(height);
  }

  private renderTextMeasure() {
    let { value } = this.state;

    // div eats the last line-break so it needs to have an extra one
    value = `${value}\n`;

    return (
      <s.TextMeasureWrapper>
        <s.TextMeasure ref={this.handleRefTextMeasure}>
          {value}
        </s.TextMeasure>
      </s.TextMeasureWrapper>
    );
  }

  private renderTextArea() {
    const { height, value } = this.state;

    return (
      <s.TextArea
        height={height}
        value={value}
        ref={this.handleRefTextArea}
        onChange={this.handleTextAreaChange}
        onFocus={this.props.onFocus}
        onBlur={this.handleBlur}
      />
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderTextMeasure()}
        {this.renderTextArea()}
      </s.Root>
    );
  }

}

export default CommentaryText;
