import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Id } from '@pages/regulation/workspace/redux/state';

import { httpCreateFolder } from './createFolder.http';

import { addProjectToFolderThunk } from '../../../redux/searchFolders/addProjectToFolder.thunk';
import { createFolderStart } from '../../../redux/searchFolders/createFolderStart';
import { createFolderSucceed } from '../../../redux/searchFolders/createFolderSucceed';
import { addFolder } from './addFolder';

export interface CreateFolderParams {
  name: string;
  projectId: Id;
  from: 'list' | 'preview';
}

export class CreateFolderAddProjectThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: CreateFolderParams) {
    const folderId = await this.createFolder(params.name);
    if (folderId) {
      const requestParams = {
        folderId,
        projectId: params.projectId,
      };

      const folder = {
        id: folderId,
        name: params.name,
        count: 0,
      };

      this.marketingEvent(params.from);
      this.dispatchCreateFolderSucceed(folder);
      await addProjectToFolderThunk(this.dispatch, requestParams);
    }
  }

  private marketingEvent(from) {
    sendAmplitudeData('regulation:create_folder', { from });
    Userpilot.track('regulation:create_folder', { from });
  }

  private async createFolder(query) {
    this.dispatchCreateFolderStart();

    try {
      const response = await httpCreateFolder(query);

      if (!response.id) {
        return null;
      }

      return response.id;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchCreateFolderStart() {
    this.dispatch(createFolderStart.createAction());
  }

  private dispatchCreateFolderSucceed(params) {
    this.dispatch(addFolder.createAction(params));
    this.dispatch(createFolderSucceed.createAction(params));
  }

}

export function createFolderAddProjectThunk(dispatch, params) {
  const thunk = new CreateFolderAddProjectThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default CreateFolderAddProjectThunk;
