import React, { useState } from 'react';

import { plural } from '@components/plural';
import { TextInput } from '@components/textInput';

import s from './proximityRangeField.style';

interface ProximityRangeFieldProps {
  proximityRange: number
  onUpdateProximityRange: (proximityRange: number) => void
}

export const ProximityRangeField = (props: ProximityRangeFieldProps) => {
  const [value, setValue] = useState(props.proximityRange);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value;
    const regExpForInput = /^[0-9]{0,2}$/;
    const pattern = new RegExp(regExpForInput);

    if (pattern.test(newValue)) {
      setValue(Number(newValue));
    }
  };

  const validate = (): boolean => {
    const isCorrectValue = value !== props.proximityRange &&
                           value <= 20 &&
                           value > 0;

    if (!isCorrectValue) {
      return false;
    }

    return true;
  };

  const handleBlur = (): void => {
    const isCorrectValue = validate();

    if (isCorrectValue) {
      props.onUpdateProximityRange(value);
    } else {
      setValue(props.proximityRange);
    }
  };

  const filterTitles = ['слова', 'слов', 'слов'];
  const afterTitle = plural(value, filterTitles);

  return (
    <s.InputWrapper>
      <TextInput
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <s.Label>{afterTitle}</s.Label>
    </s.InputWrapper>
  );
};

export default ProximityRangeField;
