import React from 'react';

import { Loader } from '@components/loader';

import { DocumentBody } from './documentBody';
import { HighlightNavigation } from './highlightNavigation';

import type { Paragraph } from './redux/document';

import s from './documentTab.style';

interface ResolutionTabProps {
  body: Paragraph[];
  loading: boolean;
  highlightIds: string[];
}

export const ResolutionTab = (props: ResolutionTabProps) => {

  if (props.loading) {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  const targetId = 'resolution-preview-container';
  const firstParagraphId = props.body[0] && props.body[0].id;

  return (
    <s.Root
      id={targetId}
    >
      <s.DocumentContainer>
        <DocumentBody
          noBookmarks
          body={props.body}
        />
        <HighlightNavigation
          title="Результаты"
          ids={props.highlightIds}
          targetId={targetId}
          firstParagraphId={firstParagraphId}
        />
      </s.DocumentContainer>
    </s.Root>
  );
};

export default ResolutionTab;
