import { Dispatch } from 'redux';

import { httpFetchFolders } from '@pages/regulation/sidebar/folders/redux/fetchFolders.http';

import { fetchFoldersForProject } from './fetchFoldersForProject';

export class FetchFoldersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {

    try {
      const result = await httpFetchFolders();
      this.dispatchFetchSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      folders: result.result,
    };

    this.dispatch(fetchFoldersForProject.createAction(actionData));
  }

}

export function fetchFoldersThunk(dispatch) {
  const thunk = new FetchFoldersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default FetchFoldersThunk;
