import { connect } from 'react-redux';

import { fetchSearchCourtsThunk } from '../redux/fetchCourts.thunk';

import { Pagination } from './pagination';

export const splitter = '...';

function preparePages(totalPages, currentPageNumber) {
  const allPages = [];

  if (!totalPages || totalPages === 1) {
    return null;
  }

  for (let i = 1; i <= totalPages; i += 1) {
    // @ts-ignore
    allPages.push(i);
  }

  const resetIndex = () => currentPageNumber - 1;

  const renderPages = [currentPageNumber];

  let previousPageCounter = 0;
  let nextPageCounter = 0;

  let currentPageIndex = resetIndex();
  const goPrevious = () => {
    while (previousPageCounter < 3) {
      currentPageIndex -= 1;
      const nextPage = allPages[currentPageIndex];
      const lastPage = allPages[0];
      const isNextLastPage = nextPage === lastPage;

      if (nextPage && previousPageCounter === 2 && !isNextLastPage) {
        renderPages.unshift(splitter);
        renderPages.unshift(lastPage);
        break;
      }

      if (nextPage) {
        renderPages.unshift(nextPage);
        if (isNextLastPage) {
          break;
        }
      } else {
        break;
      }

      previousPageCounter += 1;
    }
  };

  const goNext = () => {
    while (nextPageCounter < 3) {
      currentPageIndex += 1;
      const nextPage = allPages[currentPageIndex];
      const lastPage = allPages[allPages.length - 1];
      const isNextLastPage = nextPage === lastPage;

      if (nextPage && nextPageCounter === 2 && !isNextLastPage) {
        renderPages.push(splitter);
        renderPages.push(lastPage);
        break;
      }

      if (nextPage) {
        renderPages.push(nextPage);
        if (isNextLastPage) {
          break;
        }
      } else {
        break;
      }

      nextPageCounter += 1;
    }
  };

  goPrevious();
  currentPageIndex = resetIndex();
  goNext();

  return renderPages;
}

const mapStateToProps = (state) => {
  const {
    totalPages,
    currentPageNumber,
  } = state.searchResult.courts;

  const isFirstPageSelected = currentPageNumber === 1;
  const isLastPageSelected = currentPageNumber === totalPages;
  const pages = preparePages(totalPages, currentPageNumber);

  return {
    pages,
    currentPageNumber,
    isFirstPageSelected,
    isLastPageSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangePage(pageNumber) {
    fetchSearchCourtsThunk(dispatch, false, null, pageNumber);
  },
});

export const PaginationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Pagination);

export default PaginationConnect;
