import { store } from '@redux/store';

import {
  StateBuilder,
  Bookmark,
} from './state';

export type ActionData = Bookmark[];

export interface Action {
  type: string;
  data: ActionData;
}

export const fetchBookmarksReduce = (state: ReturnType<typeof store.getState>, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.setQueue(action.data);
  newState.setDownloading(state.downloading);
  newState.setLoading(false);

  return newState.getState();
};

export default fetchBookmarksReduce;
