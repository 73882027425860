import { connect } from 'react-redux';

import { Item } from './item';

const mapStateToProps = (state, ownProps) => {
  const {
    pages,
    currentPageNumber,
  } = state.bookmarks;

  const item = pages[currentPageNumber].itemsMap.get(ownProps.id);

  return {
    ...item,
  };
};

export const ItemConnect = connect(
  mapStateToProps,
)(Item);

export default ItemConnect;
