import { StateBuilder as SubscriptionsStateBuilder } from '../sidebar/redux/state';
import { StateBuilder as DocumentsStateBuilder } from '../workspace/documents/redux/state';
import { StateBuilder as HistoryStateBuilder } from '../workspace/filters/redux/state';
import { StateBuilder as LayoutStateBuilder } from '../header/redux/state';

export enum Source {
  package = 'subscriptions',
}

export class StateBuilder {

  static createState() {
    return {
      subscriptions: SubscriptionsStateBuilder.createState(),
      documents: DocumentsStateBuilder.createState(),
      layout: LayoutStateBuilder.createState(),
      history: HistoryStateBuilder.createState(),
    };
  }

}

export default StateBuilder;
