export interface ActionData {
  commentary: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const saveCommentaryReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    attributes: {
      ...state.attributes,
      data: {
        ...state.attributes.data,
        commentary: action.data.commentary,
      },
    },
  };
};

export default saveCommentaryReduce;
