import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { InputComponent } from '../inputComponent/inputComponent';

import { SharedLinkCopy } from './sharedLinkCopy';

import s from '../inputComponent/inputComponent.style';

interface RequisitesCopyPopoverProps {
  id: number,
  source: string,
  sourceId: number,
  usePortal?: boolean;
  disabled: boolean;
}

export const SharedLinkCopyPopover = (props: RequisitesCopyPopoverProps) => (
  <s.IconBlock>
    <BaseDropdown
      usePortal={props.usePortal}
      disabled={props.disabled}
      placement="bottom-end"
      renderInput={(p) => <InputComponent {...p} iconName="share" />}
      renderMenu={(p) => <SharedLinkCopy {...p} {...props} />}
    />
  </s.IconBlock>
);

SharedLinkCopyPopover.defaultProps = {
  usePortal: true,
};

export default SharedLinkCopyPopover;
