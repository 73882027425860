import styled from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
}

style.Root = styled.div`
  padding: 12px 16px 13px 12px;
  position: relative;
  user-select: none;

  display: flex;

  color: ${(props: Props) => props.theme.colors.defaultText};

  cursor: pointer;
  align-items: flex-start;

  &:hover {
    background: #f6fafe;
  }
`;

style.Title = styled.div`
  padding-left: 8px;
  padding-top: 5px;
  font-size: 13px;
`;

export default style;
