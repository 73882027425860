import { setPreviewIdReduce } from './setPreviewId.reduce';

import type { ActionData } from './setPreviewId.reduce';

const actionType = 'REGULATION/SET_PREVIEW_ID';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setPreviewId = {
  actionType,
  createAction,
  reduce: setPreviewIdReduce,
};

export default setPreviewId;
