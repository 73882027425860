import {
  removeFolderReduce,
  ActionData,
} from './removeFolder.reduce';

const actionType = 'REGULATION/FOLDERS/REMOVE_FOLDER';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const removeFolder = {
  actionType,
  createAction,
  reduce: removeFolderReduce,
};

export default removeFolder;
