import { Dispatch } from 'redux';

import { Source } from '@router/path.rest';

import { httpSaveCommentary } from '@pages/document/sidebar/redux/saveCommentary.http';

import { notifierManager } from '@components/notification';

import { saveCommentary } from './saveCommentary';

export class SaveCommentaryThunk {

  getState;

  private notificationId = 'SAVE_COMMENT_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Record<any, any>) {
    this.dispatchSaveSucceed(params.commentary, params.documentId);

    try {
      const requestParams = {
        source: Source.package,
        sourceId: this.getState().packages.packages.activeItemId,
        commentary: params.commentary,
        documentId: params.documentId,
      };

      await httpSaveCommentary(requestParams);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchSaveSucceed(comment, documentId) {
    const actionData = {
      comment,
      documentId,
    };

    this.dispatch(saveCommentary.createAction(actionData));
  }

}

export function saveCommentaryThunk(dispatch, params) {
  const thunk = new SaveCommentaryThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SaveCommentaryThunk;
