import { connect } from 'react-redux';

import {
  buildRequisitesForClipboard,
  buildDownloadSimilarItems,
} from '@pages/searchResult/documents/utils';

import { Settings } from '../settings/settings';

const mapStateToProps = (state, ownProps) => {

  const similarDocumentId = ownProps.id;

  return {
    requisites: buildRequisitesForClipboard(similarDocumentId, state.similar),
    downloadItems: buildDownloadSimilarItems(similarDocumentId),
  };
};

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
