import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { updatePackageName } from './updatePackageName';

import { httpUpdatePackageName } from './updatePackageName.http';

export class UpdatePackageNameThunk {

  getState;

  private notificationId = 'UPDATE_PACKAGE_NAME_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: number, title: string }) {

    this.dispatchEditPackageName(params);

    try {
      const requestParams = {
        id: params.id,
        name: params.title,
      };

      await httpUpdatePackageName(requestParams);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }

  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchEditPackageName(params) {
    this.dispatch(updatePackageName.createAction(params));
  }

}

export function updatePackageNameThunk(dispatch, params) {
  const thunk = new UpdatePackageNameThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default UpdatePackageNameThunk;
