import { StateBuilder } from './state';

export interface ActionData {
  id?: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const setSearchIdReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.packages);
  newState.setSearchId(action.data.id);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default setSearchIdReduce;
