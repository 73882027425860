import { StateBuilder } from './state';

export interface Action {
  type: string;
}

export const fetchCommentsStartReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.comments);
  newState.updateLoading(true);

  const comments = newState.getState();

  return {
    ...state,
    comments,
  };
};

export default fetchCommentsStartReduce;
