import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  isLoading: boolean;
  isActive: boolean;
  isSelected: boolean;
  firstDayNumber: number;
}

const style: any = {};

style.Root = styled.button`
  position: relative;
  border: 0;
  border-radius: 2px;
  margin: 2px;
  width: 30px;
  height: 24px;
  color: #a6b0ba;
  background-color: transparent;
  :first-child {
    grid-column: ${(props: Props) => props.firstDayNumber || 1};
  }

  :hover {
    ${(props: Props) => props.isActive && !props.isSelected && css`
      background-color: rgba(65,131,215, .07);
    `}
  }

  ${(props: Props) => props.isActive && css`
    color: ${props.theme.colors.blue};
    font-family: Roboto-Bold;
    cursor: pointer;
  `}

  ${(props: Props) => props.isSelected && css`
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.blue};
    cursor: default;
  `}

  ${(props: Props) => props.isLoading && css`
    background: rgba(166,176,186, .2);

    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeff0 8%, #f5f6f7 38%, #eeeff0 54%);
    background-size: 1000px 640px;

    @keyframes placeHolderShimmer {
      0% {
        background-position: -468px 0
      }
      100% {
        background-position: 468px 0
      }
    }
  `}
`;

export default style;
