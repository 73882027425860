import { Dispatch } from 'redux';

import { httpFetchComments } from './fetchComments.http';

import { fetchComments } from './fetchComments';
import { fetchCommentsStart } from './fetchCommentsStart';

export class FetchCommentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    this.dispatchFetchCommentsStart();
    const data = await this.fetchComments();

    this.dispatchFetchComments(data);
  }

  private async fetchComments() {
    try {
      return await httpFetchComments();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchComments(data) {
    this.dispatch(fetchComments.createAction(data));
  }

  private dispatchFetchCommentsStart() {
    this.dispatch(fetchCommentsStart.createAction());
  }

}

export async function fetchCommentsThunk(dispatch) {
  const thunk = new FetchCommentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke();
}

export default FetchCommentsThunk;
