import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
  disable: boolean;
}

style.Root = styled.div`

  ${(props: Props) => props.disable && css`
    * {
      cursor: default;
      color: ${props.theme.colors.silver};
    }
  `}
`;

export default style;
