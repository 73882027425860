import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchLawDocument } from './fetchLawDocument';
import { resetToInitial } from './resetToInitial';
import { resetDocumentToInitial } from './resetDocumentToInitial';

import { reducerBuilder as Sidebar } from '../sidebar/redux/reducer';

import { StateBuilder } from './state';

export const reducerBuilder = new ReducerBuilder([
  resetToInitial,
  resetDocumentToInitial,
  fetchLawDocument,
]);

reducerBuilder.merge(Sidebar);

const initialState = StateBuilder.createInitialState();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
