import { connect } from 'react-redux';

import { Notify } from './notify';

import { toggleNotificationThunk } from './redux/toggleNotification.thunk';

const mapStateToProps = (state) => {
  const { itemsMap, activeItemId } = state.subscriptions.subscriptions;

  let checked = false;

  const disabled = !state.subscriptions.subscriptions.activeItemId;
  if (!disabled) {
    checked = itemsMap.get(activeItemId).hasNotification;
  }

  return {
    disabled,
    checked,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onToggle() {
    toggleNotificationThunk(dispatch);
  },
});

export const NotifyConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notify);

export default NotifyConnect;
