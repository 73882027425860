import { connect } from 'react-redux';

import { AddTag } from '@components/addTag/addTag';

import {
  buildList,
  prepareTag,
} from '@components/addTag/utils';

function getActiveTagId(documents, documentId) {
  return documents.pages[documents.currentPageNumber].itemsMap.get(documentId).tag.id;
}

const mapStateToProps = (state, ownProps) => {
  const tag = getActiveTagId(state.authorPackages.documents, ownProps.documentId);
  const currentTag = prepareTag(tag);

  return {
    source: 'package',
    currentTag,
    tags: buildList(state.packages.presets.tags, currentTag),
  };
};

export const AddTagConnect = connect(
  mapStateToProps,
)(AddTag);

export default AddTagConnect;
