import { initial } from './state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return initial();
};

export default resetToInitialReduce;
