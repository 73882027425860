import { connect } from 'react-redux';

import { unpublishAuthorPackageThunk } from '../redux/packages/unpublishAuthorPackage.thunk';

import { Menu } from './menu';

const mapStateToProps = (state, ownProps) => ({
  isAuthorPackage: state.packages.packages.itemsMap.get(ownProps.id).type === 'author',
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUnpublish() {
    unpublishAuthorPackageThunk(dispatch, ownProps.id);
  },
});

export const MenuConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);

export default Menu;
