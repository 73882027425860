import { ReducerBuilder } from '../../../../../../../redux/reducer.builder';

import { toggleLayoutType } from './toggleLayoutType';
import { updateTableSettings } from './updateTableSettings';

export const reducerBuilder = new ReducerBuilder([
  toggleLayoutType,
  updateTableSettings,
]);

export default reducerBuilder;
