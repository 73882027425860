import type { SourceTypes } from './state';

export interface ActionData {
  sourceId: number;
  source: SourceTypes,
}

interface Action {
  type: string;
  data: ActionData;
}

export const setSourceReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    source: action.data.source,
    sourceId: action.data.sourceId,
  };
};

export default setSourceReduce;
