import { fetchPinnedFiltersReduce } from './fetchPinnedFilters.reduce';

const actionType = 'SEARCH/FETCH_PINNED_FILTERS';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const fetchPinnedFilters = {
  actionType,
  createAction,
  reduce: fetchPinnedFiltersReduce,
};

export default fetchPinnedFilters;
