import { connect } from 'react-redux';

import { LicenseType } from '@router/pathPermissions';
import { Bookmarks } from './bookmarks';

const mapStateToProps = (state) => {
  const accessTo = state.currentUser.accessTo;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    shouldRender: multiLicense || caselookLicense,
  };
};

export const BookmarksConnect = connect(
  mapStateToProps,
)(Bookmarks);

export default BookmarksConnect;
