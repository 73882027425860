import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { httpCreatePackage } from '../packages/createPackage.http';

import { createPackageStart } from '../searchPackages/createPackageStart';
import { createPackageSucceed } from '../searchPackages/createPackageSucceed';

import { fetchPackages } from '../packages/fetchPackages';
import { httpFetchPackages } from '../fetchPackages.http';

import { addDocumentsToPackageThunk } from './addDocumentsToPackage.thunk';

export class CreatePackageAddDocumentThunk {

  getState;

  private notificationId = 'CREATE_PACKAGE_ADD_DOCUMENTS_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { query: string; documentId: number }) {

    try {
      const targetId = await this.createPackage(params.query);
      if (!targetId) {
        return;
      }

      const requestParams = {
        targetId,
      };

      await addDocumentsToPackageThunk(this.dispatch, requestParams);

      const result = await httpFetchPackages({});
      this.dispatchFetchPackagesSucceed(result);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private async createPackage(query) {
    this.dispatchCreatePackageStart();

    try {
      const response = await httpCreatePackage(query);

      if (!response.id) {
        return null;
      }

      this.dispatchCreatePackageSucceed(response);

      return response.id;
      // eslint-disable-next-line no-empty
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();

      return null;
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchCreatePackageStart() {
    this.dispatch(createPackageStart.createAction());
  }

  private dispatchCreatePackageSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(createPackageSucceed.createAction(actionData));
  }

  private dispatchFetchPackagesSucceed(result) {
    const actionData = {
      packages: result.packages,
    };

    this.dispatch(fetchPackages.createAction(actionData));
  }

}

export function createPackageThunk(dispatch, params) {
  const thunk = new CreatePackageAddDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default CreatePackageAddDocumentThunk;
