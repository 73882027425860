import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { Settings } from '../components/settings/settings';

import {
  buildRequisitesForClipboard,
  buildDownloadItems,
} from '../utils';

const mapStateToProps = (state, ownProps) => {

  const documentId = ownProps.id;
  const source = Source.search;
  const sourceId = state.searchResult.id;

  return {
    source,
    sourceId,
    requisites: buildRequisitesForClipboard(documentId, state.searchResult.documents),
    downloadItems: buildDownloadItems({
      documentId,
      sourceId,
      source,
    }),
  };
};

export const SettingsConnect = connect(
  mapStateToProps,
  // @ts-ignore
)(Settings);

export default SettingsConnect;
