import { prepareFirstPage } from '../../../utils';
import { disputeCategory } from '../../redux/state';

export interface ActionData {
  searchId: string | number;
  result: Record<string, any>;
  page: number;
  total_pages: number;
}

export const resetItems = (state) => {
  if (!state) {
    return state;
  }

  const currentPageNumber = 1;
  const currentItems = state.analysis.itemsMap[disputeCategory.getId()].items;
  const items = prepareFirstPage(currentItems);

  return {
    ...state,
    analysis: {
      ...state.analysis,
      itemsMap: {
        ...state.analysis.itemsMap,
        [disputeCategory.getId()]: {
          ...state.analysis.itemsMap[disputeCategory.getId()],
          currentPageNumber,
          items,
        },
      },
    },
  };
};

export default resetItems;
