import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpLogin(data) {
  const request = http.post({
    url: RESTPath.user.login,
    data,
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpLogin;
