import { decreaseProjectsCountReduce } from './decreaseProjectsCount.reduce';

import type { ActionData } from './decreaseProjectsCount.reduce';

const actionType = 'REGULATION/FOLDERS/DECREASE_PROJECTS_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const decreaseProjectsCount = {
  actionType,
  createAction,
  reduce: decreaseProjectsCountReduce,
};

export default decreaseProjectsCount;
