import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

style.Toggle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  padding: 0 37px;

  width: 100%;
  cursor: pointer;

  > i {
    margin-left: -32px;
    margin-right: 8px;
    display: none;
  }

  :hover {
    > i {
      display: flex;
    }
  }
`;

style.List = styled.div`
  padding: 0 37px;
`;

export default style;
