import React, { PureComponent } from 'react';

import { Checkbox } from '@pages/regulation/workspace/projects/table/checkbox';
import { EmptyFolder } from '@components/emptyFolder';

import { Id } from '../../redux/state';

import {
  CheckStatus,
  CheckStatusType,
} from '../table/table';

import { ItemConnect } from './item.connect';

import s from './list.style';

interface Props {
  items: Id[],
  activeId: Id,
  checkStatus: CheckStatusType;
  disableToggler: boolean,
  onItemClick(id: Id): void;
  onToggleCheck(): void;
}

export class List extends PureComponent<Props> {

  renderItem = (item) => (
    <ItemConnect
      key={item}
      id={item}
      activeId={this.props.activeId}
      onItemClick={this.props.onItemClick}
    />
  );

  renderCheckbox() {
    let check = false;
    if (this.props.checkStatus === CheckStatus.check) {
      check = true;
    }

    return (
      <s.CheckboxContainer>
        <Checkbox
          checked={check}
          disable={this.props.disableToggler}
          onClick={this.props.onToggleCheck}
        />
      </s.CheckboxContainer>
    );
  }

  renderContent() {
    if (!this.props.items.length) {
      return <EmptyFolder title="В папке нет документов" />;
    }

    return this.renderLayout();
  }

  renderGroup() {
    return (
      <s.Group>
        <s.ColumnCheckbox />
        <s.ColumnLogo />
        <s.ColumnDocument />
        <s.ColumnActions />
        <s.ColumnActions />
        <s.ColumnActions />
      </s.Group>
    );
  }

  renderHeader() {
    return (
      <s.Header>
        <s.CellCheckbox>
          <s.ColumnWidthCheckbox>
            <s.CellContainer>
              {this.renderCheckbox()}
            </s.CellContainer>
          </s.ColumnWidthCheckbox>
        </s.CellCheckbox>
        <s.CellTitle>
          <s.CheckboxLabel>Проект</s.CheckboxLabel>
        </s.CellTitle>
        <s.Title />
        <s.Title />
        <s.Title />
      </s.Header>
    );
  }

  renderLayout() {
    return (
      <s.Table>
        {this.renderGroup()}
        {this.renderHeader()}
        <s.Body>{this.props.items.map(this.renderItem)}</s.Body>
      </s.Table>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default List;
