import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './notificationsButton.style';

interface Props {
  itemsCount: number;
  hasUnread: boolean;
  loading: boolean;
  shouldUpdate: boolean;
  isActive: boolean;
  disabled: boolean;
  closePopover: () => void;
  onFetchNotifications: () => void;
}

export class NotificationsButton extends React.PureComponent<Props> {

  componentDidMount() {
    this.props.onFetchNotifications();
  }

  handleClick = (e) => {
    if (this.props.loading) {
      e.stopPropagation();
    }

    if (!this.props.shouldUpdate) {
      this.props.closePopover();
    }
  };

  renderRipple = () => {
    if (!this.props.hasUnread) {
      return null;
    }

    return <s.Ripple />;
  };

  render() {
    return (
      <s.Root
        isActive={this.props.isActive}
        onClick={this.handleClick}
        loading={this.props.loading}
        disabled={this.props.disabled}
      >
        <Icon path={iconsPath.get('notificationBell')} color="#6c7b89" />
        {this.renderRipple()}
      </s.Root>
    );
  }

}

export default NotificationsButton;
