import { connect } from 'react-redux';

import { AddTag } from '@components/addTag/addTag';

import {
  buildList,
  prepareTag,
  tags,
} from '@components/addTag/utils';

import { setTagThunk } from './redux/setTag.thunk';

const mapStateToProps = (state) => {
  const tag = state.document.tag || tags.empty.id;
  const currentTag = prepareTag(tag);

  return {
    source: state.document.source,
    currentTag,
    tags: buildList(state.packages.presets.tags, currentTag),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelectTag(tagId: string) {
    const params = {
      tagId,
    };

    setTagThunk(dispatch, params);
  },
});

export const AddTagConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTag);

export default AddTagConnect;
