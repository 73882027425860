import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';
import { addBookmark } from './addBookmark';
import { addCommentary } from './addCommentary';
import { deleteBookmarkInBuffer } from './deleteBookmark';
import { fetchBookmarks } from './fetchBookmarks';
import { cleanBookmarksBuffer } from './cleanBookmarks';
import { processStart } from './processStart';
import { processComplete } from './processComplete';

export const reducerBuilder = new ReducerBuilder([
  addBookmark,
  addCommentary,
  deleteBookmarkInBuffer,
  fetchBookmarks,
  cleanBookmarksBuffer,
  processStart,
  processComplete,
]);

reducerBuilder.setInitialState(StateBuilder.createState());

export default reducerBuilder;
