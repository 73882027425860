import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { SettingsMenuConnect as SettingsMenu } from './settingsMenu.connect';

import s from '../../components/headerSettings.style';

interface InputComponentProps {
  isActive: boolean;
}

const InputComponent = (props: InputComponentProps) => {
  const iconPath = iconsPath.get('kebab');

  return (
    <s.IconBlock>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

export const HeaderSettings = () => (
  <s.IconBlock>
    <BaseDropdown
      usePortal
      placement="bottom-end"
      renderInput={(p) => <InputComponent {...p} />}
      renderMenu={(p) => <SettingsMenu {...p} />}
    />
  </s.IconBlock>
);

export default HeaderSettings;
