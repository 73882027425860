import { connect } from 'react-redux';

import {
  getTitle,
  buildHistoryItem,
} from '@utils/utils';

import { Header } from './header';

const mapStateToProps = (state) => {
  const path = [];
  path.push(buildHistoryItem(1, 'Мои подборки', false, '#/packages'));

  const { packages } = state.packages;

  if (packages.loading) {
    path.push(buildHistoryItem(2, null, true));
  }

  const title = getTitle(packages);
  const hasTitle = !packages.loading && !!title;
  if (hasTitle) {
    path.push(buildHistoryItem(2, title, false));
  }

  const item = packages.itemsMap.get(packages.activeItemId) ?
    packages.itemsMap.get(packages.activeItemId) :
    {};

  return {
    item,
    path,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
