import { notifyAllSubscriptionsReduce } from './notifyAllSubscriptions.reduce';

const actionType = 'SUBSCRIPTIONS/TOGGLE_ALL_NOTIFICATION_ON';

const createAction = () => ({
  type: actionType,
});

export const notifyAllSubscriptions = {
  actionType,
  createAction,
  reduce: notifyAllSubscriptionsReduce,
};

export default notifyAllSubscriptions;
