import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { Source } from '@router/path.rest';
import { saveCommentary } from './saveCommentary';

import { httpSaveCommentary } from './saveCommentary.http';

export class SaveCommentaryThunk {

  getState;

  private notificationId = 'SAVE_COMMENTARY_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Record<any, any>) {
    const documentId = this.getState().document.id;

    this.dispatchSaveSucceed(params.commentary);

    try {
      const dataSource = this.getSource();
      const requestParams = {
        source: dataSource.source,
        sourceId: dataSource.sourceId,
        commentary: params.commentary,
        documentId,
      };

      await httpSaveCommentary(requestParams);
    } catch (error) {
      if (this.getSource().source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private getSource() {
    const {
      source,
      sourceId,
    } = this.getState().document;

    return {
      source,
      sourceId,
    };
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchSaveSucceed(commentary) {
    const actionData = {
      commentary,
    };

    this.dispatch(saveCommentary.createAction(actionData));
  }

}

export function saveCommentaryThunk(dispatch, params) {
  const thunk = new SaveCommentaryThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SaveCommentaryThunk;
