import { Map } from 'immutable';

import {
  Annotation,
  AnnotationItem,
  Rating,
} from './annotation';

type Items = Map<DocumentId, AnnotationItem>;

export type DocumentId = number;

export class StateBuilder {

  static createInitial() {
    return {
      items: Map(),
    };
  }

  items: Map<DocumentId, AnnotationItem> = Map();

  create(items: Items) {
    this.items = items;
  }

  getState() {
    return {
      items: this.items,
    };
  }

  getItem(id: DocumentId) {
    return this.items.get(id);
  }

  addItem(id: DocumentId, annotation: AnnotationItem) {
    const newAnnotation = new Annotation();
    newAnnotation.createFromScratch(annotation);

    this.items = this.items.set(id, newAnnotation.getData());
  }

  addEmptyItem(id: DocumentId) {
    const annotation = new Annotation();

    this.items = this.items.set(id, annotation.getData());
  }

  updateFetchError(id: DocumentId, fetchError: boolean) {
    const item = this.items.get(id);
    const annotation = new Annotation();
    annotation.createFromScratch(item);
    annotation.setFetchError(fetchError);

    this.items = this.items.set(id, annotation.getData());
  }

  updateItemRating(id: DocumentId, rating: Rating) {
    const item = this.items.get(id);
    const annotation = new Annotation();
    annotation.createFromScratch(item);
    annotation.setRating(rating);

    this.items = this.items.set(id, annotation.getData());
  }

  updateItemProgress(id: DocumentId, inProgress: boolean) {
    const item = this.items.get(id);
    const annotation = new Annotation();
    annotation.createFromScratch(item);
    annotation.setInProgress(inProgress);

    this.items = this.items.set(id, annotation.getData());
  }

  updateExpanded(id: DocumentId) {
    const item = this.items.get(id);
    const annotation = new Annotation();
    annotation.createFromScratch(item);
    annotation.setIsExpanded(!item.isExpanded);

    this.items = this.items.set(id, annotation.getData());
  }

}

export default StateBuilder;
