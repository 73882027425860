import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';

import { fetchNotifications } from './fetchNotifications';
import { setNotificationVisited } from './setNotificationVisited';
import { resetNotifications } from './resetToDefault';

export const reducerBuilder = new ReducerBuilder([
  fetchNotifications,
  setNotificationVisited,
  resetNotifications,
]);

reducerBuilder.setInitialState(StateBuilder.createState());

export default reducerBuilder;
