import { getDefaultDataOfCourts } from './state';

export const resetDocumentsReduce = (state) => {
  const defaultDataOfCourts = getDefaultDataOfCourts();

  return {
    ...state,
    courts: {
      ...state.courts,
      ...defaultDataOfCourts,
    },
  };
};

export default resetDocumentsReduce;
