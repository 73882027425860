import { Rating } from '@layouts/documentHelpers/annotation/redux/annotation';

interface Document {
  title: string;
  date: string;
  briefSummary: string;
}

interface Position {
  position: string;
  courtAssessment: string;
}

interface Regulation {
  title: string;
  applicabilityCase: string;
}

export interface SummaryItem {
  briefDescription: string;
  disputeSubject: string;
  parties: string;
  claims: string;
  courtMotivation: string;
  courtDecision: string;
  documentsCase: Document[];
  courtPosition: Position[];
  regulation: Regulation[];
  rating: Rating;
  fetchError: boolean;
  inProgress: boolean;
  isExpanded: boolean;
}

export class Summary {

  briefDescription: string = null;

  disputeSubject: string = null;

  parties: string = null;

  claims: string = null;

  courtMotivation: string = null;

  courtDecision: string = null;

  documentsCase: Document[] = null;

  courtPosition: Position[] = null;

  regulation: Regulation[] = null;

  rating: Rating = null;

  fetchError = false;

  inProgress = false;

  isExpanded = false;

  createFromScratch(props) {
    this.briefDescription = props.briefDescription;
    this.disputeSubject = props.disputeSubject;
    this.parties = props.parties;
    this.claims = props.claims;
    this.courtMotivation = props.courtMotivation;
    this.courtDecision = props.courtDecision;
    this.documentsCase = props.documentsCase;
    this.courtPosition = props.courtPosition;
    this.regulation = props.regulation;
    this.rating = props.rating;
    this.fetchError = props.fetchError;
    this.inProgress = props.inProgress;
    this.isExpanded = props.isExpanded;
  }

  setRating(rating: Rating) {
    this.rating = rating;
  }

  setInProgress(inProgress: boolean) {
    this.inProgress = inProgress;
  }

  setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  setFetchError(fetchError: boolean) {
    this.fetchError = fetchError;
  }

  getData() {
    return {
      briefDescription: this.briefDescription,
      disputeSubject: this.disputeSubject,
      parties: this.parties,
      claims: this.claims,
      courtMotivation: this.courtMotivation,
      courtDecision: this.courtDecision,
      documentsCase: this.documentsCase,
      courtPosition: this.courtPosition,
      regulation: this.regulation,
      fetchError: this.fetchError,
      rating: this.rating,
      inProgress: this.inProgress,
      isExpanded: this.isExpanded,
    };
  }

}

export default Summary;
