import { connect } from 'react-redux';

import { Header } from './header';

function prepareTitle(activeDate) {
  const { year, month, day } = activeDate;
  const days = [
    'воскресенье',
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
  ];

  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  const currentDay = new Date(`${year}-${month + 1}-${day}`);

  return `${day} ${months[month]} ${year}, ${days[currentDay.getDay()]}`;
}

const mapStateToProps = (state) => ({
  title: prepareTitle(state.regulation.events.activeDate),
});

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
