import { ReducerBuilder } from '@redux/reducer.builder';

import { updateParticipant } from './updateParticipant';
import { updateMode } from './updateMode';

export const reducerBuilder = new ReducerBuilder([
  updateParticipant,
  updateMode,
]);

export default reducerBuilder;
