import styled from 'styled-components';

import s from './layoutSwitcher.style';

const style: any = {};

style.Icon = styled(s.Icon)`
  polygon {
    color: green;
  }
;`;

export default style;
