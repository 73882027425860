import React from 'react';

import {
  ResetButton,
  SaveButton,
} from '@components/buttons';

import { TextField } from '@components/textField/textField';

import { Header } from '@components/popoverHeader/header';

import { Value as ChangeValue } from '../../components/input';

import s from './updateTitle.style';

export type Title = string;

interface StateItems {
  title: Title
}

interface RenameSettingsProps {
  onGoBack: () => void
  onSaveTitle: (title: Title) => void
  title: Title
}

export class UpdateTitle extends React.PureComponent<RenameSettingsProps, StateItems> {

  state = {
    title: this.props.title,
  };

  setTitle = (title: Title) => {
    this.setState({
      title,
    });
  };

  handleGoBack = () => {
    this.props.onGoBack();
  };

  handleSaveTitle = () => {
    const oldTitleLength = this.state.title.trim().length;
    const shouldUploadTitle = oldTitleLength > 0 && oldTitleLength < 81;

    if (!shouldUploadTitle) {
      return;
    }

    this.props.onSaveTitle(this.state.title);
    this.props.onGoBack();
  };

  handleInputChange = (value: ChangeValue): void => {
    this.setTitle(value);
  };

  renderHeader() {
    const title = 'Переименовать папку';

    return (
      <Header
        onGoBack={this.handleGoBack}
        title={title}
      />
    );
  }

  renderTextArea() {
    return (
      <s.TextArea>
        <TextField
          autofocus
          label="Название папки"
          value={this.state.title}
          maxLength={80}
          onChange={this.handleInputChange}
        />
      </s.TextArea>
    );
  }

  renderResetButton() {
    return (
      <ResetButton
        onClick={this.handleGoBack}
      />
    );
  }

  renderSaveButton() {
    const title = 'Применить';

    return (
      <SaveButton
        onClick={this.handleSaveTitle}
        title={title}
      />
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        {this.renderTextArea()}
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Container>
    );
  }

}

export default UpdateTitle;
