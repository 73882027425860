import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { createCompanyPackage } from './createCompanyPackage';
import { httpCreateCompanyPackage } from './createCompanyPackage.http';

import { unpublishCompanyPackage } from './unpublishCompanyPackage';
import { httpUnpublishCompanyPackage } from './unpublishCompanyPackage.http';

export class CompanyPackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  private id: number;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.id = null;
  }

  public async invoke(params: { id: number }) {
    const state = this.getState();
    this.id = params.id;

    const { itemsMap } = state.packages.packages;
    const isAuthor = itemsMap.get(this.id).type === 'author';
    const isCompany = itemsMap.get(this.id).type === 'company';
    const isOwner = itemsMap.get(this.id).isOwner;

    if (isAuthor) {
      return;
    }

    if (!isOwner) {
      return;
    }

    if (!isCompany) {
      this.createCompanyPackage();

      return;
    }

    this.createPrivatePackage();
  }

  private sendMarketingEvent(event: string) {
    sendAmplitudeData(event);
    Userpilot.track(event);
  }

  private createCompanyPackage() {
    this.dispatchCreateCompanyPackage();
    this.httpCreateCompanyPackage();
    this.sendMarketingEvent('package: convert to company');
  }

  private createPrivatePackage() {
    this.dispatchUnpublishCompanyPackage();
    this.httpUnpublishCompanyPackage();
    this.sendMarketingEvent('package: convert to private');
  }

  private async httpCreateCompanyPackage() {
    try {
      await httpCreateCompanyPackage(this.id);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async httpUnpublishCompanyPackage() {
    try {
      await httpUnpublishCompanyPackage(this.id);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchCreateCompanyPackage() {
    this.dispatch(createCompanyPackage.createAction({ id: this.id }));
  }

  private dispatchUnpublishCompanyPackage() {
    this.dispatch(unpublishCompanyPackage.createAction({ id: this.id }));
  }

}

export function companyPackageThunk(dispatch, params) {
  const thunk = new CompanyPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default CompanyPackageThunk;
