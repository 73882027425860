import { connect } from 'react-redux';
import {
  userAuthorized,
  userAuthenticated,
  userIsActive,
  userHasPermission,
} from '@utils/user';

import { AuthRoute } from './authRoute';

export const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const { currentUser } = state;

  const props = {
    location,
    currentUser,
    userAuthorized: userAuthorized(currentUser, location),
    userHasPermission: userHasPermission(currentUser, location),
    userAuthenticated: userAuthenticated(currentUser),
    userIsActive: userIsActive(currentUser),
  };

  return props;
};

export const AuthRouteConnect = connect(mapStateToProps)(AuthRoute);
export default AuthRouteConnect;
