import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  background: white;
`;

style.Edit = styled.div`
  width: 384px;
`;

style.List = styled.div`
  width: 256px;
  padding: 8px 0;
`;

export default style;
