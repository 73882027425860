import { StateBuilder } from './state';

export const resetSimilarDocumentsReduce = (state) => {
  if (!state) {
    return state;
  }

  return StateBuilder.createInitialState();
};

export default resetSimilarDocumentsReduce;
