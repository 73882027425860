import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchSearchAnalyticDefinitionContentType(searchId: number, page?: number) {
  const request = http.get({
    url: RESTPath.search.analytic.definitionContentType.getData(searchId, page),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSearchAnalyticDefinitionContentType;
