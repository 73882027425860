import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: row;
`;

export default style;
