import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { InputComponent } from '../inputComponent/inputComponent';

import { RequisitesCopy } from './requisitesCopy';

import s from '../inputComponent/inputComponent.style';

interface RequisitesCopyPopoverProps {
  requisites: string;
  disabled?: boolean;
  usePortal?: boolean;
}

export const RequisitesCopyPopover = (props: RequisitesCopyPopoverProps) => (
  <s.IconBlock>
    <BaseDropdown
      usePortal={props.usePortal}
      disabled={props.disabled}
      placement="bottom-end"
      renderInput={(p) => <InputComponent {...p} iconName="copy" />}
      renderMenu={(p) => <RequisitesCopy {...p} {...props} />}
    />
  </s.IconBlock>
);

RequisitesCopyPopover.defaultProps = {
  disabled: false,
  usePortal: true,
};

export default RequisitesCopyPopover;
