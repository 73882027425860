import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  background: white;

  iframe {
    border: none;
  }
`;

style.Header = styled.div`
  > div {
    border-bottom: none;
  }
`;

export default style;
