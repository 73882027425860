import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

import { withFetchSearch } from '../withFetchSearch';

export const RouteSearchChronologyDocument = (
  <AuthRouteConnect
    path={RoutePath.searchChronologyDocument}
    component={withFetchSearch(DocumentConnect)}
    exact
  />
);

export default RouteSearchChronologyDocument;
