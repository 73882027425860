import React from 'react';

import field from '@components/field/field.style';

import { TextField } from '@layouts/onlineHelp/textField';

import { HeaderCassetteConnect } from '@pages/userPages/profile/profileTab/headerCassette.connect';

import { HeaderConnect } from './header.connect';
import { UserCassette } from './userCassette';
import { ActionsConnect } from './actions.connect';

import s from './createPackage.style';

type Description = string;

interface Credentials {
  firstname: string;
  lastname: string;
  position: string;
}

interface CreatePackageProps {
  id: number;
  onSubmit(id: number, description: Description, credentials: Credentials): void;
  onClose(): void;
  description: Description;
  firstname: string;
  lastname: string;
  position: string;
  company: string;
}

interface CreatePackageState {
  description: Description;
  firstname: string;
  lastname: string;
  position: string;
  company: string;
  firstnameError: string;
  lastnameError: string;
  descriptionError: Description;
}

export class CreatePackage extends React.PureComponent<CreatePackageProps, CreatePackageState> {

  state = {
    description: this.props.description,
    firstname: this.props.firstname,
    lastname: this.props.lastname,
    position: this.props.position,
    company: this.props.company,
    firstnameError: null,
    lastnameError: null,
    descriptionError: null,
  };

  private handleSetFirstname = (event) => {
    this.setState({
      firstname: event.target.value,
    });

    this.setFirstnameError(null);
  };

  private handleSetLastname = (event) => {
    this.setState({
      lastname: event.target.value,
    });

    this.setLastnameError(null);
  };

  private handleSetPosition = (event) => {
    this.setState({
      position: event.target.value,
    });
  };

  handlePackageDescriptionChange = (event) => {
    this.setState({
      description: event.target.value,
    });

    this.setDescriptionError(null);
  };

  setFirstnameError(value: string) {
    this.setState({
      firstnameError: value,
    });
  }

  setLastnameError(value: string) {
    this.setState({
      lastnameError: value,
    });
  }

  setDescriptionError(value: string) {
    this.setState({
      descriptionError: value,
    });
  }

  handleSubmit = () => {
    if (!this.passValidation()) {
      this.validateFields();

      return;
    }

    this.props.onSubmit(
      this.props.id,
      this.state.description,
      {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        position: this.state.position,
      },
    );

    this.props.onClose();
  };

  validateFields() {
    if (!this.state.firstname || !this.state.firstname.trim()) {
      this.setState({
        firstnameError: 'Поле не может быть пустым',
      });
    }

    if (!this.state.lastname || !this.state.lastname.trim()) {
      this.setState({
        lastnameError: 'Поле не может быть пустым',
      });
    }

    if (!this.state.description || !this.state.description.trim()) {
      this.setState({
        descriptionError: 'Поле не может быть пустым',
      });
    }
  }

  passValidation(): boolean {
    if (!this.state.firstname) {
      return false;
    }

    if (!this.state.lastname) {
      return false;
    }

    if (!this.state.description) {
      return false;
    }

    return true;
  }

  renderDescriptionError() {
    const message = this.state.descriptionError;
    if (!message) {
      return null;
    }

    return (
      <field.Tooltip>
        <field.TooltipContainer>
          {message}
        </field.TooltipContainer>
      </field.Tooltip>
    );
  }

  render() {
    return (
      <s.Root>
        <HeaderConnect
          id={this.props.id}
          onClose={this.props.onClose}
        />
        <s.Content>
          <s.HeaderCassette>
            <HeaderCassetteConnect />
          </s.HeaderCassette>
          <s.Separator />
          <UserCassette
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            position={this.state.position}
            company={this.state.company}
            firstnameError={this.state.firstnameError}
            lastnameError={this.state.lastnameError}
            onChangeFirstname={this.handleSetFirstname}
            onChangeLastname={this.handleSetLastname}
            onChangePosition={this.handleSetPosition}
          />
          <s.TextField>
            <s.Fieldset>
              {this.renderDescriptionError()}
              <TextField
                height={159}
                label="Описание подборки"
                value={this.state.description}
                onTextInput={this.handlePackageDescriptionChange}
              />
            </s.Fieldset>
          </s.TextField>
        </s.Content>
        <ActionsConnect
          id={this.props.id}
          onClose={this.props.onClose}
          onSubmit={this.handleSubmit}
        />
      </s.Root>
    );
  }

}

export default CreatePackage;
