import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export default style;
