export function buildTitle(data, title) {
  if (!data.length) {
    return title;
  }

  return data[0].title;

}

export function buildCounter(data) {
  if (!data.length || data.length === 1) {
    return null;
  }

  return `+${data.length - 1}`;
}

export default buildTitle;
