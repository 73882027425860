import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchCalendarThunk } from '../../redux/fetchCalendar.thunk';
import { setActiveDate } from '../../redux/setActiveDate';

import { getDays } from '../../utils';

import { Calendar } from './calendar';

export interface DateParams {
  year: number;
  month: number;
  day: number;
}

function prepareCalendar(events, year, month, activeDate) {
  const daysInMonth = getDays({ year, month: month + 1 });
  const days = Array.from({ length: daysInMonth });

  const currentActiveDate = { ...activeDate };
  currentActiveDate.year = activeDate.year;
  currentActiveDate.month = activeDate.month;
  currentActiveDate.day = activeDate.day;

  const isActualYear = year === currentActiveDate.year;
  const isActualMonth = month === currentActiveDate.month;

  return days.map((item, index) => {
    const day = index + 1;

    if (!events) {
      return {
        day,
      };
    }

    const leadingMonth = `0${month + 1}`.slice(-2);
    const leadingDay = `0${day}`.slice(-2);

    const currentDate = `${year}-${leadingMonth}-${leadingDay}`;

    let isSelected = false;

    const isActualDay = day === currentActiveDate.day;
    if (isActualYear && isActualMonth && isActualDay) {
      isSelected = true;
    }

    return {
      day,
      isSelected,
      hasEvents: !!events[currentDate],
    };
  });
}

const mapStateToProps = (state, ownProps) => {
  const { calendars, activeDate } = state.regulation.events;

  const { month, year } = ownProps;

  const currentDate = `${year}-${month}`;
  const currentCalendar = calendars[currentDate];
  const calendar = prepareCalendar(currentCalendar, year, month, activeDate);

  let loading = true;

  if (currentCalendar && !currentCalendar.loading) {
    loading = false;
  }

  return {
    calendar,
    loading,
    activeDate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchData(params) {
    fetchCalendarThunk(dispatch, params);
  },
  onSetActiveDate(params: DateParams) {
    sendAmplitudeData('regulation:calendar_day_click', params);
    Userpilot.track('regulation:calendar_day__click');

    dispatch(setActiveDate.createAction(params));
  },
});

export const CalendarConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calendar);

export default CalendarConnect;
