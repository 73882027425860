import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './inputRemoveButton.style';

type Props = {
  onClick: () => void,
};

export const InputRemoveButton = ({ onClick }: Props) => {
  // @ts-ignore
  const path: string = iconsPath.get('cross');
  const size = 13;

  return (
    <s.InputRemoveButton
      onClick={onClick}
    >
      <Icon path={path} size={size} color="#6c7b89" />
    </s.InputRemoveButton>
  );
};

export default InputRemoveButton;
