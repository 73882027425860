import { Dispatch } from 'redux';

import { httpFetchPackageDocumentsSettings } from '@pages/packages/redux/documents/fetchSettings.http';

import { fetchSettings } from './fetchSettings';

export class FetchSettingsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
    dispatch((_, getState) => { this.getState = getState; });
  }

  public invoke() {
    const { shouldLoadSettings } = this.getState().authorPackages.documents;
    if (!shouldLoadSettings) {
      return;
    }

    this.fetchSettings();
  }

  private async fetchSettings() {
    try {
      const searchResult = await httpFetchPackageDocumentsSettings();
      this.dispatchGetDocumentsSettingsSucceed(searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetDocumentsSettingsSucceed(searchResult) {
    const actionData = {
      result: searchResult,
    };

    this.dispatch(fetchSettings.createAction(actionData));
  }

}

export function fetchSettingsThunk(dispatch) {
  const thunk = new FetchSettingsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default FetchSettingsThunk;
