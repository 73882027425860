import { selectSubscriptionReduce } from './selectSubscription.reduce';

import type { ActionData } from './selectSubscription.reduce';

const actionType = 'SUBSCRIPTIONS/SELECT_SUBSCRIPTION';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const selectSubscription = {
  actionType,
  createAction,
  reduce: selectSubscriptionReduce,
};

export default selectSubscription;
