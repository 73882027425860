import React from 'react';

import { AppealState } from '../../../redux/searchBootstrapped.reduce';

import { BlockType } from '../../components/blockType/blockType';
import { Contains } from '../../components/contains/contains';
import { Select } from '../../components/select/select';

import { AppealStateBlock as Block } from './redux/appealState';

import { Data } from './redux/updateAppealState';

import s from './block.style';

export interface AppealStateBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: Block;
  appealStates: AppealState[];
  onChangeContains: (data: any) => void;
  onUpdateAppealState: (data: Data) => void
}

export class AppealStateBlock extends React.PureComponent<AppealStateBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  handleChangeType = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdateAppealState(actualData);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderAppealState() {
    const data = this.props.item.getData();
    const activeItem = {
      id: data.id,
      title: data.title,
    };

    return (
      <s.FilterItem isGrow>
        <Select
          useAnchorWidth
          activeItem={activeItem}
          list={this.props.appealStates}
          onChange={this.handleChangeType}
        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderAppealState()}
      </s.FilterBlock>
    );
  }

}

export default AppealStateBlock;
