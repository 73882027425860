import { store } from '@redux/store';

import { StateBuilder } from './state';

export const processStartReduce = (state: ReturnType<typeof store.getState>) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.setDownloading(true);

  return newState.getState();
};

export default processStartReduce;
