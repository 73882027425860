import React from 'react';

import { ListItem } from '@components/menu/listItem';

import s from './settingsMenu.style';

interface Props {
  onLogout(): void;
  closePopover: () => void;
}

interface State {
  level: number;
}

export class SettingsMenu extends React.PureComponent<Props, State> {

  state = {
    level: 1,
  };

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  handleLogout = () => {
    this.props.closePopover();
    this.props.onLogout();
  };

  renderLogout() {
    const props = {
      title: 'Выйти',
      onClick: this.handleLogout,
      hasArrow: false,
    };

    return <ListItem {...props} />;
  }

  renderLevel() {
    const firstLevel = this.state.level === 1;
    if (!firstLevel) {
      return null;
    }

    return (
      <s.List>
        {this.renderLogout()}
      </s.List>
    );
  }

  render() {
    return (
      <s.Root onClick={this.stopPropagation}>
        {this.renderLevel()}
      </s.Root>
    );
  }

}

export default SettingsMenu;
