import { Dispatch } from 'redux';

import { addBlock } from '@pages/search/form/redux/addBlock';

import { Type } from './settings';

class AddJudgeBlockThunk {

  getState;

  private readonly dispatch: Dispatch;

  // @ts-ignore
  private extendBlock: Map<string, any>;

  constructor(dispatch) {
    this.dispatch = dispatch;

    this.buildExtendBlockMap();
  }

  public invoke(data) {
    this.addCourtBlock(data.court);
    this.addJudgeBlock(data.judge);

    const { type } = this.getState().searchResult.courts.layout;

    const isApplicable = data.columnId !== 'all';
    if (isApplicable) {
      this.addExtendBlock({ type, id: data.columnId });
    }

    this.redirectToSearch();
  }

  private buildExtendBlockMap() {
    this.extendBlock = new Map();
    this.extendBlock.set(Type.caseResult, addBlock.createAction({ type: Type.caseResult }));
    this.extendBlock.set(Type.appealState, addBlock.createAction({ type: Type.appealState }));
  }

  private addCourtBlock(data) {
    const defaultData = {
      id: data.id,
      title: data.title,
    };

    this.dispatch(addBlock.createAction({ type: 'court', defaultData }));
  }

  private addJudgeBlock(data) {
    const defaultData = {
      id: data.id,
      title: data.title,
    };

    this.dispatch(addBlock.createAction({ type: 'judge', defaultData }));
  }

  private addExtendBlock(config) {
    const presets = this.getState().search.presets[config.type];
    const defaultData = presets.find((item) => item.id === config.id);
    const action = {
      ...this.extendBlock.get(config.type),
      data: {
        ...this.extendBlock.get(config.type).data,
        defaultData,
      },
    };

    this.dispatch(action);
  }

  private redirectToSearch() {
    window.location.href = '#/search/?no_fetch_filters=true';
  }

}

export function addJudgeBlockThunk(dispatch, data) {
  const thunk = new AddJudgeBlockThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(data);
}

export default addJudgeBlockThunk;
