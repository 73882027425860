import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { toggleAnnotation } from '@layouts/documentHelpers/annotation/redux/toggleAnnotation';
import { updateAnnotationRatingThunk } from '@layouts/documentHelpers/annotation/redux/updateAnnotationRating.thunk';
import { Annotation } from '@layouts/documentHelpers/annotation/annotation';
import { fetchAnnotationThunk } from '@layouts/documentHelpers/annotation/redux/fetchAnnotation.thunk';
import { UserRoleType } from '@pages/userPages/redux/state';

const mapStateToProps = (state) => {
  const id = state.document.id;
  const isReal = state.currentUser.role !== UserRoleType.guest;
  const annotation = state.annotations.items.get(id);

  const hasError = annotation && annotation.fetchError;
  const inProgress = annotation && annotation.inProgress;
  const isExpanded = annotation && annotation.isExpanded;
  const rating = annotation && annotation.rating;

  return {
    id,
    rating,
    isReal,
    shouldRender: !!annotation,
    inProgress,
    isExpanded,
    hasError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreate(id) {
    fetchAnnotationThunk(dispatch, { documentId: id });

    sendAmplitudeData('caselook:annotation create', { documentId: id });
    Userpilot.track('caselook:annotation create', { documentId: id });
  },
  onRatingUpdate(data) {
    updateAnnotationRatingThunk(dispatch, { documentId: data.documentId, value: data.value });

    sendAmplitudeData('caselook:annotation rating update', { documentId: data.documentId, rating: data.value });
    Userpilot.track('caselook:annotation rating update', { documentId: data.documentId, rating: data.value });
  },
  onToggle(id) {
    dispatch(toggleAnnotation.createAction({ documentId: id }));

    sendAmplitudeData('caselook:annotation open', { documentId: id });
    Userpilot.track('caselook:annotation open', { documentId: id });
  },
});

export const AnnotationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Annotation);

export default AnnotationConnect;
