import { StateBuilder } from './state';

export const resetDocumentToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.document = StateBuilder.createInitialDocument();

  return newState;
};

export default resetDocumentToInitialReduce;
