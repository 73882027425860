import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.article`
  display: flex;
  flex-direction: column;
  width: 256px;
  height: 100%;
  flex-shrink: 0;
  border-right: solid 1px ${(props: Props) => props.theme.colors.borderColor};
  background-color: ${(props: Props) => props.theme.colors.white};
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
`;

style.Items = styled.div`
  padding: 8px 0;
`;

export default style;
