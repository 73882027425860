import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 4;
`;

style.SearchField = styled.div`
  background-color: ${theme.colors.white};
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;

  > div {
    margin-top: 10px;
  }
`;

export default style;
