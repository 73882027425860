import { Dispatch } from 'redux';

import { fetchPackagesSearchResult }
  from '@pages/packages/redux/searchPackages/fetchPackagesSearchResult';

import { httpFetchPackages } from '@pages/packages/redux/fetchPackages.http';

export class FetchPackagesThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    const params = {
      documentId: this.getState().document.id,
    };

    try {
      const result = await httpFetchPackages(params);
      this.dispatchFetchSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      packages: result.packages,
    };

    this.dispatch(fetchPackagesSearchResult.createAction(actionData));
  }

}

export function fetchPackagesThunk(dispatch) {
  const thunk = new FetchPackagesThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default FetchPackagesThunk;
