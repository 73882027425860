import { setLayoutSettingsReduce } from './setLayoutSettings.reduce';

import type { ActionData } from './setLayoutSettings.reduce';

const actionType = 'REGULATION/SET_LAYOUT_SETTINGS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setLayoutSettings = {
  actionType,
  createAction,
  reduce: setLayoutSettingsReduce,
};

export default setLayoutSettings;
