import { css } from 'styled-components';
import { styled } from '@components/styled';

interface Props {
  isActive: boolean;
  disabled: boolean;
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  padding: 8px;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background: #f6fafe;
  }

  &:focus {
    background: #f2f6fc;
  }

  ${(props: Props) => props.isActive && css`
    background: #f6fafe;
  `}
  
  > input {
    height: 16px;
    font-size: 13px;
    color: #22313f;
    font-family: Roboto-Regular;
    font-weight: normal;
  }
`;

style.IconWrapper = styled.div`
  left: 12px;
  top: 12px;

  ${(props: Props) => props.disabled && css`
    > i {
      color: ${props.theme.colors.disabled}
    }
  `}
`;

export default style;
