import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateJudgeReduce } from './updateJudge.reduce';

export interface Data {
  id: string;
  data: {
    id: number;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const actionType = `${SEARCH_FORM_BLOCK}/JUDGE/UPDATE`;

const createAction = (data) => ({
  type: actionType,
  data,
});

export const updateJudge = {
  actionType,
  createAction,
  reduce: updateJudgeReduce,
};

export default updateJudge;
