import { Dispatch } from 'redux';

import { filterBlocksMap } from '@pages/search/form/filterBlocks/filterBlocks.map';

import { fetchArticles } from './fetchArticles';

import {
  httpFetchSearchDocumentArticles,
  GetArticlesSettings,
} from './fetchArticles.http';

export class FetchSearchArticlesThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(documentId: number) {
    const searchId = this.getState().searchResult.id;

    try {
      const {
        sortData,
        layout: { type },
      } = this.getState().searchResult.law;

      const sortByType = filterBlocksMap.get(type).block.DTOType;

      const settings: GetArticlesSettings = {
        searchId,
        documentId,
        filterType: sortByType,
        valueId: sortData.id,
        sortDirection: sortData.type,
      };

      const searchResult = await httpFetchSearchDocumentArticles(settings);

      this.dispatchGetArticlesSucceed(documentId, searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetArticlesSucceed(documentId, searchResult) {
    const actionData = {
      documentId,
      ...searchResult,
    };

    this.dispatch(fetchArticles.createAction(actionData));
  }

}

export default FetchSearchArticlesThunk;
