import React from 'react';
import { Route } from 'react-router-dom';

import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

export const RouteAuthorPackageDocument = (
  <Route
    path={RoutePath.authorPackageDocument}
    component={DocumentConnect}
    exact
  />
);

export default RouteAuthorPackageDocument;
