import { courtsSettings } from '../../../redux/settings';

export const updateViewReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const {
    type,
    format,
  } = action.data;

  const newSettings = {
    type,
    format,
  };

  const layout = courtsSettings.updateSettings(state.courts.layout, newSettings);

  return {
    ...state,
    courts: {
      ...state.courts,
      layout,
    },
  };
};

export default updateViewReduce;
