import { v4 as uuid } from 'uuid';
import {
  isBoolean, cloneDeep,
} from 'lodash';

import http from '@http';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

import {
  ParticipantBlock,
  ParticipantBlockData,
  SuggestResponse,
  Mode,
} from '../../participant/redux/participant';

export type GetItemsForSuggest = Promise<SuggestResponse>;

export class AnotherPersonBlock extends AbstractFilterBlock {

  static type = 'anotherPerson';

  static DTOType = 'another_person';

  static blockTitle = 'Иное лицо';

  static category = category.dispute;

  static SuggestAPI = '/autocomplete/side_inn';

  protected defaultData: ParticipantBlockData = {
    participant: {
      // @ts-ignore
      id: null,
      title: '',
      // @ts-ignore
      address: null,
    },
    mode: ParticipantBlock.presetsMap.exactly as Mode,
  };

  constructor() {
    super();

    this.setCategory(AnotherPersonBlock.category);
    this.setType({
      id: AnotherPersonBlock.type,
      DTOType: AnotherPersonBlock.DTOType,
      title: AnotherPersonBlock.blockTitle,
    });
  }

  // @ts-ignore
  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {

    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const updateData = {
      ...data,
      mode: ParticipantBlock.presetsMap[data.mode.id],
    };

    const block = {
      id,
      data: updateData,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    const data = cloneDeep(this.getData());

    delete data.mode.title;

    const isPreciseMode = data.mode.id === ParticipantBlock.presetsMap.precise.id;
    if (!isPreciseMode) {
      data.participant.id = null;
      data.participant.title = data.participant.title.trim();
    }

    if (!data.participant.id) {
      data.participant.id = null;
    }

    if (!data.participant.title) {
      data.participant.title = null;
    }

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: AnotherPersonBlock.DTOType,
      data,
    };
  }

  // @ts-ignore
  public getItemsForSuggest = async (query) => {
    // @ts-ignore
    const getItems = async (): GetItemsForSuggest => {
      const request = {
        url: AnotherPersonBlock.SuggestAPI,
        body: {
          query,
        },
      };

      const response = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.result.map((item) => ({
        id: item.id,
        title: item.name,
        address: item.address,
      })),
    };
  };

  // eslint-disable-next-line
  public validate() {}

}

export default AnotherPersonBlock;
