import {
  styled,
  css, theme,
} from '@components/styled';

const style: any = {};

interface Props {
  isEmpty: boolean;
  isActive: boolean;
}

style.Root = styled.article`
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  height: auto;

  ${(props: Props) => props.isEmpty && css`
    height: 100%;
  `}

  z-index: 4;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;

  > div {
    margin-top: 10px;
  }
`;

style.Items = styled.div`
  height: 100%;
  padding: 8px 0;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.EmptyTitle = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.stone};
`;

style.Item = styled.a`
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 12px 16px;

  :hover {
    background: #4183D712;
  }

  ${(props: Props) => props.isActive && css`
    background: ${theme.colors.blue};

    :hover {
      background: ${theme.colors.blue};
    }

    div {
      color: ${theme.colors.white};
    }
  `}
`;

style.ItemN = styled.div`
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 12px 16px;

  :hover {
    background: #4183D712;
  }

  ${(props: Props) => props.isActive && css`
    background: ${theme.colors.blue};

    :hover {
      background: ${theme.colors.blue};
    }

    div {
      color: ${theme.colors.white};
    }
  `}
`;

style.Title = styled.div`
  font-size: 13px;
  color: ${theme.colors.defaultText}
`;

export default style;
