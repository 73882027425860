import React from 'react';

import s from './summaryContent.style';

interface Document {
  title: string;
  date: string;
  briefSummary: string;
}

interface Position {
  position: string;
  courtAssessment: string;
}

interface Regulation {
  title: string;
  applicabilityCase: string;
}

interface SummaryProps {
  briefDescription: string;
  disputeSubject: string;
  parties: string;
  claims: string;
  courtMotivation: string;
  courtDecision: string;
  documentsCase: Document[];
  courtPosition: Position[];
  regulation: Regulation[];
}

export class SummaryContent extends React.PureComponent<SummaryProps> {

  renderBriefDescription() {
    if (!this.props.briefDescription) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Краткое описание спора
        </s.Title>
        <s.Description>
          {this.props.briefDescription}
        </s.Description>
      </s.Container>
    );
  }

  renderSubjectOfDispute() {
    if (!this.props.disputeSubject) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Предмет спора
        </s.Title>
        <s.Description>
          {this.props.disputeSubject}
        </s.Description>
      </s.Container>
    );
  }

  renderParties() {
    if (!this.props.parties) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Стороны дела
        </s.Title>
        <s.Description>
          {this.props.parties}
        </s.Description>
      </s.Container>
    );
  }

  renderCourtDecision() {
    if (!this.props.courtDecision) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Решение суда
        </s.Title>
        <s.Description>
          {this.props.courtDecision}
        </s.Description>
      </s.Container>
    );
  }

  renderClaims() {
    if (!this.props.claims) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Исковые требования
        </s.Title>
        <s.Description>
          {this.props.claims}
        </s.Description>
      </s.Container>
    );
  }

  renderCourtMotivation() {
    if (!this.props.courtMotivation) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Мотивация суда
        </s.Title>
        <s.Description>
          {this.props.courtMotivation}
        </s.Description>
      </s.Container>
    );
  }

  renderDocumentsCase() {
    if (!this.props.documentsCase) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Документы по делу
        </s.Title>
        <s.Description>
          {this.props.documentsCase.map((document) => (
            <s.CaseContainer>
              {document}
            </s.CaseContainer>
          ))}
        </s.Description>
      </s.Container>
    );
  }

  renderCourtPosition() {
    if (!this.props.courtPosition) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          Основные позиции и их оценка судом
        </s.Title>
        <s.Description>
          {this.props.courtPosition.map((document) => (
            <s.CaseContainer>
              <s.TableItem>
                {`Позиция суда: ${document.position}`}
              </s.TableItem>
              <s.TableItem>
                {`Оценка суда: ${document.courtAssessment}`}
              </s.TableItem>
            </s.CaseContainer>
          ))}
        </s.Description>
      </s.Container>
    );
  }

  renderRegulation() {
    if (!this.props.regulation) {
      return null;
    }

    return (
      <s.Container>
        <s.Title>
          НПА и их использования в деле
        </s.Title>
        <s.Description>
          {this.props.regulation.map((document) => (
            <s.CaseContainer>
              {document}
            </s.CaseContainer>
          ))}
        </s.Description>
      </s.Container>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderBriefDescription()}
        {this.renderSubjectOfDispute()}
        {this.renderParties()}
        {this.renderClaims()}
        {this.renderDocumentsCase()}
        {this.renderCourtDecision()}
        {this.renderCourtPosition()}
        {this.renderCourtMotivation()}
        {this.renderRegulation()}
      </s.Root>
    );
  }

}

export default SummaryContent;
