import React from 'react';
import ReactDOM from 'react-dom';

import { Drawer } from './drawer';

import type { Anchor } from './drawer.style';

export interface OpenDrawerProps {
  anchor: Anchor;
  component: (JSX.Element);
  // eslint-disable-next-line react/require-default-props
  withBackground: boolean;
  onClickOutside: () => void;
  shouldAnimate?: boolean;
}

export class OpenDrawer extends React.PureComponent<OpenDrawerProps> {

  static defaultProps = {
    shouldAnimate: false,
    // eslint-disable-next-line react/default-props-match-prop-types
    withBackground: true,
  };

  state = {
    open: false,
  };

  componentDidMount() {
    setTimeout(this.mount, 10);
  }

  mount = () => {
    this.setState({
      open: true,
    });
  };

  unmount = () => {
    this.setState({
      open: false,
    });
  };

  drawerClick = (e) => {
    const shouldCatchClick = e.target === e.currentTarget;
    this.unmount();
    if (shouldCatchClick) {
      setTimeout(this.handleClose, 100);
    }
  };

  handleClose = () => {
    this.props.onClickOutside();
  };

  render() {
    const body = document.body;

    return ReactDOM.createPortal(
      <Drawer
        // @ts-ignore
        shouldAnimate={this.props.shouldAnimate}
        onClick={this.drawerClick}
        anchor={this.props.anchor}
        open={this.state.open}
        withBackground={this.props.withBackground}
      >
        {this.props.component}
      </Drawer>,
      body,
    );
  }

}

export default OpenDrawer;
