import { setTagReduce } from './setTag.reduce';

import type { ActionData } from './setTag.reduce';

const actionType = 'PACKAGES/DOCUMENTS/DOCUMENT__SET_TAG';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setTag = {
  actionType,
  createAction,
  reduce: setTagReduce,
};

export default setTag;
