import http from '@http';

import { httpRecovery } from '../http/recovery.http';

export type Email = string;

export class PasswordRecoveryThunk {

  async invoke(emailFromForm: Email) {
    const email = emailFromForm.trim();
    this.validate(email);

    const data = {
      email,
    };

    const response = await this.httpRecovery(data);

    return response;
  }

  async httpRecovery(data) {
    let result;
    try {
      result = await httpRecovery(data);
    } catch (error) {

      if ([400, 423, 422].includes(error.status)) {
        throw await http.parseResponse(error);
      }
    }

    return result;
  }

  validate(email) {
    if (!email) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw {
        errors: {
          email: ['Поле не может быть пустым'],
        },
      };
    }
  }

}

export function passwordRecoveryThunk(params) {
  const thunk = new PasswordRecoveryThunk();

  thunk.invoke(params);
}

export default PasswordRecoveryThunk;
