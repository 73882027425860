import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpCreatePackage(query) {
  const request = http.post({
    url: RESTPath.packages.createPackage(query),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpCreatePackage;
