import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateClauseReduce } from './updateClause.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/LAW/UPDATE_CLAUSE`;

export interface Data {
  id: string;
  data: {
    id: string;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateClause = {
  actionType,
  createAction,
  reduce: updateClauseReduce,
};

export default updateClause;
