import cloneDeep from 'lodash/cloneDeep';

export const selectHistoryItemReduce = (state, action) => {
  const activeHistoryId = action.data.id;
  const item = state.history.data.find((currentItem) => currentItem.id === activeHistoryId);
  const queryOfItem = item.query;
  const query = cloneDeep(queryOfItem);
  const smartFilter = {
    query: null,
    hasChange: false,
    preparing: false,
  };

  if (item.smartFilter) {
    smartFilter.query = item.smartFilter.query;
    smartFilter.hasChange = item.smartFilter.hasChange;
  }

  const history = {
    ...state.history,
    activeHistoryId,
  };

  return {
    ...state,
    history,
    query: {
      ...state.query,
      ...query,
      smartFilter,
    },
  };
};

export default selectHistoryItemReduce;
