import { resetDocumentReduce } from './resetDocument.reduce';

const actionType = 'DOCUMENT/RESET_DOCUMENT_VIEW';

const createAction = () => ({
  type: actionType,
});

export const resetDocument = {
  actionType,
  createAction,
  reduce: resetDocumentReduce,
};

export default resetDocument;
