import React from 'react';

import s from './tabBar.style';

interface Item {
  id: string;
  title: string;
  active: boolean;
}

interface Props {
  items: Item[];
  onClick: (id: string) => void;
}

interface TabProps {
  item: Item;
  onClick: (id: string) => void;
}

const Tab: React.FC<TabProps> = (props: TabProps) => {

  const handleClick = () => props.onClick(props.item.id);

  return (
    <s.Tab
      key={props.item.id}
      selected={props.item.active}
      onClick={handleClick}
    >
      {props.item.title}
    </s.Tab>
  );
};

export const TabBar: React.FC<Props> = (props: Props) => {

  const renderTab = (item) => <Tab key={item.id} item={item} onClick={props.onClick} />;

  return (
    <s.Root>
      {props.items.map(renderTab)}
    </s.Root>
  );

};

export default TabBar;
