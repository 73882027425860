import { fetchDefaultFiltersReduce } from './fetchDefaultFilters.reduce';

const actionType = 'SEARCH/FETCH_DEFAULT_FILTERS';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const fetchDefaultFilters = {
  actionType,
  createAction,
  reduce: fetchDefaultFiltersReduce,
};

export default fetchDefaultFilters;
