import { Map } from 'immutable';

import {
  Type,
  AnalysisSettings,
} from '../../../../redux/settings';

import { caseType } from '../../../../redux/state';

function prepareModel(data) {
  return data.reduce((acc, item) => {
    acc.itemsList = acc.itemsList.concat(item.id);

    const itemData = {
      id: item.id,
      showColumn: item.shouldRender,
      title: item.title,
    };

    acc.itemsMap = acc.itemsMap.set(item.id, itemData);

    return acc;
  }, { itemsList: [], itemsMap: Map() });
}

export const updateTableSettingsReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const {
    itemsList,
    itemsMap,
  } = prepareModel(action.data);

  let newSettings;

  const { type } = state.analysis.itemsMap[caseType.getId()].layout;
  const isCaseResult = type === Type.caseResult;
  if (isCaseResult) {
    newSettings = {
      columns: {
        caseResult: {
          itemsList,
          itemsMap,
        },
      },
    };
  }

  const isAppealState = type === Type.appealState;
  if (isAppealState) {
    newSettings = {
      columns: {
        appealState: {
          itemsList,
          itemsMap,
        },
      },
    };
  }

  const settings = new AnalysisSettings();
  const layout = settings.updateSettings(state.analysis.itemsMap[caseType.getId()].layout, newSettings);

  return {
    ...state,
    analysis: {
      ...state.analysis,
      itemsMap: {
        ...state.analysis.itemsMap,
        [caseType.getId()]: {
          ...state.analysis.itemsMap[caseType.getId()],
          layout,
        },
      },
    },
  };
};

export default updateTableSettingsReduce;
