import { connect } from 'react-redux';

import { prepareColumns } from '../../../utils';

import { List } from './list';

const mapStateToProps = (state, ownProps) => {
  const {
    items,
    layout,
  } = state.searchResult.analysis.itemsMap[ownProps.id];

  const settings = layout.columns[layout.type];
  const viewColumns = prepareColumns(settings);

  return {
    columns: viewColumns,
    items: items.itemsList,
  };
};

export const ListConnect = connect(
  mapStateToProps,
)(List);

export default ListConnect;
