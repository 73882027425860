import { fetchDocumentTypeReduce } from './fetchDocumentType.reduce';

import type { ActionData } from './fetchDocumentType.reduce';

const actionType = 'SEARCH/RESULT/FETCH_DOCUMENT_TYPE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDocumentType = {
  actionType,
  createAction,
  reduce: fetchDocumentTypeReduce,
};

export default fetchDocumentType;
