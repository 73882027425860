import { connect } from 'react-redux';

import { addBlock } from '@pages/search/form/redux/addBlock';
import { duplicateBlock } from '@pages/search/form/redux/duplicateBlock';
import { removeBlock } from '@pages/search/form/redux/removeBlock';
import { Block } from '@pages/search/form/block';

import { cancelThunk } from './redux/cancel.thunk';

import { updateFiltersThunk } from '../redux/updateFilters.thunk';

const mapStateToProps = (state) => {
  const { query } = state.search;
  const items = query.blocksList.map((item) => (
    query.blocksMap.get(item)
  ));

  return {
    items,
    isSubscription: !!state.subscriptions.id,
    resetButtonTitle: 'Отмена',
    saveButtonTitle: 'Применить',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddBlock(type) {
    dispatch(addBlock.createAction({ type }));
  },
  onDuplicateBlock(data) {
    dispatch(duplicateBlock.createAction(data));
  },
  onRemoveBlock(data) {
    dispatch(removeBlock.createAction(data));
  },
  onResetForm() {
    cancelThunk(dispatch);
  },
  onCreateSearch() {
    updateFiltersThunk(dispatch);
  },
});

export const FormConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default FormConnect;
