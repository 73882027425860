import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateParticipant } from './redux/updateParticipant';
import { updateMode } from './redux/updateMode';

import { ThirdPartyPersonBlock } from './block';

const mapStateToProps = (state) => {
  const roles = state.search.presets.participantModes;

  return {
    roles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateMode(data) {
    dispatch(updateMode.createAction(data));
  },
  onUpdateParticipant(data) {
    dispatch(updateParticipant.createAction(data));
  },
});

export const ThirdPartyPersonBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThirdPartyPersonBlock);

export default ThirdPartyPersonBlockConnect;
