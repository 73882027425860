import { RESTPath } from '@router/path.rest';
import http from '@http';

import { SortType } from '../../components/sortIcon/sortIcon';

export interface GetJudgesSettings {
  searchId: number;
  courtId: string;
  filterType: string;
  valueId: string;
  sortDirection: SortType;
}

export function httpFetchSearchCourtJudges(settings: GetJudgesSettings) {
  const request = http.get({
    url: RESTPath.search.analytic.courts.getJudges(settings),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSearchCourtJudges;
