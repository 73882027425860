import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import type { Document } from '@layouts/similar/redux/state';

import s from './similarItemPromo.style';

type Id = string | number;

interface SimilarItemPromoProps {
  baseHref: string;
  documentId: Id;
  item: Document;
}

export class SimilarItemPromo extends React.PureComponent<SimilarItemPromoProps> {

  handleHrefClick = (e) => {
    sendAmplitudeData('preview: open similar from document');
    Userpilot.track('preview: open similar from document');
    e.stopPropagation();

    const href = `${this.props.baseHref}/similar/${this.props.item.id}`;
    window.open(href, '_blank');
  };

  render() {
    const title = `${this.props.item.documentType} от ${this.props.item.date}`;

    return (
      <s.Root
        key={this.props.item.id}
        onClick={this.handleHrefClick}
      >
        <s.Title>
          {title}
        </s.Title>
        <s.Description>
          {this.props.item.departmentNameGenitive}
        </s.Description>
      </s.Root>
    );
  }

}

export default SimilarItemPromo;
