import { Dispatch } from 'redux';

import { socket } from '@utils/utils';

import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

import { httpFetchSubscriptionDocuments } from './fetchDocuments.http';

export class FetchDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id?: number, pageNumber?: number }) {
    const state = this.getState().subscriptions;
    const sourceId = params.id || state.subscriptions.activeItemId;
    const pageNumber = params.pageNumber || state.documents.currentPageNumber;

    const hasPage = state.documents.pages[pageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber(pageNumber);

      return;
    }

    const requestParams = {
      sourceId,
      pageNumber,
      searchId: null,
    };

    this.dispatch(pageLoadingStart.createAction());

    try {
      const result = await httpFetchSubscriptionDocuments(requestParams);

      const channelId = result.channel_id;
      if (!channelId) {
        this.dispatchAll(requestParams, result);

        return;
      }

      await this.getThruSocket(channelId, requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async getThruSocket(channelId, requestParams) {
    const userSocket = socket.getSocket();
    if (!socket.getChannel(channelId)) {
      socket.setChannel(channelId);
      await userSocket.emit('enter', channelId);
    }

    await userSocket.once(channelId, async (message) => {
      if (message === 'done_process') {
        const result = await httpFetchSubscriptionDocuments(requestParams);

        this.dispatchAll(requestParams, result);
      }
    });
  }

  private dispatchAll(requestParams, result) {
    this.dispatchGetDocumentsSucceed(requestParams.sourceId, requestParams.pageNumber, result);
    this.dispatchChangePageNumber(requestParams.pageNumber);
  }

  private dispatchGetDocumentsSucceed(sourceId, pageNumber, result) {
    const actionData = {
      sourceId,
      pageNumber,
      result,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchDocumentsThunk(dispatch, params) {
  const thunk = new FetchDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default fetchDocumentsThunk;
