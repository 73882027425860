import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

style.Icon = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

style.Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.stone};
`;

style.Paragraph = styled.p`
  padding: 0;
  margin: 0;
`;

style.Link = styled.span`
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`;

export default style;
