import { Dispatch } from 'redux';

import http from '@http';

import { httpCreatePackage } from '@pages/packages/redux/packages/createPackage.http';

import { createPackageStart } from '@pages/packages/redux/searchPackages/createPackageStart';
import { createPackageSucceed } from '@pages/packages/redux/searchPackages/createPackageSucceed';

import { addDocumentToPackageThunk } from './addDocumentToPackage.thunk';

export class CreatePackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { query: string; documentId: number }) {
    const packageId = await this.createPackage(params.query);
    if (packageId) {
      const requestParams = {
        packageId,
        documentId: params.documentId,
      };

      addDocumentToPackageThunk(this.dispatch, requestParams);
    }
  }

  // eslint-disable-next-line consistent-return
  private async createPackage(query) {
    this.dispatchCreatePackageStart();

    try {
      const response = await httpCreatePackage(query);

      if (!response.id) {
        return null;
      }

      this.dispatchCreatePackageSucceed(response);

      return response.id;
    } catch (error) {
      if (http.isPaymentRequired(error)) {
        http.handlePaymentRequired(error);

        return null;
      }
    }
  }

  private dispatchCreatePackageStart() {
    this.dispatch(createPackageStart.createAction());
  }

  private dispatchCreatePackageSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(createPackageSucceed.createAction(actionData));
  }

}

export function createPackageThunk(dispatch, params) {
  const thunk = new CreatePackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default CreatePackageThunk;
