import { fetchFolderLastPageReduce } from './fetchFolderLastPage.reduce';

const actionType = 'REGULATION/FETCH_FOLDER__LAST_PAGE';

const createAction = () => ({
  type: actionType,
});

export const fetchFolderLastPage = {
  actionType,
  createAction,
  reduce: fetchFolderLastPageReduce,
};

export default fetchFolderLastPage;
