import { Dispatch } from 'redux';

import { filterBlocksMap } from '@pages/search/form/filterBlocks/filterBlocks.map';

import {
  fetchCourtsStart,
  fetchCourtsSucceed,
} from './fetchCourts';

import { changePageNumber } from './changePageNumber';

import {
  GetCourtsSettings,
  httpFetchSearchCourts,
} from './fetchCourts.http';

class FetchSearchCourtsThunk {

  getState;

  private readonly dispatch: Dispatch;

  private readonly shouldChangeSort: boolean;

  constructor(dispatch, shouldChangeSort) {
    this.dispatch = dispatch;
    this.shouldChangeSort = shouldChangeSort;
  }

  public async invoke(Id?: number, newPageNumber?: number) {
    const pageNumber = newPageNumber || this.getState().searchResult.courts.currentPageNumber;

    const hasPage = this.getState().searchResult.courts.pages[pageNumber];
    const hasPageWithThisSort = hasPage && !this.shouldChangeSort;
    if (hasPageWithThisSort) {
      this.dispatchChangePageNumber(pageNumber);

      return;
    }

    const {
      sortData,
      layout: { type },
    } = this.getState().searchResult.courts;

    const sortByType = filterBlocksMap.get(type).block.DTOType;

    const searchId = Id || this.getState().searchResult.id;
    const settings: GetCourtsSettings = {
      // @ts-ignore
      searchId,
      // @ts-ignore
      pageNumber,
      filterType: sortByType,
      valueId: sortData.id,
      sortDirection: sortData.type,
    };

    this.dispatchGetCourtsStart();

    try {
      const searchResult = await httpFetchSearchCourts(settings);
      this.dispatchGetCourtsSucceed(searchId, searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetCourtsStart() {
    this.dispatch(fetchCourtsStart.createAction());
  }

  private dispatchGetCourtsSucceed(searchId, searchResult) {
    const actionData = {
      searchId,
      ...searchResult,
    };

    this.dispatch(fetchCourtsSucceed.createAction(actionData));
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
  }

}

export function fetchSearchCourtsThunk(dispatch, shouldChangeSort, searchId?, pageNumber?) {
  const thunk = new FetchSearchCourtsThunk(dispatch, shouldChangeSort);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(searchId, pageNumber);
}

export default fetchSearchCourtsThunk;
