import { Dispatch } from 'redux';
import { Filters } from '@pages/search/form/filters';

import { resetToInitial } from '../../documents/redux/resetToInitial';
import { fetchDocumentsThunk } from '../../documents/redux/fetchDocuments.thunk';

import { fetchFiltersThunk } from './fetchFilters.thunk';
import { httpUpdateFilters } from './updateFilters.http';

export class UpdateFiltersThunk {

  getState;

  private dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke() {
    const {
      subscriptions,
      search,
    } = this.getState();

    try {
      const filters = new Filters();
      filters.createFilters(search.query);
      const conditions = filters.undecorate();
      const id = subscriptions.subscriptions.activeItemId;
      const response = await httpUpdateFilters({ id, conditions });

      if (response.search_id) {
        this.dispatch(resetToInitial.createAction());
      }

      fetchDocumentsThunk(this.dispatch, { });
      fetchFiltersThunk(this.dispatch, { });
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export function updateFiltersThunk(dispatch) {
  const thunk = new UpdateFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}
