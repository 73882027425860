import React from 'react';

import { Download } from '../../projects/preview/download';
import { AddProjectsConnect } from '../../projects/header/addProjects.connect';

import s from './header.style';

export interface HeaderProps {
  id: string;
  title: string;
  isList: boolean;

  onDownload(): void;
}

export class Header extends React.PureComponent<HeaderProps> {

  renderDownload() {
    return (
      <s.ActionMargin>
        <Download {...this.props} />
      </s.ActionMargin>
    );
  }

  renderAddProject() {
    return (
      <s.Action>
        <AddProjectsConnect />
      </s.Action>
    );
  }

  render() {
    return (
      <s.TopHeader>
        <s.Title>
          {this.props.title}
        </s.Title>
        <s.Actions>
          {this.renderDownload()}
          {this.renderAddProject()}
        </s.Actions>
      </s.TopHeader>
    );
  }

}

export default Header;
