import { updateTitleReduce } from './updateTitle.reduce';

import type { ActionData } from './updateTitle.reduce';

const actionType = 'REGULATION/FILTERS/UPDATE_TITLE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateTitle = {
  actionType,
  createAction,
  reduce: updateTitleReduce,
};

export default updateTitle;
