import React, { PureComponent } from 'react';

import s from './textField.style';

interface TextfieldProps {
  autofocus?: boolean;
  label: string;
  disabled: boolean;
  value: string;
  maxLength: number;
  onChange?(value: string): void;
  onFocus(): void;
  onBlur(): void;
  onTextInput(value: any): void;
  inputRef: any;
}

interface TextfieldState {
  value: string;
  height: number;
  heightAssigned: boolean;
}

export class TextField extends PureComponent<TextfieldProps, TextfieldState> {

  static defaultProps = {
    autofocus: false,
    // eslint-disable-next-line react/default-props-match-prop-types
    label: '',
    // eslint-disable-next-line react/default-props-match-prop-types
    disabled: false,
    // eslint-disable-next-line react/default-props-match-prop-types
    maxLength: undefined,
    // eslint-disable-next-line react/default-props-match-prop-types
    error: undefined,
    // eslint-disable-next-line react/default-props-match-prop-types
    onTextInput: Function.prototype,
    // eslint-disable-next-line react/default-props-match-prop-types
    onFocus: Function.prototype,
    // eslint-disable-next-line react/default-props-match-prop-types
    onBlur: Function.prototype,
    // eslint-disable-next-line react/default-props-match-prop-types
    inputRef: Function.prototype,
  };

  state = {
    value: this.props.value,
    height: 16,
    heightAssigned: false,
  };

  resizeObserver: ResizeObserver;

  /**
   * @private
   */
  visualizeDescriptor = null;

  /**
   * @private
   */
  refTextArea = null;

  /**
   * @private
   */
  refTextMeasure = null;

  /**
   * @private
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  tooltipRef = null;

  componentDidMount() {
    this.ensureTextAreaHeightOnMount();
    this.setHeightObserver();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setTextAreaText(this.props.value);
    }

    this.ensureTextAreaHeight();
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.refTextMeasure);
    clearTimeout(this.visualizeDescriptor);
  }

  private setFocus = () => {
    if (!this.props.autofocus) {
      return;
    }

    if (this.refTextArea) {
      this.refTextArea.focus();
      this.refTextArea.select();
    }

  };

  private setHeightObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      if (this.state.height !== this.refTextMeasure.clientHeight) {
        this.setState({ height: this.refTextMeasure.clientHeight });
      }
    });

    this.resizeObserver.observe(this.refTextArea);
  }

  private setTextAreaText(value) {
    this.setState({
      value,
      heightAssigned: false,
    });
  }

  private setTextAreaHeight(height) {
    this.setState({
      height,
      heightAssigned: true,
    });
  }

  private handleRefTextArea = (textarea) => {
    this.refTextArea = textarea;

    if (typeof this.props.inputRef === 'function') {
      this.props.inputRef(textarea);
    }
  };

  private handleRefTextMeasure = (div) => {
    this.refTextMeasure = div;
  };

  private handleTextAreaChange = (...args) => {
    const text = this.refTextArea.value;
    this.setTextAreaText(text);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.props.onTextInput(...args);
  };

  private handleTextAreaBlur = () => {
    this.props.onChange(this.state.value);
  };

  private handleTextAreaFocus = () => {
    this.props.onFocus();
  };

  ensureTextAreaHeightOnMount() {
    this.visualizeDescriptor = setTimeout(() => {
      this.ensureTextAreaHeight();
    }, 5);

    setTimeout(() => {
      this.setFocus();
    }, 5);

  }

  private ensureTextAreaHeight() {
    const { heightAssigned } = this.state;
    if (!heightAssigned) {
      this.assignTextAreaHeight();
    }
  }

  private assignTextAreaHeight() {
    const height = this.refTextMeasure.clientHeight + 2;
    this.setTextAreaHeight(height);
  }

  renderLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <s.Label
        shrink={!!this.state.value}
        disabled={this.props.disabled}
      >
        <s.LabelText>{this.props.label}</s.LabelText>
      </s.Label>
    );
  }

  private renderTextMeasure() {
    let { value } = this.state;

    // div eats the last line-break so it needs to have an extra one
    value = `${value}\n`;

    return (
      <s.TextMeasureWrapper>
        <s.TextMeasure ref={this.handleRefTextMeasure}>
          {value}
        </s.TextMeasure>
      </s.TextMeasureWrapper>
    );
  }

  private renderTextArea() {
    const { value, height } = this.state;
    const { disabled, maxLength } = this.props;
    const skewPadding = !!(this.props.label && value);

    return (
      <s.TextArea
        skewPadding={skewPadding}
        maxLength={maxLength}
        disabled={disabled}
        height={height}
        value={value}
        ref={this.handleRefTextArea}
        onChange={this.handleTextAreaChange}
        onFocus={this.handleTextAreaFocus}
        onBlur={this.handleTextAreaBlur}
      />
    );
  }

  render() {
    return (
      <s.Root>
        { this.renderLabel() }
        { this.renderTextMeasure() }
        { this.renderTextArea() }
      </s.Root>
    );
  }

}

export default TextField;
