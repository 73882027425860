import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './message.style';

export interface User {
  credentials: string;
  avatar: string;
}

export interface MessageProps {
  id: string | number;
  date: string;
  body: string;
  user: User;
}

export interface MessageState {
  isAvatarHeightDominant: boolean;
}

export class Message extends React.PureComponent<MessageProps, MessageState> {

  state = {
    isAvatarHeightDominant: true,
  };

  onAvatarLoad = ({ target: img }) => {
    const height = img.offsetHeight;
    const width = img.offsetWidth;

    if (height >= width) {
      this.setState({ isAvatarHeightDominant: true });
    } else {
      this.setState({ isAvatarHeightDominant: false });
    }
  };

  prepareMarkup = (body) => ({
    __html: `<div>${body}</div>`,
  });

  renderAvatar() {
    if (!this.props.user.avatar) {
      const avatarPath = iconsPath.get('avatar');

      return <s.AvatarIcon><Icon path={avatarPath} size={32} viewBoxSize={32} color="#dfe2e6" /></s.AvatarIcon>;
    }

    return <img onLoad={this.onAvatarLoad} src={this.props.user.avatar} alt="avatar" />;
  }

  renderCredentials() {
    if (!this.props.user.credentials) {
      return <s.Credentials />;
    }

    return <s.Credentials>{this.props.user.credentials}</s.Credentials>;
  }

  render() {
    return (
      <s.Root>
        <s.Photo
          isAvatarHeightDominant={this.state.isAvatarHeightDominant}
        >
          {this.renderAvatar()}
        </s.Photo>
        <s.Message>
          {this.renderCredentials()}
          <s.Date>{this.props.date}</s.Date>
          <s.Body
            dangerouslySetInnerHTML={this.prepareMarkup(this.props.body)}
          />
        </s.Message>
      </s.Root>
    );
  }

}

export default Message;
