import React from 'react';

import {
  Icon as IconComponent,
  iconsPath,
} from '@components/icons';

import s from './icon.style';

export const Icon = (props: { count: number }) => {
  const iconPath = !props.count ? iconsPath.get('commentEmpty') : iconsPath.get('commentFill');

  return (
    <s.Icon>
      <IconComponent path={iconPath} color="#6c7b89" />
    </s.Icon>
  );
};

export default Icon;
