import React from 'react';

import { Layout } from '../header/redux/state';

import { DocumentsConnect } from './documents/documents.connect';
import { BlockConnect as FiltersConnect } from './filters/block.connect';

import s from './workspace.style';

type WorkspaceProps = any;

export class Workspace extends React.PureComponent<WorkspaceProps> {

  renderContent() {
    const isDocuments = this.props.layout === Layout.documents;
    if (isDocuments) {
      return <DocumentsConnect />;
    }

    return <FiltersConnect />;
  }

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );

  }

}

export default Workspace;
