import styled from 'styled-components';

const style: any = {};

style.InputWrapper = styled.div`
  margin: 0 8px;
  width: 36px;
  display: flex;
  align-items: center;

  > input {
    flex-shrink: 0;
    padding: 4px 8px;
    text-align: center;
    border-bottom: 1px solid black;
  }
`;

style.Label = styled.span`
  padding-left: 8px;
`;

export default style;
