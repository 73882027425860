import { Dispatch } from 'redux';

import { savePackagesDocumentsSettingsSucceeded } from './saveLayout';

import { httpSaveDocumentsLayoutSettings } from './saveLayout.http';

function prepareDTO(layout) {
  const viewType = layout.viewType;
  const renderKeys = layout.tableView.columns.itemsList.map((columnId) => {
    const column = layout.tableView.columns.itemsMap.get(columnId);

    return {
      id: column.id,
      render: column.showColumn,
    };
  });

  return {
    layout: {
      viewType,
      renderKeys,
    },
  };
}

export class SaveDocumentsLayoutSettingsThunk {

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(layout) {

    const dto = prepareDTO(layout);

    try {
      await httpSaveDocumentsLayoutSettings(dto);
    } catch (error) {
      throw Error(error.status);
    }
  }

  dispatchSaveSucceeded() {
    this.dispatch(savePackagesDocumentsSettingsSucceeded.createAction());
  }

}

export function saveDocumentsLayoutSettingsThunk(dispatch) {
  const thunk = new SaveDocumentsLayoutSettingsThunk(dispatch);

  dispatch((_, getState) => {
    const { documents } = getState().packages;
    thunk.invoke(documents.layout);
  });

}
