import styled from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
  isFirst: boolean;
  disabled: boolean;
}

style.Item = styled.div`
  padding: 12px 16px 13px 12px;
  position: relative;
  user-select: none;

  display: flex;

  color: ${(props: Props) => props.theme.colors.defaultText};

  cursor: pointer;
  align-items: flex-start;

  &:hover {
    background: #f6fafe;
  }
`;

style.ItemTitle = styled.div`
  padding-left: 8px;
  padding-top: 5px;
  font-size: 13px;
`;

style.Icon = styled.div`
  display: inline;
  width: 16px;
  height: 16px;
  overflow: hidden;
  position: relative;

  > i {
    position: absolute;
    top: -4px;
    left: -4px;
  }
`;

export default style;
