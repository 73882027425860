export const prepareFiltersEndReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    query: {
      ...state.query,
      smartFilter: {
        ...state.query.smartFilter,
        preparing: false,
      },
    },
  };
};

export default prepareFiltersEndReduce;
