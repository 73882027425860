import { connect } from 'react-redux';

import { TableSettings } from './tableSettings';
import { updateTableSettings } from './redux/updateTableSettings';
import { saveCaseTypeLayoutSettingsThunk } from '../../redux/saveLayout.thunk';

function prepareColumns(itemsList, itemsMap) {
  const columns = itemsList.map((columnId) => ({
    id: columnId,
    shouldRender: itemsMap.get(columnId).showColumn,
    title: itemsMap.get(columnId).title,
  }));

  return columns;
}

const mapStateToProps = (state, ownProps) => {
  const {
    columns,
    defaultColumns,
    type,
  } = state.searchResult.analysis.itemsMap[ownProps.id].layout;

  const defaultColumnsState = defaultColumns[type];
  const itemsList = columns[type].itemsList;
  const itemsMap = columns[type].itemsMap;

  const viewColumns = prepareColumns(itemsList, itemsMap);
  const viewDefaultColumns = prepareColumns(defaultColumnsState.itemsList, defaultColumnsState.itemsMap);

  return {
    defaultColumns: viewDefaultColumns,
    columns: viewColumns,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSaveSort(data) {
    dispatch(updateTableSettings.createAction(data));
    saveCaseTypeLayoutSettingsThunk(dispatch);
  },
});

export const TableSettingsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableSettings);

export default TableSettingsConnect;
