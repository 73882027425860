import { Dispatch } from 'redux';

import { definitionContentType } from '../../redux/state';

import { fetchDefinitionContentType } from './fetchDefinitionContentType';

import { httpFetchSearchAnalyticDefinitionContentType } from './fetchDefinitionContentType.http';

export class FetchInitialSearchDefinitionContentTypeThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
    dispatch((_, getState) => { this.getState = getState; });
  }

  public async invoke(searchId: number) {
    const { shouldLoadInitialData } = this.getState().searchResult.analysis.itemsMap[definitionContentType.getId()];
    if (!shouldLoadInitialData) {
      return;
    }

    try {
      const searchResult = await httpFetchSearchAnalyticDefinitionContentType(searchId);
      this.dispatchGetTypeIdSucceed(searchId, searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetTypeIdSucceed(searchId, searchResult) {
    const actionData = {
      searchId,
      ...searchResult,
    };

    this.dispatch(fetchDefinitionContentType.createAction(actionData));
  }

}

export default FetchInitialSearchDefinitionContentTypeThunk;
