import { Map } from 'immutable';

type PackageId = number;

interface Item {
  id: PackageId;
  title: string;
  selected: boolean;
}

export class StateBuilder {

  private state: {
    itemsList: PackageId[];
    itemsMap: Map<number, Item>;
    loading: boolean;
    addingPackage: boolean;
  };

  static createState() {
    return {
      itemsList: [],
      itemsMap: Map(),
      addingPackage: false,
      loading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  addPackage(item: { id: number, name: string }) {
    const packageItem = {
      id: item.id,
      title: item.name,
      selected: false,
    };

    const itemsList = [packageItem.id, ...this.state.itemsList];
    const itemsMap = this.state.itemsMap.set(packageItem.id, packageItem);

    return {
      ...this.state,
      itemsList,
      itemsMap,
    };
  }

  toggleSelected(packageId) {
    const packageItem = this.state.itemsMap.get(packageId);
    const newPackageItem = {
      ...packageItem,
      selected: !packageItem.selected,
    };

    const itemsMap = this.state.itemsMap.set(packageId, newPackageItem);

    return {
      ...this.state,
      itemsMap,
      addingPackage: false,
    };
  }

}

export default StateBuilder;
