import { styled } from '@components/styled';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  border: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  border-bottom: none;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
  margin-top: 100px;
`;

export default style;
