import { createCompanyPackageReduce } from './createCompanyPackage.reduce';

import type { ActionData } from './createCompanyPackage.reduce';

const actionType = 'PACKAGES/CREATE_COMPANY_PACKAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const createCompanyPackage = {
  actionType,
  createAction,
  reduce: createCompanyPackageReduce,
};

export default createCompanyPackage;
