import { connect } from 'react-redux';

import { isSelected } from '@pages/searchResult/documents/list/item.connect';
import { toggleItemSelect } from '../../../redux/documents/toggleItemSelect';
import { Item } from './item';

const mapStateToProps = (state, ownProps) => {
  const {
    currentPageNumber,
    pages,
    selection,
    showCommentary,
  } = state.packages.documents;

  const item = pages[currentPageNumber].itemsMap.get(ownProps.id);

  return {
    item,
    selected: isSelected(ownProps.id, selection),
    sourceId: state.packages.packages.activeItemId,
    showCommentary,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onItemSelect(id) {
    dispatch(toggleItemSelect.createAction({ id }));
  },
});

export const ItemConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
