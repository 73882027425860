import React from 'react';

import { TextInput } from '@components/textInput';
import { InputRemoveButton } from '@components/inputRemoveButton';

import s from './field.style';

export interface FieldProps {
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  value?: string;
  // eslint-disable-next-line react/require-default-props
  onBlur?: () => void;
  // eslint-disable-next-line react/require-default-props
  onFocus?: () => void;
  // eslint-disable-next-line react/require-default-props
  onChange?: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
  onRemove?: () => void;
}

export const Field: React.FC<FieldProps> = ({
  placeholder = '',
  disabled = false,
  value = '',
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
  onRemove = () => {},
}) => {

  // @ts-ignore
  const removeButton: () => JSX.Element = function () {
    if (!value) {
      return null;
    }

    return (
      <InputRemoveButton
        onClick={onRemove}
      />
    );
  };

  return (
    <s.Wrapper>
      <TextInput
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        // @ts-ignore
        onChange={onChange}
      />
      {removeButton()}
    </s.Wrapper>
  );
};

export default TextInput;
