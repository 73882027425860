import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
`;

style.Container = styled.div`
`;

export default style;
