import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { BaseInput } from '../baseInput/baseInput';

import {
  List, ListItem,
} from '../baseOptionList/list';

import s from './select.style';

export interface SelectProps {
  useAnchorWidth: boolean;
  // eslint-disable-next-line react/require-default-props
  width?: string;
  // eslint-disable-next-line react/require-default-props
  placeholder? : string;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  activeItem: ListItem;
  list: ListItem[];
  onChange: (data: any) => void;
}

export const Select = (props: SelectProps) => (
  <s.BlockType>
    <BaseDropdown
      disabled={props.disabled}
      useAnchorWidth={props.useAnchorWidth}
      // @ts-ignore
      renderInput={(p) => <BaseInput value={props.activeItem.title} {...p} />}
      renderMenu={
        // @ts-ignore
        (p) => (
          <List
            // @ts-ignore
            width={props.width}
            activeItem={props.activeItem}
            items={props.list}
            onChange={props.onChange}
            {...p}
          />
        )
      }
    />
  </s.BlockType>
);

export default Select;
