import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { iconDragStyle } from './iconDrag.style';

const path = iconsPath.get('dragKebab');

export const IconDrag = () => (
  <iconDragStyle.Root>
    <iconDragStyle.Icon>
      <Icon path={path} color="currentColor" />
    </iconDragStyle.Icon>
  </iconDragStyle.Root>
);

export default IconDrag;
