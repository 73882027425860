import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Slider } from './slider';

import { inputValues } from './inputValues';

import { NumberField } from '../../components/numberField/numberField';

import s from './sliderComponent.style';

const valueMode = {
  FROM: 'from',
  TO: 'to',
};

export class SliderComponent extends React.PureComponent {

  // eslint-disable-next-line class-methods-use-this
  // eslint-disable-next-line consistent-return
  convertInputValueFromToSliderValue(value) {
    const hasValue = value !== null && value.toString().length !== 0;
    if (hasValue) {
      if (inputValues[value]) {
        return inputValues[value];
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const prop in inputValues) {
        if (Number(value) < Number(prop)) {
          return inputValues[prop];
        }
      }

    } else {
      return inputValues[0];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  // eslint-disable-next-line consistent-return
  convertInputValueToToSliderValue(value) {
    const hasValue = value !== null && value.toString().length !== 0;
    if (hasValue) {
      if (inputValues[value]) {
        return inputValues[value];
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const prop in inputValues) {
        if (Number(value) < Number(prop)) {
          return inputValues[prop];
        }
      }

    } else {
      return inputValues[2000000000000];
    }
  }

  // eslint-disable-next-line react/sort-comp
  getValueWithoutSpaces = (str: any) => {
    let value = str;

    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    if (this.props.withSpaces) {
      value = value.replace(/\D/g, '');
    }

    return value;
  };

  getValueWithSpaces = (value) => {
    if (!value) {
      return '';
    }

    const valueWithSpaces = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return valueWithSpaces;
  };

  handleChangeFromValue = (valueOfFromField) => {
    const value = this.getValueWithoutSpaces(valueOfFromField);

    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    this.props.onChangeFromValue(value);
  };

  renderValue = (mode) => {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    let value = mode === valueMode.FROM ? this.props.value[0] : this.props.value[1];
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    if (this.props.withSpaces) {
      value = this.getValueWithSpaces(value);
    }

    return value;
  };

  renderFrom() {
    return (
      <s.NumberInput>
        <NumberField
          placeholder="От"
          value={this.renderValue(valueMode.FROM)}
          onChange={this.handleChangeFromValue}
        />
      </s.NumberInput>
    );
  }

  handleChangeToValue = (valueOfToField) => {
    const value = this.getValueWithoutSpaces(valueOfToField);

    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    this.props.onChangeToValue(value);
  };

  renderTo() {
    return (
      <s.NumberInput>
        <NumberField
          placeholder="До"
          value={this.renderValue(valueMode.TO)}
          onChange={this.handleChangeToValue}
        />
      </s.NumberInput>
    );
  }

  renderInput() {
    return (
      <s.SliderInputContainer>
        {this.renderFrom()}
        {this.renderTo()}
      </s.SliderInputContainer>
    );
  }

  render() {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const valueFrom = this.convertInputValueFromToSliderValue(this.props.value[0]);
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const valueTo = this.convertInputValueToToSliderValue(this.props.value[1]);
    const value = [valueFrom, valueTo];

    return (
      <s.SliderComponent>
        <BaseDropdown
          // @ts-ignore
          // eslint-disable-next-line react/prop-types
          disabled={this.props.disabled}
          useAnchorWidth
          renderInput={() => (this.renderInput())}
          // @ts-ignore
          // eslint-disable-next-line react/prop-types
          renderMenu={(p) => <Slider value={value} onChange={this.props.onChangeSliderValue} {...p} />}
        />
      </s.SliderComponent>
    );
  }

}

export default SliderComponent;
