import {
  RESTPath,
  FetchLawDocumentEditions,
} from '@router/path.rest';

import http from '@http';

export function httpFetchEditions(requestParams: FetchLawDocumentEditions) {
  const request = http.get({
    url: RESTPath.lawDocument.getEditions(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchEditions;
