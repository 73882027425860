import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Document } from '@layouts/similar/redux/state';

import { Loader } from '@components/loader';
import { EmptyFolder } from '@components/emptyFolder';

import { SimilarPaginationConnect } from './similarPagination.connect';
import { HeaderConnect } from './components/header/similar/header.connect';
import { ListConnect } from './components/list/similar/list.connect';

import s from './similarTab.style';

interface SimilarTabProps {
  loading: boolean;
  pages: Record<number, { items: number[]; itemsMap: Record<number, Document> }>
  pageLoading: boolean;
  currentPageNumber: number;
  baseHref: string;
  documentId: string | number;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

export class SimilarTab extends React.PureComponent<SimilarTabProps> {

  componentDidMount() {
    sendAmplitudeData('preview: open similar tab');
    Userpilot.track('preview: open similar tab');
  }

  componentDidUpdate(prevProps: Readonly<SimilarTabProps>) {
    if (prevProps.currentPageNumber !== this.props.currentPageNumber) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    this.props.wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }

  renderPageLoader() {
    if (!this.props.pageLoading) {
      return null;
    }

    return (<s.PageLoader />);
  }

  renderList() {
    return (
      <ListConnect
        documentId={this.props.documentId}
        baseHref={this.props.baseHref}
      />
    );
  }

  renderLayout = () => {
    const noPages = isEmpty(this.props.pages);
    const emptyPage = isEmpty(this.props.pages[1].items);
    const noDocuments = !this.props.pageLoading && (noPages || emptyPage);
    if (noDocuments) {
      return <EmptyFolder title="Нет похожих документов" />;
    }

    return (
      <s.ContentContainer>
        <HeaderConnect />
        <s.ItemsContent>
          {this.renderPageLoader()}
          {this.renderList()}
        </s.ItemsContent>
        <SimilarPaginationConnect />
      </s.ContentContainer>
    );
  };

  render() {
    if (this.props.loading) {
      return <Loader />;
    }

    return this.renderLayout();
  }

}

export default SimilarTab;
