import http from '@http';
import { RESTPath } from '@router/path.rest';

interface RequestParams {
  id: string;
}

export function httpDeleteBookmark(requestParams: RequestParams) {
  const request = http.delete({
    url: RESTPath.bookmarks.deleteItem(requestParams),
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpDeleteBookmark;
