import React from 'react';
import { Route } from 'react-router-dom';

import { NotFound } from './404';

export const RouteNotFound = (
  <Route
    component={NotFound}
  />
);

export default RouteNotFound;
