import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export default style;
