import { ReducerBuilder } from '@redux/reducer.builder';

import { increasePackageCount } from './increasePackageCount';
import { decreasePackageCount } from './decreasePackageCount';
import { setTag } from './setTag';

export const reducerBuilder = new ReducerBuilder([
  increasePackageCount,
  decreasePackageCount,
  setTag,
]);

export default reducerBuilder;
