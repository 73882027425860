import { RESTPath } from '@router/path.rest';
import http from '@http';

interface RequestParams {
  text: string;
}

export function httpSaveFirstname(data: RequestParams) {
  const request = http.post({
    url: RESTPath.user.saveFirstname,
    data,
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpSaveFirstname;
