import { setSourceReduce } from './setSource.reduce';

import type { ActionData } from './setSource.reduce';

const actionType = 'DOCUMENT/SET_SOURCE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setSource = {
  actionType,
  createAction,
  reduce: setSourceReduce,
};

export default setSource;
