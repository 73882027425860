import { connect } from 'react-redux';

import { SummaryContent } from './summaryContent';

function prepareDocumentsCase(documentsCase) {

  if (!documentsCase) {
    return null;
  }

  const preparedDocuments = documentsCase.map((document) => (
    `${document.title} от ${document.date}: ${document.briefSummary}`
  ));

  return preparedDocuments;
}

function prepareRegulation(regulation) {

  if (!regulation) {
    return null;
  }

  const preparedRegulation = regulation.map((document) => (
    `${document.title} - ${document.applicabilityCase}`
  ));

  return preparedRegulation;
}

const mapStateToProps = (state, ownProps) => {
  const summary = state.summary.items.get(ownProps.id);

  const briefDescription = summary.briefDescription;
  const claims = summary.claims;
  const courtDecision = summary.courtDecision;
  const courtMotivation = summary.courtMotivation;
  const courtPosition = summary.courtPosition;
  const disputeSubject = summary.disputeSubject;
  const documentsCase = prepareDocumentsCase(summary.documentsCase);
  const parties = summary.parties;
  const regulation = prepareRegulation(summary.regulation);

  return {
    briefDescription,
    claims,
    courtDecision,
    courtMotivation,
    courtPosition,
    disputeSubject,
    documentsCase,
    parties,
    regulation,
  };
};

export const SummaryContentConnect = connect(
  mapStateToProps,
)(SummaryContent);

export default SummaryContentConnect;
