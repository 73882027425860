import { fetchMarkersReduce } from '@pages/searchResult/documents/preview/redux/fetchMarkers.reduce';

import type { ActionData } from '@pages/searchResult/documents/preview/redux/fetchMarkers.reduce';

const actionType = 'PACKAGE/DOCUMENTS/PREVIEW_SAVE_MARKERS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const saveMarkers = {
  actionType,
  createAction,
  reduce: fetchMarkersReduce,
};

export default saveMarkers;
