import React from 'react';

import { ProximityRangeField } from './proximityRangeField';

import s from './proximityRangeLabel.style';

interface ProximityRangeLabelProps {
  proximityRange: number
  title: string
  onUpdateProximityRange: (proximityRange: number) => void
}

export const ProximityRangeLabel = (props: ProximityRangeLabelProps) => (
  <s.Title>
    {props.title}
    <ProximityRangeField
      proximityRange={props.proximityRange}
      onUpdateProximityRange={props.onUpdateProximityRange}
    />
  </s.Title>
);

export default ProximityRangeLabel;
