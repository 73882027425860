import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

type Value = string;
type FindIn = 'document' | 'resolution';
type Mode = 'default' | 'paragraph' | 'sentence' | 'proximity';
type ProximityRange = number;

export interface TextBlockData {
  value: Value;
  findIn: FindIn;
  mode: Mode;
  proximityRange: ProximityRange;
}

export class TextBlock extends AbstractFilterBlock {

  static type = 'text';

  static DTOType = 'text';

  static blockTitle = 'Текст';

  static category = category.document;

  static presets = {
    findIn: {
      document: {
        id: 'document',
        title: 'Во всем документе',
      },
      resolution: {
        id: 'resolution',
        title: 'В резолютивной части',
      },
    },
    mode: {
      default: {
        id: 'default',
        title: 'Без ограничения области',
      },
      exactly: {
        id: 'exactly',
        title: 'Точное совпадение',
      },
      paragraph: {
        id: 'paragraph',
        title: 'В пределах абзаца',
      },
      proximity: {
        id: 'proximity',
        title: 'В пределах',
      },
      sentence: {
        id: 'sentence',
        title: 'В пределах предложения',
      },
    },
  };

  protected defaultData: TextBlockData = {
    value: '',
    findIn: TextBlock.presets.findIn.document.id as FindIn,
    mode: TextBlock.presets.mode.exactly.id as Mode,
    proximityRange: 3,
  };

  constructor() {
    super();

    this.setCategory(TextBlock.category);
    this.setType({
      id: TextBlock.type,
      DTOType: TextBlock.DTOType,
      title: TextBlock.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const block = {
      id,
      data,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    let data = this.getData();
    if (!data) {
      // @ts-ignore
      data = null;
    }

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: TextBlock.DTOType,
      data,
    };
  }

  // eslint-disable-next-line
  public validate() {}

  getItemsForSuggest(): Promise<any> {
    return Promise.resolve(undefined);
  }

}

export default TextBlock;
