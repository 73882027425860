import { filterBlocksMap } from './filterBlocks/filterBlocks.map';

import { AbstractFilterBlock } from './filterBlocks/abstract';

export function filterCreator(block: AbstractFilterBlock) {
  // @ts-ignore
  return filterBlocksMap.get(block.type).block;
}

export default filterCreator;
