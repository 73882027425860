import { connect } from 'react-redux';

import { History } from './block';
import { SearchHistory } from './searchHistory';
import { fetchSearchHistoryThunk } from './redux/fetchHistory.thunk';
import { removeHistoryItemThunk } from './redux/removeHistoryItem.thunk';
import { selectHistoryItem } from './redux/selectHistoryItem';
import { resetToInitial } from './redux/resetToInitial';

const mapStateToProps = (state) => {
  const historyFromState = [...state.search.history.data];
  const presetsFromState = { ...state.search.presets };

  const searchHistory = new SearchHistory();
  searchHistory.transform(historyFromState, presetsFromState);
  const data = searchHistory.getHistory();

  const history = {
    ...state.search.history,
    data,
  };

  return {
    history,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchSearchHistory() {
    fetchSearchHistoryThunk(dispatch);
  },
  onRemoveHistoryItem(id) {
    removeHistoryItemThunk(dispatch, id);
  },
  onSelectHistoryItem(data) {
    dispatch(selectHistoryItem.createAction(data));
  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
  },
});

export const HistoryConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(History);

export default HistoryConnect;
