import React from 'react';

import { Loader } from '@components/loader';
import { AttributeField } from '@components/attributeField/attributeField';
import { OpenButton } from '@components/buttons';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { ProjectInformation } from '../../redux/state';

import s from './attributesTab.style';

type AttributesTabProps = ProjectInformation & { loading?: boolean };

export const AttributesTab = (props: AttributesTabProps) => {

  const handleClick = () => {
    const projectName = props.title;

    sendAmplitudeData('regulation:go_to_source', projectName);
    Userpilot.track('regulation:go_to_source');
  };

  if (props.loading) {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  return (
    <s.Root>
      <AttributeField
        placeholder="Номер"
        value={props.projectNumber}
      />
      <AttributeField
        placeholder="Дата начала"
        value={props.startDate}
      />
      <AttributeField
        placeholder="Наименование"
        value={props.title}
      />
      <AttributeField
        placeholder="Разработчик"
        value={props.author}
      />
      <AttributeField
        placeholder="Тип проекта"
        value={props.type}
      />
      <AttributeField
        placeholder="Вид экономической деятельности"
        value={props.branchOfLegislation}
      />
      <s.Link>
        <AttributeField
          placeholder="Источник"
          value={props.source.title}
        />
        <OpenButton
          href={props.source.link}
          onClick={handleClick}
        />
      </s.Link>
    </s.Root>
  );
};

export default AttributesTab;
