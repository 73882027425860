import React from 'react';

import { RoutePath } from '@router/path';

import { Button } from '@pages/searchResult/components/button/button';

import {
  MenuButton,
  Breadcrumbs,
} from '@components/header';

import { Bookmarks } from '@layouts/bookmarks/components/bookmarks';

import { SettingsConnect } from './settings.connect';

import s from './header.style';

interface Path {
  id: number;
  title: string;
  route: string;
  loading: boolean;
}

interface HeaderProps {
  path: Path[];
  isReal: boolean;
}

export class Header extends React.PureComponent<HeaderProps> {

  hanleCLickOnAuth = () => {
    window.location.href = `#${RoutePath.login}`;
  };

  handleClickOnRequest = () => {
    window.location.href = `#${RoutePath.landing}?open=1`;
  };

  renderMenu() {
    if (!this.props.isReal) {
      return null;
    }

    return (
      <s.Menu>
        <MenuButton />
      </s.Menu>
    );
  }

  renderUserActions() {
    if (this.props.isReal) {
      return null;
    }

    return (
      <s.Actions>
        <Button
          title="Войти"
          color="#6c7b89"
          onClick={this.hanleCLickOnAuth}
        />
        <Button
          title="Получить доступ"
          onClick={this.handleClickOnRequest}
        />
      </s.Actions>
    );
  }

  renderBookmarks() {
    if (!this.props.isReal) {
      return null;
    }

    return (
      <s.Action>
        <Bookmarks />
      </s.Action>
    );
  }

  renderActions() {
    return (
      <s.Actions>
        {this.renderUserActions()}
        {this.renderBookmarks()}
        <s.Action>
          <SettingsConnect />
        </s.Action>
      </s.Actions>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderMenu()}
        <s.Breadcrumbs>
          <Breadcrumbs breadcrumbs={this.props.path} />
        </s.Breadcrumbs>
        {this.renderActions()}
      </s.Root>
    );
  }

}

export default Header;
