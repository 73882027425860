import React from 'react';

import { Loader } from '@components/loader';
import { SearchField } from '@components/searchField/searchField';

import { ItemsConnect } from './items.connect';

import s from './sidebar.style';

interface SidebarProps {
  loading: boolean;
  hasSubscriptions: boolean;
}

interface State {
  searchQuery: string;
}

export class Sidebar extends React.PureComponent<SidebarProps, State> {

  private placeholder = 'Поиск';

  state = {
    searchQuery: '',
  };

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: e.target.value,
    });
  };

  renderLoader() {
    return (
      <s.Root>
        <s.Loader>
          <Loader size={32} />
        </s.Loader>
      </s.Root>
    );
  }

  renderSearchField = () => (
    <SearchField
      disabled={!this.props.hasSubscriptions}
      value={this.state.searchQuery}
      placeholder={this.placeholder}
      onChange={this.handleSearchQuery}
    />
  );

  renderItems() {
    return (
      <>
        {this.renderSearchField()}
        <ItemsConnect
          query={this.state.searchQuery}
        />
      </>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return this.renderItems();
  }

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default Sidebar;
