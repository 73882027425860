import { connect } from 'react-redux';
import { changeRoleThunk } from '../redux/users/changeRole.thunk';
import { RoleId } from '../redux/users/state';

import { User } from './user';

const mapStateToProps = (state, ownProps) => {
  const { itemsMap } = state.regulation.users;

  const item = itemsMap.get(ownProps.id);

  return {
    ...item,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeRole(roleId: RoleId) {
    const data = {
      folderId: ownProps.folderId,
      userId: ownProps.id,
      roleId,
    };

    changeRoleThunk(dispatch, data);
  },
});

export const UserConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(User);

export default UserConnect;
