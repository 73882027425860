import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateRepresentative } from './redux/updateRepresentative';

import { RepresentativeBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateRepresentative(data) {
    dispatch(updateRepresentative.createAction(data));
  },
});

export const RepresentativeBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepresentativeBlock);

export default RepresentativeBlockConnect;
