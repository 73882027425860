import { toggleLayoutTypeReduce } from './toggleLayoutType.reduce';

const actionType = 'SEARCH/RESULT/DOCUMENTS/SETTINGS/TOGGLE_LAYOUT';

const createAction = () => ({
  type: actionType,
});

export const toggleLayoutType = {
  actionType,
  createAction,
  reduce: toggleLayoutTypeReduce,
};

export default toggleLayoutType;
