import styled from 'styled-components';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
}

const style: any = {};

style.Root = styled.div`
  font: 11px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.blue};
  display: flex;
  margin-right: 16px;
  :hover {
    border-radius: 2px;
    background-color:rgba(65, 131, 215, 0.07);
    text-decoration: underline;
  }
`;

export default style;
