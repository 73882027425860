import { updateFiltersReduce } from './updateFilters.reduce';

import type { ActionData } from './updateFilters.reduce';

const actionType = 'REGULATION/UPDATE_FILTERS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateFilters = {
  actionType,
  createAction,
  reduce: updateFiltersReduce,
};

export default updateFilters;
