import { StateBuilder } from '../documents/redux/state';
import type { State as DocumentsState } from '../documents/redux/state';

import { buildCourtsState } from '../courts/redux/state';
import type { CourtsState } from '../courts/redux/state';

import { buildAnalysisState } from '../analysis/redux/state';
import type { AnalysisState } from '../analysis/redux/state';

import { buildLawState } from '../law/redux/state';
import type { LawState } from '../law/redux/state';

export interface Document {
  id: string;
  body: string;
}

export interface Resolution {
  id: string;
  body: string;
}

export type DocumentId = number;

export type Id = string;

export type Items = Id[];

export type SearchId = number;
export enum ProcessingStatus {
  none = 'NONE',
  processing = 'PROCESSING',
  done = 'DONE',
}

export interface Subscription {
  id: number;
  process: boolean;
}

export interface SearchResultState {
  id: SearchId;
  subscription: Subscription;
  processingStatus: ProcessingStatus;
  documents: DocumentsState;
  courts: CourtsState;
  law: LawState;
  analysis: AnalysisState;
}

export const initial = (): SearchResultState => {

  const state = {} as SearchResultState;

  // @ts-ignore
  state.id = null;

  state.processingStatus = ProcessingStatus.none;
  state.documents = StateBuilder.createState();
  state.courts = buildCourtsState();
  state.law = buildLawState();
  state.analysis = buildAnalysisState();

  return state;
};

export default initial();
