import styled from 'styled-components';

import { adaptive } from '@pages/document/workspace/workspace.style';

const style: any = {};

style.Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  margin: 0 auto;

  min-width: 448px;

  ${adaptive()}
`;

style.View = styled.div``;

style.Loader = styled.div`
  height: 100%;
  position: relative;
`;

style.Header = styled.div`
  font-family: Roboto-Bold;
  text-align: center;
  padding: 3px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.Crest = styled.div`
  width: 72px;
  height: 72px;
`;

style.Title = styled.h4`
  padding-top: 9px;
  font-family: Roboto-Regular;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
`;

style.Type = styled.h5`
  padding-top: 9px;
  font-family: Roboto-Bold;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
`;

style.Ribbon = styled.div`
  height: 115px;
  width: 115px;
  position: absolute;
  right: -8px;
  top: -8px;
  overflow: hidden;
  z-index: 1;
`;

style.RibbonInside = styled.div`
  background: #ff6400;
  color: #FFF;
  transform: rotate(45deg);
  position: absolute;
  right: -29px;
  top: 16px;
  padding: 4px;
  min-width: 117px;
  text-align: center;
  font: 13px Roboto-Bold;

  &:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #cc6929;
    position: absolute;
    left: 3px;
    right: 0;
    bottom: 0;
    content: "";
    top: 21px;
    transform: rotate(-45deg);
  }

  &:after {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 8px solid #cc6929;
    border-bottom: 8px solid transparent;
    position: absolute;
    left: 104px;
    right: 0;
    top: 17px;
    bottom: 0;
    content: "";
    transform: rotate(-45deg);
  }

`;

export default style;
