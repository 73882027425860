import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpUpdateFolderTitle(requestParams: { id: string, name: string }) {
  const request = http.put({
    url: RESTPath.regulation.folders.work(requestParams),
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUpdateFolderTitle;
