import { Dispatch } from 'redux';

import { addBlock } from '@pages/search/form/redux/addBlock';

import { Type } from './settings';

class AddLawBlockThunk {

  getState;

  private readonly dispatch: Dispatch;

  // @ts-ignore
  private extendBlock: Map<string, any>;

  constructor(dispatch) {
    this.dispatch = dispatch;

    this.buildExtendBlockMap();
  }

  public invoke(data) {
    this.addLawBlock(data);

    const { type } = this.getState().searchResult.law.layout;

    const isApplicable = data.columnId !== 'all';
    if (isApplicable) {
      this.addExtendBlock({ type, id: data.columnId });
    }

    this.redirectToSearch();
  }

  private buildExtendBlockMap() {
    this.extendBlock = new Map();
    this.extendBlock.set(Type.caseResult, addBlock.createAction({ type: Type.caseResult }));
    this.extendBlock.set(Type.appealState, addBlock.createAction({ type: Type.appealState }));
  }

  private addLawBlock(data) {
    const defaultData = {
      document: {
        id: data.document.id || null,
        title: data.document.title || '',
        hasChildren: !!data.document.hasArticles,
      },
      article: {
        id: null,
        title: '',
        hasChildren: !!data.document.hasClauses,
      },
      clause: {
        id: null,
        title: '',
      },
    };

    const isDocument = !data.children;
    if (isDocument) {
      this.dispatch(addBlock.createAction({ type: 'law', defaultData }));

      return;
    }

    const isArticle = data.children.type === 'article';
    if (isArticle) {
      // @ts-ignore
      defaultData.article = this.buildArticle(data.children);
    }

    const isClause = data.children.type === 'clause';
    if (isClause) {
      // @ts-ignore
      defaultData.clause = this.buildClause(data.children);
    }

    this.dispatch(addBlock.createAction({ type: 'law', defaultData }));
  }

  private buildArticle(data) {
    const article = {
      id: '',
      title: '',
      hasChildren: false,
    };

    if (data.id) {
      article.id = data.id;
    }

    if (data.title) {
      article.title = data.title;
    }

    if (data.hasClauses) {
      article.hasChildren = data.hasClauses;
    }

    return article;
  }

  private buildClause(data) {
    const clause = {
      id: '',
      title: '',
    };

    if (data.id) {
      clause.id = data.id;
    }

    if (data.title) {
      clause.title = data.title;
    }

    return clause;
  }

  private addExtendBlock(config) {
    const presets = this.getState().search.presets[config.type];
    const defaultData = presets.find((item) => item.id === config.id);
    const action = {
      ...this.extendBlock.get(config.type),
      data: {
        ...this.extendBlock.get(config.type).data,
        defaultData,
      },
    };

    this.dispatch(action);
  }

  private redirectToSearch() {
    window.location.href = '#/search/?no_fetch_filters=true';
  }

}

export function addLawBlockThunk(dispatch, data) {
  const thunk = new AddLawBlockThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(data);
}

export default addLawBlockThunk;
