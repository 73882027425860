import { connect } from 'react-redux';

import { SpectractorsTab } from './spectractorsTab';

import { getPreview } from './preview.connect';

const mapStateToProps = (state, ownProps) => {

  const preview = getPreview(ownProps.id, state.regulation.projects.projects);

  return {
    items: preview.spectractors.items,
    loading: preview.spectractors.loading,
  };
};

export const SpectractorsTabConnect = connect(
  mapStateToProps,
)(SpectractorsTab);

export default SpectractorsTabConnect;
