import { connect } from 'react-redux';

import { Icon } from './icon';

const mapStateToProps = (state, ownProps) => {
  const folder = state.regulation.projects.projects.itemsMap.get(ownProps.id);
  const count = folder.commentsCount;

  return {
    count,
  };
};

export const CommentConnect = connect(
  mapStateToProps,
)(Icon);

export default CommentConnect;
