import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { fetchDefaultFiltersThunk } from '@pages/search/form/redux/fetchDefaultFilters.thunk';

import { resetToInitialThunk } from './redux/resetToInitial.thunk';

import { fetchPackagesThunk } from './redux/packages/fetchPackages.thunk';
import { fetchPackageDocumentsSettingsThunk } from './redux/documents/fetchSettings.thunk';

import { Packages } from './packages';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    ownProps.onBootstrap();
    const { packageId } = getParamsFromUrl(ownProps);

    fetchPackageDocumentsSettingsThunk(dispatch);
    fetchPackagesThunk(dispatch, { id: packageId });
    fetchDefaultFiltersThunk(dispatch, { whithPinned: false });
  },
  onResetToInitial() {
    resetToInitialThunk(dispatch);
  },
});

export const PackagesConnect = connect(
  null,
  mapDispatchToProps,
)(Packages);

export default PackagesConnect;
