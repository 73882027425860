import { fetchChronologyDocumentsReduce } from './fetchChronologyDocuments.reduce';

import type { ActionData } from './fetchChronologyDocuments.reduce';

const actionType = 'CHRONOLOGY/DOCUMENTS/FETCH';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchChronologyDocuments = {
  actionType,
  createAction,
  reduce: fetchChronologyDocumentsReduce,
};

export default fetchChronologyDocuments;
