import { dispatchRemovingStatusReduce } from './dispatchRemovingStatus.reduce';

export const actionType = 'SEARCH/HISTORY/DISPATCH_REMOVING_STATUS_OF_ELEMENT';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const dispatchRemovingStatus = {
  actionType,
  createAction,
  reduce: dispatchRemovingStatusReduce,
};

export default dispatchRemovingStatus;
