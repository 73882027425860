import { connect } from 'react-redux';

import { Header } from './header';

const mapStateToProps = (state) => {
  const {
    totalDocuments,
  } = state.similar;

  return {
    totalDocuments,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
