import React from 'react';

import {
  SaveButton,
  ResetButton,
} from '@components/buttons';

import { Header } from '@components/popoverHeader';

import field from '@components/field/field.style';

import { Value as ChangeValue } from '@pages/packages/packageMenu/components/input';

import { TextField } from './textField';

import s from './createPersonalPackageMenu.style';

type Message = string;

interface MenuProps {
  onGoBack: () => void;
  onSend(message: Message): void;
}

interface Error {
  title: string;
}

interface MenuState {
  title: Message;
  error: Error;
}

export class CreatePersonalPackageMenu extends React.PureComponent<MenuProps, MenuState> {

  state = {
    title: '',
    error: {
      title: null,
    },
  };

  componentWillUnmount() {
    this.props.onGoBack();
  }

  setTitle = (title: Message) => {
    this.setState({
      title,
    });
  };

  setError(title: string) {
    this.setState({
      error: {
        title,
      },
    });
  }

  handleSave = () => {
    const oldTitleLength = this.state.title.trim().length;
    const shouldUploadTitle = oldTitleLength > 0 && oldTitleLength < 1000;

    if (!oldTitleLength) {
      this.setError('Поле не может быть пустым');
    } else {
      this.resetError();
    }

    if (!shouldUploadTitle) {
      return;
    }

    this.props.onGoBack();

    this.props.onSend(this.state.title);
  };

  handleCancel = () => {
    this.resetError();
    this.props.onGoBack();
  };

  handleInputChange = (value: ChangeValue): void => {
    if (value) {
      this.resetError();
    }

    this.setTitle(value);
  };

  resetError = () => {
    this.setState({
      error: {
        title: null,
      },
    });
  };

  renderHeader() {
    const title = 'Соберем практику за вас';

    return (
      <Header
        onGoBack={this.props.onGoBack}
        title={title}
      />
    );
  }

  renderAnnotation() {
    return (
      <s.Annotation>
        <p>
          Подберем практику под вашу ситуацию за 48 часов! Вам доступно три запроса ежемесячно.
        </p>
        <p>
          Чтобы воспользоваться услугой:
        </p>
        <ol>
          <li>
            Подробно опишите ситуацию
          </li>
          <li>
            Отправьте запрос
          </li>
        </ol>
        <p>
          Профессиональный юрист соберет документы в течение 1-2 рабочих дней.
          Готовая подборка появится во вкладке «Мои подборки» и на почте.
        </p>
      </s.Annotation>
    );
  }

  renderTextArea() {
    return (
      <s.TextArea>
        <TextField
          autofocus
          label="Описание ситуации"
          value={this.state.title}
          height={159}
          maxLength={600}
          onChange={this.handleInputChange}
          onTextInput={this.resetError}
        />
      </s.TextArea>
    );
  }

  renderResetButton() {
    return (
      <s.CancelButton>
        <ResetButton onClick={this.handleCancel} />
      </s.CancelButton>
    );
  }

  renderSendButton() {
    return (
      <SaveButton
        title="Отправить"
        onClick={this.handleSave}
      />
    );
  }

  renderError() {
    const message = this.state.error.title;
    if (!message) {
      return null;
    }

    return (
      <field.Tooltip>
        <field.TooltipContainer>
          {message}
        </field.TooltipContainer>
      </field.Tooltip>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderHeader()}
        {this.renderAnnotation()}
        <s.Fieldset>
          {this.renderError()}
          {this.renderTextArea()}
        </s.Fieldset>
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSendButton()}
        </s.Actions>
      </s.Root>
    );
  }

}

export default CreatePersonalPackageMenu;
