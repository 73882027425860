import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { Loader } from '@components/loader';
import { NotFound } from '@components/notFound';

import { Header as MainHeader } from '../components/header/header';

import { HeaderConnect } from './header/header.connect';

import { ItemConnect } from './list/item.connect';

import type {
  Items,
  SearchId,
} from '../redux/state';

import s from './law.style';

interface Props {
  id: SearchId;
  items: Items;
  initialLoading: boolean;
  onFetchDocuments: () => void;
  onFetchSettings: () => void;
  onResetToDefaultSort: () => void;
  onBootstrap(): void;
  shouldLoadDocuments: boolean;
}

export class Law extends React.PureComponent<Props> {

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('results', { tab: 'law' });
    Userpilot.track('results', { tab: 'law' });
    this.props.onResetToDefaultSort();
    this.props.onFetchSettings();
    this.fetchDocuments();
    this.props.onBootstrap();
  }

  fetchDocuments() {
    if (this.props.shouldLoadDocuments) {
      this.props.onFetchDocuments();
    }
  }

  renderItem(item) {
    return (
      <ItemConnect
        key={item}
        id={item}
      />
    );
  }

  renderContent() {
    if (this.props.initialLoading) {
      return <Loader />;
    }

    const isEmpty = !this.props.items.length;
    if (isEmpty) {
      return <NotFound />;
    }

    return (
      <s.Content>
        <s.ContentContainer>
          <HeaderConnect>
            {this.props.items.map(this.renderItem)}
          </HeaderConnect>
        </s.ContentContainer>
      </s.Content>
    );
  }

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <MainHeader sourceId={this.props.id} />
          {this.renderContent()}
        </s.Root>
      </WithHeaderNotification>
    );
  }

}

export default Law;
