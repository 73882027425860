import React, { useState } from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { isActive } from '@components/staticSidebar/menu.items';

import { RoutePath } from '@router/path';

import { Settings } from './settings';

import s from './profileButton.style';

export const ProfileButton = (props: { title: string; }): JSX.Element => {

  const [menuVisible, setMenuVisible] = useState(false);

  const iconPath = iconsPath.get('userProfile');

  const handleSettingsMenuVisible = (visible: boolean) => {
    setMenuVisible(visible);
  };

  const title = props.title || 'Профиль пользователя';

  return (
    <s.Item
      to={RoutePath.profile}
      isActive={isActive(/profile/)}
    >
      <s.ItemIcon>
        <Icon path={iconPath} color="white" />
      </s.ItemIcon>
      <s.TextContainer>
        <s.ItemText>{title}</s.ItemText>
      </s.TextContainer>
      <s.MoreVisibility
        visible={menuVisible}
      >
        <Settings onMenuVisible={handleSettingsMenuVisible} />
      </s.MoreVisibility>
    </s.Item>
  );
};

export default ProfileButton;
