interface Calendar {
  items: Record<string, number>;
  loading: boolean;
}

interface EventInfo {
  date: string;
  dateEnd: string;
  title: string;
  eventTitle: string;
  projectTitle: string;
}

interface Folder {
  id: number;
  title: string;
}

export interface Event {
  projectId: number;
  event: EventInfo;
  folders: Folder[];
}

interface ActiveDate {
  year: number;
  month: number;
  day: number;
}

interface State {
  activeDate: ActiveDate;
  nearestActiveDate: ActiveDate;
  hasProjects: boolean;
  calendars: Record<string, Calendar>;
  events: Event[];
  eventsLoading: boolean;
  calendarsLoading: boolean;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      activeDate: {
        year: null,
        month: null,
        day: null,
      },
      nearestActiveDate: {
        year: null,
        month: null,
        day: null,
      },
      hasProjects: false,
      calendars: {},
      events: [],
      eventsLoading: true,
      calendarsLoading: true,
    };
  }

  constructor(state: State) {
    this.state = state;
  }

  buildEvent(event): Event {
    return {
      projectId: event.projectId,
      event: event.event,
      folders: event.folders,
    };
  }

  public addEvents(events: Event[]): void {
    this.state = {
      ...this.state,
      events: events.map((item) => this.buildEvent(item)),
    };
  }

  public resetEvents(): void {
    this.state = {
      ...this.state,
      events: [],
      eventsLoading: true,
    };
  }

  public resetCalendars(): void {
    this.state = {
      ...this.state,
      calendars: {},
      calendarsLoading: true,
    };
  }

  public setActiveDate(date): void {
    this.state = {
      ...this.state,
      activeDate: date,
    };
  }

  public setNearestActiveDate(date): void {
    this.state = {
      ...this.state,
      nearestActiveDate: date,
    };
  }

  public addEmptyCalendar(data) {
    this.state = {
      ...this.state,
      calendars: {
        ...this.state.calendars,
        [data.date]: {
          loading: true,
        },
      },
    };
  }

  public addCalendar(data) {
    this.state = {
      ...this.state,
      calendars: {
        ...this.state.calendars,
        [data.date]: {
          loading: false,
          ...data.events,
        },
      },
    };
  }

  public setEventsLoading(eventsLoading: boolean): void {
    this.state = {
      ...this.state,
      eventsLoading,
    };
  }

  public setCalendarsLoading(calendarsLoading: boolean): void {
    this.state = {
      ...this.state,
      calendarsLoading,
    };
  }

  public setHasProjects(hasProjects: boolean): void {
    this.state = {
      ...this.state,
      hasProjects,
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
