import { Dispatch } from 'redux';

import { courtsSettings } from './settings';

import { saveLawLayoutSettingsSucceeded } from './saveLayout';

import { httpSaveCourtsLayoutSettings } from './saveLayout.http';

export class SaveCourtsLayoutSettingsThunk {

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(layout) {

    const dto = courtsSettings.undecorate(layout);

    try {
      await httpSaveCourtsLayoutSettings(dto);
    } catch (error) {
      throw Error(error.status);
    }
  }

  dispatchSaveSucceeded() {
    this.dispatch(saveLawLayoutSettingsSucceeded.createAction());
  }

}

export function saveCourtsLayoutSettingsThunk(dispatch) {
  const thunk = new SaveCourtsLayoutSettingsThunk(dispatch);

  dispatch((_, getState) => {
    const { searchResult } = getState();
    thunk.invoke(searchResult.courts.layout);
  });

}
