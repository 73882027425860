import http from '@http';
import {
  RESTPath,
  Source,
  Id,
} from '@router/path.rest';

export interface GetPackageCountParams {
  source: Source.search | Source.package | Source.authorPackage;
  sourceId: Id;
}

export function httpGetPackageCount(requestParams: GetPackageCountParams) {
  const url = RESTPath.packages.getPackageCount(requestParams);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpGetPackageCount;
