import { Filters } from '../../../filters';

export const updateProximityRangeReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);
  const newData = {
    id: action.data.id,
    data: {
      proximityRange: action.data.data.proximityRange,
    },
  };

  const blocksMap = filters.updateData(newData);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap,
    },
  };
};

export default updateProximityRangeReduce;
