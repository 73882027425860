import { StateBuilder as DocumentsStateBuilder } from './state';
import { StateBuilder as LayoutStateBuilder } from '../../../header/redux/state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    documents: {
      ...DocumentsStateBuilder.createState(),
      layout: state.documents.layout,
      shouldLoadSettings: state.documents.shouldLoadSettings,
    },
    layout: LayoutStateBuilder.createState(),
  };
};

export default resetToInitialReduce;
