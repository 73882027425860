import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Document } from '@layouts/chronology/redux/state';

import { Loader } from '@components/loader';
import { EmptyFolder } from '@components/emptyFolder';

import { ChronologyPaginationConnect } from './chronologyPagination.connect';
import { HeaderConnect } from './components/header/header.connect';
import { ListConnect } from './components/list/list.connect';

import s from './chronologyTab.style';

interface ChronologyTabProps {
  loading: boolean;
  pages: Record<number, { items: number[]; itemsMap: Record<number, Document> }>
  pageLoading: boolean;
  currentPageNumber: number;
  baseHref: string;
  documentId: string | number;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

export class ChronologyTab extends React.PureComponent<ChronologyTabProps> {

  componentDidMount() {
    sendAmplitudeData('preview: open chronology tab');
    Userpilot.track('preview: open chronology tab');
  }

  componentDidUpdate(prevProps: Readonly<ChronologyTabProps>) {
    if (prevProps.currentPageNumber !== this.props.currentPageNumber) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    this.props.wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }

  renderPageLoader() {
    if (!this.props.pageLoading) {
      return null;
    }

    return (<s.PageLoader />);
  }

  renderList() {
    return (
      <ListConnect
        documentId={this.props.documentId}
        baseHref={this.props.baseHref}
      />
    );
  }

  renderLayout = () => {
    const noPages = isEmpty(this.props.pages);
    const emptyPage = isEmpty(this.props.pages[1].items);
    const noDocuments = !this.props.pageLoading && (noPages || emptyPage);
    if (noDocuments) {
      return <EmptyFolder title="Нет документов хронологии" />;
    }

    return (
      <s.ContentContainer>
        <HeaderConnect />
        <s.ItemsContent>
          {this.renderPageLoader()}
          {this.renderList()}
        </s.ItemsContent>
        <ChronologyPaginationConnect />
      </s.ContentContainer>
    );
  };

  render() {
    if (this.props.loading) {
      return <Loader />;
    }

    return this.renderLayout();
  }

}

export default ChronologyTab;
