import {
  css,
  styled,
} from '@components/styled';

const style: any = {};

const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

style.Root = styled.div`
  flex-direction: row;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  height: 56px;
  padding: 0 8px;
  line-height: 30px;

  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Actual = styled.div`
  display: block;
  font-family: Roboto-Medium;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.brightOrange};
  color: ${(props) => props.theme.colors.brightOrange};
  font-size: 11px;
  font-weight: 500;
  height: 16px;
  line-height: 16px;
  text-align: center;
  min-width: 78px;
  margin-left: 10px;
`;

style.Menu = styled.div`
  flex-grow: 0;
`;

style.Title = styled.div`
  margin-left: 4px;
  font: 17px Roboto-Medium;
  color: ${(props) => props.theme.colors.defaultText};
  ${ellipsis}
`;

style.LoadingTitle = styled.div`
  width: 200px;
  height: 14px;
  margin-top: 3px;
  border-radius: 7px;
  background: #f5f6f7;

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeff0 8%, #f5f6f7 38%, #eeeff0 54%);
  background-size: 1000px 640px;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
  }
`;

style.Icon = styled.div`
  padding-left: 10px;
`;

export default style;
