import React from 'react';

import { AddPackageConnect } from './addPackage/addPackage.connect';
import { SettingsPopover } from './settingsPopover/settingsPopover';

import s from './header.style';

export interface HeaderProps {
  title: string;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.TopHeader>
        <s.Title>
          {this.props.title}
        </s.Title>
        <s.Actions>
          <s.ActionIcon>
            <AddPackageConnect />
          </s.ActionIcon>
          <s.OptionsKebab>
            <SettingsPopover />
          </s.OptionsKebab>
        </s.Actions>
      </s.TopHeader>
    );
  }

}

export default Header;
