import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpCleanBookmarks() {
  const request = http.delete({
    url: RESTPath.bookmarks.deleteItems,
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpCleanBookmarks;
