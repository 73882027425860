import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  isTitle: boolean;
  isInner: boolean;
  isEmpty: boolean;
  textAlign: string;
}

const style: any = {};

const width = {
  title: '400px',
  item: '100%',
};

style.Table = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

style.Group = styled.div`
  display: table-column-group;
`;

style.ColumnWidthTitle = styled.div`
  width: ${width.title};
`;

style.ColumnWidthItem = styled.div`
  width: ${width.item};
`;

style.ColumnTitle = styled(style.ColumnWidthTitle)`
  display: table-column;
`;

style.ColumnItem = styled(style.ColumnWidthItem)`
  display: table-column;
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const cellStyle = () => css`
  display: table-cell;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  vertical-align: top;
`;

style.CellTitle = styled(style.ColumnWidthTitle)`
  ${cellStyle()}
  vertical-align: baseline;
`;

style.Title = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 12px;
`;

style.CellItem = styled(style.ColumnWidthItem)`
  ${cellStyle()}
  ${(props: Props) => !props.isEmpty && css`
    ${style.BodyText} {
      :hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${props.theme.colors.blue};
      }
    }
  `}
`;

function textEllipsis() {
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

style.HeaderText = styled.div`
  ${textEllipsis};
  font-size: 11px;
  padding: 0 15px;
  text-align: ${(props: Props) => props.textAlign || 'start'};
  font-family: Roboto-Bold;
`;

style.Body = styled.div`
  display: table-row-group;
`;

style.BodyText = styled.div`
  font-size: 13px;
  line-height: 16px;
  padding: 12px 15px;
  font-family: Roboto-Regular;
  text-align: ${(props: Props) => props.textAlign || 'start'};
  color: ${(props: Props) => props.theme.colors.blue};

  ${(props: Props) => props.isTitle && css`
    padding-left: 0;
    color: ${props.theme.colors.defaultText};
  `}

  ${(props: Props) => props.isInner && props.isTitle && css`
    padding-left: 44px;
  `}

  ${(props: Props) => props.isEmpty && css`
    color: ${props.theme.colors.silver};
  `}
`;

style.ShowMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  padding: 12px 15px;
  font-family: Roboto-Regular;
  color: ${(props) => props.theme.colors.blue};
  background-color: #f9f9f9;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  :hover {
    background-color: rgba(65, 131, 215, .07);
  }
`;

style.Row = styled.div`
  display: table-header-group;
  line-height: 38px;
  background-color: ${(props) => props.theme.colors.white}
`;

style.InnerRow = styled(style.Row)`
  background-color: #f9f9f9;
  cursor: default;

  :hover {
    background-color: rgba(65, 131, 215, .07);
  }
`;

export default style;
