import { StateBuilder } from './state';

export interface ActionData {
  loading: boolean;
}

export interface Action {
  type: string;
  data: ActionData;
}

export const setCalendarsLoadingReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.events);
  newState.setCalendarsLoading(action.data.loading);

  const events = newState.getState();

  return {
    ...state,
    events,
  };
};

export default setCalendarsLoadingReduce;
