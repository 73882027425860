import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpRemovePackage(requestParams: { packageId: number }) {
  const request = http.delete({
    url: RESTPath.packages.deletePackage(requestParams),
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpRemovePackage;
