import { connect } from 'react-redux';

import { Type } from '../redux/settings';

import { Header } from './header';

function prepareColumns(settings) {
  const columns = {
    itemsList: ['title'],
    itemsMap: settings.itemsMap,
  };

  columns.itemsMap = columns.itemsMap.set('title', { id: 'title', title: 'Наименование' });

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      columns.itemsList.push(column);
    }
  });

  return columns;
}

const mapStateToProps = (state) => {
  const {
    columns,
    type,
  } = state.searchResult.law.layout;

  const isAppealState = type === Type.appealState;
  let result = 'исходам рассмотрения';
  if (isAppealState) {
    result = 'статусам обжалования';
  }

  const title = `Нормы права по ${result}`;
  const settings = columns[type];
  const viewColumns = prepareColumns(settings);

  return {
    title,
    columns: viewColumns,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
