import { changeSortColumnReduce } from './changeSortColumn.reduce';

export type Id = string;

const actionType = 'SEARCH/RESULT/COURTS/CHANGE_SORT_COLUMN';

const createAction = (id: Id) => ({
  type: actionType,
  data: { id },
});

export const changeSortColumn = {
  actionType,
  createAction,
  reduce: changeSortColumnReduce,
};

export default changeSortColumn;
