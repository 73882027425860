import { connect } from 'react-redux';

import {
  Pagination,
  preparePages,
} from '@components/pagination';

import { fetchSimilarDocumentsThunk } from '@layouts/similar/redux/fetchSimilarDocuments.thunk';

const mapStateToProps = (state) => {
  const {
    totalPages,
    currentPageNumber,
  } = state.similar;

  const isFirstPageSelected = currentPageNumber === 1;
  const isLastPageSelected = currentPageNumber === totalPages;

  return {
    pages: preparePages(totalPages, currentPageNumber),
    currentPageNumber,
    isFirstPageSelected,
    isLastPageSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangePage(pageNumber) {
    fetchSimilarDocumentsThunk(dispatch, null, pageNumber);
  },
});

export const SimilarPaginationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pagination);

export default SimilarPaginationConnect;
