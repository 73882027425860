export interface ActionData {
  documentId: number;
  pageNumber?: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const increasePackageCountReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const currentPageNumber = action.data.pageNumber || state.documents.currentPageNumber;
  const item = { ...state.documents.pages[currentPageNumber].itemsMap.get(action.data.documentId) };
  item.packagesCount += 1;
  const itemsMap = state.documents.pages[currentPageNumber].itemsMap.set(action.data.documentId, item);

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap,
        },
      },
    },
  };
};

export default increasePackageCountReduce;
