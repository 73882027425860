import styled from 'styled-components';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  isActive: boolean;
  bold: boolean;
}

const style: any = {};

export const width = {
  isNew: '8px',
  checkbox: '24px',
  logo: '96px',
  document: '100%',
  actions: '48px',
};

style.Root = styled.div`
  font-size: 13px;
  background-color: ${(props: Props) => props.theme.colors.white};
`;

style.Table = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
`;

style.Group = styled.div`
  display: table-column-group;
`;

style.ColumnWidthIsNew = styled.div`
  width: ${width.isNew};
`;

style.ColumnWidthCheckbox = styled.div`
  height: 100%;
  width: ${width.checkbox};
`;

style.CellCheckbox = styled(style.ColumnWidthCheckbox)`
  display: table-cell;
  vertical-align: baseline;
`;

style.CellTitle = styled(style.ColumnWidthCheckbox)`
  display: table-cell;
  vertical-align: middle;
`;

style.ColumnWidthLogo = styled.div`
  width: ${width.logo};
`;

style.ColumnWidthDocument = styled.div`
  width: ${width.document};
`;

style.ColumnWidthActions = styled.div`
  width: ${width.actions};
`;

style.ColumnIsNew = styled(style.ColumnWidthIsNew)`
  display: table-column;
`;

style.ColumnCheckbox = styled(style.ColumnWidthCheckbox)`
  display: table-column;
`;

style.ColumnLogo = styled(style.ColumnWidthLogo)`
  display: table-column;
`;

style.ColumnDocument = styled(style.ColumnWidthDocument)`
  display: table-column;
`;

style.ColumnActions = styled(style.ColumnWidthActions)`
  display: table-column;
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

style.Header = styled.div`
  display: table-header-group;
  line-height: 38px;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Title = styled.div`
  display: table-cell;
`;

style.Body = styled.div`
  display: table-row-group;
`;

style.CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

style.CheckboxLabel = styled.div`
  font: 11px Roboto-bold;
  padding-left: 12px;
`;

export default style;
