import { RESTPath } from '@router/path.rest';
import http from '@http';

interface RequestParams {
  text: string;
}

export function httpSaveLastname(data: RequestParams) {
  const request = http.post({
    url: RESTPath.user.saveLastname,
    data,
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpSaveLastname;
