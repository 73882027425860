import { StateBuilder } from './state';

export interface SubscriptionDTO {
  id: number;
  title: string;
  is_notify: boolean;
  new_count: number;
}

export interface Subscription {
  id: number;
  title: string;
  hasNotification: boolean;
  newDocumentsCount: number;
}

export interface ActionData {
  items: SubscriptionDTO[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchAuthorPackagesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.packages);
  newState.addItems(action.data.items);
  const packages = {
    ...newState.getState(),
    loading: false,
  };

  return {
    ...state,
    packages,
  };
};

export default fetchAuthorPackagesReduce;
