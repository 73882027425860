import {
  ActionData,
  setHasProjectsReduce,
} from './setHasProjects.reduce';

export const actionType = 'REGULATION/EVENTS/SET_HAS_PROJECTS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setHasProjects = {
  actionType,
  createAction,
  reduce: setHasProjectsReduce,
};
