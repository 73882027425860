/* eslint-disable */
import React from 'react';

import { TextHighlighter } from './textHightlighter';

import { MarkerYellow } from './components/markerYellow';
import { MarkerGreen } from './components/markerGreen';
import { MarkerRed } from './components/markerRed';

import GlobalStyle from './global.style';

import s from './documentMarkers.style';

export class DocumentMarkers extends React.PureComponent {

  state = {
    activeMarker: null,
  };

  /**
   * @type {TextHighlighter}
   */
  highlighterInstance = null;

  isSelectionsLoaded = false;

  isLoadedSelectionsRendered = false;

  _destroyHighlightService() {
    this.highlighterInstance.setObserverMode();
    document.querySelector('body').className = 'body';
  }

  _createHighlightService(color) {
    if (!this.highlighterInstance) {
      this.highlighterInstance = new TextHighlighter(document.querySelector('.document-view__articles>div'), {
        onChangeHighlights: () => {
          this.props.onSaveMarkers(this.highlighterInstance.serializeHighlights());
        },
        enable: this.props.enable,
      });
    }

    if (color) {
      this.highlighterInstance.setColor(color);
      this.highlighterInstance.setInsertMode();
      document.querySelector('body').className = `body document-markers-color-${color}`;
    }
  }

  _markerSelect = (activeMarker) => {
    if (this.highlighterInstance && this.state.activeMarker !== activeMarker) {
      // переключение на новый цвет
      this._destroyHighlightService();
      this._createHighlightService(activeMarker);
      this.setState({ activeMarker });

      return 1;
    }

    if (this.highlighterInstance && this.state.activeMarker === activeMarker) {
      // выключение
      this._destroyHighlightService();
      this.setState({ activeMarker: '' });

      return 1;
    }

    // включение
    this._createHighlightService(activeMarker);
    this.setState({ activeMarker });
  };

  clearDom() {
    this.highlighterInstance && this.highlighterInstance.removeHighlights(null, true);
    this.highlighterInstance && this.highlighterInstance.destroy();
    this.highlighterInstance = null;
    this.isSelectionsLoaded = false;
    this.isLoadedSelectionsRendered = false;
    document.querySelector('body').className = 'body';
  }

  componentWillUnmount() {
    this.clearDom();
  }

  /**
   * рендерить подгруженные через апи сохраненные ранее маркерные выделения
   * @private
   */
  _renderLoadedHighlights(ranges) {
    this._createHighlightService(null);
    this.highlighterInstance.deserializeHighlights(ranges);
    this.isLoadedSelectionsRendered = true;
  }

  handleSelectMarkerA = () => {
    this._markerSelect('a');
  };

  handleSelectMarkerB = () => {
    this._markerSelect('b');
  };

  handleSelectMarkerC = () => {
    this._markerSelect('c');
  };

  markerClassBuilder(markerType) {
    const initialClass = 'document-markers__marker';
    const isActive = this.state.activeMarker === markerType ? `${initialClass}_active_${markerType}` : '';
    const className = `${initialClass} ${initialClass}_color_${markerType} ${isActive}`;

    return className;
  }

  renderMarkers() {
    if (!this.props.enable) {
      return null;
    }

    return (
      <>
        <s.Marker
          isActive={this.state.activeMarker === 'c'}
          onClick={this.handleSelectMarkerC}
        >
          <MarkerRed />
        </s.Marker>
        <s.Marker
          isActive={this.state.activeMarker === 'a'}
          onClick={this.handleSelectMarkerA}
        >
          <MarkerYellow />
        </s.Marker>
        <s.Marker
          isActive={this.state.activeMarker === 'b'}
          onClick={this.handleSelectMarkerB}
        >
          <MarkerGreen />
        </s.Marker>
      </>
    );
  }

  render() {
    const { selections } = this.props;

    if (selections && selections.length > 0 && !this.isLoadedSelectionsRendered) {
      // откровенный костыль от эффекта гонки при переключении вкладок "информация" <-> "документ"
      setTimeout(() => {
        this._renderLoadedHighlights(selections);
      }, 0);
    }

    return (
      <s.Root
        right={this.props.right}
        id="markers-container"
      >
        {this.renderMarkers()}
        <GlobalStyle activeMarker={this.state.activeMarker} />
      </s.Root>
    );
  }

}

export default DocumentMarkers;
