import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { createAuthorPackage } from './createAuthorPackage';

import { httpCreateAuthorPackage } from './createAuthorPackage.http';

import { httpRepublishAuthorPackage } from './republishAuthorPackage.http';

export class CreateAuthorPackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: string, text: string }) {
    const state = this.getState();
    const isPublished = state.packages.packages.itemsMap.get(params.id).type === 'author';
    this.dispatchCreateAuthorPackage(params);

    const requestParams = {
      packageId: params.id,
      description: params.text,
    };

    if (!isPublished) {
      sendAmplitudeData('public collection: create');
      Userpilot.track('public collection: create');
      this.httpCreateAuthorPackage(requestParams);
    } else {
      sendAmplitudeData('public collection: update');
      Userpilot.track('public collection: update');
      this.httpRepublishAuthorPackage(requestParams);
    }
  }

  private async httpCreateAuthorPackage(requestParams) {
    try {
      await httpCreateAuthorPackage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async httpRepublishAuthorPackage(requestParams) {
    try {
      await httpRepublishAuthorPackage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchCreateAuthorPackage(params) {
    this.dispatch(createAuthorPackage.createAction(params));
  }

}

export function createAuthorPackageThunk(dispatch, params) {
  const thunk = new CreateAuthorPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default CreateAuthorPackageThunk;
