import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchPackageDocuments(requestParams: { sourceId: number; pageNumber: number; searchId?: number }) {
  const request = http.get({
    url: RESTPath.packages.getDocuments(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchPackageDocuments;
