import {
  RESTPath, Id,
} from '@router/path.rest';
import http from '@http';

export function httpRemoveFilter(requestParams: { id: Id }) {
  const request = http.delete({
    url: RESTPath.regulation.filters.work(requestParams),
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpRemoveFilter;
