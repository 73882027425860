import { connect } from 'react-redux';

import { BookmarksList } from './bookmarksList';

const mapStateToProps = (state) => ({
  items: state.bookmarksBuffer.queue,
  disabled: !state.bookmarksBuffer.queue.length,
});

export const BookmarksListConnect = connect(
  mapStateToProps,
)(BookmarksList);

export default BookmarksListConnect;
