import { connect } from 'react-redux';

import { SimilarListPromo } from './similarListPromo';

const mapStateToProps = (state) => {
  const currentPageNumber = 1;
  const { pages } = state.similar;

  const hasPage = pages[currentPageNumber];

  if (!hasPage) {
    return {
      items: [],
    };
  }

  const items = pages[currentPageNumber].items;

  return {
    items,
    currentPageNumber,
  };

};

export const SimilarListPromoConnect = connect(
  mapStateToProps,
)(SimilarListPromo);

export default SimilarListPromoConnect;
