import { RESTPath } from '@router/path.rest';
import http from '@http';

import {
  Id,
  BodyBlock,
} from '@pages/regulation/workspace/redux/state';

export interface ProjectDTO {
  body: BodyBlock[];
}

export interface RequestParams {
  query: string;
}

export function httpFetchProject(projectId: Id, requestParams: RequestParams): Promise<ProjectDTO> {
  const url = RESTPath.regulation.projects.preview.project(projectId);
  const request = http.post({
    url,
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchProject;
