interface Action {
  type: string;
  data: {
    documentId: number;
  };
}

export const changePreviewDocumentReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const currentGlobalPageNumber = state.documents.currentPageNumber;

  const {
    currentPageNumber,
    documentId,
  } = state.documents.previewDocument;

  const page = state.documents.pages[currentPageNumber];
  if (!page) {
    return {
      ...state,
      documents: {
        ...state.documents,
        previewDocument: {
          currentPageNumber: currentGlobalPageNumber,
          documentId: action.data.documentId,
        },
      },
    };
  }

  const item = page.itemsMap.get(documentId);

  const newItem = {
    ...item,
    documentLoaded: false,
    attributesLoaded: false,
    details: {
      attributes: {
        appealState: null,
        case: {
          external: null,
          number: null,
        },
        caseCategory: null,
        caseResult: null,
        caseType: null,
        date: null,
        defendants: [],
        department: null,
        judges: [],
        others: [],
        plaintiffs: [],
        third: [],
      },
      documentId: null,
      document: [],
      resolution: [],
    },
  };

  const newItemsMap = page.itemsMap.set(documentId, newItem);

  return {
    ...state,
    documents: {
      ...state.documents,
      previewDocument: {
        currentPageNumber: currentGlobalPageNumber,
        documentId: action.data.documentId,
      },
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap: newItemsMap,
        },
      },
    },
  };
};

export default changePreviewDocumentReduce;
