import { Map } from 'immutable';

export interface User {
  avatar: string;
  firstName: string;
  lastName: string;
}

export interface MessageDTO {
  id: number;
  projectId: number;
  clientId: string;
  time: number;
  user: User;
  body: string;
}

interface Message {
  id: number;
  projectId: number;
  clientId: string;
  date: string;
  user: {
    avatar: string;
    credentials: string;
  };
  body: string;
}

type Id = number | string;

export type Loading = boolean;

interface State {
  messages: {
    itemsList: Id[];
    itemsMap: Map<Id, Message>;
  };
  loading: Loading;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      messages: {
        itemsList: [],
        itemsMap: Map(),
      },
      loading: true,
    };
  }

  constructor(state: State) {
    this.state = state;
  }

  public addClientMessage(message: MessageDTO) {
    const item = this.buildMessage(message);
    this.addToItemsList(item.clientId);
    this.addToItemsMap(item.clientId, item);
  }

  public addMessage(message: MessageDTO) {
    const item = this.buildMessage(message);
    this.addToItemsList(item.id);
    this.addToItemsMap(item.id, item);
  }

  public updateMessageFromId(message: MessageDTO) {
    const item = this.buildMessage(message);
    this.updateListId(item);
    this.updateMessageId(item);
  }

  public buildMessages(items) {
    items.forEach((item) => {
      this.addMessage(item);
    });
  }

  public setLoadingStatus(status: boolean) {
    this.state.loading = status;
  }

  public getState() {
    return this.state;
  }

  private updateListId(message) {
    this.state.messages.itemsList = this.state.messages.itemsList.map((item) => {
      const isSame = item === message.clientId;
      if (!isSame) {
        return item;
      }

      return message.id;
    });
  }

  private updateMessageId(message) {
    this.deleteFromItemsMap(message.clientId);
    this.addToItemsMap(message.id, message);
  }

  private addToItemsList(id) {
    this.state.messages.itemsList.push(id);
  }

  private addToItemsMap(id, message) {
    this.state.messages.itemsMap = this.state.messages.itemsMap.set(id, message);
  }

  private deleteFromItemsMap(clientId) {
    this.state.messages.itemsMap = this.state.messages.itemsMap.delete(clientId);
  }

  private buildMessage(message: MessageDTO): Message {
    const date = this.buildDate(message.time);
    const credentials = this.buildCredentials(message.user);

    return {
      id: message.id,
      projectId: message.projectId,
      clientId: message.clientId,
      date,
      user: {
        avatar: message.user.avatar,
        credentials,
      },
      body: message.body,
    };
  }

  private buildDate(date) {
    const time = new Date(date * 1000);
    const year = time.getFullYear();
    let month = String(time.getMonth() + 1);
    month = (`0${month}`).slice(-2);
    let day = String(time.getDate());
    day = (`0${day}`).slice(-2);
    let hour = String(time.getHours());
    hour = (`0${hour}`).slice(-2);
    let min = String(time.getMinutes());
    min = (`0${min}`).slice(-2);

    return `${day}.${month}.${year}, ${hour}:${min}`;
  }

  private buildCredentials(user) {

    const lastName = user.lastName || '';
    const firstName = user.firstName || '';

    return `${lastName} ${firstName}`;
  }

}

export default StateBuilder;
