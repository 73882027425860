import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 8px 0 16px;
  background-color: white;
  border-bottom: 1px solid rgb(223, 226,230);
  font: 17px Roboto, sans-serif;
  font-family: 'Roboto-Medium';
`;

style.Title = styled.div`
  display: flex;
  flex-shrink: 0;
`;

style.Actions = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
`;

export default style;
