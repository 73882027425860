import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { DateBlock } from '@components/filters/date/logic/date';

import { getSource } from '@pages/regulation/utils/marketing';

import { FilterType } from './state';

import { resetProjects } from '../../redux/resetProjects';
import { fetchProjectsThunk } from '../../redux/fetchProjects.thunk';
import { fetchFolderThunk } from '../../redux/fetchFolder.thunk';

import { updateFilter } from './updateFilter';
import { uncheckProjects } from '../../redux/uncheckProjects';

export class UpdateDateThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public invoke(filter) {
    this.dispatch(resetProjects.createAction());
    this.dispatch(uncheckProjects.createAction());

    const type = FilterType.date;
    const filterData = this.getState().regulation.filters.filters[type].data;
    const id = this.getState().regulation.folders.activeItemId;
    const source = getSource(id);

    if (filter.scope) {
      const scope = DateBlock.scopePresets[filter.scope];
      const data = {
        value: ['', ''],
        scope,
      };

      sendAmplitudeData('regulation:update_filter_date', { data, source });
      Userpilot.track('regulation:update_filter_date', { data });
      this.dispatch(updateFilter.createAction({ type, data }));
    } else {
      const data = {
        ...filterData,
        scope: DateBlock.scopePresets.range,
        value: filter.value,
      };

      sendAmplitudeData('regulation:update_filter_date', { data, source });
      Userpilot.track('regulation:update_filter_date', { data });
      this.dispatch(updateFilter.createAction({ type, data }));
    }

    const folderId = this.getState().regulation.folders.activeItemId;
    if (folderId) {
      fetchFolderThunk(this.dispatch);
    } else {
      fetchProjectsThunk(this.dispatch);
    }
  }

}

export function updateDateThunk(dispatch, filter) {
  const thunk = new UpdateDateThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(filter);
}
