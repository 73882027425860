import React from 'react';

import s from './tabs.style';

type TabId = string;

interface TabI {
  id: TabId;
  title: string;
}

const Tab = (props: { tab: TabI } & { activeId: TabId; onClick(tabId: string): void; }) => {
  const active = props.tab.id === props.activeId;

  const handleClick = () => {
    if (active) {
      return;
    }

    props.onClick(props.tab.id);
  };

  return (
    <s.Tab
      key={props.tab.id}
      active={active}
      onClick={handleClick}
    >
      {props.tab.title}
    </s.Tab>
  );
};

interface TabsProps {
  tabs: TabI[];
  onClick(tabId: string): void;
  activeId: TabId;
}

export const Tabs = (props: TabsProps) => (
  <s.Root>
    {props.tabs.map((tab) => (
      <Tab
        key={tab.id}
        activeId={props.activeId}
        tab={tab}
        onClick={props.onClick}
      />
    ))}
  </s.Root>
);

export default Tabs;
