import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.article`
  width: 384px;

  border-left: solid 1px ${theme.colors.borderColor};
  background-color: #f9f9f9;

  z-index: 4;
`;

export default style;
