import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.FilterBlockWrap = styled.div`
  width: 100%;
  height: 48px;
`;

style.Form = styled.div`
  position: relative;
  width: 100%;
`;

style.EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height : 88px;
  border: 2px dashed ${theme.colors.silver};
  font-size: 13px;
  color: ${theme.colors.silver};
`;

style.FailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height : 88px;
  border: 2px dashed ${theme.colors.silver};
  font-size: 13px;
  color: ${theme.colors.silver};
`;

style.FilterBlocksContainer = styled.div`
`;

style.FiltersList = styled.div`
  position: relative;
  width: 100%;
`;

style.Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;

  &:focus {
    outline: none;
  }
`;

style.SearchControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 28px;
`;

interface ColumnProps {
  verticalAlign: string;
}

style.Column = styled.div`
  display: flex;
  width: 100%;

  ${(props: ColumnProps) => props.verticalAlign === 'right' && css`
    justify-content: flex-end;
  `}

  ${(props: ColumnProps) => props.verticalAlign === 'left' && css`
    justify-content: flex-start;
  `}
`;

style.Button = styled.div`
  display: inline-block;
`;

style.FiltersLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

style.Loader = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  margin-top: 5px;
  margin-left: 5px;

  > div {
    width: 24px;
    height: 24px;
  }
`;

style.PreparingLayout = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

style.PreparingTitle = styled.div``;

export default style;
