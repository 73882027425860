import React from 'react';

import { AddProjectsConnect } from './addProjects.connect';

import s from './header.style';

export interface HeaderProps {
  title: string;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.TopHeader>
        <s.Title>
          {this.props.title}
        </s.Title>
        <s.Actions>
          <s.Action>
            <AddProjectsConnect />
          </s.Action>
        </s.Actions>
      </s.TopHeader>
    );
  }

}

export default Header;
