import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpFetchFiltersFromCbToken(token) {
  const request = {
    url: RESTPath.search.fromCbToken(token),
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(http.parseResponse);
}

export default httpFetchFiltersFromCbToken;
