import React from 'react';
import { RoutePath } from '@router/path';

import { WithSidebar } from '@components/withSidebar';
import { StaticSidebar } from '@components/staticSidebar/staticSidebar';

import { AuthRouteConnect } from '@router/authRoute.connect';

import { withFetchSearch } from '@pages/searchResult/withFetchSearch';

import { SearchConnect } from './search.connect';

export const RouteSearch = (
  <AuthRouteConnect
    exact
    path={[RoutePath.search, RoutePath.searchWithFilters]}
    component={WithSidebar(StaticSidebar, withFetchSearch(SearchConnect))}
  />
);

export default RouteSearch;
