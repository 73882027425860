import { ReducerBuilder } from '@redux/reducer.builder';

import { updateFrom } from './updateFrom';
import { updateTo } from './updateTo';

export const reducerBuilder = new ReducerBuilder([
  updateFrom,
  updateTo,
]);

export default reducerBuilder;
