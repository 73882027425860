import React from 'react';

import { Loader } from '@components/loader';

import { BlockProps } from './block';

import { DocumentBody } from './documentBody';

import s from './workspace.style';

export interface WorkspaceProps {
  title: string;
  type: string;
  loading: boolean;
  bodyBlocks: BlockProps[];
  onBodyMounted(): void;
}

export class Workspace extends React.PureComponent<WorkspaceProps> {

  private renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  private renderTitle() {
    return (
      <s.Title>
        {this.props.type}
      </s.Title>
    );
  }

  private renderType() {
    return (
      <s.Type>
        {this.props.title}
      </s.Type>
    );
  }

  render() {
    if (!this.props.loading) {
      return (
        <s.Root
          id="workspace"
        >
          <s.View
            className="document-view"
          >
            <s.Ribbon>
              <s.RibbonInside>
                BETA
              </s.RibbonInside>
            </s.Ribbon>
            <s.Header>
              {this.renderTitle()}
              {this.renderType()}
            </s.Header>
            <DocumentBody
              bodyBlocks={this.props.bodyBlocks}
              onBodyMounted={this.props.onBodyMounted}
            />
          </s.View>
        </s.Root>
      );
    }

    return this.renderLoader();
  }

}

export default Workspace;
