import { Dispatch } from 'redux';

import { getLeadingZeroDate } from '../utils';
import { resetEvents } from './resetEvents';

import { fetchEvents } from './fetchEvents';
import { httpFetchEvents } from './fetchEvents.http';

export interface Params {
  year: number;
  month: number;
  day: number;
}

export class FetchEventsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    this.dispatch(resetEvents.createAction());
    const data = await this.fetchEvents(params);
    this.dispatchFetchEventsSucceed(data);
  }

  private async fetchEvents(params) {
    const month = getLeadingZeroDate(params.month + 1);
    const day = getLeadingZeroDate(params.day);

    const date = `${params.year}-${month}-${day}`;
    const requestParams = {
      date,
    };

    try {
      return await httpFetchEvents(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchEventsSucceed(data) {
    this.dispatch(fetchEvents.createAction(data));
  }

}

export async function fetchEventsThunk(dispatch, params: Params) {
  const thunk = new FetchEventsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });
  await thunk.invoke(params);
}

export default FetchEventsThunk;
