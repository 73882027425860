import styled from 'styled-components';

const style = {};

style.Button = styled.div`
  > button {
    width: 94px;
  }
`;

style.Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;
`;

style.Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
    margin: 0;
    top: 0;
    left: 0;
    border: 2px solid transparent;
    border-color: ${(props) => `${props.theme.colors.white} ${props.theme.colors.white} ${props.theme.colors.blue}`};
  }
`;

export default style;
