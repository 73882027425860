import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './rating.style';

export interface RatingProps {
  value: string;
  onChange(value: string): void;
}

export class Rating extends React.PureComponent<RatingProps> {

  handleGoodClick = () => {
    this.props.onChange('good');
  };

  handleBadClick = () => {
    this.props.onChange('bad');
  };

  renderGoodButton() {
    const isActive = this.props.value === 'good';
    const iconPath = iconsPath.get('doneTick');

    return (
      <s.GoodButton
        isActive={isActive}
        onClick={this.handleGoodClick}
      >
        <Icon points={iconPath} />
        <span>Хорошо</span>
      </s.GoodButton>
    );
  }

  renderBadButton() {
    const isActive = this.props.value === 'bad';
    const iconPath = iconsPath.get('crossTick');

    return (
      <s.BadButton
        isActive={isActive}
        onClick={this.handleBadClick}
      >
        <Icon points={iconPath} />
        <span>Плохо</span>
      </s.BadButton>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderGoodButton()}
        <s.Divider />
        {this.renderBadButton()}
      </s.Root>
    );
  }

}

export default Rating;
