import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: #f3f3f3;
`;

style.HeaderContainer = styled.div`
`;

style.TabContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

style.Tab = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  margin: 0 auto;
  min-width: 960px;
  max-width: 1152px;
  width: 100%;
`;

export default style;
