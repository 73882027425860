import React from 'react';

import s from './scrollable.style';

interface ScrollableProps {
  onFire(): void;
}

export class Scrollable extends React.PureComponent<React.PropsWithChildren<ScrollableProps>> {

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      this.props.onFire();
    }
  };

  render() {
    return (
      <s.Root onScroll={this.handleScroll}>
        {this.props.children}
      </s.Root>
    );
  }

}

export default Scrollable;
