import { searchPackagesResultResetToInitialReduce } from './searchPackagesResultResetToInitial.reduce';

const actionType = 'PACKAGES/SEARCH_RESULT/RESET_TO_INITIAL';

const createAction = () => ({
  type: actionType,
});

export const searchPackagesResultResetToInitial = {
  actionType,
  createAction,
  reduce: searchPackagesResultResetToInitialReduce,
};

export default searchPackagesResultResetToInitial;
