import { AbstractFilterBlock } from '../../abstract';

export interface TextBlockData {
  value: string
}

export class TextBlock extends AbstractFilterBlock {

  data: TextBlockData = {
    value: '',
  };

  public decorate() {

    return {
      data: this.getData(),
    };
  }

  public undecorate() {

    return {
      data: this.getData(),
    };
  }

  // eslint-disable-next-line
  public validate() { }

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public createFromScratch(props) {
    if (!props) {
      throw Error('data is required');
    }

    this.setData(props);
  }

}

export default TextBlock;
