/* eslint-disable */
import React from 'react';

import { Tooltip } from './tooltip';
import { TooltipLoader } from './tooltipLoader';

import { withClickOutside } from '@pages/document/workspace/components/clickOutsideHandler';

import s from './tooltipRelations.style';

interface Props {
  fields: any;
  topDiff: number;
}

class TooltipRelations extends React.PureComponent<Props> {

  static defaultProps = {
    topDiff: 0,
  }

  refRoot = React.createRef();

  state = {
    style: {
      top: 0,
      left: 0,
    },
    data: {
      document: null,
      articles: [],
      clauses: [],
    },
    loading: true,
  };

  componentDidMount() {
    this.fetchRelation();
    this.setStyles();
  }

  fetchRelation = async () => {
    // @ts-ignore
    const data = await this.props.onFetchRelation();
    this.setState({
      data,
      loading: false,
    });
  };

  setStyles = () => {
    const reference = this.refRoot.current;
    if (!reference) {
      return;
    }

    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

    // @ts-ignore
    const top = this.props.tooltipOffsets.top + scrollTop - reference.offsetHeight - 8;

    // @ts-ignore
    let right = this.props.tooltipOffsets.right - reference.getBoundingClientRect().width / 2;

    if (right < 68) {
      right = 68;
    }

    if (right > 256) {
      right = 256;
    }

    // @ts-ignore
    reference.style.top = `${Math.ceil(top)}px`;
    // @ts-ignore
    reference.style.right = `${Math.ceil(right)}px`;
  };

  renderTitle() {
    return (
      <s.Title>
        <s.Link>
          {this.props.fields.number}
        </s.Link>
      </s.Title>
    );
  }

  renderTooltip() {
    if (this.state.loading) {
      return <TooltipLoader calculatePosition={this.setStyles} />
    }

    return <Tooltip { ...this.state.data } calculatePosition={this.setStyles}/>;
  }

  render() {
    return (
      <s.Root
        // @ts-ignore
        ref={this.refRoot}
      >
        {this.renderTooltip()}
      </s.Root>
    );
  }

}

export const TooltipRelationsWithClickOutside = withClickOutside(TooltipRelations);

export default TooltipRelationsWithClickOutside;
