import { fetchJudgesReduce } from './fetchJudges.reduce';

import type { ActionData } from './fetchJudges.reduce';

const actionType = 'SEARCH/RESULT/FETCH_JUDGES';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchJudges = {
  actionType,
  createAction,
  reduce: fetchJudgesReduce,
};

export default fetchJudges;
