import { connect } from 'react-redux';

import { fetchProjectsThunk } from '../redux/fetchProjects.thunk';

import { Projects } from './projects';

const mapStateToProps = (state) => {
  const {
    layout,
    projects,
    loading,
    previewId,
  } = state.regulation.projects;

  return {
    viewType: layout.viewType,
    items: projects.itemsList,
    previewId,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoadNextPage() {
    fetchProjectsThunk(dispatch);
  },
});

export const ProjectsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Projects);

export default ProjectsConnect;
