import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { updateTitle } from './updateTitle';

import { httpUpdateTitle } from './updateTitle.http';

export class UpdateTitleThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: number, title: string }) {

    this.marketingEvent(params);
    this.dispatchUpdateTitle(params);
    await this.updateTitleHttp(params);

  }

  private marketingEvent(params) {
    const oldName = this.getState().regulation.smartFolders.itemsMap.get(params.id).title;
    const newName = params.title;

    sendAmplitudeData('regulation:rename_filter', { oldName, newName });
    Userpilot.track('regulation:rename_filter');
  }

  private async updateTitleHttp(params) {
    try {
      const requestParams = {
        id: params.id,
        name: params.title,
        filters: this.getState().regulation.smartFolders.itemsMap.get(params.id).filters,
      };

      await httpUpdateTitle(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchUpdateTitle(params) {
    this.dispatch(updateTitle.createAction(params));
  }

}

export function updateTitleThunk(dispatch, params) {
  const thunk = new UpdateTitleThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default UpdateTitleThunk;
