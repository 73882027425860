import styled, { css } from 'styled-components';

interface Props {
  alert: boolean;
  theme: Record<any, any>;
  hasRightBorder: boolean;
  boxWidth?: number;
}

const style: any = {};

style.Root = styled.div`
  background: ${(props: Props) => props.theme.colors.white};
`;

style.Header = styled.div`
  display: flex;
  height: 56px;
  box-shadow: 0 1px 0 0 ${(props: Props) => props.theme.colors.borderColor};
  padding: 0 16px;
`;

style.HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font: 17px Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.defaultText};
`;

style.Info = styled.div`
  display: flex;
  flex-direction: column;
`;

style.InfoRow = styled.div`
  display: flex;
  flex-direction: row;
`;

style.Field = styled.div`
  width: ${(props: Props) => (props.boxWidth ? `${props.boxWidth}%` : '33.3%')};
  height: 48px;
  box-shadow: 0 1px 0 0 #dfe2e6;
  > div {
    width: calc(100% + 1px);
    label {
      top: 17px;
    }
    input {
      border: none;
      border-bottom: 1px solid #dfe2e6;
      height: 49px;
      background-color: transparent;

      ${(props: Props) => props.hasRightBorder && css`
        border-right: 1px solid #dfe2e6;
      `}
    }
  }
`;

export default style;
