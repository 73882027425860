import {
  CaseResultBlock, CaseResultBlockData,
} from '../../form/filterBlocks/caseResult/redux/caseResult';

import { PresetsData } from './presets';

interface CaseResultBlockDataTransform {
  id: number
  presetsData: PresetsData
}

export abstract class CaseResultBlockHistory extends CaseResultBlock {

  static transform({ id }, presetsData: CaseResultBlockDataTransform): string {
    const presets = presetsData;

    const data = presets[this.type].reduce((acc: string, el: CaseResultBlockData) => {
      let result = acc;
      const isEqual = el.id === id;

      if (isEqual) {
        result += el.title;
      }

      return result;
    }, '');

    return data;
  }

}

export default CaseResultBlockHistory;
