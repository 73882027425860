import styled from 'styled-components';

interface Props {
  theme: any,
}

const Bar = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid rgb(223, 226,230);
  font: 17px Roboto-Medium, sans-serif;
  font-weight: 500;
`;

const style: any = {};

style.Header = styled.div`
  display: flex;
  flex-direction: column;
`;

style.BreadcrumbsContainer = styled.div`
  flex-shrink: 0;
  margin-left: 4px;
`;

style.BreadcrumbsBar = styled(Bar)`
  height: 56px;
  padding: 0 8px;
`;

style.Breadcrumbs = styled.div`
  display: inline-flex;
`;

style.RootBreadcrumb = styled.div`
  display: flex;
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.CurrentBreadcrumb = styled.div`
  display: flex;
`;

style.NavigationBar = styled(Bar)`
  height: 49px;
  display: flex;
`;

style.NavigationBarContent = styled.div`
  min-width: 960px;
  max-width: 1152px;
  margin: 0 auto;
  width: 100%;
`;

style.Actions = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
`;

export default style;
