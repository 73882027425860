import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { Settings } from '@pages/searchResult/documents/components/settings/settings';

import {
  buildRequisitesForClipboard,
  buildDownloadItems,
} from '@pages/searchResult/documents/utils';

const mapStateToProps = (state, ownProps) => {

  const documentId = ownProps.id;
  const source = Source.subscription;
  const sourceId = state.subscriptions.subscriptions.activeItemId;

  return {
    source,
    sourceId,
    requisites: buildRequisitesForClipboard(documentId, state.subscriptions.documents),
    downloadItems: buildDownloadItems({
      documentId,
      sourceId,
      source,
    }),
  };
};

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
