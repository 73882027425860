import React from 'react';

import s from './block.style';

export interface BlockProps {
  id: string;
  title: string;
  body: string;
}

export class Block extends React.PureComponent<BlockProps> {

  renderBlock() {
    return {
      __html: this.props.body,
    };
  }

  render() {
    return (
      <s.Root
        data-block-id={this.props.id}
      >
        <s.Title>
          {this.props.title}
        </s.Title>
        <s.Content
          dangerouslySetInnerHTML={this.renderBlock()}
        />
      </s.Root>
    );
  }

}

export default Block;
