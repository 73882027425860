import { Map } from 'immutable';

const prepareJudge = (judge) => ({
  id: judge.id,
  title: judge.name,
  appealState: judge.appeal_state,
  caseResult: judge.case_result,
});

const prepareJudges = (fetchJudges) => fetchJudges.map(prepareJudge);

const convertJudges = (fetchJudges) => fetchJudges.reduce((accumulator, currentValue) => {
  accumulator.items = accumulator.items.concat(currentValue.id);
  accumulator.itemsMap = accumulator.itemsMap.set(currentValue.id, currentValue);

  return accumulator;
}, {
  items: [],
  itemsMap: Map(),
  shouldLoadJudges: false,
});

const updateCourt = (itemsMap, data) => {
  const judges = prepareJudges(data.results);
  const transformJudges = convertJudges(judges);

  const currentCourt = itemsMap.get(data.courtId);

  const court = {
    ...currentCourt,
    judges: transformJudges,
  };

  return itemsMap.set(data.courtId, court);
};

export interface ActionData {
  courtId: string | number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchJudgesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const { currentPageNumber } = state.courts;

  const currentCourtsMap = state.courts.pages[currentPageNumber].itemsMap;

  const data = {
    courtId: action.data.courtId,
    results: action.data.result,
  };

  const itemsMap = updateCourt(currentCourtsMap, data);

  return {
    ...state,
    courts: {
      ...state.courts,
      pages: {
        ...state.courts.pages,
        [currentPageNumber]: {
          ...state.courts.pages[currentPageNumber],
          itemsMap,
        },
      },
    },
  };
};

export default fetchJudgesReduce;
