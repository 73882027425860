import { changePageNumberReduce } from './changePageNumber.reduce';

const actionType = 'SEARCH/RESULT/DOCUMENTS/CHANGE_PAGE_NUMBER';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const changePageNumber = {
  actionType,
  createAction,
  reduce: changePageNumberReduce,
};

export default changePageNumber;
