import { Map } from 'immutable';

import {
  Selection,
  SelectionRule,
} from '@pages/searchResult/documents/redux/state';

export type DocumentId = number;

export type LayoutType = 'list' | 'table';

type Id = 'type_title' | 'date' | 'case_result' | 'department' | 'judges' | 'category_dispute_title' |
'case_type_title' | 'instance' | 'case_number' | 'appeal_state';

type Title = 'Результат обжалования' | 'Номер дела' | 'Исход рассмотрения' | 'Вид спора' | 'Категория спора' |
'Дата принятия' | 'Суд' | 'Инстанция рассмотрения' | 'Судья' | 'Тип документа';

interface Item {
  id: Id;
  showColumn: boolean;
  title: Title;
}

export interface Layout {
  viewType: LayoutType;
  tableView: {
    columns: {
      itemsList: string[];
      itemsMap: Map<Id, Item>;
    };
    defaultColumns: {
      itemsList: string[];
      itemsMap: Map<Id, Item>;
    }
  }
}

export type Total = number;
export type Loading = boolean;

export interface State {
  layout: Layout;
  shouldLoadSettings: boolean;
  totalDocuments: Total;
  totalPages: Total;
  selection: Selection;
  previewDocument: {
    currentPageNumber: number;
    documentId: number;
  };
  loading: Loading;
  currentPageNumber: number;
  pages: Record<any, any>;
}

export class StateBuilder {

  private readonly state: State;

  static createState() {
    return {
      shouldLoadSettings: true,
      showCommentary: false,
      layout: {
        viewType: 'list',
        tableView: {
          columns: {
            itemsList: [],
            itemsMap: Map(),
          },
          defaultColumns: [],
        },
      },
      totalDocuments: null,
      totalPages: null,
      previewDocument: {
        currentPageNumber: 1,
        documentId: null,
      },
      currentPageNumber: 1,
      selection: {
        rule: SelectionRule.include,
        ids: Map(),
      },
      pages: {},
      loading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

}

export default StateBuilder;
