import { fetchLayoutSettingsReduce } from './fetchLayoutSettings.reduce';

import type { ActionData } from './fetchLayoutSettings.reduce';

const actionType = 'REGULATION/FETCH_LAYOUT_SETTINGS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchLayoutSettings = {
  actionType,
  createAction,
  reduce: fetchLayoutSettingsReduce,
};

export default fetchLayoutSettings;
