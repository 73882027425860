import styled from 'styled-components';

export interface Props {
  size: string;
  color: string;
}

const style: any = {};

style.Icon = styled.i<Props>`
  display: inline-block;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  vertical-align: middle;
  fill: currentColor;
  color: ${(props) => props.color};

  svg {
    display: block;
  }
`;

export default style;
