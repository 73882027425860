import React from 'react';

import s from './text-input.style';

export interface TextInputProps {
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const defaultProps: TextInputProps = {
  placeholder: '',
  disabled: false,
  value: '',
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
};

export const TextInput: React.FC<TextInputProps> = ({ ...props }) => (
  <s.Input
    disabled={props.disabled}
    placeholder={props.placeholder}
    value={props.value}
    onBlur={props.onBlur}
    onFocus={props.onFocus}
    onChange={props.onChange}
  />
);

TextInput.defaultProps = defaultProps;

export default TextInput;
