import { Map } from 'immutable';

export const layoutTypes = {
  list: {
    id: 'list',
    title: 'Вид таблицей',
  },
  table: {
    id: 'table',
    title: 'Вид списком',
  },
};

function prepareItemsMap(layout) {
  let itemsMap = Map();

  layout.renderKeys.forEach((column) => {
    const model = {
      ...layout.presets[column.id],
      showColumn: column.render,
    };

    itemsMap = itemsMap.set(column.id, model);
  });

  return itemsMap;
}

function prepareItemsList(columns) {
  return columns.map((column) => column.id);
}

function prepareLayout(layout) {
  let viewType = '';

  const isTable = layout.viewType === layoutTypes.table.id;
  if (isTable) {
    viewType = layoutTypes.table.id;
  }

  const isList = layout.viewType === layoutTypes.list.id;
  if (isList) {
    viewType = layoutTypes.list.id;
  }

  const itemsList = prepareItemsList(layout.renderKeys);
  const itemsMap = prepareItemsMap(layout);

  return {
    viewType,
    tableView: {
      columns: {
        itemsList,
        itemsMap,
      },
      defaultColumns: layout.defaultRenderKeys,
    },
  };
}

export interface ActionData {
  result: {
    layout: any;
  };
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchSettingsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const fetchLayout = action.data.result.layout;
  const layout = prepareLayout(fetchLayout);

  const newState = {
    shouldLoadSettings: false,
    layout,
  };

  return {
    ...state,
    documents: {
      ...state.documents,
      ...newState,
    },
  };
};

export default fetchSettingsReduce;
