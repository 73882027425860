import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Id } from '@pages/regulation/workspace/redux/state';

import { increaseFoldersCount } from '../../workspace/redux/increaseFoldersCount';
import { decreaseFoldersCount } from '../../workspace/redux/decreaseFoldersCount';

import { addProject } from './addProject';
import { removeProject } from './removeProject';

import { httpAddProject } from './addProject.http';
import { httpRemoveProject } from './removeProject.http';

interface AddProjectToFolderParams {
  folderId: Id;
  projectId: Id;
  from: 'list' | 'preview';
}

export class AddProjectToFolderThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: AddProjectToFolderParams) {
    const state = this.getState().regulation.searchResult;
    const { selected } = state.itemsMap.get(params.folderId);

    if (!selected) {
      this.dispatchIncreaseFoldersCount(params.projectId);
      await this.addProject(params);

      return;
    }

    this.dispatchDecreaseFoldersCount(params.projectId);
    await this.removeProject(params);
  }

  private async addProject(params) {
    sendAmplitudeData('regulation:add_to_folder', { from: params.from });
    Userpilot.track('regulation:add_to_folder', { from: params.from });
    try {
      this.dispatchAddProjectSucceed(params);

      await httpAddProject(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async removeProject(params) {
    sendAmplitudeData('regulation:remove_from_folder', { from: params.from });
    Userpilot.track('regulation:remove_from_folder', { from: params.from });

    try {
      this.dispatchRemoveProjectSucceed(params);

      await httpRemoveProject(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchIncreaseFoldersCount(projectId) {
    this.dispatch(increaseFoldersCount.createAction({ projectId }));
  }

  private dispatchDecreaseFoldersCount(projectId) {
    this.dispatch(decreaseFoldersCount.createAction({ projectId }));
  }

  private dispatchAddProjectSucceed(params) {
    this.dispatch(addProject.createAction(params));
  }

  private dispatchRemoveProjectSucceed(params) {
    this.dispatch(removeProject.createAction(params));
  }

}

export function addProjectToFolderThunk(dispatch, params) {
  const thunk = new AddProjectToFolderThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddProjectToFolderThunk;
