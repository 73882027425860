import { RESTPath } from '@router/path.rest';
import http from '@http';

import {
  Id,
  BodyBlock,
} from './state';

export interface ProjectDTO {
  body: BodyBlock[];
}

export function httpFetchProject(projectId: Id): Promise<ProjectDTO> {
  const url = RESTPath.regulation.projects.preview.project(projectId);
  const request = http.post({
    url,
    data: {
      query: '',
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchProject;
