import { resetNotificationsReduce } from './resetToDefault.reduce';

const actionType = 'REGULATION/RESET_NOTIFICATIONS';

export interface Action {
  type: typeof actionType;
}

const createAction = (): Action => ({
  type: actionType,
});

export const resetNotifications = {
  actionType,
  createAction,
  reduce: resetNotificationsReduce,
};

export default resetNotifications;
