import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { AddPackageButton } from '../addPackage/addPackageButton';
import { AddItemPopover } from './addItemPopover';

import s from '../addPackage/addPackage.style';

interface AddFolderProps {
  count: number;
  items: [];
  usePortal?: boolean;
  disabled?: boolean;
  onInit(): void;
  onResetToInitial(): void;
  onItemToggle(id: number): void;
  onItemCreate(query: string): void;
}

export const AddFolder = (props: AddFolderProps) => (
  <s.Root>
    <BaseDropdown
      // @ts-ignore
      usePortal={props.usePortal}
      disabled={props.disabled}
      placement="bottom-end"
      renderInput={(p) => <AddPackageButton disabled={props.disabled} count={props.count} {...p} />}
      renderMenu={(p) => (
        <AddItemPopover
          placeholder="Найти или создать папку"
          items={props.items}
          onInit={props.onInit}
          onResetToInitial={props.onResetToInitial}
          onItemCreate={props.onItemCreate}
          onItemToggle={props.onItemToggle}
          {...p}
        />
      )}
    />
  </s.Root>
);

AddFolder.defaultProps = {
  disabled: false,
  usePortal: true,
};

export default AddFolder;
