import { StateBuilder } from './state';
import { StateBuilder as FiltersStateBuilder } from '../toolbar/redux/state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = StateBuilder.createState();
  newState.settingsLoading = state.projects.settingsLoading;
  newState.layout = state.projects.layout;

  const filters = FiltersStateBuilder.createState();

  return {
    ...state,
    filters,
    projects: newState,
  };
};

export default resetToInitialReduce;
