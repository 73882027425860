/* eslint-disable */

module.exports = {
 /*
  * https://github.com/oblador/angular-scroll (duScrollDefaultEasing)
  */
  defaultEasing : function (x) {
    'use strict';

    return 1 - Math.pow(2, -10 * x);
  }
};
