import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  position: relative;
  display: flex;
`;

style.Tooltip = styled.div`
  width: 150px;
  height: 40px;
  background: rgba(85, 99, 122, .9);
  border-radius: 2px;
  color: ${(props) => props.theme.colors.white};
  font-size: 11px;
  padding: 4px 8px;
  position: absolute;
  line-height: 16px;
  text-align: center;
  top: -44px;
  z-index: 1;
  right: calc(100% - 87px);
  display: none;
`;

style.Checkbox = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  :hover ~ .checkbox-tooltip {
    display: flex;
  }
`;

export default style;
