export const toggleCommentaryReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    documents: {
      ...state.documents,
      showCommentary: !state.documents.showCommentary,
    },
  };
};

export default toggleCommentaryReduce;
