export interface Category {
  id: string;
  title: string;
  color: string;
}

export const category = {
  document: {
    id: 'document',
    title: 'Документ',
    color: '#ffbe25',
  },
  member: {
    id: 'member',
    title: 'Принявший орган',
    color: '#59b671',
  },
  dispute: {
    id: 'dispute',
    title: 'Спор',
    color: '#4183d7',
  },
};

export default category;
