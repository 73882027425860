import http from '@http';
import {
  RESTPath,
  Source,
} from '@router/path.rest';

interface RequestParams {
  id: string;
  title: string;
  text: string;
  source: Source.search | Source.package | Source.authorPackage | Source.subscription;
  sourceId: number;
  documentId: number;
  paragraphId: number;
}

export function httpAddBookmark(requestParams: RequestParams) {
  const request = {
    url: RESTPath.bookmarks.addItem,
    data: requestParams,
  };

  return http
    .post(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpAddBookmark;
