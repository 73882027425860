import { connect } from 'react-redux';

import { Workspace } from './workspace';

const mapStateToProps = (state) => {
  const {
    totalPages,
  } = state.bookmarks;

  return {
    noItems: !totalPages,
  };
};

export const WorkspaceConnect = connect(
  mapStateToProps,
)(Workspace);

export default WorkspaceConnect;
