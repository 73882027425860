import {
  DocumentTypeBlock, DocumentTypeBlockData,
} from '../../form/filterBlocks/documentType/redux/documentType';

import { PresetsData } from './presets';

interface DocumentTypeBlockDataTransform {
  id: number
  presetsData: PresetsData
}

export abstract class DocumentTypeBlockHistory extends DocumentTypeBlock {

  static transform(data, presetsData: DocumentTypeBlockDataTransform): string {
    const title = presetsData[this.type].reduce((acc: string, el: DocumentTypeBlockData) => {
      let result = acc;
      const isEqual = el.id === data.id;

      if (isEqual) {
        result += el.title;

        const categoryTitle = data.category.title ? ` • ${data.category.title}` : '';
        result = `${result}${categoryTitle}`;
      }

      return result;
    }, '');

    return title;
  }

}

export default DocumentTypeBlockHistory;
