import { connect } from 'react-redux';

import { CellItemWithTooltip } from './itemWithTooltip';
import { changeSortThunk } from '../redux/changeSort.thunk';

const mapStateToProps = (state, ownProps) => {
  const {
    sortData,
    documents: { shouldLoadDocuments },
  } = state.searchResult.law;

  const isSortActive = ownProps.id === sortData.id;
  const isHeaderText = true;

  return {
    sortData,
    isSortActive,
    shouldLoadDocuments,
    isHeaderText,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSort() {
    changeSortThunk(dispatch, ownProps.id);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const onClick = () => {
    const { shouldLoadDocuments } = stateProps;

    if (!shouldLoadDocuments) {
      return dispatchProps.onSort();
    }

    return null;
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onClick,
  };
};

export const CellItemWithTooltipConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CellItemWithTooltip);

export default CellItemWithTooltipConnect;
