import { combineReducers } from 'redux';

import { reducerBuilder as currentUser } from '@pages/userPages/redux/reducer';
import { reducerBuilder as search } from '@pages/search/redux/reducer';
import { reducerBuilder as searchResult } from '@pages/searchResult/redux/reducer';
import { reducerBuilder as packages } from '@pages/packages/redux/reducer';
import { reducerBuilder as authorPackages } from '@pages/authorPackages2/redux/reducer';
import { reducerBuilder as subscriptions } from '@pages/subscriptions/redux/reducer';
import { reducerBuilder as regulation } from '@pages/regulation/redux/reducer';
import { reducerBuilder as document } from '@pages/document/redux/reducer';
import { reducerBuilder as bookmarks } from '@pages/bookmarks/redux/reducer';
import { reducerBuilder as lawDocument } from '@pages/lawDocument/redux/reducer';
import { reducerBuilder as annotations } from '@layouts/documentHelpers/annotation/redux/reducer';
import { reducerBuilder as summary } from '@layouts/documentHelpers/summary/redux/reducer';
import { reducerBuilder as bookmarksBuffer } from '@layouts/bookmarks/redux/reducer';
import { reducerBuilder as notificationsBuffer } from '@layouts/notifications/redux/reducer';
import { reducerBuilder as chronology } from '@layouts/chronology/redux/reducer';
import { reducerBuilder as similar } from '@layouts/similar/redux/reducer';
import { reducerBuilder as downloads } from '@providers/download/reducer';

import { ActionTypeApp } from './action.type';

const appReducer = combineReducers({
  currentUser: currentUser.build(),
  packages: packages.build(),
  authorPackages: authorPackages.build(),
  subscriptions: subscriptions.build(),
  regulation: regulation.build(),
  search: search.build(),
  chronology: chronology.build(),
  similar: similar.build(),
  searchResult: searchResult.build(),
  document: document.build(),
  downloads: downloads.build(),
  bookmarks: bookmarks.build(),
  bookmarksBuffer: bookmarksBuffer.build(),
  notificationsBuffer: notificationsBuffer.build(),
  lawDocument: lawDocument.build(),
  annotations: annotations.build(),
  summary: summary.build(),
});

const rootReducer = (state, action) => {
  if (action.type === ActionTypeApp.INITIAL_STATE) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
