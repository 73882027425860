import { changeContainsReduce } from './changeContains.reduce';
import { SEARCH_FORM_BLOCK } from '../../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/CHANGE_CONTAINS`;

const createAction = (data) => ({
  type: actionType,
  data,
});

export const changeContains = {
  actionType,
  createAction,
  reduce: changeContainsReduce,
};

export default changeContains;
