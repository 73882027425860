import styled from 'styled-components';

const style: any = {};

style.ContentContainer = styled.div`
  padding: 16px 0 64px;
`;

style.ItemsContent = styled.div`
  width: 100%;
  position: relative;
`;

style.PageLoader = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
`;

export default style;
