export const pageLoadingStartReduce = (state) => ({
  ...state,
  documents: {
    ...state.documents,
    pageLoading: true,
  },
});

export const pageLoadingEndReduce = (state) => ({
  ...state,
  documents: {
    ...state.documents,
    pageLoading: false,
  },
});
