import { resetDocumentToInitialReduce } from './resetDocumentToInitial.reduce';

const actionType = 'LAW_DOCUMENT/RESET_DOCUMENT_TO_INITIAL';

const createAction = () => ({
  type: actionType,
});

export const resetDocumentToInitial = {
  actionType,
  createAction,
  reduce: resetDocumentToInitialReduce,
};

export default resetDocumentToInitial;
