import { Dispatch } from 'redux';

import { httpFetchUsers } from './fetchUsers.http';
import { fetchUsers } from './fetchUsers';
import { Id } from './state';

interface Params {
  folderId: Id;
}

export class FetchUsersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    try {
      const result = await httpFetchUsers(params.folderId);
      this.dispatchFetchSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(fetchUsers.createAction(result));
  }

}

export async function fetchUsersThunk(dispatch, params) {
  const thunk = new FetchUsersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default FetchUsersThunk;
