import { connect } from 'react-redux';

import { updateTitleThunk } from './redux/updateTitle.thunk';

import { Id } from './itemMenu';

import {
  Title,
  UpdateTitle,
} from './updateTitle';

const mapStateToProps = (state, ownProps) => {
  const title = state.regulation.smartFolders.itemsMap.get(ownProps.id).title;

  return {
    title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSaveTitle(id: Id, title: Title) {
    const data = {
      id,
      title,
    };

    updateTitleThunk(dispatch, data);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const onSaveTitle = (title: Title) => (
    dispatchProps.onSaveTitle(ownProps.id, title)
  );

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSaveTitle,
  };
};

export const UpdateTitleConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(UpdateTitle);

export default UpdateTitleConnect;
