import { StateBuilder } from './state';

export interface ActionData {
  id: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const decreaseDocumentsCountReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.packages);
  const data = {
    id: action.data.id,
  };

  newState.decreaseCount(data);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default decreaseDocumentsCountReduce;
