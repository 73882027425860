import { store } from '@redux/store';

export const paymentProcessStartReduce = (state: ReturnType<typeof store.getState>) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    paymentInProgress: true,
  };
};

export default paymentProcessStartReduce;
