import { fetchSubscriptionsReduce } from './fetchSubscriptions.reduce';

import type { ActionData } from './fetchSubscriptions.reduce';

const actionType = 'SUBSCRIPTIONS/FETCH_SUBSCRIPTIONS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSubscriptions = {
  actionType,
  createAction,
  reduce: fetchSubscriptionsReduce,
};

export default fetchSubscriptions;
