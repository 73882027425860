export const resetActiveHistoryItemReduce = (state) => {
  const history = {
    ...state.history,
    activeHistoryId: null,
  };

  return {
    ...state,
    history,
  };
};

export default resetActiveHistoryItemReduce;
