import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
  isDisabled: boolean;
}

style.Pagination = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0 8px;

  align-items: center;

  font-size: 13px;

  height: 40px;

  background-color: ${(props: Props) => props.theme.colors.white};

  border-bottom: 1px solid #dfe2e6;
`;

style.ArrowItem = styled.div`
  display: flex;
  height: 32px;
  width: 32px;

  cursor: pointer;
  color: ${(props: Props) => props.theme.colors.blue};

  align-items: center;
  justify-content: center;

  :hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  ${(props: Props) => props.isDisabled && css`
    cursor: default;
    color: ${props.theme.colors.disabled};

    :hover {
      background-color: transparent;
    }
  `}
`;

export default style;
