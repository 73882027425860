import {
  toggleNotificationReduce,
  ActionData,
} from './toggleNotification.reduce';

const actionType = 'SUBSCRIPTIONS/TOGGLE_NOTIFICATION';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const toggleNotification = {
  actionType,
  createAction,
  reduce: toggleNotificationReduce,
};

export default toggleNotification;
