import React from 'react';
import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { BaseInput } from '../../components/baseInput/baseInput';

import { TextBlock } from './redux/text';

import { Checkbox } from './checkbox';
import { ProximityRangeLabel } from './proximityRangeLabel';

import s from './settings.style';

interface SettingsProps {
  value: string;
  findIn: string;
  mode: string;
  proximityRange: number;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  onChangeFindIn: (findIn: any) => void;
  onChangeMode: (mode:any) => void;
  onUpdateProximityRange: (proximityRange: number) => void;
}

export class Settings extends React.PureComponent<SettingsProps> {

  prepareInputValue = () => {
    const findInTitle = TextBlock.presets.findIn[this.props.findIn].title;
    const modeTitle = TextBlock.presets.mode[this.props.mode].title;
    let value = findInTitle;

    const isExactlyMode = this.props.mode === TextBlock.presets.mode.exactly.id;
    const showPostfix = !isExactlyMode || (isExactlyMode && this.shouldRenderExtendSettings());
    if (showPostfix) {
      value = `${value}, ${modeTitle}`;
    }

    const isProximityMode = this.props.mode === TextBlock.presets.mode.proximity.id;
    if (isProximityMode) {
      return `${value} ${this.props.proximityRange}`;
    }

    return value;
  };

  shouldRenderExtendSettings() {
    const showExtendSettings = this.props.value &&
      this.props.value.replace(/ +/g, ' ').trim().split(' ')
        .length > 1;

    return showExtendSettings;
  }

  renderInput() {
    const value = this.prepareInputValue();

    return (<BaseInput value={value} />);
  }

  renderBaseSection() {
    const isDocument = this.props.findIn === TextBlock.presets.findIn.document.id;
    const isResolution = this.props.findIn === TextBlock.presets.findIn.resolution.id;

    return (
      <s.BaseSection
        showExtendSettings={this.shouldRenderExtendSettings()}
      >
        <s.SectionContent>
          <Checkbox
            isActive={isDocument}
            item={TextBlock.presets.findIn.document}
            onClick={this.props.onChangeFindIn}
          >
            {TextBlock.presets.findIn.document.title}
          </Checkbox>
          <Checkbox
            isActive={isResolution}
            item={TextBlock.presets.findIn.resolution}
            onClick={this.props.onChangeFindIn}
          >
            {TextBlock.presets.findIn.resolution.title}
          </Checkbox>
        </s.SectionContent>
      </s.BaseSection>
    );
  }

  renderExtendSection() {
    const isExactly = this.props.mode === TextBlock.presets.mode.exactly.id;
    const isDefault = this.props.mode === TextBlock.presets.mode.default.id;
    const isParagraph = this.props.mode === TextBlock.presets.mode.paragraph.id;
    const isProximity = this.props.mode === TextBlock.presets.mode.proximity.id;
    const isSentence = this.props.mode === TextBlock.presets.mode.sentence.id;

    return (
      <s.ExtendSection>
        <s.SectionContent>
          <Checkbox
            isActive={isExactly}
            item={TextBlock.presets.mode.exactly}
            onClick={this.props.onChangeMode}
          >
            {TextBlock.presets.mode.exactly.title}
          </Checkbox>
          <Checkbox
            isActive={isProximity}
            item={TextBlock.presets.mode.proximity}
            onClick={this.props.onChangeMode}
          >
            <ProximityRangeLabel
              title={TextBlock.presets.mode.proximity.title}
              proximityRange={this.props.proximityRange}
              onUpdateProximityRange={this.props.onUpdateProximityRange}
            />
          </Checkbox>
          <Checkbox
            isActive={isSentence}
            item={TextBlock.presets.mode.sentence}
            onClick={this.props.onChangeMode}
          >
            {TextBlock.presets.mode.sentence.title}
          </Checkbox>
          <Checkbox
            isActive={isParagraph}
            item={TextBlock.presets.mode.paragraph}
            onClick={this.props.onChangeMode}
          >
            {TextBlock.presets.mode.paragraph.title}
          </Checkbox>
          <Checkbox
            isActive={isDefault}
            item={TextBlock.presets.mode.default}
            onClick={this.props.onChangeMode}
          >
            {TextBlock.presets.mode.default.title}
          </Checkbox>
        </s.SectionContent>
      </s.ExtendSection>
    );
  }

  renderSections() {
    if (!this.shouldRenderExtendSettings()) {
      return (
        <>
          {this.renderBaseSection()}
        </>
      );
    }

    return (
      <>
        {this.renderBaseSection()}
        {this.renderExtendSection()}
      </>
    );
  }

  renderDropdown() {
    return (
      <s.Dropdown>
        {this.renderSections()}
      </s.Dropdown>
    );
  }

  render() {
    return (
      <s.Settings>
        <BaseDropdown
          disabled={this.props.disabled}
          renderInput={() => (this.renderInput())}
          renderMenu={() => (this.renderDropdown())}
        />
      </s.Settings>
    );
  }

}

export default Settings;
