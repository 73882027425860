import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { resetProjects } from '../../redux/resetProjects';
import { fetchProjectsThunk } from '../../redux/fetchProjects.thunk';
import { fetchFolderThunk } from '../../redux/fetchFolder.thunk';

import { FilterType } from './state';

import { updateFilter } from './updateFilter';
import { uncheckProjects } from '../../redux/uncheckProjects';

export class UpdateTextThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public invoke(value) {

    const type = FilterType.text;
    const data = {
      value,
    };

    this.dispatch(resetProjects.createAction());
    this.dispatch(uncheckProjects.createAction());
    this.dispatch(updateFilter.createAction({ type, data }));

    this.fetchProjects();

    sendAmplitudeData('regulation:update_filter_text', { data });
    Userpilot.track('regulation:update_filter_text', { data });
  }

  private fetchProjects() {
    const folderId = this.getState().regulation.folders.activeItemId;

    if (folderId) {
      fetchFolderThunk(this.dispatch);
    } else {
      fetchProjectsThunk(this.dispatch);
    }
  }

}

export function updateTextThunk(dispatch, value) {
  const thunk = new UpdateTextThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(value);
}
