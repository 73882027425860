import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { PackageList } from './packageList';

function containSymbols(title, query) {
  const lowerTitle = title.toLowerCase();
  const lowerQuery = query.toLowerCase();

  return lowerTitle.includes(lowerQuery);
}

export function buildItems(state, query) {
  const selectedArray = [];
  const nonSelectedArray = [];

  const { itemsList, itemsMap } = state;

  itemsList.forEach((id) => {
    const {
      title,
      selected,
    } = itemsMap.get(id);

    const contain = containSymbols(title, query);

    if (!contain) {
      return;
    }

    if (selected) {
      // @ts-ignore
      selectedArray.push(itemsMap.get(id));
    } else {
      // @ts-ignore
      nonSelectedArray.push(itemsMap.get(id));
    }
  });

  return {
    selectedArray,
    nonSelectedArray,
  };
}

const mapStateToProps = (state, ownProps) => {
  const searchQuery = ownProps.searchQuery.trim();
  const packages = ownProps.packages;

  return {
    searchQuery,
    items: buildItems(packages, searchQuery),
    shouldRenderEmptyBlock: isEmpty(packages.itemsList),
    addingPackage: packages.addingPackage,
    loading: packages.loading,
  };
};

export const PackageListConnect = connect(
  mapStateToProps,
)(PackageList);

export default PackageListConnect;
