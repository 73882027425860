import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './downloadLayout.style';

import { DownloadButtonConnect } from '../downloadButton/downloadButton.connect';

export class DownloadLayout extends React.PureComponent {

  private renderIcon() {
    const iconPath = iconsPath.get('serviceError');

    return (
      <s.Icon>
        <Icon viewBoxSize={72} size={72} path={iconPath} color="#6c7b89" />
      </s.Icon>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderIcon()}
        <s.Container>
          <s.Title>
            Раздел находится в разработке
          </s.Title>
          <s.Paragraph>
            Скоро здесь будут отображаться все комментарии вашей
          </s.Paragraph>
          <s.Paragraph>
            команды к проектам, а пока вы можете их скачать.
          </s.Paragraph>
        </s.Container>
        <DownloadButtonConnect title="Скачать комментарии" />
      </s.Root>
    );
  }

}

export default DownloadLayout;
