import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'redux';

import { fetchSubscriptions } from './fetchSubscriptions';
import { selectSubscriptionThunk } from './selectSubscription.thunk';

import { httpFetchSubscriptions } from './fetchSubscriptions.http';

export class FetchSubscriptionsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: string }) {
    try {
      const result = await httpFetchSubscriptions({});
      this.dispatchFetchSucceed(result);

      if (isEmpty(result.subscriptions)) {
        return;
      }

      let idFromUrlParams = null;
      if (params.id) {
        idFromUrlParams = Number(params.id);
      }

      const sourceId = result.subscriptions[0].id;
      selectSubscriptionThunk(this.dispatch, { idFromUrlParams, sourceId });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      items: result.subscriptions,
    };

    this.dispatch(fetchSubscriptions.createAction(actionData));
  }

}

export function fetchSubscriptionsThunk(dispatch, params?) {
  const thunk = new FetchSubscriptionsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchSubscriptionsThunk;
