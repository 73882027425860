import React from 'react';
import {
  Icon,
  iconsPath,
} from '@components/icons';

import { theme } from '@components/styled';

import s from './checkbox.style';

interface Props {
  onClick?: () => void;
  checked?: boolean;
  disable?: boolean;
  isHalf?: boolean;
}

const defaultProps = {
  checked: false,
  disable: false,
  isHalf: false,
  onClick: () => {},
};

export const Checkbox:React.FC<Props> = ({ ...props }) => {
  let iconPathName = 'checkboxUnchecked';

  if (props.checked && !props.isHalf) {
    iconPathName = 'checkboxChecked';
  }

  if (props.checked && props.isHalf) {
    iconPathName = 'checkboxHalfChecked';
  }

  let iconColor = props.checked ?
    theme.colors.blue :
    theme.colors.defaultText;

  if (props.disable) {
    iconColor = theme.colors.disabled;
  }

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (props.disable) {
      return;
    }

    // @ts-ignore
    props.onClick();
  };

  return (
    <s.Root
      onClick={handleClick}
      checked={props.checked}
    >
      <Icon
        color={iconColor}
        path={iconsPath.get(iconPathName)}
      />
    </s.Root>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
