import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpRemoveFolder(requestParams: { id: string }) {
  const request = http.delete({
    url: RESTPath.regulation.folders.work(requestParams),
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpRemoveFolder;
