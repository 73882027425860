import React from 'react';

import list from './list.style';

import s from './cellMore.style';

interface CellMoreProps {
  children?: React.ReactElement;
}

export const CellMore = (props: CellMoreProps) => (
  <s.CellMore>
    <list.BodyCellContainer>
      {props.children}
    </list.BodyCellContainer>
  </s.CellMore>
);

export default CellMore;
