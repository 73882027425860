import { fetchCourtsStartReduce } from './fetchCourtsStart.reduce';
import { fetchCourtsReduce } from './fetchCourts.reduce';

import type { ActionData } from './fetchCourts.reduce';

const actionTypeStart = 'SEARCH/RESULT/FETCH_COURTS_START';

const createActionStart = () => ({
  type: actionTypeStart,
});

const actionTypeSucceed = 'SEARCH/RESULT/FETCH_COURTS';

const createActionSucceed = (data: ActionData) => ({
  type: actionTypeSucceed,
  data,
});

export const fetchCourtsStart = {
  actionType: actionTypeStart,
  createAction: createActionStart,
  reduce: fetchCourtsStartReduce,
};

export const fetchCourtsSucceed = {
  actionType: actionTypeSucceed,
  createAction: createActionSucceed,
  reduce: fetchCourtsReduce,
};

export default fetchCourtsSucceed;
