import { v4 as uuid } from 'uuid';
import { Dispatch } from 'redux';

import { Source } from '@router/path.rest';

import { notifierManager } from '@components/notification';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { Broadcaster } from '@utils/broadcaster';

import { httpAddBookmark } from './addBookmark.http';
import { addBookmark } from './addBookmark';

interface Params {
  title: string;
  text: string;
  source: Source.search | Source.package | Source.authorPackage | Source.subscription;
  sourceId: number;
  documentId: number;
  paragraphId: number;
  path: string;
  commentary?: string;
}

export class AddBookmarkThunk {

  getState;

  private notificationId = 'SAVE_BOOKMARKS_IN_PACKAGE_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    sendAmplitudeData('add bookmark');
    Userpilot.track('add bookmark');

    const state = this.getState();

    if (state.bookmarksBuffer.loading) {
      return;
    }

    const text = params.text
      .replace(/[\n\r]+|[\s]{2,}/g, ' ')
      .replace(/ {2}/gi, ' ')
      .trim()
      .slice(0, 128);

    const bookmark = {
      id: uuid(),
      ...params,
      text,
      commentary: params.commentary || '',
    };

    this.dispatchAddBookmark(bookmark);
    this.broadcastAddBookmark(bookmark);
    await this.createBookmarkRequest(bookmark);
  }

  private broadcastAddBookmark(params) {
    const broadcaster = Broadcaster.getInstance();

    const message = {
      type: addBookmark.actionType,
      data: params,
    };

    broadcaster.postMessage(message);
  }

  private async createBookmarkRequest(params) {
    try {
      await httpAddBookmark(params);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }

      throw Error(error.status);
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchAddBookmark(params) {
    this.dispatch(addBookmark.createAction(params));
  }

}

export function addBookmarkThunk(dispatch, params) {
  const thunk = new AddBookmarkThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddBookmarkThunk;
