import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';

import { addSummary } from './addSummary';
import { createSummary } from './createSummary';
import { updateFetchError } from './updateFetchError';
import { toggleSummary } from './toggleSummary';
import { updateSummaryRating } from './updateSummaryRating';

export const reducerBuilder = new ReducerBuilder([
  addSummary,
  createSummary,
  updateFetchError,
  toggleSummary,
  updateSummaryRating,
]);

reducerBuilder.setInitialState(StateBuilder.createInitial());

export default reducerBuilder;
