import { courtsSettings } from './settings';

import type { Layout } from './settings';

import {
  sortDataDefault,
  SortDataProps,
} from '../../components/sortIcon/sortIcon';

export interface CourtsState {
  shouldLoadSettings: boolean;
  shouldLoadCourts: boolean;
  pageLoading: boolean;
  initialLoading: boolean;
  layout: Layout;
  totalPages: number;
  currentPageNumber: number;
  pages: Record<any, any>;
  sortData: SortDataProps;
}

export const getDefaultDataOfCourts = () => ({
  shouldLoadCourts: true,
  pageLoading: true,
  initialLoading: true,
  totalPages: null,
  currentPageNumber: 1,
  pages: {},
});

export function buildCourtsState(): CourtsState {
  const layout = courtsSettings.initial();
  const defaultDataOfCourts = getDefaultDataOfCourts();

  // @ts-ignore
  return {
    ...defaultDataOfCourts,
    shouldLoadSettings: true,
    layout,
    sortData: sortDataDefault,
  };
}

export default buildCourtsState;
