import {
  FilterType,
  FilterData,
  StateBuilder,
} from './state';

export interface ActionData {
  type: FilterType;
  data: FilterData,
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateFilterReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const filters = new StateBuilder(state.filters);
  filters.updateFilter(action.data.type, action.data);

  return {
    ...state,
    filters: filters.getState(),
  };
};

export default updateFilterReduce;
