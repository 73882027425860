import React from 'react';

import { Loader } from '@components/loader';
import { OpenDrawer } from '@components/openDrawer';

import { isEqualDates } from '../../utils';
import { PreviewConnect } from '../preview/preview.connect';

import { HeaderConnect } from './header.connect';
import { List } from './list';

import type {
  Id,
  ItemProps,
} from './item';

import { FindByClickLayout } from './findByClickLayout';
import { EmptyLayout } from './emptyLayout';

import s from './events.style';

interface ActiveDate {
  year: number;
  month: number;
  day: number;
}

interface EventsProps {
  activeDate: ActiveDate;
  nearestActiveDate: ActiveDate;
  items: ItemProps[];
  hasProjects: boolean;
  eventsLoading: boolean;
  onFetchEvents(activeDate: ActiveDate): void;
  onSelectNearestDate(activeDate: ActiveDate): void;
}

interface EventsState {
  showPreview: boolean;
  projectPreviewId: Id;
}

export class Events extends React.PureComponent<EventsProps, EventsState> {

  state = {
    showPreview: false,
    projectPreviewId: null,
  };

  componentDidMount(): void {
    this.fetchEvents(this.props.activeDate);
  }

  componentDidUpdate(prevProps: Readonly<EventsProps>): void {
    if (!isEqualDates(prevProps.activeDate, this.props.activeDate)) {
      this.fetchEvents(this.props.activeDate);
    }
  }

  showPreview = (id: Id) => {
    this.setState({
      showPreview: true,
      projectPreviewId: id,
    });
  };

  hidePreview = () => {
    this.setState({
      showPreview: false,
      projectPreviewId: null,
    });
  };

  handleItemClick = (id: Id) => {
    this.showPreview(id);
  };

  handleCloseDrawer = () => {
    this.hidePreview();
  };

  handleSelectNearest = () => {
    this.props.onSelectNearestDate(this.props.nearestActiveDate);
  };

  fetchEvents(date: ActiveDate) {
    if (!date.year) {
      return;
    }

    this.props.onFetchEvents(this.props.activeDate);
  }

  renderLayout() {
    return (
      <>
        {this.renderDocuments()}
        {this.renderDrawer()}
      </>
    );
  }

  renderDrawer = () => {
    if (!this.state.showPreview) {
      return null;
    }

    return (
      <OpenDrawer
        anchor="right"
        withBackground={false}
        component={(
          <PreviewConnect
            id={this.state.projectPreviewId}
            onClose={this.handleCloseDrawer}
          />
        )}
        onClickOutside={this.handleCloseDrawer}
      />
    );
  };

  renderFindByClick() {
    return (
      <FindByClickLayout
        onSelectNearest={this.handleSelectNearest}
      />
    );
  }

  renderEmptyLayout() {
    return (
      <EmptyLayout />
    );
  }

  renderDocuments() {
    if (!this.props.hasProjects) {
      return this.renderEmptyLayout();
    }

    if (!this.props.activeDate.year) {
      return this.renderFindByClick();
    }

    return (
      <s.Content>
        <s.ContentContainer>
          <HeaderConnect />
          <s.ItemsContent>
            <List
              activeId={this.state.projectPreviewId}
              items={this.props.items}
              onItemClick={this.handleItemClick}
            />
          </s.ItemsContent>
        </s.ContentContainer>
      </s.Content>
    );
  }

  renderContent() {
    if (this.props.eventsLoading) {
      return <Loader />;
    }

    return this.renderLayout();
  }

  render() {
    return (<s.Root>{this.renderContent()}</s.Root>);
  }

}

export default Events;
