import { fetchSourceTitleReduce } from './fetchSourceTitle.reduce';

import type { ActionData } from './fetchSourceTitle.reduce';

const actionType = 'DOCUMENT/FETCH_SOURCE_TITLE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSourceTitle = {
  actionType,
  createAction,
  reduce: fetchSourceTitleReduce,
};

export default fetchSourceTitle;
