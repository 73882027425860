import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpUnpublishAuthorPackage(requestParams) {
  const url = RESTPath.authorPackage.unpublish;
  const request = http.post({
    url,
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUnpublishAuthorPackage;
