export interface ActionData {
  id: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const subscriptionCreateReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    subscription: {
      id: action.data.id,
      process: false,
    },
  };
};

export default subscriptionCreateReduce;
