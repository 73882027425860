import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchToken() {
  const request = {
    url: RESTPath.user.token,
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(async (response) => {
      const data = await http.parseResponse(response);
      const userTokenExist = !!data.user;
      if (!userTokenExist) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('User token not created');
      }

      const companyTokenExist = !!data.company;
      if (!companyTokenExist) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Company token not created');
      }

      return data;
    })
    .catch();
}

export default httpFetchToken;
