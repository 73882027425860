import { subscriptionProcessStartReduce } from './subscriptionProcessStart.reduce';

const actionType = 'SEARCH/RESULT/SUBSCRIPTION_PROCESS__START';

const createAction = () => ({
  type: actionType,
});

export const subscriptionProcessStart = {
  actionType,
  createAction,
  reduce: subscriptionProcessStartReduce,
};

export default subscriptionProcessStart;
