import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './removeButton.style';

type Props = {
  onClick: () => void,
};

const crossPath = iconsPath.get('cross');

export const RemoveButton = ({ onClick }: Props) => (
  <s.RemoveButton
    onClick={onClick}
  >
    <Icon path={crossPath} color="#6c7b89" />
  </s.RemoveButton>
);

export default RemoveButton;
