import React, {
  useState, useEffect,
} from 'react';

import { TextInput } from '@components/textInput';
import {
  BaseDropdown,
  RenderElement,
} from '@components/baseDropdown/baseDropdown';

import {
  List, ListItem,
} from '../baseOptionList/list';

import s from './suggest.style';

export interface SuggestProps {
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  useAnchorWidth? : boolean;
  width?: string;
  activeItem: ListItem;
  getItems: (value: string) => Promise<any>;
  onChange: (data: any) => void;
}

export const Suggest = (props: SuggestProps) => {
  const [items, setItems] = useState([]);
  const [initialItem] = useState(props.activeItem);
  const [value, setValue] = useState(props.activeItem.title);

  useEffect(() => {
    setValue(props.activeItem.title);
  }, [props]);

  const getItems: (query: string) => void = (query: string) => {
    props.getItems(query)
      .then((data) => {
        setItems(data.items);
      });
  };

  // @ts-ignore
  const handleChangeValue = (e) => {
    setValue(e.target.value);

    const trimValue = e.target.value.trim();

    if (!trimValue) {
      return;
    }

    getItems(trimValue);
  };

  const handleBlur = () => {
    const { title } = props.activeItem;

    if (title) {
      setValue(title);
    }
  };

  const renderInput:() => JSX.Element = () => (
    <s.TextInput>
      <TextInput
        placeholder={props.placeholder}
        disabled={props.disabled}
        value={value}
        onChange={handleChangeValue}
        onBlur={handleBlur}
      />
    </s.TextInput>
  );

  // @ts-ignore
  const renderMenu: RenderElement = ({ ...p }) => {
    const isMenuHidden: boolean = !items.length || !value;
    if (isMenuHidden) {
      return null;
    }

    return (
      <List
        // @ts-ignore
        width={props.width}
        activeItem={initialItem}
        items={items}
        onChange={props.onChange}
        {...p}
      />
    );
  };

  return (
    <s.BlockType>
      <BaseDropdown
        forceMenuVisible
        useAnchorWidth={props.useAnchorWidth}
        disabled={props.disabled}
        renderInput={() => renderInput()}
        renderMenu={(p) => renderMenu(p)}
      />
    </s.BlockType>
  );
};

Suggest.defaultProps = {
  placeholder: '',
  disabled: false,
  useAnchorWidth: false,
  value: '',
  width: null,
};

export default Suggest;
