import React from 'react';

import { TextInput } from '@components/textInput';
import { Select } from '@pages/search/form/components/select/select';
import { Button } from '@components/button';

import { Id } from '../workspace/redux/state';

import s from './inviteForm.style';

interface InviteFormProps {
  onInvite(query: string, role: Role): void;
  id: Id;
}

interface Role {
  id: string;
  title: string;
}

export class InviteForm extends React.PureComponent<InviteFormProps> {

  static roles: Role[] = [
    {
      id: 'editor',
      title: 'Редактор',
    },
    {
      id: 'visitor',
      title: 'Наблюдатель',
    },
  ];

  state = {
    query: '',
    role: InviteForm.roles[1],
  };

  handleChangeQuery = (e) => {
    this.setState({
      query: e.target.value,
    });
  };

  handleChangeRole = (role) => {
    this.setState({
      role,
    });
  };

  handleSubmit = () => {
    this.props.onInvite(this.state.query, this.state.role);
  };

  render() {
    return (
      <s.Root>
        <s.Input>
          <TextInput
            placeholder="Укажите один или несколько Email"
            value={this.state.query}
            onChange={this.handleChangeQuery}
          />
          <s.Select>
            <Select
              useAnchorWidth
              activeItem={this.state.role}
              list={InviteForm.roles}
              onChange={this.handleChangeRole}
            />
          </s.Select>
        </s.Input>
        <s.Action>
          <Button
            onClick={this.handleSubmit}
          >
            Пригласить
          </Button>
        </s.Action>
      </s.Root>
    );
  }

}

export default InviteForm;
