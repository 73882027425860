import {
  styled, theme,
} from '@components/styled';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: row;
`;

style.Input = styled.div`
  height: 48px;
  width: 784px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  border: 1px solid ${theme.colors.borderColor};
  position: relative;
  margin-right: 16px;

  > div {
    background: transparent;
  }

  > input {
    height: 100%;
    position: absolute;
    width: 100%;
    padding-right: 150px;


    :focus {
      background-color: #4183D712;
    }

    :active {
      background-color: #4183D712;
    }
  }
`;

style.Select = styled.div`

`;

style.Action = styled.div`
  > button {
    width: 160px;
  }
`;

export default style;
