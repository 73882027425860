import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchUsers } from './fetchUsers';
import { setLoading } from './setLoading';
import { changeRole } from './changeRole';
import { resetToDefault } from './resetToDefault';

export const reducerBuilder = new ReducerBuilder([
  fetchUsers,
  setLoading,
  changeRole,
  resetToDefault,
]);

export default reducerBuilder;
