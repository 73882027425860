import { updateViewReduce } from './updateView.reduce';

const actionType = 'SEARCH/RESULT/LAW/SETTINGS/UPDATE_VIEW_SETTINGS';
export const resetActionType = 'SEARCH/RESULT/LAW/SETTINGS/UPDATE_VIEW_SETTINGS__RESET_SORT';

const createAction = (data) => ({
  type: actionType,
  data,
});

const resetCreateAction = (data) => ({
  type: resetActionType,
  data,
});

export const updateView = {
  actionType,
  createAction,
  reduce: updateViewReduce,
};

export const updateViewReset = {
  actionType: resetActionType,
  createAction: resetCreateAction,
  reduce: updateViewReduce,
};

export default updateView;
