import { store } from '@redux/store';

export interface ActionData {
  totalLicenseDays: number;
  type: string;
}

export interface Action {
  type: string;
  data: ActionData;
}

export const paymentProcessEndReduce = (state: ReturnType<typeof store.getState>, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    paymentInProgress: false,
    isActive: action.data.totalLicenseDays > 0,
    licenseEndDate: null,
    licenceEndDays: action.data.totalLicenseDays,
  };
};

export default paymentProcessEndReduce;
