import { StateBuilder } from './state';

export interface ActionData {
  packageId: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const addDocumentToPackageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.searchResult);
  const searchResult = newState.toggleSelected(action.data.packageId);

  return {
    ...state,
    searchResult,
  };
};

export default addDocumentToPackageReduce;
