import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { UserRoleType } from '@pages/userPages/redux/state';

import { fetchSummaryThunk } from '@layouts/documentHelpers/summary/redux/fetchSummary.thunk';
import { updateSummaryRatingThunk } from '@layouts/documentHelpers/summary/redux/updateSummaryRating.thunk';
import { toggleSummary } from '@layouts/documentHelpers/summary/redux/toggleSummary';
import { Summary } from '@layouts/documentHelpers/summary/summary';

const mapStateToProps = (state) => {
  const id = state.document.id;
  const isReal = state.currentUser.role !== UserRoleType.guest;

  const summary = state.summary.items.get(id);

  const hasError = summary && summary.fetchError;
  const inProgress = summary && summary.inProgress;
  const isExpanded = summary && summary.isExpanded;
  const rating = summary && summary.rating;

  return {
    id,
    isReal,
    rating,
    shouldRender: !!summary,
    inProgress,
    isExpanded,
    hasError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreate(id) {
    fetchSummaryThunk(dispatch, { documentId: id });

    sendAmplitudeData('caselook:annotation create', { documentId: id });
    Userpilot.track('caselook:annotation create', { documentId: id });
  },
  onRatingUpdate(data) {
    updateSummaryRatingThunk(dispatch, { documentId: data.documentId, value: data.value });

    sendAmplitudeData('caselook:summary rating update', { documentId: data.documentId, rating: data.value });
    Userpilot.track('caselook:summary rating update', { documentId: data.documentId, rating: data.value });
  },
  onToggle(id) {
    dispatch(toggleSummary.createAction({ documentId: id }));

    sendAmplitudeData('caselook:summary open', { documentId: id });
    Userpilot.track('caselook:summary open', { documentId: id });
  },
});

export const SummaryConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);

export default SummaryConnect;
