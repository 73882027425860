import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { prepareFiltersStartReduce } from './prepareFiltersStart.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/FILTERS/PREPARE`;

const createAction = () => ({
  type: actionType,
});

export const prepareFiltersStart = {
  actionType,
  createAction,
  reduce: prepareFiltersStartReduce,
};

export default prepareFiltersStart;
