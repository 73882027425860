import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { resetChronologyDocuments } from '@layouts/chronology/redux/resetChronologyDocuments';
import { fetchDefaultFiltersThunk } from '@pages/search/form/redux/fetchDefaultFilters.thunk';

import { resetToInitial as resetForm } from '@pages/search/redux/resetToInitial';
import { resetToInitial as resetHistory } from '@pages/search/history/redux/resetToInitial';

import { resetToInitial } from './redux/resetToInitial';

import { fetchSubscriptionsThunk } from './sidebar/redux/fetchSubscriptions.thunk';
import { fetchSettingsThunk } from './workspace/documents/redux/fetchSettings.thunk';

import { Subscriptions } from './subscriptions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    ownProps.onBootstrap();
    const { subscriptionId } = getParamsFromUrl(ownProps);

    fetchSettingsThunk(dispatch);
    fetchSubscriptionsThunk(dispatch, { id: subscriptionId });
    fetchDefaultFiltersThunk(dispatch, { withPinned: false });
  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
    dispatch(resetForm.createAction());
    dispatch(resetHistory.createAction());
    dispatch(resetChronologyDocuments.createAction());
  },
});

export const SubscriptionsConnect = connect(
  null,
  mapDispatchToProps,
)(Subscriptions);

export default SubscriptionsConnect;
