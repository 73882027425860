import { Dispatch } from 'redux';
import { Source } from '@router/path.rest';

import { httpSaveCommentary } from '@pages/document/sidebar/redux/saveCommentary.http';
import { saveCommentary } from './saveCommentary';

export class SaveCommentaryThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Record<any, any>) {
    this.dispatchSaveSucceed(params.commentary, params.documentId);

    try {
      const requestParams = {
        source: Source.search,
        sourceId: this.getState().searchResult.id,
        commentary: params.commentary,
        documentId: params.documentId,
      };

      await httpSaveCommentary(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchSaveSucceed(comment, documentId) {
    const actionData = {
      comment,
      documentId,
    };

    this.dispatch(saveCommentary.createAction(actionData));
  }

}

export function saveCommentaryThunk(dispatch, params) {
  const thunk = new SaveCommentaryThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SaveCommentaryThunk;
