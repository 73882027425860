import { Dispatch } from 'redux';

import { RequestParams } from '@providers/download/download.http';
import { httpFetchLawDocument } from './fetchLawDocument.http';
import { fetchLawDocument } from './fetchLawDocument';

import { httpFetchEditions } from '../sidebar/redux/fetchEditions.http';
import { fetchEditions } from '../sidebar/redux/fetchEditions';

export class FetchLawDocumentThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {
    await this.getDocument(params);

    const state = this.getState();
    const hasEditions = !state.lawDocument.editions.loading;
    if (!hasEditions) {
      this.getEditions(state.lawDocument.documentId);
    }
  }

  private async getDocument(params) {
    try {
      const result = await httpFetchLawDocument(params);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async getEditions(documentId) {
    const result = await httpFetchEditions({ documentId });
    this.dispatchFetchEditionsSucceed(result);
  }

  private dispatchFetchDocumentSucceed(result) {
    this.dispatch(fetchLawDocument.createAction(result));
  }

  private dispatchFetchEditionsSucceed(result) {
    this.dispatch(fetchEditions.createAction(result));
  }

}

export function fetchLawDocumentThunk(dispatch, params) {
  const thunk = new FetchLawDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchLawDocumentThunk;
