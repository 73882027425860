import React from 'react';
import { v4 as uuid } from 'uuid';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { PaginationItem } from './paginationItem';

import s from './pagination.style';

import type { Page } from './paginationItem';

interface Props {
  pages: Page[];
  currentPageNumber: number;
  isFirstPageSelected: boolean;
  isLastPageSelected: boolean;
  onChangePage: (pageNumber: number) => void;
  onAfterPagination?: () => void;
}

export class Pagination extends React.PureComponent<Props> {

  static splitter = '...';

  handleDecreasePageNumber = () => {
    if (this.props.isFirstPageSelected) {
      return;
    }

    const pageNumber = this.props.currentPageNumber - 1;
    this.props.onChangePage(pageNumber);
  };

  handleIncreasePageNumber = () => {
    if (this.props.isLastPageSelected) {
      return;
    }

    const pageNumber = this.props.currentPageNumber + 1;
    this.props.onChangePage(pageNumber);
  };

  renderPaginationItems() {
    if (!this.props.pages) {
      return null;
    }

    return this.props.pages.map((page) => {
      const isActive = this.props.currentPageNumber === page;

      const isSplitter = page === Pagination.splitter;

      const getKey = () => {
        if (isSplitter) {
          return uuid();
        }

        return page;
      };

      const onClick = (pageNumber: number) => {
        this.props.onChangePage(pageNumber);
        if (this.props.onAfterPagination) {
          this.props.onAfterPagination();
        }
      };

      return (
        <PaginationItem
          key={getKey()}
          isActive={isActive}
          isSplitter={isSplitter}
          page={page}
          // @ts-ignore
          onClick={onClick}
        />
      );
    });
  }

  renderArrowLeft() {
    const transform = 'translate(11.500000, 12.000000) rotate(-270.000000) translate(-11.500000, -12.000000) ';
    const points = iconsPath.get('arrowLeft');

    return (
      <s.ArrowItem
        onClick={this.handleDecreasePageNumber}
        isDisabled={this.props.isFirstPageSelected}
      >
        <Icon
          points={points}
          transform={transform}
          color="currentColor"
        />
      </s.ArrowItem>
    );
  }

  renderArrowRight() {
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
    const points = iconsPath.get('arrowRight');

    return (
      <s.ArrowItem
        onClick={this.handleIncreasePageNumber}
        isDisabled={this.props.isLastPageSelected}
      >
        <Icon
          points={points}
          transform={transform}
          color="currentColor"
        />
      </s.ArrowItem>
    );
  }

  render() {
    if (!this.props.pages) {
      return null;
    }

    return (
      <s.Pagination>
        <s.PaginationItems>
          {this.renderArrowLeft()}
          {this.renderPaginationItems()}
          {this.renderArrowRight()}
        </s.PaginationItems>
      </s.Pagination>
    );
  }

}

export default Pagination;
