import { connect } from 'react-redux';

import { Source } from '../../document/redux/state';

import { DocumentConnect as DocumentRoute } from '../../document/document.connect';

const mapStateToProps = () => ({
  source: Source.package,
});

export const DocumentConnect = connect(
  mapStateToProps,
  null,
)(DocumentRoute);

export default DocumentConnect;
