import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { DownloadPopover } from '@pages/document/components/download/downloadPopover';

import {
  RESTPath,
  DownloadFormat,
} from '@router/path.rest';

enum Title {
  original = 'Оригинал документа',
  withMarkers = 'Документ с маркерами',
  docx = 'Документ DOCX',
}

function buildItems(params) {
  const downloadFormats = [DownloadFormat.docx, DownloadFormat.original, DownloadFormat.withMarkers];

  return downloadFormats.map((item) => {
    const url = RESTPath.document.download({ ...params, format: item });

    return {
      title: Title[item],
      onClick: () => window.open(`${window.location.origin}${url}`),
    };
  });

}

const mapStateToProps = (state, ownProps) => {

  const params = {
    source: Source.subscription,
    sourceId: state.subscriptions.subscriptions.activeItemId,
    documentId: ownProps.id,
    disabled: false,
  };

  const items = buildItems(params);

  return {
    items,
  };
};

export const DownloadConnect = connect(
  mapStateToProps,
)(DownloadPopover);

export default DownloadConnect;
