import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { Loader } from '@components/loader';
import { NotFound } from '@components/notFound';

import { Header as MainHeader } from '../components/header/header';

import { HeaderConnect } from './header/header.connect';
import { PaginationConnect } from './pagination/pagination.connect';

import { ItemConnect } from './list/item.connect';
import { SearchId } from '../redux/state';

import s from './courts.style';

interface Props {
  id: SearchId;
  items: string[];
  initialLoading: boolean;
  onFetchCourts(): void;
  onFetchSettings(): void;
  onResetPageNumber(): void;
  onResetToDefaultSort: () => void;
  onBootstrap(): void;
}

export class Courts extends React.PureComponent<Props> {

  contentRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('results', { tab: 'judge' });
    Userpilot.track('results', { tab: 'judge' });
    this.props.onResetToDefaultSort();
    this.props.onFetchSettings();
    this.fetchDocuments();
    this.props.onBootstrap();
  }

  componentDidUpdate() {
    this.onAfterPagination();
  }

  componentWillUnmount() {
    this.props.onResetPageNumber();
  }

  onAfterPagination = () => {
    // @ts-ignore
    if (!this.contentRef) {
      return;
    }

    if (!this.contentRef.current) {
      return;
    }

    this.contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchDocuments() {
    this.props.onFetchCourts();
  }

  renderItem(item) {
    return (
      <ItemConnect
        key={item}
        id={item}
      />
    );
  }

  renderContent() {
    if (this.props.initialLoading) {
      return <Loader />;
    }

    const isEmpty = !this.props.items.length;
    if (isEmpty) {
      return <NotFound />;
    }

    return (
      <s.Content ref={this.contentRef}>
        <s.ContentContainer>
          <HeaderConnect>
            {this.props.items.map(this.renderItem)}
          </HeaderConnect>
          <PaginationConnect />
        </s.ContentContainer>
      </s.Content>
    );
  }

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <MainHeader sourceId={this.props.id} />
          {this.renderContent()}
        </s.Root>
      </WithHeaderNotification>
    );
  }

}

export default Courts;
