import { connect } from 'react-redux';

import { removeFilterThunk } from './redux/removeFilter.thunk';

import { Remove } from './remove';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRemove() {
    removeFilterThunk(dispatch, { id: ownProps.id });
  },
});

export const RemoveConnect = connect(
  null,
  mapDispatchToProps,
)(Remove);

export default RemoveConnect;
