import { connect } from 'react-redux';

import { Settings } from '../sidebar/settings';

const mapStateToProps = (state) => ({
  id: state.authorPackages.packages.activeItemId,
  disabled: !state.authorPackages.packages.activeItemId,
});

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
