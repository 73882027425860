export interface ActionData {
  tagId: string;
  documentId: number;
  pageNumber: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const setTagReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const {
    tagId,
    documentId,
  } = action.data;

  const document = state.documents.pages[state.documents.currentPageNumber].itemsMap.get(documentId);
  const tag = state.presets.tags.find((item) => item.id === tagId);

  const newDocument = {
    ...document,
    tag,
  };

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [state.documents.currentPageNumber]: {
          ...state.documents.pages[state.documents.currentPageNumber],
          itemsMap: state.documents.pages[state.documents.currentPageNumber].itemsMap.set(documentId, newDocument),
        },
      },
    },
  };
};

export default setTagReduce;
