import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { httpFetchDocument } from '@pages/document/redux/fetchDocument.http';
import { httpFetchAttributes } from '@pages/document/sidebar/redux/fetchAtttributes.http';
import { httpFetchMarkers } from '@pages/document/redux/fetchMarkers.http';

import { fetchChronologyDocumentsThunk } from '@layouts/chronology/redux/fetchChronologyDocuments.thunk';
import { fetchSimilarDocumentsThunk } from '@layouts/similar/redux/fetchSimilarDocuments.thunk';

import { fetchDocument } from './fetchDocument';
import { fetchAttributes } from './fetchAttributes';

import { fetchMarkers } from './fetchMarkers';

import { changePreviewDocument } from './changePreviewDocument';

export class FetchDocumentThunk {

  getState;

  private notificationId = 'FETCH_PACKAGE_DOCUMENT';

  private readonly dispatch: Dispatch;

  private documentId: number = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params) {
    const state = this.getState();
    const sourceId = state.packages.packages.activeItemId;

    this.documentId = params.documentId;

    const requestParams = {
      source: params.source,
      sourceId,
      documentId: params.documentId,
    };

    this.dispatch(changePreviewDocument.createAction({ documentId: params.documentId }));

    this.fetchAttributes(requestParams);

    await this.fetchDocument(requestParams);
    this.getMarkers(requestParams);

    await fetchChronologyDocumentsThunk(this.dispatch, requestParams.documentId);
    await fetchSimilarDocumentsThunk(this.dispatch, requestParams.documentId);
  }

  private async fetchDocument(requestParams) {
    try {
      const result = await httpFetchDocument(requestParams);
      this.dispatchGetDocumentSucceed(result);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private dispatchGetDocumentSucceed(searchResult) {
    const actionData = {
      title: searchResult.body.title,
      document: searchResult.body.blocks,
      resolution: searchResult.resolution.blocks,
      documentId: this.documentId,
    };

    this.dispatch(fetchDocument.createAction(actionData));
  }

  private async getMarkers(params) {
    try {
      const result = await httpFetchMarkers(params);
      this.dispatchFetchMarkersSucceed({ documentId: params.documentId, ...result });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchFetchMarkersSucceed(result) {
    this.dispatch(fetchMarkers.createAction(result));
  }

  private async fetchAttributes(requestParams) {
    try {
      const result = await httpFetchAttributes(requestParams);
      this.dispatchGetAttributesSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetAttributesSucceed(searchResult) {
    const actionData = {
      attributes: searchResult.attributes,
      documentId: this.documentId,
      comment: searchResult.comment,
    };

    this.dispatch(fetchAttributes.createAction(actionData));
  }

}

export function fetchDocumentThunk(dispatch, params) {
  const thunk = new FetchDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchDocumentThunk;
