import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchFilters } from './fetchFilters';
import { saveFilters } from './saveFilters';
import { createFilter } from './createFilter';
import { removeFilter } from './removeFilter';
import { updateTitle } from './updateTitle';
import { selectFilter } from './selectFilter';
import { updateFilters } from './updateFilters';

export const reducerBuilder = new ReducerBuilder([
  fetchFilters,
  saveFilters,
  createFilter,
  selectFilter,
  updateFilters,
  removeFilter,
  updateTitle,
]);

export default reducerBuilder;
