import React from 'react';

import { Bookmarks } from '@layouts/bookmarks/components/bookmarks';

import { AddTagConnect } from './addTag.connect';
import { AddPackageConnect } from './addPackage.connect';

import { RequisitesCopyConnect } from './requisitesCopy.connect';
// Todo: [sharing] Вернуть с функционалом открытия шаренных документов
// import { SharedLinkCopyConnect } from './sharedLinkCopy.connect';
import { DownloadConnect } from './download.connect';

import s from './tools.style';

interface ToolsProps {
  source: 'search' | 'package' | 'subscription';
  isReal: boolean;
  disabled: boolean;
  isAuthorPackage: boolean;
  isChronologyDocument: boolean;
  isSimilarDocument: boolean;
}

export class Tools extends React.PureComponent<ToolsProps> {

  // Todo: [sharing] Вернуть с функционалом открытия шаренных документов
  // renderSharedLink() {
  //   if (this.props.isAuthorPackage) {
  //     return null;
  //   }

  //   return (
  //     <s.Tool>
  //       <SharedLinkCopyConnect disabled={this.props.disabled} />
  //     </s.Tool>
  //   );
  // }

  renderBookmark() {
    if (!this.props.isReal) {
      return null;
    }

    const bookmarksIsDisabled = this.props.disabled || this.props.isChronologyDocument || this.props.isSimilarDocument;

    return (
      <s.Tool>
        <Bookmarks
          disabled={bookmarksIsDisabled}
        />
      </s.Tool>
    );
  }

  renderAddToPackage() {
    const addToPackageDisabled = this.props.disabled || this.props.isChronologyDocument || this.props.isSimilarDocument;

    if (!this.props.isReal) {
      return null;
    }

    return (
      <s.Tool>
        <AddPackageConnect disabled={addToPackageDisabled} />
      </s.Tool>
    );
  }

  renderDownload() {
    if (!this.props.isReal) {
      return null;
    }

    return (
      <s.Tool>
        <DownloadConnect disabled={this.props.disabled} />
      </s.Tool>
    );
  }

  render() {
    const {
      disabled,
      isAuthorPackage,
      isChronologyDocument,
      isSimilarDocument,
    } = this.props;

    const tagIsDisabled = disabled || isAuthorPackage || isChronologyDocument || isSimilarDocument;

    return (
      <s.Root>
        {this.renderBookmark()}
        <s.Tool>
          <AddTagConnect disabled={tagIsDisabled} />
        </s.Tool>
        {this.renderAddToPackage()}
        {/* Todo: [sharing] Вернуть с функционалом открытия шаренных документов */}
        {/* {this.renderSharedLink()} */}
        {this.renderDownload()}
        <s.Tool marginRight={-1}>
          <RequisitesCopyConnect disabled={this.props.disabled} />
        </s.Tool>
      </s.Root>
    );
  }

}

export default Tools;
