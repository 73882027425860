import React from 'react';

import { iconsPath } from '@components/icons';

import { ViewType } from '../redux/state';

import { Button } from './button';

import { FiltersConnect } from './filters.connect';

import { TextConnect } from './text.connect';

import s from './layout.style';

type ViewTypeId = ViewType.table | ViewType.list;

interface LayoutProps {
  viewTypeId: ViewType.list | ViewType.table;
  loading?: boolean;
  disabled?: boolean;
  onViewTypeChange(ViewTypeId: ViewTypeId): void;
}

export class Layout extends React.PureComponent<LayoutProps> {

  listIcon = iconsPath.get('listView');

  tableIcon = iconsPath.get('tableView');

  handleTableButtonClick = () => this.props.onViewTypeChange(ViewType.table);

  handleListButtonClick = () => this.props.onViewTypeChange(ViewType.list);

  renderLayoutViewControls() {
    return (
      <>
        <Button
          active={this.props.viewTypeId === ViewType.list}
          disabled={false}
          loading={this.props.loading}
          iconPath={this.listIcon}
          onClick={this.handleListButtonClick}
        />
        <Button
          active={this.props.viewTypeId === ViewType.table}
          disabled={false}
          loading={this.props.loading}
          iconPath={this.tableIcon}
          onClick={this.handleTableButtonClick}
        />
      </>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Row>
          {this.renderLayoutViewControls()}
          <FiltersConnect />
        </s.Row>
        <s.Row>
          <TextConnect />
        </s.Row>
      </s.Root>
    );
  }

}

export default Layout;
