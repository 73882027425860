import { unpublishCompanyPackageReduce } from './unpublishCompanyPackage.reduce';

import type { ActionData } from './unpublishCompanyPackage.reduce';

const actionType = 'PACKAGES/UNPUBLISH_COMPANY_PACKAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const unpublishCompanyPackage = {
  actionType,
  createAction,
  reduce: unpublishCompanyPackageReduce,
};

export default unpublishCompanyPackage;
