import { styled } from '@components/styled';

import {
  hover,
  iconButton,
} from '@components/button/button.style';

const style: any = {};

style.Root = styled.div`
  background: white;
  width: 384px;
`;

style.Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  height: 48px;
  padding: 0 8px;

  font-family: Roboto-Medium;
  font-size: 13px;

  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

style.HeaderAction = styled.div`
  z-index: 1;

  > div {
    ${iconButton};
    ${hover};
  }
`;

style.HeaderTitle = styled.div``;

style.List = styled.div`
  max-height: 600px;
  overflow-y: auto;

  > div {
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor}
  }

  > div:last-child {
    border-bottom: none;
  }
`;

style.Items = styled.div``;

style.Empty = styled.div`
  padding: 16px;
  align-items: center;
  justify-content: center;
  display: flex;

  font: 13px Roboto-Regular;

  color: ${(props) => props.theme.colors.stone}
`;

style.Loader = styled.div`
  height: 64px;
  position: relative;
`;

style.BottomSensor = styled.div`
  height: 10px;
  background: transparent;
`;

export default style;
