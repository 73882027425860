import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { appHistory } from '@router/history';
import { Id } from '@pages/regulation/sidebar/folders/redux/item';

import { selectFolder } from './selectFolder';
import { setPreviewId } from './setPreviewId';

import { selectFilter } from '../../filters/redux/selectFilter';

import { resetToInitial } from '../../../workspace/redux/resetToInitial';

import { fetchFiltersThunk } from '../../../workspace/redux/fetchFilters.thunk';
import { fetchProjectsThunk } from '../../../workspace/redux/fetchProjects.thunk';
import { fetchFolderThunk } from '../../../workspace/redux/fetchFolder.thunk';

import {
  IdType,
  FolderType,
} from './state';

interface SelectFolderParams {
  id: IdType;
  previewId?: Id;
}

export class SelectFolderThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: SelectFolderParams) {

    const isVisitor = this.getState().currentUser.licenses.regulation === 'visitor';
    if (isVisitor && Number.isNaN(params.id)) {
      return;
    }

    if (params.previewId) {
      this.projectPreview(params.previewId);

      return;
    }

    if (this.isSame(params.id)) {
      return;
    }

    this.initialEvents(params.id);

    const folderId = params.id || '';
    appHistory.push(`/regulation/${folderId}`);

    if (params.id === FolderType.calendar) {
      return;
    }

    if (params.id === FolderType.comments) {
      return;
    }

    await this.fetchFiltersThunk(params.id);

    if (!params.id) {
      await this.fetchProjects();

      return;
    }

    await this.fetchFolder(params.id);
  }

  private isSame(id) {
    const { folders } = this.getState().regulation;
    const sameId = id === folders.activeItemId;

    return sameId;
  }

  private initialEvents(id) {
    this.marketingEvents(id);
    this.dispatchSelectFolder(id);
    this.dispatchResetProjects();
  }

  private async projectPreview(id: Id) {
    sendAmplitudeData('regulation:open_preview__from_outside');
    Userpilot.track('regulation:open_projects__from_outside');
    this.dispatchSelectFolder(null);
    this.dispatchResetProjects();
    this.dispatchSetPreviewId(id);

    await this.fetchFiltersThunk();
    await this.fetchProjects();
  }

  private dispatchResetProjects() {
    this.dispatch(resetToInitial.createAction());
    this.dispatch(selectFilter.createAction({ id: null }));
  }

  private marketingEvents(id: IdType) {
    if (!id) {
      sendAmplitudeData('regulation:open_projects');
      Userpilot.track('regulation:open_projects');

      return;
    }

    if (id === FolderType.underControl) {
      sendAmplitudeData('regulation:open_under_control');
      Userpilot.track('regulation:open_under_control');

      return;
    }

    if (id === FolderType.calendar) {
      sendAmplitudeData('regulation:open_calendar');
      Userpilot.track('regulation:open_calendar');

      return;
    }

    if (id === FolderType.comments) {
      sendAmplitudeData('regulation:open_comments');
      Userpilot.track('regulation:open_comments');

      return;
    }

    const folderName = this.getState().regulation.folders.folders.itemsMap.get(id).title;
    sendAmplitudeData('regulation:open_folder', folderName);
    Userpilot.track('regulation:open_folder');
  }

  private dispatchSelectFolder(id: IdType) {
    this.dispatch(selectFolder.createAction({ id }));
  }

  private dispatchSetPreviewId(id: Id) {
    this.dispatch(setPreviewId.createAction({ id }));
  }

  private async fetchFiltersThunk(id?) {
    try {
      await fetchFiltersThunk(this.dispatch, id);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchFolder(id) {
    try {
      await fetchFolderThunk(this.dispatch, { id });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchProjects() {
    try {
      await fetchProjectsThunk(this.dispatch);
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export function selectFolderThunk(dispatch, params) {
  const thunk = new SelectFolderThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SelectFolderThunk;
