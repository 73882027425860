import { Dispatch } from 'redux';

import { selectSubscriptionThunk } from '../../../sidebar/redux/selectSubscription.thunk';
import { resetToInitial as resetSubscriptions } from '../../../sidebar/redux/resetToInitial';
import { resetToInitial as resetDocuments } from '../../../workspace/documents/redux/resetToInitial';
import { fetchDocuments } from '../../../workspace/documents/redux/fetchDocuments';

import { removeSubscription } from './removeSubscription';

import { httpRemoveSubscription } from './removeSubscription.http';

export class RemoveSubscriptionThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: number }) {

    this.dispatchChangePackageSelection(params.id);
    this.dispatchRemovePackage(params);

    try {
      await httpRemoveSubscription(params);
    } catch (error) {
      throw Error(error.status);
    }

  }

  private dispatchRemovePackage(params) {
    this.dispatch(removeSubscription.createAction({ id: params.id }));
  }

  private dispatchChangePackageSelection(currentId) {
    const isActive = this.getState().subscriptions.subscriptions.activeItemId === currentId;
    if (!isActive) {
      return;
    }

    const { itemsList } = this.getState().subscriptions.subscriptions;
    const isLastItem = itemsList.length === 1;
    if (isLastItem) {
      this.dispatchResetToInitial();
    }

    const findIndex = itemsList.findIndex((id) => id === currentId);
    const hasPreviousPackage = !!findIndex;
    if (hasPreviousPackage) {
      const nextId = itemsList[findIndex - 1];
      selectSubscriptionThunk(this.dispatch, { sourceId: nextId });

      return;
    }

    const hasNext = !!itemsList[findIndex + 1];
    if (hasNext) {
      const nextId = itemsList[findIndex + 1];
      selectSubscriptionThunk(this.dispatch, { sourceId: nextId });
    }
  }

  private dispatchResetToInitial() {
    this.dispatch(resetSubscriptions.createAction());
    this.dispatch(resetDocuments.createAction());

    const emptyDocuments = {
      sourceId: 0,
      pageNumber: 0,
      result: {
        page: 0,
        total_pages: 0,
        total_count: 0,
        documents: [],
      },
    };

    this.dispatch(fetchDocuments.createAction(emptyDocuments));
  }

}

export function removeSubscriptionThunk(dispatch, params) {
  const thunk = new RemoveSubscriptionThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default RemoveSubscriptionThunk;
