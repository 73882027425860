import {
  ActionData,
  setActiveDateReduce,
} from './setActiveDate.reduce';

export const actionType = 'REGULATION/EVENTS/SET_ACTIVE_DATE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setActiveDate = {
  actionType,
  createAction,
  reduce: setActiveDateReduce,
};
