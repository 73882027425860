import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { withResetFilters } from '@pages/search/withResetFilters';
import { RoutePath } from '@router/path';

import { PackagesConnect } from './packages.connect';

export const RoutePackages = (
  <AuthRouteConnect
    path={[RoutePath.packages, RoutePath.package]}
    component={withResetFilters(PackagesConnect)}
    exact
  />
);

export default RoutePackages;
