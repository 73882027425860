import { connect } from 'react-redux';

import { AddPackage } from '@components/addPackage/addPackage';

import { fetchPackagesThunk } from '@pages/packages/redux/searchPackages/fetchPackagesSearchResult.thunk';

import { searchPackagesResultResetToInitial }
  from '@pages/packages/redux/searchPackages/searchPackagesResultResetToInitial';

import { createPackageThunk } from '../redux/createPackage.thunk';

import { addDocumentToPackageThunk } from '../redux/addDocumentToPackage.thunk';

const mapStateToProps = (state) => {
  const { pages } = state.authorPackages.documents;
  const { currentPageNumber, documentId } = state.authorPackages.documents.previewDocument;

  if (!currentPageNumber || !documentId || !pages) {
    return {};
  }

  const { packagesCount } = pages[currentPageNumber].itemsMap.get(documentId);

  const packages = state.packages.searchResult;

  const count = packagesCount > 99 ?
    '99+' :
    packagesCount;

  return {
    count,
    packages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    const documentId = ownProps.id;
    fetchPackagesThunk(dispatch, { documentId });
  },
  onResetToInitial() {
    dispatch(searchPackagesResultResetToInitial.createAction());
  },
  onPackageCreate(query: string) {
    const searchQuery = query.trim();
    const documentId = ownProps.id;

    const params = {
      query: searchQuery,
      documentId,
    };

    createPackageThunk(dispatch, params);
  },
  onPackageToggle(packageId: number) {
    const documentId = ownProps.id;
    const params = {
      documentId,
      packageId,
    };

    addDocumentToPackageThunk(dispatch, params);
  },
});

export const AddPackageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPackage);

export default AddPackageConnect;
