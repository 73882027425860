import { Dispatch } from 'redux';

import { DateBlock } from '@components/filters/date/logic/date';

import { fetchProjects } from './fetchProjects';
import { fetchFolderLastPage } from './fetchFolderLastPage';
import { httpFetchProjects } from './fetchProjects.http';

export class FetchProjectsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    if (this.getState().regulation.projects.lastPageReached) {
      return;
    }

    const { currentPageNumber } = this.getState().regulation.projects;

    let searchAfter;

    if (currentPageNumber.length === 2) {
      searchAfter = `${currentPageNumber[0]}-${currentPageNumber[1]}`;
    }

    const state = this.getState();
    const filters = { ...state.regulation.filters.filters };

    const dateFilter = new DateBlock();
    dateFilter.create(filters.date);

    filters.date = dateFilter.undecorate();

    const filterId = this.getState().regulation.smartFolders.activeItemId;

    try {
      const result = await httpFetchProjects(searchAfter, filters, filterId);

      if (!result.projects.length) {
        this.dispatchLastPageFetched();
      }

      this.dispatchFetchSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(fetchProjects.createAction(result));
  }

  private dispatchLastPageFetched() {
    this.dispatch(fetchFolderLastPage.createAction());
  }

}

export function fetchProjectsThunk(dispatch) {
  const thunk = new FetchProjectsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default FetchProjectsThunk;
