import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { changeContains } from '../redux/changeContains';
import { updateDocumentType } from './redux/updateDocumentType';
import { updateThemeOfDefinition } from './redux/updateThemeOfDefinition';
import { DocumentTypeBlockData } from './redux/documentType';

import { DocumentTypeBlock } from './block';

const modifyData = function (itemData: DocumentTypeBlockData): DocumentTypeBlockData {
  const data = cloneDeep(itemData);
  // @ts-ignore
  if (!data.category.id) {
    // @ts-ignore
    data.category.id = '';
  }

  // @ts-ignore
  if (!data.category.title) {
    // @ts-ignore
    data.category.title = '';
  }

  return data;
};

const mapStateToProps = (state, ownProps) => {
  const { documentType } = state.search.presets;
  const { item } = ownProps;

  const newData = modifyData(item.getData());
  item.setData(newData);

  return {
    documentTypes: documentType,
    item,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateDocumentType(data) {
    dispatch(updateDocumentType.createAction(data));
  },
  onUpdateThemeOfDefinition(data) {
    dispatch(updateThemeOfDefinition.createAction(data));
  },
});

export const DocumentTypeBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentTypeBlock);

export default DocumentTypeBlockConnect;
