import React from 'react';

import s from './download.style';

interface Item {
  title: string;
  onClick(): void;
}

interface DownloadProps {
  items: Item[];
  closePopover: () => void;
}

export class Download extends React.PureComponent<DownloadProps> {

  renderHeader() {
    return (
      <s.Header>
        Скачать
      </s.Header>
    );
  }

  renderItem = (params: { title: string; onClick(): void }) => {
    const handleClick = () => {
      params.onClick();
      this.props.closePopover();
    };

    return (
      <s.Item
        onClick={handleClick}
      >
        {params.title}
      </s.Item>
    );
  };

  renderContent() {
    return (
      <s.List>
        {this.props.items.map(this.renderItem)}
      </s.List>
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        {this.renderContent()}
      </s.Container>
    );
  }

}

export default Download;
