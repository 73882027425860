import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { DocumentConnect as DocumentRoute } from '@pages/document/document.connect';

const mapStateToProps = () => ({
  source: Source.search,
});

export const DocumentConnect = connect(
  mapStateToProps,
  null,
)(DocumentRoute);

export default DocumentConnect;
