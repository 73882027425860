import React from 'react';
import { Location } from 'history';

import { Userpilot } from '@utils/userpilot';

import { Button } from '@components/button';

import { Loader } from '@components/loader';

import { Field } from '@components/field';
import field from '@components/field/field.style';

import { RoutePath } from '@router/path';

import { BackToMainLink } from '../components/backToMainLink';

import { Container } from '../container';

import form from '../form.style';
import s from './createNewPassword.style';

import {
  CodeParams,
  PasswordResetParams,
} from './createNewPassword.thunk';

interface CreateNewPasswordProps {
  location: Location;
  onVerifyCode(data: CodeParams): void;
  onSend(data: PasswordResetParams): void;
}

interface CreateNewPasswordState {
  code: string;
  password: string;
  repeatPassword: string;
  sending: boolean;
  formError: string;
}

export class CreateNewPassword extends React.PureComponent<CreateNewPasswordProps, CreateNewPasswordState> {

  state = {
    code: '',
    password: '',
    repeatPassword: '',
    sending: false,
    formError: '',
  };

  refPassword: React.RefObject<HTMLInputElement> = React.createRef();

  refRepeatPassword: React.RefObject<HTMLInputElement> = React.createRef();

  componentDidMount() {
    Userpilot.reload();
    const code = this.props.location.search.split('=')[1];
    this.verifyCode(code);
  }

  setStateFormError(error) {
    this.setState({ formError: error });
  }

  setStateSending(sending) {
    this.setState({ sending });
  }

  setStateCode(code) {
    this.setState({
      code,
    });
  }

  handlePasswordFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
    } = event.target;

    this.setState({
      password: value,
      formError: '',
    });
  };

  handleRepeatPasswordFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
    } = event.target;

    this.setState({
      repeatPassword: value,
      formError: '',
    });
  };

  handleButtonClick = (event) => {
    event.preventDefault();
    this.send();
  };

  async verifyCode(code) {
    try {
      await this.props.onVerifyCode({ code });
      this.setStateCode(code);

    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }

      window.location.href = `#${RoutePath.outDatePasswordResetLink}`;
    }
  }

  disabledButton() {
    if (this.state.sending) {
      return true;
    }

    if (!this.state.password) {
      return true;
    }

    if (!this.state.repeatPassword) {
      return true;
    }

    return false;
  }

  sendFailed(error) {
    const formError = error.errors.password[0];

    this.setStateFormError(formError);
    this.setStateSending(false);

    if (formError) {
      this.refPassword.current.blur();
      this.refRepeatPassword.current.blur();
    }
  }

  async send() {
    this.setStateSending(true);

    try {
      const { password, repeatPassword } = this.state;
      await this.props.onSend({ password, repeatPassword, code: this.state.code });
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }

      this.sendFailed(error);
    }
  }

  renderButton() {
    const text = () => {
      if (!this.state.sending) {
        return <>Сохранить</>;
      }

      return (
        <s.Loader>
          <Loader size={16} />
        </s.Loader>
      );
    };

    return (
      <s.Button>
        <Button
          fullWidth
          disabled={this.disabledButton()}
          onClick={this.handleButtonClick}
        >
          {text()}
        </Button>
      </s.Button>
    );
  }

  renderError() {
    const message = this.state.formError;
    if (!message) {
      return null;
    }

    return (
      <field.Tooltip>
        <field.TooltipContainer>
          {message}
        </field.TooltipContainer>
      </field.Tooltip>
    );
  }

  render() {
    return (
      <Container withLink>
        <form>
          <form.Title>
            Создание нового пароля
          </form.Title>

          <form.Description>
            <form.DescriptionTitle>
              Введите новый пароль в поля ниже.
            </form.DescriptionTitle>
          </form.Description>

          <form.Fieldset>
            {this.renderError()}
            <form.FieldsContainer>
              <Field
                autoFocus
                disabled={this.state.sending}
                label="Новый пароль"
                type="password"
                name="password"
                value={this.state.password}
                error={!!this.state.formError}
                onChange={this.handlePasswordFieldChange}
                inputRef={this.refPassword}
              />
              <Field
                disabled={this.state.sending}
                label="Новый пароль еще раз"
                type="password"
                name="repeatPassword"
                value={this.state.repeatPassword}
                error={!!this.state.formError}
                onChange={this.handleRepeatPasswordFieldChange}
                inputRef={this.refRepeatPassword}
              />
            </form.FieldsContainer>
          </form.Fieldset>

          <s.Actions>
            <BackToMainLink />
            {this.renderButton()}
          </s.Actions>
        </form>
      </Container>
    );
  }

}

export default CreateNewPassword;
