import React from 'react';

import s from './404.style';

export const NotFound = () => (
  <s.Container>
    <h1>
      404
    </h1>
  </s.Container>
);

export default NotFound;
