import { connect } from 'react-redux';

import { UserRoleType } from '@pages/userPages/redux/state';

import { Workspace } from './workspace';

const mapStateToProps = (state) => {
  const {
    body,
    additions,
    isPractice,
    department,
  } = state.document.document.document;

  const loading = state.document.document.document.loading && state.document.document.entities.loading;

  return {
    body,
    additions,
    isPractice,
    department,
    loading,
    isReal: state.currentUser.role !== UserRoleType.guest,
  };
};

export const WorkspaceConnect = connect(
  mapStateToProps,
)(Workspace);

export default WorkspaceConnect;
