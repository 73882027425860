import {
  removeSubscriptionReduce,
  ActionData,
} from './removeSubscription.reduce';

const actionType = 'SUBSCRIPTIONS/REMOVE_SUBSCRIPTION';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const removeSubscription = {
  actionType,
  createAction,
  reduce: removeSubscriptionReduce,
};

export default removeSubscription;
