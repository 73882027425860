import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchSearchAnalyticMonth(searchId: number, yearId: number) {
  const request = http.get({
    url: RESTPath.search.analytic.date.getMonth(searchId, yearId),
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpFetchSearchAnalyticMonth;
