import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { store } from '@redux/store';

import { notifierManager } from '@components/notification';
import { processStart } from './processStart';

import { processComplete } from './processComplete';
import { httpDownloadBookmarks } from './download.http';

export interface Params {
  type?: string;
  percent?: number;
  url?: string;
}

export class ProcessThunk {

  static COMPLETE_PERCENT = 100;

  static DOWNLOAD_NOTIFICATION_ID = 111222333;

  getState: ReturnType<typeof store.getState>;

  private readonly dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    const state = this.getState().bookmarksBuffer;

    const downloadingInProgress = state.downloading;
    const isStartDownloading = !downloadingInProgress && !params;
    if (isStartDownloading) {
      sendAmplitudeData('download bookmarks');
      Userpilot.track('download bookmarks');
      await this.startProcess();

      return;
    }

    const downloadInProgress = !downloadingInProgress && params.percent !== ProcessThunk.COMPLETE_PERCENT;
    if (downloadInProgress) {
      await this.continueProcess();

      return;
    }

    const isComplete = params.percent === ProcessThunk.COMPLETE_PERCENT;
    const hasUrl = !!params.url;
    if (isComplete && hasUrl) {
      this.endProcess(params);
    }

  }

  private continueProcess() {
    this.showStartNotification(ProcessThunk.DOWNLOAD_NOTIFICATION_ID);
    this.dispatchProgressStart();
  }

  private async startProcess() {
    try {
      await httpDownloadBookmarks();
    } catch (error) {
      throw Error(error.status);
    }

    this.showStartNotification(ProcessThunk.DOWNLOAD_NOTIFICATION_ID);
    this.dispatchProgressStart();
  }

  private endProcess(params: Params) {
    const notificationParams = {
      id: ProcessThunk.DOWNLOAD_NOTIFICATION_ID,
      url: params.url,
    };

    this.showEndNotification(notificationParams);
    this.dispatchProcessComplete();
  }

  private dispatchProgressStart() {
    this.dispatch(processStart.createAction());
  }

  showStartNotification(id: number) {
    const message = 'Подготовка документа к скачиванию.\n' +
      'Пожалуйста, не закрывайте вкладку.';

    notifierManager.pending({ id, message });
  }

  private dispatchProcessComplete() {
    this.dispatch(processComplete.createAction());
  }

  showEndNotification(params: { id: number, url: string }) {
    setTimeout(() => {
      const message = 'Документ подготовлены';
      notifierManager.done({ ...params, message });
    }, 1000);

    notifierManager.remove({ id: params.id });
  }

}

export function processThunk(dispatch: Dispatch<any>, params?: Params) {
  const thunk = new ProcessThunk(dispatch);

  dispatch((_: any, getState: any) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default ProcessThunk;
