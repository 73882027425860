import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 112px;
`;

style.Header = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
`;

style.Content = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export default style;
