import { connect } from 'react-redux';

import { Items } from './items';

export function buildItems(params) {
  const query = params.query.trim().toLowerCase();

  return params.packages.itemsList.reduce((acc, id) => {
    const currentPackage = params.packages.itemsMap.get(id);
    const currentPackageTitle = currentPackage.title.toLowerCase();
    const contains = currentPackageTitle.includes(query);
    if (!contains) {
      return acc;
    }

    acc.push({
      ...params.packages.itemsMap.get(id),
      active: id === params.activeItemId,
    });

    return acc;
  }, []);
}

const mapStateToProps = (state, ownProps: { query: string }) => {
  const { packages } = state.packages;

  const items = buildItems({ packages, activeItemId: packages.activeItemId, query: ownProps.query });

  return {
    items,
  };
};

export const ItemsConnect = connect(
  mapStateToProps,
)(Items);

export default ItemsConnect;
