import React from 'react';

import { List } from './components/list/list';
import { HistoryProps as StoryProps } from './searchHistory';

export interface SearchHistoryProps {
  data: StoryProps[],
  activeHistoryId: number
}

interface HistoryProps {
  // eslint-disable-next-line react/require-default-props
  onResetToInitial(): () => void,
  // eslint-disable-next-line react/require-default-props
  onFetchSearchHistory?(): () => void,
  // eslint-disable-next-line react/require-default-props
  onRemoveHistoryItem?(id): (id: number) => void,
  // eslint-disable-next-line react/require-default-props
  onSelectHistoryItem?(data): (data) => void,
  history: SearchHistoryProps,
}

export class History extends React.Component<HistoryProps> {

  componentDidMount() {
    // @ts-ignore
    this.props.onFetchSearchHistory();
  }

  componentWillUnmount() {
    this.props.onResetToInitial();
  }

  onRowClick = (id: number) => {
    const data = { id };
    // @ts-ignore
    this.props.onSelectHistoryItem(data);
  };

  onRemoveItem = (id: number) => {
    // @ts-ignore
    this.props.onRemoveHistoryItem(id);
  };

  render() {
    return (
      <List
        data={this.props.history.data}
        selectedId={this.props.history.activeHistoryId}
        onRowClick={this.onRowClick}
        onRemoveHistoryItem={this.onRemoveItem}
      />
    );
  }

}

export default History;
