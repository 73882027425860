import { StateBuilder } from './state';

export const resetDocumentReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    document: StateBuilder.createInitialDocument(),
  };
};

export default resetDocumentReduce;
