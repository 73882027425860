import React from 'react';

import { BackToMainLink } from '../components/backToMainLink';

import { Container } from '../container';

import form from '../form.style';
import s from './createNewPassword.style';

export const OutDateLink = () => (
  <Container withLink>
    <form>
      <form.Title>
        Ссылка устарела
      </form.Title>

      <form.Description>
        <form.DescriptionTitle>
          Ссылка, которую вы используете, уже устарела, или с ее
          помощью пароль уже был изменен. Для изменения пароля
          запросите новую ссылку на форме входа.
        </form.DescriptionTitle>
      </form.Description>

      <form.Fieldset>
        <form.FieldsContainer />
      </form.Fieldset>

      <s.Actions>
        <BackToMainLink />
      </s.Actions>
    </form>
  </Container>
);

export default OutDateLink;
