import { increasePackageCountReduce } from './increasePackageCount.reduce';

const actionType = 'DOCUMENT/INCREASE_PACKAGE_COUNT';

const createAction = () => ({
  type: actionType,
});

export const increasePackageCount = {
  actionType,
  createAction,
  reduce: increasePackageCountReduce,
};

export default increasePackageCount;
