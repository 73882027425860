import { connect } from 'react-redux';

import { totalCheckStatus } from '@pages/searchResult/documents/list/list.connect';

import { Table } from './table';

function prepareColumns(settings) {
  const columns = {
    itemsList: [],
    itemsMap: settings.itemsMap,
  };

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      columns.itemsList.push(column);
    }
  });

  return columns;
}

const mapStateToProps = (state) => {
  const {
    currentPageNumber,
    pages,
    selection,
    totalDocuments,
  } = state.subscriptions.documents;

  const hasPage = pages[currentPageNumber];
  if (!hasPage) {
    return {
      items: [],
      columns: {
        itemsList: [],
      },
    };
  }

  const items = pages[currentPageNumber].items;
  const settings = state.subscriptions.documents.layout.tableView.columns;
  const columns = prepareColumns(settings);

  return {
    items,
    columns,
    totalCheckStatus: totalCheckStatus(selection, totalDocuments),
  };
};

export const TableConnect = connect(
  mapStateToProps,
)(Table);

export default TableConnect;
