import { decreasePackageCountReduce } from '@pages/packages/redux/documents/decreasePackageCount.reduce';

import type { ActionData } from '@pages/packages/redux/documents/decreasePackageCount.reduce';

const actionType = 'AUTHOR_PACKAGES/DOCUMENTS/DOCUMENT__DECREASE_PACKAGE_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const decreasePackageCount = {
  actionType,
  createAction,
  reduce: decreasePackageCountReduce,
};

export default decreasePackageCount;
