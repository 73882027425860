import { RESTPath } from '@router/path.rest';
import http from '@http';

export type Id = number;

export interface AttributesDTO {
  id: Id;
  author: string
  branch_of_legislation: string
  project_number: string;
  source: {
    title: string,
    link: string
  }
  start_date: string;
  title: string;
  type: string;
}

export function httpFetchAttributes(projectId: Id): Promise<AttributesDTO> {
  const url = RESTPath.regulation.projects.preview.attributes(projectId);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchAttributes;
