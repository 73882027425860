import { fetchMarkersReduce } from '@pages/searchResult/documents/preview/redux/fetchMarkers.reduce';

import type { ActionData } from '@pages/searchResult/documents/preview/redux/fetchMarkers.reduce';

const actionType = 'AUTHOR_PACKAGE/FETCH_DOCUMENT_MARKERS__PREVIEW';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchMarkers = {
  actionType,
  createAction,
  reduce: fetchMarkersReduce,
};

export default fetchMarkers;
