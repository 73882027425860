import React from 'react';

import { RoutePath } from '@router/path';

import { UnauthRouteConnect } from '@router/unauthRoute.connect';

import { CreateNewPasswordSuccess } from './createNewPasswordSuccess';

export const RouteCreateNewPasswordSuccess = (
  <UnauthRouteConnect
    exact
    path={RoutePath.createNewPasswordSuccess}
    component={CreateNewPasswordSuccess}
  />
);

export default RouteCreateNewPasswordSuccess;
