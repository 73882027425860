import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  :hover {
    > div {
      text-decoration: underline;
    }
  }
`;

style.Container = styled.div`
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 56px;

  &::after {
    position: fixed;
    background: #fff;
    content: '';
    right: 0;
    top: 0;
    width: 808px;
    height: 55px;
    border-bottom: 1px solid rgb(223,226,230);
  }
`;

style.DrawerRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background: #f3f4f5;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 10%);
  z-index: 999;
  padding: 28px 80px 28px 64px;

  > div{
    > div:nth-child(2) {
      margin-top: 12px;
    }
  }
`;

style.Background = styled.div`
  position: fixed;
  right: 0;
  top: 56px;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.1);
  z-index: 998;
`;

style.Action = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  font: 11px Roboto-Regular;
  padding: 6px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};
`;

style.ActionTitle = styled.div`
  padding-top: 5px;
`;

export default style;
