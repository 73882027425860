import { searchBootstrappedReduce } from './searchBootstrapped.reduce';

const actionType = 'SEARCH/FORM/PRESETS/FETCH_SUCCEED';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const searchBootstrapped = {
  actionType,
  createAction,
  reduce: searchBootstrappedReduce,
};

export default searchBootstrapped;
