import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchSearch } from '@pages/searchResult/redux/fetchSearch';
import { subscriptionCreate } from '@pages/searchResult/redux/subscriptionCreate';
import { subscriptionDelete } from '@pages/searchResult/redux/subscriptionDelete';
import { subscriptionProcessStart } from '@pages/searchResult/redux/subscriptionProcessStart';

import { initial } from './state';

import { searchBootstrapped } from './searchBootstrapped';

import { reducerBuilder as form } from '../form/redux/reducers';

import { reducerBuilder as filter } from '../form/filterBlocks/redux/reducers';
import { reducerBuilder as law } from '../form/filterBlocks/law/redux/reducers';
import { reducerBuilder as date } from '../form/filterBlocks/date/redux/reducers';
import { reducerBuilder as text } from '../form/filterBlocks/text/redux/reducers';
import { reducerBuilder as documentType } from '../form/filterBlocks/documentType/redux/reducers';
import { reducerBuilder as judge } from '../form/filterBlocks/judge/redux/reducers';
import { reducerBuilder as court } from '../form/filterBlocks/court/redux/reducers';
import { reducerBuilder as previousCourt } from '../form/filterBlocks/previousCourt/redux/reducers';
import { reducerBuilder as courtType } from '../form/filterBlocks/courtType/redux/reducers';
import { reducerBuilder as region } from '../form/filterBlocks/region/redux/reducers';
import { reducerBuilder as caseType } from '../form/filterBlocks/caseType/redux/reducers';
import { reducerBuilder as disputeCategory } from '../form/filterBlocks/disputeCategory/redux/reducers';
import { reducerBuilder as caseNumber } from '../form/filterBlocks/caseNumber/redux/reducers';
import { reducerBuilder as claimAmount } from '../form/filterBlocks/claimAmount/redux/reducers';
import { reducerBuilder as caseResult } from '../form/filterBlocks/caseResult/redux/reducers';
import { reducerBuilder as appealState } from '../form/filterBlocks/appealState/redux/reducers';
import { reducerBuilder as participant } from '../form/filterBlocks/participant/redux/reducers';
import { reducerBuilder as plaintiff } from '../form/filterBlocks/plaintiff/redux/reducers';
import { reducerBuilder as defendant } from '../form/filterBlocks/defendant/redux/reducers';
import { reducerBuilder as thirdPartyPerson } from '../form/filterBlocks/thirdPartyPerson/redux/reducers';
import { reducerBuilder as anotherPerson } from '../form/filterBlocks/anotherPerson/redux/reducers';
import { reducerBuilder as representative } from '../form/filterBlocks/representative/redux/reducers';

import { updateSmartFilter } from '../form/filterBlocks/smartFilter/redux/updateSmartFilter';
import { prepareFiltersStart } from '../form/filterBlocks/smartFilter/redux/prepareFiltersStart';
import { prepareFiltersEnd } from '../form/filterBlocks/smartFilter/redux/prepareFiltersEnd';
import { prepareFiltersFail } from '../form/filterBlocks/smartFilter/redux/prepareFiltersFail';
import { reducerBuilder as history } from '../history/redux/reducers';

import { resetToInitial } from './resetToInitial';

export const reducerBuilder = new ReducerBuilder([
  searchBootstrapped,
  fetchSearch,
  subscriptionCreate,
  subscriptionDelete,
  subscriptionProcessStart,
  updateSmartFilter,
  prepareFiltersStart,
  prepareFiltersEnd,
  prepareFiltersFail,
  resetToInitial,
]);

reducerBuilder.merge(form);
reducerBuilder.merge(filter);
reducerBuilder.merge(law);
reducerBuilder.merge(text);
reducerBuilder.merge(date);
reducerBuilder.merge(documentType);
reducerBuilder.merge(judge);
reducerBuilder.merge(court);
reducerBuilder.merge(previousCourt);
reducerBuilder.merge(courtType);
reducerBuilder.merge(region);
reducerBuilder.merge(caseType);
reducerBuilder.merge(disputeCategory);
reducerBuilder.merge(caseNumber);
reducerBuilder.merge(claimAmount);
reducerBuilder.merge(caseResult);
reducerBuilder.merge(appealState);
reducerBuilder.merge(participant);
reducerBuilder.merge(plaintiff);
reducerBuilder.merge(defendant);
reducerBuilder.merge(thirdPartyPerson);
reducerBuilder.merge(anotherPerson);
reducerBuilder.merge(representative);
reducerBuilder.merge(history);

reducerBuilder.setInitialState(initial());

export default reducerBuilder;
