import { connect } from 'react-redux';

import { httpFetchRelation } from '@pages/document/sidebar/redux/fetchRelation.http';

import { TooltipRelationsWithClickOutside } from './tooltipRelations';

const mapDispatchToProps = (dispatch, ownProps) => ({
  async onFetchRelation() {

    const { documentId } = ownProps;
    const { relatedEntities } = await httpFetchRelation({ documentId, relationId: ownProps.tooltipIdRelation });

    return {
      document: relatedEntities.document,
      articles: relatedEntities.articles,
      clauses: relatedEntities.clauses,
    };
  },
});

export const TooltipRelationWithClickConnect = connect(
  null,
  mapDispatchToProps,
)(TooltipRelationsWithClickOutside);

export default TooltipRelationWithClickConnect;
