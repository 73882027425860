import { Dispatch } from 'redux';

import { fetchSearch } from '../../../searchResult/redux/fetchSearch';

import { httpFetchFiltersFromCbToken } from './fetchFiltersFromCbToken.http';

export class FetchFiltersFromCbTokenThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(token: string) {
    try {
      const { filters } = await httpFetchFiltersFromCbToken(token);
      this.dispatchFetchDefaultFiltersSucceed({ filter: filters });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchDefaultFiltersSucceed(data) {
    this.dispatch(fetchSearch.createAction(data));
  }

}

export function fetchFiltersFromCbTokenThunk(dispatch, token) {
  const thunk = new FetchFiltersFromCbTokenThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(token);
}

export default fetchFiltersFromCbTokenThunk;
