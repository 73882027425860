import styled from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
}

style.Container = styled.div`
  width: 320px;
`;

style.Header = styled.div`
  display: flex;
  height: 48px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: Roboto-Medium;

  padding: 8px 16px;
  background: white;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Requisites = styled.div`
  color: ${(props: Props) => props.theme.colors.defaultText};
  padding: 8px 16px;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Annotation = styled.div`
  padding: 8px 16px;
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.Actions = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  height: 48px;
`;

export default style;
