import React from 'react';

import { withResetFilters } from '@pages/search/withResetFilters';

import { WithSidebar } from '@components/withSidebar';
import { StaticSidebar } from '@components/staticSidebar/staticSidebar';

import { AuthRouteConnect } from '@router/authRoute.connect';

import { RoutePath } from '@router/path';
import { BookmarksConnect } from './bookmarks.connect';

export const RouteBookmarks = (
  <AuthRouteConnect
    path={RoutePath.bookmarks}
    component={WithSidebar(StaticSidebar, withResetFilters(BookmarksConnect))}
    exact
  />
);

export default RouteBookmarks;
