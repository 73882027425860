import { StateBuilder } from './state';

export interface ActionData {
  id: number;
  name: string;
}

export const createPackageSucceedReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.searchResult);
  const searchResult = newState.addPackage(action.data);

  return {
    ...state,
    searchResult,
  };
};

export default createPackageSucceedReduce;
