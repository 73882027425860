import { StateBuilder } from '../../../redux/packages/state';

export interface ActionData {
  packageId: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const removePackageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.packages);
  newState.removePackage(action.data.packageId);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default removePackageReduce;
