import moment from 'moment';

import { AbstractFilterBlock } from '../../abstract';

interface Scope {
  id: string;
  title: string;
}

type Value = string[];

export interface DateBlockData {
  value: Value;
  scope: Scope;
}

export enum ScopeIdDTO {
  allTime = 'all_time',
  year = 'year',
  twoYears = 'two_years',
  fiveYears = 'five_years',
  range = 'range',
}

export enum ScopeId {
  allTime = 'allTime',
  year = 'year',
  twoYears = 'twoYears',
  fiveYears = 'fiveYears',
  date = 'date',
  range = 'range',
}

export enum ScopeTitle {
  allTime = 'Дата последнего события',
  year = 'Зарегистрировано за последний год',
  twoYears = 'Зарегистрировано за последние 2 года',
  fiveYears = 'Зарегистрировано за последние 5 лет',
  range = 'Зарегистрировано',
}

export enum ScopeItemTitle {
  allTime = 'За все время',
  year = 'За последний год',
  twoYears = 'За последние 2 года',
  fiveYears = 'За последние 5 лет',
  range = 'Произвольный диапазон',
}

export interface DatePreset {
  id: ScopeId;
  DTOid: ScopeIdDTO.allTime;
  title: ScopeTitle.allTime;
}

export class DateBlock extends AbstractFilterBlock {

  static scopePresets = {
    [ScopeIdDTO.allTime]: {
      id: ScopeId.allTime,
      DTOid: ScopeIdDTO.allTime,
      title: ScopeTitle.allTime,
      itemTitle: ScopeItemTitle.allTime,
    },
    [ScopeIdDTO.year]: {
      id: ScopeId.year,
      DTOid: ScopeIdDTO.year,
      title: ScopeTitle.year,
      itemTitle: ScopeItemTitle.year,
    },
    [ScopeIdDTO.twoYears]: {
      id: ScopeId.twoYears,
      DTOid: ScopeIdDTO.twoYears,
      title: ScopeTitle.twoYears,
      itemTitle: ScopeItemTitle.twoYears,
    },
    [ScopeIdDTO.fiveYears]: {
      id: ScopeId.fiveYears,
      DTOid: ScopeIdDTO.fiveYears,
      title: ScopeTitle.fiveYears,
      itemTitle: ScopeItemTitle.fiveYears,
    },
    [ScopeIdDTO.range]: {
      id: ScopeId.range,
      DTOid: ScopeIdDTO.range,
      title: ScopeTitle.range,
      itemTitle: ScopeItemTitle.range,
    },
  };

  protected defaultData: DateBlockData = {
    scope: DateBlock.scopePresets[ScopeIdDTO.allTime],
    value: ['', ''],
  };

  constructor() {
    super();

    this.setData(this.defaultData);
  }

  public create(block) {
    this.setData(block.data);
  }

  public createFromScratch(props) {
    if (!props) {
      throw Error('data is required');
    }

    const getDate = (stringOfDate: string) => new Date(moment(stringOfDate, 'YYYYMMDD').toDate());

    const firstValue = props.value[0] ? getDate(props.value[0]) : '';
    const secondValue = props.value[1] ? getDate(props.value[1]) : '';

    const value = [
      firstValue,
      secondValue,
    ];

    const data = {
      scope: DateBlock.scopePresets[props.scope],
      value,
    };

    const block = {
      data,
    };

    this.create(block);
  }

  public decorate() {
    return {
      data: this.getData(),
    };
  }

  public undecorate() {
    const data = {
      scope: this.getData().scope.DTOid,
      value: [],
    };

    const formatString = 'YYYYMMDD';
    const isRange = this.getData().scope.id === DateBlock.scopePresets.range.id;

    if (!isRange) {
      data.value = [];

      return {
        data,
      };
    }

    const firstDate = moment(this.getData().value[0]);
    if (firstDate.isValid()) {
      // @ts-ignore
      data.value[0] = (firstDate.format(formatString));
    }

    const secondDate = moment(this.getData().value[1]);
    if (secondDate.isValid()) {
      // @ts-ignore
      data.value[1] = (secondDate.format(formatString));
    }

    return {
      data,
    };
  }

  // eslint-disable-next-line
  public validate() { }

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

}

export default DateBlock;
