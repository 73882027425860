import { store } from '../redux/store';
import { ActionApp } from '../redux/action';

const BASE_URL = '/';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function buildUrl(url, baseUrl) {
  if (baseUrl === BASE_URL) {
    return url;
  }

  const segment = url.toLowerCase().split('://')[0];
  if (segment === 'http' || segment === 'https') {
    return url;
  }

  return url.substr(0, 1) === '/' ? baseUrl + url : `${baseUrl}/${url}`;
}

function buildQueryString(params) {
  return Object
    .keys(params)
    .map((p) => `${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`)
    .join('&');
}

function isFormData(value) {
  return (typeof FormData !== 'undefined') && (value instanceof FormData);
}

function isGetOrDeleteMethod(config) {
  return ['GET', 'DELETE'].includes(config.method);
}

function needsQueryString(config) {
  return isGetOrDeleteMethod(config) && !!config.body;
}

function request(inputConfig) {
  let config = {};
  config.headers = inputConfig.headers || {};
  config.body = inputConfig.data;
  config = { ...config, ...inputConfig };

  let queryString = '';
  if (needsQueryString(config)) {
    queryString = `?${buildQueryString(config.body)}`;
  } else if (!isFormData(config.body)) {
    config.body = JSON.stringify(config.body);
    config.headers = { ...config.headers, ...headers };
  }

  const options = {
    method: config.method,
    headers: config.headers,
    credentials: 'include',
  };

  if (!isGetOrDeleteMethod(config)) {
    options.body = config.body;
  }

  const url = buildUrl(config.url, BASE_URL) + queryString;

  // eslint-disable-next-line no-undef
  return fetch(url, options);
}

function handleResponse(response) {
  return response.ok ?
    Promise.resolve(response) :
    Promise.reject(response);
}

/**
 * @param {Response} response
 * @returns {boolean}
 */
export function isBadRequest(response) {
  return (response instanceof Response) && (response.status === 400);
}

/**
 * @param {Response} response
 * @returns {boolean}
 */
export function isUnauthorized(response) {
  return (response instanceof Response) && (response.status === 401);
}

/**
 * @param {Response} response
 * @returns {boolean}
 */
export function isNotFound(response) {
  return (response instanceof Response) && (response.status === 404);
}

export function isPaymentRequired(response) {
  return (response instanceof Response) && (response.status === 402);
}

function handlePaymentRequired(response) {
  if (isPaymentRequired(response)) {
    // eslint-disable-next-line
    window.location.href = '#/profile';
  }

  return Promise.reject(response);
}

function handleUnauthorized(response) {
  if (isUnauthorized(response)) {
    store.dispatch(ActionApp.initialState());
  }

  return Promise.reject(response);
}

function parseResponse(response) {
  return response.json();
}

function middlewareCatch(chainHandle, isParticipant) {
  return (response) => {
    if (!isParticipant) {
      return Promise.reject(response);
    }

    return chainHandle(response);
  };
}

/**
 * @param {Promise} promise
 * @param {Object} [options]
 * @param {boolean} [options.handleUnauthorized = true]
 * @param {boolean} [options.handlePaymentRequired = true]
 * @param {boolean} [options.handleADEnabledUnauthorized = true]
 * @param {boolean} [options.handleNotFound = true]
 * @param {boolean} [options.handleExpiredPassword = true]
 *
 * @returns {Promise<*>}
 */
function httpFetch(promise, options = {}) {
  // eslint-disable-next-line no-param-reassign
  options = {
    handleUnauthorized: true,
    handleADEnabledUnauthorized: true,
    handleNotFound: true,
    handleExpiredPassword: true,
    handlePaymentRequired: true,
    ...options,
  };

  return promise
    .then(handleResponse)
    .catch(middlewareCatch(handleUnauthorized, options.handleUnauthorized))
    .catch(middlewareCatch(handlePaymentRequired, options.handlePaymentRequired));
}

export const Http = {
  handleResponse,
  parseResponse,
  isPaymentRequired,
  handlePaymentRequired,
  isBadRequest,
  isUnauthorized,
  isNotFound,
  get(params) {
    return request({ ...params, method: 'GET' });
  },
  post(params) {
    return request({ ...params, method: 'POST' });
  },
  put(params) {
    return request({ ...params, method: 'PUT' });
  },
  delete(params) {
    return request({ ...params, method: 'DELETE' });
  },
  fetch: httpFetch,
};

export default Http;
