import React from 'react';

import s from './day.style';

type DayNumber = number;

interface DayProps {
  day: DayNumber;
  loading: boolean;
  isSelected: boolean;
  hasEvents: boolean;
  firstDayNumber: number;
  onClick:(day: DayNumber) => void;
}

export class Day extends React.PureComponent<DayProps> {

  private handleClick = () => {
    if (!this.props.hasEvents) {
      return;
    }

    this.props.onClick(this.props.day);
  };

  render() {
    return (
      <s.Root
        isActive={this.props.hasEvents}
        isSelected={this.props.isSelected}
        firstDayNumber={this.props.firstDayNumber}
        onClick={this.handleClick}
        isLoading={this.props.loading}
      >
        {this.props.day}
      </s.Root>
    );
  }

}

export default Day;
