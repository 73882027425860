import { StateBuilder } from './state';

export const toggleLayoutTypeReduce = (state) => {
  if (!state) {
    return state;
  }

  const layoutState = new StateBuilder(state.layout);
  layoutState.toggleLayoutType();
  const layout = layoutState.getState();

  return {
    ...state,
    layout,
  };
};

export default toggleLayoutTypeReduce;
