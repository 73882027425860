import { resetEventsReduce } from './resetEvents.reduce';

export const actionType = 'REGULATION/EVENTS/RESET_EVENTS';

const createAction = () => ({
  type: actionType,
});

export const resetEvents = {
  actionType,
  createAction,
  reduce: resetEventsReduce,
};
