import React from 'react';

import { Loader } from '@components/loader';

import {
  CarouselConnect,
  EventsConnect,
} from './components';

import s from './calendar.style';

interface CalendarProps {
  calendarsLoading: boolean;
  onFetchCalendars(): void;
  onReset(): void;
}

export class Calendar extends React.PureComponent<CalendarProps> {

  componentDidMount() {
    this.props.onFetchCalendars();
  }

  componentWillUnmount() {
    this.props.onReset();
  }

  renderLayout() {
    if (this.props.calendarsLoading) {
      return <Loader />;
    }

    return (
      <>
        <CarouselConnect />
        <EventsConnect />
      </>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderLayout()}
      </s.Root>
    );
  }

}

export default Calendar;
