import React from 'react';
import s from './tooltip.style';

interface TooltipProps {
  title: string;
}

export const Tooltip = ({ title }: TooltipProps) => (
  <s.TooltipWrapper>
    <s.Tooltip>{title}</s.Tooltip>
  </s.TooltipWrapper>
);

export default Tooltip;
