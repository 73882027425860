import { StateBuilder } from '../../redux/state';

interface Edition {
  link: string;
  title: string;
}
export interface ActionData {
  editions: Edition[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchEditionsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);

  newState.prepareEditions(action.data.editions);
  newState.setEditionsLoading(false);

  return newState;
};

export default fetchEditionsReduce;
