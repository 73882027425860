import React from 'react';

import s from './item.style';

export interface ItemProps {
  value: JSX.Element;
}

export const Item = (props: ItemProps) => (
  <s.Item>
    {props.value}
  </s.Item>
);

interface ItemTitleProps {
  title: string;
}

export const ItemTitle = (props: ItemTitleProps) => (
  <s.ItemTitle>
    {props.title}
  </s.ItemTitle>
);

export default Item;
