import React from 'react';

import { Userpilot } from '@utils/userpilot';

import { Loader } from '@components/loader';
import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { Header } from '@components/header/simple/header';

import { Bookmarks as BookmarksBuffer } from '@layouts/bookmarks/components/bookmarks';

import { sendAmplitudeData } from '@utils/amplitude';

import { WorkspaceConnect as Workspace } from './workspace/workspace.connect';

import s from './bookmarks.style';

interface BookmarksProps {
  loading: boolean;
  onInit(): void;
  onResetToInitial(): void;
}

export class Bookmarks extends React.PureComponent<BookmarksProps> {

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('bookmarks view');
    Userpilot.track('bookmarks view');

    this.props.onInit();
  }

  componentWillUnmount() {
    this.props.onResetToInitial();
  }

  renderMain() {
    if (this.props.loading) {
      return <Loader />;
    }

    return (
      <s.Content>
        <Workspace />
      </s.Content>
    );
  }

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <Header
            title="Мои заметки"
          >
            <BookmarksBuffer />
          </Header>
          {this.renderMain()}
        </s.Root>
      </WithHeaderNotification>
    );
  }

}

export default Bookmarks;
