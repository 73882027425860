import { unpublishAuthorPackageReduce } from './unpublishAuthorPackage.reduce';

import type { ActionData } from './unpublishAuthorPackage.reduce';

const actionType = 'PACKAGES/UNPUBLISH_AUTHOR_PACKAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const unpublishAuthorPackage = {
  actionType,
  createAction,
  reduce: unpublishAuthorPackageReduce,
};

export default unpublishAuthorPackage;
