import React from 'react';

import { Loader } from '@components/loader';
import {
  Icon,
  iconsPath,
} from '@components/icons';

import { notifierManager } from './manager';

import s from './notification.style';

export enum NotificationType {
  pending = 'pending',
  done = 'done',
  fail = 'fail',
}

export interface NotificationProps {
  id?: string | number;
  linkTitle?: string;
  parentId: number | string;
  message: string;
  link?: string;
  type: NotificationType.pending | NotificationType.done | NotificationType.fail;
}

export class Notification extends React.PureComponent<NotificationProps> {

  static defaultProps = {
    link: null,
  };

  handleCloseNotification = () => {
    notifierManager.remove({ id: this.props.parentId });
  };

  renderLink() {
    if (!this.props.link) {
      return null;
    }

    const title = this.props.linkTitle || 'Скачать';

    return (
      <s.Link
        target="_blank"
        href={this.props.link}
        onClick={this.handleCloseNotification}
      >
        {title}
      </s.Link>
    );
  }

  renderFail() {
    const iconPath = iconsPath.get('cross');

    return (
      <s.Content isDone>
        <s.IconBlock>
          <Icon path={iconPath} color="white" />
        </s.IconBlock>
        <s.Message isDone>{this.props.message}</s.Message>
      </s.Content>
    );
  }

  renderDone() {
    const iconPath = iconsPath.get('notificationDone');

    return (
      <s.Content isDone>
        <s.IconBlock>
          <Icon points={iconPath} color="white" />
        </s.IconBlock>
        <s.Message isDone>{this.props.message}</s.Message>
        {this.renderLink()}
      </s.Content>
    );
  }

  renderPending() {

    return (
      <s.Content>
        <s.Loader>
          <Loader size={16} />
        </s.Loader>
        <s.Message>{this.props.message}</s.Message>
      </s.Content>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Container>
          {this.props.type === NotificationType.pending && this.renderPending()}
          {this.props.type === NotificationType.done && this.renderDone()}
          {this.props.type === NotificationType.fail && this.renderFail()}
        </s.Container>
      </s.Root>
    );
  }

}

export default Notification;
