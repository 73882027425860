import React from 'react';

import s from './annotationContent.style';

interface AnnotationProps {
  body: string;
}

export class AnnotationContent extends React.PureComponent<AnnotationProps> {

  render() {
    return (
      <s.Root>
        {this.props.body}
      </s.Root>
    );
  }

}

export default AnnotationContent;
