import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { checkProjects } from './checkProjects';
import { uncheckProjects } from './uncheckProjects';

export class CheckProjectsThunk {

  getState;

  static SELECT_ALL_LENGTH = 100;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    const { selectProjects } = this.getState().regulation.projects;

    const ids = this.getState().regulation.projects.projects.itemsList;
    const allSelected = selectProjects.length === ids.length;

    if (allSelected) {
      this.dispatchUnselectAll();

      return;
    }

    this.dispatchSelectAll();
    this.marketingEvent();

  }

  private dispatchSelectAll() {
    const selectProjects = this.getState().regulation.projects.projects.itemsList.slice(0, 100);
    this.dispatch(checkProjects.createAction({ selectProjects }));
  }

  private dispatchUnselectAll() {
    this.dispatch(uncheckProjects.createAction());
  }

  private marketingEvent() {
    const quantityProjects = this.getState().regulation.projects.selectProjects.length;
    sendAmplitudeData('regulation: select all projects', { quantityProjects });
    Userpilot.track('regulation: select all projects', { quantityProjects });
  }

}

export function checkProjectsThunk(dispatch) {
  const thunk = new CheckProjectsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default CheckProjectsThunk;
