import { connect } from 'react-redux';

import { withBootstrap } from '../withBootstrap';
import { LoginThunk } from './login.thunk';
import { Login } from './login';

const mapDispatchToProps = (dispatch) => ({
  async onLogin(email, password) {
    const loginThunk = new LoginThunk(dispatch);
    await loginThunk.invoke(email, password);
  },
});

const LoginConnect = connect(
  null,
  mapDispatchToProps,
)(Login);

export const LoginWithBootstrap = withBootstrap(LoginConnect);

export default LoginWithBootstrap;
