import { Dispatch } from 'redux';

import { changeSortColumn } from './changeSortColumn';
import { resetDocuments } from './resetDocuments';
import { fetchSearchCourtsThunk } from './fetchCourts.thunk';

export class ChangeSortThunk {

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(columnId: string) {
    try {
      this.dispatchChangeSortColumn(columnId);
      this.dispatchResetDocuments();

      await fetchSearchCourtsThunk(this.dispatch, true);
    // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  private dispatchChangeSortColumn(columnId: string) {
    this.dispatch(changeSortColumn.createAction(columnId));
  }

  private dispatchResetDocuments() {
    this.dispatch(resetDocuments.createAction());
  }

}

export function changeSortThunk(dispatch, columnId) {
  const thunk = new ChangeSortThunk(dispatch);

  thunk.invoke(columnId);
}

export default ChangeSortThunk;
