import React from 'react';

import { BlockType } from '../../components/blockType/blockType';
import { Contains } from '../../components/contains/contains';
import { Field } from '../../components/field/field';

import { CaseNumberBlock as Block } from './redux/caseNumber';

import { Data } from './redux/updateCaseNumber';

import s from './block.style';

export interface CaseNumberBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: Block;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onChangeContains: (data: any) => void;
  onUpdateCaseNumber: (data: Data) => void
}

export class CaseNumberBlock extends React.PureComponent<CaseNumberBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  handleChangeCaseNumber = (e) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        value: e.target.value,
      },
    };

    this.props.onUpdateCaseNumber(data);
  };

  handleRemoveValue = () => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        value: '',
      },
    };

    this.props.onUpdateCaseNumber(data);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderCaseNumber() {
    const data = this.props.item.getData();

    return (
      <s.FilterItem isGrow>
        <Field
          placeholder="Номер дела"
          value={data.value}
          onChange={this.handleChangeCaseNumber}
          onRemove={this.handleRemoveValue}

        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderCaseNumber()}
      </s.FilterBlock>
    );
  }

}

export default CaseNumberBlock;
