export const processingStatusReduce = (state, action) => {
  const processingStatus = action.data.status;

  return {
    ...state,
    processingStatus,
  };
};

export default processingStatusReduce;
