import { connect } from 'react-redux';

import { AddFolder } from '@components/addFolder/addFolder';

import { createFolderAddProjectsThunk } from '@pages/regulation/sidebar/folders/redux/createFolderAddProjects.thunk';
import { fetchFoldersThunk } from '../../../redux/searchFolders/fetchFolders.thunk';
import { addProjectsToFolderThunk } from '../../../redux/searchFolders/addProjectsToFolder.thunk';
import { resetToInitial } from '../../../redux/searchFolders/resetToInitial';

const mapStateToProps = (state) => {
  const {
    searchResult,
    projects,
  } = state.regulation;

  let disabled = !projects.selectProjects.length;
  const { folders } = state.regulation;
  const item = folders.folders.itemsMap.get(folders.activeItemId);
  const isLocalVisitor = item && item.role === 'visitor';
  const isGlobalVisitor = state.currentUser.licenses.regulation === 'visitor';
  if (isLocalVisitor || isGlobalVisitor) {
    disabled = true;
  }

  return {
    count: 0,
    items: searchResult,
    disabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInit() {
    fetchFoldersThunk(dispatch);
  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
  },
  onItemCreate(query: string) {
    const searchQuery = query.trim();

    const params = {
      name: searchQuery,
    };

    createFolderAddProjectsThunk(dispatch, params);
  },
  onItemToggle(folderId: number) {
    const params = {
      folderId,
    };

    addProjectsToFolderThunk(dispatch, params);
  },
});

export const AddProjectsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddFolder);

export default AddProjectsConnect;
