import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { appHistory } from '@router/history';

import { setSearchId } from '@pages/packages/redux/packages/setSearchId';
import { resetForm } from '@pages/search/form/redux/resetForm';

import { resetToInitial } from '../documents/resetToInitial';
import { fetchPackageDocumentsThunk } from '../documents/fetchDocuments.thunk';

import { selectPackage } from './selectPackage';

export class SelectThunk {

  getState;

  private notificationId = 'SELECT_PACKAGE_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { idFromUrlParams: number, sourceId: number }) {
    const { packages } = this.getState().packages;
    const sameId = params.sourceId === packages.activeItemId;

    if (sameId) {
      return;
    }

    this.dispatch(setSearchId.createAction({ id: null }));

    try {
      const sourceId = params.idFromUrlParams || params.sourceId;
      await this.dispatchSelectPackage(sourceId);

      this.dispatchResetToInitial();
      if (!params.idFromUrlParams) {
        appHistory.push(`/packages/${params.sourceId}`);
      }

      fetchPackageDocumentsThunk(this.dispatch, { sourceId });

      // eslint-disable-next-line no-empty
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchResetToInitial() {
    this.dispatch(resetToInitial.createAction());
    this.dispatch(resetForm.createAction());
  }

  private dispatchSelectPackage(sourceId) {
    const actionData = {
      sourceId,
    };

    this.dispatch(selectPackage.createAction(actionData));
  }

}

export function selectPackageThunk(dispatch, params) {
  const thunk = new SelectThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SelectThunk;
