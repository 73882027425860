import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Filters } from './state';

interface RequestParams {
  name: string;
  filters: Filters;
  id: number;
}

export function httpSaveFilters(requestParams: RequestParams) {
  const url = RESTPath.regulation.filters.work({ id: requestParams.id });
  const request = http.put({
    url,
    data: {
      name: requestParams.name,
      filters: requestParams.filters,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSaveFilters;
