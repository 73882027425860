import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { FetchSearchResultCaseTypeSettingsThunk } from './redux/fetchSettings.thunk';
import { FetchSearchCaseTypeThunk } from './redux/fetchCaseType.thunk';

import { CaseType } from './caseType';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSearchResultCaseTypeSettingsThunk(dispatch);
    thunk.invoke();
  },
  onFetchData() {
    const thunk = new FetchSearchCaseTypeThunk(dispatch);

    const { sourceId } = getParamsFromUrl(ownProps);
    thunk.invoke(sourceId);

    return thunk;
  },
});

export const CaseTypeConnect = connect(
  null,
  mapDispatchToProps,
)(CaseType);

export default CaseTypeConnect;
