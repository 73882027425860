import React from 'react';

import { Loader } from '@components/loader';
import { NotFound } from '@components/notFound';

import { BodyBlock } from '../../redux/state';

import s from './projectTab.style';

interface ProjectTabProps {
  loading: boolean;
  body: BodyBlock[];
}

export class ProjectTab extends React.PureComponent<ProjectTabProps> {

  static prepareMarkup = (body) => ({
    __html: `<div>${body}</div>`,
  });

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderPage = (item) => (
    <s.Page>
      {item.map(this.renderParagraph)}
    </s.Page>
  );

  renderParagraph = (paragraph) => {
    const style = {
      ...paragraph.style,
    };

    delete style.align;
    style.textAlign = paragraph.style.align;

    style.width = paragraph.style.width || 'auto';
    style.height = paragraph.style.height || 'auto';

    return (
      <s.Paragraph
        style={style}
        className={paragraph.type}
      >
        {paragraph.children.map(this.renderRow)}
      </s.Paragraph>
    );
  };

  renderRow = (item) => (
    <s.Row
      dangerouslySetInnerHTML={ProjectTab.prepareMarkup(item)}
    />
  );

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    if (!this.props.body.length) {
      return <NotFound title="Проект на стадии подготовки" />;
    }

    return (
      <s.Root>
        <s.Container>
          {this.props.body.map(this.renderPage)}
        </s.Container>
      </s.Root>
    );
  }

}

export default ProjectTab;
