import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateDisputeCategory } from './redux/updateDisputeCategory';

import { DisputeCategoryBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateDisputeCategory(data) {
    dispatch(updateDisputeCategory.createAction(data));
  },
});

export const DisputeCategoryBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisputeCategoryBlock);

export default DisputeCategoryBlockConnect;
