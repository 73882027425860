import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './spectractorItem.style';

export interface SpectractorItemProps {
  id: number;
  name: string;
  avatar: string;
  email: string;
  changeStatusTitle: string;
  familiarized: boolean;
}

export class SpectractorItem extends React.PureComponent<SpectractorItemProps> {

  state = {
    isAvatarHeightDominant: true,
  };

  handleAvatarLoad = ({ target: img }) => {
    const height = img.offsetHeight;
    const width = img.offsetWidth;

    if (height >= width) {
      this.setState({ isAvatarHeightDominant: true });
    } else {
      this.setState({ isAvatarHeightDominant: false });
    }
  };

  renderAvatar() {
    if (!this.props.avatar) {
      const avatarPath = iconsPath.get('avatar');

      return <s.AvatarIcon><Icon path={avatarPath} size={32} viewBoxSize={32} color="#dfe2e6" /></s.AvatarIcon>;
    }

    return <img onLoad={this.handleAvatarLoad} src={this.props.avatar} alt="avatar" />;
  }

  renderIcon() {
    if (this.props.familiarized) {
      return (
        <s.IconCheck>
          <Icon
            points={iconsPath.get('familiarizedCheck')}
            path={iconsPath.get('familiarized')}
          />
        </s.IconCheck>
      );
    }

    return (
      <s.IconUncheck>
        <Icon
          points={iconsPath.get('familiarizedUncheck')}
          path={iconsPath.get('familiarized')}
        />
      </s.IconUncheck>
    );
  }

  renderFamiliarized() {
    const title = this.props.familiarized ? 'Ознакомлен' : 'Не ознакомлен';

    return (
      <s.Familiarized>
        <s.FamiliarizedIcon>{this.renderIcon()}</s.FamiliarizedIcon>
        <s.FamiliarizedTitle>{title}</s.FamiliarizedTitle>
      </s.Familiarized>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Photo
          isAvatarHeightDominant={this.state.isAvatarHeightDominant}
        >
          {this.renderAvatar()}
        </s.Photo>
        <s.Info>
          <s.Name>
            {this.props.name}
          </s.Name>
          <s.Email>
            {this.props.email}
          </s.Email>
        </s.Info>
        <s.Status>
          {this.props.changeStatusTitle}
        </s.Status>
        {this.renderFamiliarized()}
      </s.Root>
    );
  }

}

export default SpectractorItem;
