import { fetchSpectractorsReduce } from './fetchSpectractors.reduce';

import type { ActionData } from './fetchSpectractors.reduce';

const actionType = 'REGULATION/PREVIEW__FETCH_SPECTRACTORS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSpectractors = {
  actionType,
  createAction,
  reduce: fetchSpectractorsReduce,
};

export default fetchSpectractors;
