import { Filters } from '../../../filters';

export const updateArticleReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const block = state.query.blocksMap.get(action.data.id);

  if (block.getData().article.id === action.data.data.id) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);

  const newData = {
    id: action.data.id,
    data: {
      article: {
        id: action.data.data.id,
        title: action.data.data.title,
        hasChildren: action.data.data.hasClauses,
      },
      clause: {
        id: null,
        title: '',
      },
    },
  };

  const blocksMap = filters.updateData(newData);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap,
    },
  };
};

export default updateArticleReduce;
