import { UserState } from './state';

export const logoutReduce = (state) => {
  if (!state) {
    return state;
  }

  const userState = new UserState();

  return userState.getState();
};

export default logoutReduce;
