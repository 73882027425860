import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  padding: 48px 64px;
`;

style.SimilarTitle = styled.div`
  display: flex;
  flex-direction: row;

  padding-bottom: 24px;

  font: 17px Roboto-Medium;
  line-height: 20px;
`;

style.Item = styled.div`
  padding: 8px 0;
`;

style.Title = styled.a`
  font: 13px Roboto-Regular;
  line-height: 16px;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

style.Description = styled.div`
  font: 11px Roboto-Regular;
  color: ${(props) => props.theme.colors.stone};
`;

export default style;
