import { ReducerBuilder } from '@redux/reducer.builder';

import { updateDocument } from './updateDocument';
import { updateArticle } from './updateArticle';
import { updateClause } from './updateClause';

export const reducerBuilder = new ReducerBuilder([
  updateDocument,
  updateArticle,
  updateClause,
]);

export default reducerBuilder;
