import { StateBuilder } from './state';

export interface ActionData {
  id: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const createEmptyProjectReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.preview);
  newState.createEmptyProject(action.data.id);

  const preview = {
    ...newState.getState(),
  };

  return {
    ...state,
    preview,
  };
};

export default createEmptyProjectReduce;
