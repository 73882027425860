import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './removeFilterButton.style';

type Props = {
  onClick: () => void,
};

const trashPath = iconsPath.get('trash');

export const RemoveFilterButton = ({ onClick }: Props) => (
  <s.RemoveFilterButton
    onClick={onClick}
    className="filter-remove-button"
  >
    <Icon path={trashPath} color="#6c7b89" />
  </s.RemoveFilterButton>
);

export default RemoveFilterButton;
