import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { theme } from '@components/styled';

import s from './notify.style';

interface LayoutSwitcherProps {
  checked: boolean;
  disabled: boolean;
  onToggle(): void;
}

export class Notify extends React.PureComponent<LayoutSwitcherProps> {

  handleIconClick = () => {
    if (this.props.disabled) {
      return;
    }

    this.props.onToggle();
  };

  renderIcon() {
    const color = this.props.disabled ? theme.colors.disabled : theme.colors.stone;

    if (this.props.checked) {
      return (
        <Icon
          points={iconsPath.get('mailCheckPoints')}
          path={iconsPath.get('mailCheck')}
          color={color}
        />
      );
    }

    return (
      <Icon
        path={iconsPath.get('mailPlus')}
        color={color}
      />
    );

  }

  render() {
    return (
      <s.Icon
        onClick={this.handleIconClick}
      >
        {this.renderIcon()}
      </s.Icon>
    );
  }

}

export default Notify;
