import { StateBuilder } from './state';

import type { UserDTO } from './state';

export type ActionData = UserDTO[];

export interface Action {
  type: string;
  data: ActionData;
}

export const fetchUsersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.users);
  newState.buildUsers(action.data);
  newState.setLoading(false);

  return {
    ...state,
    users: newState.getState(),
  };
};

export default fetchUsersReduce;
