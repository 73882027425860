import React from 'react';

import s from './list.style';

// @ts-ignore
const renderContains = (props) => {

  // @ts-ignore
  const renderItem = (block, index) => {

    const handleClick = () => {
      props.onChange(block.contains);
      props.closePopover();
    };

    return (
      <s.FilterItem
        key={index}
        onClick={handleClick}
        active={block.id === props.activeItem.id}
      >
        {block.title}
      </s.FilterItem>
    );
  };

  return (
    <s.FiltersContainer>
      <s.FilterItems>
        { props.items.map(renderItem) }
      </s.FilterItems>
    </s.FiltersContainer>
  );
};

// @ts-ignore
export const List = (props) => (
  <s.List>
    {renderContains(props)}
  </s.List>
);

export default List;
