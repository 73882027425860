import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const style: any = {};

style.Root = styled.nav`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

style.Item = styled(NavLink)`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
  text-decoration: none;
  background: transparent;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  :hover {
    background: rgba(255, 255, 255, 0.07);
  }
`;

style.ItemText = styled.span`
  padding-left: 8px;
  color: white;
`;

export default style;
