import {
  StateBuilder,
  ProjectDTO,
  RouteType,
} from './state';

interface Action {
  type: string;
  data: {
    search_after: [number, string];
    projects: ProjectDTO[];
    total_projects: number;
  };
}

export const fetchProjectsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.addProjects(action.data.projects);
  newState.setRouteType(RouteType.projects);
  newState.setTotalProjects(action.data.total_projects);
  newState.setCurrentPageNumber(action.data.search_after);
  newState.setLoading(false);
  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default fetchProjectsReduce;
