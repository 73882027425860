import styled from 'styled-components';

const style: any = {};

export function adaptive() {
  return `
    @media screen and (max-width: 704px) {
      width: calc(576px - 320px);
    }

    @media screen and (min-width: 704px) {
      width: calc(576px - 320px);
    }

    @media screen and (min-width: 896px) {
      width: calc(768px - 320px);
    }

    @media screen and (min-width: 1088px) {
      width: calc(960px - 320px);
    }

    @media screen and (min-width: 1280px) {
      width: calc(1152px - 320px);
    }
  `;
}

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  margin: 0 auto;

  min-width: 448px;

  ${adaptive()}
`;

style.Crest = styled.div`
  width: 72px;
  height: 72px;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
`;

style.Header = styled.h4`
  font-family: Roboto-Bold;
  text-align: center;
  padding: 3px;
`;

style.Title = styled.h4`
  padding-top: 9px;
  font-family: Roboto-Bold;
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
`;

export default style;
