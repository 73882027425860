import React from 'react';
import ReactDOM from 'react-dom';

import { ClickOutside } from './clickOutside';

let zIndexMain = 2000;

export interface RenderToProps {
  children: React.ReactNode
  onClickOutside?: () => void;
  useLayerForClickAway?: boolean;
  disabled?: boolean;
  usePortal?: boolean;
  ignoreClickTo?: HTMLElement;
  zIndex?: number;
}

export class RenderToLayer extends React.PureComponent<RenderToProps> {

  static defaultProps = {
    disabled: false,
    onClickOutside: () => {},
    useLayerForClickAway: false,
    ignoreClickTo: null,
    usePortal: false,
    zIndex: 0,
  };

  componentWillUnmount() {
    zIndexMain -= 1;
  }

  render() {
    const {
      children,
      usePortal,
      onClickOutside,
      useLayerForClickAway,
      disabled,
      ignoreClickTo,
      zIndex,
    } = this.props;

    const hasDocumentBodyTag = document && document.body;
    if (!hasDocumentBodyTag) {
      return null;
    }

    const portal = usePortal ?
      // @ts-ignore
      (reactNode) => ReactDOM.createPortal(reactNode, document.body) :
      // @ts-ignore
      (reactNode) => reactNode;

    zIndexMain += 1;

    const style = {
      position: usePortal ? 'absolute' : 'fixed',
      zIndex: zIndex || zIndexMain,
    } as React.CSSProperties;

    const styleLayer = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: zIndex || zIndexMain,
    } as React.CSSProperties;

    let mainBody;
    if (onClickOutside) {
      // @ts-ignore
      mainBody = ({ ref }) => (
        portal(<div ref={ref} style={style}>{children}</div>)
      );

      if (useLayerForClickAway) {
        // @ts-ignore
        mainBody = ({ ref }) => (
          portal(<div style={styleLayer}><div ref={ref} style={style}>{children}</div></div>)
        );
      }

      return (
        <ClickOutside
          onClickOutside={onClickOutside}
          disabled={disabled}
          ignoreClickTo={ignoreClickTo}
        >
          {mainBody}
        </ClickOutside>
      );
    }

    if (useLayerForClickAway) {
      return portal(<div style={styleLayer}><div>{children}</div></div>);
    }

    return portal(<div style={style}>{children}</div>);
  }

}

export default RenderToLayer;
