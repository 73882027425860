const actionType = 'SEARCH/RESULT/DEFINITION_CONTENT_TYPE/LAYOUT_SETTINGS__SAVE_SUCCEEDED';

const createAction = () => ({
  type: actionType,
});

export const saveLayout = {
  actionType,
  createAction,
  reduce: () => {},
};

export default saveLayout;
