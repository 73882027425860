import { connect } from 'react-redux';

import { BookmarkButton } from './bookmarkButton';

const mapStateToProps = (state) => ({
  loading: state.bookmarksBuffer.loading,
  itemsCount: state.bookmarksBuffer.queue.length,
});

export const BookmarkButtonConnect = connect(
  mapStateToProps,
)(BookmarkButton);

export default BookmarkButtonConnect;
