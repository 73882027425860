import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Header } from './header/header';

import { TabBar } from './tabBar/tabBar';

import { ProfileTab } from './profileTab/profileTab';
import { NotificationsTab } from './notificationsTab/notificationsTab';

import s from './profile.style';

enum Tabs {
  profile = 'profile',
  notifications = 'notifications',
}

interface ProfileProps {
  shouldRenderNotificationTab: boolean;
}

export class Profile extends React.PureComponent<ProfileProps> {

  protected tabs = {
    [Tabs.profile]: {
      id: Tabs.profile,
      title: 'Профиль',
      component: ProfileTab,
    },
    [Tabs.notifications]: {
      id: Tabs.notifications,
      title: 'Рассылка',
      component: NotificationsTab,
    },
  };

  state = {
    activeTabId: Tabs.profile,
  };

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('profile view');
    Userpilot.track('profile view');
  }

  setActiveTab = (id) => {
    this.setState({
      activeTabId: id,
    });
  };

  renderTabs() {
    const tabs = [this.tabs[Tabs.profile]];

    if (this.props.shouldRenderNotificationTab) {
      tabs.push(this.tabs[Tabs.notifications]);
    }

    const items = tabs.map((tab) => {
      if (tab.id === this.state.activeTabId) {
        return {
          id: tab.id,
          title: tab.title,
          active: true,
        };
      }

      return {
        id: tab.id,
        title: tab.title,
        active: false,
      };
    });

    return <TabBar onClick={this.setActiveTab} items={items} />;
  }

  renderActiveTab() {
    const Component = this.tabs[this.state.activeTabId].component;

    return <Component />;
  }

  render() {
    return (
      <s.Root>
        <s.HeaderContainer>
          <Header />
          {this.renderTabs()}
        </s.HeaderContainer>
        <s.TabContainer>
          <s.Tab>
            {this.renderActiveTab()}
          </s.Tab>
        </s.TabContainer>
      </s.Root>
    );
  }

}

export default Profile;
