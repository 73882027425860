import { connect } from 'react-redux';

import { RESTPath } from '@router/path.rest';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { uploadImageThunk } from '@pages/userPages/redux/uploadImage.thunk';
import { deleteImageThunk } from '@pages/userPages/redux/deleteImage.thunk';
import { fetchUser } from '@pages/userPages/redux/fetchUser';
import { httpFetchUser } from '@pages/userPages/http/user.http';

import { LogoControl } from './logoControl';

const mapStateToProps = (state) => ({
  hasImage: state.currentUser.avatar,
});

const mapDispatchToProps = (dispatch) => ({
  onUpload(image) {
    uploadImageThunk(dispatch, {
      image,
      actionSucceeded: async () => {
        sendAmplitudeData('user: uploadAvatar');
        Userpilot.track('user: uploadAvatar');
        const user = await httpFetchUser();
        const action = fetchUser.createAction(user);
        dispatch(action);
      },
      restPath: RESTPath.user.avatar,
    });
  },
  onDelete() {
    deleteImageThunk(dispatch, {
      actionSucceeded: async () => {
        sendAmplitudeData('user: deleteAvatar');
        Userpilot.track('user: deleteAvatar');
        const user = await httpFetchUser();
        const action = fetchUser.createAction(user);
        dispatch(action);
      },
      restPath: RESTPath.user.avatar,
    });
  },
});

export const AvatarControlConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoControl);

export default AvatarControlConnect;
