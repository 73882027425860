import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

export default style;
