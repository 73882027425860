import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { SettingsMenuConnect as SettingsMenu } from './settingsMenu.connect';

import s from './settings.style';

interface InputComponentProps {
  isActive: boolean;
}

const InputComponent = (props: InputComponentProps) => {
  const iconPath = iconsPath.get('kebab');

  return (
    <s.IconBlock>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

interface SettingsPopoverProps {
  onMenuVisible?: (visible: boolean) => void;
}

export const Settings = ({ onMenuVisible }: SettingsPopoverProps) => (
  <s.IconBlock>
    <BaseDropdown
      usePortal
      placement="top-end"
      onMenuVisible={onMenuVisible}
      renderInput={(p) => <InputComponent {...p} />}
      renderMenu={(p) => <SettingsMenu {...p} />}
    />
  </s.IconBlock>
);

Settings.defaultProps = {
  onMenuVisible: () => {},
};

export default Settings;
