import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  isPinned: boolean;
}

style.PinButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  min-width: 32px;
  height: 32px;
  padding: 0;
  margin-left: 5px;
  opacity: 0;
  position: relative;

  opacity: 0;
  background: transparent;
  cursor: pointer;

  transition: opacity .3s;

  ${(props: Props) => props.isPinned && css`
    opacity: 1;
  `}

  &:before {
    content: 'Закрепить фильтр';
    background: rgba(85, 99, 112, 0.9);
    color: ${theme.colors.white};
    font-size: 11px;
    padding: 4px 8px;
    width: max-content;
    position: absolute;
    top: -24px;
    left: -79px;
    display: none;
    border-radius: 2px;
    
    ${(props: Props) => props.isPinned && css`
      content: 'Открепить фильтр';
    `}
  }

  &:hover {
    background: rgba(65, 131, 215, 0.07);
    z-index: 3;

    &:before {
      display: block;
    }
  }


  &:focus {
    outline: none;
  }
`;

export default style;
