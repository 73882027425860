import {
  pageLoadingStartReduce,
  pageLoadingEndReduce,
} from './pageLoading.reduce';

export const actionTypeStart = 'BOOKMARKS/PAGE_LOADING_START';
export const actionTypeEnd = 'BOOKMARKS/PAGE_LOADING_END';

const createStartAction = () => ({
  type: actionTypeStart,
});

const createEndAction = () => ({
  type: actionTypeEnd,
});

export const pageLoadingStart = {
  actionType: actionTypeStart,
  createAction: createStartAction,
  reduce: pageLoadingStartReduce,
};

export const pageLoadingEnd = {
  actionType: actionTypeEnd,
  createAction: createEndAction,
  reduce: pageLoadingEndReduce,
};
