import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  flex-direction: row;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  height: 56px;
  padding: 0 8px;
  line-height: 30px;

  background-color: ${(props: Props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Menu = styled.div`
  flex-grow: 0;
`;

style.Breadcrumbs = styled.div`
  margin-left: 4px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font: 17px Roboto-Medium, sans-serif;
  font-weight: 500;
`;

function status() {
  return `
    padding: 1px 4px;
    margin-left: 7px;
    margin-top: 2px;
    height: 16px;
    border-radius: 2px;
    color: #ffffff;
    font: 11px Roboto-Medium;
    line-height: 16px;
  `;
}

style.GreyStatus = styled.div`
  ${status()};
  background: ${(props: Props) => props.theme.colors.silver};
`;

style.BlueStatus = styled.div`
  ${status()};
  background: ${(props: Props) => props.theme.colors.blue};
`;

style.CompanyStatus = styled.div`
  ${status()};
  background: #673ab7;
`;

style.StatusTitle = styled.i`
  margin-left: 8px;
  margin-top: 3px;
  font: 11px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.stone};
  font-style: italic;
`;

style.Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

style.Action = styled.div`
  margin-left: 8px;
`;

export default style;
