import { connect } from 'react-redux';

import { DateBlock } from './dateBlock';

import { updateDateThunk } from '../../../redux/updateDate.thunk';

import {
  ScopeId,
  ScopeIdDTO,
} from './logic/date';

const mapStateToProps = (state) => {

  const history = state.search.history;
  const historyData = history.data;
  const historyFiltersData = history.filters.data;
  const emptyHistory = (!historyData.length && historyFiltersData.scope === 'all_time');

  return {
    data: history.filters.data,
    disabled: emptyHistory,
    isDefault: emptyHistory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateValue(value) {
    if (value[0] || value[1]) {
      updateDateThunk(dispatch, { value });
    } else {

      updateDateThunk(dispatch, { scope: ScopeIdDTO.allTime });
    }
  },

  onUpdateScope(scopeId) {
    const scopeModify = {
      [ScopeId.allTime]: ScopeIdDTO.allTime,
      [ScopeId.today]: ScopeIdDTO.today,
      [ScopeId.yesterday]: ScopeIdDTO.yesterday,
      [ScopeId.dayBeforeYesterday]: ScopeIdDTO.dayBeforeYesterday,
      [ScopeId.threeDaysAgo]: ScopeIdDTO.threeDaysAgo,
      [ScopeId.thisWeek]: ScopeIdDTO.thisWeek,
      [ScopeId.lastWeek]: ScopeIdDTO.lastWeek,
      [ScopeId.currentMonth]: ScopeIdDTO.currentMonth,
      [ScopeId.lastMonth]: ScopeIdDTO.lastMonth,
      [ScopeId.range]: ScopeIdDTO.range,
    };

    const transferScope = scopeModify[scopeId];
    updateDateThunk(dispatch, { scope: transferScope });
  },
});

export const DateBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DateBlock);

export default DateBlockConnect;
