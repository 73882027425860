import { TagDTO } from './state';

export interface Tag {
  id: string;
  name: string;
}

export interface ActionData {
  tags: Tag[];
}

interface Action {
  type: string;
  data: ActionData;
}

function prepareTag(tag: Tag) {
  return {
    id: TagDTO[tag.id],
    title: tag.name || 'Без метки',
  };
}

function prepareTags(tags) {
  return tags.map((tag) => prepareTag(tag));
}

export const packagesBootstrapReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    presets: {
      tags: prepareTags(action.data.tags),
    },
  };
};

export default packagesBootstrapReduce;
