import { Filters } from '../../../filters';

export const updateParticipantReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);

  const newData = {
    id: action.data.id,
    data: {
      participant: {
        id: action.data.data.id,
        title: action.data.data.title,
      },
    },
  };

  const blocksMap = filters.updateData(newData);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap,
    },
  };
};

export default updateParticipantReduce;
