import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  isActive: boolean;
  disabled: boolean;
  theme: any;
}

style.IconBlock = styled.div`
  position: relative;

  width: 32px;
  height: 32px;
`;

style.Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 2px;

  color: ${(props) => props.theme.colors.stone};
  background-color: transparent;

  cursor: pointer;

  transition: all .3s;

  user-select: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  &:active {
    background-color: rgba(65, 131, 215, 0.12);
  }

  ${(props: Props) => props.isActive && css`
    color: ${props.theme.colors.defaultText};
    background-color: rgba(65, 131, 215, 0.07);
  `}

  ${(props: Props) => props.disabled && css`
    cursor: default;
    > i {
      color: ${props.theme.colors.disabled};
    }
    &:hover {
      background-color: transparent;
    }
  `}
`;

style.Count = styled.div`
  position: absolute;
  font-size: 10px;
  color: ${(props: Props) => props.theme.colors.white};
  padding-top: 1px;
`;

export default style;
