import React from 'react';

import { NotificationsCassetteConnect as NotificationCassette } from './notificationCassette.connect';

import s from '../profileTab/profileTab.style';

export class NotificationsTab extends React.PureComponent {

  render() {
    return (
      <s.Root>
        <NotificationCassette />
      </s.Root>
    );
  }

}

export default NotificationsTab;
