import React from 'react';

import { plural } from '@components/plural';
import { Loader } from '@components/loader';

import {
  SpectractorItem,
  SpectractorItemProps,
} from './spectractorItem';

import s from './spectractorsTab.style';

interface SpectractorsTabProps {
  items: SpectractorItemProps[];
  loading: boolean;
}

export class SpectractorsTab extends React.PureComponent<SpectractorsTabProps> {

  renderHeader() {
    const endings = ['наблюдатель', 'наблюдателя', 'наблюдателей'];
    const pluralize = plural(this.props.items.length, endings);
    const title = `${this.props.items.length} ${pluralize}`;

    return (
      <s.Header>
        {title}
      </s.Header>
    );
  }

  renderItem = (item) => <SpectractorItem {...item} />;

  renderList() {
    return (
      <s.List>
        {this.props.items.map(this.renderItem)}
      </s.List>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <s.Loader>
          <Loader size={32} />
        </s.Loader>
      );
    }

    return (
      <s.Root>
        {this.renderHeader()}
        {this.renderList()}
      </s.Root>
    );
  }

}

export default SpectractorsTab;
