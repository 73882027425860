import {
  styled,
  css,
} from '@components/styled';

import {
  hover,
  iconButton,
} from '@components/button/button.style';

const style: any = {};

interface Props {
  theme: any;
}

style.Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 48px;
  padding: 0 8px;

  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Back = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;

  cursor: pointer;

  ${iconButton};
  ${hover};

  position: absolute;

  i {
    color: ${(props: Props) => props.theme.colors.blue};
  }
`;

style.HeaderTitle = styled.div`
  font-family: Roboto-Medium;
  font-size: 13px;

  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

style.Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

style.List = styled.div`
  background: white;
  width: 256px;
  padding: 8px 0;
`;

style.Item = styled.div`
  padding: 12px 8px 13px 16px;
  height: 40px;
  position: relative;
  user-select: none;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }

  ${(props: any) => props.disabled && css`
    color: #a6b0ba;
    &:hover {
      background: none;
    }

    i {
      color: #dee2e6;
    }
  `}

  ${(props: any) => props.active && css`
    background: #f6fafe;
  `}
`;

export default style;
