import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Loader } from '@components/loader';

import { PackageItem } from './packageItem';

import s from './packageList.style';

type PackageId = number;

type Query = string;

interface Item {
  id: PackageId;
  title: string;
  selected: boolean;
}

interface Items {
  selectedArray: Item[];
  nonSelectedArray: Item[];
}

export interface PackageListProps {
  items: Items;
  searchQuery: Query;
  shouldRenderEmptyBlock: boolean;
  loading: boolean;
  addingPackage: boolean;
  onPackageToggle(id: PackageId): void;
  onPackageCreate(query: string): void;
}

export class PackageList extends React.PureComponent<PackageListProps> {

  handlePackageCreate = () => {
    this.props.onPackageCreate(this.props.searchQuery);
  };

  renderEmptyPackages = () => (<s.EmptyItem>Подборок пока нет</s.EmptyItem>);

  renderPackageItem = (item) => (
    <PackageItem
      key={item.id}
      id={item.id}
      title={item.title}
      selected={item.selected}
      type={item.type}
      onItemClick={this.props.onPackageToggle}
    />
  );

  renderSelectedItems() {
    const hasSelectedItems = !isEmpty(this.props.items.selectedArray);

    if (!hasSelectedItems) {
      return null;
    }

    return (
      <s.SelectedContainer>
        {this.props.items.selectedArray.map(this.renderPackageItem)}
      </s.SelectedContainer>
    );
  }

  renderNonSelectedItems() {
    const hasSelectedItems = !isEmpty(this.props.items.nonSelectedArray);

    if (!hasSelectedItems) {
      return null;
    }

    return this.props.items.nonSelectedArray.map(this.renderPackageItem);
  }

  renderCreateItem() {
    const hasSearchQuery = !!this.props.searchQuery.length;
    if (!hasSearchQuery) {
      return null;
    }

    const title = (
      <>
        <s.CreatePrefix>Создать&nbsp;</s.CreatePrefix>
        {this.props.searchQuery}
      </>
    );

    if (this.props.addingPackage) {
      return (
        <s.CreateItem
          key="create"
          id={0}
        >
          <s.CreateItemLoader>
            <Loader size={18} />
          </s.CreateItemLoader>
          {title}
        </s.CreateItem>
      );
    }

    return (
      <PackageItem
        key="create"
        id={0}
        title={title}
        selected={false}
        onItemClick={this.handlePackageCreate}
      />
    );
  }

  renderList = () => {
    const shouldRenderEmptyBlock = this.props.shouldRenderEmptyBlock && !this.props.searchQuery;
    if (shouldRenderEmptyBlock) {
      return this.renderEmptyPackages();
    }

    return (
      <>
        {this.renderCreateItem()}
        {this.renderSelectedItems()}
        {this.renderNonSelectedItems()}
      </>
    );
  };

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={24} />
      </s.Loader>
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return (
      <s.Root>{this.renderList()}</s.Root>
    );
  }

}

export default PackageList;
