import { LawBlock } from './law/redux/law';
import { TextBlock } from './text/redux/text';
import { DocumentTypeBlock } from './documentType/redux/documentType';
import { DateBlock } from './date/redux/date';
import { JudgeBlock } from './judge/redux/judge';
import { CourtBlock } from './court/redux/court';
import { PreviousCourtBlock } from './previousCourt/redux/previousCourt';
import { CourtTypeBlock } from './courtType/redux/courtType';
import { RegionBlock } from './region/redux/region';
import { CaseTypeBlock } from './caseType/redux/caseType';
import { DisputeCategoryBlock } from './disputeCategory/redux/disputeCategory';
import { CaseNumberBlock } from './caseNumber/redux/caseNumber';
import { ClaimAmountBlock } from './claimAmount/redux/claimAmount';
import { CaseResultBlock } from './caseResult/redux/caseResult';
import { AppealStateBlock } from './appealState/redux/appealState';
import { ParticipantBlock } from './participant/redux/participant';
import { PlaintiffBlock } from './plaintiff/redux/plaintiff';
import { DefendantBlock } from './defendant/redux/defendant';
import { ThirdPartyPersonBlock } from './thirdPartyPerson/redux/thirdPartyPerson';
import { AnotherPersonBlock } from './anotherPerson/redux/anotherPerson';
import { RepresentativeBlock } from './representative/redux/representative';

import { LawBlockConnect } from './law/block.connect';
import { DateBlockConnect } from './date/block.connect';
import { TextBlockConnect } from './text/block.connect';
import { DocumentTypeBlockConnect } from './documentType/block.connect';
import { JudgeBlockConnect } from './judge/block.connect';
import { CourtBlockConnect } from './court/block.connect';
import { PreviousCourtBlockConnect } from './previousCourt/block.connect';
import { CourtTypeBlockConnect } from './courtType/block.connect';
import { RegionBlockConnect } from './region/block.connect';
import { CaseTypeBlockConnect } from './caseType/block.connect';
import { DisputeCategoryBlockConnect } from './disputeCategory/block.connect';
import { CaseNumberBlockConnect } from './caseNumber/block.connect';
import { ClaimAmountBlockConnect } from './claimAmount/block.connect';
import { CaseResultBlockConnect } from './caseResult/block.connect';
import { AppealStateBlockConnect } from './appealState/block.connect';
import { ParticipantBlockConnect } from './participant/block.connect';
import { PlaintiffBlockConnect } from './plaintiff/block.connect';
import { DefendantBlockConnect } from './defendant/block.connect';
import { ThirdPartyPersonBlockConnect } from './thirdPartyPerson/block.connect';
import { AnotherPersonBlockConnect } from './anotherPerson/block.connect';
import { RepresentativeBlockConnect } from './representative/block.connect';

export const filterBlocksMap = new Map();

const law = { block: LawBlock, component: LawBlockConnect };
filterBlocksMap.set(LawBlock.type, law);

const text = { block: TextBlock, component: TextBlockConnect };
filterBlocksMap.set(TextBlock.type, text);

const documentType = { block: DocumentTypeBlock, component: DocumentTypeBlockConnect };
filterBlocksMap.set(DocumentTypeBlock.type, documentType);

const date = { block: DateBlock, component: DateBlockConnect };
filterBlocksMap.set(DateBlock.type, date);

const judge = { block: JudgeBlock, component: JudgeBlockConnect };
filterBlocksMap.set(JudgeBlock.type, judge);

const court = { block: CourtBlock, component: CourtBlockConnect };
filterBlocksMap.set(CourtBlock.type, court);

const previousCourt = { block: PreviousCourtBlock, component: PreviousCourtBlockConnect };
filterBlocksMap.set(PreviousCourtBlock.type, previousCourt);

const courtType = { block: CourtTypeBlock, component: CourtTypeBlockConnect };
filterBlocksMap.set(CourtTypeBlock.type, courtType);

const regionBlock = { block: RegionBlock, component: RegionBlockConnect };
filterBlocksMap.set(RegionBlock.type, regionBlock);

const caseTypeBlock = { block: CaseTypeBlock, component: CaseTypeBlockConnect };
filterBlocksMap.set(CaseTypeBlock.type, caseTypeBlock);

const disputeCategory = { block: DisputeCategoryBlock, component: DisputeCategoryBlockConnect };
filterBlocksMap.set(DisputeCategoryBlock.type, disputeCategory);

const caseNumber = { block: CaseNumberBlock, component: CaseNumberBlockConnect };
filterBlocksMap.set(CaseNumberBlock.type, caseNumber);

const claimAmount = { block: ClaimAmountBlock, component: ClaimAmountBlockConnect };
filterBlocksMap.set(ClaimAmountBlock.type, claimAmount);

const caseResult = { block: CaseResultBlock, component: CaseResultBlockConnect };
filterBlocksMap.set(CaseResultBlock.type, caseResult);

const appealState = { block: AppealStateBlock, component: AppealStateBlockConnect };
filterBlocksMap.set(AppealStateBlock.type, appealState);

const participant = { block: ParticipantBlock, component: ParticipantBlockConnect };
filterBlocksMap.set(ParticipantBlock.type, participant);

const plaintiff = { block: PlaintiffBlock, component: PlaintiffBlockConnect };
filterBlocksMap.set(PlaintiffBlock.type, plaintiff);

const defendant = { block: DefendantBlock, component: DefendantBlockConnect };
filterBlocksMap.set(DefendantBlock.type, defendant);

const thirdPartyPerson = { block: ThirdPartyPersonBlock, component: ThirdPartyPersonBlockConnect };
filterBlocksMap.set(ThirdPartyPersonBlock.type, thirdPartyPerson);

const anotherPerson = { block: AnotherPersonBlock, component: AnotherPersonBlockConnect };
filterBlocksMap.set(AnotherPersonBlock.type, anotherPerson);

const representative = { block: RepresentativeBlock, component: RepresentativeBlockConnect };
filterBlocksMap.set(RepresentativeBlock.type, representative);

export default filterBlocksMap;
