import { plural } from '@components/plural';

import {
  TextBlock, TextBlockData,
} from '../../form/filterBlocks/text/redux/text';

export abstract class TextBlockHistory extends TextBlock {

  static transform(data: TextBlockData): string {
    const result = data.value ? `${data.value} • ` : '';

    const findInTitle = TextBlock.presets.findIn[data.findIn].title;
    const modeTitle = TextBlock.presets.mode[data.mode].title.toLowerCase();
    let value = findInTitle;

    const isExactlyMode = data.mode === TextBlock.presets.mode.exactly.id;
    const showExtendSettings = data.value &&
      data.value.replace(/ +/g, ' ').trim().split(' ')
        .length > 1;

    const showPostfix = !isExactlyMode || showExtendSettings;

    if (showPostfix) {
      value = `${value}, ${modeTitle}`;
    }

    const isProximityMode = data.mode === TextBlock.presets.mode.proximity.id;
    if (isProximityMode) {
      const endings = ['слова', 'слов', 'слов'];
      const pluralize = plural(data.proximityRange, endings);

      return `${result}${value} ${data.proximityRange} ${pluralize}`;
    }

    return `${result}${value}`;
  }

}

export default TextBlockHistory;
