import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Sidebar } from './sidebar';

const mapStateToProps = (state) => {
  const { packages } = state.authorPackages;
  const hasPackages = !isEmpty(packages.itemsList);

  return {
    loading: packages.loading,
    activeItemId: packages.activeItemId,
    hasPackages,
  };
};

export const SidebarConnect = connect(
  mapStateToProps,
)(Sidebar);

export default SidebarConnect;
