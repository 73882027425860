import React from 'react';

import { RoutePath } from '@router/path';

import { AuthRouteConnect } from '@router/authRoute.connect';

import { PaymentSelectConnect } from './paymentSelect.connect';

export const RoutePaymentSelect = (
  <AuthRouteConnect
    exact
    path={RoutePath.paymentSelect}
    component={PaymentSelectConnect}
  />
);

export default RoutePaymentSelect;
