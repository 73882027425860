import {
  ActionData,
  addEmptyCalendarReduce,
} from './addEmptyCalendar.reduce';

export const actionType = 'REGULATION/EVENTS/ADD_EMPTY_CALENDAR';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addEmptyCalendar = {
  actionType,
  createAction,
  reduce: addEmptyCalendarReduce,
};
