import {
  createGlobalStyle,
  css,
} from 'styled-components';

const colorA = '#ffd687';
const colorB = '#cbef8d';
const colorC = '#fec4db';

const GlobalStyle = createGlobalStyle`

  .document-view__articles {
    * {
      ::selection {

        ${(props) => props.activeMarker === 'a' && css`
        color: #22313f;
        background: ${colorA};
      `};

        ${(props) => props.activeMarker === 'b' && css`
        color: #22313f;
        background: ${colorB};
      `};

        ${(props) => props.activeMarker === 'c' && css`
        color: #22313f;
        background: ${colorC};
      `};
      }
    }
  }
`;

export default GlobalStyle;
