import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Id } from '@pages/regulation/sidebar/folders/redux/item';

export interface RequestParams {
  folderId: Id,
  userId: Id;
  roleId: string;
}

export function httpChangeRole(requestParams: RequestParams): Promise<any> {
  const url = RESTPath.regulation.folders.changeRole(requestParams.folderId);

  const data = {
    user_id: requestParams.userId,
    role: requestParams.roleId,
  };

  const request = http.post({
    url,
    data,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpChangeRole;
