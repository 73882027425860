import {
  removePackageReduce,
  ActionData,
} from './removePackage.reduce';

const actionType = 'PACKAGES/REMOVE_PACKAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const removePackage = {
  actionType,
  createAction,
  reduce: removePackageReduce,
};

export default removePackage;
