import React from 'react';

import s from './paginationItem.style';

export type Page = number | '...';

interface Props {
  isActive: boolean;
  isSplitter: boolean;
  page: Page;
  onClick: (page: Page) => void;
}

export class PaginationItem extends React.PureComponent<Props> {

  handleItemClick = () => {
    const noClickable = this.props.isActive || this.props.isSplitter;
    if (noClickable) {
      return;
    }

    this.props.onClick(this.props.page);
  };

  render() {
    const noClickable = this.props.isActive || this.props.isSplitter;

    return (
      <s.PaginationItem
        isDisabled={noClickable}
        onClick={this.handleItemClick}
      >
        { this.props.page }
      </s.PaginationItem>
    );
  }

}

export default PaginationItem;
