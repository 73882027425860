import React from 'react';
import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './inputComponent.style';

interface InputComponentProps {
  isActive: boolean;
  iconName: string;
}

export const InputComponent = (props: InputComponentProps) => {
  const iconPath = iconsPath.get(props.iconName);

  return (
    <s.IconBlock>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

export default InputComponent;
