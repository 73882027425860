import React from 'react';

import { BlockType } from '../../components/blockType/blockType';
import { Contains } from '../../components/contains/contains';
import { Suggest } from '../../components/suggest/suggest';

import { JudgeBlock as Block } from './redux/judge';

import { Data } from './redux/updateJudge';

import s from './block.style';

export interface JudgeBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: Block;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onChangeContains: (data: any) => void;
  onUpdateJudge: (data: Data) => void
}

export class JudgeBlock extends React.PureComponent<JudgeBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  handleChangeJudge = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdateJudge(actualData);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderJudge() {
    const data = this.props.item.getData();
    const activeItem = {
      id: data.id,
      title: data.title,
    };

    return (
      <s.FilterItem isGrow>
        <Suggest
          useAnchorWidth
          activeItem={activeItem}
          placeholder="Судья"
          getItems={this.props.item.getItemsForSuggest}
          onChange={this.handleChangeJudge}
        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderJudge()}
      </s.FilterBlock>
    );
  }

}

export default JudgeBlock;
