import { LawBlockHistory } from './law';
import { JudgeBlockHistory } from './judge';
import { CourtBlockHistory } from './court';
import { PreviousCourtBlockHistory } from './previousCourt';
import { RegionBlockHistory } from './region';
import { DisputeCategoryBlockHistory } from './disputeCategory';
import { CaseNumberBlockHistory } from './caseNumber';
import { RepresentativeBlockHistory } from './representative';
import { DateBlockHistory } from './date';
import { TextBlockHistory } from './text';
import { ClaimAmountBlockHistory } from './claimAmount';
import { DocumentTypeBlockHistory } from './documentType';
import { CourtTypeBlockHistory } from './courtType';
import { CaseTypeBlockHistory } from './caseType';
import { CaseResultBlockHistory } from './caseResult';
import { AppealStateBlockHistory } from './appealState';
import { ParticipantBlockHistory } from './participant';
import { DefendantBlockHistory } from './defendant';
import { ThirdPartyPersonBlockHistory } from './thirdPartyPerson';
import { AnotherPersonBlockHistory } from './anotherPerson';
import { PlaintiffBlockHistory } from './plaintiff';

export const historyBlocksMap = new Map();

historyBlocksMap.set(LawBlockHistory.DTOType, LawBlockHistory);
historyBlocksMap.set(JudgeBlockHistory.DTOType, JudgeBlockHistory);
historyBlocksMap.set(CourtBlockHistory.DTOType, CourtBlockHistory);
historyBlocksMap.set(PreviousCourtBlockHistory.DTOType, PreviousCourtBlockHistory);
historyBlocksMap.set(RegionBlockHistory.DTOType, RegionBlockHistory);
historyBlocksMap.set(DisputeCategoryBlockHistory.DTOType, DisputeCategoryBlockHistory);
historyBlocksMap.set(CaseNumberBlockHistory.DTOType, CaseNumberBlockHistory);
historyBlocksMap.set(RepresentativeBlockHistory.DTOType, RepresentativeBlockHistory);
historyBlocksMap.set(DateBlockHistory.DTOType, DateBlockHistory);
historyBlocksMap.set(TextBlockHistory.DTOType, TextBlockHistory);
historyBlocksMap.set(ClaimAmountBlockHistory.DTOType, ClaimAmountBlockHistory);
historyBlocksMap.set(DocumentTypeBlockHistory.DTOType, DocumentTypeBlockHistory);
historyBlocksMap.set(CourtTypeBlockHistory.DTOType, CourtTypeBlockHistory);
historyBlocksMap.set(CaseTypeBlockHistory.DTOType, CaseTypeBlockHistory);
historyBlocksMap.set(CaseResultBlockHistory.DTOType, CaseResultBlockHistory);
historyBlocksMap.set(AppealStateBlockHistory.DTOType, AppealStateBlockHistory);
historyBlocksMap.set(ParticipantBlockHistory.DTOType, ParticipantBlockHistory);
historyBlocksMap.set(DefendantBlockHistory.DTOType, DefendantBlockHistory);
historyBlocksMap.set(ThirdPartyPersonBlockHistory.DTOType, ThirdPartyPersonBlockHistory);
historyBlocksMap.set(AnotherPersonBlockHistory.DTOType, AnotherPersonBlockHistory);
historyBlocksMap.set(PlaintiffBlockHistory.DTOType, PlaintiffBlockHistory);

export default historyBlocksMap;
