import { increasePackageCountReduce } from './increasePackageCount.reduce';

import type { ActionData } from './increasePackageCount.reduce';

const actionType = 'SUBSCRIPTIONS/DOCUMENTS/DOCUMENT__INCREASE_PACKAGE_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const increasePackageCount = {
  actionType,
  createAction,
  reduce: increasePackageCountReduce,
};

export default increasePackageCount;
