import { connect } from 'react-redux';

import { Type } from '../../redux/settings';

import { Header } from './header';

const mapStateToProps = (state, ownProps) => {

  const { id } = ownProps;
  const { type } = state.searchResult.analysis.itemsMap[id].layout;

  const isAppealState = type === Type.appealState;
  let result = 'исходам рассмотрения';
  if (isAppealState) {
    result = 'статусам обжалования';
  }

  const title = `Даты принятия по ${result}`;

  return {
    id,
    title,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
