import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './addPackageButton.style';

const Count = (props: { count: number }) => {
  if (!props.count) {
    return null;
  }

  return <s.Count>{props.count}</s.Count>;
};

interface AddPackageButtonProps {
  count: number;
  isActive: boolean;
  disabled: boolean;
}

export const AddPackageButton = (props: AddPackageButtonProps) => {
  const iconPath = props.count ? iconsPath.get('fillFolder') : iconsPath.get('emptyFolder');

  return (
    <s.IconBlock>
      <s.Button
        disabled={props.disabled}
        isActive={props.isActive}
      >
        <Count count={props.count} />
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

export default AddPackageButton;
