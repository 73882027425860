import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

export const RoutePackageChronologyDocument = (
  <AuthRouteConnect
    path={RoutePath.packageChronologyDocument}
    component={DocumentConnect}
    exact
  />
);

export default RoutePackageChronologyDocument;
