import { resetItems as reduceResetItems } from './resetItems.reduce';

const actionType = 'SEARCH/RESULT/DISPUTE_CATEGORY/RESET_ITEMS';

const createAction = () => ({
  type: actionType,
});

export const resetItems = {
  actionType,
  createAction,
  reduce: reduceResetItems,
};

export default resetItems;
