import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchDocument } from './fetchDocument';
import { fetchAttributes } from './fetchAttributes';
import { fetchMarkers } from './fetchMarkers';
import { saveMarkers } from './saveMarkers';
import { changePreviewDocument } from './changePreviewDocument';
import { saveCommentary } from './saveCommentary';

export const reducerBuilder = new ReducerBuilder([
  fetchDocument,
  fetchAttributes,
  fetchMarkers,
  saveMarkers,
  changePreviewDocument,
  saveCommentary,
]);

export default reducerBuilder;
