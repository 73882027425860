import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { Tag } from '@pages/packages/redux/state';
import { httpSetTag } from '@pages/packages/redux/documents/setTag.http';

import { setTag } from './setTag';

export class SetTagThunk {

  getState;

  private notificationId = 'SET_TAG_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { tagId: string }) {
    const { sourceId, id } = this.getState().document;

    this.dispatchSetTag(params);

    try {
      const requestParams = {
        documentId: id,
        tagId: Tag[params.tagId],
        packageId: sourceId,
      };

      await httpSetTag(requestParams);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchSetTag(params) {
    this.dispatch(setTag.createAction(params));
  }

}

export function setTagThunk(dispatch, params) {
  const thunk = new SetTagThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SetTagThunk;
