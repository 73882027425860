import React, { Component } from 'react';

import { Checkbox } from '@components/checkbox/checkbox';

import { CellMore } from '@pages/searchResult/documents/list/cellMore';
import type { Document } from '@pages/searchResult/documents/redux/state';

import { AddPackageConnect } from './addPackage.connect';
import { SettingsConnect } from './settings.connect';

import s from './list.style';

interface State {
  showPreview: boolean;
}

type Id = string | number;

interface Props {
  sourceId: string;
  item: Document;
  selected: boolean;
  isActive: boolean;
  showCommentary: boolean;
  onItemSelect: (id: Id) => void;
  onItemClick: (id: Id) => void;
  onHandleSelectItem: (id: number) => void;
}

export class Item extends Component<Props, State> {

  handleItemSelect = () => {
    this.props.onItemSelect(this.props.item.id);
  };

  handleItemClick = () => {
    this.props.onItemClick(this.props.item.id);
  };

  handleHrefClick = (e) => {
    e.stopPropagation();
  };

  renderComment(comment) {
    if (!this.props.showCommentary) {
      return null;
    }

    const hasComment = comment.trim().length !== 0;
    if (!hasComment) {
      return null;
    }

    return (
      <s.Comment>
        <s.CommentCell colSpan="5">
          {comment}
        </s.CommentCell>
      </s.Comment>
    );
  }

  render() {
    const {
      item,
    } = this.props;

    const href = `#/subscription/${this.props.sourceId}/documents/${item.id}`;

    return (
      <>
        <s.Item
          isActive={this.props.isActive}
          onClick={this.handleItemClick}
        >
          <s.CellCheckbox>
            <s.BodyCellContainer>
              <Checkbox
                checked={this.props.selected}
                onClick={this.handleItemSelect}
              />
            </s.BodyCellContainer>
          </s.CellCheckbox>
          <s.CellDocument>
            <s.BodyCellContainer>
              <s.TitleText bold>{item.date}</s.TitleText>
              <s.TitleLink
                onClick={this.handleHrefClick}
                href={href}
                target="_blank"
              >
                {item.documentType}
              </s.TitleLink>
              <s.TitleLabel>{item.instanceNumber}</s.TitleLabel>
            </s.BodyCellContainer>
          </s.CellDocument>
          <s.CellText>
            <s.BodyCellContainer>
              <s.TitleText bold>{item.department}</s.TitleText>
              <s.SimpleTitleText>{item.documentTitle}</s.SimpleTitleText>
              <s.TitleAdditionalText>{item.caseResult}</s.TitleAdditionalText>
            </s.BodyCellContainer>
          </s.CellText>
          <CellMore>
            <AddPackageConnect
              id={item.id}
            />
          </CellMore>
          <CellMore>
            <SettingsConnect
              id={item.id}
            />
          </CellMore>
        </s.Item>
        {this.renderComment(item.comment)}
      </>
    );
  }

}

export default Item;
