import styled from 'styled-components';

const style: any = {};

style.Header = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  width: 100%;
  padding: 18px 16px 16px 16px;
  font-family: 'Roboto-Medium';
  font-size: 17px;
  font-weight: 500;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Title = styled.div`
  display: flex;
  flex: 1;
`;

style.Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

style.OptionsKebab = styled.div``;

export default style;
