import styled, { css } from 'styled-components';

const style: any = {};

style.Root = styled.div`
  background: white;
  width: auto;
`;

style.List = styled.div`
  width: 256px;
`;

style.Edit = styled.div`
  width: 320px;
`;

style.Items = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

style.ItemTitle = styled.div`
  font-size: 13px;
`;

style.FilterItems = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  flex-wrap: wrap;
`;

style.Item = styled.div`
  padding: 12px 16px;
  position: relative;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }

  ${(props: any) => props.disabled && css`
    color: #a6b0ba;
    &:hover {
      background: none;
    }

    i {
      color: #dee2e6;
    }
  `}

  ${(props: any) => props.active && css`
    background: #f6fafe;
  `}
`;

style.ArrowItem = styled.div`
  display: flex;
  height: 32px;
  width: 32px;

  cursor: pointer;
  color: ${(props: any) => props.theme.colors.disabled};

  align-items: center;
  justify-content: center;
`;

export default style;
