// eslint-disable-next-line max-classes-per-file
import { Map } from 'immutable';

import {
  Document,
  DocumentId,
  Items,
} from '../../redux/state';

import { AnalysisSettings } from './settings';

export interface ItemsProp {
  itemsList: Items;
  itemsMap: Record<DocumentId, Document> | Record<string, unknown>,
}

class ItemBuilder {

  protected id: string;

  protected title: string;

  protected layout: Record<string, any>;

  protected shouldLoadSettings: boolean;

  protected shouldLoadInitialData: boolean;

  protected items: ItemsProp;

  constructor() {
    // @ts-ignore
    this.id = undefined;
    // @ts-ignore
    this.title = undefined;
    this.layout = {};
    this.shouldLoadSettings = true;
    this.shouldLoadInitialData = true;
    this.items = {
      itemsList: [],
      itemsMap: Map(),
    };
  }

  public getId = () => this.id;

  private setId(id: string) {
    if (!id) {
      throw Error('id is required');
    }

    this.id = id;
  }

  private setTitle(title: string) {
    if (!title) {
      throw Error('title is required');
    }

    this.title = title;
  }

  setItems(items: ItemsProp) {
    if (!items.itemsList) {
      throw Error('itemsList is required');
    }

    if (!items.itemsMap) {
      throw Error('itemsMap is required');
    }

    this.items = items;
  }

  private setLayout(layout) {
    this.layout = layout;
  }

  private buildSettings() {
    return new AnalysisSettings();
  }

  protected setShouldLoadSettings(shouldLoad: boolean) {
    this.shouldLoadSettings = shouldLoad;
  }

  protected setShouldLoadInitialData(shouldLoad: boolean) {
    this.shouldLoadInitialData = shouldLoad;
  }

  invoke(data: { id, title }) {
    this.setId(data.id);
    this.setTitle(data.title);

    const settings = this.buildSettings();
    const layout = settings.initial();
    this.setLayout(layout);
  }

}

class BuildSimpleType extends ItemBuilder {

  private getItems = () => this.items;

  public build(data: { id, title }) {
    this.invoke(data);
  }

  public getData() {
    return {
      id: this.id,
      title: this.title,
      layout: this.layout,
      shouldLoadSettings: this.shouldLoadSettings,
      shouldLoadInitialData: this.shouldLoadInitialData,
      items: this.items,
    };
  }

}

class BuildTypeWithPages extends ItemBuilder {

  private currentPageNumber: number;

  private totalPages: number;

  constructor() {
    super();

    this.currentPageNumber = 1;
    this.totalPages = 1;
  }

  public build(data: { id, title }) {
    this.invoke(data);
  }

  public getData() {
    return {
      id: this.id,
      title: this.title,
      layout: this.layout,
      shouldLoadSettings: this.shouldLoadSettings,
      shouldLoadInitialData: this.shouldLoadInitialData,
      currentPageNumber: this.currentPageNumber,
      totalPages: this.totalPages,
      items: this.items,
    };
  }

}

export const documentType = new BuildSimpleType();
documentType.invoke({ id: 'documentType', title: 'Типы документов' });
export const date = new BuildSimpleType();
date.invoke({ id: 'date', title: 'Даты принятия документов' });
export const caseType = new BuildSimpleType();
caseType.invoke({ id: 'caseType', title: 'Виды споров' });
export const definitionContentType = new BuildTypeWithPages();
definitionContentType.invoke({ id: 'definitionContentType', title: 'Темы определений' });
export const disputeCategory = new BuildTypeWithPages();
disputeCategory.invoke({ id: 'disputeCategory', title: 'Категории спора' });

export interface AnalysisState {
  itemsList: string[];
  itemsMap: Record<string, Record<string, any>>
}

export function buildAnalysisState(): AnalysisState {
  const defaultItems = { itemsList: [], itemsMap: Map() };
  documentType.invoke({ id: 'documentType', title: 'Типы документов' });
  documentType.setItems({ ...defaultItems });
  date.invoke({ id: 'date', title: 'Даты принятия документов' });
  date.setItems({ ...defaultItems });
  caseType.invoke({ id: 'caseType', title: 'Виды споров' });
  caseType.setItems({ ...defaultItems });
  definitionContentType.invoke({ id: 'definitionContentType', title: 'Темы определений' });
  definitionContentType.setItems({ ...defaultItems });
  disputeCategory.invoke({ id: 'disputeCategory', title: 'Категории спора' });
  disputeCategory.setItems({ ...defaultItems });

  const analysisTypes = {
    documentType: documentType.getData(),
    date: date.getData(),
    caseType: caseType.getData(),
    definitionContentType: definitionContentType.getData(),
    disputeCategory: disputeCategory.getData(),
  };

  const analysisItemsList = [
    analysisTypes.documentType.id,
    analysisTypes.definitionContentType.id,
    analysisTypes.date.id,
    analysisTypes.caseType.id,
    analysisTypes.disputeCategory.id,
  ];

  return {
    itemsList: analysisItemsList,
    itemsMap: analysisTypes,
  };
}

export default buildAnalysisState;
