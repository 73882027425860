/* eslint-disable */
/**
 * https://github.com/mir3z/texthighlighter
 */

// eslint-disable-next-line max-len
/* eslint-disable no-void, consistent-this, no-use-before-define, prefer-reflect, no-extra-boolean-cast, no-unused-expressions, no-throw-literal */

let
  tmpHoveredMark = null;

/**
   * Attribute added by default to every highlight.
   * @type {string}
   */
const DATA_ATTR = 'data-highlighted';

const DELETE_PIVOT_CLASS = 'mark-delete-pivot';

/**
   * Attribute used to group highlight wrappers.
   * @type {string}
   */
const TIMESTAMP_ATTR = 'data-timestamp';

const REMOVE_BUTTON_CLASS = 'mark-delete-helper';

const NODE_TYPE = {
  ELEMENT_NODE: 1,
  TEXT_NODE: 3,
};

/**
   * Don't highlight content of these tags.
   * @type {string[]}
   */
const IGNORE_TAGS = [
  'SCRIPT', 'STYLE', 'SELECT', 'OPTION', 'BUTTON', 'OBJECT', 'APPLET', 'VIDEO', 'AUDIO', 'CANVAS', 'EMBED',
  'PARAM', 'METER', 'PROGRESS',
];

/**
 * Returns true if elements a i b have the same color.
 * @param {Node} a
 * @param {Node} b
 * @returns {boolean}
 */
function haveSameColor(a, b) {
  return a.className === b.className;
}

/**
 * Fills undefined values in obj with default properties with the same name from source object.
 * @param {object} obj - target object
 * @param {object} source - source object with default values
 * @returns {object}
 */
function defaults(obj, source) {
  obj = obj || {};

  for (const prop in source) {
    // eslint-disable-next-line no-prototype-builtins
    if (source.hasOwnProperty(prop) && obj[prop] === void 0) {
      obj[prop] = source[prop];
    }
  }

  return obj;
}

/**
 * Returns array without duplicated values.
 * @param {Array} arr
 * @returns {Array}
 */
function unique(arr) {
  return arr.filter((value, idx, self) => self.indexOf(value) === idx);
}

/**
 * Takes range object as parameter and refines it boundaries
 * @param range
 * @returns {object} refined boundaries and initial state of highlighting algorithm.
 */
function refineRangeBoundaries(range) {
  let startContainer = range.startContainer;
  let endContainer = range.endContainer;
  const ancestor = range.commonAncestorContainer;
  let goDeeper = true;

  if (range.endOffset === 0) {
    while (!endContainer.previousSibling && endContainer.parentNode !== ancestor) {
      endContainer = endContainer.parentNode;
    }

    endContainer = endContainer.previousSibling;
  } else if (endContainer.nodeType === NODE_TYPE.TEXT_NODE) {
    if (range.endOffset < endContainer.nodeValue.length) {
      endContainer.splitText(range.endOffset);
    }
  } else if (range.endOffset > 0) {
    endContainer = endContainer.childNodes.item(range.endOffset - 1);
  }

  if (startContainer.nodeType === NODE_TYPE.TEXT_NODE) {
    if (range.startOffset === startContainer.nodeValue.length) {
      goDeeper = false;
    } else if (range.startOffset > 0) {
      startContainer = startContainer.splitText(range.startOffset);
      if (endContainer === startContainer.previousSibling) {
        endContainer = startContainer;
      }
    }
  } else if (range.startOffset < startContainer.childNodes.length) {
    startContainer = startContainer.childNodes.item(range.startOffset);
  } else {
    startContainer = startContainer.nextSibling;
  }

  return {
    startContainer,
    endContainer,
    goDeeper,
  };
}

/**
 * Sorts array of DOM elements by its depth in DOM tree.
 * @param {HTMLElement[]} arr - array to sort.
 * @param {boolean} descending - order of sort.
 */
function sortByDepth(arr, descending) {
  arr.sort((a, b) => dom(descending ? b : a).parents().length - dom(descending ? a : b).parents().length);
}

/**
 * Groups given highlights by timestamp.
 * @param {Array} highlights
 * @returns {Array} Grouped highlights.
 */
function groupHighlights(highlights) {
  const order = [];
  const chunks = {};
  const grouped = [];

  highlights.forEach((hl) => {
    const timestamp = hl.getAttribute(TIMESTAMP_ATTR);

    if (typeof chunks[timestamp] === 'undefined') {
      chunks[timestamp] = [];
      order.push(timestamp);
    }

    chunks[timestamp].push(hl);
  });

  order.forEach((timestamp) => {
    const group = chunks[timestamp];

    grouped.push({
      chunks: group,
      timestamp,
      toString() {
        return group.map((h) => h.textContent).join('');
      },
    });
  });

  return grouped;
}

/**
 * Utility functions to make DOM manipulation easier.
 * @param {Node|HTMLElement} [el] - base DOM element to manipulate
 * @returns {object}
 */
var dom = function (el) {
  return /** @lends dom * */ {

    /**
     * Adds class to element.
     * @param {string} className
     */
    addClass(className) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += ` ${className}`;
      }
    },

    /**
     * Removes class from element.
     * @param {string} className
     */
    removeClass(className) {
      if (el.classList) {
        el.classList.remove(className);
      } else {
        el.className = el.className.replace(new RegExp(`(^|\\b)${className}(\\b|$)`, 'gi'), ' ');
      }
    },

    /**
     * Prepends child nodes to base element.
     * @param {Node[]} nodesToPrepend
     */
    prepend(nodesToPrepend) {
      const nodes = Array.prototype.slice.call(nodesToPrepend);
      let i = nodes.length;

      while (i--) {
        el.insertBefore(nodes[i], el.firstChild);
      }
    },

    /**
     * Appends child nodes to base element.
     * @param {Node[]} nodesToAppend
     */
    append(nodesToAppend) {
      const nodes = Array.prototype.slice.call(nodesToAppend);

      for (let i = 0, len = nodes.length; i < len; ++i) {
        el.appendChild(nodes[i]);
      }
    },

    /**
     * Inserts base element after refEl.
     * @param {Node} refEl - node after which base element will be inserted
     * @returns {Node} - inserted element
     */
    insertAfter(refEl) {
      return refEl.parentNode.insertBefore(el, refEl.nextSibling);
    },

    /**
     * Inserts base element before refEl.
     * @param {Node} refEl - node before which base element will be inserted
     * @returns {Node} - inserted element
     */
    insertBefore(refEl) {
      try {
        return refEl.parentNode.insertBefore(el, refEl);
      } catch (e) {
        console.warn(e);
      }

    },

    /**
     * Removes base element from DOM.
     */
    remove() {
      el.parentNode.removeChild(el);
      el = null;
    },

    /**
     * Returns true if base element contains given child.
     * @param {Node|HTMLElement} child
     * @returns {boolean}
     */
    contains(child) {
      return el !== child && el.contains(child);
    },

    /**
     * Wraps base element in wrapper element.
     * @param {HTMLElement} wrapper
     * @returns {HTMLElement} wrapper element
     */
    wrap(wrapper) {
      if (el.parentNode) {
        el.parentNode.insertBefore(wrapper, el);
      }

      wrapper.appendChild(el);

      return wrapper;
    },

    /**
     * Unwraps base element.
     * @returns {Node[]} - child nodes of unwrapped element.
     */
    unwrap() {
      const nodes = Array.prototype.slice.call(el.childNodes);
      let wrapper;

      nodes.forEach((node) => {
        wrapper = node.parentNode;
        dom(node).insertBefore(node.parentNode);
        dom(wrapper).remove();
      });

      return nodes;
    },

    /**
     * Returns array of base element parents.
     * @returns {HTMLElement[]}
     */
    parents() {
      let parent; const
        path = [];

      while (!!(parent = el.parentNode)) {
        path.push(parent);
        el = parent;
      }

      return path;
    },

    /**
     * Normalizes text nodes within base element, ie. merges sibling text nodes and assures that every
     * element node has only one text node.
     * It should does the same as standard element.normalize, but IE implements it incorrectly.
     */
    normalizeTextNodes() {
      if (!el) {
        return;
      }

      if (el.nodeType === NODE_TYPE.TEXT_NODE) {
        while (el.nextSibling && el.nextSibling.nodeType === NODE_TYPE.TEXT_NODE) {
          el.nodeValue += el.nextSibling.nodeValue;
          el.parentNode.removeChild(el.nextSibling);
        }
      } else {
        dom(el.firstChild).normalizeTextNodes();
      }

      dom(el.nextSibling).normalizeTextNodes();
    },

    /**
     * Returns element background color.
     * @returns {CSSStyleDeclaration.backgroundColor}
     */
    color() {
      return el.style.backgroundColor;
    },

    /**
     * Creates dom element from given html string.
     * @param {string} html
     * @returns {NodeList}
     */
    fromHTML(html) {
      const div = document.createElement('div');

      div.innerHTML = html;

      return div.childNodes;
    },

    /**
     * Returns first range of the window of base element.
     * @returns {Range}
     */
    getRange() {
      const selection = dom(el).getSelection();
      let range;

      if (selection.rangeCount > 0) {
        range = selection.getRangeAt(0);
      }

      return range;
    },

    /**
     * Removes all ranges of the window of base element.
     */
    removeAllRanges() {
      const selection = dom(el).getSelection();

      selection.removeAllRanges();
    },

    /**
     * Returns selection object of the window of base element.
     * @returns {Selection}
     */
    getSelection() {
      return dom(el).getWindow().getSelection();
    },

    /**
     * Returns window of the base element.
     * @returns {Window}
     */
    getWindow() {
      return dom(el).getDocument().defaultView;
    },

    /**
     * Returns document of the base element.
     * @returns {HTMLDocument}
     */
    getDocument() {
      // if ownerDocument is null then el is the document itself.
      return el.ownerDocument || el;
    },

  };
};

/**
 * Creates TextHighlighter instance and binds to given DOM elements.
 * @param {HTMLElement} element - DOM element to which highlighted will be applied.
 * @param {object} [options] - additional options.
 * @param {string} options.color - highlight color.
 * @param {function} options.onRemoveHighlight - function called before highlight is removed. Highlight is
 *  passed as param. Function should return true if highlight should be removed, or false - to prevent removal.
 * @param {function} options.onBeforeHighlight - function called before highlight is created. Range object is
 *  passed as param. Function should return true to continue processing, or false - to prevent highlighting.
 * @param {function} options.onAfterHighlight - function called after highlight is created. Array of created
 * wrappers is passed as param.
 * @class TextHighlighter
 */
export function TextHighlighter(element, options) {
  if (!element) {
    throw 'Missing anchor element';
  }

  this.el = element;
  this.mode = 'observer'; // работаем в двух режимах: режим вставки выделений и режим удаления выделений (observer)
  this.options = defaults(options, {
    color: '#ffff7b',
    onRemoveHighlight() {
      return true;
    },
    onBeforeHighlight() {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAfterHighlight() {
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChangeHighlights() {
    },
  });

  if (this.highlightHandler.bind) {
    // in wkhtmltopdf there are not this.highlightHandler.bind (??)
    this.highlightHandler = this.highlightHandler.bind(this);
    this.pollutionsObserver = this.pollutionsObserver.bind(this);
    this.pollutionsObserverLeft = this.pollutionsObserverLeft.bind(this);
    this.pollutionRemoveButtonClick = this.pollutionRemoveButtonClick.bind(this);
    this.el.addEventListener('mouseup', this.highlightHandler);
    this.el.addEventListener('touchend', this.highlightHandler);
    this.el.addEventListener('mousemove', this.pollutionsObserver);
    this.el.addEventListener('click', this.pollutionRemoveButtonClick);
    this.el.addEventListener('mouseleave', this.pollutionsObserverLeft);
  }
}

TextHighlighter.prototype.color = '';
TextHighlighter.prototype.setInsertMode = function () {
  this.mode = 'insert';
};

TextHighlighter.prototype.isInsertMode = function () {
  return this.mode === 'insert';
};

TextHighlighter.prototype.setObserverMode = function () {
  this.mode = 'observer';
};

TextHighlighter.prototype.isObserverMode = function () {
  return this.mode === 'observer';
};

/**
 * рисует интерфейс удаления выделения
 */
TextHighlighter.prototype.pollutionsObserver = function (e) {
  if (!this.isObserverMode()) {
    return;
  }

  if (e.target.tagName === 'DIV' && e.target.className === REMOVE_BUTTON_CLASS) {
    return;
  }

  if (e.target.tagName === 'MARK' && this.options.enable) {
    this.renderRemoveButton(e.target);
  } else { this.removeRemoveButton(); }
};

/**
 * курсор уходит с контейнера подсветок
 */
TextHighlighter.prototype.pollutionsObserverLeft = function () {
  if (!this.isObserverMode()) {
    return;
  }

  this.removeRemoveButton();
};

TextHighlighter.prototype.renderRemoveButton = function (mark) {
  const timestamp = mark.dataset ? mark.dataset.timestamp : mark.getAttribute('data-timestamp');

  if (timestamp !== tmpHoveredMark) {
    this.removeRemoveButton();
    tmpHoveredMark = timestamp;
    const button = document.createElement('div');

    button.className = REMOVE_BUTTON_CLASS;

    // в пределах 1 сущности обрабатываем все подсветки, созданные за 1 раз, как одну подсветку
    const markers = mark.parentNode.querySelectorAll(`mark[data-timestamp="${timestamp}"]`);

    const pivot = document.createElement('div');

    pivot.className = DELETE_PIVOT_CLASS;
    markers[markers.length - 1].appendChild(pivot);

    button.style.left = `${pivot.offsetLeft}px`;
    button.style.top = `${pivot.offsetTop}px`;
    markers[markers.length - 1].appendChild(button);
  }
};

TextHighlighter.prototype.removeRemoveButton = function () {
  Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
  };

  NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (let i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i]);
      }
    }
  };

  if (tmpHoveredMark) {
    const button = this.el.querySelector(`mark div.${REMOVE_BUTTON_CLASS}`);
    const pivot = this.el.querySelector(`mark div.${DELETE_PIVOT_CLASS}`);

    button && button.remove();
    pivot && pivot.remove();
    tmpHoveredMark = null;
  }
};

TextHighlighter.prototype.pollutionRemoveButtonClick = function (e) {
  if (e.target.tagName === 'DIV' && e.target.className === REMOVE_BUTTON_CLASS) {
    // в пределах 1 сущности обрабатываем все подсветки, созданные за 1 раз, как одну подсветку
    const mark = e.target.parentElement;
    const timestamp = mark.dataset ? mark.dataset.timestamp : mark.getAttribute('data-timestamp');
    let markers = mark.parentNode.querySelectorAll(`mark[data-timestamp="${timestamp}"]`);

    markers = Array.prototype.slice.call(markers);
    this.removeRemoveButton();
    markers.forEach((mark) => {
      this.removeHighlights(mark, true);
    });
    this.options.onChangeHighlights();

    e.preventDefault();
  }
};

/**
 * Permanently disables highlighting.
 * Unbinds events and remove context element class.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.destroy = function () {
  this.el.removeEventListener('mouseup', this.highlightHandler);
  this.el.removeEventListener('touchend', this.highlightHandler);
  this.el.removeEventListener('mousemove', this.pollutionsObserver);
  this.el.removeEventListener('click', this.pollutionRemoveButtonClick);
  this.el.removeEventListener('mouseleave', this.pollutionsObserverLeft);
};

TextHighlighter.prototype.highlightHandler = function () {
  if (!this.isInsertMode()) {
    return;
  }

  this.doHighlight();
};

/**
 * Highlights current range.
 * @param {boolean} keepRange - Don't remove range after highlighting. Default: false.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.doHighlight = function (keepRange) {
  const range = dom(this.el).getRange();
  let wrapper;
  let createdHighlights;
  let normalizedHighlights;
  let timestamp;

  if (!range || range.collapsed) {
    return;
  }

  if (this.options.onBeforeHighlight(range) === true) {
    timestamp = +new Date();
    wrapper = this.createWrapper();
    wrapper.setAttribute(TIMESTAMP_ATTR, timestamp);

    createdHighlights = this.highlightRange(range, wrapper);
    if (range.startOffset > 1 || range.endContainer.tagName !== 'MARK') {
      normalizedHighlights = this.normalizeHighlights(createdHighlights);
    } else {
      normalizedHighlights = createdHighlights;
    }

    this.options.onAfterHighlight(range, normalizedHighlights, timestamp);
    this.options.onChangeHighlights();
  }

  if (!keepRange) {
    dom(this.el).removeAllRanges();
  }
};

/**
 * Highlights range.
 * Wraps text of given range object in wrapper element.
 * @param {Range} range
 * @param {HTMLElement} wrapper
 * @returns {Array} - array of created highlights.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.highlightRange = function (range, wrapper) {
  if (!range || range.collapsed) {
    return [];
  }

  const result = refineRangeBoundaries(range);
  const startContainer = result.startContainer;
  const endContainer = result.endContainer;
  let goDeeper = result.goDeeper;
  let done = false;
  let node = startContainer;
  const highlights = [];
  let highlight;
  let wrapperClone;
  let nodeParent;

  do {
    if (goDeeper && node.nodeType === NODE_TYPE.TEXT_NODE) {
      if (IGNORE_TAGS.indexOf(node.parentNode.tagName) === -1 &&
        node.parentNode.tagName !== 'SPAN' && node.parentNode.className !== 'g-highlight' &&
        node.nodeValue.trim() !== '') {
        wrapperClone = wrapper.cloneNode(true);
        wrapperClone.setAttribute(DATA_ATTR, true);
        nodeParent = node.parentNode;

        // highlight if a node is inside the el
        if (dom(this.el).contains(nodeParent) || nodeParent === this.el) {
          highlight = dom(node).wrap(wrapperClone);
          highlights.push(highlight);
        }
      }

      goDeeper = false;
    }

    if (node === endContainer && !(endContainer.hasChildNodes() && goDeeper)) {
      done = true;
    }

    if (node.tagName && IGNORE_TAGS.indexOf(node.tagName) > -1) {
      if (endContainer.parentNode === node) {
        done = true;
      }

      goDeeper = false;
    }

    if (goDeeper && node.hasChildNodes()) {
      node = node.firstChild;
    } else if (node.nextSibling) {
      node = node.nextSibling;
      goDeeper = true;
    } else {
      node = node.parentNode;
      goDeeper = false;
    }
  } while (!done);

  return highlights;
};

/**
 * Normalizes highlights. Ensures that highlighting is done with use of the smallest possible number of
 * wrapping HTML elements.
 * Flattens highlights structure and merges sibling highlights. Normalizes text nodes within highlights.
 * @param {Array} highlights - highlights to normalize.
 * @returns {Array} - array of normalized highlights. Order and number of returned highlights may be different than
 * input highlights.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.normalizeHighlights = function (highlights) {
  let normalizedHighlights;

  this.flattenNestedHighlights(highlights);
  this.mergeSiblingHighlights(highlights);

  // omit removed nodes
  normalizedHighlights = highlights.filter((hl) => (hl.parentElement ? hl : null));

  normalizedHighlights = unique(normalizedHighlights);
  normalizedHighlights.sort((a, b) => a.offsetTop - b.offsetTop || a.offsetLeft - b.offsetLeft);

  return normalizedHighlights;
};

/**
 * Flattens highlights structure.
 * Note: this method changes input highlights - their order and number after calling this method may change.
 * @param {Array} highlights - highlights to flatten.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.flattenNestedHighlights = function (highlights) {
  let again;
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  sortByDepth(highlights, true);

  function flattenOnce() {
    let again = false;

    highlights.forEach((hl, i) => {
      const parent = hl.parentElement;
      const parentPrev = parent.previousSibling;
      const parentNext = parent.nextSibling;

      if (self.isHighlight(parent)) {
        if (!haveSameColor(parent, hl)) {
          if (!hl.nextSibling) {
            dom(hl).insertBefore(parentNext || parent);
            again = true;
          }

          if (!hl.previousSibling) {
            dom(hl).insertAfter(parentPrev || parent);
            again = true;
          }

          if (!parent.hasChildNodes()) {
            dom(parent).remove();
          }
        } else {
          parent.replaceChild(hl.firstChild, hl);
          highlights[i] = parent;
          again = true;
        }
      }
    });

    return again;
  }

  do {
    again = flattenOnce();
  } while (again);
};

/**
 * Merges sibling highlights and normalizes descendant text nodes.
 * Note: this method changes input highlights - their order and number after calling this method may change.
 * @param highlights
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.mergeSiblingHighlights = function (highlights) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  function shouldMerge(current, node) {
    return node && node.nodeType === NODE_TYPE.ELEMENT_NODE &&
      haveSameColor(current, node) &&
      self.isHighlight(node);
  }

  highlights.forEach((highlight) => {
    const prev = highlight.previousSibling;
    const next = highlight.nextSibling;

    if (shouldMerge(highlight, prev)) {
      dom(highlight).prepend(prev.childNodes);
      dom(prev).remove();
    }

    if (shouldMerge(highlight, next)) {
      dom(highlight).append(next.childNodes);
      dom(next).remove();
    }

    dom(highlight).normalizeTextNodes();
  });
};

/**
 * Sets highlighting color.
 * @param {string} color - valid CSS color.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.setColor = function (color) {
  this.color = color;
};

/**
 * Returns highlighting color.
 * @returns {string}
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.getColor = function () {
  return this.options.color;
};

/**
 * Removes highlights from element. If element is a highlight itself, it is removed as well.
 * If no element is given, all highlights all removed.
 * @param {HTMLElement} [element] - element to remove highlights from
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.removeHighlights = function (element, isSilent = false) {
  const container = element || this.el;
  const highlights = this.getHighlights({ container });
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  function mergeSiblingTextNodes(textNode) {
    const prev = textNode.previousSibling;
    const next = textNode.nextSibling;

    if (prev && prev.nodeType === NODE_TYPE.TEXT_NODE) {
      textNode.nodeValue = prev.nodeValue + textNode.nodeValue;
      dom(prev).remove();
    }

    if (next && next.nodeType === NODE_TYPE.TEXT_NODE) {
      textNode.nodeValue += next.nodeValue;
      dom(next).remove();
    }
  }

  function removeHighlight(highlight) {
    const textNodes = dom(highlight).unwrap();

    textNodes.forEach((node) => {
      mergeSiblingTextNodes(node);
    });
  }

  sortByDepth(highlights, true);

  highlights.forEach((hl) => {
    if (self.options.onRemoveHighlight(hl) === true) {
      removeHighlight(hl);
      if (!isSilent) {
        self.options.onChangeHighlights();
      }
    }
  });
};

/**
 * Returns highlights from given container.
 * @param params
 * @param {HTMLElement} [params.container] - return highlights from this element. Default: the element the
 * highlighter is applied to.
 * @param {boolean} [params.andSelf] - if set to true and container is a highlight itself, add container to
 * returned results. Default: true.
 * @param {boolean} [params.grouped] - if set to true, highlights are grouped in logical groups of highlights added
 * in the same moment. Each group is an object which has got array of highlights, 'toString' method and 'timestamp'
 * property. Default: false.
 * @returns {Array} - array of highlights.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.getHighlights = function (params) {
  params = defaults(params, {
    container: this.el,
    andSelf: true,
    grouped: false,
  });

  const nodeList = params.container.querySelectorAll(`[${DATA_ATTR}]`);
  let highlights = Array.prototype.slice.call(nodeList);

  if (params.andSelf === true && params.container.hasAttribute(DATA_ATTR)) {
    highlights.push(params.container);
  }

  if (params.grouped) {
    highlights = groupHighlights(highlights);
  }

  return highlights;
};

/**
 * Returns true if element is a highlight.
 * All highlights have 'data-highlighted' attribute.
 * @param el - element to check.
 * @returns {boolean}
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.isHighlight = function (el) {
  return el && el.nodeType === NODE_TYPE.ELEMENT_NODE && el.hasAttribute(DATA_ATTR);
};

/**
 * Serializes all highlights in the element the highlighter is applied to.
 * @returns {string} - stringified JSON with highlights definition
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.serializeHighlights = function () {
  const highlights = this.getHighlights();
  const refEl = this.el;
  const hlDescriptors = [];

  function getElementPath(el, refElement) {
    const path = [];
    let childNodes;

    do {
      childNodes = Array.prototype.slice.call(el.parentNode.childNodes);
      path.unshift(childNodes.indexOf(el));
      el = el.parentNode;
    } while (el !== refElement || !el);

    return path;
  }

  sortByDepth(highlights, false);

  highlights.forEach((highlight) => {
    let offset = 0; // Hl offset from previous sibling within parent node.
    const length = highlight.textContent.length;
    const hlPath = getElementPath(highlight, refEl);

    if (highlight.previousSibling && highlight.previousSibling.nodeType === NODE_TYPE.TEXT_NODE) {
      offset = highlight.previousSibling.length;
    }

    const timestamp = highlight.dataset ? highlight.dataset.timestamp : highlight.getAttribute('data-timestamp');

    hlDescriptors.push([
      highlight.className,
      timestamp,
      hlPath.join(':'),
      offset,
      length,
    ]);
  });

  return hlDescriptors;
};

/**
 * Deserializes highlights.
 * @throws exception when can't parse JSON or JSON has invalid structure.
 * @param {object} arrayOfSelections - JSON object with highlights definition.
 * @returns {Array} - array of deserialized highlights.
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.deserializeHighlights = function (hlDescriptors) {
  const highlights = [];
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  if (!hlDescriptors) {
    return highlights;
  }

  function deserializationFn(hlDescriptor) {
    const hl = {
      wrapper: `<mark class="${hlDescriptor[0]}" data-timestamp="${hlDescriptor[1]}" data-highlighted="true"></mark>`,
      path: hlDescriptor[2].split(':'),
      offset: hlDescriptor[3],
      length: hlDescriptor[4],
    };

    let elIndex = hl.path.pop();
    let node = self.el;
    let hlNode;
    let highlight;
    let idx;

    while (!!(idx = hl.path.shift())) {
      node = node.childNodes[idx];
    }

    if (node.childNodes[elIndex - 1] && node.childNodes[elIndex - 1].nodeType === NODE_TYPE.TEXT_NODE) {
      elIndex -= 1;
    }

    node = node.childNodes[elIndex];
    hlNode = node.splitText(hl.offset);
    hlNode.splitText(hl.length);

    if (hlNode.nextSibling && !hlNode.nextSibling.nodeValue) {
      dom(hlNode.nextSibling).remove();
    }

    if (hlNode.previousSibling && !hlNode.previousSibling.nodeValue) {
      dom(hlNode.previousSibling).remove();
    }

    highlight = dom(hlNode).wrap(dom().fromHTML(hl.wrapper)[0]);
    highlights.push(highlight);
  }

  hlDescriptors.forEach((hlDescriptor) => {
    try {
      deserializationFn(hlDescriptor);
    } catch (e) {
      if (console && console.warn) {
        console.warn(`Can't deserialize highlight descriptor. Cause: ${e}`);
      }
    }
  });

  return highlights;
};

/**
 * Finds and highlights given text.
 * @param {string} text - text to search for
 * @param {boolean} [caseSensitive] - if set to true, performs case sensitive search (default: true)
 * @memberof TextHighlighter
 */
TextHighlighter.prototype.find = function (text, caseSensitive) {
  const wnd = dom(this.el).getWindow();
  const scrollX = wnd.scrollX;
  const scrollY = wnd.scrollY;
  const caseSens = (typeof caseSensitive === 'undefined' ? true : caseSensitive);

  dom(this.el).removeAllRanges();

  if (wnd.find) {
    while (wnd.find(text, caseSens)) {
      this.doHighlight(true);
    }
  } else if (wnd.document.body.createTextRange) {
    const textRange = wnd.document.body.createTextRange();

    textRange.moveToElementText(this.el);
    while (textRange.findText(text, 1, caseSens ? 4 : 0)) {
      if (!dom(this.el).contains(textRange.parentElement()) && textRange.parentElement() !== this.el) {
        break;
      }

      textRange.select();
      this.doHighlight(true);
      textRange.collapse(false);
    }
  }

  dom(this.el).removeAllRanges();
  wnd.scrollTo(scrollX, scrollY);
};

/**
 * Creates wrapper for highlights.
 * TextHighlighter instance calls this method each time it needs to create highlights and pass options retrieved
 * in constructor.
 * @param {object} options - the same object as in TextHighlighter constructor.
 * @returns {HTMLElement}
 * @memberof TextHighlighter
 * @static
 */
TextHighlighter.prototype.createWrapper = function () {
  const mark = document.createElement('mark');

  mark.className = `color-${this.color}`;

  return mark;
};

export default TextHighlighter;
