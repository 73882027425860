import { RESTPath } from '@router/path.rest';
import http from '@http';

type Page = number;
type Range = string[];
type Scope = string;

export interface RequestParams {
  page: Page;
  range: Range;
  scope: Scope;
}

export function httpGetHistory(params: RequestParams) {
  const request = {
    url: RESTPath.search.getHistory(params),
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpGetHistory;
