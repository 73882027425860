import {
  styled,
  css,
} from '@components/styled';

interface Props {
  hightlight: boolean;
  shouldAlwaysRender: boolean;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  padding: 0 64px;

  &:hover {
    >:nth-child(2) {
      display: block;
    }
  }
`;

style.Content = styled.div`
  transition: all .2s;

  ${(props: Props) => props.hightlight && css`
    background-color: rgba(65, 131, 215, 0.07);
  `}
`;

style.Bookmark = styled.div`
  position: absolute;
  top: 0;
  left: 25px;
  display: none;
  color: black;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    transition: all .2s;
    content: '';
    opacity: 0;
    position: absolute;
    width: 7px;
    height: 32px;
    top: 0px;
    right: -7px;
    background-color: rgba(65, 131, 215, 0.07);
  }

  ${(props: Props) => props.shouldAlwaysRender && css`
    display: block;
  `}
`;

export default style;
