import { fetchAttributesReduce } from '@pages/searchResult/documents/preview/redux/fetchAttributes.reduce';

import type { ActionData } from '@pages/searchResult/documents/preview/redux/fetchAttributes.reduce';

const actionType = 'AUTHOR_PACKAGE/FETCH_DOCUMENT_ATTRIBUTES__PREVIEW';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchAttributes = {
  actionType,
  createAction,
  reduce: fetchAttributesReduce,
};

export default fetchAttributes;
