import { connect } from 'react-redux';

import { ProjectTab } from './projectTab';

import { getPreview } from './preview.connect';

const mapStateToProps = (state, ownProps) => {
  const preview = getPreview(ownProps.id, state.regulation.projects.projects);

  return {
    body: preview.project.body,
    loading: preview.project.loading,
  };
};

export const ProjectTabConnect = connect(
  mapStateToProps,
)(ProjectTab);

export default ProjectTabConnect;
