import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { theme } from '@components/styled';

import {
  Layout,
  LayoutTypes,
} from './redux/state';

import s from './layoutSwitcher.style';

interface LayoutSwitcherProps {
  layout: LayoutTypes;
  disabled: boolean;
  onToggle(): void;
}

export class LayoutSwitcher extends React.PureComponent<LayoutSwitcherProps> {

  handleIconClick = () => {
    if (this.props.disabled) {
      return;
    }

    this.props.onToggle();
  };

  render() {
    const isActive = this.props.layout === Layout.filters;
    const color = this.props.disabled ? theme.colors.disabled : theme.colors.stone;

    return (
      <s.Icon
        isActive={isActive}
        onClick={this.handleIconClick}
      >
        <Icon
          path={iconsPath.get('subscriptionFilter')}
          color={color}
        />
      </s.Icon>
    );
  }

}

export default LayoutSwitcher;
