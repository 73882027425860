import { toggleSelectionReduce } from '@pages/searchResult/documents/redux/toggleSelectionRule.reduce';

const actionType = 'AUTHOR_PACKAGES/DOCUMENTS/ITEM/TOGGLE_SELECTION_RULE';

export interface Action {
  type: typeof actionType;
}

const createAction = (): Action => ({
  type: actionType,
});

export const toggleSelectionRule = {
  actionType,
  createAction,
  reduce: toggleSelectionReduce,
};

export default toggleSelectionRule;
