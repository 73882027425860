import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { addDocumentToPackage } from '@pages/packages/redux/searchPackages/addDocumentToPackage';
import { removeDocumentFromPackage } from '@pages/packages/redux/searchPackages/removeDocumentFromPackage';

import { httpAddDocumentToPackage } from '@pages/packages/redux/packages/addDocumentToPackage.http';

import { httpRemoveDocument } from '@pages/packages/redux/packages/removeDocument.http';

import { Source } from '@router/path.rest';
import { increasePackageCount } from './increasePackageCount';
import { decreasePackageCount } from './decreasePackageCount';

export class AddDocumentToPackageThunk {

  getState;

  private notificationId = 'ADD_DOCUMENT_TO_PACKAGE_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { packageId: number }) {
    const state = this.getState().packages.searchResult;
    const documentId = this.getState().document.id;

    const { selected } = state.itemsMap.get(params.packageId);

    const config = {
      ...params,
      documentId,
    };

    if (!selected) {
      this.dispatchIncreasePackageCount();
      await this.addDocumentToPackage(config);

      return;
    }

    this.dispatchDecreasePackageCount();
    await this.removeDocumentFromPackage(config);
  }

  private async addDocumentToPackage(params) {
    try {
      this.dispatchAddSucceed(params);

      const {
        sourceId,
        source,
      } = this.getState().document;

      const requestParams = {
        ...params,
        sourceId,
        source,
      };

      await httpAddDocumentToPackage(requestParams);
    } catch (error) {
      if (this.getState().document.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async removeDocumentFromPackage(params) {
    try {
      this.dispatchRemoveSucceed(params);
      await httpRemoveDocument(params);
      // eslint-disable-next-line no-empty
    } catch (error) {
      if (this.getState().document.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchAddSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(addDocumentToPackage.createAction(actionData));
  }

  private dispatchIncreasePackageCount() {
    this.dispatch(increasePackageCount.createAction());
  }

  private dispatchDecreasePackageCount() {
    this.dispatch(decreasePackageCount.createAction());
  }

  private dispatchRemoveSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(removeDocumentFromPackage.createAction(actionData));
  }

}

export function addDocumentToPackageThunk(dispatch, params) {
  const thunk = new AddDocumentToPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddDocumentToPackageThunk;
