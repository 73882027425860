import { subscriptionDeleteReduce } from './subscriptionDelete.reduce';

const actionType = 'SEARCH/RESULT/SUBSCRIPTION_DELETE';

const createAction = () => ({
  type: actionType,
});

export const subscriptionDelete = {
  actionType,
  createAction,
  reduce: subscriptionDeleteReduce,
};

export default subscriptionDelete;
