import { Dispatch } from 'redux';

import { fetchFilters } from './fetchFilters';
import { httpFetchFolderFilters } from './fetchFolderFilters.http';
import { httpFetchProjectsFilters } from './fetchProjectsFilters.http';

export class FetchFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(id?) {

    if (!id) {
      await this.fetchProjectsFilters();

      return;
    }

    await this.fetchFolderFilters();
  }

  private async fetchProjectsFilters() {
    try {
      const filters = await httpFetchProjectsFilters();
      await this.dispatchFetchSucceed(filters);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchFolderFilters() {
    try {
      const filters = await httpFetchFolderFilters();
      await this.dispatchFetchSucceed(filters);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async dispatchFetchSucceed(result) {
    await this.dispatch(fetchFilters.createAction(result));
  }

}

export async function fetchFiltersThunk(dispatch, id?) {
  const thunk = new FetchFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(id);
}

export default FetchFiltersThunk;
