import React from 'react';

import { Button } from '@components/button';

import s from './saveButton.style';

/**
 * @param {string} title - optional.
 *
 * @param {function} onClick - mouse event arg & return void
 */

interface SaveButtonProps {
  title?: string;
  onClick: (e) => void;
}

export const SaveButton = (props: SaveButtonProps) => (
  <s.SaveButton>
    <Button
      linkLike
      disabled={false}
      fullWidth={false}
      onClick={props.onClick}
    >
      {props.title}
    </Button>
  </s.SaveButton>
);

SaveButton.defaultProps = {
  title: 'Применить',
};

export default SaveButton;
