import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { increaseCommentsCount } from '../../workspace/redux/increaseCommentsCount';

import { addMessageFromClient } from './addMessageFromClient';

import { httpSendMessage } from './sendMessage.http';

import type {
  User,
  MessageDTO,
} from './state';

interface Params {
  body: string;
  projectId: number;
}

interface Message {
  body: string;
  projectId: number,
  clientId: string;
}

export class SendMessageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  public invoke(params: Params) {
    this.dispatchIncreaseCommentsCount(params.projectId);

    const { projects, preview } = this.getState().regulation;
    const project = projects.projects.itemsMap.get(params.projectId) || preview[params.projectId].attributes;
    const projectName = project.title;

    const requestParams = { ...params };
    const clientId = uuid();
    requestParams.body = requestParams.body.trim();
    this.addMessage({ ...requestParams, clientId });
    this.sendMessage({ ...requestParams, clientId });

    sendAmplitudeData('regulation:add_comment', { projectName });
    Userpilot.track('regulation:add_comment', { projectName });
  }

  private addMessage(params: Message) {
    const message = this.buildMessage(params);
    this.dispatchAddMessageFromClient(message);
  }

  private buildMessage(params: Message): MessageDTO {
    const user = this.buildUser();
    const time = Date.now() / 1000;
    const body = String(params.body).trim();

    return {
      id: null,
      projectId: params.projectId,
      clientId: params.clientId,
      time,
      body,
      user,
    };
  }

  private buildUser(): User {
    const state = this.getState();

    const {
      firstName,
      lastName,
      avatar,
    } = state.currentUser;

    return {
      firstName,
      lastName,
      avatar,
    };
  }

  private async sendMessage(requestParams) {
    try {
      await httpSendMessage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchAddMessageFromClient(message: MessageDTO) {
    this.dispatch(addMessageFromClient.createAction(message));
  }

  private dispatchIncreaseCommentsCount(id: number) {
    const { projects } = this.getState().regulation.projects;
    const hasProject = projects.itemsMap.get(id);
    if (!hasProject) {
      return;
    }

    this.dispatch(increaseCommentsCount.createAction({ id }));
  }

}

export function sendMessageThunk(dispatch, params) {
  const thunk = new SendMessageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SendMessageThunk;
