import { connect } from 'react-redux';

import {
  Pagination,
  preparePages,
} from '@components/pagination';

import { fetchSearchHistoryThunk } from '../../redux/fetchHistory.thunk';

const mapStateToProps = (state) => {
  const {
    totalPages,
    currentPageNumber,
  } = state.search.history;

  const isFirstPageSelected = currentPageNumber === 1;
  const isLastPageSelected = currentPageNumber === totalPages;

  return {
    pages: preparePages(totalPages, currentPageNumber),
    currentPageNumber,
    isFirstPageSelected,
    isLastPageSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangePage(pageNumber) {
    fetchSearchHistoryThunk(dispatch, pageNumber);
  },
});

export const PaginationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Pagination);

export default PaginationConnect;
