import { fetchYearReduce } from './fetchYear.reduce';

import type { ActionData } from './fetchYear.reduce';

const actionType = 'SEARCH/RESULT/FETCH_DATE_YEAR';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchYear = {
  actionType,
  createAction,
  reduce: fetchYearReduce,
};

export default fetchYear;
