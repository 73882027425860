import React from 'react';

import { Checkbox } from '@components/checkbox/checkbox';

export enum CheckStatus {
  check = 'check',
  half = 'half',
  uncheck = 'uncheck',
}

export type Status = CheckStatus.check | CheckStatus.half | CheckStatus.uncheck;

interface Props {
  status: Status;
  onClick: () => void;
}

export const TotalCheckbox: React.FC<Props> = (props: Props) => {
  if (props.status === CheckStatus.check) {
    return (
      <Checkbox
        checked
        onClick={props.onClick}
      />
    );
  }

  if (props.status === CheckStatus.half) {
    return (
      <Checkbox
        checked
        isHalf
        onClick={props.onClick}
      />
    );
  }

  return <Checkbox onClick={props.onClick} />;

};

export default TotalCheckbox;
