import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
`;

export default style;
