import {
  removeFilterReduce,
  ActionData,
} from './removeFilter.reduce';

const actionType = 'REGULATION/FILTERS/REMOVE_FOLDER';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const removeFilter = {
  actionType,
  createAction,
  reduce: removeFilterReduce,
};

export default removeFilter;
