import { Dispatch } from 'redux';

import { fetchFoldersForProject } from './fetchFoldersForProject';

import { httpFetchFoldersForProject } from './fetchFoldersForProject.http';

export class FetchFoldersForProjectThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { projectId: string }) {

    try {
      const result = await httpFetchFoldersForProject(params.projectId);
      this.dispatchFetchSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      folders: result.result,
    };

    this.dispatch(fetchFoldersForProject.createAction(actionData));
  }

}

export function fetchFoldersForProjectThunk(dispatch, params) {
  const thunk = new FetchFoldersForProjectThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchFoldersForProjectThunk;
