import styled, { css } from 'styled-components';
import t from '../../components/tooltip/tooltip.style';

interface Props {
  theme: any;
  isTitle: boolean;
  isInner: boolean;
  isEmpty: boolean;
  textAlign: string;
  isTransparent: boolean;
  isEllipsis: boolean;
  isNoWrap: boolean;
  isSortActive: boolean;
  isHeaderTitle: boolean;
  isActive: boolean;
}

const style: any = {};

function getWidth(amountOfColumn: number, isLarger: boolean) {
  const MAX_SIZE = 96;
  const MIN_SIZE = 80;
  const size = isLarger ? MAX_SIZE : MIN_SIZE;
  const result = size * amountOfColumn;

  return result || size * 2;
}

function renderHoverUnderline() {
  const underlineStyles = `
    :hover {
      ${style.BodyText} {
        text-decoration: underline;
      }
    }
  `;

  return underlineStyles;
}

style.Table = styled.div`
  display: table;
  width: 100%;
`;

style.Group = styled.div`
  display: flex;
`;

style.ColumnWidthTitle = styled.div`
  width: ${() => getWidth(5, false)}px;

  @media (min-width: 1408px) {
    width: ${() => getWidth(5, true)}px;
  }

`;

style.ColumnWidthItem = styled.div`
  width: 100%;
`;

style.ColumnTitle = styled(style.ColumnWidthTitle)`
  display: table-column;
`;

style.ColumnItem = styled(style.ColumnWidthItem)`
  display: table-column;
`;

style.ColumnSort = styled.span`
  position: absolute;

  ${(props: Props) => !props.isActive && css`
    display: none;
  `}
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const cellStyle = () => css`
  display: table-cell;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  vertical-align: top;
  position: relative;
  flex: 1;

  :hover {
    ${t.TooltipWrapper} {
      display: flex;
    }
  }
`;

style.CellTitle = styled(style.ColumnWidthTitle)`
  ${cellStyle()}
  vertical-align: baseline;
  ${(props: Props) => props.isNoWrap && css`
    white-space: nowrap;
  `}
`;

style.Title = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 12px;
`;

style.HeaderText = styled.div`
  font-size: 11px;
  padding: 0 15px 0 0;
  text-align: ${(props: Props) => props.textAlign || 'start'};
  font-family: Roboto-Bold;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props: Props) => (props.isTransparent ? props.theme.colors.blue : props.theme.colors.defaultText)};

  ${(props: Props) => props.isEllipsis && css`
    text-overflow: ellipsis;
  `}

  ${(props: Props) => props.isHeaderTitle && css`
    padding: 0 15px;
  `}
`;

style.HeaderTextTitle = styled.span`
  padding-left: 30px;
  user-select: none;
`;

style.Body = styled.div`
  display: table-row-group;
  position: relative;
`;

style.BodyText = styled.div`
  font-size: 13px;
  line-height: 16px;
  padding: 12px 15px;
  font-family: Roboto-Regular;
  text-align: ${(props: Props) => props.textAlign || 'start'};
  color: ${(props: Props) => (props.isTransparent ? props.theme.colors.transparent : props.theme.colors.blue)};
  overflow: hidden;

  ${(props: Props) => props.isEllipsis && css`
    text-overflow: ellipsis;
  `}

  ${(props: Props) => props.isTitle && css`
    padding-left: 0;
    color: ${props.theme.colors.defaultText};
  `}

  ${(props: Props) => props.isInner && props.isTitle && css`
    padding-left: 44px;
  `}

  ${(props: Props) => props.isEmpty && css`
    color: ${props.theme.colors.silver};
  `}
`;

style.CellItem = styled.div`
  ${cellStyle()}
  min-width: ${() => getWidth(1, false)}px;
  max-width: 100%;

  @media (min-width: 1408px) {
    min-width: ${() => getWidth(1, true)}px;
  }

  ${style.BodyText} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  cursor: default;

  ${(props: Props) => !props.isEmpty && renderHoverUnderline()}

  ${(props: Props) => !props.isEmpty && css`
    cursor: pointer;
  `}

  &:hover {
    ${style.ColumnSort} {
      display: inline;
    }
  }
`;

style.ShowMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  padding: 12px 15px;
  font-family: Roboto-Regular;
  color: ${(props) => props.theme.colors.blue};
  background-color: #f9f9f9;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  :hover {
    background-color: rgba(65, 131, 215, .07);
  }
`;

style.Row = styled.div`
  cursor: pointer;
  display: flex;
  line-height: 38px;
  background-color: ${(props) => props.theme.colors.white}
`;

style.InnerRow = styled(style.Row)`
  background-color: #f9f9f9;
  cursor: default;

  :hover {
    background-color: rgba(65, 131, 215, .07);
  }
`;

style.ArrowItem = styled.div`
  display: flex;
  margin-top: 7px;
  padding-right: 8px;
`;

export default style;
