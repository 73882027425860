import { ReducerBuilder } from '@redux/reducer.builder';

import { createEmptyProject } from './createEmptyProject';
import { fetchAttributes } from './fetchAttributes';
import { fetchNote } from './fetchNote';
import { fetchPhases } from './fetchPhases';
import { fetchProject } from './fetchProject';

export const reducerBuilder = new ReducerBuilder([
  createEmptyProject,
  fetchAttributes,
  fetchPhases,
  fetchProject,
  fetchNote,
]);

export default reducerBuilder;
