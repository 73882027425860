import styled, { css } from 'styled-components';

const style = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 10px 16px 11px 16px;
  border-bottom: 1px solid #eef3f4;
  color: #4183d7;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`;

style.Actual = styled.div`
  display: block;
  font-family: Roboto-Medium;
  border-radius: 2px;
  border: 1px solid #ff6400;
  color: #ff6400;
  font-size: 11px;
  font-weight: 500;
  height: 16px;
  line-height: 16px;
  text-align: center;
  width: 78px;

  ${(props) => props.isActual && css`
    display: none;
  `}
`;

style.AnchorContainer = styled.div`
  display: flex;
  gap: 0;

  ${(props) => props.showNumber && css`
    gap: 8px;
  `}
`;

style.AnchorNumber = styled.a`
  color: #9098a0;
  line-height: 1.23;
  text-decoration: none;
`;

style.AnchorTitle = styled.a`
  color: #4183d7;
  line-height: 1.23;
  text-decoration: none;
`;

export default style;
