import React, {
  useState, useEffect, useRef,
} from 'react';

import s from './notificationsListItem.style';

type Id = number;

interface NotificationProps {
  id: Id;
  isVisited: boolean;
  body: string;
  date: string;
  onItemClick: () => void;
}

export const NotificationsListItem = (props: NotificationProps) => {
  const innerRef = useRef(null);

  const [hovered, setHovered] = useState(false);

  const handleClick = (e) => {
    const isCorrectCurrent = e.target.parentNode.nodeName === 'A';
    const isCorrectParent = e.target.nodeName === 'A';
    const isHref = isCorrectCurrent || isCorrectParent;
    if (!isHref) {
      return;
    }

    props.onItemClick();
  };

  useEffect(() => {
    const div = innerRef.current;
    div.addEventListener('click', handleClick);

    return () => {
      div.removeEventListener('click', handleClick);
    };
  }, []);

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const prepareMarkup = (body) => ({
    __html: `<div>${body}</div>`,
  });

  const renderIsNew = () => {
    if (props.isVisited) {
      return null;
    }

    return <s.Circle />;
  };

  return (
    <s.Root
      hovered={hovered}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <s.Item>
        {renderIsNew()}
        <s.Text
          ref={innerRef}
          dangerouslySetInnerHTML={prepareMarkup(props.body)}
        />
        <s.Date>{props.date}</s.Date>
      </s.Item>
    </s.Root>
  );
};

export default NotificationsListItem;
