import { Filters } from '../../../filters';

export const updateModeReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const block = state.query.blocksMap.get(action.data.id);

  if (block.getData().mode === action.data.data.mode) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);
  const newData = {
    id: action.data.id,
    data: {
      mode: action.data.data.mode,
    },
  };

  const blocksMap = filters.updateData(newData);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap,
    },
  };
};

export default updateModeReduce;
