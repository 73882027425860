import { fetchLawsReduce } from './fetchLaws.reduce';

import type { ActionData } from './fetchLaws.reduce';

const actionType = 'SEARCH/RESULT/FETCH_LAWS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchLaws = {
  actionType,
  createAction,
  reduce: fetchLawsReduce,
};

export default fetchLaws;
