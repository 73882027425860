import { connect } from 'react-redux';

import { TableOfContents } from './tableOfContents';

const mapStateToProps = (state) => ({
  loading: state.lawDocument.document.loading,
});

export const TableOfContentsConnect = connect(
  mapStateToProps,
)(TableOfContents);

export default TableOfContentsConnect;
