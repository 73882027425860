import { connect } from 'react-redux';

import { FolderType } from '@pages/regulation/sidebar/folders/redux/state';

import { Share } from './share';

const mapStateToProps = (state) => {
  const { folders } = state.regulation;

  const restrictedFolders = !folders.activeItemId ||
    folders.activeItemId === FolderType.underControl ||
    folders.activeItemId === FolderType.calendar ||
    folders.activeItemId === FolderType.comments;

  let shouldRender = !restrictedFolders;

  const item = folders.folders.itemsMap.get(folders.activeItemId);
  if (item && item.role === 'visitor') {
    shouldRender = false;
  }

  return {
    id: folders.activeItemId,
    shouldRender,
  };
};

export const ShareConnect = connect(
  mapStateToProps,
)(Share);

export default ShareConnect;
