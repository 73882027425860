import { connect } from 'react-redux';

import { MAXIMUM_SELECTED_LENGTH } from '../../redux/state';

import { checkProject } from '../../redux/checkProject';
import { uncheckProject } from '../../redux/uncheckProject';

import { Item } from './item';

const mapStateToProps = (state, ownProps) => {
  const {
    projects,
    selectProjects,
  } = state.regulation.projects;

  const item = projects.itemsMap.get(ownProps.id);
  const isSelect = !!selectProjects.find((projectId) => projectId === ownProps.id);

  const isCheckDisable = !isSelect && selectProjects.length >= MAXIMUM_SELECTED_LENGTH;

  return {
    item,
    isActive: ownProps.activeId === item.id,
    isCheckDisable,
    isSelect,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onItemCheck() {
    dispatch(checkProject.createAction({ id: ownProps.id }));
  },
  onItemUncheck() {
    dispatch(uncheckProject.createAction({ id: ownProps.id }));
  },
});

export const ItemConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
