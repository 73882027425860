import {
  Id,
  RESTPath,
} from '@router/path.rest';
import http from '@http';

interface RequestParams {
  projectId: Id;
  familiarize: boolean;
}

export function httpToggleFamiliarize(requestParams: RequestParams) {
  const url = RESTPath.regulation.toggleFamiliarize(requestParams.projectId);
  const request = http.put({
    url,
    data: {
      familiarize: requestParams.familiarize,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpToggleFamiliarize;
