import React from 'react';
import { Loader } from '@components/loader';

import { ScrollTo } from '@components/scrollTo/scrollTo';

import s from './relations.style';

const useScrollTo = (id: string) => {
  let offset = 100;

  const annotationBlock = document.getElementById('annotation');
  if (annotationBlock) {
    offset = -annotationBlock.offsetHeight;
  }

  const summaryBlock = document.getElementById('summary');
  if (summaryBlock) {
    offset = -summaryBlock.offsetHeight;
  }

  if (offset > 100) {
    offset += 100;
  }

  const scrollTo = new ScrollTo({ targetId: 'document-view-container', offset });
  scrollTo.setTo(id);
  scrollTo.scrollTo();
  scrollTo.highlight();
};

interface RelationItemI {
  id: string;
  title: string;
  body: string;
  blockIds: string[];
  isChildren: boolean;
}

interface RelationItemProps {
  item: RelationItemI;
  activeId: string;
  onClick(id: string): void;
}

const RelationItem = (props: RelationItemProps) => {
  let index = 0;

  const handleClick = (e) => {
    e.stopPropagation();
    let blockId = props.item.blockIds[index];

    if (!blockId) {
      index = 0;
    }

    blockId = props.item.blockIds[index];

    // eslint-disable-next-line
    // react-hooks/rules-of-hooks
    useScrollTo(blockId);

    index += 1;
    props.onClick(props.item.id);
  };

  return (
    <s.Item
      isChildren={props.item.isChildren}
      onClick={handleClick}
      isActive={props.activeId === props.item.id}
    >
      <s.ItemTitle>{props.item.title}</s.ItemTitle>
      <s.ItemBody>{props.item.body}</s.ItemBody>
    </s.Item>
  );
};

interface RelationsProps {
  data: RelationItemI[];
  loading: boolean;
}

export class Relations extends React.PureComponent<RelationsProps> {

  state = {
    activeId: null,
  };

  handleClick = (id) => {
    this.setState({
      activeId: id,
    });
  };

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return (
      <s.Root>
        {this.props.data.map(
          (item) => (
            <RelationItem
              activeId={this.state.activeId}
              onClick={this.handleClick}
              item={item}
            />
          ),
        )}
      </s.Root>
    );
  }

}

export default Relations;
