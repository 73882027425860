import { toggleLayoutTypeReduce } from './toggleLayoutType.reduce';

const actionType = 'PACKAGE/TOGGLE_LAYOUT_TYPE';

const createAction = () => ({
  type: actionType,
});

export const toggleLayoutType = {
  actionType,
  createAction,
  reduce: toggleLayoutTypeReduce,
};

export default toggleLayoutType;
