import { saveFiltersReduce } from './saveFilters.reduce';

import type { ActionData } from './saveFilters.reduce';

const actionType = 'REGULATION/FILTERS/SAVE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const saveFilters = {
  actionType,
  createAction,
  reduce: saveFiltersReduce,
};

export default saveFilters;
