import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { buildItems } from '../addPackage/packageList.connect';

import { ItemsList } from './itemsList';

const mapStateToProps = (state, ownProps) => {
  const searchQuery = ownProps.searchQuery.trim();
  const { items } = ownProps;

  return {
    searchQuery,
    items: buildItems(items, searchQuery),
    shouldRenderEmptyBlock: isEmpty(items.itemsList),
    addingItem: items.addingItem,
    loading: items.loading,
  };
};

export const ItemsListConnect = connect(
  mapStateToProps,
)(ItemsList);

export default ItemsListConnect;
