export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    query: {
      blocksList: [],
      blocksMap: new Map(),
      smartFilter: {
        query: null,
        preparing: false,
        hasChange: false,
        prepareFail: false,
      },
    },
    defaultQuery: {
      blocksList: [],
      blocksMap: new Map(),
      smartFilter: {
        query: null,
        preparing: false,
        hasChange: false,
      },
      loading: true,
    },
  };
};

export default resetToInitialReduce;
