import {
  RegionBlock, IRegionBlockData,
} from '../../form/filterBlocks/region/redux/region';

export abstract class RegionBlockHistory extends RegionBlock {

  static transform(data: IRegionBlockData) {
    return data.title || '';
  }

}

export default RegionBlockHistory;
