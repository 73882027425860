import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { DownloadPopover } from '@pages/document/components/download/downloadPopover';

import { buildDownloadItems } from '../utils';

const mapStateToProps = (state, ownProps) => {

  const params = {
    source: Source.search,
    sourceId: state.searchResult.id,
    documentId: ownProps.id,
    disabled: false,
  };

  const items = buildDownloadItems(params);

  return {
    items,
  };
};

export const DownloadConnect = connect(
  mapStateToProps,
)(DownloadPopover);

export default DownloadConnect;
