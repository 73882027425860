import styled from 'styled-components';

const style: any = {};

style.InputRemoveButton = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  padding: 12px;

  background: transparent;
  cursor: pointer;

  transition: opacity .3s;

  &:focus {
    outline: none;
  }
`;

style.IconWrapper = styled.div`
  width: 12px;
  height: 12px;
`;

export default style;
