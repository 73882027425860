import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import field from '@components/field/field.style';

const style: any = {};

style.Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 35px 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${field.Input} {
    border-bottom: 0;
  }

  ${field.Root} {
    border: 1px solid ${(p) => p.theme.colors.borderColor};
    border-top: 0;
    background: ${(p) => p.theme.colors.polar};

    &:first-child {
      border-top: 1px solid ${(p) => p.theme.colors.borderColor};
    }
  }
`;

style.FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

style.Title = styled.h1`
  margin: 0;
  font-size: 27px;
`;

style.Description = styled.div`
  margin-top: 13px;
  display: inline-flex;
`;

style.DescriptionTitle = styled.span`
  color: ${(p) => p.theme.colors.stone};
`;

style.InternalLink = styled(NavLink)`
  color: ${(p) => p.theme.colors.blue};
  text-decoration: none;
`;

style.ExternalLink = styled.a`
  color: ${(p) => p.theme.colors.blue};
  text-decoration: none;
`;

style.Note = styled.div`
  margin-top: 28px;
  color: ${(p) => p.theme.colors.stone};

  a {
    color: ${(p) => p.theme.colors.blue};
    text-decoration: none;
  }
`;

export default style;
