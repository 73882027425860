import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

style.Icon = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

style.Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.stone};
`;

style.Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto-Medium;
  font-size: 17px;
  font-weight: bold;
  font-weight: 500;
  margin-bottom: 15px;
  color: #22313f;
`;

style.Paragraph = styled.p`
  padding: 0;
  margin: 0;
`;

export default style;
