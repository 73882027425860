import { Dispatch } from 'redux';

import { increaseCommentsCount } from '../../workspace/redux/increaseCommentsCount';

import { addMessage } from './addMessage';
import { updateMessage } from './updateMessage';

import type { MessageDTO } from './state';

export class AddMessageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  public invoke(message: MessageDTO) {
    this.addMessageToChatPreview(message);
    this.dispatchIncreaseCommentsCount(message.projectId);
  }

  private addMessageToChatPreview(message: MessageDTO) {
    const state = this.getState();

    const sameProject = message.projectId === state.regulation.projects.previewId;
    if (!sameProject) {
      return;
    }

    const isExist = state.regulation.chat.messages.itemsList.find((item) => item === message.id);
    if (isExist) {
      return;
    }

    const currentItem = state.regulation.chat.messages.itemsList.find((item) => item === message.clientId);
    if (!currentItem) {
      this.dispatchAddMessage(message);

      return;
    }

    this.dispatchUpdateMessage(message);
  }

  private dispatchIncreaseCommentsCount(id: number) {
    this.dispatch(increaseCommentsCount.createAction({ id }));
  }

  private dispatchAddMessage(message: MessageDTO) {
    this.dispatch(addMessage.createAction(message));
  }

  private dispatchUpdateMessage(message: MessageDTO) {
    this.dispatch(updateMessage.createAction(message));
  }

}

export function addMessageThunk(dispatch, params) {
  const thunk = new AddMessageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddMessageThunk;
