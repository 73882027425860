import { ReducerBuilder } from '../../redux/reducer.builder';

import { resetToInitial } from './resetToInitial';
import { processStart } from './processStart';
import { processComplete } from './processComplete';

import { StateBuilder } from './state';

export const reducerBuilder = new ReducerBuilder([
  processStart,
  processComplete,
  resetToInitial,
]);

const initialState = StateBuilder.createState();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
