import { StateBuilder } from './state';

import type { Id } from './state';

export type ActionData = {
  id: Id;
};

interface Action {
  type: string;
  data: ActionData;
}

export const setNotificationVisitedReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.setVisited(action.data.id);

  return newState.getState();
};

export default setNotificationVisitedReduce;
