import { connect } from 'react-redux';

import { saveFirstnameThunk } from '../../redux/saveFirstname.thunk';
import { saveLastnameThunk } from '../../redux/saveLastname.thunk';
import { savePositionThunk } from '../../redux/savePosition.thunk';

import { UserCassette } from './userCassette';

const mapStateToProps = (state) => ({
  lastName: state.currentUser.lastName,
  firstName: state.currentUser.firstName,
  patronymic: state.currentUser.patronymic,
  phone: state.currentUser.phone,
  email: state.currentUser.email,
  notificationEmail: {
    email: null,
    confirmed: false,
  },
  company: state.currentUser.company,
  position: state.currentUser.position,
});

const mapDispatchToProps = (dispatch) => ({
  onSaveFirstname(text) {
    saveFirstnameThunk(dispatch, { text });
  },
  onSaveLastname(text) {
    saveLastnameThunk(dispatch, { text });
  },
  onSavePosition(text) {
    savePositionThunk(dispatch, { text });
  },
});

export const UserCassetteConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserCassette);

export default UserCassetteConnect;
