import { connect } from 'react-redux';

import { removePackageThunk } from './redux/removePackage.thunk';

import { RemovePackage } from './removePackage';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRemovePackage() {
    removePackageThunk(dispatch, { packageId: ownProps.id });
  },
});

export const RemovePackageConnect = connect(
  null,
  mapDispatchToProps,
)(RemovePackage);

export default RemovePackageConnect;
