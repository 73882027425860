import styled from 'styled-components';

const style: any = {};

style.Container = styled.div`
  height: 100%;
  display: flex;
`;

export default style;
