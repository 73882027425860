import { connect } from 'react-redux';

import { Breadcrumbs } from '../../../components/header';

import { buildBreadcrumbs } from './breadcrumbs';

const mapStateToProps = (state) => ({
  breadcrumbs: buildBreadcrumbs({
    document: state.document,
  }),
});

export const BreadcrumbsConnect = connect(
  mapStateToProps,
)(Breadcrumbs);

export default BreadcrumbsConnect;
