import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { TableSettingsConnect } from './tableSettings.connect';

import s from './settings.style';

interface Props {
  id: string;
  onLevelChange: (params: { wide: boolean; level: number }) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  closePopover: () => void;
}

enum Level {
  main,
  edit,
}

export class Settings extends React.PureComponent<Props> {

  state = {
    level: Level.main,
  };

  setLevel(level: number) {
    this.setState({
      level,
    });
  }

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  handleSetLevelToMain = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.setLevel(Level.main);
    this.props.onLevelChange({ wide: false, level: Level.main });
  };

  handleSetLevelToSort = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.setLevel(Level.edit);
    this.props.onLevelChange({ wide: true, level: Level.edit });
  };

  renderArrowRight() {
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
    const points = iconsPath.get('arrowRight');

    return (
      <s.ArrowItem>
        <Icon
          points={points}
          transform={transform}
          color="currentColor"
        />
      </s.ArrowItem>
    );
  }

  renderMainLevel() {
    return (
      <s.FilterItems>
        <s.Item
          onClick={this.handleSetLevelToSort}
        >
          <s.ItemTitle>Настроить колонки</s.ItemTitle>
          {this.renderArrowRight()}
        </s.Item>
      </s.FilterItems>
    );
  }

  renderSortLevel() {
    return (
      <TableSettingsConnect
        id={this.props.id}
        onGoBack={this.handleSetLevelToMain}
      />
    );
  }

  renderLevel() {
    const firstLevel = this.state.level === Level.main;
    if (firstLevel) {
      return (
        <s.List>
          {this.renderMainLevel()}
        </s.List>
      );
    }

    return (
      <s.Edit>
        {this.renderSortLevel()}
      </s.Edit>
    );
  }

  render() {
    return (
      <s.Root
        onClick={this.stopPropagation}
      >
        {this.renderLevel()}
      </s.Root>
    );
  }

}

export default Settings;
