import { createFolderStartReduce } from './createFolderStart.reduce';

const actionType = 'REGULATION/CREATE_FOLDER__START';

const createAction = () => ({
  type: actionType,
});

export const createFolderStart = {
  actionType,
  createAction,
  reduce: createFolderStartReduce,
};

export default createFolderStart;
