import React from 'react';
import { CellItemWidthItem } from './cellItem';
import { ColumnSort } from './columnSort';
import TextFitCalculationComponent from '../../components/textFitCalculationComponent/textFitCalculationComponent';
import { Tooltip } from '../../components/tooltip/tooltip';

import s from './item.style';
import { SortDataProps } from '../redux/state';

interface CellItemWithTooltipProps {
  title: string;
  tooltip: string;
  // eslint-disable-next-line react/require-default-props
  isEmpty?: boolean;
  // eslint-disable-next-line react/require-default-props
  sortData?: SortDataProps;
  // eslint-disable-next-line react/require-default-props
  isSortActive?: boolean;
  columns: number
  // eslint-disable-next-line react/require-default-props
  isHeaderText?: boolean;
  // eslint-disable-next-line react/require-default-props
  isPercent?: boolean;
  onClick?: (e: MouseEvent) => void;
}

interface CellItemWithTooltipState {
  parentRef: React.RefObject<HTMLDivElement>;
  isFit: boolean;
}

function withTooltip(CellWidthItem) {
  return class extends React.Component<CellItemWithTooltipProps, CellItemWithTooltipState> {

    ref: React.RefObject<HTMLDivElement> = React.createRef();

    static defaultProps = {
      onClick: () => {},
    };

    state = {
      parentRef: { current: null },
      isFit: true,
    };

    componentDidMount() {
      if (this.ref.current) {
        this.setState({ parentRef: this.ref });
      }
    }

    handleFit = (isFitVar: boolean) => {
      const isFitWasChanged = isFitVar !== this.state.isFit;
      if (isFitWasChanged) {
        this.setState({ isFit: isFitVar });
      }
    };

    renderColumnSort = () => {
      const { isHeaderText, isSortActive, sortData } = this.props;

      if (!isHeaderText) {
        return null;
      }

      return (
        <ColumnSort
          // @ts-ignore
          sortData={sortData}
          // @ts-ignore
          isActive={isSortActive}
        />
      );
    };

    renderTextColumn = () => {
      const { title, isSortActive, isHeaderText } = this.props;

      if (!isHeaderText) {
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {title}
          </>
        );
      }

      return (
        <>
          {this.renderColumnSort()}
          <s.HeaderTextTitle isSortActive={isSortActive}>
            {title}
          </s.HeaderTextTitle>
        </>
      );
    };

    renderHiddenColumn = () => {
      const { title, columns, isEmpty, isHeaderText } = this.props;

      const renderBodyText = () => (
        <s.BodyText
          textAlign="end"
          isEmpty={isEmpty}
          isTransparent
        >
          {this.renderTextColumn()}
        </s.BodyText>
      );

      const renderHeaderText = () => (
        <s.HeaderText
          isSortActive={this.props.isSortActive}
          textAlign="end"
          isEmpty={isEmpty}
          isTransparent
        >
          {this.renderTextColumn()}
        </s.HeaderText>
      );

      return (
        <TextFitCalculationComponent
          title={title}
          handleFit={this.handleFit}
          parentRef={this.state.parentRef}
          columns={columns}
        >
          { isHeaderText ? renderHeaderText() : renderBodyText() }
        </TextFitCalculationComponent>
      );
    };

    renderTooltip = () => {
      const { isEmpty, isPercent } = this.props;

      const isPercentValid = isPercent && !isEmpty;
      const shouldRenderTooltip = isPercentValid || !this.state.isFit;

      if (!shouldRenderTooltip) {
        return null;
      }

      const tooltipBlock = (<Tooltip title={this.props.tooltip} />);

      return tooltipBlock;
    };

    render() {
      const isEllipsis = !this.state.isFit;
      const { isEmpty, isHeaderText, isSortActive, onClick } = this.props;

      return (
        <s.CellItem
          isEmpty={isEmpty}
          ref={this.ref}
          onClick={onClick}
        >
          {this.renderHiddenColumn()}
          {this.renderTooltip()}
          <CellWidthItem
            isEllipsis={isEllipsis}
            isEmpty={isEmpty}
            isHeaderText={isHeaderText}
            textContent={this.renderTextColumn()}
            isSortActive={isSortActive}
          />
        </s.CellItem>
      );
    }

  };
}

export const CellItemWithTooltip = withTooltip(CellItemWidthItem);
export default CellItemWithTooltip;
