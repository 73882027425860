import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { ItemConnect } from './item.connect';

import s from './items.style';

interface Item {
  id: number;
  title: string;
  active: boolean;
}

interface ItemsProps {
  items: Item[];
}

export class Items extends React.PureComponent<ItemsProps> {

  renderItems() {
    if (isEmpty(this.props.items)) {
      return this.renderEmptyTitle();
    }

    return this.props.items.map(this.renderItem);
  }

  renderEmptyTitle() {
    return (
      <s.EmptyTitle>
        Подборок не найдено
      </s.EmptyTitle>
    );
  }

  renderItem(item) {
    return <ItemConnect {...item} />;
  }

  render() {
    return (
      <s.Root>
        {this.renderItems()}
      </s.Root>
    );
  }

}

export default Items;
