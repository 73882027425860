import React from 'react';

import s from './itemMenu.style';

interface ItemMenuProps {
  onRemoveBookmark(): void;
}

export const ItemMenu = (props: ItemMenuProps) => {

  const handleItemClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.onRemoveBookmark();
  };

  return (
    <s.Root
      onClick={handleItemClick}
    >
      <s.Item>
        Удалить
      </s.Item>
    </s.Root>
  );
};

export default ItemMenu;
