import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  position: relative;
  padding: 12px 16px;


  overflow: hidden;
  user-select: none;
  word-break: break-word;

  cursor: pointer;

  &:hover {
    background: rgba(65, 131, 215, 0.07);
  }
`;

export default style;
