import {
  ActionData,
  addBookmarkReduce,
} from './addBookmark.reduce';

export const actionType = 'BOOKMARKS_BUFFER/ADD_ITEM';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addBookmark = {
  actionType,
  createAction,
  reduce: addBookmarkReduce,
};
