import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchAttributes } from './fetchAttributes';
import { fetchRelations } from './fetchRelations';
import { fetchChronology } from './fetchChronology';
import { fetchSimilar } from './fetchSimilar';
import { saveCommentary } from './saveCommentary';

import { setActiveTab } from './setActiveTab';

export const reducerBuilder = new ReducerBuilder([
  fetchAttributes,
  fetchRelations,
  fetchChronology,
  fetchSimilar,
  saveCommentary,
  setActiveTab,
]);

export default reducerBuilder;
