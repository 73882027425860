import React from 'react';

import { CommentaryText } from './commentaryText';

import s from './commentary.style';

interface CommentaryProps {
  renderFromRight?: boolean;
  onAddCommentary: (comment: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  commentary: string;
}

export class Commentary extends React.PureComponent<CommentaryProps> {

  static defaultProps = {
    renderFromRight: false,
  };

  state = {
    shouldRenderFromRight: false,
    isEditable: false,
  };

  rootRef = React.createRef();

  componentDidMount() {
    this.ensureRenderFromRight();
  }

  handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  private handleLabelClick = () => {
    this.setState({
      isEditable: true,
    });
  };

  onAddComment = (commentary) => {
    this.props.onBlur();
    if (this.props.commentary === commentary) {
      return;
    }

    this.props.onAddCommentary(commentary);
  };

  ensureRenderFromRight() {
    // @ts-ignore
    const shouldRenderFromRight = this.rootRef.current.getBoundingClientRect().left < 8;
    if (shouldRenderFromRight) {
      this.setState({
        shouldRenderFromRight: true,
      });
    }
  }

  renderComment() {
    const shouldRenderLabel = !this.props.commentary && !this.state.isEditable;
    if (shouldRenderLabel) {
      return (
        <s.Label
          onClick={this.handleLabelClick}
        >
          Добавить комментарий
        </s.Label>
      );
    }

    if (!this.props.commentary) {
      return (
        <CommentaryText
          autofocus
          onBlur={this.onAddComment}
          onFocus={this.props.onFocus}
          value={this.props.commentary}
        />
      );
    }

    return (
      <CommentaryText
        onBlur={this.onAddComment}
        onFocus={this.props.onFocus}
        value={this.props.commentary}
      />
    );
  }

  render() {
    return (
      <s.Root
        ref={this.rootRef}
        onClick={this.handleStopPropagation}
        shouldRenderFromRight={this.props.renderFromRight || this.state.shouldRenderFromRight}
      >
        {this.renderComment()}
      </s.Root>
    );
  }

}

export default Commentary;
