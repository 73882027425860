/* eslint-disable */
export function createChatOptions() {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.innerHTML = `
      var formOneWidget = {
        src: 'https://support.bot.one/widget/chat/11140',
        buttonColor: '#FFAC47'
      };

      function onloadFormOneWidget () {
        window.addEventListener('popstate', function () {
          var widgetRoot = document.getElementsByClassName('form-one-widget__root')[0];
          if (location.hash !== '#/') {
            widgetRoot.setAttribute('style', 'display: block;');
          } else {
            widgetRoot.setAttribute('style', 'display: none;');
          }
        });

        function loadCallback () {
          var widgetRoot = document.getElementsByClassName('form-one-widget__root')[0];
          if (location.hash === '#/') {
            widgetRoot.setAttribute('style', 'display: none;');
          }
        }

        if (window.attachEvent) {
          window.attachEvent('onload', loadCallback);
        } else {
          window.addEventListener('load', loadCallback, false);
        }
      }`;

  document.body.appendChild(script);
}

export function createChatScript() {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.setAttribute('onload', 'onloadFormOneWidget()');
  script.src = 'https://support.bot.one/static/js/widget.bundle.js';

  document.body.appendChild(script);
}

export default createChatScript;
