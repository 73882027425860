import React from 'react';

import {
  SaveButton,
  CloseButton,
  ResetButton,
} from '@components/buttons';

import { Value as ChangeValue } from '@pages/packages/packageMenu/components/input';

import { TextField } from '@components/textField/textField';

import s from './menu.style';

function defaultTitle() {
  const date = new Date();
  const year = new Intl.DateTimeFormat('ru', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('ru', { month: '2-digit' }).format(date);
  const day = new Intl.DateTimeFormat('ru', { day: '2-digit' }).format(date);

  return `Подписка от ${day}.${month}.${year}`;
}

type Title = string;

interface MenuProps {
  closePopover: () => void;
  onSave(title: Title): void;
}

interface MenuState {
  title: Title;
}

export class Menu extends React.PureComponent<MenuProps, MenuState> {

  state = {
    title: defaultTitle(),
  };

  setTitle = (title: Title) => {
    this.setState({
      title,
    });
  };

  handleSave = () => {
    const oldTitleLength = this.state.title.trim().length;
    const shouldUploadTitle = oldTitleLength > 0 && oldTitleLength < 81;

    if (!shouldUploadTitle) {
      return;
    }

    this.props.onSave(this.state.title);
    this.props.closePopover();
  };

  handleCancel = () => {
    this.props.closePopover();
  };

  handleInputChange = (value: ChangeValue): void => {
    this.setTitle(value);
  };

  renderHeader() {
    return (
      <s.Header>
        <s.HeaderAction>
          <CloseButton
            color="#4183d7"
            onClick={this.handleCancel}
          />
        </s.HeaderAction>
        <s.TitleContainer>
          <s.HeaderTitle>
            Создать подписку
          </s.HeaderTitle>
        </s.TitleContainer>
      </s.Header>
    );
  }

  renderTextArea() {
    return (
      <s.TextArea>
        <TextField
          autofocus
          label="Название подписки"
          value={this.state.title}
          maxLength={80}
          onChange={this.handleInputChange}
        />
      </s.TextArea>
    );
  }

  renderResetButton() {
    return (
      <ResetButton onClick={this.handleCancel} />
    );
  }

  renderSaveButton() {
    return (
      <SaveButton
        title="Создать"
        onClick={this.handleSave}
      />
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderHeader()}
        {this.renderTextArea()}
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Root>
    );
  }

}

export default Menu;
