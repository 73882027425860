import React, { useState } from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { NotificationsButtonConnect } from './notificationsButton.connect';

import { NotificationsListConnect } from './notificationsList.connect';

import s from './notifications.style';

export const Notifications = (props: { disabled?: boolean; shouldRender?: true }) => {
  if (!props.shouldRender) {
    return null;
  }

  const [shouldUpdate, changeShouldUpdate] = useState(true);

  const handleMount = () => {
    changeShouldUpdate(false);
  };

  const handleUnmount = () => {
    changeShouldUpdate(true);
  };

  return (
    <s.Root>
      <BaseDropdown
        placement="bottom-end"
        disabled={props.disabled}
        forceMenuVisible
        renderInput={(p) => <NotificationsButtonConnect disabled={props.disabled} shouldUpdate={shouldUpdate} {...p} />}
        renderMenu={(p) => <NotificationsListConnect onMount={handleMount} onUnmount={handleUnmount} {...p} />}
      />
    </s.Root>
  );
};

Notifications.defaultProps = {
  shouldRender: true,
};

export default Notifications;
