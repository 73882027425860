import { saveCommentaryReduce } from './saveCommentary.reduce';

import type { ActionData } from './saveCommentary.reduce';

const actionType = 'SEARCH/RESULT/DOCUMENTS/PREVIEW_SAVE_COMMENTARY';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const saveCommentary = {
  actionType,
  createAction,
  reduce: saveCommentaryReduce,
};

export default saveCommentary;
