import { RESTPath } from '@router/path.rest';
import http from '@http';

interface RequestParams {
  id: number;
  conditions: Record<any, any>;
}

export function httpUpdateFilters(requestParams: RequestParams) {
  const request = {
    url: RESTPath.packages.setFilters({ id: requestParams.id }),
    data: {
      conditions: requestParams.conditions,
    },
  };

  return http
    .post(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUpdateFilters;
