import styled, { css } from 'styled-components';

interface Props {
  alert: boolean;
  theme: Record<any, any>;
  hasRightBorder: boolean;
  boxWidth?: number;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-bottom: none;
`;

style.InfoRow = styled.div`
  display: flex;
  flex-direction: row;
`;

style.Field = styled.div`
  width: ${(props: Props) => (props.boxWidth ? `${props.boxWidth}%` : '100%')};
  height: 48px;
  box-shadow: 0 1px 0 0 #dfe2e6;
`;

style.FieldWithFieldset = styled(style.Field)`
  > fieldset {
    > div:nth-of-type(2) {
      width: calc(100% + 2px);

      label {
        top: 17px;
      }

      input {
        border: none;
        border-bottom: 1px solid #dfe2e6;
        height: 49px;
        background-color: transparent;

        ${(props: Props) => props.hasRightBorder && css`
        border-right: 1px solid #dfe2e6;
      `}
      }
    }
  }
`;

style.Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.DefaultField = styled(style.Field)`
  > div {
    width: calc(100% + 1px);
    label {
      top: 17px;
    }
    input {
      border: none;
      border-bottom: 1px solid #dfe2e6;
      height: 49px;
      background-color: transparent;

      ${(props: Props) => props.hasRightBorder && css`
        border-right: 1px solid #dfe2e6;
      `}
    }
  }
`;

export default style;
