import styled, { css } from 'styled-components';

import counterStyle from './folders/counter.style';

interface Props {
  theme: any;
  active: boolean;
  hovered: boolean;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  padding: 12px 16px;

  overflow: hidden;
  user-select: none;
  word-break: break-word;

  cursor: pointer;

  ${(props: Props) => props.hovered && css`
    background: ${props.theme.colors.lightBlue};

    ${style.Title} {
      color: ${props.theme.colors.defaultText};
    }
  `}

  ${(props: Props) => props.active && css`
    background: ${props.theme.colors.blue};

    ${style.Title} {
      color: ${props.theme.colors.white};
    }

    ${counterStyle.Root} {
      color: ${props.theme.colors.white};
      opacity: 0.5;
    }

    ${style.Settings} {
      > div {
        background: ${props.theme.colors.white};
        opacity: 0.5;
      }
    }
  `}
`;

style.Title = styled.p`
  margin: 0;
  line-height: 16px;
  padding-right: 32px;
  display: flex;
  align-items: center;
`;

style.Settings = styled.span`
  position: absolute;
  float: right;
  right: 4px;
  top: 7px;
`;

style.Icon = styled.div`
  margin-right: 8px;
`;

export default style;
