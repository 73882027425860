import React from 'react';

import {
  isEmpty,
  isEqual,
} from 'lodash';

import { Loader } from '@components/loader';
import { Checkbox } from '@components/checkbox/checkbox';

import { SearchField } from './searchField';
import { Scrollable } from './scrollable';
import { Item } from './item';

import s from './layout.style';

interface ListItem {
  id: string;
  title: string;
}

interface LayoutProps {
  data: ListItem[];
  withSearch: boolean;
  selectAllTitle: string;
  isDefault: boolean;
  onUpdate(data: any): void;
  onFetchData(query: string, page: number): Promise<any>;
}

interface LayoutState {
  query: string;
  loading: boolean;
  items: ListItem[];
  fetchedItems: any;
  selected: Record<number, boolean>;
  page: number;
}

export class Layout extends React.PureComponent<LayoutProps, LayoutState> {

  private placeholder = 'Поиск';

  state = {
    query: '',
    loading: true,
    items: [],
    fetchedItems: [],
    selected: {},
    page: 1,
  };

  componentDidMount() {
    this.buildInitialItems();
    this.buildSelectedState();
    this.fetchItems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading) {
      return;
    }

    if (prevState.page !== this.state.page) {
      this.fetchNextItems();
    }

    if (isEqual(prevState.selected, this.state.selected)) {
      return;
    }

    this.props.onUpdate(this.state.selected);
  }

  handleReset = () => {
    this.setState({
      selected: {},
    }, this.fetchItems);
  };

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setQuery(e.target.value);
  };

  handleItemClick = (id: string) => {
    this.setState((prevState) => {
      const selected = this.createSelected(prevState, id);

      return { selected };

    });
  };

  setQuery(query) {
    this.setState({
      query,
      page: 1,
    }, this.fetchItems);
  }

  private setLoadinFalse = () => this.setLoading(false);

  private setLoading(loading: boolean) {
    this.setState(() => ({
      loading,
    }));
  }

  cleanItems = (items) => {
    const fetchedItems = items.filter((item) => !this.state.items.some((stateItem) => item.id === stateItem.id));

    return fetchedItems;
  };

  private createSelected = (prevState, item) => {
    const actualSelected = { ...prevState.selected };
    if (prevState.selected[item.id]) {
      delete actualSelected[item.id];
    } else {
      actualSelected[item.id] = item;
    }

    return actualSelected;
  };

  private increasePageNumber = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  };

  private buildInitialItems() {
    this.setState({
      items: this.props.data,
    });
  }

  private async fetchItems() {
    const data = await this.props.onFetchData(this.state.query, this.state.page);
    const fetchedItems = this.cleanItems(data.items);

    this.setState(() => ({
      fetchedItems,
    }), this.setLoadinFalse);
  }

  private async fetchNextItems() {
    const data = await this.props.onFetchData(this.state.query, this.state.page);
    const fetchedItems = this.cleanItems(data.items);

    this.setState((prevState) => ({
      fetchedItems: prevState.fetchedItems.concat(fetchedItems),
    }));
  }

  private buildSelectedState() {
    const selected = {};
    this.props.data.forEach((item) => {
      selected[item.id] = item;
    });

    this.setState(() => ({
      selected,
    }));
  }

  renderSearchField = () => {
    if (!this.props.withSearch) {
      return null;
    }

    return (
      <SearchField
        value={this.state.query}
        placeholder={this.placeholder}
        onChange={this.handleSearchQuery}
      />
    );
  };

  renderSelectAll() {
    if (!this.props.withSearch) {
      return null;
    }

    const checked = isEmpty(this.state.selected);

    return (
      <s.DefaultContainer>
        <s.Option
          onClick={this.handleReset}
        >
          <Checkbox
            checked={checked}
            onClick={this.handleReset}
          />
          <s.OptionTitle>
            {this.props.selectAllTitle}
          </s.OptionTitle>
        </s.Option>
      </s.DefaultContainer>
    );
  }

  renderItem = (item) => (
    <Item
      id={item.id}
      title={item.title}
      checked={this.state.selected[item.id]}
      onClick={this.handleItemClick}
    />
  );

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderDefaultItems() {
    const items = this.state.items.filter((item) => item.title.includes(this.state.query));
    if (isEmpty(items)) {
      return null;
    }

    return (
      <s.OptionsContainer>
        {items.map(this.renderItem)}
      </s.OptionsContainer>
    );
  }

  renderFetchedItems() {
    if (this.state.loading) {
      return this.renderLoader();
    }

    return (
      <s.OptionsContainer>
        {this.state.fetchedItems.map(this.renderItem)}
      </s.OptionsContainer>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderSearchField()}
        <Scrollable onFire={this.increasePageNumber}>
          {this.renderSelectAll()}
          {this.renderDefaultItems()}
          {this.renderFetchedItems()}
        </Scrollable>
      </s.Root>
    );
  }

}

export default Layout;
