import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchBookmarks } from './fetchBookmarks';
import { changePageNumber } from './changePageNumber';

import { httpFetchBookmarks } from './fetchBookmarks.http';

import { loadingEnd } from './loading';

import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export class FetchBookmarksThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(pageNumber?: number, shouldPageLoad?: boolean) {

    if (!pageNumber) {
      // eslint-disable-next-line no-param-reassign
      pageNumber = this.getState().searchResult.documents.currentPageNumber;
    }

    sendAmplitudeData('bookmarks: go to page', { page: pageNumber });
    Userpilot.track('bookmarks: go to page', { page: pageNumber });

    if (shouldPageLoad) {
      this.dispatch(pageLoadingStart.createAction());
    }

    try {
      const bookmarks = await httpFetchBookmarks({ pageNumber });
      this.dispatchGetBookmarksSucceed(pageNumber, bookmarks);
      this.dispatchChangePageNumber(pageNumber);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetBookmarksSucceed(pageNumber, bookmarks) {
    const actionData = {
      pageNumber,
      result: bookmarks,
    };

    this.dispatch(fetchBookmarks.createAction(actionData));
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchBookmarksThunk(dispatch, pageNumber, shouldPageLoad = true) {
  const thunk = new FetchBookmarksThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(pageNumber, shouldPageLoad);
}

export default fetchBookmarksThunk;
