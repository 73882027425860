import {
  DocumentId,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

export interface RequestParams {
  documentId: DocumentId,
  value: string | null;
}

export function httpUpdateAnnotationRating(requestParams: RequestParams) {
  const request = http.put({
    url: RESTPath.document.annotationRating(requestParams),
    data: {
      value: requestParams.value,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUpdateAnnotationRating;
