import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { updatePackageDescription } from './updatePackageDescription';

import { httpUpdatePackageDescription } from './updatePackageDescription.http';

export class UpdatePackageDescriptionThunk {

  getState;

  private notificationId = 'UPDATE_PACKAGE_DESCRIPTION';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: number, description: string }) {

    this.dispatchEditPackageDescription(params);

    try {
      const requestParams = {
        id: params.id,
        value: params.description,
      };

      await httpUpdatePackageDescription(requestParams);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }

  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchEditPackageDescription(params) {
    this.dispatch(updatePackageDescription.createAction(params));
  }

}

export function updatePackageDescriptionThunk(dispatch, params) {
  const thunk = new UpdatePackageDescriptionThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default UpdatePackageDescriptionThunk;
