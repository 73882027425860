import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpFetchCaseResult() {
  const request = {
    url: RESTPath.search.bootstrap.caseResult,
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(http.parseResponse);
}

export default httpFetchCaseResult;
