import { store } from '@redux/store';

import { StateBuilder } from './state';

export interface ActionData {
  id: string;
}

export interface Action {
  type: string;
  data: ActionData;
}

export const deleteBookmarkInBufferReduce = (state: ReturnType<typeof store.getState>, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.deleteBookmark(action.data.id);

  return newState.getState();
};

export default deleteBookmarkInBufferReduce;
