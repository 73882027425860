import { Dispatch } from 'redux';

import { fetchLayoutSettings } from './fetchLayoutSettings';
import { httpFetchLayoutSettings } from './fetchLayoutSettings.http';

export class FetchLayoutSettingsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    try {
      const result = await httpFetchLayoutSettings();
      this.dispatchFetchSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(fetchLayoutSettings.createAction(result));
  }

}

export function fetchLayoutSettingsThunk(dispatch) {
  const thunk = new FetchLayoutSettingsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default FetchLayoutSettingsThunk;
