import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { Broadcaster } from '@utils/broadcaster';

import { cleanBookmarks } from '@pages/bookmarks/redux/cleanBookmarks';

import { httpCleanBookmarks } from './cleanBookmarks.http';
import { cleanBookmarksBuffer } from './cleanBookmarks';

export class CleanBookmarksThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    sendAmplitudeData('clean bookmarks');
    Userpilot.track('clean bookmarks');

    this.dispatchCleanBookmarksBuffer();
    this.dispatchCleanBookmarks();
    this.broadcastCleanBookmark();
    await this.cleanBookmarksRequest();
  }

  private broadcastCleanBookmark() {
    const broadcaster = Broadcaster.getInstance();

    const message = {
      type: cleanBookmarksBuffer.actionType,
    };

    broadcaster.postMessage(message);
  }

  private async cleanBookmarksRequest() {
    try {
      await httpCleanBookmarks();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchCleanBookmarksBuffer() {
    this.dispatch(cleanBookmarksBuffer.createAction());
  }

  private dispatchCleanBookmarks() {
    this.dispatch(cleanBookmarks.createAction());
  }

}

export function cleanBookmarksThunk(dispatch) {
  const thunk = new CleanBookmarksThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default CleanBookmarksThunk;
