import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpPasswordReset(data: { password: string, retype_password: string; code: string }) {
  const request = http.put({
    url: RESTPath.user.recovery,
    data,
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpPasswordReset;
