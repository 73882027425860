import {
  DocumentDTO,
  DocumentBuilder,
} from './document';

interface Action {
  type: string;
  data: DocumentDTO;
}

export const fetchDocumentReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const document = new DocumentBuilder(action.data);
  document.buildSate();
  const documentState = document.getState();

  const { currentPageNumber } = state.documents;
  const item = state.documents.pages[currentPageNumber].itemsMap.get(documentState.documentId);

  const newItem = {
    ...item,
    documentLoaded: true,
    details: {
      ...item.details,
      ...documentState,
      title: action.data.title,
    },
  };

  const newItemsMap = state.documents.pages[currentPageNumber].itemsMap.set(documentState.documentId, newItem);

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap: newItemsMap,
        },
      },
    },
  };
};

export default fetchDocumentReduce;
