import { connect } from 'react-redux';

import { resetToInitial } from '@pages/subscriptions/redux/resetToInitial';
import { toggleNotificationThunk } from '@pages/subscriptions/header/redux/toggleNotification.thunk';
import { fetchSubscriptionsThunk } from '@pages/subscriptions/sidebar/redux/fetchSubscriptions.thunk';

import { NotificationCassette } from './notificationCassette';

function buildItems(subscriptions) {
  return subscriptions.itemsList.reduce((acc, id) => {
    const currentItem = subscriptions.itemsMap.get(id);

    acc.push(currentItem);

    return acc;
  }, []);
}

const mapStateToProps = (state) => {
  const { subscriptions } = state.subscriptions;

  const items = buildItems(subscriptions);

  return {
    items,
    loading: subscriptions.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInit() {
    fetchSubscriptionsThunk(dispatch);
  },
  onToggle(id) {
    toggleNotificationThunk(dispatch, { id });
  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
  },
});

export const NotificationsCassetteConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationCassette);

export default NotificationsCassetteConnect;
