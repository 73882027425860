import React, { ReactElement } from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './tagItem.style';

type Id = string;

interface PackageItemProps {
  id: Id;
  title: string | ReactElement;
  selected: boolean;
  color: string;
  path: string;
  onClick?(id: Id): void;
  onMouseOver(id: Id): void;
  onMouseOut(): void;
}

export const TagItem = (props: PackageItemProps) => {
  const onClick = (e) => {
    e.stopPropagation();
    props.onClick(props.id);
  };

  const onMouseOver = () => {
    props.onMouseOver(props.id);
  };

  const onMouseOut = () => {
    props.onMouseOut();
  };

  const iconPath = iconsPath.get(props.path);

  return (
    <s.Item
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      selected={props.selected}
    >
      <Icon path={iconPath} color={props.color} />
      <s.ItemTitle>
        {props.title}
      </s.ItemTitle>
    </s.Item>
  );
};

TagItem.defaultProps = {
  onClick: () => {},
};

export default TagItem;
