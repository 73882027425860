import styled from 'styled-components';

const style: any = {};

style.Icon = styled.div`
  position: relative;

  width: 32px;
  height: 32px;

  justify-content: center;
  align-items: center;
  display: flex;
`;

export default style;
