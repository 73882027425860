import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import ru from 'date-fns/locale/ru';

import s from './datepicker.style';

registerLocale('ru', ru);

export interface DatePickerProps {
  dateFormat?: string
  // eslint-disable-next-line
  placeholderText: string
  // eslint-disable-next-line
  selected: Date
  // eslint-disable-next-line
  onChange?: (date: Date) => void
}

const defaultProps: DatePickerProps = {
  // eslint-disable-next-line
  placeholderText: '',
  // @ts-ignore
  // eslint-disable-next-line react/default-props-match-prop-types
  selected: null,
  dateFormat: 'dd.MM.yyyy',
};

export const DatePicker: React.FC<DatePickerProps> = ({ ...props }) => (
  <s.DatePickerWrapper>
    <ReactDatePicker
      locale="ru"
      customInput={<MaskedTextInput type="text" mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]} />}
      dateFormat={props.dateFormat}
      placeholderText={props.placeholderText}
      selected={props.selected}
      // @ts-ignore
      onChange={props.onChange}
    />
  </s.DatePickerWrapper>
);

DatePicker.defaultProps = defaultProps;

export default DatePicker;
