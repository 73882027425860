import { connect } from 'react-redux';

import { EditView } from './editView';
import { updateView } from './redux/updateView';
import { saveCaseTypeLayoutSettingsThunk } from '../../redux/saveLayout.thunk';

const mapStateToProps = (state, ownProps) => {
  const { type, format } = state.searchResult.analysis.itemsMap[ownProps.id].layout;

  return {
    type,
    format,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSaveView(data) {
    dispatch(updateView.createAction(data));
    saveCaseTypeLayoutSettingsThunk(dispatch);
  },
});

export const EditViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditView);

export default EditViewConnect;
