import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  background-color: #f3f3f3;
`;

style.Content = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

style.ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;

  display: table;
  padding: 16px 64px 64px;
  overflow-x: auto;
  width: 100%;
`;

style.ListWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

style.ItemsContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

style.PageLoader = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
`;

export default style;
