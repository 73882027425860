import styled from 'styled-components';

const ifProp = (prop: any, value: any, failValue = '') => (props: any) => {
  if (!props[prop]) {
    return failValue;
  }

  return value;
};

const styles: any = {};

interface Props {
  isDisabled: boolean;
  theme: any;
}

styles.Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 48px;
  padding: 0;
  border: none;

  color: ${(props: Props) => (props.isDisabled ? props.theme.colors.silver : props.theme.colors.blue)};

  user-select: none;
  cursor: ${ifProp('isDisabled', 'default', 'pointer')};
  outline: none;
  background: none;
`;

export default styles;
