import { connect } from 'react-redux';

import { Block } from '@components/filters/text/block';

import { FilterType } from './redux/state';

import { updateTextThunk } from './redux/updateText.thunk';

const mapStateToProps = (state) => {
  const { filters } = state.regulation;
  const { value } = filters.filters[FilterType.text].data;
  const { loading } = filters;
  const placeholder = 'Поиск по тексту';

  return {
    placeholder,
    loading,
    initialValue: value,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdate(data) {
    updateTextThunk(dispatch, data);
  },
});

export const TextConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default TextConnect;
