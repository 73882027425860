import React from 'react';

import { RoutePath } from '@router/path';

import form from '../form.style';

export const BackToMainLink = () => (
  <form.InternalLink to={RoutePath.login}>
    Перейти к форме входа
  </form.InternalLink>
);

export default BackToMainLink;
