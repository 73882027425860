import { setDocumentIdReduce } from './setDocumentId.reduce';

import type { ActionData } from './setDocumentId.reduce';

const actionType = 'DOCUMENT/SET_ID';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setDocumentId = {
  actionType,
  createAction,
  reduce: setDocumentIdReduce,
};

export default setDocumentId;
