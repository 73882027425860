import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateFromReduce } from './updateFrom.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/CLAIM_AMOUNT/UPDATE_FROM`;

export interface Data {
  id: string;
  data: {
    value: string | number;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateFrom = {
  actionType,
  createAction,
  reduce: updateFromReduce,
};

export default updateFrom;
