import { resetToInitialReduce } from './resetToInitial.reduce';

const actionType = 'SUBSCRIPTIONS/DOCUMENTS/RESET_TO_INITIAL';

const createAction = () => ({
  type: actionType,
});

export const resetToInitial = {
  actionType,
  createAction,
  reduce: resetToInitialReduce,
};

export default resetToInitial;
