import { resetDocumentsReduce } from './resetDocuments.reduce';

const actionType = 'SEARCH/RESULT/COURTS/RESET_DOCUMENTS';

const createAction = () => ({
  type: actionType,
});

export const resetDocuments = {
  actionType,
  createAction,
  reduce: resetDocumentsReduce,
};

export default resetDocuments;
