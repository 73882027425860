import {
  AttributesDTO,
  AttributesBuilder,
} from '@pages/document/sidebar/redux/attributes';

export interface ActionData {
  attributes: AttributesDTO;
  documentId: number;
  comment: string,
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchAttributesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  // @ts-ignore
  const attributes = new AttributesBuilder();
  attributes.createFromDTO({ ...action.data.attributes, comment: action.data.comment });
  attributes.buildState();

  const attributesState = attributes.getState();
  const documentId = action.data.documentId;
  const { currentPageNumber } = state.documents;
  const item = state.documents.pages[currentPageNumber].itemsMap.get(documentId);

  const newItem = {
    ...item,
    attributesLoaded: true,
    details: {
      ...item.details,
      attributes: attributesState,
    },
  };

  const newItemsMap = state.documents.pages[currentPageNumber].itemsMap.set(action.data.documentId, newItem);

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap: newItemsMap,
        },
      },
    },
  };
};

export default fetchAttributesReduce;
