import { resetToDefaultReduce } from './resetToDefault.reduce';

const actionType = 'REGULATION/FOLDER/USERS/RESET_TO_DEFAULT';

const createAction = () => ({
  type: actionType,
});

export const resetToDefault = {
  actionType,
  createAction,
  reduce: resetToDefaultReduce,
};

export default resetToDefault;
