import { updateAnnotationRatingReduce } from './updateAnnotationRating.reduce';

import type { ActionData } from './updateAnnotationRating.reduce';

const actionType = 'ANNOTATION/UPDATE_RATING';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateAnnotationRating = {
  actionType,
  createAction,
  reduce: updateAnnotationRatingReduce,
};

export default updateAnnotationRating;
