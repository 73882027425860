import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { ItemDetailConnect } from './itemDetail.connect';
import { CellItemWithTooltip } from './itemWithTooltip';
import s from './item.style';

import type { DataFromView } from './item.connect';

export interface Props {
  item: {
    id: string,
    title: string,
  };
  columns: any;
  onAddCourt(data: DataFromView): void;
}

interface State {
  showDetails: boolean;
}

export class Item extends React.PureComponent<Props, State> {

  state = {
    showDetails: false,
  };

  handleRowClick = () => {
    this.setShowDetails();
  };

  setShowDetails = () => {
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  handleCellClick = (columnId) => {
    const data = {
      columnId,
      court: this.props.item,
    };

    this.props.onAddCourt(data);
  };

  renderArrowRight() {
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
    const points = iconsPath.get('arrowRight');

    return (
      <s.ArrowItem>
        <Icon
          points={points}
          transform={transform}
          color="#4183d7"
        />
      </s.ArrowItem>
    );
  }

  renderArrowDown() {
    const points = iconsPath.get('arrowDown');

    return (
      <s.ArrowItem>
        <Icon
          points={points}
          color="#4183d7"
        />
      </s.ArrowItem>
    );
  }

  renderIcon() {
    if (!this.state.showDetails) {
      return this.renderArrowRight();
    }

    return this.renderArrowDown();
  }

  renderColumnInGroup() {
    return this.props.columns.map((column) => {
      const isTitle = column.id === 'title';
      if (isTitle) {
        return (
          <s.ColumnTitle key={column.id} />
        );
      }

      return (
        <s.ColumnItem key={column.id} />
      );
    });
  }

  renderItems() {
    return this.props.columns.map((column) => {
      const isTitle = column.id === 'title';
      if (isTitle) {
        return (
          <s.CellTitle
            key={column.id}
          >
            <s.ColumnWidthTitle>
              <s.CellContainer>
                <s.Title>
                  {this.renderIcon()}
                  <s.BodyText
                    isTitle
                  >
                    {column.title}
                  </s.BodyText>
                </s.Title>
              </s.CellContainer>
            </s.ColumnWidthTitle>
          </s.CellTitle>
        );
      }

      const { title, tooltip, isPercent } = column;
      const isEmpty = title === '0' || title === '0%';
      const itemClick = (e) => {
        e.stopPropagation();

        if (isEmpty) {
          return;
        }

        this.handleCellClick(column.id);
      };

      return (
        <CellItemWithTooltip
          key={column.id}
          title={title}
          tooltip={tooltip}
          isEmpty={isEmpty}
          isPercent={isPercent}
          columns={this.props.columns.length}
          onClick={itemClick}
        />
      );
    });
  }

  renderItemDetails() {
    if (!this.state.showDetails) {
      return null;
    }

    return <ItemDetailConnect item={this.props.item} />;
  }

  render() {
    const key = `${this.props.item.id}-main`;

    return (
      <>
        <s.Table key={key}>
          <s.Group>
            {this.renderColumnInGroup()}
          </s.Group>
          <s.Row
            onClick={this.handleRowClick}
          >
            {this.renderItems()}
          </s.Row>
        </s.Table>
        {this.renderItemDetails()}
      </>
    );
  }

}

export default Item;
