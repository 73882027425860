import { connect } from 'react-redux';

import { RequisitesCopyPopover } from '../components/requisitesCopy/requisitesCopyPopover';

function buildRequisitesForClipBoard(document) {
  const data = document.attributes.data;

  // eslint-disable-next-line max-len
  return `${data.title} ${document.document.document.departmentNameGenitive} от ${data.attributes.date} по делу № ${data.attributes.case.number}`;
}

const mapStateToProps = (state) => ({
  requisites: buildRequisitesForClipBoard(state.document),
});

export const RequisitesCopyConnect = connect(
  mapStateToProps,
)(RequisitesCopyPopover);

export default RequisitesCopyConnect;
