import React from 'react';

import s from './header.style';

export interface HeaderProps {
  title: string;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.TopHeader>
        <s.Title>
          {this.props.title}
        </s.Title>
        <s.Actions />
      </s.TopHeader>
    );
  }

}

export default Header;
