import { LawBlock } from '../filterBlocks/law/redux/law';
import { TextBlock } from '../filterBlocks/text/redux/text';
import { DocumentTypeBlock } from '../filterBlocks/documentType/redux/documentType';
import { DateBlock } from '../filterBlocks/date/redux/date';

import { JudgeBlock } from '../filterBlocks/judge/redux/judge';
import { CourtBlock } from '../filterBlocks/court/redux/court';
import { PreviousCourtBlock } from '../filterBlocks/previousCourt/redux/previousCourt';
import { CourtTypeBlock } from '../filterBlocks/courtType/redux/courtType';
import { RegionBlock } from '../filterBlocks/region/redux/region';

import { CaseTypeBlock } from '../filterBlocks/caseType/redux/caseType';
import { DisputeCategoryBlock } from '../filterBlocks/disputeCategory/redux/disputeCategory';
import { CaseNumberBlock } from '../filterBlocks/caseNumber/redux/caseNumber';
import { ClaimAmountBlock } from '../filterBlocks/claimAmount/redux/claimAmount';
import { CaseResultBlock } from '../filterBlocks/caseResult/redux/caseResult';
import { AppealStateBlock } from '../filterBlocks/appealState/redux/appealState';
import { ParticipantBlock } from '../filterBlocks/participant/redux/participant';
import { PlaintiffBlock } from '../filterBlocks/plaintiff/redux/plaintiff';
import { DefendantBlock } from '../filterBlocks/defendant/redux/defendant';
import { ThirdPartyPersonBlock } from '../filterBlocks/thirdPartyPerson/redux/thirdPartyPerson';
import { AnotherPersonBlock } from '../filterBlocks/anotherPerson/redux/anotherPerson';
import { RepresentativeBlock } from '../filterBlocks/representative/redux/representative';

export type BlockType = typeof LawBlock
                | typeof TextBlock
                | typeof DocumentTypeBlock
                | typeof DateBlock
                | typeof JudgeBlock
                | typeof CourtBlock
                | typeof PreviousCourtBlock
                | typeof CourtTypeBlock
                | typeof RegionBlock
                | typeof CaseTypeBlock
                | typeof DisputeCategoryBlock
                | typeof CaseNumberBlock
                | typeof ClaimAmountBlock
                | typeof CaseResultBlock
                | typeof AppealStateBlock
                | typeof ParticipantBlock
                | typeof PlaintiffBlock
                | typeof DefendantBlock
                | typeof ThirdPartyPersonBlock
                | typeof AnotherPersonBlock
                | typeof RepresentativeBlock;

export interface BlockProps {
  type: string
  block: BlockType
}

export const blocksTypes = {
  document: 'document',
  member: 'member',
  dispute: 'dispute',
};

export const blocks: BlockProps[] = [
  {
    type: blocksTypes.document,
    block: LawBlock,
  },
  {
    type: blocksTypes.document,
    block: TextBlock,
  },
  {
    type: blocksTypes.document,
    block: DocumentTypeBlock,
  },
  {
    type: blocksTypes.document,
    block: DateBlock,
  },
  {
    type: blocksTypes.member,
    block: JudgeBlock,
  },
  {
    type: blocksTypes.member,
    block: CourtBlock,
  },
  {
    type: blocksTypes.member,
    block: PreviousCourtBlock,
  },
  {
    type: blocksTypes.member,
    block: CourtTypeBlock,
  },
  {
    type: blocksTypes.member,
    block: RegionBlock,
  },
  {
    type: blocksTypes.dispute,
    block: CaseTypeBlock,
  },
  {
    type: blocksTypes.dispute,
    block: DisputeCategoryBlock,
  },
  {
    type: blocksTypes.dispute,
    block: CaseNumberBlock,
  }, {
    type: blocksTypes.dispute,
    block: ClaimAmountBlock,
  },
  {
    type: blocksTypes.dispute,
    block: CaseResultBlock,
  },
  {
    type: blocksTypes.dispute,
    block: AppealStateBlock,
  },
  {
    type: blocksTypes.dispute,
    block: ParticipantBlock,
  },
  {
    type: blocksTypes.dispute,
    block: PlaintiffBlock,
  },
  {
    type: blocksTypes.dispute,
    block: DefendantBlock,
  },
  {
    type: blocksTypes.dispute,
    block: ThirdPartyPersonBlock,
  },
  {
    type: blocksTypes.dispute,
    block: AnotherPersonBlock,
  },
  {
    type: blocksTypes.dispute,
    block: RepresentativeBlock,
  },
];

export const getBlocks = (type: string, data: BlockProps[]) => data.reduce((acc, block) => {
  if (block.type === type) {
    // @ts-ignore
    acc.push(block.block);
  }

  return acc;
}, []);
