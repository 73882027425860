import React from 'react';

import { category } from '../filterBlocks/categories';

import {
  BlockProps, blocksTypes, getBlocks,
} from './utils';

import s from './blocks.style';

interface BlocksProps {
  blocks: BlockProps[];
  // eslint-disable-next-line react/no-unused-prop-types
  onHandleItemSelect: (type: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  closePopover: () => void;
}

// @ts-ignore
const renderDocumentContainer = (props: BlocksProps) => {
  const blocks = getBlocks(blocksTypes.document, props.blocks);

  const renderItem = (block: { blockTitle: string, type: string }, index: number) => {
    const handleClick = () => {
      props.onHandleItemSelect(block.type);
      props.closePopover();
    };

    return (
      <s.FilterItem
        key={index}
        color={category.document.color}
        onClick={handleClick}
      >
        {block.blockTitle}
      </s.FilterItem>
    );
  };

  const renderFilterContent = () => {
    if (!blocks.length) {
      return null;
    }

    return (
      <s.Items>
        <s.FiltersContainer>
          <s.FilterTitle>{category.document.title}</s.FilterTitle>
          <s.FilterItems>
            {
              blocks.map(renderItem)
            }
          </s.FilterItems>
        </s.FiltersContainer>
      </s.Items>
    );
  };

  return renderFilterContent();
};

const renderMemberContainer = (props: BlocksProps) => {
  const blocks = getBlocks(blocksTypes.member, props.blocks);

  // @ts-ignore
  const renderItem = (block, index) => {
    const handleClick = () => {
      props.onHandleItemSelect(block.type);
      props.closePopover();
    };

    return (
      <s.FilterItem
        key={index}
        color={category.member.color}
        onClick={handleClick}
      >
        {block.blockTitle}
      </s.FilterItem>
    );
  };

  const renderFilterContent = () => {
    if (!blocks.length) {
      return null;
    }

    return (
      <s.Items>
        <s.FiltersContainer>
          <s.FilterTitle>{category.member.title}</s.FilterTitle>
          <s.FilterItems>
            {
              blocks.map(renderItem)
            }
          </s.FilterItems>
        </s.FiltersContainer>
      </s.Items>
    );
  };

  return renderFilterContent();
};

const renderDisputeContainer = (props: BlocksProps) => {
  const blocks = getBlocks(blocksTypes.dispute, props.blocks);

  // @ts-ignore
  const renderItem = (block, index) => {
    const handleClick = () => {
      props.onHandleItemSelect(block.type);
      props.closePopover();
    };

    return (
      <s.FilterItem
        key={index}
        color={category.dispute.color}
        onClick={handleClick}
      >
        {block.blockTitle}
      </s.FilterItem>
    );
  };

  const renderFilterContent = () => {
    if (!blocks.length) {
      return null;
    }

    return (
      <s.Items>
        <s.FiltersContainer>
          <s.FilterTitle>{category.dispute.title}</s.FilterTitle>
          <s.FilterItems>
            {
              blocks.map(renderItem)
            }
          </s.FilterItems>
        </s.FiltersContainer>
      </s.Items>
    );
  };

  return renderFilterContent();
};

export const Blocks = (props: BlocksProps) => {

  const renderContent = () => {
    if (!props.blocks.length) {
      const emptyBlockTitle = 'Нет совпадений';

      return (
        <s.BlocksEmpty>
          <s.BlocksEmptyText>{emptyBlockTitle}</s.BlocksEmptyText>
        </s.BlocksEmpty>
      );
    }

    return (
      // eslint-disable-next-line react/jsx-no-comment-textnodes
      <>
        {renderDocumentContainer(props)}
        {renderMemberContainer(props)}
        {renderDisputeContainer(props)}
      </>
    );
  };

  return (
    <s.Blocks>
      {renderContent()}
    </s.Blocks>
  );
};

export default Blocks;
