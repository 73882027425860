import { setLoadingStatusReduce } from './setLoadingStatus.reduce';

import type { ActionData } from './setLoadingStatus.reduce';

const actionType = 'REGULATION/CHAT/CHANGE_LOADING_STATUS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setLoadingStatus = {
  actionType,
  createAction,
  reduce: setLoadingStatusReduce,
};

export default setLoadingStatus;
