import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { fetchPackagesSearchResult } from './fetchPackagesSearchResult';

import { httpFetchPackages } from '../fetchPackages.http';

export class FetchPackagesSearchResultThunk {

  getState;

  private notificationId = 'FETCH_PACKAGE_DOCUMENT_WITH_FILTERS';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { documentId?: number, query?: string }) {

    try {
      const result = await httpFetchPackages(params);
      this.dispatchFetchSucceed(result);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchFetchSucceed(result) {
    const actionData = {
      packages: result.packages,
    };

    this.dispatch(fetchPackagesSearchResult.createAction(actionData));
  }

}

export function fetchPackagesThunk(dispatch, params) {
  const thunk = new FetchPackagesSearchResultThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchPackagesSearchResultThunk;
