import { connect } from 'react-redux';

import { Items } from './items';

function buildItems(params) {
  const query = params.query.trim().toLowerCase();

  return params.items.filter((item) => {
    const currentItem = item.title.toLowerCase();

    return currentItem.includes(query);
  });
}

const mapStateToProps = (state, ownProps: { query: string }) => ({
  items: buildItems({ items: state.lawDocument.document.titles, query: ownProps.query }),
});

export const ItemsConnect = connect(
  mapStateToProps,
)(Items);

export default ItemsConnect;
