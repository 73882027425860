import { connect } from 'react-redux';

import { toggleNotificationsThunk } from '@pages/subscriptions/header/redux/toggleNotifications.thunk';

import { SettingsMenu } from './settingsMenu';

const mapDispatchToProps = (dispatch) => ({
  onSwitchOn() {
    toggleNotificationsThunk(dispatch, { switchOn: true });
  },
  onSwitchOff() {
    toggleNotificationsThunk(dispatch, { switchOn: false });
  },
});

export const SettingsMenuConnect = connect(
  null,
  mapDispatchToProps,
)(SettingsMenu);

export default SettingsMenuConnect;
