import { courtsSettings } from './settings';

export interface ActionData {
  result: {
    layout: any;
  };
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchSettingsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const fetchLayout = action.data.result;
  const layout = courtsSettings.decorate(fetchLayout);

  const newState = {
    shouldLoadSettings: false,
    layout,
  };

  return {
    ...state,
    courts: {
      ...state.courts,
      ...newState,
    },
  };
};

export default fetchSettingsReduce;
