import { styled } from '@components/styled';

const style: any = {};

style.Actions = styled.div`
  display: flex;
  align-items: center;
`;

style.ShowMore = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
  margin-right: 5px;
`;

export default style;
