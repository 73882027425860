import { Dispatch } from 'redux';

import { Userpilot } from '@utils/userpilot';
import { sendAmplitudeData } from '@utils/amplitude';
import { Broadcaster } from '@utils/broadcaster';
import { notifierManager } from '@components/notification';

import { addCommentary as addCommentaryInBookmarks } from '@pages/bookmarks/redux/addCommentary';

import { httpAddCommentary } from './addCommentary.http';
import { addCommentary as addCommentaryInBuffer } from './addCommentary';

interface Params {
  paragraphId: number;
  commentary: string;
}

export class AddCommentaryThunk {

  getState;

  private notificationId = 'ADD_PACKAGE_COMMENTARY';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    sendAmplitudeData('add commentary', {});
    Userpilot.track('add commentary');

    const { id } = this.getState().bookmarksBuffer.queue.find((item) => item.paragraphId === params.paragraphId);

    this.dispatchAddCommentaryInBookmarks({ id, commentary: params.commentary });
    this.dispatchAddCommentaryInBuffer({ id, commentary: params.commentary });
    this.broadcastAddCommentary({ id, commentary: params.commentary });
    await this.addCommentaryRequest({ id, commentary: params.commentary });
  }

  private broadcastAddCommentary(params) {
    const broadcaster = Broadcaster.getInstance();

    const message = {
      type: addCommentaryInBuffer.actionType,
      data: params,
    };

    broadcaster.postMessage(message);
  }

  private async addCommentaryRequest(params) {
    try {
      await httpAddCommentary(params);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchAddCommentaryInBookmarks(params) {
    this.dispatch(addCommentaryInBookmarks.createAction(params));
  }

  private dispatchAddCommentaryInBuffer(params) {
    this.dispatch(addCommentaryInBuffer.createAction(params));
  }

}

export function addCommentaryThunk(dispatch, params) {
  const thunk = new AddCommentaryThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default addCommentaryThunk;
