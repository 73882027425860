export const inputValues = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  20: 11,
  30: 12,
  40: 13,
  50: 14,
  60: 15,
  70: 16,
  80: 17,
  90: 18,
  100: 19,
  200: 20,
  300: 21,
  400: 22,
  500: 23,
  600: 24,
  700: 25,
  800: 26,
  900: 27,
  1000: 28,
  2000: 29,
  3000: 30,
  4000: 31,
  5000: 32,
  6000: 33,
  7000: 34,
  8000: 35,
  9000: 36,
  10000: 37,
  20000: 38,
  30000: 39,
  40000: 40,
  50000: 41,
  60000: 42,
  70000: 43,
  80000: 44,
  90000: 45,
  100000: 46,
  200000: 47,
  300000: 48,
  400000: 49,
  500000: 50,
  600000: 51,
  700000: 52,
  800000: 53,
  900000: 54,
  1000000: 55,
  2000000: 56,
  3000000: 57,
  4000000: 58,
  5000000: 59,
  6000000: 60,
  7000000: 61,
  8000000: 62,
  9000000: 63,
  10000000: 64,
  20000000: 65,
  30000000: 66,
  40000000: 67,
  50000000: 68,
  60000000: 69,
  70000000: 70,
  80000000: 71,
  90000000: 72,
  100000000: 73,
  200000000: 74,
  300000000: 75,
  400000000: 76,
  500000000: 77,
  600000000: 78,
  700000000: 79,
  800000000: 80,
  900000000: 81,
  1000000000: 82,
  2000000000: 83,
  3000000000: 84,
  4000000000: 85,
  5000000000: 86,
  6000000000: 87,
  7000000000: 88,
  8000000000: 89,
  9000000000: 90,
  10000000000: 91,
  20000000000: 92,
  30000000000: 93,
  40000000000: 94,
  50000000000: 95,
  60000000000: 96,
  70000000000: 97,
  80000000000: 98,
  90000000000: 99,
  100000000000: 100,
  200000000000: 101,
  300000000000: 102,
  400000000000: 103,
  500000000000: 104,
  600000000000: 105,
  700000000000: 106,
  800000000000: 107,
  900000000000: 108,
  1000000000000: 109,
  2000000000000: 110,
};

export default inputValues;
