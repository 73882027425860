import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchEditions } from './fetchEditions';

import { setActiveTab } from './setActiveTab';

export const reducerBuilder = new ReducerBuilder([
  fetchEditions,
  setActiveTab,
]);

export default reducerBuilder;
