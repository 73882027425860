import React from 'react';
import {
  DragDropContext, Droppable,
} from 'react-beautiful-dnd';

import s from './dragAndDrop.style';

interface DragAndDropProps {
  droppableId: string,
  renderContent: () => JSX.Element[],
  onDragEnd: (data) => void,
  onDragUpdate?: (data) => void
}

export const DragAndDrop = ({
  onDragEnd,
  onDragUpdate,
  droppableId,
  renderContent,
}: DragAndDropProps) => {

  const renderDragAndDrop = () => {
    const renderDroppable = (provided) => {
      const providedPlaceholder = provided.placeholder;
      const providedProps = {
        ...provided.droppable,
        ref: provided.innerRef,
      };

      return (
        <s.DragAndDropContent {...providedProps}>
          {renderContent()}
          {providedPlaceholder}
        </s.DragAndDropContent>
      );
    };

    const handleDragEnd = (e) => {
      e.stopPropagation();
    };

    return (
      <s.StopPropagation
        onClick={handleDragEnd}
        onDragEnd={handleDragEnd}
      >
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragUpdate={onDragUpdate}
        >
          <Droppable
            droppableId={droppableId}
          >
            {renderDroppable}
          </Droppable>
        </DragDropContext>
      </s.StopPropagation>
    );
  };

  return renderDragAndDrop();
};

export default DragAndDrop;
