import { connect } from 'react-redux';

import { fetchPackagesThunk } from '@pages/packages/redux/searchPackages/fetchPackagesSearchResult.thunk';
// eslint-disable-next-line max-len
import { searchPackagesResultResetToInitial } from '@pages/packages/redux/searchPackages/searchPackagesResultResetToInitial';

import { AddPackage } from '@components/addPackage/addPackage';

import { createPackageThunk } from '../../redux/createPackageAddDocuments.thunk';
import { addDocumentsToPackageThunk } from '../../redux/addDocumentsToPackage.thunk';

const mapStateToProps = (state) => {
  const packages = state.packages.searchResult;

  return {
    count: 0,
    packages,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInit() {
    fetchPackagesThunk(dispatch, {});
  },
  onResetToInitial() {
    dispatch(searchPackagesResultResetToInitial.createAction());
  },
  onPackageCreate(query: string) {
    const searchQuery = query.trim();

    const params = {
      query: searchQuery,
    };

    createPackageThunk(dispatch, params);
  },
  onPackageToggle(packageId: number) {
    const params = {
      targetId: packageId,
    };

    addDocumentsToPackageThunk(dispatch, params);
  },
});

export const AddPackageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPackage);

export default AddPackageConnect;
