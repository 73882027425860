import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateCaseTypeReduce } from './updateCaseType.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/CASE_TYPE/UPDATE`;

export interface Data {
  id: string;
  data: {
    id: number;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateCaseType = {
  actionType,
  createAction,
  reduce: updateCaseTypeReduce,
};

export default updateCaseType;
