import { connect } from 'react-redux';

import { fetchFolderThunk } from '../redux/fetchFolder.thunk';

import { Folder } from './folder';

const mapStateToProps = (state) => {
  const {
    layout,
    projects,
    loading,
    newProjects,
    withoutChange,
  } = state.regulation.projects;

  const newItems = projects.itemsList.filter((item) => projects.itemsMap.get(item).isNew);

  const withoutChangeItems = projects.itemsList.filter((item) => !projects.itemsMap.get(item).isNew);

  return {
    viewType: layout.viewType,
    items: projects.itemsList,
    newItems,
    withoutChangeItems,
    newProjects,
    withoutChange,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoadNextPage() {
    fetchFolderThunk(dispatch);
  },
});

export const FolderConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Folder);

export default FolderConnect;
