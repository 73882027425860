import { Dispatch } from 'redux';

import {
  Id,
  Source,
} from '@router/path.rest';

import { addDocumentToPackage } from '@pages/packages/redux/searchPackages/addDocumentToPackage';

import { httpAddDocumentsToPackage } from '@pages/packages/redux/packages/addDocumentsToPackage.http';
import { httpGetPackageCount } from '@pages/packages/redux/packages/getPackageCount.http';

import { fetchDocumentsPackageCount } from './fetchDocumentsPackageCount';

export class AddDocumentsToPackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  private sourceId: Id = null;

  private readonly source: Source.subscription;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.source = Source.subscription;
  }

  public async invoke(params: { targetId: Id }) {
    this.sourceId = this.getState().subscriptions.id;
    const { selected } = this.getState().packages.searchResult.itemsMap.get(params.targetId);
    this.dispatchAddToPackage(params.targetId);
    if (selected) {
      return;
    }

    await this.httpAddDocumentsToPackage(params);
    const response = await this.httpGetPackageCount();

    this.dispatchAddDocumentsToPackage(response);
  }

  private dispatchAddToPackage(packageId: number) {
    this.dispatch(addDocumentToPackage.createAction({ packageId }));
  }

  private dispatchAddDocumentsToPackage(response) {
    this.dispatch(fetchDocumentsPackageCount.createAction(response));
  }

  private async httpAddDocumentsToPackage(params) {
    const { selection } = this.getState().subscriptions.documents;

    const ids = selection.ids.toArray().map((i) => i[0]);
    const requestParams = {
      targetId: params.targetId,
      source: this.source,
      sourceId: this.sourceId,
      documents: {
        rule: selection.rule,
        ids,
      },
    };

    try {
      await httpAddDocumentsToPackage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  // eslint-disable-next-line consistent-return
  private async httpGetPackageCount() {
    const requestParams = {
      source: Source.search as Source.search,
      sourceId: this.sourceId,
    };

    try {
      return await httpGetPackageCount(requestParams);
    } catch (error) {
      throw Error(error.status);
    }

  }

}

export function addDocumentsToPackageThunk(dispatch, params) {
  const thunk = new AddDocumentsToPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddDocumentsToPackageThunk;
