import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';

import { fetchBookmarks } from './fetchBookmarks';
import { changePageNumber } from './changePageNumber';

import { addCommentary } from './addCommentary';

import { cleanBookmarks } from './cleanBookmarks';

import { resetToInitial } from './resetToInitial';

import {
  loadingStart,
  loadingEnd,
} from './loading';

import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export const reducerBuilder = new ReducerBuilder([
  fetchBookmarks,
  changePageNumber,
  addCommentary,
  cleanBookmarks,
  loadingEnd,
  loadingStart,
  pageLoadingEnd,
  pageLoadingStart,
  resetToInitial,
]);

const initialState = StateBuilder.createState();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
