import React from 'react';

import { DatePicker } from '@components/datepicker';
import { Checkbox } from '../components/checkbox';
import {
  DateBlock,
  ScopeId,
  ScopeIdDTO,
} from './logic/date';

import 'react-datepicker/dist/react-datepicker.css';

import s from './layout.style';

interface LayoutProps {
  scope: {
    id: string;
    title: string;
    itemTitle: string;
  };
  value: Date[];
  onFromChange: (data: any) => void;
  onToChange: (data: any) => void;
  onScopeChange: (data: any) => void;
}

export class Layout extends React.PureComponent<LayoutProps> {

  renderDefaultContainer() {
    const isActive = this.props.scope.id === ScopeId.allTime;
    const noValue = this.props.value.every((item) => !item);

    return (
      <s.DefaultContainer>
        <s.Option>
          <Checkbox
            isActive={isActive && noValue}
            item={DateBlock.scopePresets[ScopeIdDTO.allTime]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.allTime].itemTitle}
          </Checkbox>
        </s.Option>
      </s.DefaultContainer>
    );
  }

  renderOtionsContainer() {
    const isActiveYear = this.props.scope.id === ScopeId.year;
    const isActiveTwoYears = this.props.scope.id === ScopeId.twoYears;
    const isActiveFiveYears = this.props.scope.id === ScopeId.fiveYears;

    return (
      <s.OptionsContainer>
        <s.Option>
          <Checkbox
            isActive={isActiveYear}
            item={DateBlock.scopePresets[ScopeIdDTO.year]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.year].itemTitle}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveTwoYears}
            item={DateBlock.scopePresets[ScopeIdDTO.twoYears]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.twoYears].itemTitle}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveFiveYears}
            item={DateBlock.scopePresets[ScopeIdDTO.fiveYears]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.fiveYears].itemTitle}
          </Checkbox>
        </s.Option>
      </s.OptionsContainer>
    );
  }

  renderDateFrom() {
    return (
      <s.Input>
        <DatePicker
          placeholderText="С"
          selected={this.props.value[0]}
          onChange={this.props.onFromChange}
        />
      </s.Input>
    );
  }

  renderDateTo() {
    return (
      <s.Input>
        <DatePicker
          placeholderText="По"
          selected={this.props.value[1]}
          onChange={this.props.onToChange}
        />
      </s.Input>
    );
  }

  render() {
    return (
      <s.Root>
        <s.InputContainer>
          {this.renderDateFrom()}
          {this.renderDateTo()}
        </s.InputContainer>
        {this.renderDefaultContainer()}
        {this.renderOtionsContainer()}
      </s.Root>
    );
  }

}

export default Layout;
