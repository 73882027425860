/* eslint-disable */
import { animateTopScroll } from './animateScroll';

function isIE() {
  const myNav = navigator.userAgent.toLowerCase();

  return (myNav.indexOf('msie') !== -1) ? parseInt(myNav.split('msie')[1], 10) : false;
}

const HIGHLIGHTED_CLASS_NAME = 'highlighted';
const TRANSITIONED_CLASS_NAME = 'transitioned-bg';

const SCROLL_DURATION = 300;
const HIGHLIGHTED_TIMEOUT = 200;
const TRANSITIONED_TIMEOUT = 2500;

// Подсматривал в: https://github.com/fisshy/react-scroll
export class ScrollTo {

  constructor(props) {
    this.targetId = props.targetId;
    this.offset = props.offset || null;
  }

  to = null;
  idAttr = 'data-entity-id';

  setTo = (to) => {
    this.to = to;
  };

  scrollTo = () => {
    const
      documentViewContainer = document.getElementById(this.targetId),
      documentHalfHeight = Math.ceil(document.body.getBoundingClientRect().height / 2),
      elementHalfHeight = Math.ceil(this._getTargetElement().getBoundingClientRect().height / 2),
      elementOffset = this.offset || elementHalfHeight - documentHalfHeight
    ;

    let coordinates;

    if (this.offset) {
      coordinates = this._getYCoordinate() - this.offset;
    } else {
      coordinates = this._getYCoordinate();
    }

    // https://github.com/fisshy/react-scroll/blob/master/modules/mixins/scroller.js#L50
    if ((!isIE() || isIE() > 9)) {
      const options = {
        duration: SCROLL_DURATION,
        targetElement: documentViewContainer
      };

      animateTopScroll(coordinates, options);
    } else {
      documentViewContainer.scrollTop = coordinates;
    }
  }

  highlight = () => {
    if (this._highlightTimeout) {
      clearTimeout(this._highlightTimeout);
    }

    this._addClassNameToTarget(HIGHLIGHTED_CLASS_NAME);

    this._highlightTimeout = setTimeout(() => {
      this._removeClassName(TRANSITIONED_CLASS_NAME);
    }, TRANSITIONED_TIMEOUT + SCROLL_DURATION);

    this._addClassNameToTarget(TRANSITIONED_CLASS_NAME);

    setTimeout(() => {
      this._removeClassName(HIGHLIGHTED_CLASS_NAME);
    }, HIGHLIGHTED_TIMEOUT + SCROLL_DURATION);
  }

  _getTargetElement() {
    const elements = document.querySelectorAll([`[${ this.idAttr }='${ this.to }']`]);

    return elements[1];
  }

  _getBodyPosition() {
    return document.body.getBoundingClientRect().top;
  }

  _getYCoordinate = () => {
    return this._getTargetElement().offsetTop + this._getTargetElement().offsetHeight;
  }

  _removeClassName(className) {
    // Выбираем массив элементов для того, чтобы удалить класс «подсветки»
    let highlightedElements = document.querySelectorAll(`.${ className }`);

    // Пробегаемся по элементам
    for (let i = 0; i < highlightedElements.length; i++) {
      // http://stackoverflow.com/questions/195951/change-an-elements-class-with-javascript
      highlightedElements[i].classList.remove(className);
    }
  }

  _addClassNameToTarget(className) {
    const targetElement = this._getTargetElement();

    targetElement.classList.add(className);
  }
}

export default ScrollTo;
