import React, { useState } from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { theme } from '@components/styled';

import { OpenDrawer } from '@components/openDrawer';
import { StaticSidebar } from '../../staticSidebar/staticSidebar';

import s from './menuButton.style';

interface Props {
  checkWidth?: number;
}

export const MenuButton = ({ checkWidth }: Props) => {
  const [showHeader, setShowHeader] = useState(false);

  const handleClick = () => {
    setShowHeader(true);
  };

  const handleClickOutside = () => {
    setShowHeader(false);
  };

  const renderDrawer = () => {
    if (!showHeader) {
      return null;
    }

    return (
      <OpenDrawer
        shouldAnimate
        anchor="left"
        component={<StaticSidebar />}
        onClickOutside={handleClickOutside}
      />
    );
  };

  const hamburgerIconPath = iconsPath.get('hamburger');

  return (
    <>
      <s.Root
        checkWidth={checkWidth}
        onClick={handleClick}
      >
        <Icon
          path={hamburgerIconPath}
          color={theme.colors.stone}
        />
      </s.Root>
      {renderDrawer()}
    </>
  );
};

export default MenuButton;
