import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { EditViewConnect } from './editView.connect';

import s from './editViewPopover.style';

interface InputComponentProps {
  isActive: boolean;
}

const InputComponent = (props: InputComponentProps) => {
  const iconPath = iconsPath.get('settings');

  return (
    <s.IconBlock>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

export const EditViewPopover = () => (
  <BaseDropdown
  // @ts-ignore
    placement="bottom-end"
    renderInput={(p) => <InputComponent {...p} />}
    renderMenu={(p) => <EditViewConnect {...p} />}
  />
);

export default EditViewPopover;
