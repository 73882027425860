import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchDocumentType } from '../documentType/redux/fetchDocumentType';
import { fetchSettings as fetchTypeIdSettings } from '../documentType/redux/fetchSettings';
import { updateTableSettings as updateTypeIdTable }
  from '../documentType/header/settingsPopover/redux/updateTableSettings';
import { updateView as updateTypeIdView } from '../documentType/header/editViewPopover/redux/updateView';

import { fetchCaseType } from '../caseType/redux/fetchCaseType';
import { fetchSettings as fetchCaseTypeSettings } from '../caseType/redux/fetchSettings';
import { updateTableSettings as updateCaseTypeTable }
  from '../caseType/header/settingsPopover/redux/updateTableSettings';
import { updateView as updateCaseTypeView } from '../caseType/header/editViewPopover/redux/updateView';

import { fetchDefinitionContentType } from '../definitionContentType/redux/fetchDefinitionContentType';
import { fetchSettings as fetchDefinitionContenttypeSettings } from '../definitionContentType/redux/fetchSettings';
import { updateTableSettings as updateDefinitionContentTypeTable }
  from '../definitionContentType/header/settingsPopover/redux/updateTableSettings';
import { updateView as updateDefinitionContentTypeView }
  from '../definitionContentType/header/editViewPopover/redux/updateView';
import { resetItems as definitionContentTypeResetItems } from '../definitionContentType/redux/resetItems';

import { fetchDisputeCategory } from '../disputeCategory/redux/fetchDisputeCategory';
import { fetchSettings as fetchCategoryDisputeIdSettings } from '../disputeCategory/redux/fetchSettings';
import { updateTableSettings as updateCategoryDisputeIdTable }
  from '../disputeCategory/header/settingsPopover/redux/updateTableSettings';
import { updateView as updateCategoryDisputeIdView }
  from '../disputeCategory/header/editViewPopover/redux/updateView';
import { resetItems as categoryDisputeIdResetItems } from '../disputeCategory/redux/resetItems';

import { fetchYear } from '../date/redux/fetchYear';
import { fetchMonth } from '../date/redux/fetchMonth';
import { fetchSettings as fetchDateSettings } from '../date/redux/fetchSettings';
import { updateTableSettings as updateDateTableSettings }
  from '../date/header/settingsPopover/redux/updateTableSettings';
import { updateView as updateDateView } from '../date/header/editViewPopover/redux/updateView';

export const reducerBuilder = new ReducerBuilder([
  fetchDocumentType,
  fetchTypeIdSettings,
  updateTypeIdTable,
  updateTypeIdView,
  fetchCaseType,
  fetchCaseTypeSettings,
  updateCaseTypeTable,
  updateCaseTypeView,
  fetchDefinitionContentType,
  fetchDefinitionContenttypeSettings,
  updateDefinitionContentTypeTable,
  updateDefinitionContentTypeView,
  definitionContentTypeResetItems,
  fetchDisputeCategory,
  fetchCategoryDisputeIdSettings,
  updateCategoryDisputeIdTable,
  updateCategoryDisputeIdView,
  categoryDisputeIdResetItems,
  fetchYear,
  fetchMonth,
  fetchDateSettings,
  updateDateTableSettings,
  updateDateView,
]);

export default reducerBuilder;
