import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
`;

style.Menu = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
`;

style.Actions = styled.div`
  font-size: 13px;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: flex-end;
  height: 48px;
`;

style.Icon = styled.div`
  margin-left: -4px;
`;

style.Title = styled.div`
  font-size: 11px;
  padding-bottom: 13px;
  font-family: 'Roboto-Regular';
  color: ${(props) => props.theme.colors.silver};
  text-transform: uppercase;
`;

style.ItemTitle = styled.div`
  padding-left: 7px;
  font-size: 13px;
  font-family: 'Roboto-Regular';
`;

style.FilterItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 17px 3px;

  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Item = styled.div`
  position: relative;
  user-select: none;
  padding-bottom: 8px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;
`;

export default style;
