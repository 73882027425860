import React from 'react';

import { Item } from './item';

import type {
  Id,
  ItemProps,
} from './item';

import s from './list.style';

export interface ListProps {
  items: ItemProps[];
  activeId: Id;
  onItemClick: (id: Id) => void;
}

export class List extends React.PureComponent<ListProps> {

  renderItem = (item) => (
    <Item
      key={item.projectId}
      item={item}
      activeId={this.props.activeId}
      onItemClick={this.props.onItemClick}
    />
  );

  renderLayout() {
    return (
      <s.List>
        <s.Group>
          <s.ColumnDocument />
        </s.Group>
        <s.Body>{this.props.items.map(this.renderItem)}</s.Body>
      </s.List>
    );
  }

  renderContent() {
    if (!this.props.items.length) {
      return null;
    }

    return this.renderLayout();
  }

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default List;
