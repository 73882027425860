import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  padding: 44px 0;
  margin-bottom: 16px;
`;

style.ContentContainer = styled.div`
  margin-top: 24px;
  font: 13px Roboto-Regular;
  line-height: 16px;
  color: ${(props) => props.theme.colors.stone};
  white-space: pre-line;
  padding: 0 64px;
`;

export default style;
