import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 213px;
  height: 48px;
  cursor: pointer;
  margin-top: 28px;
  background-color: ${(props) => props.theme.colors.blue};
`;

style.Text = styled.div`
  font-family: Roboto-Medium;
  font-size: 13px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
`;

export default style;
