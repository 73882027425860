export const authorPackagesPath = `M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22
C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12
C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z
M15.9237722,6.8113167 C16.6699958,6.56257551 17.3815781,7.22893215 17.2180833,7.9699508 L17.1886833,8.07622777
 L15.0686833,14.4362278 C14.9815902,14.6975071 14.7913057,14.9095649 14.5450534,15.0251775 L14.4362278,15.0686833
  L8.07622777,17.1886833 C7.33000419,17.4374245 6.61842188,16.7710679 6.78191665,16.0300492 L6.8113167,15.9237722
   L8.9313167,9.56377223 C9.01840983,9.30249286 9.2086943,9.09043511 9.45494661,8.97482249 L9.56377223,8.9313167
    L15.9237722,6.8113167 Z M14.659,9.341 L10.67,10.67 L9.341,14.659 L13.329,13.329 L14.659,9.341 Z`;

export default authorPackagesPath;
