import React from 'react';
import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { BaseInput } from '../baseInput/baseInput';

import { List } from './list';

import s from './contains.style';

type Title = 'Содержит' | 'Не содержит';
type HistoryTitle = ' = ' | ' ≠ ';

interface ContainItem {
  id: number;
  title: Title;
  historyTitle: HistoryTitle;
  contains: boolean;
}

const containsList: ContainItem[] = [
  {
    id: 1,
    title: 'Содержит',
    historyTitle: ' = ',
    contains: true,
  },
  {
    id: 2,
    title: 'Не содержит',
    historyTitle: ' ≠ ',
    contains: false,
  },
];

export const containsMap = new Map();
containsMap.set(true, containsList[0]);
containsMap.set(false, containsList[1]);

export interface ContainsProps {
  contains: boolean;
  onChange: (contains: any) => void;
}

export const Contains = (props: ContainsProps) => (
  <s.BlockType>
    <BaseDropdown
      renderInput={(p) => <BaseInput value={containsMap.get(props.contains).title} {...p} />}
      renderMenu={
        (p) => (
          <List
            activeItem={containsMap.get(props.contains)}
            items={containsList}
            onChange={props.onChange}
            {...p}
          />
        )
      }
    />
  </s.BlockType>
);

export default Contains;
