import { Map } from 'immutable';

type Id = number;

export interface Bookmark {
  id: Id;
  title: string;
  text: string;
  path: string;
  commentary: string;
}

export interface FetchedPageInfo {
  per_page: number;
  total_pages: number;
  page: number;
}

interface Page {
  items: Id[];
  itemsMap: Map<Id, Bookmark>;
}

type Pages = Record<number, Page>;

interface State {
  pages: Pages;
  totalPages: number;
  currentPageNumber: number;
  perPage: number;
  loading: boolean;
  pageLoading: boolean;
  downloading: boolean;
}

export class StateBuilder {

  pages: Pages = {};

  perPage: number = 25;

  totalPages: number = 1;

  currentPageNumber: number = 1;

  loading: boolean = true;

  pageLoading: boolean = true;

  downloading: boolean = false;

  static createState(): State {
    return {
      pages: {},
      perPage: 25,
      totalPages: 1,
      currentPageNumber: 1,
      loading: true,
      pageLoading: true,
      downloading: false,
    };
  }

  private prepareBookmark = (bookmark) : Bookmark => ({
    id: bookmark.paragraphId,
    title: bookmark.title,
    text: bookmark.text,
    path: bookmark.path,
    commentary: bookmark.commentary,
  });

  private prepareBookmarks = (fetchedBookmarks): Page => {
    const items = [];
    let itemsMap = Map();

    fetchedBookmarks.forEach((bookmark) => {
      items.push(bookmark.id);
      itemsMap = itemsMap.set(bookmark.id, this.prepareBookmark(bookmark));
    });

    return {
      items,
      itemsMap,
    } as Page;
  };

  private findBookmark = (id: string) => {
    let page = null;
    const pages = Object.keys(this.pages);
    const pagesLength = pages.length - 1;
    for (let i = 0; i <= pagesLength; i += 1) {
      const currentPage = pages[i];
      if (this.pages[currentPage].itemsMap.get(id)) {
        page = currentPage;
      }
    }

    return page;
  };

  public createState(state: State) {
    this.pages = { ...state.pages };
    this.perPage = state.perPage;
    this.totalPages = state.totalPages;
    this.currentPageNumber = state.currentPageNumber;
    this.loading = state.loading;
    this.pageLoading = state.pageLoading;
    this.downloading = state.downloading;
  }

  public cleanPages() {
    this.pages = {};
    this.perPage = 25;
    this.totalPages = 0;
    this.currentPageNumber = 1;
  }

  public setCurrentPageNumber(page: number) {
    this.currentPageNumber = page;
  }

  public addPage(page, bookmarks: Bookmark[]) {
    this.pages = {
      ...this.pages,
      [page]: this.prepareBookmarks(bookmarks),
    };
  }

  public setPageInfo(pageInfo: FetchedPageInfo) {
    this.perPage = pageInfo.per_page;
    this.totalPages = pageInfo.total_pages;
    this.currentPageNumber = pageInfo.page;
  }

  public deleteBookmark(id) {
    const page = this.findBookmark(id);
    if (!page) {
      return;
    }

    const newItems = this.pages[page].items.filter((item) => item !== id);
    const newItemsMap = this.pages[page].itemsMap.delete(id);

    this.pages = {
      ...this.pages,
      [page]: {
        ...this.pages[page],
        items: newItems,
        itemsMap: newItemsMap,
      },
    };
  }

  public addCommentary(params) {
    const page = this.findBookmark(params.id);
    if (!page) {
      return;
    }

    const bookmark = this.pages[page].itemsMap.get(params.id);

    const newBookmark = {
      ...bookmark,
      commentary: params.commentary,
    };

    const newItemsMap = this.pages[page].itemsMap.set(params.id, newBookmark);

    this.pages = {
      ...this.pages,
      [page]: {
        ...this.pages[page],
        itemsMap: newItemsMap,
      },
    };
  }

  public setLoading(value: boolean) {
    this.loading = value;
  }

  public setPageLoading(value: boolean) {
    this.pageLoading = value;
  }

  public setDownloading(value: boolean) {
    this.downloading = value;
  }

  public getState(): State {
    return {
      pages: this.pages,
      perPage: this.perPage,
      currentPageNumber: this.currentPageNumber,
      totalPages: this.totalPages,
      loading: this.loading,
      pageLoading: this.pageLoading,
      downloading: this.downloading,
    };
  }

}

export default StateBuilder;
