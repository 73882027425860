import { connect } from 'react-redux';

import { toggleSelectionRule } from './redux/toggleSelectionRule';

import { Documents } from './documents';

const mapStateToProps = (state) => {
  const id = state.authorPackages.packages.activeItemId;
  const {
    layout,
    loading,
    totalDocuments,
    pageLoading,
  } = state.authorPackages.documents;

  return {
    id,
    isEmpty: !totalDocuments,
    layout,
    loading,
    pageLoading,
    processingStatus: state.authorPackages.processingStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeSelectionRule() {
    dispatch(toggleSelectionRule.createAction());
  },
});

export const DocumentsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents);

export default DocumentsConnect;
