import { Dispatch } from 'redux';

import { httpFetchAuthorPackageDocuments } from './fetchDocuments.http';

import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

export class FetchDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id?: number, pageNumber?: number }) {
    const state = this.getState().authorPackages;
    const sourceId = params.id || state.id;
    const pageNumber = params.pageNumber || state.documents.currentPageNumber;

    const hasPage = state.documents.pages[pageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber(pageNumber);

      return;
    }

    const requestParams = {
      sourceId,
      pageNumber,
    };

    this.dispatch(pageLoadingStart.createAction());

    try {
      const result = await httpFetchAuthorPackageDocuments(requestParams);
      this.dispatchGetDocumentsSucceed(sourceId, pageNumber, result);
      this.dispatchChangePageNumber(pageNumber);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetDocumentsSucceed(sourceId, pageNumber, result) {
    const actionData = {
      sourceId,
      pageNumber,
      result,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export function fetchDocumentsThunk(dispatch, params) {
  const thunk = new FetchDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default fetchDocumentsThunk;
