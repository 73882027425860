import React from 'react';
import s from './item.style';

interface CellItemProps {
  isEllipsis: boolean
  isEmpty?: boolean
  isHeaderText?: boolean
  isSortActive?: boolean
  textContent: JSX.Element
}

export const CellItemWidthItem = (props: CellItemProps) => {
  const { isEmpty, isEllipsis, isHeaderText, isSortActive, textContent } = props;
  const renderBodyText = () => (
    <s.BodyText
      textAlign="end"
      isEmpty={isEmpty}
      isEllipsis={isEllipsis}
    >
      {textContent}
    </s.BodyText>
  );

  const renderHeaderText = () => (
    <s.HeaderText
      textAlign="end"
      isEmpty={isEmpty}
      isEllipsis={isEllipsis}
      isSortActive={isSortActive}
    >
      {textContent}
    </s.HeaderText>
  );

  return (
    <s.ColumnWidthItem>
      <s.CellContainer isNoWrap>
        { isHeaderText ? renderHeaderText() : renderBodyText() }
      </s.CellContainer>
    </s.ColumnWidthItem>
  );
};

CellItemWidthItem.defaultProps = {
  isEmpty: false,
  isHeaderText: false,
  isSortActive: false,
};

export default CellItemWidthItem;
