import { Map } from 'immutable';

import {
  Id,
  Item,
} from './item';

export enum FolderType {
  projects = 'projects',
  underControl = 'under_control',
  calendar = 'calendar',
  comments = 'comments',
}

export type IdType = Id | FolderType.underControl | FolderType.calendar | FolderType.comments;

interface State {
  underControl: {
    id: 'under_control';
    title: 'Проекты на контроле';
    count: number;
  },
  folders: {
    itemsList: Id[];
    itemsMap: Map<Id, Item>;
  },
  activeItemId: IdType;
  previewId: Id;
  foldersLoading: boolean;
  underControlLoading: boolean;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      underControl: {
        id: FolderType.underControl,
        title: 'Проекты на контроле',
        count: null,
      },
      folders: {
        itemsList: [],
        itemsMap: Map(),
      },
      activeItemId: null,
      previewId: null,
      foldersLoading: true,
      underControlLoading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  public addItem(params: { id: number; name: string; count: number; role: string; }) {
    const item = new Item();
    item.buildItem({
      id: params.id,
      role: params.role,
      title: params.name,
      count: params.count,
    });

    const itemsList = [...this.state.folders.itemsList, item.getId()];
    const itemsMap = this.state.folders.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
      folders: {
        itemsList,
        itemsMap,
      },
    };
  }

  public addItems(items) {
    items.forEach((item) => this.addItem({
      id: item.id,
      name: item.title,
      role: item.role,
      count: Number(item.counter),
    }));
  }

  public changeActiveItemId(id: IdType) {
    this.state = {
      ...this.state,
      activeItemId: id,
    };
  }

  public setPreviewId(id: Id) {
    this.state = {
      ...this.state,
      previewId: id,
    };
  }

  public updateItemTitle(params: { id: Id, title: string }) {
    const currentItem = this.state.folders.itemsMap.get(params.id);
    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      role: currentItem.getRole(),
      count: currentItem.getCounter(),
    });

    item.setTitle(params.title);

    this.state.folders.itemsMap = this.state.folders.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
    };
  }

  public increaseCounter(params: { id: Id }) {
    const currentItem = this.state.folders.itemsMap.get(params.id);
    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      role: currentItem.getRole(),
      count: currentItem.getCounter(),
    });

    const count = currentItem.getCounter() + 1;
    item.setCounter(count);

    this.state.folders.itemsMap = this.state.folders.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
    };
  }

  public decreaseCounter(params: { id: Id }) {
    const currentItem = this.state.folders.itemsMap.get(params.id);
    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      role: currentItem.getRole(),
      count: currentItem.getCounter(),
    });

    const count = currentItem.getCounter() - 1;
    item.setCounter(count);

    this.state.folders.itemsMap = this.state.folders.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
    };
  }

  private createItem(id) {
    const currentItem = this.state.folders.itemsMap.get(id);

    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      role: currentItem.getRole(),
      count: currentItem.getCounter(),
    });

    return item;
  }

  public removeItem(id: Id): void {
    const index = this.state.folders.itemsList.findIndex((item) => item === id);
    const itemsList = [...this.state.folders.itemsList];
    itemsList.splice(index, 1);
    this.state.folders.itemsList = itemsList;
    this.state.folders.itemsMap = this.state.folders.itemsMap.delete(id);
  }

  public setFoldersLoadingFalse() {
    this.state = {
      ...this.state,
      foldersLoading: false,
    };
  }

  public setUnderControlCounter(count: number) {
    this.state = {
      ...this.state,
      underControl: {
        ...this.state.underControl,
        count,
      },
    };
  }

  public setUnderControlLoadingFalse() {
    this.state = {
      ...this.state,
      underControlLoading: false,
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
