import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './bookmarkButton.style';

interface Props {
  itemsCount: number;
  loading: boolean;
  shouldUpdate: boolean;
  isActive: boolean;
  disabled: boolean;
  closePopover: () => void;
}

export class BookmarkButton extends React.PureComponent<Props> {

  refRipple = React.createRef();

  componentDidUpdate(prevProps) {
    const updated = prevProps.itemsCount !== this.props.itemsCount;
    if (!updated) {
      return;
    }

    if (!this.props.shouldUpdate) {
      return;
    }

    const isFirst = !prevProps.itemsCount && !!this.props.itemsCount;
    if (isFirst) {
      return;
    }

    if (!this.props.itemsCount) {
      return;
    }

    this.animateBubble();
  }

  renderRipple = () => {
    if (!this.props.itemsCount) {
      return null;
    }

    return <s.Ripple ref={this.refRipple} />;
  };

  animateBubble = () => {
    // @ts-ignore
    this.refRipple.current.classList.add('animate');
    setTimeout(() => {
      // @ts-ignore
      this.refRipple.current.classList.remove('animate');
    }, 500);
  };

  handleClick = (e) => {
    if (this.props.loading) {
      e.stopPropagation();
    }

    if (!this.props.shouldUpdate) {
      this.props.closePopover();
    }
  };

  render() {
    return (
      <s.Root
        isActive={this.props.isActive}
        onClick={this.handleClick}
        loading={this.props.loading}
        disabled={this.props.disabled}
      >
        <Icon path={iconsPath.get('bookmarksList')} color="#6c7b89" />
        {this.renderRipple()}
      </s.Root>
    );
  }

}

export default BookmarkButton;
