import { connect } from 'react-redux';

import { separator } from '@components/separator';

import { Format } from '../../redux/settings';

import { documentType } from '../../redux/state';

import { addSearchBlockThunk } from '../redux/addSearchBlocks.thunk';

import { Item } from './item';

export interface DataFromView {
  columnId: string;
  block: {
    id: string;
    title: string;
  };
}

function prepareColumns(data, settings, options) {
  const columns = [];

  const title = {
    id: 'title',
    title: data.title || '',
  };

  // @ts-ignore
  columns.push(title);

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      const isPercent = options.format === Format.percent;
      const value = separator(data[options.type][column][options.format]);
      let cellData = value;
      if (isPercent) {
        cellData = `${value}%`;
      }

      columns.push({
        // @ts-ignore
        id: column,
        // @ts-ignore
        title: cellData,
      });
    }
  });

  return columns;
}

const mapStateToProps = (state, ownProps) => {
  const data = state.searchResult.analysis.itemsMap[documentType.getId()];
  const { type, format } = data.layout;
  const document = data.items.itemsMap.get(ownProps.id);

  const settings = data.layout.columns[type];
  const columns = prepareColumns(document, settings, { type, format });

  return {
    item: {
      id: document.id,
      title: document.title,
    },
    columns,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddBlock(data: DataFromView) {
    addSearchBlockThunk(dispatch, data);
  },
});

export const ItemConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
