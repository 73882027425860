import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';

import { addCalendar } from './addCalendar';
import { addEmptyCalendar } from './addEmptyCalendar';
import { setActiveDate } from './setActiveDate';
import { setNearestActiveDate } from './setNearestActiveDate';
import { setCalendarsLoading } from './setCalendarsLoading';
import { setEventsLoading } from './setEventsLoading';
import { setHasProjects } from './setHasProjects';
import { resetEvents } from './resetEvents';
import { fetchEvents } from './fetchEvents';
import { resetState } from './resetState';

export const reducerBuilder = new ReducerBuilder([
  addCalendar,
  addEmptyCalendar,
  setActiveDate,
  setNearestActiveDate,
  setCalendarsLoading,
  setHasProjects,
  setEventsLoading,
  fetchEvents,
  resetEvents,
  resetState,
]);

reducerBuilder.setInitialState(StateBuilder.createState());

export default reducerBuilder;
