import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.article`
  display: flex;
  flex-direction: column;
  width: 256px;
  height: 100%;
  flex-shrink: 0;
  border-right: solid 1px ${(props) => props.theme.colors.borderColor};
  background-color: ${(props) => props.theme.colors.white};
`;

style.Folders = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
`;

export default style;
