import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  positionX: number;
}

const style: any = {};

style.Root = styled.div`
  width: 100%;
  height: 256px;
  overflow: hidden;
  display: flex;
  position: relative;
`;

style.Container = styled.div`
  display: flex;
  transform: translate(${(props: Props) => props.positionX}px, 10px);
  transition: 500ms;
  ${(props: Props) => props.positionX === -560 && css`
    transition: 0ms;
  `}
`;

style.Arrow = styled.div`
  position: absolute;
  background-color: ${(props: Props) => props.theme.colors.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  top: calc(50% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

style.ArrowLeft = styled(style.Arrow)`
  left: 8px;
`;

style.ArrowRight = styled(style.Arrow)`
  right: 8px;
`;

export default style;
