import { connect } from 'react-redux';

import { PhasesTab } from './phasesTab';

import { getPreview } from './preview.connect';

const mapStateToProps = (state, ownProps) => {
  const preview = getPreview(ownProps.id, state.regulation.projects.projects);

  return {
    items: preview.phases.items,
    loading: preview.phases.loading,
  };
};

export const PhasesTabConnect = connect(
  mapStateToProps,
)(PhasesTab);

export default PhasesTabConnect;
