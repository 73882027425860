import styled from 'styled-components';

const style: any = {};

style.RemoveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  padding: 0;

  opacity: 0;
  background: transparent;
  cursor: pointer;

  transition: opacity .3s;

  &:hover {
    background: rgba(65, 131, 215, 0.07);
  }


  &:focus {
    outline: none;
  }
`;

export default style;
