import { store } from '@redux/store';

import { StateBuilder } from './state';

export const resetToInitialReduce = (state: ReturnType<typeof store.getState>) => {
  if (!state) {
    return state;
  }

  return {
    ...StateBuilder.createState(),
  };
};

export default resetToInitialReduce;
