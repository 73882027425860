import { Map } from 'immutable';

function prepareModel(data) {
  return data.reduce((acc, item) => {
    acc.itemsList = acc.itemsList.concat(item.id);

    const itemData = {
      id: item.id,
      showColumn: item.shouldRender,
      title: item.title,
    };

    acc.itemsMap = acc.itemsMap.set(item.id, itemData);

    return acc;
  }, { itemsList: [], itemsMap: Map() });
}

export const updateTableSettingsReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const columns = prepareModel(action.data);

  return {
    ...state,
    documents: {
      ...state.documents,
      layout: {
        ...state.documents.layout,
        tableView: {
          ...state.documents.layout.tableView,
          columns,
        },
      },
    },
  };
};

export default updateTableSettingsReduce;
