import styled from 'styled-components';

const style: any = {};

style.Header = styled.div`
  border-bottom: 1px solid rgb(223, 226, 230);
  display: flex;
  padding: 16px;
  justify-content: space-between;
`;

style.Title = styled.p`
  color: rgb(34, 49, 63);
  margin: auto 0;
  font-size: 17px;
`;

export default style;
