import { StateBuilder } from './state';

import type { MessageDTO } from './state';

export type ActionData = MessageDTO;

export interface Action {
  type: string;
  data: ActionData;
}

export const updateMessageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.chat);

  newState.updateMessageFromId(action.data);

  return {
    ...state,
    chat: newState.getState(),
  };
};

export default updateMessageReduce;
