export const prepareFiltersFailReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    query: {
      blocksList: [],
      blocksMap: new Map(),
      smartFilter: {
        ...state.query.smartFilter,
        preparing: false,
        prepareFail: true,
      },
    },
  };
};

export default prepareFiltersFailReduce;
