import { RoutePath } from '@router/path';

const isActive = (regexp) => (match, location) => {
  const path = location.pathname.split('/')[3];

  return regexp.test(path);
};

export const navigationItems = [
  {
    id: 1,
    text: 'Документы',
    link: RoutePath.documents,
    isActive: isActive(/documents/),
  },
  {
    id: 2,
    text: 'Принявшие лица',
    link: RoutePath.courts,
    isActive: isActive(/judges/),
  },
  {
    id: 3,
    text: 'Нормы права',
    link: RoutePath.law,
    isActive: isActive(/law/),
  },
  {
    id: 4,
    text: 'Анализ',
    link: RoutePath.analysis,
    isActive: isActive(/analysis/),
  },
];

export default navigationItems;
