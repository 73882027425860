import { addDocumentToPackageReduce } from './addDocumentToPackage.reduce';

import type { ActionData } from './addDocumentToPackage.reduce';

const actionType = 'PACKAGE/ADD_DOCUMENT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addDocumentToPackage = {
  actionType,
  createAction,
  reduce: addDocumentToPackageReduce,
};

export default addDocumentToPackage;
