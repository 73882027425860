import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Source } from '@router/path.rest';

import { notifierManager } from '@components/notification';

import { increaseDocumentsCount } from '../packages/increaseDocumentsCount';
import { decreaseDocumentsCount } from '../packages/decreaseDocumentsCount';
import { httpAddDocumentToPackage } from '../packages/addDocumentToPackage.http';
import { httpRemoveDocument } from '../packages/removeDocument.http';

import { addDocumentToPackage } from '../searchPackages/addDocumentToPackage';
import { removeDocumentFromPackage } from '../searchPackages/removeDocumentFromPackage';

import { increasePackageCount } from './increasePackageCount';
import { decreasePackageCount } from './decreasePackageCount';

import { changePreviewDocument } from '../../workspace/documents/preview/redux/changePreviewDocument';

import { resetToInitial } from './resetToInitial';
import { pageLoadingStart } from './pageLoading';

import { fetchPackageDocumentsThunk } from './fetchDocuments.thunk';

export class AddDocumentToPackageThunk {

  getState;

  private notificationId = 'REMOVE_FROM_PACKAGE_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { documentId: number; packageId: number }) {
    const state = this.getState().packages.searchResult;
    const pageNumber = this.getState().packages.documents.previewDocument.currentPageNumber;

    const { selected } = state.itemsMap.get(params.packageId);

    if (!selected) {
      this.dispatchIncreasePackageCount(params.documentId, pageNumber);
      this.dispatchIncreaseDocumentsCount(params.packageId);
      await this.addDocumentToPackage(params);

      return;
    }

    this.dispatchDecreaseDocumentsCount(params.packageId);

    const currentPackageId = this.getState().packages.packages.activeItemId;
    const isCurrentPackage = params.packageId === currentPackageId;
    if (isCurrentPackage) {
      this.dispatch(pageLoadingStart.createAction());
      await this.removeDocumentFromPackage(params);
      await this.removeDocumentFromCurrentPage();

      return;
    }

    await this.removeDocumentFromPackage(params);
    this.dispatchDecreasePackageCount(params.documentId, pageNumber);
  }

  private async removeDocumentFromCurrentPage() {
    const {
      currentPageNumber,
      pages,
    } = this.getState().packages.documents;

    const itemsLength = pages[currentPageNumber].items.length;

    let pageNumber = currentPageNumber;

    if (itemsLength === 1) {
      pageNumber = currentPageNumber - 1;
    }

    if (!pageNumber) {
      pageNumber = 1;
    }

    this.dispatch(resetToInitial.createAction());

    await fetchPackageDocumentsThunk(this.dispatch, { pageNumber });

    this.dispatch(changePreviewDocument.createAction({ documentId: null }));
  }

  private async addDocumentToPackage(params) {
    sendAmplitudeData('add_to_collection');
    Userpilot.track('add_to_collection');
    try {
      const sourceId = this.getState().packages.packages.activeItemId;
      this.dispatchAddSucceed(params);
      const requestParams = {
        ...params,
        sourceId,
        source: Source.package,
      };

      await httpAddDocumentToPackage(requestParams);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private async removeDocumentFromPackage(params) {
    try {
      this.dispatchRemoveSucceed(params);
      await httpRemoveDocument(params);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
      throw Error(error.status);
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchAddSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(addDocumentToPackage.createAction(actionData));
  }

  private dispatchIncreasePackageCount(documentId, pageNumber) {
    this.dispatch(increasePackageCount.createAction({ documentId, pageNumber }));
  }

  private dispatchIncreaseDocumentsCount(id) {
    this.dispatch(increaseDocumentsCount.createAction({ id }));
  }

  private dispatchDecreasePackageCount(documentId, pageNumber) {
    this.dispatch(decreasePackageCount.createAction({ documentId, pageNumber }));
  }

  private dispatchDecreaseDocumentsCount(id) {
    this.dispatch(decreaseDocumentsCount.createAction({ id }));
  }

  private dispatchRemoveSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(removeDocumentFromPackage.createAction(actionData));
  }

}

export function addDocumentToPackageThunk(dispatch, params) {
  const thunk = new AddDocumentToPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddDocumentToPackageThunk;
