import { decreaseFoldersCountReduce } from './decreaseFoldersCount.reduce';

import type { ActionData } from './decreaseFoldersCount.reduce';

const actionType = 'REGULATION/PROJECTS/DECREASE_FOLDER_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const decreaseFoldersCount = {
  actionType,
  createAction,
  reduce: decreaseFoldersCountReduce,
};

export default decreaseFoldersCount;
