import { TabIds } from '../../redux/state';

export interface ActionData {
  tabId: TabIds;
}

interface Action {
  type: string;
  data: ActionData;
}

export const setActiveTabReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    layout: {
      ...state.layout,
      sidebar: {
        ...state.layout.sidebar,
        activeTabId: action.data.tabId,
      },
    },
  };
};

export default setActiveTabReduce;
