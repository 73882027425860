import React from 'react';

import { CloseButton } from '@components/buttons';
import { Bookmark } from '@layouts/bookmarks/redux/state';

import { GlobalSettings } from './globalSettings';

import { BookmarksListItem } from './bookmarksListItem';

import s from './bookmarksList.style';

interface BookmarksListProps {
  onMount: () => void;
  onUnmount: () => void;
  closePopover: () => void;
  items: Bookmark[];
  disabled: boolean;
}

export class BookmarksList extends React.PureComponent<BookmarksListProps> {

  componentDidMount() {
    this.props.onMount();
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  renderItem = (item) => <BookmarksListItem key={item.paragraphId} {...item} />;

  renderHeader() {
    return (
      <s.Header>
        <s.HeaderAction>
          <CloseButton
            color="#4183d7"
            onClick={this.props.closePopover}
          />
        </s.HeaderAction>
        <s.TitleContainer>
          <s.HeaderTitle>
            Закладки
          </s.HeaderTitle>
        </s.TitleContainer>
        <s.HeaderAction>
          <GlobalSettings disabled={this.props.disabled} />
        </s.HeaderAction>
      </s.Header>
    );
  }

  renderListItems() {
    if (!this.props.items.length) {
      return <s.Empty>Закладок нет</s.Empty>;
    }

    return this.props.items.map(this.renderItem);
  }

  render() {
    return (
      <s.Root>
        {this.renderHeader()}
        <s.List>
          {this.renderListItems()}
        </s.List>
      </s.Root>
    );
  }

}

export default BookmarksList;
