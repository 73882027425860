import { fetchSimilarDocumentsReduce } from './fetchSimilarDocuments.reduce';

import type { ActionData } from './fetchSimilarDocuments.reduce';

const actionType = 'SIMILAR/DOCUMENTS/FETCH';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSimilarDocuments = {
  actionType,
  createAction,
  reduce: fetchSimilarDocumentsReduce,
};

export default fetchSimilarDocuments;
