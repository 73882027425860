import React from 'react';

import s from './icon.style';

export interface IconProps {
  size?: number;
  path?: string | null;
  viewBoxSize?: number | null;
  points? : string | null;
  transform?: string;
  color?: string;
}

const defaultProps = {
  color: '#22313f',
  size: 24,
  viewBoxSize: null,
  path: null,
  points: null,
  transform: '',
};

export const Icon: React.FC<IconProps> = ({ ...props }) => (
  <s.Icon
    color={props.color}
    size={props.size}
  >
    <svg
      viewBox={props.viewBoxSize ? `0 0 ${props.viewBoxSize} ${props.viewBoxSize}` : '0 0 24 24'}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!!props.path && <path d={props.path} />}
      {!!props.points && <polygon transform={props.transform} points={props.points} />}
    </svg>
  </s.Icon>
);

Icon.defaultProps = defaultProps;

export default Icon;
