import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { toggleFamiliarize } from './toggleFamiliarize';
import { httpToggleFamiliarize } from './toggleFamiliarize.http';
import { Id } from './state';

interface Params {
  id: Id;
}

export class ToggleFamiliarizeThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {

    this.marketingEvent(params.id);

    this.dispatchToggleFamiliarize(params.id);

    const state = this.getState();
    const { familiarize } = state.regulation.projects.projects.itemsMap.get(params.id);

    try {
      const requestParams = {
        projectId: params.id,
        familiarize,
      };

      await httpToggleFamiliarize(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private marketingEvent(id) {
    sendAmplitudeData('regulation:toggle_familiarize', { id });
    Userpilot.track('regulation:toggle_familiarize', { id });
  }

  private dispatchToggleFamiliarize(id: Id) {
    this.dispatch(toggleFamiliarize.createAction({ id }));
  }

}

export function toggleFamiliarizeThunk(dispatch, params) {
  const thunk = new ToggleFamiliarizeThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default ToggleFamiliarizeThunk;
