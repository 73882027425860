import styled from 'styled-components';

const style: any = {};

style.SliderComponent = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
`;

style.SliderInputContainer = styled.div`
  display: flex;
  flex: 1;
`;

style.NumberInput = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid #e3e3e3;

  &:first-of-type {
    border: none;
  }
`;

export default style;
