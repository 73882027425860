import styled, { css } from 'styled-components';

const style: any = {};

interface RowProps {
  disable: boolean
}

style.RowTitle = styled.div`
  flex: 0 0 190px;
`;

style.RowContent = styled.div`
  align-items: center;
  display: flex;
  line-height: 13px;
`;

style.RowWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  :hover {
    .filter-remove-button {
      opacity: 1;
    }
  }
`;

style.Row = styled.div<RowProps>`
  border-bottom: 1px solid rgb(223,226,230);
  display: flex;
  cursor: pointer;
  padding: 12px 16px;
  line-height: 13px;
  width: 100%;

  :hover {
    background: rgb(246, 250, 254);
  }

  ${(props: RowProps) => props.disable && css`
    ${style.RowTitle},
    ${style.RowContent} {
      opacity: 0.35;
    }
  `}
`;

style.FilterItem = styled.span`
  line-height: 16px;

  :first-child {
    font-family: 'Roboto-Bold';
  }
`;

style.Wrapper = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
`;

style.RowText = styled.p`
  margin: 0;
`;

style.RowRoot = styled.div``;

style.SmartText = styled.div`
  display: inline;
`;

style.Smart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  margin-bottom: 8px;
`;

style.SmartTitle = styled.span`
  flex-shrink: 0;
  font-family: 'Roboto-Bold';
`;

style.SmartValue = styled.span`
  padding-left: 3px;
`;

style.Icon = styled.div`
  position: absolute;
  left: -24px;
  top: -5px;
`;

style.RowItem = styled.div`
  color: black;
  margin-bottom: 8px;

  :last-child {
    margin-bottom: 0;
  }
`;

style.RowItemWrapper = styled.div`
  display: block;
`;

style.EmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 256px;
  color: ${(props) => props.theme.colors.stone};
`;

export default style;
