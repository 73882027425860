import { RESTPath } from '@router/path.rest';
import http from '@http';

import { GetDocumentsSettings } from './fetchLaws.http';

export interface GetArticlesSettings extends GetDocumentsSettings {
  searchId: number;
  documentId: number;
}

export function httpFetchSearchDocumentArticles(settings: GetArticlesSettings) {
  const request = http.get({
    url: RESTPath.search.analytic.documents.getArticles(settings),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSearchDocumentArticles;
