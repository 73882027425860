import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchFoldersForProject } from './fetchFoldersForProject';
import { createFolderStart } from './createFolderStart';
import { createFolderSucceed } from './createFolderSucceed';
import { addProject } from './addProject';
import { removeProject } from './removeProject';
import { resetToInitial } from './resetToInitial';

export const reducerBuilder = new ReducerBuilder([
  fetchFoldersForProject,
  createFolderStart,
  createFolderSucceed,
  addProject,
  removeProject,
  resetToInitial,
]);

export default reducerBuilder;
