import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div``;

style.Icon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  transition: all .3s;

  :hover {
    background: #4183D712;
  }

  :active {
    background: #4183D726;
  }
`;

export default style;
