import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpCreateFolder(name: string) {
  const url = RESTPath.regulation.folders.create();
  const data = { name };
  const request = http.post({
    url,
    data,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpCreateFolder;
