import { connect } from 'react-redux';

import { paymentProcessThunk } from '@pages/userPages/redux/paymentProcess.thunk';

import { PaymentSelect } from './paymentSelect';

function buildPayments(presets) {
  const paymentDescriptions = [
    'Используйте все возможности\n' +
    'полной версии Caselook\n' +
    'в течение суток',
    'Оптимальное предложение\n' +
    'для тех, кто работает с судебной\n' +
    'практикой на постоянной основе',
    'Оптимальный вариант\n' +
    'для детальной подготовки\n' +
    'к нескольким спорам',
  ];

  const paymentDate = ['Сутки', 'Год', 'Месяц'];

  const paymentStars = [1, 3, 2];

  return presets.map((preset, i) => ({
    cost: preset.cost,
    stars: paymentStars[i],
    date: paymentDate[i],
    period: preset.period,
    description: paymentDescriptions[i],
    prefer: preset.prefer,
  }));

}

const mapStateToProps = (state) => ({
  paymentOptions: buildPayments(state.currentUser.paymentOptions),
});

const mapDispatchToProps = (dispatch) => ({
  onPaymentStart(period) {
    paymentProcessThunk(dispatch, { period });
  },
});

export const PaymentSelectConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentSelect);

export default PaymentSelectConnect;
