import {
  styled,
  css,
} from '@components/styled';

const style: any = {};

interface Props {
  theme: any;
}

style.FilterBlockWrap = styled.div`
  width: 100%;
  height: 48px;
`;

style.Root = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
`;

style.Form = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding: 16px 42px;
  min-width: 960px;
  max-width: 1152px;
  width: 100%;
`;

style.EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height : 88px;
  border: 2px dashed ${(props: Props) => props.theme.colors.silver};
  font-size: 13px;
  color: ${(props: Props) => props.theme.colors.silver};
`;

style.FilterBlocksContainer = styled.div`
`;

style.FiltersList = styled.div`
  position: relative;
  width: 100%;
`;

style.Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;

  &:focus {
    outline: none;
  }
`;

style.SearchControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 28px;
`;

interface ColumnProps {
  verticalAlign: string;
}

style.Column = styled.div`
  display: flex;
  width: 100%;

  ${(props: ColumnProps) => props.verticalAlign === 'right' && css`
    justify-content: flex-end;
  `}

  ${(props: ColumnProps) => props.verticalAlign === 'left' && css`
    justify-content: flex-start;
  `}
`;

style.Button = styled.div`
  display: inline-block;
`;

export default style;
