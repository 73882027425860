import {
  styled,
  css,
} from '@components/styled';

import { hover } from '@components/button/button.style';

const style: any = {};

interface Props {
  disabled: boolean;
  color: string;
  theme: any;
  needUppercase: boolean;
}

style.Root = styled.div``;

style.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 2px;

  font-size: 13px;
  font-family: 'Roboto-Medium';
  text-transform: uppercase;

  color: ${(props) => props.color || props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.white};

  cursor: pointer;

  user-select: none;

  ${hover};

  ${(props: Props) => !props.needUppercase && css`
    text-transform:none;
  `}

  ${(props: Props) => props.disabled && css`
    cursor: default;
    background-color: ${props.theme.colors.white};
    color: ${props.theme.colors.stone};

    &:hover {
      background-color: ${props.theme.colors.white};
    }

    &:active {
      background-color: ${props.theme.colors.white};
    }
  `}
`;

export default style;
