export const UserRoleType = {

  virtual: 'virtual',

  guest: 'guest',

  trial: 'trial',

  admin: 'admin',

  sales: 'sales',

  user: 'user',

  teamlead: 'teamlead',

  support: 'support',

};

interface Phone {
  number: string;
  confirmed: boolean;
}

interface Email {
  email: string;
  confirmed: boolean;
}

export interface UserStateI {
  id: number;
  role: string;
  isActive: boolean;
  licenceEndDays: number;
  licenseEndDate: string;
  licenseEndTitle: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  licenses: {
    caselook: string;
    regulation: string;
  };
  phone: Phone;
  email: Email;
  company: string;
  position: string;
  avatar: string;
  logo: string;
}

interface LicenseEndData {
  day: string;
  month: string;
  year: string;
}

export interface ActionData {
  id: number;
  role: string;
  is_license_active: boolean;
  days_count_before_license_end: number;
  license_end_title: string;
  license_end_date: null | LicenseEndData;
  first_name: string;
  last_name: string;
  patronymic: string;
  position: string;
  phone: string;
  is_phone_validated: boolean;
  email: string;
  is_email_validated: boolean;
  licenses: {
    CASELOOK: string;
    REGULATION: string;
  };
  company: string;
  avatar: string;
  logo: string;
  access_to: string[];
  paymentOptions: any;
  paymentStatus: {
    result: {
      isPayment: boolean;
    };
  };
}

export class UserState {

  state = {
    id: null,
    role: UserRoleType.guest,
    isActive: false,
    licenceEndDays: 0,
    licenseEndDate: null,
    licenseEndTitle: null,
    licenseAb: [],
    firstName: null,
    lastName: null,
    patronymic: null,
    accessTo: [],
    phone: {
      number: null,
      confirmed: false,
    },
    email: {
      email: null,
      confirmed: false,
    },
    licenses: {
      caselook: 'standart',
      regulation: 'visitor',
    },
    company: null,
    avatar: null,
    logo: null,
    position: null,
    paymentOptions: [],
    paymentInProgress: false,
  };

  getState(): UserStateI {
    return this.state;
  }

  buildState(data: ActionData) {
    this.setId(data);
    this.setRole(data);
    this.setActive(data);
    this.setAccessTo(data);
    this.setDaysCount(data);
    this.setLicenseEndDate(data);
    this.setLicenseEndTitle(data);
    this.setFirstName(data);
    this.setLastName(data);
    this.setPatronymic(data);
    this.setPhone(data);
    this.setEmail(data);
    this.setCompany(data);
    this.setPosition(data);
    this.setAvatar(data);
    this.setLogo(data);
    this.setPaymentOptions(data);
    this.setPaymentInProgress(false);
    this.setLicenses(data);
  }

  setId(data) {
    if (!data.id) {
      return;
    }

    this.state.id = data.id;
  }

  setRole(data) {
    if (!data.role) {
      return;
    }

    this.state.role = data.role;
  }

  setLicenses(data) {
    if (!data.licenses) {
      return;
    }

    if (data.licenses.CASELOOK) {
      this.state.licenses.caselook = data.licenses.CASELOOK.toLowerCase();
    }

    if (data.licenses.REGULATION) {
      this.state.licenses.regulation = data.licenses.REGULATION.toLowerCase();
    }
  }

  setActive(data) {
    if (!data.is_license_active) {
      return;
    }

    this.state.isActive = data.is_license_active;
  }

  setAccessTo(data) {
    if (!data.access_to) {
      return;
    }

    this.state.accessTo = data.access_to;
  }

  setDaysCount(data) {
    if (!data.days_count_before_license_end) {
      return;
    }

    this.state.licenceEndDays = data.days_count_before_license_end;
  }

  setLicenseEndTitle(data) {
    if (!data.license_end_title) {
      return;
    }

    this.state.licenseEndTitle = data.license_end_title;
  }

  setLicenseEndDate(data) {
    if (!data.license_end_date) {
      return;
    }

    if (!data.license_end_date.day) {
      return;
    }

    if (!data.license_end_date.month) {
      return;
    }

    if (!data.license_end_date.year) {
      return;
    }

    this.state.licenseEndDate =
      `${data.license_end_date.year}-${data.license_end_date.month}-${data.license_end_date.day}`;
  }

  setFirstName(data) {
    if (!data.first_name) {
      return;
    }

    this.state.firstName = data.first_name;
  }

  setLastName(data) {
    if (!data.last_name) {
      return;
    }

    this.state.lastName = data.last_name;
  }

  setPatronymic(data) {
    if (!data.patronymic) {
      return;
    }

    this.state.patronymic = data.patronymic;
  }

  setPhone(data) {
    this.state.phone = {
      number: data.phone || this.state.phone.number,
      confirmed: data.is_phone_validated || this.state.phone.confirmed,
    };
  }

  setEmail(data) {
    this.state.email = {
      email: data.email || this.state.email.email,
      confirmed: data.is_email_validated || this.state.email.confirmed,
    };
  }

  setCompany(data) {
    if (!data.company) {
      return;
    }

    this.state.company = data.company;
  }

  setPosition(data) {
    if (!data.position) {
      return;
    }

    this.state.position = data.position;
  }

  setAvatar(data) {
    if (!data.avatar) {
      return;
    }

    this.state.avatar = data.avatar;
  }

  setLogo(data) {
    if (!data.logo) {
      return;
    }

    this.state.logo = data.logo;
  }

  setPaymentOptions(data) {
    if (!data.paymentOptions) {
      return;
    }

    if (!data.paymentOptions.products) {
      return;
    }

    this.state.paymentOptions = data.paymentOptions.products;
  }

  setPaymentInProgress(inProgress: boolean) {
    this.state.paymentInProgress = inProgress;
  }

}

export default UserState;
