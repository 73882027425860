import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  width: 320px;
`;

style.Loader = styled.div`
  height: 48px;
  position: relative;
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

style.List = styled.div`
  max-height: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

style.DefaultContainer = styled.div``;

style.OptionsContainer = styled.div`
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

interface Props {
  theme: any;
}

style.Option = styled.div`
  padding: 12px 16px 13px 12px;
  position: relative;
  user-select: none;

  display: flex;

  color: ${(props: Props) => props.theme.colors.defaultText};

  cursor: pointer;
  align-items: flex-start;

  &:hover {
    background: #f6fafe;
  }
`;

style.OptionTitle = styled.div`
  padding-left: 8px;
  padding-top: 5px;
  font-size: 13px;
`;

style.Input = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #dadfe1;
  padding: 12px 15px;
  border-width: 0 1px 1px 0;
  display: flex;
  width: 50%;

  input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
  }
`;

export default style;
