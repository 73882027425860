import { Dispatch } from 'redux';

import { fetchFoldersThunk } from '@pages/regulation/sidebar/folders/redux/fetchFolders.thunk';
import { Id } from '@pages/regulation/workspace/redux/state';

import { setPreviewId } from '@pages/regulation/workspace/redux/setPreviewId';

import { FilterType } from '@pages/regulation/workspace/toolbar/redux/state';
import {
  httpFetchProject,
  ProjectDTO,
} from './fetchProject.http';
import { fetchProject } from './fetchProject';

import {
  httpFetchNote,
  NoteDTO,
} from './fetchNote.http';
import { fetchNote } from './fetchNote';

import {
  httpFetchPhases,
  PhasesDTO,
} from './fetchPhases.http';
import { fetchPhases } from './fetchPhases';

export class FetchProjectThunk {

  getState;

  private readonly dispatch: Dispatch;

  private id: Id = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(param: { id: Id }) {
    this.id = param.id;

    this.dispatchSetPreviewId(param.id);

    const shouldFetch = !this.getState().regulation.projects.projects.itemsMap.get(this.id).preview.project.loading;
    if (shouldFetch) {
      return;
    }

    const requestParams = {
      query: this.getState().regulation.filters.filters[FilterType.text].data.value,
    };

    const project = await this.fetchProject(requestParams);
    this.dispatchFetchProjectSucceed(project);

    const note = await this.fetchNote(requestParams);
    this.dispatchFetchNoteSucceed(note);

    const phases = await this.fetchPhases();
    this.dispatchFetchPhasesSucceed(phases);

    const { isNew } = this.getState().regulation.projects.projects.itemsMap.get(this.id);

    if (isNew) {
      fetchFoldersThunk(this.dispatch);
    }
  }

  private async fetchProject(requestParams): Promise<ProjectDTO> {
    try {
      const result = await httpFetchProject(this.id, requestParams);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchNote(requestParams): Promise<NoteDTO> {
    try {
      const result = await httpFetchNote(this.id, requestParams);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchPhases(): Promise<PhasesDTO> {
    try {
      const result = await httpFetchPhases(this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchProjectSucceed(data: ProjectDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchProject.createAction(result));
  }

  private dispatchFetchNoteSucceed(data: NoteDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchNote.createAction(result));
  }

  private dispatchFetchPhasesSucceed(data: PhasesDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchPhases.createAction(result));
  }

  private dispatchSetPreviewId(id) {
    this.dispatch(setPreviewId.createAction({ id }));
  }

}

export function fetchProjectThunk(dispatch, param: { id: Id }) {
  const thunk = new FetchProjectThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(param);
}

export default FetchProjectThunk;
