import { PlaintiffBlock } from '../../form/filterBlocks/plaintiff/redux/plaintiff';
import {
  ParticipantBlock,
  ParticipantBlockData,
} from '../../form/filterBlocks/participant/redux/participant';

export abstract class PlaintiffBlockHistory extends PlaintiffBlock {

  static transform(data: ParticipantBlockData): string {
    let result = ParticipantBlock.presetsMap[data.mode.id].title;

    if (data.participant.title) {
      result = `${result} • ${data.participant.title}`;
    }

    return result;
  }

}

export default PlaintiffBlockHistory;
