import { uncheckProjectsReduce } from './uncheckProjects.reduce';

const actionType = 'REGULATION/WORKSPACE/UNCHECK_PROJECTS';

const createAction = () => ({
  type: actionType,
});

export const uncheckProjects = {
  actionType,
  createAction,
  reduce: uncheckProjectsReduce,
};

export default uncheckProjects;
