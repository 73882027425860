import { store } from '@redux/store';

import {
  StateBuilder,
  Bookmark,
} from './state';

export type ActionData = Bookmark;

export interface Action {
  type: string;
  data: ActionData;
}

export const addBookmarkReduce = (state: ReturnType<typeof store.getState>, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.addBookmark(action.data);

  return newState.getState();
};

export default addBookmarkReduce;
