import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from '@pages/packages/sidebar/settings.style';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { PackageMenu } from '../packageMenu/packageMenu';

interface InputComponentProps {
  isActive: boolean;
}

const InputComponent = (props: InputComponentProps) => {
  const iconPath = iconsPath.get('kebab');

  return (
    <s.IconBlock>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

type PackageId = number;

interface SettingsPopoverProps {
  id: PackageId;
  onMenuVisible?: (visible: boolean) => void;
}

export const Settings = ({ id, onMenuVisible }: SettingsPopoverProps) => (
  <s.IconBlock>
    <BaseDropdown
      usePortal
      placement="bottom-end"
      onMenuVisible={onMenuVisible}
      renderInput={(p) => <InputComponent {...p} />}
      renderMenu={(p) => <PackageMenu id={id} {...p} />}
    />
  </s.IconBlock>
);

Settings.defaultProps = {
  onMenuVisible: () => {},
};

export default Settings;
