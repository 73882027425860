import { Dispatch } from 'redux';

import {
  getDays,
  getLeadingZeroDate,
} from '../utils';

import { httpFetchCalendar } from './fetchCalendar.http';
import { addEmptyCalendar } from './addEmptyCalendar';
import { addCalendar } from './addCalendar';

export interface Params {
  year: number;
  month: number;
}

export class FetchCalendarThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    const date = `${params.year}-${params.month}`;
    const shouldFetch = !this.getState().regulation.events.calendars[date];
    if (!shouldFetch) {
      return;
    }

    this.dispatchAddEmptyCalendar({ date });

    const requestParams = this.buildRequest(params);
    const data = await this.fetchCalendar(requestParams);

    const actionData = {
      date,
      events: data.items,
    };

    this.dispatchAddCalendar(actionData);
  }

  private buildRequest(params) {
    const month = getLeadingZeroDate(params.month + 1);
    const from = `${params.year}-${month}-01`;

    const lastDay = getDays({ ...params, month });
    const to = `${params.year}-${month}-${lastDay}`;

    return {
      from,
      to,
    };
  }

  private async fetchCalendar(requestParams) {
    try {
      return await httpFetchCalendar(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchAddEmptyCalendar(data) {
    this.dispatch(addEmptyCalendar.createAction(data));
  }

  private dispatchAddCalendar(data) {
    this.dispatch(addCalendar.createAction(data));
  }

}

export async function fetchCalendarThunk(dispatch, params) {
  const thunk = new FetchCalendarThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default FetchCalendarThunk;
