import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './menuItem.style';

const renderArrow = () => {
  const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
  const points = iconsPath.get('arrowRight');

  return (
    <s.Arrow>
      <Icon
        points={points}
        transform={transform}
        color="currentColor"
      />
    </s.Arrow>
  );
};

interface MenuItemProps {
  onClick() : void;
  title: string;
  withArrow?: boolean;
}

export const MenuItem = (props: MenuItemProps) => (
  <s.Root>
    <s.Item
      onClick={props.onClick}
    >
      <s.Title>{props.title}</s.Title>
      {props.withArrow ? renderArrow() : null}
    </s.Item>
  </s.Root>
);

MenuItem.defaultProps = {
  withArrow: false,
};

export default MenuItem;
