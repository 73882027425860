import styled, { css } from 'styled-components';

interface Props {
  isList: boolean;
}

const style: any = {};

style.Root = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 0;
  height: 100%;
`;

style.Content = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

style.ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;

  display: table;
  padding: 0 64px 64px 64px;
  overflow-x: auto;
  width: 100%;

  ${(props: Props) => props.isList && css`
    width: 960px;
  `}
`;

style.ItemsContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export default style;
