import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { SimilarItem } from '@pages/document/redux/state';

import s from './similar.style';

interface SimilarProps {
  items: SimilarItem[];
  loading: boolean;
  baseId: number;
  isSimilarDocument: boolean;
  isChronologyDocument: boolean;
}

export class Similar extends React.PureComponent<SimilarProps> {

  title = 'Похожие документы';

  handleItemClick = () => {
    sendAmplitudeData('document: open similar from document');
    Userpilot.track('document: open similar from document');
  };

  renderItem = (item) => {
    const params = {
      baseId: this.props.baseId,
      documentId: item.documentId,
    };

    let path = '';

    const basePath = window.location.hash
      .replace('#/', '');

    if (this.props.isSimilarDocument) {
      const pathStack = basePath.split('/');
      pathStack[pathStack.length - 1] = params.documentId;
      path = `#/${pathStack.join('/')}`;
    } else if (this.props.isChronologyDocument) {
      const pathStack = basePath.split('/');
      pathStack[pathStack.length - 2] = 'similar';
      pathStack[pathStack.length - 1] = params.documentId;
      path = `#/${pathStack.join('/')}`;
    } else {
      path = `#/${basePath}/similar/${params.documentId}`;
    }

    return (
      <s.Item
        onClick={this.handleItemClick}
        key={item.documentId}
      >
        <s.Title href={path}>{item.title}</s.Title>
        <s.Description>{item.department}</s.Description>
      </s.Item>
    );
  };

  render() {
    if (this.props.loading) {
      return null;
    }

    const noDocuments = !this.props.loading && isEmpty(this.props.items);
    if (noDocuments) {
      return null;
    }

    return (
      <s.Root>
        <s.SimilarTitle>
          {this.title}
        </s.SimilarTitle>
        {this.props.items.map(this.renderItem)}
      </s.Root>
    );
  }

}

export default Similar;
