export type ActionData = number;

interface Action {
  type: string;
  data: ActionData;
}

export const setDocumentIdReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    id: action.data,
  };
};

export default setDocumentIdReduce;
