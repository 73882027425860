import { Filters } from '@pages/search/form/filters';
import { normalizeType } from '@pages/search/form/utils/blocksTypesMap';

export type ActionData = any[];

interface Action {
  type: string;
  data: ActionData;
}

export function getQuery(blocks, state, pinned = false) {
  const filters = new Filters();

  blocks.forEach((blockDTO) => {
    const block = normalizeType(blockDTO);
    const data = {
      type: block,
      defaultData: undefined,

    };

    const presetExist = !!state.presets[block];
    if (presetExist) {
      const defaultData = {
        ...state.presets[block][0],
      };

      data.defaultData = defaultData;
    }

    filters.addFilterBlock(data, pinned);
  });

  return {
    blocksList: filters.blocksList,
    blocksMap: filters.blocksMap,
  };
}

export const fetchDefaultFiltersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const query = getQuery(action.data, state);

  return {
    ...state,
    defaultQuery: {
      ...state.defaultQuery,
      ...query,
      loading: false,
    },
  };
};

export default fetchDefaultFiltersReduce;
