import { fetchMarkersReduce } from './fetchMarkers.reduce';

import type { ActionData } from './fetchMarkers.reduce';

const actionType = 'DOCUMENT/FETCH_MARKERS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchMarkers = {
  actionType,
  createAction,
  reduce: fetchMarkersReduce,
};

export default fetchMarkers;
