import {
  ActionData,
  setCalendarsLoadingReduce,
} from './setCalendarsLoading.reduce';

export const actionType = 'REGULATION/EVENTS/SET_CALENDARS_LOADING';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setCalendarsLoading = {
  actionType,
  createAction,
  reduce: setCalendarsLoadingReduce,
};
