type MarkerType = 'color-a' | 'color-b' | 'color-c';

type MarkerId = string;

type MarkerPath = string;

type MarkerOffset = number;

type MarkerLength = number;

type Marker = [MarkerType, MarkerId, MarkerPath, MarkerOffset, MarkerLength];

export interface ActionData {
  entities: Marker[];
}

interface Action {
  type: string;
  data: ActionData;
}

function prepareEntities(data) {
  return {
    markers: data.entities,
    loading: false,
  };
}

export const fetchMarkersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    document: {
      ...state.document,
      entities: prepareEntities(action.data),
    },
  };
};

export default fetchMarkersReduce;
