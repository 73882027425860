import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { Counter } from './counter';
import { Settings } from './settings';

import s from './item.style';

interface ItemProps {
  id: number;
  title: string;
  active: boolean;
  isNew: boolean;
  isOwner: boolean;
  documentsCount: number;
  type: string;
  onSelectPackage(): void;
}

interface ItemState {
  openConfigPopover: boolean;
  hovered: boolean;
}

export class Item extends React.PureComponent<ItemProps, ItemState> {

  state = {
    openConfigPopover: false,
    hovered: false,
  };

  handleMouseOver = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    if (this.state.openConfigPopover) {
      return;
    }

    this.setState({
      hovered: false,
    });
  };

  handleSettingsMenuVisible = (visible: boolean) => {
    this.setState({
      openConfigPopover: visible,
    });
  };

  handleClickOutside = () => {
    this.setState({
      hovered: false,
      openConfigPopover: false,
    });
  };

  renderConfig() {
    const showConfigElement = this.state.hovered || this.state.openConfigPopover;
    if (!showConfigElement) {
      return <Counter count={this.props.documentsCount} />;
    }

    return (
      <s.Settings>
        <Settings
          isOwner={this.props.isOwner}
          onClickOutside={this.handleClickOutside}
          onMenuVisible={this.handleSettingsMenuVisible}
          id={this.props.id}
        />
      </s.Settings>
    );
  }

  renderNewLabel() {
    if (!this.props.isNew) {
      return null;
    }

    return <s.NewLabel active={this.props.active} />;
  }

  renderAuthorIcon() {
    const isAuthor = this.props.type === 'author';
    if (!isAuthor) {
      return null;
    }

    const iconPath = iconsPath.get('authorPackage');

    return this.renderIcon(iconPath);
  }

  renderCompanyIcon() {
    const isCompany = this.props.type === 'company';
    if (!isCompany) {
      return null;
    }

    const iconPath = iconsPath.get('companyPackage');

    return <s.Icon>{this.renderIcon(iconPath)}</s.Icon>;
  }

  renderIcon(path: string) {
    const color = this.props.active ? '#ffffff' : '#6c7b89';

    return <Icon path={path} color={color} />;
  }

  renderTitle() {
    return (
      <s.Title>
        {this.props.title}
        {this.renderAuthorIcon()}
        {this.renderCompanyIcon()}
      </s.Title>
    );
  }

  render() {
    return (
      <s.Root
        active={this.props.active}
        hovered={this.state.hovered}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.props.onSelectPackage}
      >
        {this.renderNewLabel()}
        {this.renderConfig()}
        {this.renderTitle()}
      </s.Root>
    );
  }

}

export default Item;
