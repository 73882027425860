import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { FolderType } from './redux/state';

import { Counter } from './counter';

import s from '../item.style';

interface ItemProps {
  id: string;
  title: string;
  active: boolean;
  count: number;
  onSelectItem(id: string): void;
}

interface ItemState {
  hovered: boolean;
}

export class BaseFolder extends React.PureComponent<ItemProps, ItemState> {

  state = {
    hovered: false,
  };

  handleMouseOver = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  hadleItemClick = () => {
    this.props.onSelectItem(this.props.id);
  };

  renderCounter() {
    if (!this.props.count) {
      return null;
    }

    return <Counter count={this.props.count} />;
  }

  renderTitle() {
    return (
      <s.Title>
        {this.renderIcon()}
        {this.props.title}
      </s.Title>
    );
  }

  renderIcon() {
    let iconPath = iconsPath.get('regulationAllProjects');
    if (this.props.id === FolderType.underControl) {
      iconPath = iconsPath.get('regulationUnderControl');
    }

    if (this.props.id === FolderType.calendar) {
      iconPath = iconsPath.get('regulationCalendar');
    }

    if (this.props.id === FolderType.comments) {
      iconPath = iconsPath.get('commentFill');
    }

    const color = this.props.active ? 'white' : '#22313f';

    return (
      <s.Icon>
        <Icon path={iconPath} color={color} />
      </s.Icon>
    );
  }

  render() {
    return (
      <s.Root
        active={this.props.active}
        hovered={this.state.hovered}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.hadleItemClick}
      >
        {this.renderCounter()}
        {this.renderTitle()}
      </s.Root>
    );
  }

}
export default BaseFolder;
