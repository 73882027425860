import { connect } from 'react-redux';

import { selectSubscriptionThunk } from './redux/selectSubscription.thunk';

import { Item } from './item';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectItem() {
    selectSubscriptionThunk(dispatch, { sourceId: ownProps.id });
  },
});

export const ItemConnect = connect(
  null,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
