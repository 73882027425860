import { Map } from 'immutable';

export type Loading = boolean;

export type DocumentId = number;

interface FetchedDocument {
  document_id: DocumentId;
  date: string;
  type_title: string;
  department: string;
  department_name_genitive: string;
  instance_number: string;
  case_result: string;
  document_title: string;
}

export interface FetchedDocuments {
  documentId: DocumentId;
  documents: FetchedDocument[];
  page: number;
  total_count: number;
  total_pages: number;
}

export interface Document {
  id: DocumentId;
  date: string;
  documentType: string
  departmentNameGenitive: string;
  instanceNumber: string;
  department: string;
  caseResult: string;
  documentTitle: string;
}

export type ItemsMap = Map<number, Document>;

interface Page {
  items: number[];
  itemsMap: ItemsMap;
}

interface State {
  documentId: DocumentId;
  totalPages: number;
  currentPageNumber: number;
  totalDocuments: number;
  loading: Loading;
  pageLoading: Loading;
  pages: Record<number, Page>
}

export class StateBuilder {

  totalPages: number = null;

  currentPageNumber: number = null;

  totalDocuments: number = null;

  documentId: DocumentId = null;

  pages: Record<number, Page>;

  loading: Loading;

  pageLoading: Loading;

  constructor(state: State) {
    this.documentId = state.documentId;
    this.totalPages = state.totalPages;
    this.currentPageNumber = state.currentPageNumber;
    this.totalDocuments = state.totalDocuments;
    this.pages = state.pages;
    this.loading = state.loading;
    this.pageLoading = state.pageLoading;
  }

  static createInitialState(): State {
    return {
      documentId: null,
      totalPages: null,
      currentPageNumber: 1,
      totalDocuments: null,
      loading: true,
      pageLoading: true,
      pages: {},
    };
  }

  private prepareDocument = (document: FetchedDocument): Document => ({
    id: document.document_id,
    date: document.date,
    documentType: document.type_title,
    departmentNameGenitive: document.department_name_genitive,
    instanceNumber: document.instance_number,
    department: document.department,
    caseResult: document.case_result,
    documentTitle: document.document_title,
  });

  private prepareDocuments = (fetchedDocuments: FetchedDocument[]): Page => {
    const items = [];
    let itemsMap: ItemsMap = Map();

    fetchedDocuments.forEach((document) => {
      items.push(document.document_id);
      itemsMap = itemsMap.set(document.document_id, this.prepareDocument(document));
    });

    return {
      items,
      itemsMap,
    };
  };

  private setTotalPages(params) {
    this.totalPages = params.total_pages;
  }

  private setTotalDocuments(params) {
    this.totalDocuments = params.total_count;
  }

  public setPage(params) {
    this.currentPageNumber = params.page;
  }

  public createPage(fetchedData: FetchedDocuments) {
    if (this.pages[fetchedData.page]) {
      return;
    }

    this.pages[fetchedData.page] = {
      ...this.pages[fetchedData.page],
      ...this.prepareDocuments(fetchedData.documents),
    };
  }

  public setDocumentId(documentId: DocumentId) {
    this.documentId = documentId;
  }

  public setPageInfo(page): void {
    this.setTotalPages(page);
    this.setPage(page);
    this.setTotalDocuments(page);
  }

  public setLoading(loadingStatus) {
    this.loading = loadingStatus;
  }

  public setPageLoading(loadingStatus) {
    this.pageLoading = loadingStatus;
  }

  public getState() {
    return {
      documentId: this.documentId,
      pages: this.pages,
      totalPages: this.totalPages,
      currentPageNumber: this.currentPageNumber,
      totalDocuments: this.totalDocuments,
      loading: this.loading,
      pageLoading: this.pageLoading,
    };
  }

}

export default StateBuilder;
