import every from 'lodash/every';
import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { Analysis } from './analysis';

function allRequiredDataIsFetched(analysis) {
  return every(analysis.itemsList, (currentItem) => {
    const item = analysis.itemsMap[currentItem];
    const dataIsFetched = !item.shouldLoadInitialData && !item.shouldLoadSettings;

    return dataIsFetched;
  });
}

const mapStateToProps = (state, ownProps) => {
  const shouldRender = allRequiredDataIsFetched(state.searchResult.analysis);
  const { sourceId } = getParamsFromUrl(ownProps);

  return {
    id: sourceId,
    shouldRender,
  };
};

export const AnalysisConnect = connect(
  mapStateToProps,
)(Analysis);

export default AnalysisConnect;
