import React from 'react';

import { CreatePackageConnect } from './createPackage.connect';

export const createPackageHoc = (WrappedComponent, props) => class CreatePackage extends React.PureComponent {

  state = {
    shouldRender: false,
  };

  handleRenderOn = () => {
    this.setState({
      shouldRender: true,
    });
  };

  handleRenderOff = () => {
    props.onClose();
    this.setState({
      shouldRender: false,
    });
  };

  render() {
    if (!this.state.shouldRender) {
      return <WrappedComponent onClick={this.handleRenderOn} id={props.id} />;
    }

    return <CreatePackageConnect id={props.id} onClose={this.handleRenderOff} />;
  }

};

export default createPackageHoc;
