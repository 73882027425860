import React from 'react';

import field from '@components/field/field.style';
import { Field } from '@components/field';
import { Field as ProfileField } from '@pages/userPages/components/field';

import s from './userCassette.style';

interface UserCassetteProps {
  firstname: string;
  firstnameError: string;
  lastname: string;
  lastnameError: string;
  company: string;
  position: string;
  onChangeFirstname: (event) => void;
  onChangeLastname: (event) => void;
  onChangePosition: (event) => void;
}

interface UserCassetteState {
  firstname: string;
  lastname: string;
  position: string;
}

export class UserCassette extends React.PureComponent<UserCassetteProps, UserCassetteState> {

  renderFirstnameError() {
    const message = this.props.firstnameError;
    if (!message) {
      return <div />;
    }

    return (
      <field.Tooltip>
        <field.TooltipContainer>
          {message}
        </field.TooltipContainer>
      </field.Tooltip>
    );
  }

  renderLastnameError() {
    const message = this.props.lastnameError;
    if (!message) {
      return <div />;
    }

    return (
      <field.Tooltip>
        <field.TooltipContainer>
          {message}
        </field.TooltipContainer>
      </field.Tooltip>
    );
  }

  render() {
    return (
      <s.Root>
        <s.InfoRow>
          <s.FieldWithFieldset
            hasRightBorder
          >
            <s.Fieldset>
              {this.renderFirstnameError()}
              <Field
                label="Имя"
                value={this.props.firstname}
                onChange={this.props.onChangeFirstname}
              />
            </s.Fieldset>
          </s.FieldWithFieldset>
          <s.FieldWithFieldset
            hasRightBorder
          >
            <s.Fieldset>
              {this.renderLastnameError()}
              <Field
                label="Фамилия"
                value={this.props.lastname}
                onChange={this.props.onChangeLastname}
              />
            </s.Fieldset>
          </s.FieldWithFieldset>
        </s.InfoRow>
        <s.InfoRow>
          <s.DefaultField
            hasRightBorder
          >
            <Field
              label="Должность в компании"
              value={this.props.position}
              onChange={this.props.onChangePosition}
            />
          </s.DefaultField>
          <ProfileField
            width={50}
            hasRightBorder
            title="Наименование компании"
            value={this.props.company}
          />
        </s.InfoRow>
      </s.Root>
    );
  }

}

export default UserCassette;
