import {
  CaseNumberBlock, CaseNumberBlockData,
} from '../../form/filterBlocks/caseNumber/redux/caseNumber';

export abstract class CaseNumberBlockHistory extends CaseNumberBlock {

  static transform(data: CaseNumberBlockData) {
    return data.value || '';
  }

}

export default CaseNumberBlockHistory;
