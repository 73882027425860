import { RESTPath } from '@router/path.rest';

import http from '@http';

type FilterType = string;

interface Response {
  filters: FilterType[];
}

export function httpSavePinnedFilters(requestParams: Response) {
  const key = 'pinned_filters';
  const request = http.put({
    url: RESTPath.user.configuration.base,
    data: {
      key,
      value: requestParams,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSavePinnedFilters;
