/* eslint-disable */
import amplitude from 'amplitude-js';

const PATHS_EVENTS = {
  // '/judges': 'judges view',
  // '/law': 'law view',
  // '/historical': 'analysis view',
  // '/statistics': 'statistics view',
  '/search\\/\\d+\\/documents': 'search documents',
  // '/package$': 'collection view',
  // '/updates$': 'subscription view',
  // '/authorpackages$': 'author\'s collection views',
  // '/profile/about$': 'profile view',
};

const KEY_USER_LAST_ENTER = 'amp_user_last_enter';

let prevPath = ''; // prevent event duplicate

const setAmplitudeUser = (bootstrap) => {
  // @ts-ignore
  if (AMPLITUDE_API_KEY) {
    let amp = amplitude.getInstance(),
      name = 'null null';

    if (bootstrap['first_name'] || bootstrap['last_name']) {
      name = `${bootstrap['first_name']} ${bootstrap['last_name']}`;
    }

    amp.setUserId(bootstrap['id']);
    amp.setUserProperties({
      'Name user': name,
      'Email': bootstrap['email'],
      'Type ': bootstrap['role'],
      'License': bootstrap['is_paid_until'] ? 'Paid' : 'Trial'
    });
  }
};

export const setAmplitudeUserProperties = properties => {
  // @ts-ignore
  AMPLITUDE_API_KEY && amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties = {}) => {
  // @ts-ignore
  AMPLITUDE_API_KEY && amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const sendAmplitudeEventByPath = (pathname, eventProperties) => {
  // @ts-ignore
  if (!AMPLITUDE_API_KEY || prevPath === pathname) {
    return;
  }

  prevPath = pathname;
  Object.entries(PATHS_EVENTS).forEach(([path, name]) => {
    if (RegExp(path).test(pathname)) {
      amplitude.getInstance().logEvent(name, eventProperties);
    }
  });
};

export const amplitudeLogout = () => {
  // @ts-ignore
  if (AMPLITUDE_API_KEY) {
    amplitude.getInstance().logEvent('logout');
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  }
};

export const initAmplitude = (bootstrap) => {
  // @ts-ignore
  if (!AMPLITUDE_API_KEY) {
    return;
  }

  const currentInit = (new Date()).toDateString();
  const lastInit = window.localStorage.getItem(KEY_USER_LAST_ENTER);

  // @ts-ignore
  amplitude.getInstance().init(AMPLITUDE_API_KEY);
  setAmplitudeUser(bootstrap);

  if (lastInit !== currentInit) {
    // @ts-ignore
    sendAmplitudeData('login');
    window.localStorage.setItem(KEY_USER_LAST_ENTER, currentInit);
  }
};
