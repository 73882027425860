import React from 'react';

import { Loader } from '@components/loader';

import { TitleGroupConnect } from './titleGroup/titleGroup.connect';
import { DocumentsConnect } from './documents/documents.connect';

import s from './workspace.style';

interface WorkspaceProps {
  loading: boolean;
}

export class Workspace extends React.PureComponent<WorkspaceProps> {

  contentRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  renderContent() {
    if (this.props.loading) {
      return <Loader />;
    }

    return (
      <>
        <TitleGroupConnect />
        <DocumentsConnect contentRef={this.contentRef} />
      </>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Content ref={this.contentRef}>
          <s.ContentContainer>
            {this.renderContent()}
          </s.ContentContainer>
        </s.Content>
      </s.Root>
    );

  }

}

export default Workspace;
