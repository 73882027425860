import React from 'react';

import { DatePicker } from '@components/datepicker';
import { Checkbox } from '../../../../../../components/filters/components/checkbox';
import {
  DateBlock,
  ScopeId,
  ScopeIdDTO,
} from './logic/date';

import 'react-datepicker/dist/react-datepicker.css';

import s from './layout.style';

interface LayoutProps {
  scope: {
    id: string;
    title: string;
  };
  value: Date[];
  onFromChange: (data: any) => void;
  onToChange: (data: any) => void;
  onScopeChange: (data: any) => void;
}

export class Layout extends React.PureComponent<LayoutProps> {

  renderDefaultContainer() {
    const isActive = this.props.scope.id === ScopeId.allTime;
    const noValue = this.props.value.every((item) => !item);

    return (
      <s.DefaultContainer>
        <s.Option>
          <Checkbox
            isActive={isActive && noValue}
            item={DateBlock.scopePresets[ScopeIdDTO.allTime]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.allTime].title}
          </Checkbox>
        </s.Option>
      </s.DefaultContainer>
    );
  }

  renderOtionsContainer() {
    const isActiveToday = this.props.scope.id === ScopeId.today;
    const isActiveYesterday = this.props.scope.id === ScopeId.yesterday;
    const isActiveDayBeforeYesterday = this.props.scope.id === ScopeId.dayBeforeYesterday;
    const isActiveThreeDaysAgo = this.props.scope.id === ScopeId.threeDaysAgo;
    const isActiveThisWeek = this.props.scope.id === ScopeId.thisWeek;
    const isActiveLastWeek = this.props.scope.id === ScopeId.lastWeek;
    const isActiveCurrentMonth = this.props.scope.id === ScopeId.currentMonth;
    const isActiveLastMonth = this.props.scope.id === ScopeId.lastMonth;

    return (
      <s.OptionsContainer>
        <s.Option>
          <Checkbox
            isActive={isActiveToday}
            item={DateBlock.scopePresets[ScopeIdDTO.today]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.today].title}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveThisWeek}
            item={DateBlock.scopePresets[ScopeIdDTO.thisWeek]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.thisWeek].title}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveYesterday}
            item={DateBlock.scopePresets[ScopeIdDTO.yesterday]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.yesterday].title}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveLastWeek}
            item={DateBlock.scopePresets[ScopeIdDTO.lastWeek]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.lastWeek].title}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveDayBeforeYesterday}
            item={DateBlock.scopePresets[ScopeIdDTO.dayBeforeYesterday]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.dayBeforeYesterday].title}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveCurrentMonth}
            item={DateBlock.scopePresets[ScopeIdDTO.currentMonth]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.currentMonth].title}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveThreeDaysAgo}
            item={DateBlock.scopePresets[ScopeIdDTO.threeDaysAgo]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.threeDaysAgo].title}
          </Checkbox>
        </s.Option>
        <s.Option>
          <Checkbox
            isActive={isActiveLastMonth}
            item={DateBlock.scopePresets[ScopeIdDTO.lastMonth]}
            onClick={this.props.onScopeChange}
          >
            {DateBlock.scopePresets[ScopeIdDTO.lastMonth].title}
          </Checkbox>
        </s.Option>
      </s.OptionsContainer>
    );
  }

  renderDateFrom() {
    return (
      <s.Input>
        <DatePicker
          placeholderText="С"
          selected={this.props.value[0]}
          onChange={this.props.onFromChange}
        />
      </s.Input>
    );
  }

  renderDateTo() {
    return (
      <s.Input>
        <DatePicker
          placeholderText="По"
          selected={this.props.value[1]}
          onChange={this.props.onToChange}
        />
      </s.Input>
    );
  }

  render() {
    return (
      <s.Root>
        <s.InputContainer>
          {this.renderDateFrom()}
          {this.renderDateTo()}
        </s.InputContainer>
        {this.renderDefaultContainer()}
        {this.renderOtionsContainer()}
      </s.Root>
    );
  }

}

export default Layout;
