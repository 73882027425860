import styled, { css } from 'styled-components';

interface Props {
  selected: boolean;
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  height: 48px;
  padding: 0 16px;
  background-color: ${(props: Props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Tab = styled.button`
  flex: 1;
  flex-shrink: 0;
  height: 100%;
  padding: 4px 2px 0;
  margin-right: 20px;
  border: 0;
  border-bottom: 3px solid transparent;

  font: 13px Roboto-Medium;
  text-transform: uppercase;
  color: ${(props: Props) => props.theme.colors.stone};
  background-color: ${(props: Props) => props.theme.colors.white};
  outline: none;
  cursor: pointer;

  transition: color 0.2s ease;

  &:hover {
    color: ${(props: Props) => props.theme.colors.defaultText};
  }


  ${(props: Props) => props.selected && css`
    border-bottom-color: ${props.theme.colors.orange};
    color: ${props.theme.colors.defaultText};
    cursor: default;
  `}
`;

export default style;
