import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpCreateSearch(conditions) {
  const request = {
    url: RESTPath.search.create,
    data: {
      ...conditions,
    },
  };

  return http.post(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpCreateSearch;
