import {
  StateBuilder,
  FetchedPageInfo,
} from './state';

export interface ActionData {
  pageNumber: number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchBookmarksReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);

  newState.setPageInfo(action.data.result as FetchedPageInfo);

  newState.setLoading(false);
  newState.setPageLoading(false);

  newState.addPage(action.data.result.page, action.data.result.result);

  return {
    ...newState.getState(),
  };
};

export default fetchBookmarksReduce;
