import React from 'react';

import s from './downloadButton.style';

interface DownloadButtonProps {
  onDownload(): void;
  title: string;
}

export const DownloadButton = (props: DownloadButtonProps) => (
  <s.Root
    onClick={props.onDownload}
  >
    <s.Text>
      {props.title}
    </s.Text>
  </s.Root>
);

export default DownloadButton;
