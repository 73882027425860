import styled from 'styled-components';

import button from '@components/button/button.style';

const style: any = {};

style.SaveButton = styled.div`
  ${button.Root} {
    padding: 0 16px 0 8px;
  }
`;

export default style;
