import React from 'react';

import { Button } from '@components/button';

import { Block as FilterBlock } from '@pages/search/form/filterBlocks/block';
import { AddNewBlock } from '@pages/search/form/addNewBlock/addNewBlock';

import s from './block.style';

interface BlockProps {
  onAddBlock: (block) => void;
  onDuplicateBlock: (block) => void;
  onRemoveBlock: (block) => void;
  onUpdateFilters: (block) => void;
  onResetForm: () => void;
  items: any[];
}

export class Block extends React.PureComponent<BlockProps> {

  renderEmptyContainer = () => (
    <s.EmptyContainer>
      Используйте кнопку «Добавить фильтр» для составления поискового запроса
    </s.EmptyContainer>
  );

  renderFilterBlocks = () => this.props.items.map((item) => (
    <s.Item
      key={item.id}
    >
      <FilterBlock
        item={item}
        onDuplicateBlock={this.props.onDuplicateBlock}
        onRemoveBlock={this.props.onRemoveBlock}
        isPinned={item.pinned}
      />
    </s.Item>
  ));

  renderFilters() {
    if (!this.props.items.length) {
      return this.renderEmptyContainer();
    }

    return (
      <s.FilterBlocksContainer>
        {this.renderFilterBlocks()}
      </s.FilterBlocksContainer>
    );
  }

  renderAddFilterButton() {
    return (
      <s.Button>
        <AddNewBlock
          onAddBlock={this.props.onAddBlock}
        />
      </s.Button>
    );
  }

  renderResetButton() {
    return (
      <Button
        linkLike
        disabled={false}
        fullWidth={false}
        onClick={this.props.onResetForm}
      >
        Сбросить
      </Button>
    );
  }

  renderSaveButton() {
    return (
      <Button
        disabled={false}
        fullWidth={false}
        onClick={this.props.onUpdateFilters}
      >
        Найти
      </Button>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Form>
          <s.FiltersList>
            {this.renderFilters()}
          </s.FiltersList>
          <s.SearchControls>
            <s.Column
              verticalAlign="left"
            >
              {this.renderAddFilterButton()}
            </s.Column>
            <s.Column
              verticalAlign="right"
            >
              {this.renderResetButton()}
              {this.renderSaveButton()}
            </s.Column>
          </s.SearchControls>
        </s.Form>
      </s.Root>
    );
  }

}

export default Block;
