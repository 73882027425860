import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { FetchSearchResultCourtsSettingsThunk } from './redux/fetchSettings.thunk';
import { fetchSearchCourtsThunk } from './redux/fetchCourts.thunk';
import { changePageNumber } from './redux/changePageNumber';
import { changeSortThunk } from './redux/changeSort.thunk';
import { sortDataDefault } from '../components/sortIcon/sortIcon';
import { isDefault } from '../utils';

import { Courts } from './courts';

const mapStateToProps = (state, ownProps) => {
  const { sourceId } = getParamsFromUrl(ownProps);

  const {
    pages,
    currentPageNumber,
    initialLoading,
  } = state.searchResult.courts;

  const items = pages[currentPageNumber] ?
    pages[currentPageNumber].items :
    [];

  return {
    initialLoading,
    id: sourceId,
    items,
    sortData: state.searchResult.courts.sortData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSearchResultCourtsSettingsThunk(dispatch);
    thunk.invoke();
  },
  onResetPageNumber() {
    dispatch(changePageNumber.createAction({
      pageNumber: 1,
    }));
  },
  onFetchCourts() {
    const { sourceId } = getParamsFromUrl(ownProps);
    fetchSearchCourtsThunk(dispatch, false, sourceId, null);
  },
  onResetToDefaultSort() {
    changeSortThunk(dispatch, sortDataDefault.id);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const onResetToDefaultSort = () => {
    if (!isDefault(stateProps.sortData)) {
      return dispatchProps.onResetToDefaultSort();
    }

    return null;
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onResetToDefaultSort,
  };
};

export const CourtsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Courts);

export default CourtsConnect;
