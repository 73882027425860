import styled from 'styled-components';

const style: any = {};

style.Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

style.Navigation = styled.aside`
  flex-shrink: 0;
  width: 256px;

  @media (max-width: 768px) {
    display: none;
  }
`;

style.Main = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

style.Content = styled.div`
  flex: 1;
  position: relative;
`;

export default style;
