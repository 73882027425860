import { processCompleteReduce } from './processComplete.reduce';

export const actionType = 'BOOKMARKS_BUFFER/DOWNLOAD_COMPLETE';

const createAction = () => ({
  type: actionType,
});

export const processComplete = {
  actionType,
  createAction,
  reduce: processCompleteReduce,
};
