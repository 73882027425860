import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Source } from '@router/path.rest';

import { addDocumentToPackage } from '@pages/packages/redux/searchPackages/addDocumentToPackage';
import { removeDocumentFromPackage } from '@pages/packages/redux/searchPackages/removeDocumentFromPackage';

import { httpAddDocumentToPackage } from '@pages/packages/redux/packages/addDocumentToPackage.http';
import { httpRemoveDocument } from '@pages/packages/redux/packages/removeDocument.http';

import { increasePackageCount } from './increasePackageCount';
import { decreasePackageCount } from './decreasePackageCount';

export class AddDocumentToPackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { documentId: number; packageId: number, pageNumber: number }) {
    sendAmplitudeData('add_to_collection');
    Userpilot.track('add_to_collection');
    const state = this.getState().packages.searchResult;
    const pageNumber = params.pageNumber || this.getState().searchResult.documents.previewDocument.currentPageNumber;
    const { selected } = state.itemsMap.get(params.packageId);

    if (!selected) {
      this.dispatchIncreasePackageCount(params.documentId, pageNumber);
      await this.addDocumentToPackage(params);

      return;
    }

    this.dispatchDecreasePackageCount(params.documentId, pageNumber);
    await this.removeDocumentFromPackage(params);
  }

  private async addDocumentToPackage(params) {
    try {
      this.dispatchAddSucceed(params);

      const sourceId = this.getState().searchResult.id;
      const requestParams = {
        documentId: params.documentId,
        packageId: params.packageId,
        sourceId,
        source: Source.search,
      };

      await httpAddDocumentToPackage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async removeDocumentFromPackage(params) {
    try {
      this.dispatchRemoveSucceed(params);
      await httpRemoveDocument(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchAddSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(addDocumentToPackage.createAction(actionData));
  }

  private dispatchIncreasePackageCount(documentId, pageNumber) {
    this.dispatch(increasePackageCount.createAction({ documentId, pageNumber }));
  }

  private dispatchDecreasePackageCount(documentId, pageNumber) {
    this.dispatch(decreasePackageCount.createAction({ documentId, pageNumber }));
  }

  private dispatchRemoveSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(removeDocumentFromPackage.createAction(actionData));
  }

}

export function addDocumentToPackageThunk(dispatch, params) {
  const thunk = new AddDocumentToPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddDocumentToPackageThunk;
