import { resetStateReduce } from './resetState.reduce';

export const actionType = 'REGULATION/EVENTS/RESET_STATE';

const createAction = () => ({
  type: actionType,
});

export const resetState = {
  actionType,
  createAction,
  reduce: resetStateReduce,
};
