import {
  StateBuilder,
  DocumentId,
} from './state';

export interface ActionData {
  id: DocumentId
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateFetchErrorReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.items);

  newState.updateFetchError(action.data.id, true);
  newState.updateItemProgress(action.data.id, false);

  return newState.getState();
};

export default updateFetchErrorReduce;
