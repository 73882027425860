import {
  Id,
  RoleId,
  StateBuilder,
} from './state';

export type ActionData = {
  userId: Id;
  roleId: RoleId;
};

export interface Action {
  type: string;
  data: ActionData;
}

export const changeRoleReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.users);
  newState.updateRole(action.data.userId, action.data.roleId);

  return {
    ...state,
    users: newState.getState(),
  };
};

export default changeRoleReduce;
