import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchMessages } from './fetchMessages';
import { setLoadingStatus } from './setLoadingStatus';
import { addMessageFromClient } from './addMessageFromClient';
import { addMessage } from './addMessage';
import { updateMessage } from './updateMessage';
import { resetToDefault } from './resetToDefault';

export const reducerBuilder = new ReducerBuilder([
  fetchMessages,
  setLoadingStatus,
  addMessageFromClient,
  addMessage,
  updateMessage,
  resetToDefault,
]);

export default reducerBuilder;
