import React from 'react';

import { getFirstDayNumber } from '../../utils';

import { Params } from '../../redux/fetchCalendar.thunk';

import { Day } from './day';

import s from './calendar.style';
import d from './day.style';

import { DateParams } from './calendar.connect';

interface DayItem {
  day: number;
  isSelected: boolean;
  hasEvents: boolean;
}

interface CalendarProps {
  month: number;
  year: number;
  activeDate: number;
  width?: number;
  loading: boolean;
  calendar: DayItem[];
  onFetchData(data: Params): void;
  onSetActiveDate(data: DateParams): void;
}

export class Calendar extends React.PureComponent<CalendarProps> {

  static defaultProps = {
    width: 280,
  };

  days = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

  month = ['Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  componentDidMount() {
    this.fetchData();
  }

  private handleClick = (day: number) => {
    const params = {
      year: this.props.year,
      month: this.props.month,
      day,
    };

    this.props.onSetActiveDate(params);
  };

  private fetchData() {
    this.props.onFetchData({ year: this.props.year, month: this.props.month });
  }

  renderDays() {
    return this.days.map((item) => <d.Root>{item}</d.Root>);
  }

  renderDate() {
    const firstDayNumber = getFirstDayNumber({ year: this.props.year, month: this.props.month });

    return this.props.calendar.map(
      (item, index) => (
        <Day
          firstDayNumber={firstDayNumber}
          isSelected={item.isSelected}
          hasEvents={item.hasEvents}
          day={index + 1}
          onClick={this.handleClick}
          loading={this.props.loading}
        />
      ),
    );
  }

  renderTitle() {
    const title = `${this.month[this.props.month]} ${this.props.year}`;

    return <s.Title>{title}</s.Title>;
  }

  render() {

    return (
      <s.Root
        width={this.props.width}
      >
        {this.renderTitle()}
        <s.Days>
          {this.renderDays()}
        </s.Days>
        <s.Date>
          {this.renderDate()}
        </s.Date>
      </s.Root>
    );
  }

}

export default Calendar;
