import React from 'react';

import { ChatView } from './chatView';
import { InputView } from './inputView';

import type { MessageProps } from './message';

import s from './chat.style';

interface ChatProps {
  messages: MessageProps[];
  avatar: string;
  loading: boolean;
  onSend(body: string): void;
  mentionAPI?(query: string): any;
}

export class Chat extends React.PureComponent<ChatProps> {

  render() {
    return (
      <s.Root>
        <ChatView
          loading={this.props.loading}
          messages={this.props.messages}
        />
        <InputView
          avatar={this.props.avatar}
          onSend={this.props.onSend}
          mentionAPI={this.props.mentionAPI}
        />
      </s.Root>
    );
  }

}

export default Chat;
