import {
  ParticipantBlock, ParticipantBlockData,
} from '../../form/filterBlocks/participant/redux/participant';

export abstract class ParticipantBlockHistory extends ParticipantBlock {

  static transform(data: ParticipantBlockData): string {

    let result = ParticipantBlock.presetsMap[data.mode.id].title;

    if (data.participant.title) {
      result = `${result} • ${data.participant.title}`;
    }

    return result;
  }

}

export default ParticipantBlockHistory;
