import { Dispatch } from 'redux';

import { RequestParams } from '@providers/download/download.http';
import { httpFetchLawDocument } from './fetchLawDocument.http';
import { fetchLawDocument } from './fetchLawDocument';

import { resetDocumentToInitial } from './resetDocumentToInitial';

export class UpdateLawDocumentThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {
    this.dispatch(resetDocumentToInitial.createAction());
    await this.getDocument(params);
  }

  private async getDocument(params) {
    try {
      const result = await httpFetchLawDocument(params);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchDocumentSucceed(result) {
    this.dispatch(fetchLawDocument.createAction(result));
  }

}

export function updateLawDocumentThunk(dispatch, params) {
  const thunk = new UpdateLawDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default UpdateLawDocumentThunk;
