import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
  selected: boolean;
}

style.Item = styled.div`
  padding: 12px 16px 13px 12px;
  position: relative;
  user-select: none;

  display: flex;
  align-items: center;

  color: ${(props: Props) => props.theme.colors.defaultText};

  cursor: pointer;

  ${(props: Props) => props.selected && css`
    background: #f6fafe;
  `};
`;

style.ItemTitle = styled.div`
  padding-left: 8px;
  font-size: 13px;
`;

export default style;
