import React from 'react';

import { WithSidebar } from '@components/withSidebar';
import { StaticSidebar } from '@components/staticSidebar/staticSidebar';
import { RoutePath } from '@router/path';

import { AuthRouteConnect } from '@router/authRoute.connect';

import { ProfileConnect } from './profile.connect';

export const RouteProfile = (
  <AuthRouteConnect
    exact
    path={RoutePath.profile}
    component={WithSidebar(StaticSidebar, ProfileConnect)}
  />
);

export default RoutePath;
