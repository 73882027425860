import { updatePreviousCourtReduce } from './updatePreviousCourt.reduce';
import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/PREVIOUS_COURT/UPDATE`;

export interface Data {
  id: string;
  data: {
    id: number;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updatePreviousCourt = {
  actionType,
  createAction,
  reduce: updatePreviousCourtReduce,
};

export default updatePreviousCourt;
