import { connect } from 'react-redux';

import { separator } from '@components/separator';

import { Format } from '../redux/settings';

import { addLawBlockThunk } from '../redux/addLawBlock.thunk';

import { Item } from './item';

export interface ViewData {
  item: {
    id: string,
    title: string,
    hasArticles: boolean;
    hasClauses: boolean;
  };
  columns: any;
}

export interface DataFromView {
  columnId: string;
  document: {
    id: string;
    title: string;
    hasArticles: boolean;
    hasClauses: boolean;
  };
}

function prepareColumns(data, settings, options) {
  const columns = [];

  const title = {
    id: 'title',
    title: data.title || '',
  };

  // @ts-ignore
  columns.push(title);

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      const value = separator(data[options.type][column][options.format]);
      let cellData = value;

      const isPercent = options.format === Format.percent;
      if (isPercent) {
        cellData = `${value}%`;
      }

      const tooltipData = separator(data[options.type][column][Format.number]);

      columns.push({
        // @ts-ignore
        id: column,
        // @ts-ignore
        title: cellData,
        // @ts-ignore
        tooltip: tooltipData,
        // @ts-ignore
        isPercent,
      });
    }
  });

  return columns;
}

const mapStateToProps = (state, ownProps): ViewData => {
  const { type, format } = state.searchResult.law.layout;
  const document = state.searchResult.law.documents.itemsMap.get(ownProps.id);

  const settings = state.searchResult.law.layout.columns[state.searchResult.law.layout.type];
  const columns = prepareColumns(document, settings, { type, format });

  return {
    item: {
      id: document.id,
      title: document.title,
      hasArticles: document.hasArticles,
      hasClauses: document.hasClauses,
    },
    columns,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddLaw(data: DataFromView) {
    addLawBlockThunk(dispatch, data);
  },
});

export const ItemConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
