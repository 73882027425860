import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import http from '@http';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

type Id = number;
type Title = string;

interface ResponseItem {
  id: Id
  name: Title
}

export interface SuggestResponse {
  result: ResponseItem[]
}

interface Category {
  id: string,
  title: Title
}

interface UndecorateResponseItem {
  id: number,
  category?: Category
}

export interface DocumentTypeBlockData {
  id: Id;
  title: Title;
  category?: Category
}

export class DocumentTypeBlock extends AbstractFilterBlock {

  static type = 'documentType';

  static DTOType = 'document_type';

  static blockTitle = 'Тип документа';

  static category = category.document;

  static SuggestAPI = '/autocomplete/content/definition';

  protected defaultData: DocumentTypeBlockData = {
    // @ts-ignore
    id: null,
    // @ts-ignore
    title: null,
    category: {
      // @ts-ignore
      id: null,
      // @ts-ignore
      title: null,
    },
  };

  constructor() {
    super();

    this.setCategory(DocumentTypeBlock.category);
    this.setType({
      id: DocumentTypeBlock.type,
      DTOType: DocumentTypeBlock.DTOType,
      title: DocumentTypeBlock.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains, presets) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const title = presets.find((preset) => preset.id === data.id).title;

    const updateData = {
      ...this.defaultData,
      ...data,
      title,
    };

    const block = {
      id,
      data: updateData,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    const documentTypeData = this.getData();

    let data: UndecorateResponseItem = {
      id: documentTypeData.id,
    };
    const isCategory = Boolean(documentTypeData.category.id) && Boolean(documentTypeData.category.title);

    if (isCategory) {
      const categoryData = documentTypeData.category;

      data = {
        ...data,
        category: categoryData,
      };
    }

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: DocumentTypeBlock.DTOType,
      data,
    };
  }

  // eslint-disable-next-line
  public validate() {}

  public getItemsForSuggest = async (query) => {
    const getItems = async (): Promise<SuggestResponse> => {
      const request = {
        url: DocumentTypeBlock.SuggestAPI,
        body: {
          query,
        },
      };

      const response = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.result.map((item) => ({
        id: item.id,
        title: item.name,
      })),
    };
  };

}

export default DocumentTypeBlock;
