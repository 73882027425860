import { styled } from '@components/styled';

import {
  hover,
  iconButton,
} from '@components/button/button.style';

import {} from '@components/button';

const style: any = {};

interface Props {
  theme: any;
}

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 320px;
`;

style.Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  height: 48px;
  padding: 0 8px;

  font-family: Roboto-Medium;
  font-size: 13px;

  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.HeaderAction = styled.div`
  z-index: 1;

  > div {
    ${iconButton};
    ${hover};
  }
`;

style.TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

style.HeaderTitle = styled.div``;

style.TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: white;
`;

style.Actions = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  height: 48px;
`;

export default style;
