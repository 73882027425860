import React, { useMemo } from 'react';
import 'quill-mention';

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import './editor.css';

type Theme = 'snow' | 'bubble';

interface EditorProps {
  value: string;
  placeholder?: string;
  shouldReset?: boolean;
  onChange?(value: string): void;
  onBlur?: () => void;
  onFocus?: () => void;
  theme?: Theme;
  mentionAPI?: (query: string) => any;
}

export const Editor = (props: EditorProps) => {
  const mention = {
    allowedChars: /^[A-Za-z\w.-\sÅa-яёА-ЯЁÄÖåäö]*$/,
    mentionDenotationChars: ['@'],
    dataAttributes: ['id', 'email', 'name'],
    defaultMenuOrientation: 'top',
    async source(searchTerm, renderList) {
      const result = await props.mentionAPI(searchTerm);

      renderList(result);
    },
    renderItem(item) {
      return `<span>${item.name}</span><span>${item.email}</span>`;
    },
    onSelect(item, insertItem) {
      const value = item.name ? item.name : item.email;

      const attributes = { id: item.id, value, email: item.email, denotationChar: '@' };

      return insertItem(attributes);
    },
  };

  const modules:any = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
        ],
        handlers: {
          link(value) {
            // eslint-disable-next-line
            const tooltip = this.quill.theme.tooltip;
            const input = tooltip.root.querySelector('input[data-link]');

            input.dataset.link = 'www.placeholder.io';
            input.placeholder = 'www.placeholder.io';
            input.dataset.lpignore = true;

            if (value) {
              tooltip.edit('link', 'http://');
            } else {
              tooltip.edit('link', false);
            }
          },
        },
      },
    }),
    [],
  );

  if (props.mentionAPI) {
    modules.mention = mention;
  }

  const handleChage = (value) => {
    if (!props.onChange) {
      return;
    }

    props.onChange(value);
  };

  return (
    <ReactQuill
      value={props.value}
      onChange={handleChage}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      theme={props.theme}
      placeholder={props.placeholder}
      modules={modules}
      bounds=".quill-container"
    />
  );
};

Editor.defaultProps = {
  theme: 'snow',
  onBlur: () => {},
  onFocus: () => {},
};

export default Editor;
