import { RESTPath } from '@router/path.rest';
import http from '@http';

export interface RequestParams {
  id: number,
  value: string,
}

export function httpUpdatePackageDescription(requestParams) {
  const url = RESTPath.packages.updateDescription(requestParams.id);
  const request = http.post({
    url,
    data: {
      value: requestParams.value,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUpdatePackageDescription;
