import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { httpSavePosition } from './savePosition.http';

import { savePosition } from './savePosition';

class SavePositionThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { text: string }) {
    sendAmplitudeData('user: changePosition');
    Userpilot.track('user: changePosition');
    this.dispatchSetPosition(params);
    await this.httpSavePosition(params);

  }

  private dispatchSetPosition(data) {
    this.dispatch(savePosition.createAction(data));
  }

  private async httpSavePosition(params) {
    try {
      await httpSavePosition(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export async function savePositionThunk(dispatch, params) {
  const thunk = new SavePositionThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default SavePositionThunk;
