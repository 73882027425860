import { LawBlock } from '../filterBlocks/law/redux/law';
import { TextBlock } from '../filterBlocks/text/redux/text';
import { DocumentTypeBlock } from '../filterBlocks/documentType/redux/documentType';
import { DateBlock } from '../filterBlocks/date/redux/date';
import { JudgeBlock } from '../filterBlocks/judge/redux/judge';
import { CourtBlock } from '../filterBlocks/court/redux/court';
import { PreviousCourtBlock } from '../filterBlocks/previousCourt/redux/previousCourt';
import { CourtTypeBlock } from '../filterBlocks/courtType/redux/courtType';
import { RegionBlock } from '../filterBlocks/region/redux/region';
import { CaseTypeBlock } from '../filterBlocks/caseType/redux/caseType';
import { DisputeCategoryBlock } from '../filterBlocks/disputeCategory/redux/disputeCategory';
import { CaseNumberBlock } from '../filterBlocks/caseNumber/redux/caseNumber';
import { ClaimAmountBlock } from '../filterBlocks/claimAmount/redux/claimAmount';
import { CaseResultBlock } from '../filterBlocks/caseResult/redux/caseResult';
import { AppealStateBlock } from '../filterBlocks/appealState/redux/appealState';
import { ParticipantBlock } from '../filterBlocks/participant/redux/participant';
import { PlaintiffBlock } from '../filterBlocks/plaintiff/redux/plaintiff';
import { DefendantBlock } from '../filterBlocks/defendant/redux/defendant';
import { ThirdPartyPersonBlock } from '../filterBlocks/thirdPartyPerson/redux/thirdPartyPerson';
import { AnotherPersonBlock } from '../filterBlocks/anotherPerson/redux/anotherPerson';
import { RepresentativeBlock } from '../filterBlocks/representative/redux/representative';

const blocksTypesMap = new Map();

blocksTypesMap.set(LawBlock.DTOType, LawBlock.type);
blocksTypesMap.set(JudgeBlock.DTOType, JudgeBlock.type);
blocksTypesMap.set(CourtBlock.DTOType, CourtBlock.type);
blocksTypesMap.set(PreviousCourtBlock.DTOType, PreviousCourtBlock.type);
blocksTypesMap.set(RegionBlock.DTOType, RegionBlock.type);
blocksTypesMap.set(DisputeCategoryBlock.DTOType, DisputeCategoryBlock.type);
blocksTypesMap.set(CaseNumberBlock.DTOType, CaseNumberBlock.type);
blocksTypesMap.set(RepresentativeBlock.DTOType, RepresentativeBlock.type);
blocksTypesMap.set(DateBlock.DTOType, DateBlock.type);
blocksTypesMap.set(TextBlock.DTOType, TextBlock.type);
blocksTypesMap.set(ClaimAmountBlock.DTOType, ClaimAmountBlock.type);
blocksTypesMap.set(DocumentTypeBlock.DTOType, DocumentTypeBlock.type);
blocksTypesMap.set(CourtTypeBlock.DTOType, CourtTypeBlock.type);
blocksTypesMap.set(CaseTypeBlock.DTOType, CaseTypeBlock.type);
blocksTypesMap.set(CaseResultBlock.DTOType, CaseResultBlock.type);
blocksTypesMap.set(AppealStateBlock.DTOType, AppealStateBlock.type);
blocksTypesMap.set(ParticipantBlock.DTOType, ParticipantBlock.type);
blocksTypesMap.set(DefendantBlock.DTOType, DefendantBlock.type);
blocksTypesMap.set(ThirdPartyPersonBlock.DTOType, ThirdPartyPersonBlock.type);
blocksTypesMap.set(AnotherPersonBlock.DTOType, AnotherPersonBlock.type);
blocksTypesMap.set(PlaintiffBlock.DTOType, PlaintiffBlock.type);

export const normalizeType = function (blockType: string) {
  return blocksTypesMap.get(blockType);
};

export default normalizeType;
