import { RESTPath } from '@router/path.rest';
import http from '@http';

export interface RequestParams {
  query: string;
}

export function httpFetchFilters(data: RequestParams) {
  const request = {
    url: RESTPath.search.wizard,
    data,
  };

  return http.post(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchFilters;
