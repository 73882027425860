import { resetFiltersReduce } from './resetFilters.reduce';

const actionType = 'REGULATION/RESET_FILTERS';

const createAction = () => ({
  type: actionType,
});

export const resetFilters = {
  actionType,
  createAction,
  reduce: resetFiltersReduce,
};

export default resetFilters;
