import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { theme } from '@components/styled';

import s from './closeButton.style';

interface Props {
  onClick?: () => void;
  // eslint-disable-next-line react/require-default-props
  checked?: boolean;
  // eslint-disable-next-line react/require-default-props
  color?: string;
}

const defaultProps = {
  onClick: () => {},
};

export const CloseButton:React.FC<Props> = ({ ...props }) => {

  const iconPathName = 'cross';

  const iconColor = props.color || theme.colors.defaultText;

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    // @ts-ignore
    props.onClick();
  };

  return (
    <s.Root
      onClick={handleClick}
      checked={props.checked}
    >
      <Icon
        color={iconColor}
        path={iconsPath.get(iconPathName)}
      />
    </s.Root>
  );
};

CloseButton.defaultProps = defaultProps;

export default CloseButton;
