import { Map } from 'immutable';

type Id = number;

interface Item {
  id: Id;
  documentsCount: number;
  description: string;
  title: string;
  authorInformation: {
    firstName: string;
    lastName: string;
    company: string;
    position: string;
    avatar: string;
    logo: string;
  }
}

interface State {
  itemsList: Id[];
  itemsMap: Map<number, Item>;
  activeItemId: number;
  loading: boolean;
}

interface AuthorInformationDTO {
  first_name: string;
  last_name: string;
  company: string;
  position: string;
  avatar: string;
  logo: string;
}

interface ItemDTO {
  id: number;
  name: string;
  description: string;
  documents_count: number;
  author_information: AuthorInformationDTO;

}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      itemsList: [],
      itemsMap: Map(),
      activeItemId: null,
      loading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  static createItem(item: ItemDTO): Item {
    return {
      id: item.id,
      authorInformation: {
        firstName: item.author_information.first_name,
        lastName: item.author_information.last_name,
        avatar: item.author_information.avatar,
        logo: item.author_information.logo,
        company: item.author_information.company,
        position: item.author_information.position,
      },
      title: item.name,
      documentsCount: item.documents_count,
      description: item.description,
    };
  }

  public addItem(params: ItemDTO) {
    const item = StateBuilder.createItem(params);

    const itemsList = [...this.state.itemsList, item.id];
    const itemsMap = this.state.itemsMap.set(item.id, item);

    this.state = {
      ...this.state,
      itemsList,
      itemsMap,
    };
  }

  public addItems(items) {
    items.forEach((item) => this.addItem(item));
  }

  public changeActiveItemId(id: Id) {
    this.state = {
      ...this.state,
      activeItemId: id,
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
