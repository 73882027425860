import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';

import { httpFetchSearchDocuments } from './fetchDocuments.http';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

export class FetchSearchDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(searchId: number, pageNumber?: number) {
    if (!searchId) {
      // eslint-disable-next-line no-param-reassign
      searchId = this.getState().searchResult.id;
    }

    if (!pageNumber) {
      // eslint-disable-next-line no-param-reassign
      pageNumber = this.getState().searchResult.documents.currentPageNumber;
    }

    sendAmplitudeData('go to page_search results', { page: pageNumber });
    Userpilot.track('go to page_search results', { page: pageNumber });

    // @ts-ignore
    const hasPage = this.getState().searchResult.documents.pages[pageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber(pageNumber);

      return;
    }

    this.dispatch(pageLoadingStart.createAction());

    try {
      const searchResult = await httpFetchSearchDocuments({ sourceId: searchId, pageNumber });
      this.dispatchGetDocumentsSucceed(searchId, pageNumber, searchResult);
      this.dispatchChangePageNumber(pageNumber);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetDocumentsSucceed(sourceId, pageNumber, searchResult) {
    const actionData = {
      sourceId,
      pageNumber,
      result: searchResult,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchSearchDocumentsThunk(dispatch, searchId, pageNumber) {
  const thunk = new FetchSearchDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(searchId, pageNumber);
}

export default fetchSearchDocumentsThunk;
