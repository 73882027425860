import {
  CourtTypeBlock, CourtTypeBlockData,
} from '../../form/filterBlocks/courtType/redux/courtType';

import { PresetsData } from './presets';

interface CourtTypeBlockDataTransform {
  id: number
  presetsData: PresetsData
}

export abstract class CourtTypeBlockHistory extends CourtTypeBlock {

  static transform({ id }, presetsData: CourtTypeBlockDataTransform): string {
    const presets = presetsData;

    const data = presets[this.type].reduce((acc: string, el: CourtTypeBlockData) => {
      let result = acc;
      const isEqual = el.id === id;
      if (isEqual) {
        result += el.title;
      }

      return result;
    }, '');

    return data;
  }

}

export default CourtTypeBlockHistory;
