import React from 'react';

import VisibilitySensor from 'react-visibility-sensor';

import { Loader } from '@components/loader';
import { CloseButton } from '@components/buttons';
import { Bookmark } from '@layouts/bookmarks/redux/state';

import { NotificationsListItemConnect } from './notificationsListItem.connect';

import s from './notificationsList.style';

interface NotificationsListProps {
  loading: boolean;
  shouldFetchMore: boolean;
  items: Bookmark[];
  disabled: boolean;
  onMount: () => void;
  onUnmount: () => void;
  onReset:() => void;
  onFetchNotifications: () => void;
  closePopover: () => void;
}

export class NotificationsList extends React.PureComponent<NotificationsListProps> {

  componentDidMount() {
    this.props.onMount();
  }

  componentWillUnmount() {
    this.props.onUnmount();
    this.props.onReset();
  }

  renderItem = (item) => <NotificationsListItemConnect key={item.id} {...item} />;

  handleVisible = (isVisible) => {
    if (isVisible) {
      this.props.onFetchNotifications();
    }
  };

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderBottomSensor() {
    if (this.props.loading) {
      return null;
    }

    if (!this.props.shouldFetchMore) {
      return null;
    }

    return (
      <VisibilitySensor onChange={this.handleVisible} partialVisibility>
        <s.BottomSensor />
      </VisibilitySensor>
    );
  }

  renderHeader() {
    return (
      <s.Header>
        <s.HeaderAction>
          <CloseButton
            color="#4183d7"
            onClick={this.props.closePopover}
          />
        </s.HeaderAction>
        <s.TitleContainer>
          <s.HeaderTitle>
            Центр уведомлений
          </s.HeaderTitle>
        </s.TitleContainer>
      </s.Header>
    );
  }

  renderListItems() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    if (!this.props.items.length) {
      return <s.Empty>Уведомлений пока нет</s.Empty>;
    }

    return (
      <s.Items>
        {this.props.items.map(this.renderItem)}
        {this.renderBottomSensor()}
      </s.Items>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderHeader()}
        <s.List>
          {this.renderListItems()}
        </s.List>
      </s.Root>
    );
  }

}

export default NotificationsList;
