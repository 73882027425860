import React from 'react';

import { RoutePath } from '@router/path';
import { UnauthRouteConnect } from '@router/unauthRoute.connect';
import { LoginWithBootstrap } from './login.connect';

export const RouteLogin = (
  <UnauthRouteConnect
    exact
    path={RoutePath.login}
    component={LoginWithBootstrap}
  />
);

export default RouteLogin;
