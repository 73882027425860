import React, { PureComponent } from 'react';

import { Loader } from '@components/loader';
import { Button } from '@components/button';

import { ProcessingStatus } from '../../searchResult/redux/state';

import { Block as FilterBlock } from './filterBlocks/block';
import { SmartBlockConnect } from './filterBlocks/smartFilter/block.connect';
import { AddNewBlock } from './addNewBlock/addNewBlock';

import s from './block.style';

interface BlockProps {
  sourceId?: string;
  resetButtonTitle?: string;
  saveButtonTitle?: string;
  renderSmartFilters?: boolean;
  filtersLoading?: boolean;
  filtersPreparing?: boolean;
  filtersPreparingFail?: boolean;
  isSubscription?: boolean;
  onAddBlock: (block) => void;
  onDuplicateBlock: (block) => void;
  onRemoveBlock: (block) => void;
  onPinBlock?: (block) => void;
  onCreateSearch: (block) => void;
  onUnmount?(): void;
  onResetForm: () => void;
  items: any[];
  processingStatus?: string;
}

export class Block extends PureComponent<BlockProps> {

  static defaultProps = {
    renderSmartFilters: false,
  };

  componentWillUnmount() {
    if (!this.props.onUnmount) {
      return;
    }

    this.props.onUnmount();
  }

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={24} />
      </s.Loader>
    );
  }

  renderSmartFiltersLoading = () => (
    <s.EmptyContainer>
      <s.PreparingLayout>
        {this.renderLoader()}
        <s.PreparingTitle>
          Готовим фильтры
        </s.PreparingTitle>
      </s.PreparingLayout>
    </s.EmptyContainer>
  );

  renderEmptyAIContainer = () => (
    <s.EmptyContainer>
      Используйте функцию ассистента или кнопку «Добавить фильтр» для составления поискового запроса
    </s.EmptyContainer>
  );

  renderEmptySubscriptionContainer = () => (
    <s.EmptyContainer>
      Используйте кнопку «Добавить фильтр» для составления поискового запроса
    </s.EmptyContainer>
  );

  renderFailContainer = () => (
    <s.FailContainer>
      Не удалось выделить фильтры из текста. Пожалуйста, попробуйте переформулировать сообщение
    </s.FailContainer>
  );

  renderFilterBlocks = () => this.props.items.map((item) => {
    if (!this.props.onPinBlock) {
      return (
        <s.Item
          key={item.id}
        >
          <FilterBlock
            item={item}
            onDuplicateBlock={this.props.onDuplicateBlock}
            onRemoveBlock={this.props.onRemoveBlock}
          />
        </s.Item>
      );
    }

    return (
      <s.Item
        key={item.id}
      >
        <FilterBlock
          item={item}
          onDuplicateBlock={this.props.onDuplicateBlock}
          onRemoveBlock={this.props.onRemoveBlock}
          onPinBlock={this.props.onPinBlock}
          isPinned={item.pinned}
        />
      </s.Item>
    );
  });

  renderFiltersBlocks() {
    if (this.props.filtersPreparing) {
      return this.renderSmartFiltersLoading();
    }

    const isRenderFail = this.props.filtersPreparingFail && !this.props.items.length;
    if (isRenderFail) {
      return this.renderFailContainer();
    }

    const isEmptyItems = !this.props.items.length;
    if (isEmptyItems && this.props.isSubscription) {
      return this.renderEmptySubscriptionContainer();
    }

    if (isEmptyItems && !this.props.filtersLoading) {
      return this.renderEmptyAIContainer();
    }

    return (
      <s.FilterBlocksContainer>
        {this.renderFilterBlocks()}
      </s.FilterBlocksContainer>
    );
  }

  renderAddFilterButton() {
    return (
      <s.Button>
        <AddNewBlock
          onAddBlock={this.props.onAddBlock}
        />
      </s.Button>
    );
  }

  renderResetButton() {
    const title = this.props.resetButtonTitle || 'Сбросить';

    return (
      <Button
        linkLike
        disabled={false}
        fullWidth={false}
        onClick={this.props.onResetForm}
      >
        {title}
      </Button>
    );
  }

  renderSaveButton() {
    const title = this.props.saveButtonTitle || 'Найти';
    const isProcessing = this.props.processingStatus === ProcessingStatus.processing;
    const hasNoItems = !!this.props.items.length;
    const isDisabled = isProcessing || !hasNoItems;

    return (
      <Button
        disabled={isDisabled}
        fullWidth={false}
        onClick={this.props.onCreateSearch}
      >
        {title}
      </Button>
    );
  }

  renderSmartFilters() {
    if (!this.props.renderSmartFilters) {
      return null;
    }

    return <SmartBlockConnect />;
  }

  renderFilters() {
    if (this.props.filtersLoading) {
      return (<s.FiltersLoader>{this.renderLoader()}</s.FiltersLoader>);
    }

    return (
      <>
        <s.FiltersList>
          {this.renderFiltersBlocks()}
        </s.FiltersList>
        <s.SearchControls>
          <s.Column
            verticalAlign="left"
          >
            {this.renderAddFilterButton()}
          </s.Column>
          <s.Column
            verticalAlign="right"
          >
            {this.renderResetButton()}
            {this.renderSaveButton()}
          </s.Column>
        </s.SearchControls>
      </>
    );
  }

  render() {
    return (
      <s.Form>
        {this.renderSmartFilters()}
        {this.renderFilters()}
      </s.Form>
    );
  }

}

export default Block;
