import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './empty.style';

const iconPath = iconsPath.get('shadowFolder');

export const Empty = () => (
  <s.Root>
    <s.Notification>
      <Icon path={iconPath} color="currentColor" size={72} viewBoxSize={72} />
      <s.Title>
        Нет закладок
      </s.Title>
    </s.Notification>
  </s.Root>
);

export default Empty;
