import { fetchMarkersReduce } from './fetchMarkers.reduce';

import type { ActionData } from './fetchMarkers.reduce';

const actionType = 'SEARCH/RESULT/DOCUMENTS/PREVIEW_SAVE_MARKERS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const saveMarkers = {
  actionType,
  createAction,
  reduce: fetchMarkersReduce,
};

export default saveMarkers;
