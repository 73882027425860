import React from 'react';

import s from './header.style';

export type Title = string;
interface HeaderProps {
  title: Title
}
export const Header = ({ title }: HeaderProps) => (
  <s.Root>
    <s.Title>{ title }</s.Title>
  </s.Root>
);

export default Header;
