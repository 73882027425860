import { StateBuilder } from '../../sidebar/redux/state';

export const unNotifyAllSubscriptionsReduce = (state) => {
  if (!state) {
    return state;
  }

  const layoutState = new StateBuilder(state.subscriptions);
  layoutState.toggleNotifications(false);
  const subscriptions = layoutState.getState();

  return {
    ...state,
    subscriptions,
  };
};

export default unNotifyAllSubscriptionsReduce;
