import { connect } from 'react-redux';
import moment from 'moment';

import { ScopeId } from '../filters/date/logic/date';

import { Title } from './title';

function formatDate(date) {
  return moment(date).format('DD.MM.yyyy');
}

function getTitle(filter) {
  const id = filter.scope.id;

  if (id === ScopeId.allTime) {
    return 'Поисковые запросы за все время';
  }

  if (id === ScopeId.today) {
    return 'Сегодня';
  }

  if (id === ScopeId.yesterday) {
    return 'Вчера';
  }

  if (id === ScopeId.dayBeforeYesterday) {
    return 'Позавчера';
  }

  if (id === ScopeId.threeDaysAgo) {
    return 'Три дня назад';
  }

  if (id === ScopeId.thisWeek) {
    return 'Текущая неделя';
  }

  if (id === ScopeId.lastWeek) {
    return 'Прошлая неделя';
  }

  if (id === ScopeId.currentMonth) {
    return 'Текущий месяц';
  }

  if (id === ScopeId.lastMonth) {
    return 'Прошлый месяц';
  }

  if (filter.value[0] && filter.value[1]) {
    return `Поисковые запросы c ${formatDate(filter.value[0])} по ${formatDate(filter.value[1])}`;
  }

  if (!filter.value[1]) {
    return `Поисковые запросы с ${formatDate(filter.value[0])}`;
  }

  return `Поисковые запросы по ${formatDate(filter.value[1])}`;

}

const mapStateToProps = (state) => {
  const { filters } = state.search.history;
  const title = getTitle(filters.data);

  return {
    title,
  };
};

export const TitleConnect = connect(
  mapStateToProps,
)(Title);

export default TitleConnect;
