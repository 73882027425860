import React from 'react';

import s from './folder.style';

interface FolderProps {
  id: number;
  title: string;
  onSelectItem(id: number): void;
}

export class Folder extends React.PureComponent<FolderProps> {

  handleFolderClick = (e) => {
    e.stopPropagation();

    this.props.onSelectItem(this.props.id);
  };

  render() {
    return (
      <s.Root
        onClick={this.handleFolderClick}
      >
        {this.props.title}
      </s.Root>
    );
  }

}

export default Folder;
