import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.span`
  float: right;
  font-size: 11px;
  padding-top: 2px;
  color: ${(props: Props) => props.theme.colors.stone}
`;

export default style;
