import { connect } from 'react-redux';

import { notifierManager } from '@components/notification';

import { fetchEventsThunk } from '../../redux/fetchEvents.thunk';
import { setActiveDate } from '../../redux/setActiveDate';

import { Events } from './events';

const FAIL_ID = '';

const mapStateToProps = (state) => ({
  activeDate: state.regulation.events.activeDate,
  nearestActiveDate: state.regulation.events.nearestActiveDate,
  hasProjects: state.regulation.events.hasProjects,
  eventsLoading: state.regulation.events.eventsLoading,
  items: state.regulation.events.events,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchEvents(date) {
    fetchEventsThunk(dispatch, date);
  },
  onSelectNearestDate(date) {
    if (!date.year) {
      notifierManager.fail({ id: FAIL_ID, message: 'Не найдено будущих событий в интервале одного года' });
      const removeNotification = () => {
        setTimeout(() => {
          notifierManager.remove({ id: FAIL_ID });
        }, 5000);
      };

      removeNotification();
    }

    dispatch(setActiveDate.createAction(date));
  },
});

export const EventsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Events);

export default EventsConnect;
