import {
  UserState,
  ActionData,
} from './state';

interface Action {
  type: string;
  data: ActionData;
}

export const fetchUserReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const userState = new UserState();
  userState.buildState(action.data);
  // eslint-disable-next-line
  const shouldSetPaymentStatus = action.data.paymentStatus && action.data.paymentStatus.result && action.data.paymentStatus.result.isPayment;
  if (shouldSetPaymentStatus) {
    userState.setPaymentInProgress(action.data.paymentStatus.result.isPayment);
  }

  return {
    ...state,
    ...userState.getState(),
  };
};

export default fetchUserReduce;
