import React, { PureComponent } from 'react';

import { Checkbox } from '@pages/regulation/workspace/projects/table/checkbox';
import {
  CheckStatus,
  Props,
  grid,
} from '@pages/regulation/workspace/projects/table/table';

import { ItemConnect } from './item.connect';

import s from './table.style';

export class Table extends PureComponent<Props> {

  renderItem = (item) => (
    <ItemConnect
      key={item}
      id={item}
      isActive={this.props.activeId === item}
      onItemClick={this.props.onItemClick}
      columns={this.props.columns}
    />
  );

  renderColumnInGroup() {
    return this.props.columns.map((column) => (<s.ColumnItem key={column.id} />));
  }

  renderHeaderItems() {
    return this.props.columns.map((column) => (
      <s.CellItem
        key={column.id}
      >
        <s.ColumnWidthItem
          column={grid[column.id]}
        >
          <s.CellContainer>
            <s.HeaderText>{column.title}</s.HeaderText>
          </s.CellContainer>
        </s.ColumnWidthItem>
      </s.CellItem>
    ));
  }

  renderCheckbox() {
    let check = false;
    if (this.props.checkStatus === CheckStatus.check) {
      check = true;
    }

    return (
      <s.CheckboxContainer>
        <Checkbox
          checked={check}
          disable={this.props.disableToggler}
          onClick={this.props.onToggleCheck}
        />
      </s.CheckboxContainer>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Table>
          <s.Group>
            <s.ColumnIsNew />
            <s.ColumnCheckbox />
            {this.renderColumnInGroup()}
            <s.ColumnAdditional />
          </s.Group>
          <s.Header>
            <s.CellIsNew>
              <s.ColumnWidthIsNew />
            </s.CellIsNew>
            <s.CellCheckbox>
              <s.ColumnWidthCheckbox>
                {this.renderCheckbox()}
              </s.ColumnWidthCheckbox>
            </s.CellCheckbox>
            {this.renderHeaderItems()}
            <s.CellAdditional>
              <s.ColumnWidthAdditional />
            </s.CellAdditional>
            <s.CellAdditional>
              <s.ColumnWidthAdditional />
            </s.CellAdditional>
          </s.Header>
          <s.Body>{this.props.items.map(this.renderItem)}</s.Body>
        </s.Table>
      </s.Root>
    );
  }

}

export default Table;
