// eslint-disable-next-line max-len
import { changePreviewDocumentReduce } from '@pages/searchResult/documents/preview/redux/changePreviewDocument.reduce';

const actionType = 'PACKAGE/DOCUMENT_PREVIEW__CHANGE_PARAMS';

const createAction = (data: { documentId: number }) => ({
  type: actionType,
  data,
});

export const changePreviewDocument = {
  actionType,
  createAction,
  reduce: changePreviewDocumentReduce,
};

export default changePreviewDocument;
