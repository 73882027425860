import {
  styled,
  css,
} from '@components/styled';

interface Props {
  isEmpty: boolean;
}

const style: any = {};

style.Items = styled.div`
  height: 100%;
  padding: 8px 0;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.EmptyTitle = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.stone};
`;

style.Root = styled.div`
  overflow-y: auto;
  height: auto;

  ${(props: Props) => props.isEmpty && css`
    height: 100%;
  `}
`;

export default style;
