import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { getSource } from '@pages/regulation/utils/marketing';

import { setLayoutSettings } from './setLayoutSettings';
import { httpSaveLayoutSettings } from './saveLayoutSettings.http';

import type {
  Layout,
  ViewType,
} from './state';

export type ViewTypes = ViewType.list | ViewType.table;

export class SaveLayoutSettingsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(viewType: ViewTypes) {
    const { layout } = this.getState().regulation.projects;

    if (layout.viewType === viewType) {
      return;
    }

    const newLayout = {
      viewType,
    };

    const id = this.getState().regulation.folders.activeItemId;

    this.marketingEvent(viewType, id);

    this.dispatchSetSettings(newLayout);

    try {
      await httpSaveLayoutSettings(newLayout);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private marketingEvent(viewType, id) {
    const source = getSource(id);

    sendAmplitudeData('regulation:change_viewtype', { viewType, source });
    Userpilot.track('regulation:change_viewtype', { viewType });
  }

  private dispatchSetSettings(layout: Layout) {
    this.dispatch(setLayoutSettings.createAction({ layout }));
  }

}

export function saveLayoutSettingsThunk(dispatch, param: ViewTypes) {
  const thunk = new SaveLayoutSettingsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(param);
}

export default SaveLayoutSettingsThunk;
