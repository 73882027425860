import { connect } from 'react-redux';

import {
  RESTPath,
  DownloadFormat,
} from '@router/path.rest';

import {
  buildDownloadChronologyItems,
  buildDownloadSimilarItems,
} from '@pages/searchResult/documents/utils';

import { DownloadPopover } from '../components/download/downloadPopover';

enum Title {
  original = 'Оригинал документа',
  withMarkers = 'Документ с маркерами',
  docx = 'Документ DOCX',
}

function buildItems(params) {
  const downloadFormats = [DownloadFormat.docx, DownloadFormat.original, DownloadFormat.withMarkers];

  return downloadFormats.map((item) => {
    const url = RESTPath.document.download({ ...params, format: item });

    return {
      title: Title[item],
      onClick: () => window.open(`${window.location.origin}${url}`),
    };
  });

}

const mapStateToProps = (state) => {

  const params = {
    source: state.document.source,
    sourceId: state.document.sourceId,
    documentId: state.document.id,
  };

  let items = [];

  if (state.document.isChronologyDocument) {
    items = buildDownloadChronologyItems({
      documentId: state.document.parentDocumentId,
      chronologyDocumentId: params.documentId,
    });

    return {
      items,
    };
  }

  if (state.document.isSimilarDocument) {
    items = buildDownloadSimilarItems(params.documentId);

    return {
      items,
    };
  }

  items = buildItems(params);

  return {
    items,
  };
};

export const DownloadConnect = connect(
  mapStateToProps,
)(DownloadPopover);

export default DownloadConnect;
