import styled, { css } from 'styled-components';

export interface Props {
  theme: any;
  isActive: boolean;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  border-radius: 2px;

  width: 32px;
  height: 32px;
`;

style.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 2px;

  color: ${(props) => props.theme.colors.stone};
  background-color: ${(props) => props.theme.colors.white};

  cursor: pointer;

  transition: all .3s;

  user-select: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  &:active {
    background-color: rgba(65, 131, 215, 0.12);
  }

  ${(props: Props) => props.isActive && css`
    color: ${props.theme.colors.defaultText};
    background-color: rgba(65, 131, 215, 0.12);

    &:hover {
      background-color: rgba(65, 131, 215, 0.12);
    }

  `}
`;

export default style;
