import { addMessageThunk } from '@pages/regulation/redux/chat/addMessage.thunk';

export enum Events {
  regulationChatMessageCreate = 'regulation:chat:message:create',
  regulationChatMessageEdit = 'regulation:chat:message:edit',
  regulationChatMessageDelete = 'regulation:chat:message:delete',
}

export class RegulationSocketManager {

  private static instance: RegulationSocketManager;

  private dispatch;

  private strategies;

  private constructor(dispatch) {
    this.dispatch = dispatch;

    this.initStrategies();
  }

  static getInstance(dispatch) {
    if (!RegulationSocketManager.instance) {
      RegulationSocketManager.instance = new RegulationSocketManager(dispatch);
    }

    return RegulationSocketManager.instance;
  }

  public getStrategy(event: Events) {
    return this.strategies.get(event);
  }

  private initStrategies() {
    this.strategies = new Map();

    this.strategies.set(Events.regulationChatMessageCreate, this.regulationChatMessageCreateStrategy);
  }

  private regulationChatMessageCreateStrategy = (response) => addMessageThunk(this.dispatch, response);

}

export function regulationSocketManager(dispatch, response) {
  const manager = RegulationSocketManager.getInstance(dispatch);

  const strategy = manager.getStrategy(response.type);
  if (!strategy) {
    return;
  }

  strategy(response);
}

export default RegulationSocketManager;
