import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './notFound.style';

export const NotFound = (props: { title?: string }) => {
  const iconPath = iconsPath.get('notFound');

  const title = props.title || 'Ничего не найдено';

  return (
    <s.Root>
      <s.IconBlock>
        <s.Icon>
          <Icon viewBoxSize={72} size={72} path={iconPath} color="#6c7b89" />
          <s.IconTitle>{title}</s.IconTitle>
        </s.Icon>
      </s.IconBlock>
    </s.Root>
  );
};

export default NotFound;
