import { connect } from 'react-redux';

import { UserStateI } from '../redux/state';

import { ProfileButton } from './profileButton';

function buildTitle(params: UserStateI) {
  let title = '';
  if (params.firstName) {
    title += params.firstName;
  }

  if (params.lastName) {
    title += ` ${params.lastName}`;
  }

  return title;
}

const mapStateToProps = (state) => {
  const title = buildTitle(state.currentUser);

  return {
    title,
  };
};

export const ProfileButtonConnect = connect(
  mapStateToProps,
)(ProfileButton);

export default ProfileButtonConnect;
