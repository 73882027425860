import React from 'react';

import { DocumentId } from '../../redux/state';

import { TotalCheckbox } from '../components/totalCheckbox';

import { TotalCheckStatus } from './list.connect';

import { ItemConnect } from './item.connect';

import cellMore from './cellMore.style';
import s from './list.style';

interface Props {
  items: DocumentId[],
  totalCheckStatus: TotalCheckStatus,
  documentPreviewId: number;
  onItemClick(id: DocumentId): void;
  onChangeSelectionRule(): void;
}

export class List extends React.PureComponent<Props> {

  renderItem = (item) => (
    <ItemConnect
      key={item}
      id={item}
      isActive={this.props.documentPreviewId === item}
      onItemClick={this.props.onItemClick}
    />
  );

  render() {
    return (
      <s.Root>
        <s.Table>
          <s.Group>
            <s.ColumnCheckbox />
            <s.ColumnDocument />
            <s.ColumnText />
            <cellMore.ColumnMore />
            <cellMore.ColumnMore />
          </s.Group>

          <s.Header>
            <s.CellCheckbox>
              <s.ColumnWidthCheckbox>
                <s.CellContainer>
                  <TotalCheckbox
                    status={this.props.totalCheckStatus}
                    onClick={this.props.onChangeSelectionRule}
                  />
                </s.CellContainer>
              </s.ColumnWidthCheckbox>
            </s.CellCheckbox>

            <s.CellDocument>
              <s.ColumnWidthDocument>
                <s.CellContainer>
                  <s.HeaderText>Документ</s.HeaderText>
                </s.CellContainer>
              </s.ColumnWidthDocument>
            </s.CellDocument>

            <s.CellText>
              <s.ColumnWidthText>
                <s.CellContainer>
                  <s.HeaderText>Информация</s.HeaderText>
                </s.CellContainer>
              </s.ColumnWidthText>
            </s.CellText>

            <cellMore.CellMore>
              <cellMore.ColumnWidthMore />
            </cellMore.CellMore>
            <cellMore.CellMore>
              <cellMore.ColumnWidthMore />
            </cellMore.CellMore>
          </s.Header>

          <s.Body>{this.props.items.map(this.renderItem)}</s.Body>
        </s.Table>
      </s.Root>
    );
  }

}

export default List;
