import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { removeSpacialCharactersFromValue } from '@utils/utils';

import { SearchField } from '@components/searchField/searchField';
import { PackageListConnect as PackageList } from './packageList.connect';

import s from './addPackagePopover.style';

export interface AddPackagePopoverProps {
  packages: any;
  onInit(): void;
  onResetToInitial(): void;
  onPackageToggle(id: number): void;
  onPackageCreate(query: string): void;
}

export interface AddPackagePopoverState {
  searchQuery: string;
  addingPackage: boolean;
}

export class AddPackagePopover extends React.PureComponent<AddPackagePopoverProps, AddPackagePopoverState> {

  private placeholder = 'Найти или создать подборку';

  state = {
    searchQuery: '',
    addingPackage: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {} as AddPackagePopoverState;

    state.addingPackage = nextProps.packages.addingPackage;

    if (prevState.addingPackage && !nextProps.packages.addingPackage) {
      state.searchQuery = '';
    }

    return isEmpty(state) ? null : state;
  }

  componentDidMount() {
    this.onInit();
  }

  componentWillUnmount() {
    this.onResetToInitial();
  }

  private onInit() {
    this.props.onInit();
  }

  private onResetToInitial() {
    this.props.onResetToInitial();
  }

  private setSearchQuery(value: string) {
    this.setState({
      searchQuery: value,
    });
  }

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = removeSpacialCharactersFromValue(e.target.value);
    value = value.slice(0, 80);

    this.setSearchQuery(value);
  };

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => e.stopPropagation();

  render() {
    return (
      <s.Container onClick={this.stopPropagation}>
        <SearchField
          value={this.state.searchQuery}
          placeholder={this.placeholder}
          onChange={this.handleSearchQuery}
        />
        <PackageList
          packages={this.props.packages}
          searchQuery={this.state.searchQuery}
          onPackageToggle={this.props.onPackageToggle}
          onPackageCreate={this.props.onPackageCreate}
        />
      </s.Container>
    );
  }

}

export default AddPackagePopover;
