import React from 'react';

import { TextField } from '@components/textField/textField';

import s from './description.style';

interface DescriptionProps {
  data: string;
  loading: boolean;
  activeItemId: number;
  onDescriptionUpdate(data): void;
}

export class Description extends React.PureComponent<DescriptionProps> {

  handleDescriptionUpdate = (value) => {
    const data = this.prepareData(value);
    this.props.onDescriptionUpdate(data);
  };

  prepareData = (value) => ({
    id: this.props.activeItemId,
    description: value.trim(),
  });

  render() {
    const shouldRender = !!this.props.activeItemId && !this.props.loading;
    if (!shouldRender) {
      return null;
    }

    return (
      <s.Root>
        <TextField
          label="Описание подборки"
          value={this.props.data}
          onChange={this.handleDescriptionUpdate}
        />
      </s.Root>
    );
  }

}

export default Description;
