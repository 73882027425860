import React from 'react';

import { theme } from '@components/styled';

import { UserRoleType } from '@router/pathPermissions';

import {
  iconsPath,
  Icon,
} from '@components/icons';

import s from './notification.style';

interface NotificationProps {
  licenseEndTitle: string;
  role: UserRoleType;
}

export const HeaderNotification = (props: NotificationProps) => {
  const iconPath = iconsPath.get('allert');

  const handleHeaderActionClick = () => {
    window.location.href = '#/paymentselect';
  };

  if (props.role !== UserRoleType.trial) {
    return null;
  }

  return (
    <s.Root>
      <s.Title>
        <s.Icon>
          <Icon path={iconPath} color={theme.colors.white} />
        </s.Icon>
        <s.Text>
          {props.licenseEndTitle}
        </s.Text>
      </s.Title>
      <s.Action
        onClick={handleHeaderActionClick}
      >
        Выбрать тариф
      </s.Action>
    </s.Root>
  );
};

export default HeaderNotification;
