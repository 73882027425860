import { connect } from 'react-redux';

import {
  CheckProjectsThunk,
  checkProjectsThunk,
} from '../../redux/checkProjects.thunk';

import { CheckStatus } from '../../projects/table/table';

import { List } from './list';

const mapStateToProps = (state) => {
  let checkStatus = CheckStatus.uncheck;
  let disableToggler = true;

  const {
    projects,
    selectProjects,
  } = state.regulation.projects;

  if (projects.itemsList.length === selectProjects.length) {
    checkStatus = CheckStatus.check;
  }

  if (state.regulation.projects.totalProjects <= CheckProjectsThunk.SELECT_ALL_LENGTH) {
    disableToggler = false;
  }

  return {
    disableToggler,
    checkStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onToggleCheck() {
    checkProjectsThunk(dispatch);
  },
});

export const ListConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(List);

export default ListConnect;
