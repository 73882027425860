import { updateMessageReduce } from './updateMessage.reduce';

import type { ActionData } from './updateMessage.reduce';

const actionType = 'REGULATION/CHAT/UPDATE_MESSAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateMessage = {
  actionType,
  createAction,
  reduce: updateMessageReduce,
};

export default updateMessage;
