import React from 'react';

import s from './counter.style';

interface Shorten {
  value: number;
  stringValue: string;
}

function buildTitle(count: number): string {
  if (!count) {
    return null;
  }

  if (count < 1000) {
    return `+${count}`;
  }

  const arrayOfShorten: Shorten[] = [
    {
      value: 1E6,
      stringValue: 'M',
    },
    {
      value: 1E3,
      stringValue: 'K',
    },
  ];

  const index = arrayOfShorten.findIndex((shorten: Shorten) => count >= shorten.value);
  const newNumber = count / arrayOfShorten[index].value;
  const result = newNumber.toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + arrayOfShorten[index].stringValue;

  return `+${result}`;
}

interface CounterProps {
  count: number;
}

export const Counter = (props: CounterProps) => (
  <s.Root>
    {buildTitle(props.count)}
  </s.Root>
);

export default Counter;
