import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateModeReduce } from './updateMode.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/ANOTHER_PERSON/UPDATE_MODE`;

export interface Data {
  id: string;
  data: {
    id: string;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateMode = {
  actionType,
  createAction,
  reduce: updateModeReduce,
};

export default updateMode;
