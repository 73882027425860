import { connect } from 'react-redux';

import {
  CheckProjectsThunk,
  checkProjectsThunk,
} from '../../redux/checkProjects.thunk';

import {
  Table,
  CheckStatus,
} from './table';

const mapStateToProps = (state) => {
  const columns = [];

  columns.push({
    id: 'projectNumber',
    title: 'Номер проекта',
  });

  columns.push({
    id: 'title',
    title: 'Наименование',
  });

  columns.push({
    id: 'lastEventDate',
    title: 'Дата последнего события',
  });

  columns.push({
    id: 'lastEvent',
    title: 'Последнее событие',
  });

  columns.push({
    id: 'currentPhase',
    title: 'Текущая стадия',
  });

  columns.push({
    id: 'author',
    title: 'Разработчик',
  });

  columns.push({
    id: 'type',
    title: 'Тип проекта',
  });

  columns.push({
    id: 'branchOfLegislation',
    title: 'Вид экономической деятельности',
  });

  let checkStatus = CheckStatus.uncheck;
  let disableToggler = true;

  const {
    projects,
    selectProjects,
  } = state.regulation.projects;

  if (projects.itemsList.length === selectProjects.length) {
    checkStatus = CheckStatus.check;
  }

  if (state.regulation.projects.totalProjects <= CheckProjectsThunk.SELECT_ALL_LENGTH) {
    disableToggler = false;
  }

  return {
    columns,
    disableToggler,
    checkStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onToggleCheck() {
    checkProjectsThunk(dispatch);
  },
});

export const TableConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Table);

export default TableConnect;
