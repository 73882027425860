import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Button } from '@pages/searchResult/components/button/button';

import { Menu } from './menu';

import s from './createSubscription.style';

interface Props {
  subscriptionExist: boolean;
  disabled: boolean;
  onClick(title?: string): void;
}

export const CreateSubscription = (props: Props) => {
  if (props.subscriptionExist) {
    return (
      <Button
        disabled={props.disabled}
        onClick={props.onClick}
        title="Отписаться"
      />
    );
  }

  return (
    <s.Root>
      <BaseDropdown
      // @ts-ignore
        usePortal
        placement="bottom-end"
        renderInput={(p) => (
          <Button
            title="Подписаться"
            disabled={props.disabled}
            onClick={() => {}}
            {...p}
          />
        )}
        renderMenu={(p) => (
          <Menu
            onSave={props.onClick}
            {...p}
          />
        )}
      />
    </s.Root>
  );

};

export default CreateSubscription;
