import React, { useState } from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { SettingsMenu } from './settingsMenu';
import { Item } from './download';

import s from './settings.style';

interface InputComponentProps {
  isActive: boolean;
}

const InputComponent = (props: InputComponentProps) => {
  const iconPath = iconsPath.get('kebab');

  return (
    <s.IconBlock>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

type Id = number;

interface SettingsPopoverProps {
  id: Id;
  source: string;
  sourceId: Id,
  requisites: string;
  downloadItems: Item[];
}

export const Settings = (props: SettingsPopoverProps) => {
  const [wide, setWide] = useState(false);
  const [level, setLevel] = useState(0);

  const handleLevelChange = (params: { wide: boolean; level: number }) => {
    setWide(params.wide);
    setLevel(params.level);
  };

  const renderMenu = (p) => (
    <SettingsMenu {...p} {...props} onLevelChange={handleLevelChange} />
  );

  return (
    <s.IconBlock>
      <BaseDropdown
        // @ts-ignore
        usePortal
        forceLeftRecalculate
        wide={wide}
        level={level}
        placement="bottom-end"
        renderInput={(p) => <InputComponent {...p} {...props} />}
        renderMenu={(p) => renderMenu(p)}
      />
    </s.IconBlock>
  );
};

export default Settings;
