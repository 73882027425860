import { css } from 'styled-components';

import { styled } from '@components/styled';

interface Props {
  isDone: boolean;
}

const style: any = {};

style.Root = styled.div`
  flex-shrink: 0;
  padding: 0 0 32px 32px;

  &.notification-enter {
    transform: translate3d(-100%, 0, 0);
  }

  &.notification-enter-active {
    transform: translate3d(0, 0, 0);
    transition: all 0.5s;
  }

  &.notification-exit {
    transform: translate3d(0, 0, 0);
  }

  &.notification-exit-active {
    transform: translate3d(-100%, 0, 0);
    transition: all 0.5s;
  }
`;

style.Container = styled.div`
  display: flex;
  min-height: 40px;
  background-color: #30404f;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  pointer-events: auto;
  border-radius: 4px;
`;

style.Content = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 12px;

  ${(props) => props.isDone && css`
    align-items: center;
    padding: 4px 12px;
  `}
`;

style.Message = styled.div<Props>`
  font-size: 13px;
  color: ${(props) => props.theme.colors.white};
  white-space: break-spaces;
`;

style.Loader = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  margin-left: 8px;
  margin-top: 4px;

  > div {
    border: 2px solid transparent;
    border-color: white white #30404f;
  }
`;

style.IconBlock = styled.div`
  position: relative;

  width: 32px;
  height: 32px;
  padding-top: 4px;
`;

style.Link = styled.a<Props>`
  font-size: 13px;
  color: ${(props) => props.theme.colors.orange};
  text-transform: uppercase;
  font-family: Roboto-Medium;
  text-decoration: none;
  margin: 0 8px 0 33px;
`;

export default style;
