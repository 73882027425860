import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpRemoveSubscription(requestParams: { id: number }) {
  const request = http.delete({
    url: RESTPath.subscriptions.delete(requestParams),
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpRemoveSubscription;
