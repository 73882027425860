import { fetchNoteReduce } from './fetchNote.reduce';

import type { ActionData } from './fetchNote.reduce';

const actionType = 'REGULATION/PREVIEW__FETCH_NOTE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchNote = {
  actionType,
  createAction,
  reduce: fetchNoteReduce,
};

export default fetchNote;
