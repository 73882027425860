import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';
import { UserRoleType } from '@pages/userPages/redux/state';

import { addBookmarkThunk } from '@layouts/bookmarks/redux/addBookmark.thunk';
import { addCommentaryThunk } from '@layouts/bookmarks/redux/addCommentary.thunk';
import { deleteBookmarkFromBufferThunk } from '@layouts/bookmarks/redux/deleteBookmark.thunk';

import { Block } from './block';

export function isParagraphInBookmarks(queue, params) {
  const targetItem = queue.find((item) => Number(item.paragraphId) === Number(params.block.id));

  return {
    paragraphInBookmarks: !!targetItem,
    commentary: targetItem ? targetItem.commentary : '',
  };
}

const mapStateToProps = (state, ownProps) => {
  const { paragraphInBookmarks, commentary } = isParagraphInBookmarks(state.bookmarksBuffer.queue, ownProps);

  return {
    paragraphInBookmarks,
    commentary,
    isReal: state.currentUser.role !== UserRoleType.guest,
  };

};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddBookmark(text) {
    const params = {
      paragraphId: Number(ownProps.block.id),
      text,
      title: `${ownProps.body.title} от ${ownProps.body.date}`,
      source: ownProps.source,
      sourceId: ownProps.sourceId,
      documentId: Number(ownProps.documentId),
      path: `${ownProps.source}/${ownProps.sourceId}/documents/${ownProps.documentId}`,
    };

    if (ownProps.source === Source.authorPackage) {
      params.path = `${Source.authorPackage}s/${ownProps.sourceId}/documents/${ownProps.documentId}`;
    }

    addBookmarkThunk(dispatch, params);
  },
  onAddCommentary(commentary) {
    const params = {
      paragraphId: Number(ownProps.block.id),
      commentary,
    };

    addCommentaryThunk(dispatch, params);
  },
  onRemoveBookmark() {
    const params = {
      paragraphId: Number(ownProps.block.id),
    };

    deleteBookmarkFromBufferThunk(dispatch, params);
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
