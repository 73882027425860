/* eslint-disable */

import React from 'react';
import ReactDOM from 'react-dom';
import isEqual from 'lodash/isEqual';
import s from './textFitCalculationComponent.style';

interface TextFitCalculationComponentProps {
  title?: string
  parentRef?: React.RefObject<HTMLDivElement>
  children?: JSX.Element
  columns?: number
  handleFit: (isFit: boolean) => void
}
class TextFitCalculationComponent extends React.Component<TextFitCalculationComponentProps> {

  hiddenColumnRef;

  childVisibleTrue = true;

  constructor(props) {
    super(props);

    this.hiddenColumnRef = React.createRef<HTMLDivElement>();
  }

  state = {
    parentWidth: 0,
    childVisible: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps) {
    const isAmountOfColumnsWasChanged = !isEqual(prevProps.columns, this.props.columns);

    if (isAmountOfColumnsWasChanged) {
      this.afterParentRefWasChanged(this.mount);
    }

    // @ts-ignore
    const propsOffsetWidth = this.props.parentRef.current ? this.props.parentRef.current.offsetWidth : null;

    const isParentWidthWasChanged = !isEqual(propsOffsetWidth, this.state.parentWidth);

    if (isParentWidthWasChanged) {
      this.afterParentRefWasChanged(this.mount);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  afterParentRefWasChanged = (func) => {
    // @ts-ignore
    this.setState({ parentWidth: this.props.parentRef.current.offsetWidth }, func);
  };

  resize = () => {
    // @ts-ignore
    if (this.props.parentRef.current && this.props.parentRef.current.offsetWidth !== this.state.parentWidth) {
      this.afterParentRefWasChanged(this.mount);
    }
  };

  unmount(): void {
    this.setState({ childVisible: false });
  }

  mount(): void {
    if (this.state.childVisible !== this.childVisibleTrue) {
      this.setState({ childVisible: this.childVisibleTrue }, this.compareWidth);
    }
  }

  compareWidth(): void {
    const isFit = !(this.hiddenColumnRef.current.offsetWidth >= this.state.parentWidth);

    this.props.handleFit(isFit);
    this.unmount();
  }

  render() {
    // @ts-ignore
    if (!this.props.parentRef.current || !this.state.childVisible) {
      return null;
    }

    return ReactDOM.createPortal(
      <s.TextFitCalculationComponentWrapper ref={this.hiddenColumnRef}>
        {this.props.children}
      </s.TextFitCalculationComponentWrapper>,
      // @ts-ignore
      this.props.parentRef.current,
    );
  }

}

export default TextFitCalculationComponent;
