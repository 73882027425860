import { Dispatch } from 'redux';

import { fetchSearch } from './fetchSearch';

import { httpFetchSearch } from './fetchSearch.http';

export class FetchSearchThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(searchId: number) {
    try {
      const searchResult = await httpFetchSearch(searchId);
      this.dispatchFetchSearchSucceed(searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSearchSucceed(searchResult) {
    const actionData = {
      ...searchResult,
    };

    this.dispatch(fetchSearch.createAction(actionData));
  }

}

export function fetchSearchThunk(dispatch, searchId) {
  const thunk = new FetchSearchThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(searchId);
}

export default fetchSearchThunk;
