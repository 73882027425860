import { RESTPath } from '@router/path.rest';
import http from '@http';

type Query = string;
type PageNumber = number;

export interface GetSubscriptionsParams {
  query: Query;
  pageNumber: PageNumber;
}

export function httpFetchSubscriptions(requestParams) {
  const url = RESTPath.subscriptions.get(requestParams);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSubscriptions;
