import { connect } from 'react-redux';
import queryString from 'query-string';

import { getParamsFromUrl } from '@utils/utils';
import { fetchLawDocumentThunk } from './redux/fetchLawDocument.thunk';
import { updateLawDocumentThunk } from './redux/updateLawDocument.thunk';

import { LawDocument } from './lawDocument';

const mapStateToProps = (state, ownProps) => {
  const values = queryString.parse(ownProps.location.search);

  let article = null;
  let articleId = null;
  if (!state.lawDocument.document.loading) {
    article = state.lawDocument.document.titles.find((title) => title.id === values.articleId);
  }

  if (article) {
    articleId = article.id;
  }

  return {
    loading: state.lawDocument.document.loading,
    articleId,
    editionDate: values.editionDate,
  };

};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    const {
      documentId,
    } = getParamsFromUrl(ownProps);

    const values = queryString.parse(ownProps.location.search);

    const requestParams = {
      documentId,
      articleId: values.articleId,
      editionDate: values.editionDate,
    };

    fetchLawDocumentThunk(dispatch, requestParams);
  },
  onUpdate() {
    const {
      documentId,
    } = getParamsFromUrl(ownProps);

    const values = queryString.parse(ownProps.location.search);

    const requestParams = {
      documentId,
      articleId: values.articleId,
      editionDate: values.editionDate,
    };

    updateLawDocumentThunk(dispatch, requestParams);
  },
});

export const LawDocumentConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LawDocument);

export default LawDocumentConnect;
