import { connect } from 'react-redux';

import {
  buildRequisitesForClipboard,
  buildDownloadChronologyItems,
} from '@pages/searchResult/documents/utils';

import { Settings } from './settings/settings';

const mapStateToProps = (state, ownProps) => {

  const chronologyDocumentId = ownProps.id;
  const documentId = state.chronology.documentId;

  return {
    requisites: buildRequisitesForClipboard(chronologyDocumentId, state.chronology),
    downloadItems: buildDownloadChronologyItems({
      documentId,
      chronologyDocumentId,
    }),
  };
};

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
