import { connect } from 'react-redux';

import { resetToInitial } from '../redux/resetToInitial';

import { addBlock } from './redux/addBlock';
import { duplicateBlock } from './redux/duplicateBlock';
import { removeBlock } from './redux/removeBlock';
import { updatePin } from './redux/updatePin';
import { createSearch } from './redux/createSearch.thunk';
import { savePinnedFiltersThunk } from './redux/savePinnedFilters.thunk';
import { fetchDefaultFiltersThunk } from './redux/fetchDefaultFilters.thunk';

import { Block } from './block';

const mapStateToProps = (state) => {
  const { query } = state.search;
  const { activeHistoryId } = state.search.history;
  const items = query.blocksList.map((item) => (
    query.blocksMap.get(item)
  ));

  return {
    items,
    filtersLoading: state.search.defaultQuery.loading,
    activeHistoryId,
    processingStatus: state.searchResult.processingStatus,
    filtersPreparing: state.search.query.smartFilter.preparing,
    filtersPreparingFail: state.search.query.smartFilter.prepareFail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddBlock(type) {
    dispatch(addBlock.createAction({ type }));
  },
  onDuplicateBlock(data) {
    dispatch(duplicateBlock.createAction(data));
  },
  onRemoveBlock(data) {
    dispatch(removeBlock.createAction(data));
  },
  onPinBlock(data) {
    dispatch(updatePin.createAction(data));
    savePinnedFiltersThunk(dispatch);
  },
  async onResetForm() {
    dispatch(resetToInitial.createAction());
    fetchDefaultFiltersThunk(dispatch, { withPinned: true });
  },
  onCreateSearch() {
    createSearch(dispatch);
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
