import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './field.style';

interface FieldProps {
  title: string;
  value: string;
  hasAction: boolean;
  confirmed?: boolean;
  onActionClick?: () => void;
  width: number;
  hasRightBorder?: boolean;
}

export class Field extends React.PureComponent<FieldProps> {

  static defaultProps = {
    onActionClick: () => {},
    confirmed: false,
    // eslint-disable-next-line react/default-props-match-prop-types
    hasAction: false,
    hasRightBorder: false,
  };

  renderCredential() {
    if (!this.props.value) {
      return (
        <s.ValueContainer>
          <s.Placeholder>{this.props.title}</s.Placeholder>
        </s.ValueContainer>
      );
    }

    return (
      <>
        <s.Title>{this.props.title}</s.Title>
        <s.ValueContainer>
          <s.Value>{this.props.value}</s.Value>
        </s.ValueContainer>
      </>
    );
  }

  renderAction() {
    if (this.props.confirmed) {
      const icon = iconsPath.get('confirmedCredential');

      return (
        <s.ActionIcon>
          <Icon points={icon} color="#59b671" />
        </s.ActionIcon>
      );
    }

    const icon = iconsPath.get('nonConfirmedCredential');

    return (
      <s.ActionIcon>
        <Icon path={icon} color="#6c7b89" />
      </s.ActionIcon>
    );
  }

  render() {
    return (
      <s.Root
        width={this.props.width}
        hasRightBorder={this.props.hasRightBorder}
      >
        <s.Credential>
          {this.renderCredential()}
        </s.Credential>
        <s.Action
          hasAction={this.props.hasAction}
        >
          {this.props.value && this.renderAction()}
        </s.Action>
      </s.Root>
    );
  }

}

export default Field;
