import { StateBuilder } from './state';

export interface ActionData {
  sourceId: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const selectPackageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.packages);
  newState.changeActiveItemId(action.data.sourceId);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default selectPackageReduce;
