import { connect } from 'react-redux';

import { RequisitesCopyPopover } from '@pages/document/components/requisitesCopy/requisitesCopyPopover';

import { buildRequisitesForClipboard } from '../utils';

const mapStateToProps = (state) => ({
  // eslint-disable-next-line max-len
  requisites: buildRequisitesForClipboard(state.searchResult.documents.previewDocument.documentId, {
    ...state.searchResult.documents,
    currentPageNumber: state.searchResult.documents.previewDocument.currentPageNumber,
  }),
});

export const RequisitesCopyConnect = connect(
  mapStateToProps,
)(RequisitesCopyPopover);

export default RequisitesCopyConnect;
