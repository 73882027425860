import styled, { css } from 'styled-components';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  isActive: boolean;
  isBlue?: string;
  column?: string
}

const table = {
  MINIMAL_COLUMN: 1,
  REGULAR_COLUMN: 2,
  MIDDLE_COLUMN: 3,
  LARGE_COLUMN: 4,
};

function textEllipsis() {
  return `
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

function getColor(props: Props) {
  if (props.isBlue) {
    return props.theme.colors.blue;
  }

  return props.theme.colors.defaultText;
}

function getWidth(props: Props, isLarger: boolean) {
  const MAX_SIZE = 96;
  const MIN_SIZE = 80;
  const size = isLarger ? MAX_SIZE : MIN_SIZE;
  const result = size * table[props.column];

  return result || size * 2;
}

const style: any = {};

const width = {
  isNew: '8px',
  checkbox: '48px',
  item: '100%',
  additional: '48px',
};

style.Root = styled.div`
  font-size: 13px;
  background-color: ${(props: Props) => props.theme.colors.white};
`;

style.Table = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

style.Group = styled.div`
  display: table-column-group;
`;

style.ColumnWidthAdditional = styled.div`
  height: 100%;
  width: ${width.additional};
`;

style.ColumnWidthItem = styled.div`
  min-width: ${(props: Props) => getWidth(props, false)}px;
  max-width: 100%;

  @media (min-width: 1408px) {
    min-width: ${(props: Props) => getWidth(props, true)}px;
  }
`;

style.ColumnWidthCheckbox = styled.div`
  height: 100%;
  width: ${width.checkbox};
`;

style.ColumnWidthIsNew = styled.div`
  height: 100%;
  width: ${width.isNew};
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

style.IsNewCellContainer = styled(style.CellContainer)`
  padding: 0;
`;

style.CheckboxCellContainer = styled(style.CellContainer)`
  padding: 16px 12px;
`;

style.BodyCellContainer = styled(style.CellContainer)`
  padding: 16px 12px;
`;

style.ColumnCheckbox = styled(style.ColumnWidthCheckbox)`
  display: table-column;
`;

style.ColumnIsNew = styled(style.ColumnWidthIsNew)`
  display: table-column;
`;

style.ColumnAdditional = styled(style.ColumnWidthAdditional)`
  display: table-column;
`;

style.ColumnItem = styled(style.ColumnWidthItem)`
  display: table-column;
`;

const cellStyle = () => css`
  display: table-cell;
  vertical-align: top;
`;

style.CellAdditional = styled(style.ColumnWidthAdditional)`
  ${cellStyle()}
  vertical-align: baseline;
`;

style.CellItem = styled(style.ColumnWidthItem)`
  ${cellStyle()}
`;

style.CellCheckbox = styled(style.ColumnWidthCheckbox)`
  ${cellStyle()}
  vertical-align: baseline;
`;

style.CellIsNew = styled(style.ColumnWidthIsNew)`
  ${cellStyle()}
  vertical-align: baseline;
`;

style.TopHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 16px 16px 16px;
  font-size: 17px;
  font-weight: 500;
  height: 57px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Header = styled.div`
  display: table-header-group;
  line-height: 38px;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.HeaderText = styled.div`
  ${1};
  font-size: 11px;
  font-family: Roboto-Bold;
`;

style.BodyText = styled.div`
  ${textEllipsis};
`;

style.TitleText = styled(style.BodyText)`
  color: ${(props: Props) => getColor(props)};
`;

style.TitleLabel = styled(style.BodyText)`
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.TitleLink = styled.a`
  text-decoration: none;
  ${textEllipsis};
  color: ${(props: Props) => props.theme.colors.blue};
`;

style.Body = styled.div`
  display: table-row-group;
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

style.Dot = styled.div`
  margin-top: 6px;
  margin-right: 4px;
  flex-shrink: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${(props: Props) => props.theme.colors.blue};
`;

style.Item = styled.div`
  display: table-row;
  cursor: pointer;
  user-select: none;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  a {
    color: #4183d7;
  }

  ${(props: Props) => props.isActive && css`
    background-color: ${props.theme.colors.polar};
  `}

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${(props: Props) => props.theme.colors.polar};
  }

  &:last-child {
    ${style.ColumnWidthItem},
    ${style.ColumnWidthIcon} {
      border-bottom: none;
    }
  }
`;

style.CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default style;
