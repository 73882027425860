import { Map } from 'immutable';

import {
  Type,
  lawSettings,
} from '../../../redux/settings';

function prepareModel(data) {
  return data.reduce((acc, item) => {
    acc.itemsList = acc.itemsList.concat(item.id);

    const itemData = {
      id: item.id,
      showColumn: item.shouldRender,
      title: item.title,
    };

    acc.itemsMap = acc.itemsMap.set(item.id, itemData);

    return acc;
  }, { itemsList: [], itemsMap: Map() });
}

export const updateTableSettingsReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const {
    itemsList,
    itemsMap,
  } = prepareModel(action.data);

  let newSettings;

  const isCaseResult = state.law.layout.type === Type.caseResult;
  if (isCaseResult) {
    newSettings = {
      columns: {
        caseResult: {
          itemsList,
          itemsMap,
        },
      },
    };
  }

  const isAppealState = state.law.layout.type === Type.appealState;
  if (isAppealState) {
    newSettings = {
      columns: {
        appealState: {
          itemsList,
          itemsMap,
        },
      },
    };
  }

  const layout = lawSettings.updateSettings(state.law.layout, newSettings);

  return {
    ...state,
    law: {
      ...state.law,
      layout,
    },
  };
};

export default updateTableSettingsReduce;
