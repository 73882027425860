import type {
  Rating,
  AnnotationItem,
} from './annotation';

import {
  StateBuilder,
  DocumentId,
} from './state';

export interface ActionData {
  documentId: DocumentId,
  annotation: AnnotationItem,
  rating: Rating;
}

interface Action {
  type: string;
  data: ActionData;
}

export const addAnnotationReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.items);

  const annotation = {
    rating: action.data.rating,
    ...action.data.annotation,
  };

  newState.addItem(action.data.documentId, annotation);
  newState.updateFetchError(action.data.documentId, false);
  newState.updateExpanded(action.data.documentId);

  return newState.getState();
};

export default addAnnotationReduce;
