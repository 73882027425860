import React, { Component } from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { iconsPath } from '@components/icons';
import { Checkbox } from '@components/checkbox/checkbox';

import { CellMore } from '@pages/searchResult/documents/list/cellMore';

import s from './item.style';

import {
  Id,
  Project,
} from '../../redux/state';

import { CommentConnect } from '../../projects/list/comment.connect';
import { AddFolderConnect } from '../../projects/list/addFolder.connect';
import { FamiliarizeConnect } from '../../projects/list/familiarize.connect';
import { prepareMarkup } from '../../projects/list/item';

interface State {
  showPreview: boolean;
}

interface Props {
  item: Project;
  isSelect: boolean;
  isCheckDisable: boolean;
  isActive: boolean;
  onItemClick: (id: Id) => void;
  onItemCheck(): void;
  onItemUncheck(): void;
}

export class Item extends Component<Props, State> {

  handleItemSelect = () => {
    if (!this.props.isSelect) {
      this.props.onItemCheck();

      sendAmplitudeData('regulation: project selected', { projectNumber: this.props.item.projectNumber });
      Userpilot.track('regulation: project selected', { projectNumber: this.props.item.projectNumber });

      return;
    }

    this.props.onItemUncheck();
  };

  handleItemClick = () => {
    this.props.onItemClick(this.props.item.id);
  };

  renderLogo() {
    if (!this.props.item.logo) {
      return (
        <svg
          viewBox="0 0 72 72"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={iconsPath.get('crest')} />
        </svg>
      );
    }

    return (
      <img src={this.props.item.logo} alt="crest" />
    );
  }

  renderIsNew() {
    if (!this.props.item.isNew) {
      return <s.IsNewItem />;
    }

    return (
      <s.IsNewItem>
        <s.Dot />
      </s.IsNewItem>
    );
  }

  renderCommentsIcon() {
    return <CommentConnect id={this.props.item.id} />;
  }

  render() {
    const {
      item,
    } = this.props;

    return (
      <s.Root
        isActive={this.props.isActive}
        onClick={this.handleItemClick}
      >
        {this.renderIsNew()}
        <CellMore>
          <s.CellCheckbox>
            <Checkbox
              checked={this.props.isSelect}
              disable={this.props.isCheckDisable}
              onClick={this.handleItemSelect}
            />
          </s.CellCheckbox>
        </CellMore>
        <CellMore>
          <s.CellLogo>
            <s.Logo>
              {this.renderLogo()}
            </s.Logo>
          </s.CellLogo>
        </CellMore>
        <s.CellDocument>
          <s.BodyCell>
            <s.TitleText bold>{item.author}</s.TitleText>
            <s.TitleLabel>{item.type}</s.TitleLabel>
            <s.TitleLabel marginBottom={8}>{item.branchOfLegislation}</s.TitleLabel>
            <s.TitleText>{item.title}</s.TitleText>
            <s.TitleEvent
              dangerouslySetInnerHTML={prepareMarkup(item.lastEvent)}
            />
          </s.BodyCell>
        </s.CellDocument>
        <s.Actions>
          <s.BodyActions>
            <FamiliarizeConnect id={item.id} />
          </s.BodyActions>
        </s.Actions>
        <s.Actions>
          <s.BodyActions>
            {this.renderCommentsIcon()}
          </s.BodyActions>
        </s.Actions>
        <s.Actions>
          <s.BodyActions>
            <AddFolderConnect id={item.id} from="list" />
          </s.BodyActions>
        </s.Actions>
      </s.Root>
    );
  }

}

export default Item;
