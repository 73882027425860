import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import http from '@http';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

type Id = string;
type Title = string;

export interface DisputeCategoryBlockData {
  id: Id;
  title: Title;
}

interface ResponseItem {
  id: Id;
  name: Title;
  is_disabled: boolean;
}

export interface SuggestResponse {
  result: ResponseItem[]
}

export class DisputeCategoryBlock extends AbstractFilterBlock {

  static type = 'disputeCategory';

  static DTOType = 'category_dispute';

  static blockTitle = 'Категория спора АС';

  static category = category.dispute;

  static SuggestAPI = '/autocomplete/content/dispute';

  protected defaultData: DisputeCategoryBlockData = {
    // @ts-ignore
    id: null,
    // @ts-ignore
    title: null,
  };

  constructor() {
    super();

    this.setCategory(DisputeCategoryBlock.category);
    this.setType({
      id: DisputeCategoryBlock.type,
      DTOType: DisputeCategoryBlock.DTOType,
      title: DisputeCategoryBlock.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const block = {
      id,
      data,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      type: DisputeCategoryBlock.DTOType,
      data: this.getData(),
    };
  }

  public getItemsForSuggest = async (query) => {
    const getItems = async (): Promise<SuggestResponse> => {
      const request = {
        url: DisputeCategoryBlock.SuggestAPI,
        body: {
          query,
        },
      };

      const response = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.result.map((item) => ({
        id: item.id,
        title: item.name,
      })),
    };
  };

  // eslint-disable-next-line
  public validate() {}

}

export default DisputeCategoryBlock;
