import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { Commentary } from './commentary';

import s from './addTagButton.style';

export interface AddPackageButtonProps {
  path: string;
  color: string;
  renderFromRight: boolean;
  disabled?: boolean;
  isActive: boolean;
  onAddCommentary: (commentary: string) => void;
  commentary: string;
  shouldRenderComment: boolean;
}

export class AddTagButton extends React.PureComponent<AddPackageButtonProps> {

  state = {
    hovered: false,
    textInFocus: false,
  };

  static defaultProps = {
    disabled: false,
  };

  handleMouseOver = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  setFocusState = () => {
    this.setState({
      textInFocus: true,
    });
  };

  deleteFocusState = () => {
    this.setState({
      textInFocus: false,
    });
  };

  renderCommentary() {
    if (!this.state.hovered && !this.state.textInFocus) {
      return null;
    }

    if (!this.props.shouldRenderComment) {
      return null;
    }

    return (
      <Commentary
        renderFromRight={this.props.renderFromRight}
        commentary={this.props.commentary}
        onFocus={this.setFocusState}
        onBlur={this.deleteFocusState}
        onAddCommentary={this.props.onAddCommentary}
      />
    );
  }

  renderCommentaryNotification() {
    if (!this.props.commentary) {
      return null;
    }

    return <s.CommentaryNotification />;
  }

  render() {
    const iconPath = iconsPath.get(this.props.path);

    return (
      <s.Root
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        <s.IconBlock>
          <s.Button
            isHovered={this.state.hovered}
            disabled={this.props.disabled}
            isActive={this.props.isActive}
          >
            {this.renderCommentaryNotification()}
            <Icon path={iconPath} color={this.props.color} />
          </s.Button>
        </s.IconBlock>
        {this.renderCommentary()}
      </s.Root>
    );
  }

}

export default AddTagButton;
