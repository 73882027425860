import { selectPackageReduce } from '@pages/packages/redux/packages/selectPackage.reduce';

import type { ActionData } from '@pages/packages/redux/packages/selectPackage.reduce';

const actionType = 'AUTHOR_PACKAGES/SELECT_PACKAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const selectPackage = {
  actionType,
  createAction,
  reduce: selectPackageReduce,
};

export default selectPackage;
