import React from 'react';

import s from './switch.style';

interface Props {
  checked?: boolean;
}

const defaultProps = {
  checked: false,
};

export const Switch:React.FC<Props> = ({ ...props }) => (
  <s.Root>
    <s.Slider checked={props.checked} />
    <s.Check checked={props.checked} />
  </s.Root>
);

Switch.defaultProps = defaultProps;

export default Switch;
