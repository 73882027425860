import { connect } from 'react-redux';

import {
  Pagination,
  preparePages,
} from '@components/pagination';

import { fetchChronologyDocumentsThunk } from '@layouts/chronology/redux/fetchChronologyDocuments.thunk';

const mapStateToProps = (state) => {
  const {
    totalPages,
    currentPageNumber,
  } = state.chronology;

  const isFirstPageSelected = currentPageNumber === 1;
  const isLastPageSelected = currentPageNumber === totalPages;

  return {
    pages: preparePages(totalPages, currentPageNumber),
    currentPageNumber,
    isFirstPageSelected,
    isLastPageSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangePage(pageNumber) {
    fetchChronologyDocumentsThunk(dispatch, null, pageNumber);
  },
});

export const ChronologyPaginationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pagination);

export default ChronologyPaginationConnect;
