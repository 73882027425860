import { StateBuilder } from './state';

export const resetRelationsReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    relations: StateBuilder.createInitialRelations(),
  };
};

export default resetRelationsReduce;
