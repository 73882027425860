import { StateBuilder } from './state';

import type { NotificationItem } from './state';

export type ActionData = {
  notifications: NotificationItem[],
  hasUnread: boolean;
  page: number;
  total_pages: number;
};

interface Action {
  type: string;
  data: ActionData;
}

export const fetchNotificationsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);

  newState.addItems(action.data.notifications);
  newState.setTotalPages(action.data.total_pages);
  newState.setPage(action.data.page);
  newState.setLoading(false);
  newState.setHasUnread(action.data.hasUnread);

  return newState.getState();
};

export default fetchNotificationsReduce;
