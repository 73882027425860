import { Map } from 'immutable';

type FolderId = number;

interface Item {
  id: FolderId;
  title: string;
  selected: boolean;
}

export class StateBuilder {

  private state: {
    itemsList: FolderId[];
    itemsMap: Map<number, Item>;
    loading: boolean;
    addingItem: boolean;
  };

  static createState() {
    return {
      itemsList: [],
      itemsMap: Map(),
      addingItem: false,
      loading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  addFolder(item: { id: number, name: string }) {
    const folderItem = {
      id: item.id,
      title: item.name,
      selected: false,
    };

    const itemsList = [folderItem.id, ...this.state.itemsList];
    const itemsMap = this.state.itemsMap.set(folderItem.id, folderItem);

    return {
      ...this.state,
      itemsList,
      itemsMap,
    };
  }

  toggleSelected(folderId) {
    const folderItem = this.state.itemsMap.get(folderId);
    const newFolderItem = {
      ...folderItem,
      selected: !folderItem.selected,
    };

    const itemsMap = this.state.itemsMap.set(folderId, newFolderItem);

    return {
      ...this.state,
      itemsMap,
      addingItem: false,
    };
  }

}

export default StateBuilder;
