import { updatePinReduce } from './updatePin.reduce';
import { SEARCH_FORM_BLOCK } from '../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/UPDATE_PIN`;

const createAction = (data) => ({
  type: actionType,
  data,
});

export const updatePin = {
  actionType,
  createAction,
  reduce: updatePinReduce,
};

export default updatePin;
