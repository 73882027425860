import { connect } from 'react-redux';

import { UserRoleType } from '@pages/userPages/redux/state';

import { DocumentBody } from './documentBody';

const mapStateToProps = (state) => ({
  isReal: state.currentUser.role !== UserRoleType.guest,
});

export const DocumentBodyConnect = connect(
  mapStateToProps,
)(DocumentBody);

export default DocumentBodyConnect;
