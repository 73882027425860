import { changeTotalPagesReduce } from './changeTotalPages.reduce';

const actionType = 'SEARCH/HISTORY/CHANGE_TOTAL_PAGES';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const changeTotalPages = {
  actionType,
  createAction,
  reduce: changeTotalPagesReduce,
};

export default changeTotalPages;
