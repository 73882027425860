export interface Document {
  document_id: number;
  packages_count: number;
}

export interface ActionData {
  documents: Document[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchDocumentsPackageCountReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
      },
    },
  };

  const { documents } = newState;
  action.data.documents.forEach((document) => {
    Object.keys(documents.pages).forEach((pageNumber) => {
      const hasItem = documents.pages[pageNumber].itemsMap.has(document.document_id);

      if (!hasItem) {
        return;
      }

      const item = { ...documents.pages[pageNumber].itemsMap.get(document.document_id) };
      item.packagesCount = document.packages_count;
      const itemsMap = documents.pages[pageNumber].itemsMap.set(document.document_id, item);
      newState.documents.pages[pageNumber].itemsMap = itemsMap;
    });
  });

  return newState;
};

export default fetchDocumentsPackageCountReduce;
