import React from 'react';

import s from './logo.style';

export const Logo = () => (
  <s.Logo>
    <s.LogoCase>
      Case
    </s.LogoCase>
    <s.LogoLook>
      look
    </s.LogoLook>
  </s.Logo>
);

export default Logo;
