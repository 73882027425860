import styled, { css } from 'styled-components';

interface Props {
  isLoading: boolean;
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  padding: 0 0 12px;
  position: relative;
  z-index: 1;
`;

style.Icon = styled.div`
  position: absolute;
  top: 11px;
  left: 12px;
`;

style.DefaultControl = styled.div`
  position: relative;
  min-height: 47px;
  padding: 16px 16px 16px 44px;
  background: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  cursor: pointer;
  white-space: break-spaces;

  ${(props: Props) => props.isLoading && css`
    cursor: default;
    color: #a6b0ba;

    i {
      color: #a6b0ba;
    }
  `}
`;

export default style;
