import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { withResetFilters } from '@pages/search/withResetFilters';

import { RegulationConnect } from './regulation.connect';

export const RouteRegulation = (
  <AuthRouteConnect
    path={[
      RoutePath.regulations,
      RoutePath.regulation,
      RoutePath.regulationSmartFolder,
      RoutePath.regulationPreview,
    ]}
    component={withResetFilters(RegulationConnect)}
    exact
  />
);

export default RouteRegulation;
