import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  overflow: hidden;

  background-color: #f3f3f3;
`;

style.Content = styled.section`
  display: flex;
  flex: 1;

  overflow: hidden;
`;

style.DocumentRoot = styled.section`
  flex: 1;

  overflow: auto;
  padding: 16px 0;
`;

export default style;
