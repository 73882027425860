import { Map } from 'immutable';
import { StateBuilder } from './state';

export interface FolderDTO {
  id: number;
  title: string;
  counter: number;
}

export interface Folder {
  id: number;
  title: string;
  counter: number;
}

export interface ActionData {
  items: FolderDTO[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchFoldersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newFolders = {
    itemsList: [],
    itemsMap: Map(),
  };

  const newState = new StateBuilder({ ...state.folders, folders: newFolders });
  newState.addItems(action.data.items);
  newState.setFoldersLoadingFalse();
  const folders = {
    ...newState.getState(),
  };

  return {
    ...state,
    folders,
  };
};

export default fetchFoldersReduce;
