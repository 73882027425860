import { connect } from 'react-redux';

import { RoutePath } from '@router/path';
import { userAuthenticated } from '@utils/user';
import { UnauthRoute } from './unauthRoute';

const getRedirectPathname = (location) => {
  let pathname = RoutePath.search;

  if (location && location.state) {
    ({ pathname } = location.state.from);
  }

  return pathname;
};

const getRedirectSearch = (location) => {
  let search = '';

  if (location && location.state) {
    ({ search } = location.state.from);
  }

  return search;
};

export const mapStateToProps = (state, ownProps) => {
  const props = {};
  // @ts-ignore
  props.redirectPathname = '';
  // @ts-ignore
  props.redirectSearch = '';

  if (userAuthenticated(state.currentUser)) {
    // @ts-ignore
    props.redirectPathname = getRedirectPathname(ownProps.location);
    // @ts-ignore
    props.redirectSearch = getRedirectSearch(ownProps.location);
  }

  return props;
};

export const UnauthRouteConnect = connect(mapStateToProps)(UnauthRoute);
export default UnauthRouteConnect;
