import { createFolderSucceedReduce } from './createFolderSucceed.reduce';

import type { ActionData } from './createFolderSucceed.reduce';

const actionType = 'REGULATION/FOLDERS/CREATE_FOLDER__SUCCEED';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const createFolderSucceed = {
  actionType,
  createAction,
  reduce: createFolderSucceedReduce,
};

export default createFolderSucceed;
