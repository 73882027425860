import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { appHistory } from '@router/history';
import { Id } from '@pages/regulation/sidebar/filters/redux/item';

import { selectFilter } from './selectFilter';
import { updateFilters } from './updateFilters';

import { selectFolder } from '../../folders/redux/selectFolder';

import { resetToInitial as resetWorkspace } from '../../../workspace/redux/resetToInitial';
import { fetchProjectsThunk } from '../../../workspace/redux/fetchProjects.thunk';

export class SelectFilterThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: Id }) {
    const { smartFolders } = this.getState().regulation;
    const sameId = params.id === smartFolders.activeItemId;
    if (sameId) {
      return;
    }

    await this.dispatchReset();
    this.marketingEvents();
    this.dispatchSelectFilter(params.id);

    this.dispatchUpdateFilters(params.id);

    const filterId = params.id || '';
    appHistory.push(`/regulation/filters/${filterId}`);

    await this.fetchProjects();
  }

  private dispatchReset() {
    this.dispatch(resetWorkspace.createAction());
    this.dispatch(selectFolder.createAction({ id: null }));
  }

  private marketingEvents() {
    sendAmplitudeData('regulation:select_filters');
    Userpilot.track('regulation:select_filters');
  }

  private dispatchSelectFilter(id: Id) {
    this.dispatch(selectFilter.createAction({ id }));
  }

  private dispatchUpdateFilters(id: Id) {
    this.dispatch(updateFilters.createAction({ id }));
  }

  private async fetchProjects() {
    try {
      await fetchProjectsThunk(this.dispatch);
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export function selectFilterThunk(dispatch, params) {
  const thunk = new SelectFilterThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SelectFilterThunk;
