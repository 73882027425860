import React from 'react';

import s from '@pages/searchResult/documents/header/header.style';

import { SettingsPopover } from './settingsPopover/settingsPopover';
import { EditViewPopover } from './editViewPopover/editViewPopover';

import { CellItemWithTooltipConnect } from '../list/itemWithTooltip.connect';

import { CellTitleWithTooltip } from '../list/titleWithTooltip';
import table from '../list/item.style';

export interface HeaderProps {
  title: string;
  columns: {
    itemsList: string[];
    itemsMap: any;
  };
  children: React.ReactElement[];
}

export class Header extends React.PureComponent<HeaderProps> {

  renderColumnInGroup() {
    return this.props.columns.itemsList.map((column) => {
      const isTitle = column === 'title';
      if (isTitle) {
        return (
          <table.ColumnTitle key={column} />
        );
      }

      return (
        <table.ColumnItem key={column} />
      );
    });
  }

  renderHeaderItems() {
    const columns = this.props.columns.itemsList.length;

    return this.props.columns.itemsList.map((column) => {
      const { title } = this.props.columns.itemsMap.get(column);

      const isTitle = column === 'title';
      if (isTitle) {
        return (
          <CellTitleWithTooltip
            key={column}
            title={title}
            tooltip={title}
            columns={columns}
          />
        );
      }

      return (
        <CellItemWithTooltipConnect
          key={column}
          id={column}
          title={title}
          tooltip={title}
          columns={columns}
        />
      );
    });
  }

  render() {
    return (
      <s.Root>
        <s.TopHeader>
          <s.Title>
            {this.props.title}
          </s.Title>
          <s.Actions>
            <s.OptionsKebab>
              <EditViewPopover />
            </s.OptionsKebab>
            <s.OptionsKebab>
              <SettingsPopover />
            </s.OptionsKebab>
          </s.Actions>
        </s.TopHeader>
        <table.Table>
          <table.Group>
            {this.renderColumnInGroup()}
          </table.Group>
          <table.Row>
            {this.renderHeaderItems()}
          </table.Row>
        </table.Table>
        <table.Body>
          {this.props.children}
        </table.Body>
      </s.Root>
    );
  }

}

export default Header;
