import { connect } from 'react-redux';

import { Actions } from './actions';

const mapStateToProps = (state, ownProps) => ({
  isAuthorPackage: state.packages.packages.itemsMap.get(ownProps.id).type === 'author',
});

export const ActionsConnect = connect(
  mapStateToProps,
)(Actions);

export default ActionsConnect;
