import { Dispatch } from 'redux';

import { httpFetchFilters } from './fetchFilters.http';
import { fetchFilters } from './fetchFilters';

export class FetchFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    try {
      const result = await httpFetchFilters();
      this.dispatchFetchSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      items: result,
    };

    this.dispatch(fetchFilters.createAction(actionData));
  }

}

export async function fetchFiltersThunk(dispatch) {
  const thunk = new FetchFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke();
}

export default FetchFiltersThunk;
