import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  width: 384px;

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 17px;
  }
`;

style.InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

style.DefaultContainer = styled.div`
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.OptionsContainer = styled.div``;

style.Option = styled.div`
  padding: 12px 0 12px 8px;
  margin: 0;
  min-height: 32px;
  height: auto;
  width: 100%;
  box-sizing: border-box;

  > div {
    > div {
      padding-right: 4px;
    }
  }
`;

style.Input = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #dadfe1;
  padding: 15px;
  border-width: 0 1px 1px 0;
  display: flex;
  width: 50%;

  input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
  }
`;

export default style;
