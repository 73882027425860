import React from 'react';

import { InformationConnect } from './information.connect';

import s from './sidebar.style';

interface SidebarProps {
  isReal: boolean;
  isAuthorPackage: boolean;
  isChronologyDocument: boolean;
  isSimilarDocument: boolean;
}

export class Sidebar extends React.PureComponent<SidebarProps> {

  render() {
    const isViewOnly = this.props.isAuthorPackage || this.props.isChronologyDocument;

    return (
      <s.Root
        id="sidebar"
      >
        <InformationConnect viewOnly={isViewOnly} />
      </s.Root>
    );

  }

}

export default Sidebar;
