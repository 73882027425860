import { connect } from 'react-redux';

import { DocumentMarkers } from './documentMarkers';

const mapStateToProps = (state, ownProps) => {
  const enable = !(ownProps.isAuthorPackage || state.document.isChronologyDocument || state.document.isSimilarDocument);

  return {
    selections: state.document.document.entities.markers,
    enable,
  };
};

export const DocumentMarkersConnect = connect(
  mapStateToProps,
)(DocumentMarkers);

export default DocumentMarkersConnect;
