import { increaseDocumentsCountReduce } from './increaseDocumentsCount.reduce';

import type { ActionData } from './increaseDocumentsCount.reduce';

const actionType = 'PACKAGES/PACKAGE__INCREASE_DOCUMENT_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const increaseDocumentsCount = {
  actionType,
  createAction,
  reduce: increaseDocumentsCountReduce,
};

export default increaseDocumentsCount;
