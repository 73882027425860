import { connect } from 'react-redux';

import { EditView } from './editView';
import {
  updateView,
  updateViewReset,
} from './redux/updateView';
import { saveLawLayoutSettingsThunk } from '../../redux/saveLayout.thunk';

const mapStateToProps = (state) => {
  const { type, format } = state.searchResult.law.layout;

  return {
    type,
    format,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSaveView(data, shouldResetSort) {
    const update = shouldResetSort ? updateViewReset : updateView;
    dispatch(update.createAction(data));
    saveLawLayoutSettingsThunk(dispatch);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const onSaveView = (data) => {
    const shouldResetSort = data.type !== stateProps.type;

    return dispatchProps.onSaveView(data, shouldResetSort);
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSaveView,
  };
};

export const EditViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(EditView);

export default EditViewConnect;
