import { connect } from 'react-redux';

import { Settings } from './settings';

const mapStateToProps = (state) => ({
  id: state.subscriptions.subscriptions.activeItemId,
  disabled: !state.subscriptions.subscriptions.activeItemId,
});

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
