import { RoutePath } from '@router/path';

import { copyToClipboard } from '@utils/utils';

type PackageId = number;

export class CopyLinkThunk {

  private packageId: PackageId;

  constructor(packageId) {
    this.packageId = packageId;
  }

  public async invoke() {

    if (!this.packageId) {
      return;
    }

    const { origin } = window.location;

    if (!origin) {
      return;
    }

    const link = `${origin}/#${RoutePath.authorPackages}/${this.packageId}`;

    if (!link) {
      return;
    }

    copyToClipboard(link);

  }

}

export function copyLinkThunk(packageId: PackageId) {
  const thunk = new CopyLinkThunk(packageId);

  thunk.invoke();
}

export default CopyLinkThunk;
