import React from 'react';

import { CloseButton } from '@components/buttons';

import { Id } from '../workspace/redux/state';

import { InviteFormConnect } from './inviteForm.connect';
import { UsersConnect } from './users.connect';

import s from './shareMenu.style';

interface ShareMenuProps {
  id: Id;
  onClose: () => void;
}

export class ShareMenu extends React.PureComponent<ShareMenuProps> {

  renderTitle() {
    return <s.Title>Настроить совместный доступ</s.Title>;
  }

  render() {
    return (
      <s.Root>
        <s.Header>
          <s.Action>
            <CloseButton
              color="#4183d7"
              onClick={this.props.onClose}
            />
          </s.Action>
          {this.renderTitle()}
        </s.Header>
        <s.Workspace>
          <s.WorkspaceContainer>
            <InviteFormConnect id={this.props.id} />
            <UsersConnect id={this.props.id} />
          </s.WorkspaceContainer>
        </s.Workspace>
      </s.Root>
    );
  }

}

export default ShareMenu;
