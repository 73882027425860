import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchProject } from '../projects/preview/redux/fetchProject';
import { fetchNote } from '../projects/preview/redux/fetchNote';
import { fetchPhases } from '../projects/preview/redux/fetchPhases';
import { fetchSpectractors } from '../projects/preview/redux/fetchSpectractors';

import { fetchProjects } from './fetchProjects';
import { fetchFolder } from './fetchFolder';
import { fetchFolderLastPage } from './fetchFolderLastPage';
import { fetchLayoutSettings } from './fetchLayoutSettings';
import { setLayoutSettings } from './setLayoutSettings';

import { fetchFilters } from './fetchFilters';
import { updateFilter } from '../toolbar/redux/updateFilter';
import { resetFilters } from '../toolbar/redux/resetFilters';

import { resetProjects } from './resetProjects';
import { resetToInitial } from './resetToInitial';

import { increaseFoldersCount } from './increaseFoldersCount';
import { decreaseFoldersCount } from './decreaseFoldersCount';

import { toggleFamiliarize } from './toggleFamiliarize';

import { updateFoldersCount } from './updateFoldersCount';

import { increaseCommentsCount } from './increaseCommentsCount';

import { setPreviewId } from './setPreviewId';

import { checkProject } from './checkProject';
import { uncheckProject } from './uncheckProject';

import { checkProjects } from './checkProjects';
import { uncheckProjects } from './uncheckProjects';

import { removeProjectFromFolder } from './removeProjectFromFolder';

export const reducerBuilder = new ReducerBuilder([
  fetchProjects,
  fetchFolder,
  fetchFolderLastPage,
  fetchProject,
  fetchNote,
  fetchPhases,
  fetchSpectractors,
  fetchLayoutSettings,
  setLayoutSettings,
  fetchFilters,
  updateFilter,
  resetFilters,
  increaseFoldersCount,
  decreaseFoldersCount,
  toggleFamiliarize,
  updateFoldersCount,
  increaseCommentsCount,
  checkProject,
  uncheckProject,
  checkProjects,
  uncheckProjects,
  setPreviewId,
  removeProjectFromFolder,
  resetProjects,
  resetToInitial,
]);

export default reducerBuilder;
