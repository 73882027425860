import { processStartReduce } from './processStart.reduce';

export const actionType = 'BOOKMARKS_BUFFER/DOWNLOAD_START';

const createAction = () => ({
  type: actionType,
});

export const processStart = {
  actionType,
  createAction,
  reduce: processStartReduce,
};
