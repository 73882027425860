import { fetchDocumentReduce } from '@pages/searchResult/documents/preview/redux/fetchDocument.reduce';

import { DocumentDTO } from '@pages/searchResult/documents/preview/redux/document';

const actionType = 'AUTHOR_PACKAGE/FETCH_DOCUMENT__PREVIEW';

const createAction = (data: DocumentDTO) => ({
  type: actionType,
  data,
});

export const fetchDocument = {
  actionType,
  createAction,
  reduce: fetchDocumentReduce,
};

export default fetchDocument;
