import React from 'react';

import { HeaderCassette } from '@pages/userPages/profile/profileTab/headerCassette';

import s from './titleGroup.style';

export interface TitleGroupProps {
  name: string;
  title: string;
  position: string;
  logo: string;
  avatar: string;
  description: string[];
}

export class TitleGroup extends React.PureComponent<TitleGroupProps> {

  renderTheme() {
    return (
      <s.Theme>
        <s.Title>{this.props.title}</s.Title>
        <s.Description>
          {this.props.description.map((text) => (<p>{text}</p>))}
        </s.Description>
      </s.Theme>
    );
  }

  render() {
    return (
      <s.Root>
        <HeaderCassette
          disable
          avatar={this.props.avatar}
          logo={this.props.logo}
          name={this.props.name}
          position={this.props.position}
        />
        {this.renderTheme()}
      </s.Root>
    );
  }

}

export default TitleGroup;
