import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './familiarize.style';

interface FamiliarizeProps {
  familiarize: boolean;
  onToggle(): void;
}

export class Familiarize extends React.PureComponent<FamiliarizeProps> {

  handleIconClick = (e) => {
    e.stopPropagation();
    this.props.onToggle();
  };

  renderIcon() {
    if (this.props.familiarize) {
      return (
        <s.IconCheck>
          <Icon
            points={iconsPath.get('familiarizedCheck')}
            path={iconsPath.get('familiarized')}
          />
        </s.IconCheck>
      );
    }

    return (
      <s.IconUncheck>
        <Icon
          points={iconsPath.get('familiarizedUncheck')}
          path={iconsPath.get('familiarized')}
        />
      </s.IconUncheck>
    );
  }

  render() {
    return (
      <s.Root
        onClick={this.handleIconClick}
      >
        {this.renderIcon()}
      </s.Root>
    );
  }

}

export default Familiarize;
