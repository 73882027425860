export enum Source {
  search = 'search',
  package = 'package',
  authorPackage = 'authorpackage',
  subscription = 'subscription',
  chronology = 'history',
  similar = 'similar',
  regulationProject = 'regulation',
  regulationFolder = 'regulationFolder',
}

export class StateBuilder {

  static createState() {
    return {
      [Source.search]: {},
      [Source.package]: {},
      [Source.authorPackage]: {},
      [Source.subscription]: {},
      [Source.chronology]: {},
      [Source.similar]: {},
      [Source.regulationProject]: {},
      [Source.regulationFolder]: {},
    };
  }

}

export default StateBuilder;
