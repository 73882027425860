import React from 'react';

import { Userpilot } from '@utils/userpilot';

import { separator } from '@components/separator';
import { Button } from '@components/button';
import { CloseButton } from '@components/buttons';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './paymentSelect.style';

interface PaymentSelectProps {
  paymentOptions: any[];
  onPaymentStart(period: string): void;
}

export class PaymentSelect extends React.PureComponent<PaymentSelectProps> {

  componentDidMount() {
    Userpilot.reload();
  }

  handlePay = (period) => () => {
    this.props.onPaymentStart(period);
  };

  handleClose = () => {
    window.location.href = '#/profile';
  };

  renderRibbon(prefer) {
    if (!prefer) {
      return null;
    }

    return (
      <s.Ribbon>
        <s.RibbonTitle>Выгодно</s.RibbonTitle>
      </s.Ribbon>
    );
  }

  renderStars = (stars) => {
    const path = iconsPath.get('star');
    const totalStars = [1, 2, 3];

    return totalStars.map((item, index) => {
      const position = index + 1;
      if (position <= stars) {
        return <s.Icon><Icon size={32} viewBoxSize={32} points={path} color="#ffbe25" /></s.Icon>;
      }

      return <s.Icon><Icon size={32} viewBoxSize={32} points={path} color="#d2d7dc" /></s.Icon>;
    });
  };

  renderItem = (payment) => (
    <s.Item>
      {this.renderRibbon(payment.prefer)}
      <s.ItemTitle>{payment.date}</s.ItemTitle>
      <s.ItemStars>{this.renderStars(payment.stars)}</s.ItemStars>
      <s.ItemCost>
        {separator(payment.cost)}
        ₽
      </s.ItemCost>
      <s.ItemDescription>{payment.description}</s.ItemDescription>
      <s.ItemAction><Button onClick={this.handlePay(payment.period)} fullWidth>Оплатить</Button></s.ItemAction>
    </s.Item>
  );

  render() {
    return (
      <s.Root>
        <s.Header>
          <s.HeaderAction>
            <CloseButton
              color="#4183d7"
              onClick={this.handleClose}
            />
          </s.HeaderAction>
          <s.HeaderTitle>Оплата лицензии</s.HeaderTitle>
        </s.Header>
        <s.Payment>
          {this.props.paymentOptions.map(this.renderItem)}
        </s.Payment>
      </s.Root>
    );
  }

}

export default PaymentSelect;
