import { StateBuilder } from './state';

export const pageLoadingStartReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.setPageLoading(true);

  return {
    ...newState.getState(),
  };
};

export const pageLoadingEndReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.setPageLoading(false);

  return {
    ...newState.getState(),
  };
};
