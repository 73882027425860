import { paymentProcessEndReduce } from './paymentProcessEnd.reduce';
import type { ActionData } from './paymentProcessEnd.reduce';

export const actionType = 'USER/PAYMENT_END';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const paymentProcessEnd = {
  actionType,
  createAction,
  reduce: paymentProcessEndReduce,
};
