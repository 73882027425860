import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { LawDocumentConnect } from './lawDocument.connect';

export const RouteLawDocument = (
  <AuthRouteConnect
    path={RoutePath.lawDocument}
    component={LawDocumentConnect}
    exact
  />
);

export default RouteLawDocument;
