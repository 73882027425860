import React from 'react';
import { theme } from '@components/styled';

import s from './button.style';

interface SubscriptionButtonProps {
  title: string;
  disabled?: boolean;
  onClick(): void;
  needUppercase?: boolean;
  color?: string;
}

export const Button = ({ title,
  disabled = false, needUppercase = true, onClick, color = theme.colors.blue }: SubscriptionButtonProps) => {

  const handleClick = (e) => {
    if (disabled) {
      e.stopPropagation();

      return;
    }

    onClick();
  };

  return (
    <s.Root>
      <s.Button
        disabled={disabled}
        color={color}
        needUppercase={needUppercase}
        onClick={handleClick}
      >
        {title}
      </s.Button>
    </s.Root>
  );
};

export default Button;
