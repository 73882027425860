import React from 'react';

import VisibilitySensor from 'react-visibility-sensor';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { EmptyFolder } from '@components/emptyFolder';
import { Loader } from '@components/loader';
import { OpenDrawer } from '@components/openDrawer';

import {
  ViewType,
  Id,
} from '../redux/state';

import { ViewTypes } from '../redux/saveLayoutSettings.thunk';

import { HeaderConnect } from './header/header.connect';
import { ListConnect } from './list/list.connect';
import { TableConnect } from './table/table.connect';
import { PreviewConnect } from '../projects/preview/preview.connect';

import s from './folder.style';

interface ProjectsProps {
  items: Id[],
  newItems: Id[],
  withoutChangeItems: Id[],
  viewType: ViewTypes;
  newProjects: number;
  withoutChange: number;
  onLoadNextPage(): void;
  loading: boolean;
}

interface ProjectsState {
  showPreview: boolean;
  projectPreviewId: Id;
}

export class Folder extends React.PureComponent<ProjectsProps, ProjectsState> {

  contentRef: React.RefObject<HTMLDivElement>;

  state = {
    showPreview: false,
    projectPreviewId: null,
  };

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidUpdate(prevProps: ProjectsProps) {
    this.checkViewChage(prevProps.viewType);
  }

  showPreview = (id: Id) => {
    this.setState({
      showPreview: true,
      projectPreviewId: id,
    });
  };

  hidePreview = () => {
    this.setState({
      showPreview: false,
      projectPreviewId: null,
    });
  };

  marketingEvent = () => {
    sendAmplitudeData('regulation:open_view');
    Userpilot.track('regulation:open_view');
  };

  handleItemClick = (id: Id) => {
    this.showPreview(id);
    this.marketingEvent();
  };

  handleCloseDrawer = () => {
    this.hidePreview();
  };

  onAfterChangeView = () => {
    this.contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleVisible = (isVisible) => {
    if (isVisible) {
      this.props.onLoadNextPage();
    }
  };

  checkViewChage(viewType: ViewTypes) {
    if (!viewType) {
      return;
    }

    if (viewType === this.props.viewType) {
      return;
    }

    if (this.props.loading) {
      return;
    }

    this.onAfterChangeView();
  }

  contentSwitcher() {
    const isListLayout = this.props.viewType === ViewType.list;
    if (!this.props.items.length) {
      return <EmptyFolder title="В папке нет документов" />;
    }

    if (isListLayout) {
      return (
        <s.ContentContainer
          isList
        >
          {this.renderNewItemsList()}
          {this.renderDivider()}
          {this.renderWithoutChangeItemsList()}
          {this.renderBottomSensor()}
        </s.ContentContainer>
      );
    }

    return (
      <s.ContentContainer>
        <HeaderConnect />
        <s.ItemsContent>
          {this.renderTable()}
          {this.renderBottomSensor()}
        </s.ItemsContent>
      </s.ContentContainer>
    );
  }

  renderDivider() {
    if (!this.props.newItems.length && !this.props.withoutChangeItems.length) {
      return null;
    }

    return <s.Divider />;
  }

  renderNewItemsList() {
    if (!this.props.newItems.length) {
      return null;
    }

    return (
      <>
        <HeaderConnect isList isNew />
        <s.ItemsContent>
          <ListConnect
            activeId={this.state.projectPreviewId}
            items={this.props.newItems}
            onItemClick={this.handleItemClick}
          />
        </s.ItemsContent>
      </>
    );
  }

  renderWithoutChangeItemsList() {
    if (!this.props.withoutChangeItems.length) {
      return null;
    }

    return (
      <>
        <HeaderConnect isList isWithoutChange />
        <s.ItemsContent>
          <ListConnect
            activeId={this.state.projectPreviewId}
            items={this.props.withoutChangeItems}
            onItemClick={this.handleItemClick}
          />
        </s.ItemsContent>
      </>
    );
  }

  renderTable = () => (
    <TableConnect
      activeId={this.state.projectPreviewId}
      items={this.props.items}
      onItemClick={this.handleItemClick}
    />
  );

  renderContent() {
    if (this.props.loading) {
      return <Loader />;
    }

    return this.renderLayout();
  }

  renderLayout() {
    return (
      <>
        {this.renderDocuments()}
        {this.renderDrawer()}
      </>
    );
  }

  renderBottomSensor() {
    if (this.props.loading) {
      return null;
    }

    return (
      <VisibilitySensor onChange={this.handleVisible} partialVisibility>
        <s.BottomSensor />
      </VisibilitySensor>
    );
  }

  renderDocuments = () => (
    <s.Content ref={this.contentRef}>
      {this.contentSwitcher()}
    </s.Content>
  );

  renderDrawer = () => {
    if (!this.state.showPreview) {
      return null;
    }

    sendAmplitudeData('regulation: preview');
    Userpilot.track('regulation: preview');

    return (
      <OpenDrawer
        anchor="right"
        withBackground={false}
        component={(
          <PreviewConnect
            id={this.state.projectPreviewId}
            onClose={this.handleCloseDrawer}
          />
        )}
        onClickOutside={this.handleCloseDrawer}
      />
    );
  };

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default Folder;
