import { Id } from './changeSortColumn';
import {
  SortType,
  SortDirection,
  SortDataProps,
} from '../../components/sortIcon/sortIcon';

type ActionData = {
  id: Id,
};

interface Action {
  type: string
  data: ActionData
}

const sortValues: SortType[] = [SortDirection.DESC, SortDirection.ASC];

export const changeSortColumnReduce = (state, action: Action) => {
  const { data } = action;
  const { sortData: { id, type } } = state.courts;

  const getSortData = (): SortDataProps => {
    const shouldCreateNewSort = id !== data.id;

    if (shouldCreateNewSort) {
      return {
        id: data.id,
        type: SortDirection.DESC,
      };
    }

    const newType = sortValues.find((typeValue) => typeValue !== type);

    return {
      id,
      // @ts-ignore
      type: newType,
    };
  };

  const sortData = getSortData();

  return {
    ...state,
    courts: {
      ...state.courts,
      sortData,
    },
  };
};

export default changeSortColumnReduce;
