/* eslint-disable */

import React from 'react';

import {
  createChatOptions,
  createChatScript,
} from './utils';

export class ChatBot extends React.PureComponent {

  componentDidMount() {
    createChatOptions();
    createChatScript();
  }

  render() {
    return null;
  }

}

export default ChatBot;
