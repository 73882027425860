import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateSmartFilterReduce } from './updateSmartFilter.reduce';

import type { ActionData } from './updateSmartFilter.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/SMART_FILTER/UPDATE`;

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateSmartFilter = {
  actionType,
  createAction,
  reduce: updateSmartFilterReduce,
};

export default updateSmartFilter;
