import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Button } from '@pages/searchResult/components/button/button';

import { Menu } from './menu';

import s from './filtersSave.style';

interface Props {
  shouldRender: boolean;
  shouldRenderButton: boolean;
  disabled?: boolean;
  onSave(title?: string): void;
}

export const FiltersSave = (props: Props) => {
  if (!props.shouldRender) {
    return null;
  }

  if (props.shouldRenderButton) {
    return (
      <s.Root>
        <Button
          title="Сохранить фильтры"
          disabled={props.disabled}
          onClick={props.onSave}
        />
      </s.Root>
    );
  }

  return (
    <s.Root>
      <BaseDropdown
      // @ts-ignore
        usePortal
        placement="bottom-end"
        renderInput={(p) => (
          <Button
            title="Сохранить фильтры"
            disabled={props.disabled}
            onClick={() => {}}
            {...p}
          />
        )}
        renderMenu={(p) => (
          <Menu
            onSave={props.onSave}
            {...p}
          />
        )}
      />
    </s.Root>
  );
};

export default FiltersSave;
