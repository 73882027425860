import { ReducerBuilder } from '@redux/reducer.builder';

import { updateValue } from './updateValue';
import { updateFindIn } from './updateFindIn';
import { updateMode } from './updateMode';
import { updateProximityRange } from './updateProximityRange';

export const reducerBuilder = new ReducerBuilder([
  updateValue,
  updateFindIn,
  updateMode,
  updateProximityRange,
]);

export default reducerBuilder;
