import React from 'react';
import { Route } from 'react-router-dom';
import { RoutePath } from '@router/path';

import { withResetFilters } from '@pages/search/withResetFilters';
import { AuthorPackagesConnect } from './authorPackages.connect';

export const RouteAuthorPackages = (
  <Route
    path={[RoutePath.authorPackages, RoutePath.authorPackage]}
    component={withResetFilters(AuthorPackagesConnect)}
    exact
  />
);

export default RouteAuthorPackages;
