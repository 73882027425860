import { AnalysisSettings } from '../../../../redux/settings';
import { definitionContentType } from '../../../../redux/state';

export const updateViewReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const {
    type,
    format,
  } = action.data;

  const newSettings = {
    type,
    format,
  };

  const settings = new AnalysisSettings();
  const block = state.analysis.itemsMap[definitionContentType.getId()];
  const layout = settings.updateSettings(block.layout, newSettings);

  return {
    ...state,
    analysis: {
      ...state.analysis,
      itemsMap: {
        ...state.analysis.itemsMap,
        [definitionContentType.getId()]: {
          ...block,
          layout,
        },
      },
    },
  };
};

export default updateViewReduce;
