import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { Button } from '@pages/searchResult/components/button/button';

import s from './textArea.style';

interface TextAreaProps {
  value: string;
  onClose(): void;
  onSubmit(value: string): void;
}

interface TextAreaState {
  value: string;
}

export class TextArea extends React.PureComponent<TextAreaProps, TextAreaState> {

  textareaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  state = {
    value: null,
  };

  componentDidMount() {
    this.setValue(this.props.value);
    this.textareaRef.current.focus();
  }

  setValue = (value) => {
    this.setState({
      value,
    });
  };

  handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > 1999) {
      return;
    }

    this.setValue(e.target.value);
  };

  handleSubmit = () => {
    const value = this.state.value.trim();
    if (!value) {
      return;
    }

    const correctSize = value.length < 2000;
    if (!correctSize) {
      return;
    }

    this.props.onSubmit(value);
    this.props.onClose();
  };

  renderSmartIcon() {
    const iconPath = iconsPath.get('smartWizard');

    return (
      <s.SmartIcon>
        <Icon path={iconPath} color="#673ab7" />
      </s.SmartIcon>
    );
  }

  renderInfoIcon() {
    const iconPath = iconsPath.get('information');

    return (
      <s.Icon>
        <Icon size={16} path={iconPath} color="#6c7b89" />
      </s.Icon>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Control>
          {this.renderSmartIcon()}
          <s.Textarea
            ref={this.textareaRef}
            value={this.state.value}
            onInput={this.handleInput}
          />
          <s.Bottom>
            <s.Info>
              {this.renderInfoIcon()}
              <s.InfoTitle>
                Опишите, что вы ищите и мы подготовим соответствующий запрос — состав фильтров будет обновлен
              </s.InfoTitle>
            </s.Info>
            <s.Actions>
              <s.Button>
                <Button
                  title="Отмена"
                  onClick={this.props.onClose}
                />
              </s.Button>
              <s.Button>
                <Button
                  title="Подготовить фильтры"
                  onClick={this.handleSubmit}
                  color="#673ab7"
                />
              </s.Button>
            </s.Actions>
          </s.Bottom>
        </s.Control>
        <s.Overlay onClick={this.props.onClose} />
      </s.Root>
    );
  }

}

export default TextArea;
