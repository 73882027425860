import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: Record<string, Record<string, string>>;
  disabled: boolean;
}

style.Root = styled.div`
  position: fixed;
  bottom: 32px;
  right: calc(495px - 168px);
  z-index: 3;
`;

style.Navigation = styled.div`
  display: flex;
  flex-direction: row;
  width: 168px;
  height: 40px;
  border-radius: 20px;
  background: rgba(34, 49, 63, 0.9);
  align-items: center;
  padding: 0 17px;
  user-select: none;
`;

style.Title = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props: Props) => props.theme.colors.white};
  font-size: 13px;
  font-family: "Roboto-Regular", Helvetica, Arial, sans-serif;
`;

style.Actions = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 6px;
`;

style.Action = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 6px;

  &:last-of-type {
    padding: 6px 5px;
  }

  ${(props: Props) => props.disabled && css`
    i {
      opacity: 0.2;
    }
  `}
`;

style.ArrowItem = styled.div`
  display: flex;
`;

export default style;
