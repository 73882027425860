import React from 'react';

import { RoutePath } from '@router/path';

import { Container } from '../container';

import form from '../form.style';
import s from './createNewPassword.style';

export const CreateNewPasswordSuccess = () => (
  <Container withLink>
    <form>
      <form.Title>
        Пароль успешно изменен
      </form.Title>

      <form.Description>
        <form.DescriptionTitle>
          Текущий пароль вы всегда можете изменить через меню профиля в системе или по ссылке на форме входа.
        </form.DescriptionTitle>
      </form.Description>

      <form.Fieldset>
        <form.FieldsContainer />
      </form.Fieldset>

      <s.Actions>
        <form.InternalLink to={RoutePath.login}>
          Приступить к работе
        </form.InternalLink>
      </s.Actions>
    </form>
  </Container>
);

export default CreateNewPasswordSuccess;
