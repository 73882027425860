import { fetchDefinitionContentTypeReduce } from './fetchDefinitionContentType.reduce';

import type { ActionData } from './fetchDefinitionContentType.reduce';

const actionType = 'SEARCH/RESULT/FETCH_DEFINITION_CONTENT_TYPE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDefinitionContentType = {
  actionType,
  createAction,
  reduce: fetchDefinitionContentTypeReduce,
};

export default fetchDefinitionContentType;
