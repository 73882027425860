import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchComments() {
  const url = RESTPath.regulation.comments.getComments();
  const request = http.get({ url });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchComments;
