import { connect } from 'react-redux';

import { TableSettings } from './tableSettings';
import { updateTableSettings } from './redux/updateTableSettings';
import { saveDocumentsLayoutSettingsThunk } from './redux/saveLayout.thunk';

function prepareColumns(itemsList, itemsMap) {
  const columns = itemsList.map((columnId) => ({
    id: columnId,
    shouldRender: itemsMap.get(columnId).showColumn,
    title: itemsMap.get(columnId).title,
  }));

  return columns;
}

const mapStateToProps = (state) => {
  const { documents } = state.searchResult;
  const defaultColumns = documents.layout.tableView.defaultColumns;
  const itemsList = documents.layout.tableView.columns.itemsList;
  const itemsMap = documents.layout.tableView.columns.itemsMap;

  const columns = prepareColumns(itemsList, itemsMap);

  return {
    defaultColumns,
    columns,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSaveSort(data) {
    dispatch(updateTableSettings.createAction(data));
    saveDocumentsLayoutSettingsThunk(dispatch);
  },
});

export const TableSettingsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableSettings);

export default TableSettingsConnect;
