export type Id = number;
export type Title = string;
export type Filters = any;

export class Item {

  private id: Id = null;

  private title: Title = '';

  private filters: any;

  public buildItem(item: { id: Id, title: Title, filters: Filters }): void {
    this.setId(item.id);
    this.setTitle(item.title);
    this.setFilters(item.filters);
  }

  public setId(id: Id): void {
    this.id = id;
  }

  public getId(): Id {
    return this.id;
  }

  public setTitle(title: Title): void {
    this.title = title || '';
  }

  public getTitle(): Title {
    return this.title;
  }

  public setFilters(filters: any): void {
    this.filters = filters;
  }

  public getFilters(): any {
    return this.filters;
  }

  public getItem(): Item {
    return this;
  }

}

export default Item;
