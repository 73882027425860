import React from 'react';

import { BookmarksConnect } from '@layouts/bookmarks/components/bookmarks.connect';

import { HeaderSettings } from '../../components/headerSettings';

import s from './header.style';

export const Header = () => (
  <s.Root>
    <s.Title>Личный кабинет</s.Title>
    <s.Actions>
      <s.Action>
        <BookmarksConnect />
      </s.Action>
      <s.Action>
        <HeaderSettings />
      </s.Action>
    </s.Actions>
  </s.Root>
);

export default Header;
