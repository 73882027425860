import { ReducerBuilder } from '@redux/reducer.builder';

import { reducerBuilder as folders } from '../sidebar/folders/redux/reducer';
import { reducerBuilder as smartFolders } from '../sidebar/filters/redux/reducer';
import { reducerBuilder as projects } from '../workspace/redux/reducer';
import { reducerBuilder as searchFolders } from './searchFolders/reducer';
import { reducerBuilder as chat } from './chat/reducer';
import { reducerBuilder as events } from '../workspace/calendar/redux/reducer';
import { reducerBuilder as comments } from '../workspace/comments/redux/reducer';
import { reducerBuilder as preview } from './preview/reducer';
import { reducerBuilder as users } from './users/reducer';

import { resetToInitial } from './resetToInitial';

import { StateBuilder } from './state';

export const reducerBuilder = new ReducerBuilder([
  resetToInitial,
]);

reducerBuilder.merge(folders);
reducerBuilder.merge(smartFolders);
reducerBuilder.merge(projects);
reducerBuilder.merge(searchFolders);
reducerBuilder.merge(chat);
reducerBuilder.merge(events);
reducerBuilder.merge(comments);
reducerBuilder.merge(preview);
reducerBuilder.merge(users);

const initialState = StateBuilder.createState();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
