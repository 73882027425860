import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  height: 100%;
  overflow-y: auto;
  display: flex;
`;

style.Container = styled.div`
  padding: 16px 64px 80px;
  overflow-y: auto;
  overflow-x: hidden;
`;

style.Loader = styled.div`
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
