import { connect } from 'react-redux';

import { saveFiltersThunk } from '@pages/regulation/workspace/toolbar/redux/saveFilters.thunk';

import { FiltersSave } from './filtersSave';

const mapStateToProps = (state) => ({
  shouldRenderButton: !!state.regulation.smartFolders.activeItemId,
  shouldRender: !state.regulation.folders.activeItemId,
});

const mapDispatchToProps = (dispatch) => ({
  onSave(title) {
    saveFiltersThunk(dispatch, title);
  },
});

export const FiltersSaveConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FiltersSave);

export default FiltersSaveConnect;
