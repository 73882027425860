import React from 'react';
import { debounce } from 'lodash';

import {
  TextInput,
  TextInputProps,
} from '@components/textInput/index';

import { SearchIcon } from '@components/searchField/icon/search-icon';

import { RemoveButton } from './removeButton/removeButton';

import s from './block.style';

interface InputProps extends TextInputProps {
  placeholder: string;
  disabled?: boolean;
  initialValue: any;
  onUpdate(data: any): void;
}

interface InputState {
  active: boolean;
  value: string;
}

export class Input extends React.PureComponent<InputProps, InputState> {

  static defaultProps = {
    disabled: false,
  };

  state = {
    active: false,
    value: '',
  };

  rootRef = React.createRef();

  private debouncedUpdateValue = debounce((inputValue) => {
    this.props.onUpdate(inputValue);
  }, 300);

  componentDidMount() {
    const el: any = this.rootRef.current;
    el.children[1].focus();
    this.setState({
      value: this.props.initialValue,
    });
  }

  private handleChangeValue = (e) => {
    const inputValue = e.target.value;

    this.setState({
      value: inputValue,
    });

    this.debouncedUpdateValue(inputValue);
  };

  private handleFocus = () => {
    this.setState(() => ({
      active: true,
    }));
  };

  private handleBlur = () => {
    this.setState(() => ({
      active: false,
    }));
  };

  private handleClearValue = () => {
    this.setState({
      value: '',
    });

    this.props.onUpdate('');
  };

  private renderRemoveButton: () => JSX.Element = function () {
    if (!this.state.value) {
      return null;
    }

    return (
      <RemoveButton
        onClick={this.handleClearValue}
      />
    );
  };

  render() {
    return (
      <s.Root
        ref={this.rootRef}
        isActive={this.state.active}
      >
        <s.IconWrapper
          disabled={this.props.disabled}
        >
          <SearchIcon />
        </s.IconWrapper>
        <TextInput
          onChange={this.handleChangeValue}
          value={this.state.value}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...this.props}
        />
        {this.renderRemoveButton()}
      </s.Root>
    );
  }

}

export default Input;
