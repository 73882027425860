import { connect } from 'react-redux';

import { Workspace } from './workspace';

const mapStateToProps = (state) => ({
  folderId: state.regulation.folders.activeItemId,
  routeType: state.regulation.projects.routeType,
});

export const WorkspaceConnect = connect(
  mapStateToProps,
)(Workspace);

export default WorkspaceConnect;
