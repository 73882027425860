import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { layoutTypes } from '../../../redux/fetchSettings.reduce';

function getViewType(state) {
  const { viewType } = state.documents.layout;

  const isTable = viewType === layoutTypes.table.id;
  if (isTable) {
    sendAmplitudeData('view', { type: 'list' });
    Userpilot.track('view', { type: 'list' });

    return layoutTypes.list.id;
  }

  sendAmplitudeData('view', { type: 'sheets' });
  Userpilot.track('view', { type: 'sheets' });

  return layoutTypes.table.id;
}

export const toggleLayoutTypeReduce = (state) => {
  if (!state) {
    return state;
  }

  const viewType = getViewType(state);

  return {
    ...state,
    documents: {
      ...state.documents,
      layout: {
        ...state.documents.layout,
        viewType,
      },
    },
  };
};

export default toggleLayoutTypeReduce;
