import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  margin-top: 16px;
  background: ${(props) => props.theme.colors.white};
`;

style.Link = styled.div`
  position: relative;

  > a {
    position: absolute;
    top: 11px;
    right: 11px;
  }
`;

style.Loader = styled.div`
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
