import {
  DocumentId,
  Id,
  Source,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

export interface RequestParams {
  source: Source.search | Source.package | Source.subscription | Source.authorPackage;
  sourceId: Id;
  documentId: DocumentId;
}

export function httpFetchDocument(requestParams: RequestParams) {
  const request = http.get({
    url: RESTPath.document.getBody(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchDocument;
