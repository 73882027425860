import { connect } from 'react-redux';

import { Similar } from './similar';

const mapStateToProps = (state) => ({
  baseId: state.document.id,
  isChronologyDocument: state.document.isChronologyDocument,
  isSimilarDocument: state.document.isSimilarDocument,
  parentDocumentId: state.document.parentDocumentId,
  items: state.document.similar.data,
  loading: state.document.similar.loading,
  activeId: state.document.id,
});

export const SimilarConnect = connect(
  mapStateToProps,
)(Similar);

export default SimilarConnect;
