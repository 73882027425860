import { processingStatusReduce } from './processingStatus.reduce';

const actionType = 'SEARCH/RESULT/PROCESSING_STATUS';

const createAction = (status) => ({
  type: actionType,
  data: { status },
});

export const processingStatus = {
  actionType,
  createAction,
  reduce: processingStatusReduce,
};

export default processingStatus;
