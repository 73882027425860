import { connect } from 'react-redux';

import { subscriptionTriggerThunk } from '../../../redux/subscriptionTrigger.thunk';

import { CreateSubscription } from './createSubscription';

const mapStateToProps = (state) => {
  const subscriptionExist = !!state.search.subscription.id;
  const disabled = state.search.subscription.process;

  return {
    subscriptionExist,
    disabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClick(title) {
    subscriptionTriggerThunk(dispatch, { title });
  },
});

export const CreateSubscriptionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSubscription);

export default CreateSubscriptionConnect;
