import { createFilterReduce } from './createFilter.reduce';

import type { ActionData } from './createFilter.reduce';

const actionType = 'REGULATION/FILTER/CREATE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const createFilter = {
  actionType,
  createAction,
  reduce: createFilterReduce,
};

export default createFilter;
