import styled from 'styled-components';

const style: any = {};

style.RemoveButton = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;

  background: transparent;
  cursor: pointer;

  transition: opacity .3s;
`;

export default style;
