import { StateBuilder } from '../../sidebar/redux/state';

export interface ActionData {
  id: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const toggleNotificationReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const layoutState = new StateBuilder(state.subscriptions);
  layoutState.toggleNotification({ id: action.data.id });
  const subscriptions = layoutState.getState();

  return {
    ...state,
    subscriptions,
  };
};

export default toggleNotificationReduce;
