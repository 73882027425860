import { ReducerBuilder } from '@redux/reducer.builder';

import { updateDocumentType } from './updateDocumentType';
import { updateThemeOfDefinition } from './updateThemeOfDefinition';

export const reducerBuilder = new ReducerBuilder([
  updateDocumentType,
  updateThemeOfDefinition,
]);

export default reducerBuilder;
