import React, { Component } from 'react';

import {
  Route,
  Redirect,
} from 'react-router-dom';

interface AuthRoutePropsProps {
  path: string,
  exact: boolean,
  redirectPathname: string;
  redirectSearch: string;
  component: React.ComponentType<any>,
}

export class UnauthRoute extends Component<AuthRoutePropsProps> {

  renderRedirect() {
    const to = {
      push: false,
      pathname: this.props.redirectPathname,
      search: this.props.redirectSearch,
    };

    return <Redirect to={to} />;
  }

  render() {
    if (this.props.redirectPathname) {
      return this.renderRedirect();
    }

    return <Route {...this.props} />;
  }

}

export default UnauthRoute;
