import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { updateFolderTitle } from './updateFolderTitle';

import { httpUpdateFolderTitle } from './updateFolderTitle.http';

export class UpdateFolderTitleThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: number, title: string }) {

    this.marketingEvent(params);
    this.dispatchEditPackageName(params);
    await this.updateSubscriptionHttp(params);

  }

  private marketingEvent(params) {
    const oldName = this.getState().regulation.folders.folders.itemsMap.get(params.id).title;
    const newName = params.title;

    sendAmplitudeData('regulation:rename_folder', { oldName, newName });
    Userpilot.track('regulation:rename_folder');
  }

  private async updateSubscriptionHttp(params) {
    try {
      const requestParams = {
        id: params.id,
        name: params.title,
      };

      await httpUpdateFolderTitle(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchEditPackageName(params) {
    this.dispatch(updateFolderTitle.createAction(params));
  }

}

export function updateSubscriptionTitleThunk(dispatch, params) {
  const thunk = new UpdateFolderTitleThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default UpdateFolderTitleThunk;
