import { packagesBootstrapReduce } from './packagesBootstrap.reduce';

const actionType = 'PACKAGES/PRESETS/FETCH_SUCCESS';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const packagesBootstrap = {
  actionType,
  createAction,
  reduce: packagesBootstrapReduce,
};

export default packagesBootstrap;
