export type ActionData = boolean;

export interface Action {
  type: string;
  data: ActionData;
}

export const setIsSimilarDocumentReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    isSimilarDocument: action.data,
  };
};

export default setIsSimilarDocumentReduce;
