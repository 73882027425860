import React from 'react';
import s from './item.style';

interface CellTitleProps {
  title: string
  isEllipsis: boolean
  isHeaderTitle: boolean
}

export const CellTitleWidthItem = (props: CellTitleProps) => {
  const { title, isEllipsis, isHeaderTitle = false } = props;

  return (
    <s.ColumnWidthTitle>
      <s.CellContainer isNoWrap>
        <s.HeaderText
          isEllipsis={isEllipsis}
          isHeaderTitle={isHeaderTitle}
        >
          {title}
        </s.HeaderText>
      </s.CellContainer>
    </s.ColumnWidthTitle>
  );
};

export default CellTitleWidthItem;
