import { setIsChronologyDocumentReduce } from './setIsChronologyDocument.reduce';

import type { ActionData } from './setIsChronologyDocument.reduce';

const actionType = 'DOCUMENT/SET_IS_CHRONOLOGY_DOCUMENT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setIsChronologyDocument = {
  actionType,
  createAction,
  reduce: setIsChronologyDocumentReduce,
};

export default setIsChronologyDocument;
