import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import {
  SaveButton,
  ResetButton,
} from '@components/buttons';

import {
  Format,
  Formats,
  Type,
  Types,
} from '../../redux/settings';

import s from './editView.style';

interface View {
  type: Types;
  format: Formats;
}

interface Props {
  type: Types;
  format: Formats;
  onSaveView: (view: View) => void;
  closePopover: () => void;
}

interface State {
  type: Types;
  format: Formats;
}

export class EditView extends React.PureComponent<Props, State> {

  state = {
    type: Type.caseResult,
    format: Format.number,
  };

  componentDidMount() {
    if (this.props.type === Type.caseResult) {
      this.handleSetTypeResult();
    }

    if (this.props.type === Type.appealState) {
      this.handleSetTypeAppeal();
    }

    if (this.props.format === Format.number) {
      this.handleSetFormatNumber();
    }

    if (this.props.format === Format.percent) {
      this.handleSetFormatPercent();
    }
  }

  handleSetTypeAppeal = () => {
    this.setState({
      type: Type.appealState,
    });
  };

  handleSetTypeResult = () => {
    this.setState({
      type: Type.caseResult,
    });
  };

  handleSetFormatNumber = () => {
    this.setState({
      format: Format.number,
    });
  };

  handleSetFormatPercent = () => {
    this.setState({
      format: Format.percent,
    });
  };

  handleSaveView = () => {
    this.props.onSaveView(this.state);
    this.props.closePopover();
  };

  renderRadioButton(param, value) {
    if (this.state[param] === value) {
      const checked = iconsPath.get('radiobuttonChecked');

      return (
        <s.Icon>
          <Icon
            path={checked}
            color="#4183d7"
          />
        </s.Icon>
      );
    }

    const unchecked = iconsPath.get('radiobuttonUnchecked');

    return (
      <s.Icon>
        <Icon
          path={unchecked}
          color="#22313f"
        />
      </s.Icon>
    );
  }

  renderType() {
    return (
      <s.FilterItems>
        <s.Title>
          Тип данных
        </s.Title>
        <s.Item
          onClick={this.handleSetTypeResult}
        >
          {this.renderRadioButton('type', Type.caseResult)}
          <s.ItemTitle>Исход рассмотрения</s.ItemTitle>
        </s.Item>
        <s.Item
          onClick={this.handleSetTypeAppeal}
        >
          {this.renderRadioButton('type', Type.appealState)}
          <s.ItemTitle>Статус обжалования</s.ItemTitle>
        </s.Item>
      </s.FilterItems>
    );
  }

  renderFormat() {
    return (
      <s.FilterItems>
        <s.Title>
          Формат данных
        </s.Title>
        <s.Item
          onClick={this.handleSetFormatNumber}
        >
          {this.renderRadioButton('format', Format.number)}
          <s.ItemTitle>Количество документов</s.ItemTitle>
        </s.Item>
        <s.Item
          onClick={this.handleSetFormatPercent}
        >
          {this.renderRadioButton('format', Format.percent)}
          <s.ItemTitle>В процентах от найденных</s.ItemTitle>
        </s.Item>
      </s.FilterItems>
    );
  }

  renderResetButton() {
    return (
      <ResetButton onClick={this.props.closePopover} />
    );
  }

  renderSaveButton() {
    return (
      <SaveButton
        title="Применить"
        onClick={this.handleSaveView}
      />
    );
  }

  render() {
    return (
      <s.Root>
        <s.Menu>
          {this.renderType()}
          {this.renderFormat()}
        </s.Menu>
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Root>
    );
  }

}

export default EditView;
