import { connect } from 'react-redux';

import { cleanBookmarksThunk } from '@layouts/bookmarks/redux/cleanBookmarks.thunk';
import { processThunk } from '@layouts/bookmarks/redux/process.thunk';

import { GlobalMenu } from './globalMenu';

const mapDispatchToProps = (dispatch) => ({
  onRemoveAll() {
    cleanBookmarksThunk(dispatch);
  },
  onDownload() {
    processThunk(dispatch);
  },
});

export const GlobalMenuConnect = connect(
  null,
  mapDispatchToProps,
)(GlobalMenu);

export default GlobalMenuConnect;
