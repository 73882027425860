import type { Rating } from '../../annotation/redux/annotation';

import {
  StateBuilder,
  DocumentId,
} from './state';

export interface ActionData {
  documentId: DocumentId;
  value: Rating;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateSummaryRatingReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.items);
  newState.updateItemRating(action.data.documentId, action.data.value);

  return newState.getState();
};

export default updateSummaryRatingReduce;
