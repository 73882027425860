import { fetchFolderReduce } from './fetchFolder.reduce';

import type { ProjectDTO } from './state';

type ActionData = ProjectDTO[];

const actionType = 'REGULATION/FETCH_FOLDER';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchFolder = {
  actionType,
  createAction,
  reduce: fetchFolderReduce,
};

export default fetchFolder;
