import { RESTPath } from '@router/path.rest';
import http from '@http';
import { Id } from '@pages/regulation/sidebar/folders/redux/item';

interface RequestParams {
  folderId: string;
  ids: Id[];
}

export function httpAddProjects(requestParams: RequestParams) {
  const url = RESTPath.regulation.folders.work({ id: requestParams.folderId });
  const request = http.post({
    url,
    data: {
      ids: requestParams.ids,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpAddProjects;
