import { connect } from 'react-redux';

import { Items } from './items';

function buildItems(params) {
  const query = params.query.trim().toLowerCase();

  return params.subscriptions.itemsList.reduce((acc, id) => {
    const currentItem = params.subscriptions.itemsMap.get(id);
    const currentItemTitle = currentItem.title ? currentItem.title.toLowerCase() : '';
    const contains = currentItemTitle.includes(query);
    if (!contains) {
      return acc;
    }

    acc.push({
      ...params.subscriptions.itemsMap.get(id),
      active: id === params.activeItemId,
    });

    return acc;
  }, []);
}

const mapStateToProps = (state, ownProps: { query: string }) => {
  const { subscriptions } = state.subscriptions;

  const items = buildItems({ subscriptions, activeItemId: subscriptions.activeItemId, query: ownProps.query });

  return {
    items,
  };
};

export const ItemsConnect = connect(
  mapStateToProps,
)(Items);

export default ItemsConnect;
