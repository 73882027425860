// eslint-disable-next-line consistent-return
export default function (value) {
  // eslint-disable-next-line default-case
  switch (value) {
    case 0:
      return '';
      break;
    case 1:
      return 1;
      break;
    case 2:
      return 2;
      break;
    case 3:
      return 3;
      break;
    case 4:
      return 4;
      break;
    case 5:
      return 5;
      break;
    case 6:
      return 6;
      break;
    case 7:
      return 7;
      break;
    case 8:
      return 8;
      break;
    case 9:
      return 9;
      break;
    case 10:
      return 10;
      break;
    case 11:
      return 20;
      break;
    case 12:
      return 30;
      break;
    case 13:
      return 40;
      break;
    case 14:
      return 50;
      break;
    case 15:
      return 60;
      break;
    case 16:
      return 70;
      break;
    case 17:
      return 80;
      break;
    case 18:
      return 90;
      break;
    case 19:
      return 100;
      break;
    case 20:
      return 200;
      break;
    case 21:
      return 300;
      break;
    case 22:
      return 400;
      break;
    case 23:
      return 500;
      break;
    case 24:
      return 600;
      break;
    case 25:
      return 700;
      break;
    case 26:
      return 800;
      break;
    case 27:
      return 900;
      break;
    case 28:
      return 1000;
      break;
    case 29:
      return 2000;
      break;
    case 30:
      return 3000;
      break;
    case 31:
      return 4000;
      break;
    case 32:
      return 5000;
      break;
    case 33:
      return 6000;
      break;
    case 34:
      return 7000;
      break;
    case 35:
      return 8000;
      break;
    case 36:
      return 9000;
      break;
    case 37:
      return 10000;
      break;
    case 38:
      return 20000;
      break;
    case 39:
      return 30000;
      break;
    case 40:
      return 40000;
      break;
    case 41:
      return 50000;
      break;
    case 42:
      return 60000;
      break;
    case 43:
      return 70000;
      break;
    case 44:
      return 80000;
      break;
    case 45:
      return 90000;
      break;
    case 46:
      return 100000;
      break;
    case 47:
      return 200000;
      break;
    case 48:
      return 300000;
      break;
    case 49:
      return 400000;
      break;
    case 50:
      return 500000;
      break;
    case 51:
      return 600000;
      break;
    case 52:
      return 700000;
      break;
    case 53:
      return 800000;
      break;
    case 54:
      return 900000;
      break;
    case 55:
      return 1000000;
      break;
    case 56:
      return 2000000;
      break;
    case 57:
      return 3000000;
      break;
    case 58:
      return 4000000;
      break;
    case 59:
      return 5000000;
      break;
    case 60:
      return 6000000;
      break;
    case 61:
      return 7000000;
      break;
    case 62:
      return 8000000;
      break;
    case 63:
      return 9000000;
      break;
    case 64:
      return 10000000;
      break;
    case 65:
      return 20000000;
      break;
    case 66:
      return 30000000;
      break;
    case 67:
      return 40000000;
      break;
    case 68:
      return 50000000;
      break;
    case 69:
      return 60000000;
      break;
    case 70:
      return 70000000;
      break;
    case 71:
      return 80000000;
      break;
    case 72:
      return 90000000;
      break;
    case 73:
      return 100000000;
      break;
    case 74:
      return 200000000;
      break;
    case 75:
      return 300000000;
      break;
    case 76:
      return 400000000;
      break;
    case 77:
      return 500000000;
      break;
    case 78:
      return 600000000;
      break;
    case 79:
      return 700000000;
      break;
    case 80:
      return 800000000;
      break;
    case 81:
      return 900000000;
      break;
    case 82:
      return 1000000000;
      break;
    case 83:
      return 2000000000;
      break;
    case 84:
      return 3000000000;
      break;
    case 85:
      return 4000000000;
      break;
    case 86:
      return 5000000000;
      break;
    case 87:
      return 6000000000;
      break;
    case 88:
      return 7000000000;
      break;
    case 89:
      return 8000000000;
      break;
    case 90:
      return 9000000000;
      break;
    case 91:
      return 10000000000;
      break;
    case 92:
      return 20000000000;
      break;
    case 93:
      return 30000000000;
      break;
    case 94:
      return 40000000000;
      break;
    case 95:
      return 50000000000;
      break;
    case 96:
      return 60000000000;
      break;
    case 97:
      return 70000000000;
      break;
    case 98:
      return 80000000000;
      break;
    case 99:
      return 90000000000;
      break;
    case 100:
      return 100000000000;
      break;
    case 101:
      return 200000000000;
      break;
    case 102:
      return 300000000000;
      break;
    case 103:
      return 400000000000;
      break;
    case 104:
      return 500000000000;
      break;
    case 105:
      return 600000000000;
      break;
    case 106:
      return 700000000000;
      break;
    case 107:
      return 800000000000;
      break;
    case 108:
      return 900000000000;
      break;
    case 109:
      return 999999999999;
      break;
    case 110:
      return '';
      break;
  }
}
