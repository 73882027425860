import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchPackages } from './fetchPackages';
import { updatePackageDescription } from './updatePackageDescription';
import { addPackage } from './addPackage';
import { createAuthorPackage } from './createAuthorPackage';
import { unpublishAuthorPackage } from './unpublishAuthorPackage';
import { selectPackage } from './selectPackage';
import { updatePackageName } from '../../packageMenu/updatePackageName/redux/updatePackageName';
import { removePackage } from '../../packageMenu/removePackage/redux/removePackage';
import { increaseDocumentsCount } from './increaseDocumentsCount';
import { decreaseDocumentsCount } from './decreaseDocumentsCount';
import { setSearchId } from './setSearchId';
import { resetToInitial } from './resetToInitial';
import { createCompanyPackage } from './createCompanyPackage';
import { unpublishCompanyPackage } from './unpublishCompanyPackage';

export const reducerBuilder = new ReducerBuilder([
  fetchPackages,
  updatePackageDescription,
  addPackage,
  createAuthorPackage,
  unpublishAuthorPackage,
  increaseDocumentsCount,
  decreaseDocumentsCount,
  selectPackage,
  updatePackageName,
  removePackage,
  setSearchId,
  resetToInitial,
  createCompanyPackage,
  unpublishCompanyPackage,
]);

export default reducerBuilder;
