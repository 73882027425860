import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateJudge } from './redux/updateJudge';

import { JudgeBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateJudge(data) {
    dispatch(updateJudge.createAction(data));
  },
});

export const JudgeBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JudgeBlock);

export default JudgeBlockConnect;
