import { fetchProjectReduce } from './fetchProject.reduce';

import type { ActionData } from './fetchProject.reduce';

const actionType = 'REGULATION/PREVIEW/FETCH_PROJECT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchProject = {
  actionType,
  createAction,
  reduce: fetchProjectReduce,
};

export default fetchProject;
