export type ActionData = boolean;

export interface Action {
  type: string;
  data: ActionData;
}

export const setIsChronologyDocumentReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    isChronologyDocument: action.data,
  };
};

export default setIsChronologyDocumentReduce;
