import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { withResetFilters } from '@pages/search/withResetFilters';
import { SubscriptionsConnect } from './subscriptions.connect';

export const RouteSubscriptions = (
  <AuthRouteConnect
    path={[RoutePath.subscriptions, RoutePath.subscription]}
    component={withResetFilters(SubscriptionsConnect)}
    exact
  />
);

export default RouteSubscriptions;
