import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';

import { resetSimilarDocuments } from './resetSimilarDocuments';
import { fetchSimilarDocuments } from './fetchSimilarDocuments';
import { changePageNumber } from './changePageNumber';

import {
  loadingStart,
  loadingEnd,
} from './loading';

import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export const reducerBuilder = new ReducerBuilder([
  fetchSimilarDocuments,
  changePageNumber,
  loadingEnd,
  loadingStart,
  pageLoadingEnd,
  pageLoadingStart,
  resetSimilarDocuments,
]);

reducerBuilder.setInitialState(StateBuilder.createInitialState());

export default reducerBuilder;
