import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  isDefault: boolean;
  isActive: boolean;
  theme: any;
}

style.Root = styled.div`
  height: auto;
  border-right: none;
  padding: 4px 4px 4px 4px;
  flex: 0 auto;
  float: none;

  font: 13px Roboto-Regular;

  transition: all .3s;

  ${(props: Props) => !props.isDefault && css`

    &:hover {
      background-color: rgba(65, 131, 215, 0.07);
    }

    &:active {
      background-color: rgba(65, 131, 215, 0.12);
    }

  `}

  ${(props: Props) => props.isDefault && css`

    cursor: default;

    i {
      transition: all .3s;
      color: rgba(108, 123, 137, 0.12);
    }

  `}

  ${(props: Props) => props.isActive && css`
    background-color: rgba(65, 131, 215, 0.07);

    i {
      transition: all .3s;
      color: ${props.theme.colors.defaultText};
    }
  `}
`;

export default style;
