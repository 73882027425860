import { Id } from './item';
import {
  FolderType, StateBuilder,
} from './state';

export interface ActionData {
  id: Id | FolderType.underControl | FolderType.calendar | FolderType.comments;
}

interface Action {
  type: string;
  data: ActionData;
}

export const selectFolderReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.folders);
  newState.changeActiveItemId(action.data.id);
  const folders = newState.getState();

  return {
    ...state,
    folders,
  };
};

export default selectFolderReduce;
