import { Dispatch } from 'redux';
import { Filters } from '@pages/search/form/filters';
import { setSearchId } from '@pages/packages/redux/packages/setSearchId';

import { resetToInitial } from '@pages/packages/redux/documents/resetToInitial';
import { fetchPackageDocumentsThunk } from '@pages/packages/redux/documents/fetchDocuments.thunk';

import { httpUpdateFilters } from './updateFilters.http';

interface Response {
  channel_id: string;
  search_id: number;
}

export class UpdateFiltersThunk {

  getState;

  private dispatch: Dispatch;

  private response: Response;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke() {
    const {
      packages,
      search,
    } = this.getState();

    try {
      const filters = new Filters();
      filters.createFilters(search.query);
      const conditions = filters.undecorate();
      const response = await httpUpdateFilters({ id: packages.id, conditions });

      if (response.search_id) {
        this.dispatch(resetToInitial.createAction());
        this.dispatch(setSearchId.createAction({ id: response.search_id }));
      }

      fetchPackageDocumentsThunk(this.dispatch, { packageId: packages.id, pageNumber: 1 });
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export function updateFiltersThunk(dispatch) {
  const thunk = new UpdateFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}
