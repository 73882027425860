import { connect } from 'react-redux';

import { plural } from '@components/plural';
import { separator } from '@components/separator';

import {
  Source,
  DownloadType,
} from '@providers/download/download.http';

import { processThunk } from '@providers/download/process.thunk';

import { Header } from './header';

function buildTitle(count, endings) {
  const pluralize = plural(count, endings);
  const totalDocuments = separator(count);

  return `${totalDocuments} ${pluralize}`;
}

const mapStateToProps = (state, ownProps) => {
  const {
    totalProjects,
    newProjects,
    withoutChange,
  } = state.regulation.projects;

  const id = state.regulation.folders.activeItemId;

  if (!ownProps.isList) {
    const endings = ['проект', 'проекта', 'проектов'];

    return {
      id,
      title: buildTitle(totalProjects, endings),
    };
  }

  if (ownProps.isNew) {
    const endings = ['обновленный проект', 'обновленных проекта', 'обновленных проектов'];

    return {
      id,
      title: buildTitle(newProjects, endings),
    };
  }

  const endings = ['проект без изменений', 'проекта без изменений', 'проектов без изменений'];

  return {
    title: buildTitle(withoutChange, endings),
    id: state.regulation.folders.activeItemId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDownload(id: string) {
    let downloadType;
    if (!ownProps.isList) {
      downloadType = DownloadType.all;
    } else {
      downloadType = ownProps.isNew ? DownloadType.new : DownloadType.viewed;
    }

    const params = {
      source: Source.regulationFolder,
      id,
      downloadType,
    };

    processThunk(dispatch, params);
  },
});

export const HeaderConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

export default HeaderConnect;
