import React from 'react';

import { Loader } from '@components/loader';

import { RemoveButton } from '../../../form/components/removeButton/removeButton';

import s from './removeHistoryButton.style';

interface RemoveButtonProps {
  showLoader: boolean;
  onRemove: () => void;
}

export class RemoveHistoryButton extends React.PureComponent<RemoveButtonProps> {

  renderLoader() {
    return (
      <s.LoaderWrapper>
        <Loader
          size={16}
          viewMode
        />
      </s.LoaderWrapper>
    );
  }

  render() {
    if (this.props.showLoader) {
      return this.renderLoader();
    }

    return (
      <s.Root>
        <RemoveButton onClick={this.props.onRemove} />
      </s.Root>
    );

  }

}

export default RemoveHistoryButton;
