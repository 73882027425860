import React from 'react';

import {
  iconsPath,
  Icon,
} from '@components/icons';

import s from './header.style';

interface HeaderProps {
  onGoBack: () => void;
  title: string;
}

export const Header = (props: HeaderProps) => {
  const arrowBackIcon = iconsPath.get('arrowBack');

  return (
    <s.Header>
      <s.Back
        onClick={props.onGoBack}
      >
        <Icon points={arrowBackIcon} color="currentColor" />
      </s.Back>
      <s.HeaderTitle>
        {props.title}
      </s.HeaderTitle>
    </s.Header>
  );
};

export default Header;
