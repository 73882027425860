import { connect } from 'react-redux';

import {
  Title,
  ProjectTypeBlock,
} from '@components/filters/projectType/logic';

import { Block } from '@components/filters/components/block';

import { updateProjectTypeThunk } from './redux/updateProjectType.thunk';

import { FilterType } from './redux/state';

import {
  buildTitle,
  buildCounter,
} from './utils';

const mapStateToProps = (state) => {
  const { data } = state.regulation.filters.filters[FilterType.projectType];
  const selectAllTitle = 'Все типы';

  return {
    title: buildTitle(data, Title.default),
    selectAllTitle,
    counter: buildCounter(data),
    isDefault: !data.length,
    data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdate(data: any) {
    updateProjectTypeThunk(dispatch, data);
  },
  onFetchData(query, page): Promise<any> {
    const block = new ProjectTypeBlock();

    const result = block.getItemsForSuggest(query, page);

    return result;
  },
});

export const ProjectTypeConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default ProjectTypeConnect;
