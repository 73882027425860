import { connect } from 'react-redux';

import { Header } from './header';

const mapStateToProps = (state, ownProps) => ({
  isAuthorPackage: state.packages.packages.itemsMap.get(ownProps.id).type === 'author',
});

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
