import { Dispatch } from 'redux';
import isEqual from 'lodash/isEqual';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Choice } from '@components/filters/abstract';

import { getSource } from '@pages/regulation/utils/marketing';

import { resetProjects } from '../../redux/resetProjects';
import { fetchProjectsThunk } from '../../redux/fetchProjects.thunk';
import { fetchFolderThunk } from '../../redux/fetchFolder.thunk';

import { FilterType } from './state';

import { updateFilter } from './updateFilter';
import { uncheckProjects } from '../../redux/uncheckProjects';

export class UpdateBranchThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public invoke(selected) {
    const type = FilterType.branchOfLegislation;
    const filterData = this.getState().regulation.filters.filters[type].data;
    const data: Choice[] = Object.values(selected);
    const id = this.getState().regulation.folders.activeItemId;
    const source = getSource(id);

    sendAmplitudeData('regulation:update_filter_branch', { data, source });
    Userpilot.track('regulation:update_filter_branch', { data });

    if (isEqual(data, filterData)) {
      return;
    }

    this.dispatch(updateFilter.createAction({ type, data }));
    this.dispatch(uncheckProjects.createAction());
    this.dispatch(resetProjects.createAction());

    const folderId = this.getState().regulation.folders.activeItemId;
    if (folderId) {
      fetchFolderThunk(this.dispatch);
    } else {
      fetchProjectsThunk(this.dispatch);
    }
  }

}

export function updateBranchThunk(dispatch, filter) {
  const thunk = new UpdateBranchThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(filter);
}
