import { updateSubscriptionTitleReduce } from './updateSubscriptionTitle.reduce';

import type { ActionData } from './updateSubscriptionTitle.reduce';

const actionType = 'SUBSCRIPTION/UPDATE_TITLE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateSubscriptionTitle = {
  actionType,
  createAction,
  reduce: updateSubscriptionTitleReduce,
};

export default updateSubscriptionTitle;
