import { toggleLayoutTypeReduce }
  from '@pages/searchResult/documents/header/settingsPopover/redux/toggleLayoutType.reduce';

const actionType = 'AUTHOR_PACKAGES/DOCUMENTS/SETTINGS/TOGGLE_LAYOUT';

const createAction = () => ({
  type: actionType,
});

export const toggleLayoutType = {
  actionType,
  createAction,
  reduce: toggleLayoutTypeReduce,
};

export default toggleLayoutType;
