import { Dispatch } from 'redux';

import { store } from '@redux/store';
import { notifierManager } from '@components/notification';

import { processStart } from './processStart';
import { processComplete } from './processComplete';

import {
  Source,
  Sources,
  DownloadType,
  Format,
  httpDownloadDocuments,
} from './download.http';

type Id = string | number;

export interface Params {
  type?: string;
  name?: string;
  format?: Format.xlsx | Format.docx | Format.pdf;
  percent?: number;
  // eslint-disable-next-line
  source: Sources;

  downloadType?: DownloadType,
  id: Id;
  url?: string;
}

export class ProcessThunk {

  static COMPLETE_PERCENT = 100;

  getState: ReturnType<typeof store.getState>;

  private notificationId = 'PROCESS_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    const state = this.getState().downloads;

    const downloadItem = state[params.source][params.id] && state[params.source][params.id][params.format];
    if (!downloadItem && !params.type) {
      await this.startProcess(params);

      return;
    }

    const isContinue = !downloadItem && params.type && params.percent !== ProcessThunk.COMPLETE_PERCENT;
    if (isContinue) {
      await this.continueProcess(params);

      return;
    }

    const itemAlreadyComplete = downloadItem && !!downloadItem.url;
    if (itemAlreadyComplete) {
      this.showEndNotification(downloadItem);

      return;
    }

    const isComplete = params.percent === ProcessThunk.COMPLETE_PERCENT;
    const hasUrl = !!params.url;
    if (isComplete && hasUrl) {
      this.endProcess(params);
    }

  }

  private continueProcess(params: Params) {
    this.showStartNotification(params.id);
    this.dispatchProgressStart(params);
  }

  private async startProcess(params: Params) {
    try {
      await httpDownloadDocuments(params);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }

    this.showStartNotification(params.id);
    this.dispatchProgressStart(params);
  }

  private endProcess(params: Params) {
    this.showEndNotification(params);
    this.dispatchProcessComplete(params);
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchProgressStart(params: Params) {
    // @ts-ignore
    this.dispatch(processStart.createAction(params));
  }

  showStartNotification(id: Id) {
    const message = 'Подготовка документов к скачиванию.\n' +
      'Пожалуйста, не закрывайте вкладку.';

    notifierManager.pending({ id, message });
  }

  private dispatchProcessComplete(params: Params) {
    // @ts-ignore
    this.dispatch(processComplete.createAction(params));
  }

  showEndNotification(params: Params) {
    setTimeout(() => {
      const message = 'Документы подготовлены';
      notifierManager.done({ ...params, message });
    }, 1000);

    notifierManager.remove({ id: params.id });
  }

}

export function processThunk(dispatch: Dispatch<any>, params: Params) {
  const thunk = new ProcessThunk(dispatch);

  dispatch((_: any, getState: any) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default ProcessThunk;
