import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchAuthorPackages } from './fetchAuthorPackages';
import { selectPackage } from './selectPackage';

export const reducerBuilder = new ReducerBuilder([
  fetchAuthorPackages,
  selectPackage,
]);

export default reducerBuilder;
