import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpLogout() {
  const request = http.delete({
    url: RESTPath.user.login,
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpLogout;
