export interface ActionData {
  title: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchSourceTitleReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    sourceTitle: action.data.title,
  };
};

export default fetchSourceTitleReduce;
