import React from 'react';

import s from './list.style';

export interface ListItem {
  id: string | number,
  title: string
  isChild?: boolean;
}

export interface ListProps {
  // @ts-ignore
  // eslint-disable-next-line react/no-unused-prop-types
  items: ListItem[];
  width: string;
  // @ts-ignore
  // eslint-disable-next-line react/no-unused-prop-types
  activeItem: ListItem,
  // @ts-ignore
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: (block: ListItem) => void;
  // @ts-ignore
  // eslint-disable-next-line react/no-unused-prop-types
  closePopover: () => void;
}

const renderItems: React.FunctionComponent<ListProps> = (props: ListProps) => {
  // @ts-ignore
  const renderItem = (block, index) => {
    const handleClick = () => {
      props.onChange(block);
      props.closePopover();
    };

    return (
      <s.FilterItem
        key={index}
        onClick={handleClick}
        active={block.id === props.activeItem.id}
        disabled={block.isDisabled}
        isChild={block.isChild}
      >
        {block.title}
      </s.FilterItem>
    );
  };

  return (
    <s.FiltersContainer>
      <s.FilterItems>
        { props.items.map(renderItem) }
      </s.FilterItems>
    </s.FiltersContainer>
  );
};

export const List = (props: ListProps) => (
  <s.List width={props.width}>
    {renderItems(props)}
  </s.List>
);

export default List;
