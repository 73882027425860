import React from 'react';

import { ListItem } from '@components/menu/listItem';

import s from './menu.style';

interface MenuProps {
  disable: boolean;
  title: string;
  onClose(): void;
  onClick(): void;
}

export class Menu extends React.PureComponent<MenuProps> {

  handleClick = () => {
    if (this.props.disable) {
      return;
    }

    this.props.onClick();
    this.props.onClose();
  };

  render() {
    return (
      <s.Root
        disable={this.props.disable}
      >
        <ListItem
          title={this.props.title}
          onClick={this.handleClick}
          hasArrow={false}
        />
      </s.Root>
    );
  }

}

export default Menu;
