import React from 'react';

import { DatePicker } from '@components/datepicker';

import { DateBlock as Block } from './redux/date';

import { BlockType } from '../../components/blockType/blockType';
import { Select } from '../../components/select/select';

import 'react-datepicker/dist/react-datepicker.css';

import d from './block.style';
import s from '../block.style';

import { ActionData as DataScope } from './redux/updateScope';
import { ActionData as DataFirstDate } from './redux/updateFirstDate';
import { ActionData as DataSecondDate } from './redux/updateSecondDate';

interface RangePreset {
  id: number;
  title: string;
}

interface DatePreset {
  id: number;
  title: string;
}

export interface DateBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: Block;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onUpdateScope: (data: DataScope) => void;
  onUpdateFirstDate: (data: DataFirstDate) => void;
  onUpdateSecondDate: (data: DataSecondDate) => void;
}

export class DateBlock extends React.PureComponent<DateBlockProps> {

  range: RangePreset;

  date: DatePreset;

  presets: [RangePreset, DatePreset];

  constructor(props) {
    super(props);

    this.range = { id: 1, title: 'Период' };
    this.date = { id: 2, title: 'Конкретная дата' };

    this.presets = [
      this.range,
      this.date,
    ];
  }

  handleChangeType = (item) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: undefined,
    };

    if (item.id === this.range.id) {
      // @ts-ignore
      data.data = Block.presets[0];
    } else {
      // @ts-ignore
      data.data = Block.presets[3];
    }

    // @ts-ignore
    this.props.onUpdateScope(data);
  };

  handleChangeScope = (scope) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: scope,
    };

    this.props.onUpdateScope(data);
  };

  handleDateFromChange = (date) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        value: date,
      },
    };

    this.props.onUpdateFirstDate(data);
  };

  handleDateToChange = (date) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        value: date,
      },
    };

    this.props.onUpdateSecondDate(data);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderType() {
    let activeItem;
    const data = this.props.item.getData();
    const isRange = data.scope.range;
    if (isRange) {
      activeItem = { ...this.range };
    } else {
      activeItem = { ...this.date };
    }

    return (
      <s.FilterItem size="176px">
        <Select
          useAnchorWidth
          activeItem={activeItem}
          list={this.presets}
          onChange={this.handleChangeType}
        />
      </s.FilterItem>
    );
  }

  renderScope() {
    const data = this.props.item.getData();
    if (!data.scope.range) {
      return null;
    }

    const list = [...Block.presets];
    list.splice(3, 1);

    return (
      <d.FilterItem
        isGrow
      >
        <Select
          useAnchorWidth
          activeItem={data.scope}
          list={list}
          onChange={this.handleChangeScope}
        />
      </d.FilterItem>
    );
  }

  renderDateFrom() {
    const data = this.props.item.getData();
    const shouldRenderDate = data.scope.id === Block.presets[3].id ||
      data.scope.id === Block.presets[4].id;

    if (!shouldRenderDate) {
      return null;
    }

    let placeholderText = 'Дата';

    if (data.scope.id === Block.presets[3].id) {
      return (
        <d.FilterItem
          isGrow
        >
          <DatePicker
            placeholderText={placeholderText}
            selected={data.value[0]}
            onChange={this.handleDateFromChange}
          />
        </d.FilterItem>
      );
    }

    if (data.scope.id === Block.presets[4].id) {
      placeholderText = 'С';
    }

    return (
      <d.FilterItem size="144px">
        <DatePicker
          placeholderText={placeholderText}
          selected={data.value[0]}
          onChange={this.handleDateFromChange}
        />
      </d.FilterItem>
    );
  }

  renderDateTo() {
    const data = this.props.item.getData();
    const shouldRenderDate = data.scope.id === Block.presets[4].id;
    if (!shouldRenderDate) {
      return null;
    }

    return (
      <d.FilterItem size="144px">
        <DatePicker
          placeholderText="По"
          selected={data.value[1]}
          onChange={this.handleDateToChange}
        />
      </d.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderType()}
        {this.renderScope()}
        {this.renderDateFrom()}
        {this.renderDateTo()}
      </s.FilterBlock>
    );
  }

}

export default DateBlock;
