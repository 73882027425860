import {
  ActionData,
  fetchCommentsReduce,
} from './fetchComments.reduce';

export const actionType = 'REGULATION/COMMENTS/HAS_COMMENTS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchComments = {
  actionType,
  createAction,
  reduce: fetchCommentsReduce,
};
