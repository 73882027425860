import styled, { css } from 'styled-components';

interface Props {
  width: number;
  hasAction: boolean;
  hasRightBorder: boolean;
  theme: Record<any, any>;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 0 16px;
  height: 48px;
  box-shadow: 0 1px 0 0 ${(props: Props) => props.theme.colors.borderColor};
  border: none;

  width: ${(props: Props) => props.width}%;

  ${(props: Props) => props.hasRightBorder && css`
    box-shadow: 1px 1px 0 0 ${props.theme.colors.borderColor};
  `}
`;

style.Credential = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

style.Title = styled.div`
  padding-top: 11px;

  font: 11px Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.blue};
`;

style.ValueContainer = styled.div`
  padding-top: 2px;
  overflow: hidden;
`;

style.Placeholder = styled.div`
  padding-top: 15px;
  font: 13px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.stone};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

style.Value = styled.div`
  font: 13px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.defaultText};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

style.Action = styled.div`
  display: none;
  align-items: center;

  ${(props: Props) => props.hasAction && css`
    display: flex;
  `}
`;

style.ActionIcon = styled.div`
  position: relative;

  width: 24px;
  height: 24px;

  padding-top: 10px;
`;

style.ActionTitle = styled.div``;

export default style;
