import {
  isString,
  isFunction,
  isPlainObject,
} from 'lodash';

import invariant from 'invariant';

export type ActionType = string;

export type Data = any;

export interface Action {
  type: ActionType;
  data?: Data;
}

interface Item {
  reduce: (state: any, action: any) => (any);
  actionType: string;
  createAction: (data?: Data) => Action;
}

type State = any;

type Reduce = (state: State, action: Action) => Record<string, unknown>;

export class ReducerBuilder {

  private initialState = {};

  private itemsMap = new Map();

  private fnsToCallAfterReduce = [];

  protected protectItem(actionType: ActionType, reduce: Reduce) {
    invariant(isString(actionType), 'Expected a string actionType');
    invariant(isFunction(reduce), 'Expected a function reduce');
    invariant(!this.itemsMap.has(actionType), `Action type ${actionType} already exist`);
  }

  constructor(
    items?: Item[],
  ) {
    invariant(Array.isArray(items), 'Expected an array items');

    items.forEach((i) => {
      if (i) {
        this.setItem(i.actionType, i.reduce);
      }
    });
  }

  get map() {
    return this.itemsMap;
  }

  build() {
    const initialState = isPlainObject(this.initialState) ?
      { ...this.initialState } :
      this.initialState;

    // @ts-ignore
    const reducers = new Map([...this.itemsMap]);

    // eslint-disable-next-line @typescript-eslint/default-param-last
    return (state: State = initialState, action: Action) => {
      const reducer = reducers.get(action.type);
      if (!reducer) {
        return state;
      }

      // @ts-ignore
      const newState = reducer(state, action);

      // @ts-ignore
      return this.fnsToCallAfterReduce.reduce((acc, fn) => fn(acc, state, action), newState);
    };
  }

  setInitialState(state: State) {
    this.initialState = state;
  }

  setItem(actionType: ActionType, reduce: Reduce) {
    this.protectItem(actionType, reduce);
    this.itemsMap.set(actionType, reduce);
  }

  merge(builder: any) {
    invariant(builder instanceof ReducerBuilder, 'Expected instance of ReducerBuilder');

    this.itemsMap = new Map([
      // @ts-ignore
      ...this.itemsMap,
      // @ts-ignore
      ...builder.map,
    ]);
  }

}

export default ReducerBuilder;
