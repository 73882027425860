import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './duplicateButton.style';

type Props = {
  onClick: () => void,
};

const plusPoints = iconsPath.get('plus');

export const DuplicateButton = ({ onClick }: Props) => (
  <s.DuplicateButton
    onClick={onClick}
    className="filter-duplicate-button"
  >
    <Icon points={plusPoints} color="#6c7b89" />
  </s.DuplicateButton>
);

export default DuplicateButton;
