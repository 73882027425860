export interface ActionData {
  value: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateSmartFilterReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    query: {
      ...state.query,
      smartFilter: {
        ...state.query.smartFilter,
        query: action.data.value,
      },
    },
  };
};

export default updateSmartFilterReduce;
