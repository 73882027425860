import { updateViewReduce } from './updateView.reduce';

const actionType = 'SEARCH/RESULT/DEFINITION_CONTENT_TYPE/SETTINGS/UPDATE_VIEW_SETTINGS';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const updateView = {
  actionType,
  createAction,
  reduce: updateViewReduce,
};

export default updateView;
