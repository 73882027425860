import React from 'react';

import { Userpilot } from '@utils/userpilot';

import { OnlineHelp } from '@layouts/onlineHelp/onlineHelp';
import { Bookmarks } from '@layouts/bookmarks/components/bookmarks';

import { MenuButton } from '@components/header';
import { Header } from './components/header';

import { HistoryConnect } from './history/block.connect';
import { BlockConnect } from './form/block.connect';

import s from './search.style';

interface Props {
  sourceId?: string;
  noFetchFilters?: boolean;
  onResetSearchResult(): void;
  renderSmartFilters: boolean;
  onBootstrap(): void;
  onResetToInitial(): void;
  formToken: string;
  onFetchForm(formToken: string): void;
}

export class Search extends React.PureComponent<Props> {

  componentDidMount() {
    Userpilot.reload();
    this.props.onResetSearchResult();
    this.fetchForm();
    if (this.props.noFetchFilters) {
      return;
    }

    this.props.onBootstrap();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.sourceId && !this.props.sourceId) {
      this.props.onBootstrap();
    }
  }

  fetchForm() {
    if (!this.props.formToken) {
      return;
    }

    this.props.onFetchForm(this.props.formToken);
  }

  render() {
    return (
      <s.Search>
        <Header
          title="Поиск"
          menu={<MenuButton checkWidth={768} />}
        >
          <OnlineHelp />
          <Bookmarks />
        </Header>
        <s.Workspace>
          <s.RootContainer>
            <s.FilterContainer>
              <BlockConnect sourceId={this.props.sourceId} renderSmartFilters={this.props.renderSmartFilters} />
            </s.FilterContainer>
            <s.HistoryContainer>
              <HistoryConnect />
            </s.HistoryContainer>
          </s.RootContainer>
        </s.Workspace>
      </s.Search>
    );
  }

}

export default Search;
