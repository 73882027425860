import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BootstrapThunk } from '@bootstrap/bootstrap.thunk';

let bootstraped = false;

function injectDisplayName(Wrapper, Wrapped) {
  // eslint-disable-next-line no-param-reassign
  Wrapper.displayName = Wrapped.displayName || Wrapped.name || 'Component';
}

function ensureConnect(Wrapper) {
  const mapDispatchToProps = (dispatch) => ({

    onBootstrap() {
      const thunk = new BootstrapThunk(dispatch);

      return thunk.invoke();
    },

  });

  return connect(
    null,
    mapDispatchToProps,
  )(Wrapper);
}

export function withBootstrap(WrappedComponent, { alwaysDisplayed = false } = {}) {
  class WithBootstrap extends Component {

    state = {
      status: '',
    };

    statusId = {
      LOADING: 'loading',
      FAILED: 'failed',
      SUCCEED: 'succeed',
    };

    componentDidMount() {
      this.init();
    }

    componentWillUnmount() {
      // @ts-ignore
      this.unmounted = true;
    }

    alwaysDisplayedBootstrap() {
      if (!bootstraped) {
        return this.defaultBootstrap();
      }

      return Promise.resolve(null);
    }

    defaultBootstrap() {
      bootstraped = true;

      // @ts-ignore
      // eslint-disable-next-line react/prop-types
      return this.props.onBootstrap();
    }

    bootstrap() {
      if (alwaysDisplayed) {
        return this.alwaysDisplayedBootstrap();
      }

      return this.defaultBootstrap();
    }

    async init() {
      this.setState({ status: this.statusId.LOADING });

      try {
        await this.bootstrap();

        // @ts-ignore
        if (!this.unmounted) {
          this.setState({ status: this.statusId.SUCCEED });
        }
      } catch (error) {
        // @ts-ignore
        if (!this.unmounted) {
          this.setState({ status: this.statusId.FAILED });
        }
      }
    }

    alwaysDisplayedRender() {
      if (this.state.status === this.statusId.LOADING) {
        return null;
      }

      return this.renderContent();
    }

    defaultRender() {
      if (this.state.status !== this.statusId.FAILED) {
        return null;
      }

      return this.renderContent();
    }

    renderContent() {
      const {
        // @ts-ignore
        // eslint-disable-next-line react/prop-types
        onBootstrap,
        ...props
      } = this.props;

      return <WrappedComponent {...props} />;
    }

    render() {
      if (alwaysDisplayed) {
        return this.alwaysDisplayedRender();
      }

      return this.defaultRender();
    }

  }

  injectDisplayName(WithBootstrap, WrappedComponent);

  return ensureConnect(WithBootstrap);
}

export default withBootstrap;
