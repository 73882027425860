export const decreasePackageCountReduce = (state) => {
  if (!state) {
    return state;
  }

  const packagesCount = state.packagesCount - 1 < 0 ? 0 : state.packagesCount - 1;

  return {
    ...state,
    packagesCount,
  };
};

export default decreasePackageCountReduce;
