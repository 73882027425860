import { connect } from 'react-redux';

import { updateScope } from './redux/updateScope';
import { updateFirstDate } from './redux/updateFirstDate';
import { updateSecondDate } from './redux/updateSecondDate';

import { DateBlock } from './block';

const mapStateToProps = (state) => {
  const scope = state.search.presets.date;

  return {
    scope,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateScope(data) {
    dispatch(updateScope.createAction(data));
  },
  onUpdateFirstDate(data) {
    dispatch(updateFirstDate.createAction(data));
  },
  onUpdateSecondDate(data) {
    dispatch(updateSecondDate.createAction(data));
  },
});

export const DateBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DateBlock);

export default DateBlockConnect;
