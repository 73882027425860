import React from 'react';

import s from './control.style';

export interface ControlProps {
  title: string;
  isDefault: boolean;
}

export class Control extends React.PureComponent<ControlProps> {

  render() {
    return (
      <s.Root
        isDefault={this.props.isDefault}
      >
        <s.Wrapper>
          {this.props.title}
        </s.Wrapper>
      </s.Root>
    );
  }

}

export default Control;
