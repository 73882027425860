import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateCaseNumber } from './redux/updateCaseNumber';

import { CaseNumberBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateCaseNumber(data) {
    dispatch(updateCaseNumber.createAction(data));
  },
});

export const CaseNumberBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaseNumberBlock);

export default CaseNumberBlockConnect;
