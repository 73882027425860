import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchBookmarks(requestParams: { pageNumber: number }) {
  const request = http.get({
    url: RESTPath.bookmarks.getItemsPage(requestParams.pageNumber),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchBookmarks;
