import React from 'react';

import { TextInput } from '@components/textInput/index';

import { SearchIcon } from '@components/searchField/icon/search-icon';

import { RemoveButton } from './removeButton/removeButton';

import s from './block.style';

interface InputMockProps {
  placeholder: string;
}

export class InputMock extends React.PureComponent<InputMockProps> {

  private handleClearValue = () => null;

  private renderRemoveButton: () => JSX.Element = function () {
    return (
      <RemoveButton
        onClick={this.handleClearValue}
      />
    );
  };

  render() {
    return (
      <s.Root
        isActive={false}
      >
        <s.IconWrapper
          disabled
        >
          <SearchIcon />
        </s.IconWrapper>
        <TextInput
          value=""
          disabled
          placeholder={this.props.placeholder}
        />
        {this.renderRemoveButton()}
      </s.Root>
    );
  }

}

export default InputMock;
