import { setNotificationVisitedReduce } from './setNotificationVisited.reduce';

import type { ActionData } from './setNotificationVisited.reduce';

const actionType = 'REGULATION/SET_NOTIFICATION_VISITED';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setNotificationVisited = {
  actionType,
  createAction,
  reduce: setNotificationVisitedReduce,
};

export default setNotificationVisited;
