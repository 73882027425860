import { connect } from 'react-redux';

import { separator } from '@components/separator';

import { ItemDetail } from './itemDetail';

import { Format } from '../../redux/settings';

import { date } from '../../redux/state';

import { FetchSearchMonthThunk } from '../redux/fetchMonth.thunk';
import { addSearchBlockThunk } from '../redux/addSearchBlocks.thunk';

const transformItem = (data, settings, options) => {
  const columns = [];

  const title = {
    id: 'title',
    title: data.title || '',
  };

  // @ts-ignore
  columns.push(title);

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      const isPercent = options.format === Format.percent;
      const value = separator(data[options.type][column][options.format]);
      let cellData = value;
      if (isPercent) {
        cellData = `${value}%`;
      }

      columns.push({
        // @ts-ignore
        id: column,
        // @ts-ignore
        title: cellData,
      });
    }
  });

  return columns;
};

const prepareItems = (items, settings, options) => {
  const data = [];

  if (!items.itemsList.length) {
    return data;
  }

  items.itemsList.forEach((itemId) => {
    const item = items.itemsMap.get(itemId);
    // @ts-ignore
    data.push(transformItem(item, settings, options));
  });

  return data;
};

export interface BlockData {
  year: string;
  month: string;
  columnId: string;
}

const mapStateToProps = (state, ownProps) => {

  const data = state.searchResult.analysis.itemsMap[date.getId()];

  const { shouldLoadMonth } = data.items.itemsMap.get(ownProps.id);

  const { type, format } = data.layout;
  const month = data.items.itemsMap.get(ownProps.id).items;

  const settings = data.layout.columns[type];
  const renderItems = prepareItems(month, settings, { type, format });

  return {
    items: renderItems,
    shouldLoadMonth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    const thunk = new FetchSearchMonthThunk(dispatch);
    dispatch((_, getState) => { thunk.getState = getState; });

    const courtId = ownProps.id;
    thunk.invoke(courtId);

    return thunk;
  },
  onAddBlock(data: BlockData) {
    addSearchBlockThunk(dispatch, data);
  },
});

export const ItemDetailConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemDetail);

export default ItemDetailConnect;
