import React from 'react';

import { CloseButton } from '@components/buttons';

import { AddFolderConnect } from '../list/addFolder.connect';
import { Download } from './download';

import s from './header.style';

interface HeaderProps {
  id: string,
  title: string;
  onClose: () => void;
  onDownload(id: string): void;
}

export const Header = (props: HeaderProps) => (
  <s.Root>
    <s.CloseButton>
      <CloseButton
        color="#4183d7"
        onClick={props.onClose}
      />
    </s.CloseButton>
    <s.Title>{props.title}</s.Title>
    <s.Tools>
      <s.Tool>
        <AddFolderConnect id={props.id} usePortal={false} from="preview" />
      </s.Tool>
      <s.Tool>
        <Download {...props} />
      </s.Tool>
    </s.Tools>
  </s.Root>
);

export default Header;
