import http from '@http';
import { RESTPath } from '@router/path.rest';

export enum Source {
  search = 'search',
  package = 'package',
  authorPackage = 'authorpackage',
  regulationProject = 'regulation',
  regulationFolder = 'regulationFolder',
  subscription = 'subscription',
  chronology = 'history',
  similar = 'similar',
}

export type Sources = Source.search |
Source.package |
Source.subscription |
Source.chronology |
Source.similar |
Source.authorPackage |
Source.regulationProject |
Source.regulationFolder;

export enum Format {
  xlsx = 'xlsx',
  docx = 'docx',
  pdf = 'pdf',
}

export enum DownloadType {
  all = 'all',
  viewed = 'viewed',
  new = 'new',
}

export interface RequestParams {
  // eslint-disable-next-line
  source: Sources;
  id: number | string;
  format?: Format.xlsx | Format.docx | Format.pdf;
  downloadType?: DownloadType;
}

export function httpDownloadDocuments(requestParams: RequestParams) {
  const url = RESTPath.download(requestParams);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpDownloadDocuments;
