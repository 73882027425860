import React from 'react';

import s from './button.style';

interface ButtonProps {
  color?: string;
  size?: string;
  fullWidth?: boolean;
  linkLike?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent) => void;
  children: React.ReactNode;
}

export const Button = (props: ButtonProps) => (
  <s.Root
    linkLike={props.linkLike}
    fullWidth={props.fullWidth}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <s.Text
      disabled={props.disabled}
      linkLike={props.linkLike}
    >
      {props.children}
    </s.Text>
  </s.Root>
);

Button.defaultProps = {
  disabled: false,
  linkLike: false,
  fullWidth: false,
};

export default Button;
