import { RESTPath } from '@router/path.rest';
import http from '@http';

export interface RequestParams {
  id: number,
  name: string,
}

export function httpUpdatePackageName(requestParams: RequestParams) {
  const request = http.post({
    url: RESTPath.packages.updateTitle(requestParams.id),
    data: {
      value: requestParams.name,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUpdatePackageName;
