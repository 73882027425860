import { v4 as uuid } from 'uuid';
import {
  isBoolean, cloneDeep,
} from 'lodash';

import http from '@http';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

export type Id = string;
export type Title = string;
export type Address = string;

interface Exactly {
  id: 'exactly';
  title: 'Точное название';
}

interface Precise {
  id: 'precise';
  title: 'Конкретная компания';
}

interface Same {
  id: 'same';
  title: 'Похожее название';
}

export type Mode = Exactly | Precise | Same;

export interface Participant {
  id: Id;
  title: Title;
  address: Address;
}

export interface ParticipantBlockData {
  participant: Participant;
  mode: Mode;
}

export interface ResponseItem {
  id: string;
  address: string;
  name: string;
}

export interface SuggestResponse {
  result: ResponseItem[];
}

export class ParticipantBlock extends AbstractFilterBlock {

  static type = 'participant';

  static DTOType = 'participant';

  static blockTitle = 'Участник';

  static category = category.dispute;

  static SuggestAPI = '/autocomplete/side_inn';

  protected defaultData: ParticipantBlockData = {
    participant: {
      // @ts-ignore
      id: null,
      title: '',
      // @ts-ignore
      address: null,
    },
    mode: ParticipantBlock.presetsMap.exactly as Mode,
  };

  static presetsMap = {
    precise: {
      id: 'precise',
      title: 'Конкретная компания',
    },
    exactly: {
      id: 'exactly',
      title: 'Точное название',
    },
    same: {
      id: 'same',
      title: 'Похожее название',
    },
  };

  constructor() {
    super();

    this.setCategory(ParticipantBlock.category);
    this.setType({
      id: ParticipantBlock.type,
      DTOType: ParticipantBlock.DTOType,
      title: ParticipantBlock.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const updateData = {
      ...data,
      mode: ParticipantBlock.presetsMap[data.mode.id],
    };

    const block = {
      id,
      data: updateData,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    const data = cloneDeep(this.getData());

    delete data.mode.title;

    const isPreciseMode = data.mode.id === ParticipantBlock.presetsMap.precise.id;
    if (!isPreciseMode) {
      data.participant.id = null;
      data.participant.title = data.participant.title.trim();
    }

    if (!data.participant.id) {
      data.participant.id = null;
    }

    if (!data.participant.title) {
      data.participant.title = null;
    }

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: ParticipantBlock.DTOType,
      data,
    };
  }

  public getItemsForSuggest = async (query) => {
    const getItems = async (): Promise<SuggestResponse> => {
      const request = {
        url: ParticipantBlock.SuggestAPI,
        body: {
          query,
        },
      };

      const response: SuggestResponse = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.result.map((item) => ({
        id: item.id,
        title: item.name,
        address: item.address,
      })),
    };
  };

  // eslint-disable-next-line
  public validate() {}

}

export default ParticipantBlock;
