import React from 'react';

import { theme } from '@components/styled';

import {
  Icon,
  iconsPath,
} from '@components/icons';

export const SearchIcon = () => {
  const path = iconsPath.get('search');
  const size = 24;
  const color = theme.colors.stone;

  return (
    <Icon path={path} size={size} color={color} />
  );
};

export default SearchIcon;
