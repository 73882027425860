import { DateBlock } from '@components/filters/date/logic/date';
import {
  StateBuilder as FiltersBuilder, FilterType,
} from '@pages/regulation/workspace/toolbar/redux/state';
import { StateBuilder } from './state';

export interface ActionData {
  id: number;
  name: string;
  filters: any;
}

interface Action {
  type: string;
  data: ActionData;
}

export const createFilterReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const filters = action.data.filters;

  const newFilters = new FiltersBuilder({ filters, loading: false });
  const date = new DateBlock();
  date.create(filters.date);
  const newDate = date.undecorate();
  newFilters.updateFilter(FilterType.date, newDate);

  const filtersState = newFilters.getState().filters;

  const newState = new StateBuilder(state.smartFolders);

  const item = {
    id: action.data.id,
    name: action.data.name,
    filters: filtersState,
  };

  newState.addItem(item);

  return {
    ...state,
    smartFolders: newState.getState(),
  };
};

export default createFilterReduce;
