import { StateBuilder } from './state';

export interface ActionData {
  counter: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchUnderControlCounterReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.folders);
  newState.setUnderControlCounter(action.data.counter);
  newState.setUnderControlLoadingFalse();

  const folders = {
    ...newState.getState(),
  };

  return {
    ...state,
    folders,
  };
};

export default fetchUnderControlCounterReduce;
