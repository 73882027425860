export const markIsVisitedReduce = (state) => {
  if (!state) {
    return state;
  }

  const {
    currentPageNumber,
    documentId,
  } = state.documents.previewDocument;

  const page = state.documents.pages[currentPageNumber];

  const item = page.itemsMap.get(documentId);

  const newItem = {
    ...item,
    isVisited: true,
  };

  const newItemsMap = page.itemsMap.set(documentId, newItem);

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap: newItemsMap,
        },
      },
    },
  };
};

export default markIsVisitedReduce;
