import React from 'react';

import { Checkbox } from '@components/checkbox/checkbox';

import s from './item.style';

type Id = string;
type Title = string;

interface ItemProps {
  id: Id;
  title: Title;
}

interface ItemProps {
  id: string;
  title: string;
  checked: boolean;
  onClick: (ItemProps) => void;
}

export class Item extends React.PureComponent<ItemProps> {

  handleClick = () => {
    const item = {
      id: this.props.id,
      title: this.props.title,
    };

    this.props.onClick(item);
  };

  render() {
    return (
      <s.Root
        onClick={this.handleClick}
      >
        <Checkbox
          checked={this.props.checked}
          onClick={this.handleClick}
        />
        <s.Title>
          {this.props.title}
        </s.Title>
      </s.Root>
    );
  }

}

export default Item;
