import { resetChronologyDocumentsReduce } from './resetChronologyDocuments.reduce';

const actionType = 'CHRONOLOGY/DOCUMENTS/RESET';

export interface Action {
  type: typeof actionType;
}

const createAction = (): Action => ({
  type: actionType,
});

export const resetChronologyDocuments = {
  actionType,
  createAction,
  reduce: resetChronologyDocumentsReduce,
};

export default resetChronologyDocuments;
