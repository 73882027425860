import { Dispatch } from 'redux';

import { fetchSearch } from '@pages/searchResult/redux/fetchSearch';
import { updateSmartFilter } from './updateSmartFilter';
import { prepareFiltersStart } from './prepareFiltersStart';
import { prepareFiltersEnd } from './prepareFiltersEnd';
import { prepareFiltersFail } from './prepareFiltersFail';
import { httpFetchFilters } from './fetchFilters.http';

interface Params {
  value: string;
}

export class FetchFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(params: Params) {
    this.dispatch(prepareFiltersStart.createAction());
    this.updateSmartFilter(params.value);
    const requestParams = {
      query: params.value,
    };

    try {
      const result = await httpFetchFilters(requestParams);
      this.dispatchFetchFilters(result, params);
      this.dispatch(prepareFiltersEnd.createAction());
    } catch (error) {
      this.dispatch(prepareFiltersFail.createAction());
      throw Error(error.status);
    }
  }

  updateSmartFilter(value: string) {
    this.dispatch(updateSmartFilter.createAction({ value }));
  }

  dispatchFetchFilters(response, params) {
    const data = {
      filter: response.filters,
      wizard_filters: {
        query: params.value,
        is_changed: false,
      },
    };

    this.dispatch(fetchSearch.createAction(data));
  }

}

export function fetchFiltersThunk(dispatch, params) {
  const thunk = new FetchFiltersThunk(dispatch);
  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}
