import React from 'react';

type Ref = {
  ref: React.Ref<HTMLDivElement>
};

type RenderBodyT = ({ ref }: Ref) => React.RefObject<HTMLDivElement>;

export interface ClickOutsideProps {
  onClickOutside: () => void;
  children: RenderBodyT;
  ignoreClickTo?: HTMLElement;
  disabled?: boolean;
  useCapture?: boolean;
}

export class ClickOutside extends React.PureComponent<ClickOutsideProps> {

  private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  static defaultProps = {
    ignoreClickTo: null,
    useCapture: true,
    disabled: false,
  };

  mouseUpListener = {
    type: 'mouseup',
    options: { capture: this.props.useCapture },
  };

  mouseDownListener = {
    type: 'mousedown',
    options: { capture: this.props.useCapture },
  };

  componentDidMount() {
    if (this.props.disabled) {
      return;
    }

    this.addMouseDownEventListener();
  }

  componentDidUpdate(prevProps: ClickOutsideProps) {
    const { disabled } = this.props;

    const shouldUpdate = prevProps.disabled !== disabled;
    if (shouldUpdate) {
      this.removeAllEventListeners();
      if (!disabled) {
        this.addMouseDownEventListener();
      }
    }
  }

  componentWillUnmount() {
    this.removeAllEventListeners();
  }

  private removeAllEventListeners = () => {
    this.removeMouseDownEventListener();
    this.removeMouseUpEventListener();
  };

  private addMouseDownEventListener = () => {
    // @ts-ignore
    document.addEventListener(
      this.mouseDownListener.type,
      this.handleMouseDown,
      this.mouseDownListener.options,
    );
  };

  private removeMouseDownEventListener = () => {
    // @ts-ignore
    document.removeEventListener(
      this.mouseDownListener.type,
      this.handleMouseDown,
      this.mouseDownListener.options,
    );
  };

  private addMouseUpEventListener = () => {
    // @ts-ignore
    document.addEventListener(
      this.mouseUpListener.type,
      this.handleMouseUp,
      this.mouseUpListener.options,
    );
  };

  private removeMouseUpEventListener = () => {
    // @ts-ignore
    document.removeEventListener(
      this.mouseUpListener.type,
      this.handleMouseUp,
      this.mouseUpListener.options,
    );
  };

  private isRightTarget = (e: MouseEvent) => {
    const { ignoreClickTo } = this.props;

    const isDescendantOfRoot = (
      e.target instanceof Node &&
      this.wrapperRef.current &&
      this.wrapperRef.current.contains(e.target)
    );

    const isDescendantOfIgnore = (
      e.target instanceof Node &&
      ignoreClickTo &&
      ignoreClickTo.contains(e.target)
    );

    const LEFT_MOUSE_BUTTON_CODE = 0;
    const isLeftMouseButton = e.button === LEFT_MOUSE_BUTTON_CODE;

    const isNeededTarget = !isDescendantOfRoot && !isDescendantOfIgnore;

    return isNeededTarget && isLeftMouseButton;
  };

  private handleMouseDown = (e: MouseEvent) => {
    if (this.isRightTarget(e)) {
      this.addMouseUpEventListener();
    }
  };

  private handleMouseUp = (e: MouseEvent) => {
    if (this.isRightTarget(e)) {
      this.removeMouseUpEventListener();
      this.props.onClickOutside();
    }
  };

  render() {
    return this.props.children({ ref: this.wrapperRef });
  }

}

export default ClickOutside;
