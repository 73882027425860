import { logoutReduce } from './logout.reduce';

const actionType = 'USER/LOGOUT';

const createAction = () => ({
  type: actionType,
});

export const logout = {
  actionType,
  createAction,
  reduce: logoutReduce,
};

export default logout;
