import { RESTPath } from '@router/path.rest';
import http from '@http';

interface RequestParams {
  searchId: number;
  title: string;
}

export function httpSubscriptionCreate(requestParams: RequestParams) {
  const request = http.post({
    url: RESTPath.subscriptions.origin,
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSubscriptionCreate;
