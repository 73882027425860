import React from 'react';

import { Loader } from '@components/loader';
import { SearchField } from '@components/searchField/searchField';

import { ItemsConnect } from './items.connect';

import s from './tableOfContents.style';

interface TableOfContentsProps {
  loading: boolean;
}

export class TableOfContents extends React.PureComponent<TableOfContentsProps> {

  private placeholder = 'Поиск';

  state = {
    searchQuery: '',
  };

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: e.target.value,
    });
  };

  renderSearchField = () => (
    <SearchField
      value={this.state.searchQuery}
      placeholder={this.placeholder}
      onChange={this.handleSearchQuery}
    />
  );

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return (
      <s.Root>
        <s.SearchField>
          {this.renderSearchField()}
        </s.SearchField>
        <ItemsConnect query={this.state.searchQuery} />
      </s.Root>
    );
  }

}

export default TableOfContents;
