import { connect } from 'react-redux';

import { prepareColumns } from '../../../utils';

import { resetItems } from '../redux/resetItems';
import { FetchSearchDisputeCategoryThunk } from '../redux/fetchDisputeCategory.thunk';

import { List } from './list';

const mapStateToProps = (state, ownProps) => {
  const {
    items,
    layout,
    currentPageNumber,
    totalPages,
  } = state.searchResult.analysis.itemsMap[ownProps.id];

  const settings = layout.columns[layout.type];
  const viewColumns = prepareColumns(settings);

  return {
    searchId: state.searchResult.id,
    columns: viewColumns,
    items: items.itemsList,
    currentPageNumber,
    totalPages,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchData(id) {
    const thunk = new FetchSearchDisputeCategoryThunk(dispatch);
    thunk.invoke(id);

    return thunk;
  },
  onResetItems() {
    dispatch(resetItems.createAction());
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onFetchData() {
    dispatchProps.onFetchData(stateProps.searchId);
  },
});

export const ListConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(List);

export default ListConnect;
