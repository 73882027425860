import { Dispatch } from 'redux';

import { AnalysisSettings } from '../../redux/settings';

import { caseType } from '../../redux/state';

import { saveCaseTypeLayoutSettingsSucceeded } from './saveLayout';

import { httpSaveCaseTypeLayoutSettings } from './saveLayout.http';

export class SaveCaseTypeLayoutSettingsThunk {

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(layout) {

    const settings = new AnalysisSettings();
    const dto = settings.undecorate(layout);

    try {
      await httpSaveCaseTypeLayoutSettings(dto);
    } catch (error) {
      throw Error(error.status);
    }
  }

  dispatchSaveSucceeded() {
    this.dispatch(saveCaseTypeLayoutSettingsSucceeded.createAction());
  }

}

export function saveCaseTypeLayoutSettingsThunk(dispatch) {
  const thunk = new SaveCaseTypeLayoutSettingsThunk(dispatch);

  dispatch((_, getState) => {
    const { searchResult } = getState();
    thunk.invoke(searchResult.analysis.itemsMap[caseType.getId()].layout);
  });

}
