import { connect } from 'react-redux';

import { fetchPackagesThunk } from '@pages/packages/redux/searchPackages/fetchPackagesSearchResult.thunk';
// eslint-disable-next-line max-len
import { searchPackagesResultResetToInitial } from '@pages/packages/redux/searchPackages/searchPackagesResultResetToInitial';

import { AddPackage } from '@components/addPackage/addPackage';

import { addDocumentToPackageThunk } from '../redux/addDocumentToPackage.thunk';
import { createPackageThunk } from '../redux/createPackageAddDocument.thunk';

const mapStateToProps = (state, ownProps) => {
  const { currentPageNumber, pages } = state.searchResult.documents;
  const { packagesCount } = pages[currentPageNumber].itemsMap.get(ownProps.id);

  const packages = state.packages.searchResult;

  const count = packagesCount > 99 ?
    '99+' :
    packagesCount;

  return {
    count,
    currentPageNumber,
    packages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    const documentId = ownProps.id;
    fetchPackagesThunk(dispatch, { documentId });
  },
  onResetToInitial() {
    dispatch(searchPackagesResultResetToInitial.createAction());
  },
  onPackageCreate(query: string) {
    const searchQuery = query.trim();
    const documentId = ownProps.id;

    const params = {
      query: searchQuery,
      documentId,
    };

    createPackageThunk(dispatch, params);
  },
  onPackageToggle(packageId: number, pageNumber: number) {
    const documentId = ownProps.id;
    const params = {
      documentId,
      packageId,
      pageNumber,
    };

    addDocumentToPackageThunk(dispatch, params);
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onPackageToggle(packageId: number) {
    dispatchProps.onPackageToggle(packageId, stateProps.currentPageNumber);
  },
});

export const AddPackageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AddPackage);

export default AddPackageConnect;
