import { connect } from 'react-redux';

import { layoutTypes } from '@pages/searchResult/documents/redux/fetchSettings.reduce';
import { toggleCommentary } from './redux/toggleCommentary';

import { LayoutSettings } from './layoutSettings';
import { toggleLayoutType } from './redux/toggleLayoutType';
import { saveDocumentsLayoutSettingsThunk } from './redux/saveLayout.thunk';

interface ViewType {
  id: string;
  title: string;
}

interface Props {
  showCommentary: boolean;
  viewType: ViewType,
}

const mapStateToProps = (state): Props => {
  const props = {} as Props;

  const viewTypeId = state.subscriptions.documents.layout.viewType;

  props.viewType = layoutTypes[viewTypeId];

  props.showCommentary = state.subscriptions.documents.showCommentary;

  return props;
};

const mapDispatchToProps = (dispatch) => ({
  toggleLayoutType() {
    dispatch(toggleLayoutType.createAction());
    saveDocumentsLayoutSettingsThunk(dispatch);
  },
  toggleCommentary: () => {
    dispatch(toggleCommentary.createAction());
  },
});

export const LayoutSettingsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutSettings);

export default LayoutSettingsConnect;
