import React from 'react';

import { BackToMainLink } from '../components/backToMainLink';

import { Container } from '../container';

import form from '../form.style';
import s from './passwordRecovery.style';

export const PasswordRecoveryEmailSent = () => (
  <Container withLink>
    <form>
      <form.Title>
        Письмо отправлено
      </form.Title>

      <form.Description>
        <form.DescriptionTitle>
          На указанный email отправлена ссылка для установки нового пароля, она будет активна в течение 10 часов.
        </form.DescriptionTitle>
      </form.Description>

      <form.Fieldset>
        <form.FieldsContainer />
      </form.Fieldset>

      <s.Actions>
        <BackToMainLink />
      </s.Actions>
    </form>
  </Container>
);

export default PasswordRecoveryEmailSent;
