import { getQuery } from '@pages/search/history/redux/fetchHistory.reduce';

interface SmartFilterDTO {
  query: string;
  is_changed: boolean;
}

export interface ActionData {
  subscription_id?: number;
  filter: Record<string, any>;
  wizard_filters?: SmartFilterDTO;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchSearchReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = {
    ...state,
    query: {
      ...state.query,
      ...getQuery(action.data.filter, state.presets),
    },
    subscription: {
      ...state.subscription,
      id: action.data.subscription_id,
    },
    defaultQuery: {
      ...state.defaultQuery,
      loading: false,
    },
  };

  let smartFilter = {
    query: null,
    hasChange: false,
    preparing: false,
  };

  if (action.data.wizard_filters) {
    smartFilter = {
      query: action.data.wizard_filters.query,
      hasChange: action.data.wizard_filters.is_changed,
      preparing: false,
    };
  }

  newState.query.smartFilter = smartFilter;

  return newState;
};

export default fetchSearchReduce;
