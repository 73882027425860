import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  padding: 0 64px 16px;
  margin: 0 auto;
  min-width: 960px;
  max-width: 1152px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

style.Action = styled.div`
  padding: 0;
`;

style.ActionCancel = styled.div`
  margin-right: 16px;
  > button {
    background: #f3f4f5;

    &:hover,:active,:focus {
      background: #f3f4f5;
    }

    > span {
      color: #6c7b89;
    }
  }
`;

export default style;
