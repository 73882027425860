import { connect } from 'react-redux';

import { UserRoleType } from '@pages/userPages/redux/state';

import {
  getTitle,
  buildHistoryItem,
} from '@utils/utils';

import { Header } from './header';

const mapStateToProps = (state) => {
  const path = [];
  path.push(buildHistoryItem(1, 'Авторские подборки', false, '#/authorpackages'));

  if (state.authorPackages.packages.loading) {
    path.push(buildHistoryItem(2, null, true));
  }

  const title = getTitle(state.authorPackages.packages);
  const hasTitle = !state.authorPackages.packages.loading && !!title;
  if (hasTitle) {
    path.push(buildHistoryItem(2, title, false));
  }

  return {
    isReal: state.currentUser.role !== UserRoleType.guest,
    path,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
