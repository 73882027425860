import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Id } from '@pages/regulation/sidebar/folders/redux/item';

export interface RequestParams {
  folderId: Id,
  emails: string;
  role: string;
}

export function httpInviteUsers(requestParams: RequestParams): Promise<any> {
  const url = RESTPath.regulation.folders.inviteUsers(requestParams.folderId);

  const data = {
    emails: requestParams.emails,
    role: requestParams.role,
  };

  const request = http.post({
    url,
    data,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpInviteUsers;
