import { connect } from 'react-redux';

import { FolderType } from '@pages/regulation/sidebar/folders/redux/state';

import { Settings } from './settings';

const mapStateToProps = (state) => {
  const { folders, smartFolders } = state.regulation;

  const restrictedFolders = !folders.activeItemId ||
    folders.activeItemId === FolderType.underControl ||
    folders.activeItemId === FolderType.calendar;

  let shouldRender = !restrictedFolders || !!smartFolders.activeItemId;

  const item = folders.folders.itemsMap.get(folders.activeItemId);
  if (item && item.role === 'visitor') {
    shouldRender = false;
  }

  return {
    folderId: folders.activeItemId,
    filterId: smartFolders.activeItemId,
    shouldRender,
    renderForFolders: !restrictedFolders,
  };
};

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
