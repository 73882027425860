import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchSettings } from './fetchSettings';
import { fetchDocuments } from './fetchDocuments';
import { fetchDocumentsPackageCount } from './fetchDocumentsPackageCount';
import { changePageNumber } from './changePageNumber';
import { toggleItemSelect } from './toggleItemSelect';
import { toggleSelectionRule } from './toggleSelectionRule';

import { increasePackageCount } from './increasePackageCount';
import { decreasePackageCount } from './decreasePackageCount';

import { resetToInitial } from './resetToInitial';

import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

import {
  loadingEnd,
  loadingStart,
} from './loading';

import { reducerBuilder as documentsSettingsReducer } from '../header/settingsPopover/redux/reducer';
import { reducerBuilder as documentPreviewReducer } from '../preview/redux/reducer';

export const reducerBuilder = new ReducerBuilder([
  fetchSettings,
  fetchDocuments,
  fetchDocumentsPackageCount,
  loadingEnd,
  loadingStart,
  pageLoadingStart,
  pageLoadingEnd,
  changePageNumber,
  toggleItemSelect,
  toggleSelectionRule,
  increasePackageCount,
  decreasePackageCount,
  resetToInitial,
]);

reducerBuilder.merge(documentsSettingsReducer);
reducerBuilder.merge(documentPreviewReducer);

export default reducerBuilder;
