import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateAppealState } from './redux/updateAppealState';

import { AppealStateBlock } from './block';

const mapStateToProps = (state) => {
  const { appealState } = state.search.presets;

  return {
    appealStates: appealState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateAppealState(data) {
    dispatch(updateAppealState.createAction(data));
  },
});

export const AppealStateBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppealStateBlock);

export default AppealStateBlockConnect;
