import { connect } from 'react-redux';

import { deleteBookmarkFromBufferThunk } from '@layouts/bookmarks/redux/deleteBookmark.thunk';

import { ItemMenu } from './itemMenu';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRemoveBookmark() {

    const params = {
      paragraphId: Number(ownProps.paragraphId),
    };

    deleteBookmarkFromBufferThunk(dispatch, params);
  },
});

export const ItemMenuConnect = connect(
  null,
  mapDispatchToProps,
)(ItemMenu);

export default ItemMenuConnect;
