import { setActiveTabReduce } from './setActiveTab.reduce';

import type { ActionData } from './setActiveTab.reduce';

const actionType = 'DOCUMENT/CHANGE_ACTIVE_TAB';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setActiveTab = {
  actionType,
  createAction,
  reduce: setActiveTabReduce,
};

export default setActiveTab;
