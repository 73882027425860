import moment from 'moment';
import {
  Id,
  DateBlock,
  DateBlockData,
} from '../../form/filterBlocks/date/redux/date';

export abstract class DateBlockHistory extends DateBlock {

  static transform(data: DateBlockData): string[] {
    const { scope } = data;

    const rangeTitle = 'Период • ';
    const scopeTitle = scope.title;
    const isDateRange = scope.range && scope.id === Id.range;
    if (isDateRange) {
      const dataValue1 = data.value[0] ? ` • ${moment(data.value[0]).format('DD.MM.YYYY').toString()}` : '';
      const dataValue2 = data.value[1] ? ` • ${moment(data.value[1]).format('DD.MM.YYYY').toString()}` : '';

      const title = `${scopeTitle}${dataValue1}${dataValue2}`;

      return [rangeTitle, title];
    }

    if (scope.range) {
      return [rangeTitle, `${scopeTitle}`];
    }

    const { value } = data;
    const title = value[0] ?
      ` • ${moment(value[0]).format('DD.MM.YYYY').toString()}` :
      '';

    const result = [`${scopeTitle}`, title];

    return result;
  }

}

export default DateBlockHistory;
