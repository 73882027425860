import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import {
  AddTagButton,
  AddPackageButtonProps,
} from './addTagButton';

import {
  TagList,
  Item,
} from './tagList';

import s from './addTag.style';

interface AddTagProps {
  source: string;
  tags: Item[];
  disabled: boolean;
  usePortal?: boolean;
  currentTag: AddPackageButtonProps;
  onSelectTag(id: string): void;
}

export const AddTag = (props: AddTagProps) => {
  if (props.source !== 'package' && props.source !== 'authorpackage') {
    return null;
  }

  return (
    <s.Root>
      <BaseDropdown
        usePortal={props.usePortal}
        disabled={props.disabled}
        placement="bottom-end"
        renderInput={(p) => (
          <AddTagButton
            disabled={props.disabled}
            {...p}
            {...props.currentTag}
          />
        )}
        renderMenu={(p) => (
          <TagList
            items={props.tags}
            onSelectTag={props.onSelectTag}
            {...p}
          />
        )}
      />
    </s.Root>
  );
};

AddTag.defaultProps = {
  usePortal: true,
};

export default AddTag;
