import React from 'react';
import { CellTitleWidthItem } from './cellTitle';
import TextFitCalculationComponent from '../../components/textFitCalculationComponent/textFitCalculationComponent';
import { Tooltip } from '../../components/tooltip/tooltip';

import s from './item.style';

interface CellTitleWithTooltipProps {
  title: string;
  tooltip: string;
  // eslint-disable-next-line react/require-default-props
  isEmpty?: boolean;
  columns: number
  // eslint-disable-next-line react/require-default-props
  isPercent?: boolean;
}

interface CellTitleWithTooltipState {
  parentRef: React.RefObject<HTMLDivElement>;
  isFit: boolean;
}

function withTooltip(CellWidthItem) {
  return class extends React.Component<CellTitleWithTooltipProps, CellTitleWithTooltipState> {

    ref: React.RefObject<HTMLDivElement> = React.createRef();

    state = {
      parentRef: { current: null },
      isFit: true,
    };

    componentDidMount() {
      if (this.ref.current) {
        this.setState({ parentRef: this.ref });
      }
    }

    handleFit = (isFitVar: boolean) => {
      if (isFitVar !== this.state.isFit) {
        this.setState({ isFit: isFitVar });
      }
    };

    renderHiddenColumn = () => {
      const { title, columns, isEmpty } = this.props;

      const renderHeaderText = () => (
        <s.HeaderText
          textAlign="end"
          isEmpty={isEmpty}
          isHeaderTitle
          isTransparent
        >
          {title}
        </s.HeaderText>
      );

      return (
        <TextFitCalculationComponent
          title={title}
          handleFit={this.handleFit}
          parentRef={this.state.parentRef}
          columns={columns}
        >
          { renderHeaderText() }
        </TextFitCalculationComponent>
      );
    };

    renderTooltip = () => {
      const { isEmpty, isPercent } = this.props;

      const isPercentValid = isPercent && !isEmpty;
      const shouldRenderTooltip = isPercentValid || !this.state.isFit;

      if (!shouldRenderTooltip) {
        return null;
      }

      const tooltipBlock = (<Tooltip title={this.props.tooltip} />);

      return tooltipBlock;
    };

    render() {
      const isEllipsis = !this.state.isFit;
      const { title, isEmpty } = this.props;

      return (
        <s.CellTitle
          ref={this.ref}
        >
          {this.renderHiddenColumn()}
          {this.renderTooltip()}
          <CellWidthItem
            title={title}
            isEllipsis={isEllipsis}
            isEmpty={isEmpty}
            isHeaderTitle
          />
        </s.CellTitle>
      );
    }

  };
}

export const CellTitleWithTooltip = withTooltip(CellTitleWidthItem);
export default CellTitleWithTooltip;
