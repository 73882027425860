import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Item } from './item';

import s from './items.style';

import type { ItemProps } from './item';

interface ItemsProps {
  items: ItemProps[];
}

export class Items extends React.PureComponent<ItemsProps> {

  checkIsEmptyList = () => isEmpty(this.props.items);

  renderItems() {
    if (this.checkIsEmptyList()) {
      return this.renderEmptyTitle();
    }

    return (
      <s.Items>
        {this.props.items.map(this.renderItem)}
      </s.Items>
    );
  }

  renderEmptyTitle() {
    return (
      <s.EmptyTitle>
        Ничего не найдено
      </s.EmptyTitle>
    );
  }

  renderItem(item) {
    return <Item key={item.id} {...item} />;
  }

  render() {
    return (
      <s.Root
        isEmpty={this.checkIsEmptyList()}
      >
        {this.renderItems()}
      </s.Root>
    );
  }

}

export default Items;
