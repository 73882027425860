import { fetchCommentsStartReduce } from './fetchCommentsStart.reduce';

export const actionType = 'REGULATION/COMMENTS/FETCH_START';

const createAction = () => ({
  type: actionType,
});

export const fetchCommentsStart = {
  actionType,
  createAction,
  reduce: fetchCommentsStartReduce,
};
