import { Dispatch } from 'redux';

import { AnalysisSettings } from '../../redux/settings';

import { documentType } from '../../redux/state';

import { saveDocumentTypeLayoutSettingsSucceeded } from './saveLayout';

import { httpSaveTypeIdLayoutSettings } from './saveLayout.http';

export class SaveTypeIdLayoutSettingsThunk {

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(layout) {

    const settings = new AnalysisSettings();
    const dto = settings.undecorate(layout);

    try {
      await httpSaveTypeIdLayoutSettings(dto);
    } catch (error) {
      throw Error(error.status);
    }
  }

  dispatchSaveSucceeded() {
    this.dispatch(saveDocumentTypeLayoutSettingsSucceeded.createAction());
  }

}

export function saveDocumentTypeLayoutSettingsThunk(dispatch) {
  const thunk = new SaveTypeIdLayoutSettingsThunk(dispatch);

  dispatch((_, getState) => {
    const { searchResult } = getState();
    thunk.invoke(searchResult.analysis.itemsMap[documentType.getId()].layout);
  });

}
