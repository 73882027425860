import http from '@http';
import {
  RESTPath,
  Source,
  Id,
} from '@router/path.rest';

export enum SelectionRule {
  include = 'include',
  exclude = 'exclude',
}

export interface DocumentsToPackageAddRequestParams {
  targetId: Id;
  source: Source.search | Source.subscription | Source.package | Source.authorPackage;
  sourceId: Id;
  documents: {
    rule: SelectionRule.include | SelectionRule.exclude;
    ids: number[];
  };
}

export function httpAddDocumentsToPackage(requestParams: DocumentsToPackageAddRequestParams) {
  const url = RESTPath.packages.addDocuments({ packageId: requestParams.targetId });
  const request = http.post({
    url,
    data: {
      source: requestParams.source,
      sourceId: requestParams.sourceId,
      documents: requestParams.documents,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpAddDocumentsToPackage;
