import React from 'react';
import { connect } from 'react-redux';

import { resetForm } from '@pages/search/form/redux/resetForm';
import { resetToInitial } from '@pages/search/redux/resetToInitial';

function ensureConnect(Wrapped) {
  const mapDispatchToProps = (dispatch) => ({
    onBootstrap() {
      dispatch(resetToInitial.createAction());
      dispatch(resetForm.createAction());
    },

  });

  return connect(
    null,
    mapDispatchToProps,
  )(Wrapped);
}

export function withResetFilters(WrappedComponent) {
  class WithBootstrap extends React.PureComponent {

    render() {
      return <WrappedComponent {...this.props} />;
    }

  }

  return ensureConnect(WithBootstrap);
}

export default withResetFilters;
