export enum Layout {
  documents = 'documents',
  filters = 'filters',
}

export type LayoutTypes = Layout.documents | Layout.filters;

interface State {
  type: LayoutTypes;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      type: Layout.documents,
    };
  }

  constructor(state) {
    this.state = state;
  }

  toggleLayoutType() {
    let type = Layout.documents;

    const isDocuments = this.state.type === Layout.documents;
    if (isDocuments) {
      type = Layout.filters;
    }

    const isFilters = this.state.type === Layout.filters;
    if (isFilters) {
      type = Layout.documents;
    }

    this.state = {
      ...this.state,
      type,
    };
  }

  getState() {
    return this.state;
  }

}

export default StateBuilder;
