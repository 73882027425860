import React from 'react';

import { MenuButton } from '@components/header';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './header.style';

export interface HeaderProps {
  title: string;
  loading: boolean;
  isActual: boolean;
}

export class Header extends React.PureComponent<HeaderProps> {

  renderMenu() {
    return (
      <s.Menu>
        <MenuButton />
      </s.Menu>
    );
  }

  renderActualContainer() {
    if (this.props.isActual) {
      return null;
    }

    return (
      <s.Actual>
        Утратил силу
      </s.Actual>
    );
  }

  renderTitle() {
    if (!this.props.title || this.props.loading) {
      return <s.LoadingTitle />;
    }

    return (
      <s.Title>{this.props.title}</s.Title>
    );
  }

  renderBetaIcon() {
    const crossPath = iconsPath.get('betaCircle');

    return (
      <s.Icon>
        <Icon path={crossPath} color="#ff6400" />
      </s.Icon>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderMenu()}
        {this.renderTitle()}
        {this.renderActualContainer()}
        {this.renderBetaIcon()}
      </s.Root>
    );
  }

}

export default Header;
