import { connect } from 'react-redux';

import { updatePackageNameThunk } from './redux/updatePackageName.thunk';

import { PackageId } from '../packageMenu';

import {
  PackageName,
  UpdatePackageName,
} from './updatePackageName';

const mapStateToProps = (state, ownProps) => {
  const packageName = state.packages.packages.itemsMap.get(ownProps.id).title;

  return {
    packageName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSavePackageName(id: PackageId, title: PackageName) {
    const data = {
      id,
      title,
    };

    updatePackageNameThunk(dispatch, data);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const onSavePackageName = (name: PackageName) => (
    dispatchProps.onSavePackageName(ownProps.id, name)
  );

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSavePackageName,
  };
};

export const UpdatePackageNameConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(UpdatePackageName);

export default UpdatePackageNameConnect;
