import { StateBuilder } from './state';

export const changePageNumberReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.setCurrentPageNumber(action.data.pageNumber);

  return {
    ...newState.getState(),
  };
};

export default changePageNumberReduce;
