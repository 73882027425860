import React from 'react';

import s from './control.style';

export interface ControlProps {
  title: string;
  counter: string;
  isActive: boolean;
  isDefault: boolean;
}

export class Control extends React.PureComponent<ControlProps> {

  renderCounter() {
    if (!this.props.counter) {
      return null;
    }

    return (
      <s.Counter>
        &nbsp;
        {this.props.counter}
      </s.Counter>
    );
  }

  render() {
    return (
      <s.Root
        isDefault={this.props.isDefault}
        isActive={this.props.isActive}
      >
        <s.Wrapper>
          <s.Title>
            {this.props.title}
          </s.Title>
          {this.renderCounter()}
        </s.Wrapper>
      </s.Root>
    );
  }

}

export default Control;
