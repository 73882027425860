import { fetchProjectsReduce } from './fetchProjects.reduce';

import type { ProjectDTO } from './state';

type ActionData = ProjectDTO[];

const actionType = 'REGULATION/FETCH_PROJECTS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchProjects = {
  actionType,
  createAction,
  reduce: fetchProjectsReduce,
};

export default fetchProjects;
