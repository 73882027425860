import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { ProjectNumber } from '../../redux/state';
import { Header } from './header';

import { ProjectTabConnect as ProjectTab } from './projectTab.connect';
import { PhasesTabConnect as PhasesTab } from './phasesTab.connect';
import { NoteTabConnect as NoteTab } from './noteTab.connect';
import { AttributesTabConnect as AttributesTab } from './attributesTab.connect';
import { ChatTabConnect as ChatTab } from './chatTab.connect';
import { SpectractorsTabConnect as SpectractorsTab } from './spectractorsTab.connect';

import s from './preview.style';

export interface DocumentsProps {
  id: ProjectNumber;
  title: string;
  role: string;
  projectLoading: boolean;
  isFolder: boolean;
  onClose: () => void;
  onDownload(id: ProjectNumber): void;
  onFetchPreview(): void;
  onFetchChat(): void;
  onFetchSpectractors(): void;
}

type ItemId = string;

interface TabBarItemProps {
  onClick: (id: ItemId) => void;
  title: string | React.Component;
  activeId: ItemId;
  id: ItemId;
}

const TabBarItem = (props: TabBarItemProps) => {
  const handleClick = () => {
    props.onClick(props.id);
  };

  const isActive = props.activeId === props.id;

  return <s.TabItem active={isActive} onClick={handleClick}>{props.title}</s.TabItem>;
};

interface TabBarProps {
  activeId: ItemId;
  tabs: any[];
  onTabClick: (id: ItemId) => void;
}

const TabBar = (props: TabBarProps) => {
  const renderTabs = () => props.tabs.map((tab) => (
    <TabBarItem
      activeId={props.activeId}
      key={tab.id}
      id={tab.id}
      title={tab.title}
      onClick={props.onTabClick}
    />
  ));

  return (
    <s.TabBar>
      {renderTabs()}
    </s.TabBar>
  );
};

export class Preview extends React.PureComponent<DocumentsProps, any> {

  protected tabs = {
    project: {
      id: 'project',
      title: 'Проект',
      component: ProjectTab,
    },
    phases: {
      id: 'phases',
      title: 'Стадии',
      component: PhasesTab,
    },
    note: {
      id: 'note',
      title: 'Пояснительная записка',
      component: NoteTab,
    },
    attributes: {
      id: 'attributes',
      title: 'Атрибуты',
      component: AttributesTab,
    },
    chat: {
      id: 'chat',
      title: 'Комментарии',
      component: ChatTab,
    },
    spectractors: {
      id: 'spectractors',
      title: 'Наблюдатели',
      component: SpectractorsTab,
    },
  };

  projectRef: React.RefObject<HTMLDivElement> = React.createRef();

  phasesRef: React.RefObject<HTMLDivElement> = React.createRef();

  noteRef: React.RefObject<HTMLDivElement> = React.createRef();

  chatRef: React.RefObject<HTMLDivElement> = React.createRef();

  spectractorsRef: React.RefObject<HTMLDivElement> = React.createRef();

  state = {
    activeTabId: this.tabs.project.id,
  };

  componentDidMount() {
    if (this.props.projectLoading) {
      this.props.onFetchPreview();
      this.props.onFetchChat();
      this.props.onFetchSpectractors();
    }
  }

  componentDidUpdate(prevProps: Readonly<DocumentsProps>) {
    if (this.props.id !== prevProps.id) {
      this.init();
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleChangeActiveTab = (tabId: ItemId) => {
    this.marketingEvent(tabId);
    this.setActiveTab(tabId);
  };

  setActiveTab = (tabId) => {
    this.setState({
      activeTabId: tabId,
    });
  };

  private marketingEvent(tabId) {
    sendAmplitudeData(`regulation:preview_open_${tabId}_tab`);
    Userpilot.track(`regulation:preview_open_${tabId}_tab`);
  }

  init() {
    if (!this.props.id) {
      this.props.onClose();

      return;
    }

    this.props.onFetchPreview();
    this.props.onFetchChat();
    this.props.onFetchSpectractors();
  }

  renderTabBar() {
    const tabs = Object.values(this.tabs);

    const isVisitor = this.props.role === 'visitor';
    if (!this.props.isFolder || isVisitor) {
      tabs.pop();
    }

    return (
      <TabBar
        tabs={tabs}
        activeId={this.state.activeTabId}
        onTabClick={this.handleChangeActiveTab}
      />
    );
  }

  renderActiveTab() {
    const Component = this.tabs[this.state.activeTabId].component;

    const isProject = this.state.activeTabId === this.tabs.project.id;
    if (isProject) {
      return (
        <s.Workspace
          ref={this.projectRef}
        >
          <Component id={this.props.id} />
        </s.Workspace>
      );
    }

    const isPhases = this.state.activeTabId === this.tabs.phases.id;
    if (isPhases) {
      return (
        <s.Workspace
          ref={this.phasesRef}
        >
          <Component id={this.props.id} />
        </s.Workspace>
      );
    }

    const isNote = this.state.activeTabId === this.tabs.note.id;
    if (isNote) {
      return (
        <s.Workspace
          ref={this.noteRef}
        >
          <Component id={this.props.id} />
        </s.Workspace>
      );
    }

    const isChat = this.state.activeTabId === this.tabs.chat.id;
    if (isChat) {
      return (
        <s.Workspace
          ref={this.chatRef}
        >
          <Component id={this.props.id} />
        </s.Workspace>
      );
    }

    const isSpectractors = this.state.activeTabId === this.tabs.spectractors.id;
    if (isSpectractors) {
      return (
        <s.Workspace
          ref={this.spectractorsRef}
        >
          <Component id={this.props.id} />
        </s.Workspace>
      );
    }

    return (
      <s.Workspace>
        <Component id={this.props.id} />
      </s.Workspace>
    );
  }

  render() {
    if (!this.props.id) {
      return null;
    }

    return (
      <>
        <s.Root>
          <Header
            id={this.props.id}
            title={this.props.title}
            onClose={this.props.onClose}
            onDownload={this.props.onDownload}
          />
          {this.renderTabBar()}
          {this.renderActiveTab()}
        </s.Root>
        <s.Background />
      </>
    );
  }

}

export default Preview;
