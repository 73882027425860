import styled from 'styled-components';

interface Props {
  marginRight: number;
  active: boolean;
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 56px;
  padding-right: 8px;
  align-items: center;
  flex-shrink: 0;
`;

style.CloseButton = styled.div`
  padding: 12px 8px 12px 12px;
`;

style.Title = styled.div`
  font-size: 17px;
  font-family: "Roboto-Medium", Helvetica, Arial, sans-serif;
  padding-top: 1px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

style.Tools = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: end;
  align-items: center;
`;

style.Tool = styled.div`
  margin-right: ${(props: Props) => props.marginRight || 8}px;
`;

export default style;
