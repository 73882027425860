import {
  Id,
  StateBuilder,
} from './state';

export interface ActionData {
  selectProjects: Id[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const checkProjectsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.checkProjects(action.data.selectProjects);
  const projects = newState.getState();

  return {
    ...state,
    projects,
  };
};

export default checkProjectsReduce;
