import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  position: relative;
  height: 47px;
`;

style.Control = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 320px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15), 0px 2px 8px 0px rgba(0,0,0,0.15);
`;

style.Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index:1;
`;

style.Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  display: flex;
  resize: none;
  padding: 16px 44px;
  border: none;
  outline: none;
`;

style.Bottom = styled.div`
  display: flex;
  height: 48px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 8px;
`;

style.Info = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 11px;
  align-items: center;
  color: ${(props) => props.theme.colors.stone};
  padding: 16px 32px;
  width: 100%;
`;

style.InfoTitle = styled.div``;

style.Icon = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
`;

style.SmartIcon = styled.div`
  position: absolute;
  top: 11px;
  left: 12px;
`;

style.Actions = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 250px;
`;
style.Button = styled.div``;

export default style;
