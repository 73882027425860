import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { downloadCommentsThunk } from '../../redux/downloadComments.thunk';

import { DownloadButton } from './downloadButton';

const mapDispatchToProps = (dispatch) => ({
  onDownload() {
    sendAmplitudeData('regulation:download_comments');
    Userpilot.track('regulation:download_comments');
    downloadCommentsThunk(dispatch);
  },
});

export const DownloadButtonConnect = connect(
  null,
  mapDispatchToProps,
)(DownloadButton);

export default DownloadButtonConnect;
