import { Dispatch } from 'redux';

import { notifyAllSubscriptions } from './notifyAllSubscriptions';
import { unNotifyAllSubscriptions } from './unNotifyAllSubscriptions';

import { httpNotifyAllSubscriptions } from './notifyAllSubscriptions.http';
import { httpUnNotifyAllSubscriptions } from './unNotifyAllSubscriptions.http';

export class ToggleNotificationsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params?: { switchOn: boolean }) {

    if (params.switchOn) {
      this.dispatchSwitchOnAllNotifications();
      this.httpNotifyAllSubscriptions();
    } else {
      this.dispatchSwitchOffAllNotifications();
      this.httpUnNotifyAllSubscriptions();
    }
  }

  private dispatchSwitchOnAllNotifications() {
    this.dispatch(notifyAllSubscriptions.createAction());
  }

  private async httpNotifyAllSubscriptions() {
    try {
      await httpNotifyAllSubscriptions();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchSwitchOffAllNotifications() {
    this.dispatch(unNotifyAllSubscriptions.createAction());
  }

  private async httpUnNotifyAllSubscriptions() {
    try {
      await httpUnNotifyAllSubscriptions();
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export function toggleNotificationsThunk(dispatch, params) {
  const thunk = new ToggleNotificationsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default ToggleNotificationsThunk;
