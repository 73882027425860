import {
  styled,
  theme,
} from '@components/styled';

const style: any = {};

style.Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${theme.colors.white};
  z-index: 999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

style.Header = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 12px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.borderColor};
`;

style.Workspace = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

style.WorkspaceContainer = styled.div`
  padding: 15px 0 16px;
  margin: 0 auto;
  width: 960px;
`;

style.Action = styled.div`
  width: 24px;
  height: 24px;
`;

style.Title = styled.div`
  font: 17px Roboto-Medium;
  margin-left: 8px;
`;

style.Users = styled.div``;

export default style;
