import { RESTPath } from '@router/path.rest';
import http from '@http';
import { SortType } from '../../components/sortIcon/sortIcon';

export interface GetDocumentsSettings {
  searchId: number,
  filterType: string,
  valueId: string,
  sortDirection: SortType,
}

export function httpFetchSearchLaws(settings: GetDocumentsSettings) {
  const request = http.get({
    url: RESTPath.search.analytic.documents.getDocuments(settings),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSearchLaws;
