import { connect } from 'react-redux';

import { AddPackage } from '@components/addPackage/addPackage';

import { createPackageThunk } from '../../../redux/documents/createPackage.thunk';

import { addDocumentToPackageThunk } from '../../../redux/documents/addDocumentToPackage.thunk';

import { fetchPackagesThunk } from '../../../redux/searchPackages/fetchPackagesSearchResult.thunk';

import { searchPackagesResultResetToInitial } from '../../../redux/searchPackages/searchPackagesResultResetToInitial';

const mapStateToProps = (state, ownProps) => {
  const { currentPageNumber, pages } = state.packages.documents;
  const { packagesCount } = pages[currentPageNumber].itemsMap.get(ownProps.id);

  const packages = state.packages.searchResult;

  const count = packagesCount > 99 ?
    '99+' :
    packagesCount;

  return {
    count,
    packages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    const documentId = ownProps.id;
    fetchPackagesThunk(dispatch, { documentId });
  },
  onResetToInitial() {
    dispatch(searchPackagesResultResetToInitial.createAction());
  },
  onPackageCreate(query: string) {
    const searchQuery = query.trim();
    const documentId = ownProps.id;

    const params = {
      query: searchQuery,
      documentId,
    };

    createPackageThunk(dispatch, params);
  },
  onPackageToggle(packageId: number) {
    const documentId = ownProps.id;
    const params = {
      documentId,
      packageId,
    };

    addDocumentToPackageThunk(dispatch, params);
  },
});

export const AddPackageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPackage);

export default AddPackageConnect;
