import {
  css,
  keyframes,
} from 'styled-components';

import { styled } from '@components/styled';

interface Props {
  shrink: boolean;
  disabled: boolean;
  error: boolean;
  skewPadding: boolean;
}

function textEllipsis() {
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  width: 100%;
`;

function labelColor(props: { theme: any, shrink: boolean; disabled: boolean; error: boolean }) {
  const { colors } = props.theme;

  let color = colors.stone;
  if (props.shrink) {
    color = colors.blue;
  }

  if (props.disabled) {
    color = colors.silver;
  }

  if (props.error) {
    color = colors.brightOrange;
  }

  return color;
}

style.Label = styled.label<Props>`
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  color: ${labelColor};
  pointer-events: none;

  transform: translate(0, 0) scale(1);
  transform-origin: top left;
  transition: all 150ms;

  ${(props) => props.shrink && css`
    line-height: 1.45;
    font-family: 'Roboto-Medium';
    transform: translate(0, -7px) scale(0.8);
  `}
`;

style.LabelText = styled.div`
  ${textEllipsis};
`;

style.animationAutoFillStart = keyframes`
  0% {
    background: transparent;
  }
  100% {
    background: transparent;
  }
`;

style.animationAutoFillCancel = keyframes`
  from {
    background: transparent;
  }
  to {
    background: transparent;
  }
`;

function inputBackgroundColor(props: { theme: any; error: boolean; }) {
  const { colors } = props.theme;

  let color = colors.white;
  if (props.error) {
    color = colors.polar;
  }

  return color;
}

style.Input = styled.input<Props>`
  box-sizing: border-box;
  display: block;
  height: 47px;
  width: 100%;
  padding: 16px 16px 15px;
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: ${inputBackgroundColor};
  outline: none;

  // remove firefox autofill yellow background
  filter: none;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: ${(props) => props.theme.colors.polar};
  }

  &:not([disabled]):active {
    background-color: ${(props) => props.theme.colors.polar};
  }

  ${(props) => props.skewPadding && css`
    padding: 24px 16px 7px;
  `}

  ${(props) => props.disabled && css`
    cursor: default;
    background: ${props.theme.colors.white};
    color: ${props.theme.colors.silver};
  `}

  ${(props) => props.error && css`
    background: #fff7f5;
  `}

  // https://github.com/klarna/ui/blob/v4.10.0/Field/styles.scss#L228-L241
  &:-webkit-autofill {
    animation-name: ${style.animationAutoFillStart};
  }

  &:not(:-webkit-autofill) {
    animation-name: ${style.animationAutoFillCancel};
  }
`;

style.Tooltip = styled.div`
  position: absolute;
  z-index: 5;
  bottom: 100%;
  max-width: 100%;
  margin-bottom: 8px;
  padding: 6px 9px;
  background-color: ${(props) => props.theme.colors.brightOrange};
  border-radius: 2px;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin: -1px 0 0 -6px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid ${(props) => props.theme.colors.brightOrange};
  }
`;

style.TooltipContainer = styled.div`
  text-align: center;
  font-size: 11px;
  font-family: 'Roboto-Medium';
  color: ${(props) => props.theme.colors.white};
`;

export default style;
