import styled, { css } from 'styled-components';

interface Props {
  showExtendSettings: boolean;
}

const style: any = {};

style.Settings = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex: 1;
`;

style.Dropdown = styled.div`
  width: 288px;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

style.BaseSection = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-bottom: none;
  padding: 16px 16px 16px 16px;

  ${(props: Props) => props.showExtendSettings && css`
    border-bottom: 1px solid #e3e3e3;
  `}
`;

style.SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

style.ExtendSection = styled.div`
  padding: 16px 16px 16px 16px;
  flex-direction: row;
  display: flex;
  flex: 1;
`;

export default style;
