import React from 'react';

import {
  TextInput,
  TextInputProps,
} from '@components/textInput/index';

import { SearchIcon } from '@components/searchField/icon/search-icon';

import s from './searchField.style';

interface SearchProps extends TextInputProps {
  placeholder: string;
  disabled?: boolean;
}

interface SearchState {
  active: boolean;
}

export class SearchField extends React.PureComponent<SearchProps, SearchState> {

  static defaultProps = {
    disabled: false,
  };

  state = {
    active: false,
  };

  rootRef = React.createRef();

  componentDidMount() {
    const el: any = this.rootRef.current;
    el.children[1].focus();
  }

  private handleFocus = () => {
    this.setState(() => ({
      active: true,
    }));
  };

  private handleBlur = () => {
    this.setState(() => ({
      active: false,
    }));
  };

  render() {
    return (
      <s.Root
        ref={this.rootRef}
        isActive={this.state.active}
      >
        <s.IconWrapper
          disabled={this.props.disabled}
        >
          <SearchIcon />
        </s.IconWrapper>
        <TextInput
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...this.props}
        />
      </s.Root>
    );
  }

}

export default SearchField;
