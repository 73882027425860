import { connect } from 'react-redux';

import { HeaderNotification } from '@components/header/notification/notification';

export const mapStateToProps = (state) => {
  const { licenseEndTitle, role } = state.currentUser;

  const props = {
    role,
    licenseEndTitle,
  };

  return props;
};

export const HeaderNotificationConnect = connect(
  mapStateToProps,
)(HeaderNotification);

export default HeaderNotificationConnect;
