import { connect } from 'react-redux';

import { logoutThunk } from '../redux/logout.thunk';

import { SettingsMenu } from './settingsMenu';

const mapDispatchToProps = (dispatch) => ({
  onLogout() {
    logoutThunk(dispatch);
  },
});

export const SettingsMenuConnect = connect(
  null,
  mapDispatchToProps,
)(SettingsMenu);

export default SettingsMenuConnect;
