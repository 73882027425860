import { connect } from 'react-redux';

import { processThunk } from '@layouts/bookmarks/redux/process.thunk';

import { Header } from './header';

const mapStateToProps = (state) => ({
  disable: state.bookmarks.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onDownload() {
    processThunk(dispatch);
  },
});

export const HeaderConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

export default HeaderConnect;
