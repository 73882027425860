import { animateTopScroll } from '@components/scrollTo/animateScroll';

export class ScrollTo {

  rootNode: HTMLElement = null;

  targetNode: HTMLElement = null;

  static HIGHLIGHTED_CLASS_NAME = 'law-highlighted';

  static HIGHLIGHTED_TIMEOUT = 2500;

  static SCROLL_DURATION = 300;

  public setRootNode(id: string) {
    this.rootNode = document.getElementById(id);
  }

  public setTargetNode(id: string) {
    this.targetNode = document.querySelector(`[data-block-id="${id}"]`);
  }

  public execute() {
    this.scrollTo();
    this.highlight();
  }

  private scrollTo() {
    const documentHeight = Math.ceil(this.rootNode.getBoundingClientRect().height);
    const elementHeight = Math.ceil(this.targetNode.getBoundingClientRect().height);
    const documentHalfHeight = documentHeight / 2;
    const elementHalfHeight = elementHeight / 2;

    let targetOffsetTop = this.targetNode.offsetTop + elementHalfHeight - documentHalfHeight;

    if (elementHeight >= documentHeight) {
      targetOffsetTop = this.targetNode.offsetTop;
    }

    const options = {
      duration: 300,
      targetElement: this.rootNode,
    };

    animateTopScroll(targetOffsetTop, options);
  }

  private highlight() {
    this.targetNode.classList.add(ScrollTo.HIGHLIGHTED_CLASS_NAME);

    setTimeout(() => {
      this.targetNode.classList.remove(ScrollTo.HIGHLIGHTED_CLASS_NAME);
    }, ScrollTo.HIGHLIGHTED_TIMEOUT + ScrollTo.SCROLL_DURATION);
  }

}

export default ScrollTo;
