/* eslint-disable */
import React from 'react';

export const MarkerRed = () => (
  <svg width="80px" height="44px" viewBox="0 0 56 44" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="97.3664093%" id="linearGradient-1r">
        <stop stopColor="#22313F" offset="0%" />
        <stop stopColor="#415466" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0.346011715%" x2="50%" y2="100%" id="linearGradient-2r">
        <stop stopColor="#A83261" offset="0%" />
        <stop stopColor="#FF73AA" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0.346011715%" x2="50%" y2="100%" id="linearGradient-3r">
        <stop stopColor="#A83261" offset="0%" />
        <stop stopColor="#FF73AA" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="97.3664093%" id="linearGradient-4r">
        <stop stopColor="#19242E" offset="0%" />
        <stop stopColor="#364654" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" fill="none">
      <g transform="translate(108, 0) rotate(180)">
        <g transform="translate(53, -44) rotate(0)">
          <path d="M24,2.00492334 C24,0.897634756 23.1132936,0 22.0018986,0 L17.9981014,0 C16.8945804,0 16,0.898855112 16,2.00492334 L16,41.9950767 C16,43.1023652 16.8867064,44 17.9981014,44 L22.0018986,44 C23.1054196,44 24,43.1011449 24,41.9950767 C24,41.9950767 24,42 28,42 C36.5,28 44,28 44,28 L44,16 C44,16 36.5,16 28,2 C24,2 24,2.00492334 24,2.00492334 Z" id="Head-1" fill="url(#linearGradient-1r)" />
          <polygon id="Pin" fill="url(#linearGradient-2r)" transform="translate(50.000000, 22.000000) scale(-1, 1) translate(-50.000000, -22.000000) " points="56 27 50 27 44 21 44 17 56 17" />
          <polygon id="Body" fill="url(#linearGradient-3r)" transform="translate(8.000000, 22.000000) scale(-1, 1) translate(-8.000000, -22.000000) " points="0 2 40 2 40 42 0 42" />
          <rect id="Rectangle" fill="url(#linearGradient-4r)" x="24" y="2" width="1" height="40" />
        </g>
      </g>
    </g>
  </svg>
);

export default MarkerRed;
