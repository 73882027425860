import { createSummaryReduce } from './createSummary.reduce';

import type { ActionData } from './createSummary.reduce';

const actionType = 'SUMMARY/CREATE_SUMMARY';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const createSummary = {
  actionType,
  createAction,
  reduce: createSummaryReduce,
};

export default createSummary;
