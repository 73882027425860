/* eslint-disable */
import React from 'react';

import s from './tooltipRelationsTitle.style';

export const TooltipRelationsTitle = (props = { link: null, title: '', number: '', is_actual, onClick: () => {}, }) => (
  <s.Root>
    <s.AnchorContainer
      showNumber={!!props.number}
    >
      <s.AnchorNumber
        onClick={props.onClick}
        href={props.link}
        target="_blank"
      >
        {props.number}
      </s.AnchorNumber>
      <s.Actual
        isActual={props.is_actual}
      >
        Утратил силу
      </s.Actual>
    </s.AnchorContainer>
    <s.AnchorTitle
      onClick={props.onClick}
      href={props.link}
      target="_blank"
    >
      {props.title}
    </s.AnchorTitle>
  </s.Root>
);

export default TooltipRelationsTitle;
