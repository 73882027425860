import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
  isFirst: boolean;
}

style.List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 8px 16px;
  background: white;
`;

style.Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${(props: Props) => props.isFirst && css`
    border: none;
  `}
`;

style.TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: white;
`;

style.Actions = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  height: 48px;
`;

export default style;
