import React from 'react';

import s from './item.style';

import { DataFromView } from './item.connect';

export interface Props {
  item: {
    id: string,
    title: string,
  };
  columns: any;
  onAddBlock(data: DataFromView): void;
}

export class Item extends React.PureComponent<Props> {

  handleCellClick = (columnId) => {
    const data = {
      columnId,
      block: this.props.item,
    };

    this.props.onAddBlock(data);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderShowMore() {
    return (
      <s.ShowMore>
        Показать еще
      </s.ShowMore>
    );
  }

  renderColumnInGroup() {
    return this.props.columns.map((column) => {
      const isTitle = column.id === 'title';
      if (isTitle) {
        return (
          <s.ColumnTitle key={column.id} />
        );
      }

      return (
        <s.ColumnItem key={column.id} />
      );
    });
  }

  renderItems() {
    return this.props.columns.map((column) => {
      const isTitle = column.id === 'title';
      if (isTitle) {
        return (
          <s.CellTitle
            key={column.id}
          >
            <s.ColumnWidthTitle>
              <s.CellContainer>
                <s.Title>
                  <s.BodyText
                    isTitle
                  >
                    {column.title}
                  </s.BodyText>
                </s.Title>
              </s.CellContainer>
            </s.ColumnWidthTitle>
          </s.CellTitle>
        );
      }

      const isEmpty = column.title === '0' || column.title === '0%';
      const itemClick = (e) => {
        e.stopPropagation();

        if (isEmpty) {
          return;
        }

        this.handleCellClick(column.id);
      };

      return (
        <s.CellItem
          key={column.id}
          isEmpty={isEmpty}
        >
          <s.ColumnWidthItem>
            <s.CellContainer>
              <s.BodyText
                textAlign="end"
                isEmpty={isEmpty}
                onClick={itemClick}
              >
                {column.title}
              </s.BodyText>
            </s.CellContainer>
          </s.ColumnWidthItem>
        </s.CellItem>
      );
    });
  }

  render() {
    return (
      <s.Table>
        <s.Group>
          {this.renderColumnInGroup()}
        </s.Group>
        <s.Row>
          {this.renderItems()}
        </s.Row>
      </s.Table>
    );
  }

}

export default Item;
