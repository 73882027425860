import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  hasDate: boolean;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

style.Container = styled.div`
  display: flex;
  flex-direction: row;
`;

style.Title = styled.div`
  text-transform: uppercase;
  font: 11px Roboto-Medium;
  color: ${(props) => props.theme.colors.silver};
  margin-top: 28px;
  margin-bottom: 24px;
`;

style.Item = styled.div`
  position: relative;
  border-left: 2px solid ${(props) => props.theme.colors.borderColor};
  padding-top: 10px;
  padding-left: 23px;
  padding-bottom: 14px;

  ::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 10px;
    left: -9px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.borderColor};
    border: 4px solid ${(props) => props.theme.colors.white};
  }

  ::after {
      display: none;
  }

  ${(props: Props) => !props.hasDate && css`
    ::after {
      display: block;
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      top: 16px;
      left: -3px;
      border-radius: 50%;
      background-color: ${props.theme.colors.white};
    }
  `}
`;

style.Date = styled.div`
  width: 103px;
  padding-top: 12px;
  padding-right: 23px;
  flex-shrink: 0;
  overflow: hidden;

  font: 11px Roboto-Bold;
  text-transform: uppercase;
  text-align: right;
  color: ${(props) => props.theme.colors.defaultText};

  ${(props: Props) => !props.hasDate && css`
    color: ${props.theme.colors.silver};
  `}
`;

style.Name = styled.div`
  font: 13px Roboto-Medium;
  color: ${(props) => props.theme.colors.defaultText};
  margin-bottom: 6px;
`;

style.Description = styled.div`
  font: 13px Roboto-Regular;
  color: ${(props) => props.theme.colors.stone};
  margin-bottom: 8px;
`;

style.Files = styled.div`
  font: 13px Roboto-Regular;
  color: ${(props) => props.theme.colors.blue};
`;

export default style;
