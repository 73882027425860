import React from 'react';

import { theme } from '@components/styled';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { Id } from '../workspace/redux/state';

import { ShareMenu } from './shareMenu';

import s from './share.style';

interface ShareProps {
  id: Id;
  shouldRender: boolean;
}

export class Share extends React.PureComponent<ShareProps> {

  state = {
    showMenu: false,
  };

  handleIconClick = () => {
    this.setState({
      showMenu: true,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      showMenu: false,
    });
  };

  render() {
    if (!this.props.shouldRender) {
      return null;
    }

    if (this.state.showMenu) {
      return (
        <ShareMenu
          id={this.props.id}
          onClose={this.handleCloseMenu}
        />
      );
    }

    return (
      <s.Root>
        <s.Icon
          onClick={this.handleIconClick}
        >
          <Icon
            path={iconsPath.get('users')}
            color={theme.colors.stone}
          />
        </s.Icon>
      </s.Root>
    );
  }

}

export default Share;
