import { updatePackageDescriptionReduce } from './updatePackageDescription.reduce';

import type { ActionData } from './updatePackageDescription.reduce';

const actionType = 'PACKAGE/UPDATE_DESCRIPTION';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updatePackageDescription = {
  actionType,
  createAction,
  reduce: updatePackageDescriptionReduce,
};

export default updatePackageDescription;
