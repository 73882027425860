import React from 'react';

import { Item } from './item';

import s from '../items.style';

interface ItemProps {
  id: string;
  role: string;
  title: string;
  active: boolean;
}

interface ItemsProps {
  items: ItemProps[];
  title?: string;
  onSelectItem(id: string): void;
}

export class Items extends React.PureComponent<ItemsProps> {

  renderItems() {
    if (!this.props.items.length) {
      return null;
    }

    return (
      <>
        {this.renderTitle()}
        {this.props.items.map(this.renderItem)}
      </>
    );
  }

  renderTitle() {
    if (!this.props.title) {
      return null;
    }

    return <s.Title>{this.props.title}</s.Title>;
  }

  renderItem = (item) => <Item onSelectItem={this.props.onSelectItem} {...item} />;

  render() {
    return (
      <s.Root>
        {this.renderItems()}
      </s.Root>
    );
  }

}

export default Items;
