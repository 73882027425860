import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { prepareFiltersFailReduce } from './prepareFiltersFail.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/FILTERS/PREPARE_FAIL`;

const createAction = () => ({
  type: actionType,
});

export const prepareFiltersFail = {
  actionType,
  createAction,
  reduce: prepareFiltersFailReduce,
};

export default prepareFiltersFail;
