import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { fetchUsersThunk } from './fetchUsers.thunk';

import { httpInviteUsers } from './inviteUsers.http';
import { resetToDefault } from './resetToDefault';

import { Id } from './state';

interface Params {
  folderId: Id;
  emails: string;
  role: string;
}

export class InviteUsersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    this.dispatch(resetToDefault.createAction());

    try {
      const result = await httpInviteUsers(params);
      this.sendNotification(result.emails);
      await fetchUsersThunk(this.dispatch, { folderId: params.folderId });
    } catch (error) {
      this.sendErrorNotification();
      throw Error(error.status);
    }
  }

  private sendNotification(emails: Record<string, boolean>) {
    const errorMails = [];

    Object.values(emails).forEach((item, index) => {
      if (!item) {
        errorMails.push(Object.keys(emails)[index]);
      }
    });

    if (!errorMails.length) {
      this.sendNotificationSuccess();

      return;
    }

    const nothingSend = errorMails.length === Object.keys(emails).length;
    if (nothingSend) {
      this.sendErrorNotification();

      return;
    }

    const id = 'REGULATION_INVITE_PART_ERROR';
    const message = `Во время отправки приглашений ${errorMails} произошла ошибка`;
    notifierManager.fail({ id, message });
    setTimeout(() => {
      notifierManager.remove({ id });
    }, 10000);

  }

  private sendNotificationSuccess() {
    const id = 'REGULATION_INVITE_SUCCESS';
    const message = 'Приглашения отправлены';
    notifierManager.done({ id, message });
    setTimeout(() => {
      notifierManager.remove({ id });
    }, 5000);

    return null;
  }

  private sendErrorNotification() {
    const id = 'REGULATION_INVITE_ERROR';
    const message = 'Во время отправки приглашений произошла ошибка';
    notifierManager.fail({ id, message });
    setTimeout(() => {
      notifierManager.remove({ id });
    }, 10000);

    return null;
  }

}

export function inviteUsersThunk(dispatch, params) {
  const thunk = new InviteUsersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default InviteUsersThunk;
