import React from 'react';

import { HeaderCassetteConnect } from './headerCassette.connect';
import { LicenseCassetteConnect } from './licenseCassette.connect';
import { UserCassetteConnect } from './userCassette.connect';

import s from './profileTab.style';

export class ProfileTab extends React.PureComponent {

  render() {
    return (
      <s.Root>
        <HeaderCassetteConnect />
        <LicenseCassetteConnect />
        <s.Separator />
        <UserCassetteConnect />
      </s.Root>
    );
  }

}

export default ProfileTab;
