import {
  processCompleteReduce,
  ActionData,
} from './processComplete.reduce';

export const actionType = 'DOWNLOADS/COMPLETE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const processComplete = {
  actionType,
  createAction,
  reduce: processCompleteReduce,
};
