import { Dispatch } from 'redux';

import { selectHistoryItem } from '@pages/search/history/redux/selectHistoryItem';

import { toggleLayoutType } from '../../../../header/redux/toggleLayoutType';

export class CancelThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public invoke() {
    const { id } = this.getState().search.history.data[0];
    this.dispatch(toggleLayoutType.createAction());
    this.dispatch(selectHistoryItem.createAction({ id }));
  }

}

export function cancelThunk(dispatch) {
  const thunk = new CancelThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}
