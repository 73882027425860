import { connect } from 'react-redux';

import { SelectionRule } from '../redux/state';

import { List } from './list';

export enum CheckStatus {
  check = 'check',
  half = 'half',
  uncheck = 'uncheck',
}

export type TotalCheckStatus = CheckStatus.check | CheckStatus.half | CheckStatus.uncheck;

export function totalCheckStatus(selectionState, documentsCount): TotalCheckStatus {
  const isInclude = selectionState.rule === SelectionRule.include;

  const count = documentsCount > 10000 ? 1000 : documentsCount;
  const selectionCount = selectionState.ids.count();

  const isAllSelected = count === selectionCount;
  const isHalfChecked = count !== selectionCount;

  if (isInclude && !selectionCount) {
    return CheckStatus.uncheck;
  }

  if (isInclude && isAllSelected) {
    return CheckStatus.check;
  }

  if (isInclude && isHalfChecked) {
    return CheckStatus.half;
  }

  if (!isInclude && isAllSelected) {
    return CheckStatus.uncheck;
  }

  if (!isInclude && !selectionCount) {
    return CheckStatus.check;
  }

  return CheckStatus.half;

}

const mapStateToProps = (state) => {
  const {
    currentPageNumber,
    pages,
    selection,
    totalDocuments,
  } = state.searchResult.documents;

  const hasPage = pages[currentPageNumber];

  if (!hasPage) {
    return {
      items: [],
    };
  }

  const items = pages[currentPageNumber].items;

  return {
    items,
    totalCheckStatus: totalCheckStatus(selection, totalDocuments),
  };

};

export const ListConnect = connect(
  mapStateToProps,
  // @ts-ignore
)(List);

export default ListConnect;
