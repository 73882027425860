import { resetRelationsReduce } from './resetRelations.reduce';

const actionType = 'DOCUMENT/RESET_RELATIONS';

const createAction = () => ({
  type: actionType,
});

export const resetRelations = {
  actionType,
  createAction,
  reduce: resetRelationsReduce,
};

export default resetRelations;
