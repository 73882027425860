import { addProjectReduce } from './addProject.reduce';

import type { ActionData } from './addProject.reduce';

const actionType = 'REGULATION/SEARCH_RESULT/ADD_PROJECT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addProject = {
  actionType,
  createAction,
  reduce: addProjectReduce,
};

export default addProject;
