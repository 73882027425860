import React, {
  useRef,
  useEffect,
} from 'react';

import { removeSpacialCharactersFromValue } from '@utils/utils';

import s from './input.style';

export type Value = string;

interface InputProps {
  textContent: string
  onChange: (value: Value) => void
}

export const Input = ({ textContent, onChange }: InputProps) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.setSelectionRange(0, textContent.length);
  }, []);

  const onChangeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    let value = removeSpacialCharactersFromValue(e.target.value);
    value = value.slice(0, 80);

    onChange(value);
  };

  return (
    <s.Input
      ref={inputRef}
      value={textContent}
      onChange={onChangeContent}
    />
  );
};

export default Input;
