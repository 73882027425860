import { resetAttributesReduce } from './resetAttributes.reduce';

const actionType = 'DOCUMENT/RESET_DOCUMENT';

const createAction = () => ({
  type: actionType,
});

export const resetAttributes = {
  actionType,
  createAction,
  reduce: resetAttributesReduce,
};

export default resetAttributes;
