import {
  Id,
  DocumentId,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

export interface RequestParams {
  id: Id;
  source: string;
  documentId: DocumentId;
}

export function httpGetSharedLink(requestParams: RequestParams) {
  const request = http.post({
    url: RESTPath.document.getSharedLink(requestParams),
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpGetSharedLink;
