import React from 'react';

import s from './header.style';

interface HeaderProps {
  title: string;
  children: JSX.Element[] | JSX.Element;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.Root>
        <s.Title>{this.props.title}</s.Title>
        <s.Actions>
          {this.props.children}
        </s.Actions>
      </s.Root>
    );
  }

}

export default Header;
