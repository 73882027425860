import { fetchUnderControlCounterReduce } from './fetchUnderControlCounter.reduce';

import type { ActionData } from './fetchUnderControlCounter.reduce';

const actionType = 'REGULATION/FETCH_UNDER_CONTROL_COUNTER';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchUnderControlCounter = {
  actionType,
  createAction,
  reduce: fetchUnderControlCounterReduce,
};

export default fetchUnderControlCounter;
