import { Map } from 'immutable';

const prepareCourt = (court) => ({
  id: court.id,
  title: court.name,
  appealState: court.appeal_state,
  caseResult: court.case_result,
  judges: {
    shouldLoadJudges: true,
    items: [],
    itemsMap: Map(),
  },
});

const prepareCourts = (fetchCourts) => fetchCourts.reduce((accumulator, currentValue) => {
  accumulator.items = accumulator.items.concat(currentValue.id);
  accumulator.itemsMap = accumulator.itemsMap.set(currentValue.id, prepareCourt(currentValue));

  return accumulator;
}, {
  items: [],
  itemsMap: Map(),
});

export interface ActionData {
  searchId: string | number;
  page: number;
  per_page: number;
  total_pages: number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchCourtsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const currentPageNumber = Number(action.data.page);
  const totalPages = action.data.total_pages;

  const courts = {
    currentPageNumber,
    totalPages,
  };

  const fetchedCourts = action.data.result;

  const resetLoadStatus = {
    initialLoading: false,
    pageLoading: false,
    shouldLoadCourts: false,
  };

  return {
    ...state,
    id: action.data.searchId,
    courts: {
      ...state.courts,
      ...resetLoadStatus,
      ...courts,
      pages: {
        ...state.courts.pages,
        [action.data.page]: prepareCourts(fetchedCourts),
      },
    },
  };
};

export default fetchCourtsReduce;
