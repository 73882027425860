import { Dispatch } from 'redux';
import { fetchSettings } from './fetchSettings';

import { httpFetchSearchResultDocumentsSettings } from './fetchSettings.http';

export class FetchSearchResultDocumentsSettingsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
    dispatch((_, getState) => { this.getState = getState; });
  }

  public invoke() {
    const { shouldLoadSettings } = this.getState().searchResult.documents;
    if (!shouldLoadSettings) {
      return;
    }

    this.fetchSettings();
  }

  private async fetchSettings() {
    try {
      const searchResult = await httpFetchSearchResultDocumentsSettings();
      this.dispatchGetDocumentsSettingsSucceed(searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetDocumentsSettingsSucceed(searchResult) {
    const actionData = {
      result: searchResult,
    };

    this.dispatch(fetchSettings.createAction(actionData));
  }

}

export default FetchSearchResultDocumentsSettingsThunk;
