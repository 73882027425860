import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateCaseType } from './redux/updateCaseType';

import { CaseTypeBlock } from './block';

const mapStateToProps = (state) => {
  const { caseType } = state.search.presets;

  return {
    caseTypes: caseType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateCaseType(data) {
    dispatch(updateCaseType.createAction(data));
  },
});

export const CaseTypeBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaseTypeBlock);

export default CaseTypeBlockConnect;
