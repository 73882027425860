import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateCourtType } from './redux/updateCourtType';

import { CourtTypeBlock } from './block';

const mapStateToProps = (state) => {
  const { courtType } = state.search.presets;

  return {
    courtTypes: courtType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateCourtType(data) {
    dispatch(updateCourtType.createAction(data));
  },
});

export const CourtTypeBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourtTypeBlock);

export default CourtTypeBlockConnect;
