import { buildHistoryItem } from '@utils/utils';

import { Source } from '../redux/state';

function buildForSearch(breadcrumbs, document) {
  const {
    attributes,
    sourceId,
    isChronologyDocument,
    isSimilarDocument,
    parentDocumentId,
    parentDocumentTitle,
  } = document;

  breadcrumbs.push(buildHistoryItem(1, 'Поиск', false, `#/search/${sourceId}`));
  breadcrumbs.push(buildHistoryItem(2, 'Результаты поиска', false, `#/search/${sourceId}/documents`));

  const isDeepDocument = isChronologyDocument || isSimilarDocument;
  const isParentExist = parentDocumentId && parentDocumentTitle;
  if (isDeepDocument && !isParentExist) {
    breadcrumbs.push(buildHistoryItem(3, null, true));
  }

  if (isDeepDocument && isParentExist) {
    const parentRoute = `#/search/${sourceId}/documents/${parentDocumentId}`;
    breadcrumbs.push(buildHistoryItem(3, parentDocumentTitle, false, parentRoute));
  }

  const isDocumentExist = !!document.attributes.data.title && !!document.attributes.data.attributes.date;
  if (!isDocumentExist) {
    breadcrumbs.push(buildHistoryItem(4, null, true));
  } else {
    const currentTitle = `${attributes.data.title} от ${attributes.data.attributes.date}`;
    breadcrumbs.push(buildHistoryItem(4, currentTitle, false));
  }

  return breadcrumbs;
}

function buildForPackage(breadcrumbs, document) {
  const {
    sourceTitle,
    attributes,
    sourceId,
    isChronologyDocument,
    isSimilarDocument,
    parentDocumentId,
    parentDocumentTitle,
  } = document;

  breadcrumbs.push(buildHistoryItem(1, 'Мои подборки', false, '#/packages'));

  const breadcrumbsExist = !!sourceTitle && !!sourceId;
  if (!breadcrumbsExist) {
    breadcrumbs.push(buildHistoryItem(2, null, true));
  } else {
    breadcrumbs.push(buildHistoryItem(2, sourceTitle, false, `#/packages/${sourceId}`));
  }

  const isDeepDocument = isChronologyDocument || isSimilarDocument;
  const isParentExist = parentDocumentId && parentDocumentTitle;
  if (isDeepDocument && !isParentExist) {
    breadcrumbs.push(buildHistoryItem(3, null, true));
  }

  if (isDeepDocument && isParentExist) {
    const parentRoute = `#/package/${sourceId}/documents/${parentDocumentId}`;
    breadcrumbs.push(buildHistoryItem(3, parentDocumentTitle, false, parentRoute));
  }

  const isDocumentExist = !!document.attributes.data.title && !!document.attributes.data.attributes.date;
  if (!isDocumentExist) {
    breadcrumbs.push(buildHistoryItem(4, null, true));
  } else {
    const currentTitle = `${attributes.data.title} от ${attributes.data.attributes.date}`;
    breadcrumbs.push(buildHistoryItem(4, currentTitle, false));
  }

  return breadcrumbs;
}

function buildForAuthorPackage(breadcrumbs, document) {
  const {
    sourceTitle,
    attributes,
    sourceId,
    isChronologyDocument,
    isSimilarDocument,
    parentDocumentId,
    parentDocumentTitle,
  } = document;

  breadcrumbs.push(buildHistoryItem(1, 'Авторские подборки', false, '#/authorpackages'));

  const breadcrumbsExist = !!sourceTitle && !!sourceId;
  if (!breadcrumbsExist) {
    breadcrumbs.push(buildHistoryItem(2, null, true));
  } else {
    breadcrumbs.push(buildHistoryItem(2, sourceTitle, false, `#/authorpackages/${sourceId}`));
  }

  const isDeepDocument = isChronologyDocument || isSimilarDocument;
  const isParentExist = parentDocumentId && parentDocumentTitle;
  if (isDeepDocument && !isParentExist) {
    breadcrumbs.push(buildHistoryItem(3, null, true));
  }

  if (isDeepDocument && isParentExist) {
    const parentRoute = `#/authorpackages/${sourceId}/documents/${parentDocumentId}`;
    breadcrumbs.push(buildHistoryItem(3, parentDocumentTitle, false, parentRoute));
  }

  const isDocumentExist = !!document.attributes.data.title && !!document.attributes.data.attributes.date;
  if (!isDocumentExist) {
    breadcrumbs.push(buildHistoryItem(4, null, true));
  } else {
    const currentTitle = `${attributes.data.title} от ${attributes.data.attributes.date}`;
    breadcrumbs.push(buildHistoryItem(4, currentTitle, false));
  }

  return breadcrumbs;
}

function buildForSubscription(breadcrumbs, document) {
  const {
    sourceTitle,
    attributes,
    sourceId,
    isChronologyDocument,
    isSimilarDocument,
    parentDocumentId,
    parentDocumentTitle,
  } = document;

  breadcrumbs.push(buildHistoryItem(1, 'Подписки', false, '#/subscriptions'));

  const breadcrumbExist = !!sourceTitle && !!sourceId;
  if (!breadcrumbExist) {
    breadcrumbs.push(buildHistoryItem(2, null, true));
  } else {
    breadcrumbs.push(buildHistoryItem(2, sourceTitle, false, `#/subscriptions/${sourceId}`));
  }

  const isDeepDocument = isChronologyDocument || isSimilarDocument;
  const isParentExist = parentDocumentId && parentDocumentTitle;
  if (isDeepDocument && !isParentExist) {
    breadcrumbs.push(buildHistoryItem(3, null, true));
  }

  if (isDeepDocument && isParentExist) {
    const parentRoute = `#/subscription/${sourceId}/documents/${parentDocumentId}`;
    breadcrumbs.push(buildHistoryItem(3, parentDocumentTitle, false, parentRoute));
  }

  const isDocumentExist = !!document.attributes.data.title && !!document.attributes.data.attributes.date;
  if (!isDocumentExist) {
    breadcrumbs.push(buildHistoryItem(4, null, true));
  } else {
    const currentTitle = `${attributes.data.title} от ${attributes.data.attributes.date}`;
    breadcrumbs.push(buildHistoryItem(4, currentTitle, false));
  }

  return breadcrumbs;
}

export function buildBreadcrumbs(params: { document: any, chronologyDocumentId?: number }) {
  const breadcrumbs = [];

  if (params.document.source === Source.search) {
    return buildForSearch(breadcrumbs, params.document);
  }

  if (params.document.source === Source.package) {
    return buildForPackage(breadcrumbs, params.document);
  }

  if (params.document.source === Source.authorPackage) {
    return buildForAuthorPackage(breadcrumbs, params.document);
  }

  return buildForSubscription(breadcrumbs, params.document);
}

export default buildBreadcrumbs;
