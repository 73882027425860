import styled, { css } from 'styled-components';

interface Props {
  theme: Record<any, any>;
  isAvatarHeightDominant: boolean;
  isLogoHeightDominant: boolean;
}

const style: any = {};

style.Root = styled.div`
  min-height: 128px;
  margin-bottom: 15px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props: Props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Photo = styled.div`
  width: 96px;
  height: 96px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;

  > img {
    height: 100%;
    width: auto;

    ${(props: Props) => props.isAvatarHeightDominant && css`
      height: auto;
      width: 100%;
    `}
  }
`;

style.AvatarIcon = styled.div`
  width: 96px;
  height: 96px;
  background: #f3f4f5;
`;

style.Credentials = styled.div`
  flex-grow: 1;
  padding: 0 32px 0 16px;
`;

style.Author = styled.div`
  font: 17px Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.defaultText};
  overflow-wrap: anywhere;
  line-height: 18px;
`;

style.Position = styled.div`
  font: 13px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.stone};
  line-height: 18px;
`;

style.Logo = styled.div`
  height: 96px;
  width: 208px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > img {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    object-fit: contain;

    ${(props: Props) => props.isLogoHeightDominant && css`
      height: 100%;
      width: auto;
    `}
  }
`;

style.EmptyLogo = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;

  font: 11px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.stone};

  user-select: none;

  background: #f3f4f5;
`;

export default style;
