import baseStyled, { ThemedStyledInterface } from 'styled-components';

export { css } from 'styled-components';

export const theme = {
  colors: {
    black: '#000000',
    white: '#ffffff',
    defaultText: '#22313f',
    lightBlue: '#f6f9fd',
    green: '#59b671',
    blue: '#4183d7',
    orange: '#ffbe25',
    brightOrange: '#ff6400',
    red: '#e74c3c',
    pressed: '#f0f6fc',
    polar: 'rgba(65, 131, 215, 0.07)',
    stone: '#6c7b89',
    grey: '#979797',
    silver: '#a6b0ba',
    deleteButtonGray: '#c8cccf',
    background: '#f3f3f3',
    backgroundGrey: '#f9f9f9',
    globalLines: '#e3e3e3',
    borderColor: '#dfe2e6',
    disabled: '#dee2e6',
    disabledText: '#919CA7',
    defaultButton: '#f3f4f5',
    transparent: 'transparent',
  },
};

export type Theme = typeof theme;

export const styled = baseStyled as ThemedStyledInterface<Theme>;
