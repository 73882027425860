import { StateBuilder } from './state';

export const resetNotificationsReduce = (state) => {
  if (!state) {
    return state;
  }

  return StateBuilder.createState();
};

export default resetNotificationsReduce;
