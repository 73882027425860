import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateValue } from './redux/updateValue';
import { updateFindIn } from './redux/updateFindIn';
import { updateMode } from './redux/updateMode';
import { updateProximityRange } from './redux/updateProximityRange';

import { TextBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateValue(data) {
    dispatch(updateValue.createAction(data));
  },
  onUpdateMode(data) {
    dispatch(updateMode.createAction(data));
  },
  onUpdateFindIn(data) {
    dispatch(updateFindIn.createAction(data));
  },
  onUpdateProximityRange(data) {
    dispatch(updateProximityRange.createAction(data));
  },
});

export const TextBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextBlock);

export default TextBlockConnect;
