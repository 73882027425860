import { connect } from 'react-redux';

import { companyPackageThunk } from '../../redux/packages/companyPackage.thunk';

import { Menu } from './menu';

function getInformation(itemsMap, id) {
  const props = {
    disable: true,
    title: 'Сделать подборку командной',
  };

  const item = itemsMap.get(id);
  const isAuthor = item.type === 'author';
  const isOwner = item.isOwner;
  const disable = isAuthor || !isOwner;

  props.disable = disable;

  const isCompany = item.type === 'company';

  if (isCompany) {
    props.title = 'Сделать подборку личной';
  }

  return props;
}

const mapStateToProps = (state, ownProps) => ({
  ...getInformation(state.packages.packages.itemsMap, ownProps.id),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick() {
    const { id } = ownProps;

    companyPackageThunk(dispatch, { id });
  },
});

export const MenuConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);

export default Menu;
