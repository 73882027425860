import React from 'react';

import { RoutePath } from '@router/path';

import { UnauthRouteConnect } from '@router/unauthRoute.connect';

import { OutDateLink } from './outDateLink';

export const RouteOutDatePasswordResetLink = (
  <UnauthRouteConnect
    exact
    path={RoutePath.outDatePasswordResetLink}
    component={OutDateLink}
  />
);

export default RouteOutDatePasswordResetLink;
