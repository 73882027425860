import { Dispatch } from 'redux';
import { fetchSettings } from './fetchSettings';

import { httpFetchSearchResultCourtsSettings } from './fetchSettings.http';

export class FetchSearchResultCourtsSettingsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
    dispatch((_, getState) => { this.getState = getState; });
  }

  public invoke() {
    const { shouldLoadSettings } = this.getState().searchResult.courts;
    if (!shouldLoadSettings) {
      return;
    }

    this.fetchSettings();
  }

  private async fetchSettings() {
    try {
      const searchResult = await httpFetchSearchResultCourtsSettings();
      this.dispatchGetSettingsSucceed(searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetSettingsSucceed(searchResult) {
    const actionData = {
      result: searchResult,
    };

    this.dispatch(fetchSettings.createAction(actionData));
  }

}

export default FetchSearchResultCourtsSettingsThunk;
