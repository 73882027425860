import React from 'react';

import { Empty } from '../components/empty';
import { HeaderConnect } from '../components/header.connect';

import { ListConnect } from './list.connect';

import { PaginationConnect } from './pagination.connect';

import s from './workspace.style';

type WorkspaceProps = {
  noItems: boolean;
};

export class Workspace extends React.PureComponent<WorkspaceProps> {

  contentRef: React.RefObject<HTMLDivElement> = React.createRef();

  onAfterPagination = () => {
    this.contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  renderEmpty() {
    return <Empty />;
  }

  render() {
    if (this.props.noItems) {
      return this.renderEmpty();
    }

    const paginationProps = {
      onAfterPagination: this.onAfterPagination,
    };

    return (
      <s.Root>
        <s.ContentContainer
          ref={this.contentRef}
        >
          <HeaderConnect />
          <ListConnect />
          <PaginationConnect {...paginationProps} />
        </s.ContentContainer>
      </s.Root>
    );
  }

}

export default Workspace;
