import { StateBuilder } from './state';

export interface ActionData {
  id: number;
  description: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updatePackageDescriptionReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const params = {
    id: action.data.id,
    description: action.data.description,
  };

  const newState = new StateBuilder(state.packages);
  newState.updatePackageDescription(params);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default updatePackageDescriptionReduce;
