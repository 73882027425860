import React from 'react';

import { RequisitesCopy } from '@pages/document/components/requisitesCopy/requisitesCopy';
import {
  Download,
  Item,
} from '@pages/searchResult/documents/components/settings/download';

import { MenuItem } from '@pages/searchResult/documents/components/settings/menuItem';

import s from '@pages/searchResult/documents/components/settings/settingsMenu.style';

interface SettingsMenuProps {
  id: number;
  onLevelChange: (params: { wide: boolean; level: number }) => void;
  source: string;
  isReal: boolean;
  sourceId: number;
  requisites: string;
  downloadItems: Item[];
  closePopover: () => void;
}

enum ViewTypes {
  requisites = 'REQUISITES',
  download = 'DOWNLOAD',
  default = '',
}

enum Level {
  main,
  edit,
}

interface SettingsMenuState {
  view: {
    type: ViewTypes.requisites | ViewTypes.download | ViewTypes.default;
    level: number;
  };
}

export class SettingsMenu extends React.PureComponent<SettingsMenuProps, SettingsMenuState> {

  state = {
    view: {
      type: ViewTypes.default,
      level: Level.main,
    },
  };

  setView(type: ViewTypes, level: number) {
    this.setState({
      view: {
        type,
        level,
      },
    });
  }

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  setLevelToMain = () => {
    this.props.onLevelChange({ wide: false, level: Level.main });
    this.setView(ViewTypes.default, Level.main);
  };

  setLevelToRequisites = () => {
    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.requisites, Level.edit);
  };

  setLevelToDownload = () => {
    this.props.onLevelChange({ wide: false, level: Level.edit });
    this.setView(ViewTypes.download, Level.edit);
  };

  renderMainRequisites() {
    return (
      <MenuItem
        title="Скопировать реквизиты"
        onClick={this.setLevelToRequisites}
      />
    );
  }

  renderSecondRequisites() {
    const shouldRenderNextLEvel = this.state.view.type === ViewTypes.requisites;
    if (!shouldRenderNextLEvel) {
      return null;
    }

    return (<RequisitesCopy onClick={this.setLevelToMain} {...this.props} />);
  }

  renderMainDownload() {
    if (!this.props.isReal) {
      return null;
    }

    return (
      <MenuItem
        title="Скачать"
        onClick={this.setLevelToDownload}
        withArrow
      />
    );
  }

  renderSecondDownload() {
    const shouldRenderNextLevel = this.state.view.type === ViewTypes.download;
    if (!shouldRenderNextLevel) {
      return null;
    }

    return (
      <Download
        onGoBack={this.setLevelToMain}
        items={this.props.downloadItems}
        closePopover={this.props.closePopover}
      />
    );
  }

  renderLevel() {
    const isFirstLevel = this.state.view.level === Level.main;
    if (isFirstLevel) {
      return (
        <s.List>
          {this.renderMainRequisites()}
          {this.renderMainDownload()}
        </s.List>
      );
    }

    const isSecondDownload = this.state.view.type === ViewTypes.download;
    if (isSecondDownload) {
      return (
        <s.List>
          {this.renderSecondDownload()}
        </s.List>
      );
    }

    return (
      <s.Edit>
        {this.renderSecondRequisites()}
      </s.Edit>
    );
  }

  render() {
    return (
      <s.Root
        onClick={this.stopPropagation}
      >
        {this.renderLevel()}
      </s.Root>
    );
  }

}

export default SettingsMenu;
