import http from '@http';

import {
  AbstractFilterBlock,
  MultiChoice,
  AutocompleteDTO,
} from '../abstract';

export enum Title {
  default = 'Номер проекта',
}

export class ProjectNumberBlock extends AbstractFilterBlock {

  static SuggestAPI = '/regulation/autocomplete/number';

  data: MultiChoice = [];

  public createFromScratch(props) {
    if (!props) {
      throw Error('data is required');
    }

    this.setData(props);
  }

  public decorate() {
    return {
      data: this.getData(),
    };
  }

  public undecorate() {
    return {
      data: this.getData(),
    };
  }

  public getItemsForSuggest = async (query = '', page = 1) => {
    const getItems = async (): Promise<any> => {
      const request = {
        url: ProjectNumberBlock.SuggestAPI,
        body: {
          query,
          page,
        },
      };

      const response: AutocompleteDTO = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.map((item) => ({
        id: item.id,
        title: item.name,
      })),
    };
  };

  // eslint-disable-next-line
  public validate() { }

}

export default ProjectNumberBlock;
