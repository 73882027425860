import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
  isDisabled: boolean;
}

style.PaginationItem = styled.div`
  display: flex;
  height: 32px;

  padding: 0 10px;

  cursor: pointer;
  color: ${(props: Props) => props.theme.colors.blue};

  align-items: center;
  justify-content: center;

  :hover {
    text-decoration: underline;
  }

  ${(props: Props) => props.isDisabled && css`
    cursor: default;
    color: ${props.theme.colors.black};
    padding: 0 12px;

    :hover {
      text-decoration: none;
    }
  `}
`;

export default style;
