import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { getParamsFromUrl } from '@utils/utils';

import { FetchSearchResultLawSettingsThunk } from './redux/fetchSettings.thunk';
import { fetchSearchLawsThunk } from './redux/fetchLaws.thunk';
import { changeSortThunk } from './redux/changeSort.thunk';
import { sortDataDefault } from './redux/state';

import { Law } from './law';

const mapStateToProps = (state, ownProps) => {
  const { sourceId } = getParamsFromUrl(ownProps);

  const {
    documents,
    initialLoading,
    sortData,
  } = state.searchResult.law;

  return {
    id: sourceId,
    shouldLoadDocuments: documents.shouldLoadDocuments,
    initialLoading,
    items: documents.items,
    sortData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSearchResultLawSettingsThunk(dispatch);
    thunk.invoke();
  },
  onFetchDocuments() {
    const { sourceId } = getParamsFromUrl(ownProps);
    fetchSearchLawsThunk(dispatch, sourceId);
  },
  onResetToDefaultSort() {
    changeSortThunk(dispatch, sortDataDefault.id);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const onResetToDefaultSort = () => {
    const isSortDataHasDefaultValue = isEqual(stateProps.sortData, sortDataDefault);

    if (!isSortDataHasDefaultValue) {
      return dispatchProps.onResetToDefaultSort();
    }

    return null;
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onResetToDefaultSort,
  };
};

export const LawConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Law);

export default LawConnect;
