import React, { PureComponent } from 'react';

import { Userpilot } from '@utils/userpilot';

import { Button } from '@components/button';

import { Loader } from '@components/loader';

import { RoutePath } from '@router/path';
import { Field } from '@components/field';
import field from '@components/field/field.style';

import { Container } from '../container';

import form from '../form.style';
import s from './login.style';

const statusId = {
  login: 'login',
};

export class Login extends PureComponent {

  state = {
    email: '',
    password: '',
    formError: '',
  };

  refEmail = React.createRef();

  refPassword = React.createRef();

  componentDidMount() {
    Userpilot.reload();
  }

  componentWillUnmount() {
    // @ts-ignore
    this.isUnmounted = true;
  }

  setStateFormError(error) {
    this.setState({ formError: error });
  }

  setStateStatus(status) {
    this.setState({ status });
  }

  handleLoginClick = (event) => {
    event.preventDefault();
    this.login();
  };

  handleFieldChange = (event) => {
    const {
      name,
      value,
    } = event.target;

    this.setState({
      [name]: value,
      formError: '',
    });
  };

  async login() {
    this.setStateStatus(statusId.login);

    try {
      const { email, password } = this.state;
      // @ts-ignore
      // eslint-disable-next-line react/prop-types
      await this.props.onLogin(email, password);
    } catch (error) {

      if (error instanceof Error) {
        throw error;
      }

      this.loginFailed();
    }
  }

  loginFailed() {
    // @ts-ignore
    if (this.isUnmounted) {
      return;
    }

    const formError = 'Неверный логин или пароль';

    this.setStateFormError(formError);
    // @ts-ignore
    this.setStateStatus();

    if (formError) {
      // @ts-ignore
      this.refEmail.current.blur();
      // @ts-ignore
      this.refPassword.current.blur();
    }
  }

  statusOneOf(...items) {
    // @ts-ignore
    return items.some((p) => p === this.state.status);
  }

  // eslint-disable-next-line consistent-return
  disabledLogin() {
    if (this.statusOneOf(statusId.login)) {
      return true;
    }

    if (!this.state.email.trim()) {
      return true;
    }

    if (!this.state.password.trim()) {
      return true;
    }
  }

  renderLoginButton() {
    const text = () => {
      if (!this.statusOneOf(statusId.login)) {
        return <div>Войти</div>;
      }

      return (
        <s.Loader>
          <Loader size={16} />
        </s.Loader>
      );
    };

    return (
      <s.Button>
        <Button
          color="blue"
          size="large"
          fullWidth
          disabled={this.disabledLogin()}
          onClick={this.handleLoginClick}
        >
          {text()}
        </Button>
      </s.Button>
    );
  }

  renderError() {
    const message = this.state.formError;
    if (!message) {
      return null;
    }

    return (
      <field.Tooltip>
        <field.TooltipContainer>
          {message}
        </field.TooltipContainer>
      </field.Tooltip>
    );
  }

  renderForgottenPassword() {
    const link = `${RoutePath.passwordRecovery}?query=${this.state.email}`;

    return (
      <form.InternalLink to={link}>
        Забыли пароль?
      </form.InternalLink>
    );
  }

  render() {
    return (
      <Container withLink={false}>
        <form>
          <form.Title>
            Вход в Caselook
          </form.Title>

          <form.Description>
            <form.DescriptionTitle>Еще нет аккаунта?</form.DescriptionTitle>
            &nbsp;
            <form.ExternalLink href={RoutePath.landing}>
              Узнайте больше.
            </form.ExternalLink>
          </form.Description>

          <form.Fieldset>
            {this.renderError()}
            <form.FieldsContainer>
              <Field
                autoFocus
                disabled={this.statusOneOf(statusId.login)}
                label="Логин"
                name="email"
                value={this.state.email}
                error={!!this.state.formError}
                onChange={this.handleFieldChange}
                // @ts-ignore
                inputRef={this.refEmail}
              />

              <Field
                disabled={this.statusOneOf(statusId.login)}
                label="Пароль"
                type="password"
                name="password"
                value={this.state.password}
                error={!!this.state.formError}
                onChange={this.handleFieldChange}
                // @ts-ignore
                inputRef={this.refPassword}
              />
            </form.FieldsContainer>
          </form.Fieldset>

          <s.Actions>
            {this.renderForgottenPassword()}
            {this.renderLoginButton()}
          </s.Actions>

        </form>
      </Container>
    );
  }

}

export default Login;
