import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpFetchBookmarks() {
  const request = http.get({
    url: RESTPath.bookmarks.getItems,
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchBookmarks;
