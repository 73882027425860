import React from 'react';

import s from './blockType.style';

interface BlockTypeProps {
  title: string;
}

export const BlockType = (props: BlockTypeProps) => (
  <s.BlockType>
    {props.title}
  </s.BlockType>
);

export default BlockType;
