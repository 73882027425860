import { ActionData } from '../sidebar/redux/fetchAttributes.reduce';

interface Action {
  type: string;
  data: ActionData & { id: number };
}

export const setSourceReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    parentDocumentId: Number(action.data.id),
    parentDocumentTitle: `${action.data.title} от ${action.data.attributes.date}`,
  };
};

export default setSourceReduce;
