import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { removeSpacialCharactersFromValue } from '@utils/utils';

import { SearchField } from '@components/searchField/searchField';
import { ItemsListConnect } from './itemsList.connect';

import s from '../addPackage/addPackagePopover.style';

export interface AddItemPopoverProps {
  items: any;
  onInit(): void;
  placeholder: string;
  onResetToInitial(): void;
  onItemToggle(id: number): void;
  onItemCreate(query: string): void;
}

export interface AddItemPopoverState {
  searchQuery: string;
  addingItem: boolean;
}

export class AddItemPopover extends React.PureComponent<AddItemPopoverProps, AddItemPopoverState> {

  state = {
    searchQuery: '',
    addingItem: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {} as AddItemPopoverState;

    state.addingItem = nextProps.items.addingItem;

    if (prevState.addingItem && !nextProps.items.addingItem) {
      state.searchQuery = '';
    }

    return isEmpty(state) ? null : state;
  }

  componentDidMount() {
    this.onInit();
  }

  componentWillUnmount() {
    this.onResetToInitial();
  }

  private onInit() {
    this.props.onInit();
  }

  private onResetToInitial() {
    this.props.onResetToInitial();
  }

  private setSearchQuery(value: string) {
    this.setState({
      searchQuery: value,
    });
  }

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = removeSpacialCharactersFromValue(e.target.value);
    value = value.slice(0, 80);

    this.setSearchQuery(value);
  };

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => e.stopPropagation();

  render() {
    return (
      <s.Container onClick={this.stopPropagation}>
        <SearchField
          value={this.state.searchQuery}
          placeholder={this.props.placeholder}
          onChange={this.handleSearchQuery}
        />
        <ItemsListConnect
          items={this.props.items}
          searchQuery={this.state.searchQuery}
          onItemToggle={this.props.onItemToggle}
          onItemCreate={this.props.onItemCreate}
        />
      </s.Container>
    );
  }

}

export default AddItemPopover;
