export const getSource = (id: string) => {
  if (!id) {
    return 'all';
  }

  if (id === 'under_control') {
    return id;
  }

  return 'my_folders';

};

export default getSource;
