import React from 'react';
import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';
import { fetchDefaultFiltersThunk } from '@pages/search/form/redux/fetchDefaultFilters.thunk';

import { fetchSearchThunk } from './redux/fetchSearch.thunk';

function ensureConnect(Wrapped) {
  const mapStateFromProps = (state, ownProps) => {
    const { sourceId } = getParamsFromUrl(ownProps);

    return {
      sourceId,
    };

  };

  const mapDispatchToProps = (dispatch, ownProps) => ({

    onBootstrap() {
      const { sourceId } = getParamsFromUrl(ownProps);

      if (!sourceId) {
        fetchDefaultFiltersThunk(dispatch, { withPinned: true });

        return;
      }

      fetchSearchThunk(dispatch, sourceId);
    },

  });

  return connect(
    mapStateFromProps,
    mapDispatchToProps,
  )(Wrapped);
}

export function withFetchSearch(WrappedComponent) {
  class WithBootstrap extends React.PureComponent {

    render() {
      return <WrappedComponent {...this.props} />;
    }

  }

  return ensureConnect(WithBootstrap);
}

export default withFetchSearch;
