import {
  ActionData,
  fetchBookmarksReduce,
} from './fetchBookmarks.reduce';

export const actionType = 'BOOKMARKS_BUFFER/FETCH_BOOKMARKS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchBookmarks = {
  actionType,
  createAction,
  reduce: fetchBookmarksReduce,
};
