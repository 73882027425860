import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  padding: 8px 16px 7px 16px;

  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.borderColor};
`;

style.Placeholder = styled.span`
  font-size: 11px;
  color: ${theme.colors.stone}
`;

style.Value = styled.div`
  font-size: 13px;
  color: ${theme.colors.defaultText}
`;

export default style;
