import { resetFormReduce } from './resetForm.reduce';
import { SEARCH_FORM_BLOCK } from '../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/RESET`;

const createAction = () => ({
  type: actionType,
});

export const resetForm = {
  actionType,
  createAction,
  reduce: resetFormReduce,
};

export default resetForm;
