import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import 'moment/locale/ru';

import { LicenseType } from '@router/pathPermissions';
import { UserRoleType } from '@pages/userPages/redux/state';
import { plural } from '@components/plural';
import { separator } from '@components/separator';

import { LicenseCassette } from './licenseCassette';

function createItem(title = '', value = '', alert = false) {
  return {
    id: uuid(),
    title,
    value,
    alert,
  };
}

const mapStateToProps = (state) => {
  const { role, accessTo } = state.currentUser;
  const expectedRoles = role === UserRoleType.user ||
      role === UserRoleType.guest ||
      role === UserRoleType.trial;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;
  const approvedLicenses = caselookLicense || multiLicense;

  const shouldRenderPaymentControl = expectedRoles && approvedLicenses;

  const props = {
    shouldRenderPaymentControl,
    paymentInProgress: state.currentUser.paymentInProgress,
    items: [],
  };

  const stateValue = state.currentUser.isActive ? 'Активна' : 'Не действует';
  props.items.push(createItem('Статус', stateValue, !state.currentUser.isActive));

  const localDate = moment(state.currentUser.licenseEndDate).locale('ru').format('DD MMM YYYY');
  const date = localDate.replace('.', '');

  const dateValue = state.currentUser.licenseEndDate ? date : '—';
  props.items.push(createItem('Дата окончания', dateValue));

  const days = separator(state.currentUser.licenceEndDays);
  const endings = ['день', 'дня', 'дней'];
  const daysValue = state.currentUser.licenceEndDays ?
    `${days} ${plural(state.currentUser.licenceEndDays, endings)}` :
    '—';

  props.items.push(createItem('До окончания', daysValue));

  return props;
};

export const LicenseCassetteConnect = connect(
  mapStateToProps,
)(LicenseCassette);

export default LicenseCassetteConnect;
