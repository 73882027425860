import { connect } from 'react-redux';
import { LicenseType } from '@router/pathPermissions';

import { Profile } from './profile';

const mapStateToProps = (state) => {

  const accessTo = state.currentUser.accessTo;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    shouldRenderNotificationTab: caselookLicense || multiLicense,
  };
};

export const ProfileConnect = connect(
  mapStateToProps,
)(Profile);

export default ProfileConnect;
