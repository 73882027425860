import { connect } from 'react-redux';

import { inviteUsersThunk } from '../redux/users/inviteUsers.thunk';

import { InviteForm } from './inviteForm';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInvite(query, role) {
    const data = {
      folderId: ownProps.id,
      emails: query,
      role: role.id,
    };

    inviteUsersThunk(dispatch, data);
  },
});

export const InviteFormConnect = connect(
  null,
  mapDispatchToProps,
)(InviteForm);

export default InviteFormConnect;
