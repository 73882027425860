import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';

import { resetChronologyDocuments } from './resetChronologyDocuments';
import { fetchChronologyDocuments } from './fetchChronologyDocuments';
import { changePageNumber } from './changePageNumber';

import {
  loadingStart,
  loadingEnd,
} from './loading';

import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export const reducerBuilder = new ReducerBuilder([
  fetchChronologyDocuments,
  changePageNumber,
  loadingEnd,
  loadingStart,
  pageLoadingEnd,
  pageLoadingStart,
  resetChronologyDocuments,
]);

reducerBuilder.setInitialState(StateBuilder.createInitialState());

export default reducerBuilder;
