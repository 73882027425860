import { store } from '@redux/store';

import { StateBuilder } from './state';

export const cleanBookmarksReduce = (state: ReturnType<typeof store.getState>) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.cleanPages();

  return {
    ...newState.getState(),
  };
};

export default cleanBookmarksReduce;
