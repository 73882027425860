import styled, {
  keyframes,
  css,
} from 'styled-components';

interface Props {
  loading: boolean;
  isActive: boolean;
  disabled: boolean;
  theme: any;
}

const style: any = {};

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0.1;
    transform: scale(3);
  }
`;

const loading = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.4;
  }
`;

style.Root = styled.div`
  position: relative;

  transition: all .3s;

  user-select: none;

  width: 32px;
  height: 32px;

  color: ${(props) => props.theme.colors.stone};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  &:active {
    background-color: rgba(65, 131, 215, 0.12);
  }

  ${(props: Props) => props.isActive && css`
    background-color: rgba(65, 131, 215, 0.07);

    &:hover {
      background-color: rgba(65, 131, 215, 0.12);
    }

    i {
      transition: all .3s;
      color: ${props.theme.colors.defaultText};
    }
  `}

  user-select: none;

  ${(props: Props) => props.loading && css`
    animation: ${loading} 1.5s linear infinite;
  `}

  .animate {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: 50%;
      z-index: 1;
      animation: ${ripple} 0.3s ease-out;
      animation-delay: 0.1s;
    }
  }

  ${(props: Props) => props.disabled && css`
    cursor: default;
    > i {
      color: ${props.theme.colors.disabled};
    }
    &:hover {
      background-color: transparent;
    }
  `}
`;

style.Ripple = styled.span`
  position: absolute;
  display: inline-block;
  top: 2px;
  left: 18px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffbe25;
  border: 1px solid white;
  box-sizing: content-box;
`;

export default style;
