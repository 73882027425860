import { fetchPackagesReduce } from './fetchPackages.reduce';

import type { ActionData } from './fetchPackages.reduce';

const actionType = 'PACKAGES/FETCH_PACKAGES';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchPackages = {
  actionType,
  createAction,
  reduce: fetchPackagesReduce,
};

export default fetchPackages;
