import { css } from 'styled-components';
import { styled } from '@components/styled';

interface Props {
  disabled: boolean;
  theme: any;
}

const style: any = {};

style.SearchWrapper = styled.div`
  border-bottom: 1px solid rgb(223, 226, 230);
  padding: 16px 0 16px 28px;
  position: relative;
  height: 48px;

  > input {
    height: 16px;
  }
`;

style.IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;

  ${(props: Props) => props.disabled && css`
    > i {
      color: ${props.theme.colors.disabled}
    }
  `}
`;

export default style;
