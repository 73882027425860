import { Map } from 'immutable';
import {
  Selection,
  SelectionRule,
} from '@pages/searchResult/documents/redux/state';

import { Layout } from './layout';

export type DocumentId = number;

export type Total = number;
export type Loading = boolean;

export interface State {
  layout: Layout;
  shouldLoadSettings: boolean;
  totalDocuments: Total;
  totalPages: Total;
  selection: Selection;
  previewDocument: {
    currentPageNumber: number;
    documentId: number;
  };
  loading: Loading;
  currentPageNumber: number;
  pages: Record<any, any>;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      loading: true,
      showCommentary: true,
      layout: Layout.createState(),
      shouldLoadSettings: true,
      totalDocuments: null,
      totalPages: null,
      previewDocument: {
        currentPageNumber: 1,
        documentId: null,
      },
      currentPageNumber: 1,
      selection: {
        rule: SelectionRule.include,
        ids: Map(),
      },
      pages: {},
    };
  }

  constructor(state) {
    this.state = state;
  }

}

export default StateBuilder;
