/* eslint-disable */
import React from 'react';

import s from './drawer.style';

import type { Anchor } from './drawer.style';

export interface DrawerProps {
  onClick: (e: React.SyntheticEvent) => void;
  anchor: Anchor;
  open: boolean;
  shouldAnimate: boolean;
  withBackground: boolean;
}

export class Drawer extends React.PureComponent<DrawerProps> {

  refRoot = React.createRef();

  render() {
    if (this.props.withBackground) {
      return (
        <s.Root
          onClick={this.props.onClick}
          anchor={this.props.anchor}
          ref={this.refRoot}
          open={this.props.open}
          shouldAnimate={this.props.shouldAnimate}
        >
          {this.props.children}
        </s.Root>
      );
    }

    return (
      <>
        {this.props.children}
      </>
    );

  }

}

export default Drawer;
