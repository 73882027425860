import styled, { css } from 'styled-components';

const style = {};

style.Root = styled.div`
  position: fixed;
  right: ${(props) => props.right || '373px'};
  top: 50%;
  display: block;
  margin-top: -36px;
  z-index: 3;


`;

style.Marker = styled.div`
  margin-left: -12px;
  margin-bottom: 8px;

  cursor: pointer;

  :hover {
    margin-left: -22px;
  }

  ${(props) => props.isActive && css`
    margin-left: -22px;
  `}
`;

export default style;
