export const increasePackageCountReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    packagesCount: state.packagesCount + 1,
  };
};

export default increasePackageCountReduce;
