import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import s from './navigation.style';

function renderMenuItem(item, id: number) {
  const link = item.link.replace(':sourceId', id);

  const handleClick = (element) => () => {
    if (element.id !== 1) {
      return;
    }

    sendAmplitudeData('search_results');
    Userpilot.track('search_results');
  };

  return (
    <s.Item
      key={item.id}
      to={link}
      onClick={handleClick(item)}
      activeClassName="active"
      isActive={item.isActive}
    >
      {item.icon}
      <s.ItemText>{item.text}</s.ItemText>
    </s.Item>
  );
}

interface NavigationBarProps {
  menuItems: any;
  id: number;
}

export const Navigation = (props: NavigationBarProps) => (
  <s.Root>
    {props.menuItems.map((item) => renderMenuItem(item, props.id))}
  </s.Root>
);

export default Navigation;
