import { changePageNumberReduce } from '@pages/searchResult/documents/redux/changePageNumber.reduce';

const actionType = 'SUBSCRIPTIONS/DOCUMENTS/CHANGE_PAGE_NUMBER';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const changePageNumber = {
  actionType,
  createAction,
  reduce: changePageNumberReduce,
};

export default changePageNumber;
