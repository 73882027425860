import { connect } from 'react-redux';

import {
  Title,
  AuthorBlock,
} from '@components/filters/author/logic';

import { Block } from '@components/filters/components/block';

import { updateAuthorThunk } from './redux/updateAuthor.thunk';

import { FilterType } from './redux/state';

import {
  buildTitle,
  buildCounter,
} from './utils';

const mapStateToProps = (state) => {
  const { data } = state.regulation.filters.filters[FilterType.author];
  const selectAllTitle = 'Все разработчики';

  return {
    title: buildTitle(data, Title.default),
    selectAllTitle,
    counter: buildCounter(data),
    isDefault: !data.length,
    data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdate(data: any) {
    updateAuthorThunk(dispatch, data);
  },
  onFetchData(query, page): Promise<any> {
    const block = new AuthorBlock();

    const result = block.getItemsForSuggest(query, page);

    return result;
  },
});

export const AuthorConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default AuthorConnect;
