import styled, { css } from 'styled-components';

const style: any = {};

style.List = styled.div`
  width: 175px;
  max-height: 428px;
  overflow-y: auto;
`;

style.Items = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

style.FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  ${(props: any) => props.isFirst && css`
    borfder: none;
  `}
`;

style.FilterTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(166, 176, 186);
  padding: 0px 16px 13px;
`;

style.FilterItems = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  flex-wrap: wrap;
`;

style.FilterItem = styled.div`
  padding: 12px 16px 13px;
  position: relative;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }

  ${(props: any) => props.active && css`
    background: #f6fafe;
  `}
`;

export default style;
