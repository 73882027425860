import { StateBuilder } from './state';

export interface Action {
  type: string;
}

export const resetToDefaultReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    users: StateBuilder.createState(),
  };
};

export default resetToDefaultReduce;
