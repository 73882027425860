import { connect } from 'react-redux';

import { Workspace } from './workspace';

const mapStateToProps = (state) => {
  const {
    title,
    type,
    document: {
      bodyBlocks,
      loading,
    },
  } = state.lawDocument;

  return {
    title,
    type,
    bodyBlocks,
    loading,
  };
};

export const WorkspaceConnect = connect(
  mapStateToProps,
)(Workspace);

export default WorkspaceConnect;
