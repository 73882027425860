import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.BaseFoldersRoot = styled.div`
  padding: 8px 0;
  flex-shrink: 0;
`;

style.Root = styled.div`
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  padding-top: 8px;
`;

style.Title = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font: 11px Roboto-Regular;
  text-transform: uppercase;
  color: ${(props: Props) => props.theme.colors.stone};
`;

export default style;
