import { styled } from '@components/styled';

const style: any = {};

style.Logo = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 21px;
  height: 20px;
  line-height: 20px;
  width: 80px;
`;

style.LogoCase = styled.span`
  color: ${(props) => props.theme.colors.white};
`;

style.LogoLook = styled.span`
  color: ${(props) => props.theme.colors.orange};
`;

export default style;
