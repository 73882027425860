import { toggleItemSelectReduce } from '@pages/searchResult/documents/redux/toggleItemSelect.reduce';

import type { ActionData } from '@pages/searchResult/documents/redux/toggleItemSelect.reduce';

const actionType = 'SUBSCRIPTIONS/DOCUMENTS/ITEM/UPDATE_SELECTED_ID';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const toggleItemSelect = {
  actionType,
  createAction,
  reduce: toggleItemSelectReduce,
};

export default toggleItemSelect;
