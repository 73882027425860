import React from 'react';

import s from './disolveLayer.style';

interface DisolveLayerProps {
  shouldRender: boolean;
}

export const DisolveLayer: React.FC<DisolveLayerProps> = (props) => (
  <s.Root shouldRender={props.shouldRender} />
);

export default DisolveLayer;
