import { Dispatch } from 'redux';

import { Id } from '@pages/regulation/workspace/redux/state';

import {
  httpFetchSpectractors,
  SpectractorsDTO,
} from './fetchSpectractors.http';
import { fetchSpectractors } from './fetchSpectractors';

export class FetchSpectractorsThunk {

  getState;

  private readonly dispatch: Dispatch;

  private id: Id = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(param: { id: Id }) {
    this.id = param.id;

    const state = this.getState();
    const { activeItemId } = state.regulation.folders;
    const isFolder = typeof (activeItemId) === 'number';
    if (!isFolder) {
      return;
    }

    const spectractors = await this.fetchSpectractors(activeItemId);
    this.dispatchFetchSpectractorsSucceed(spectractors);

  }

  private async fetchSpectractors(folderId): Promise<SpectractorsDTO> {
    try {
      const result = await httpFetchSpectractors(folderId, this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSpectractorsSucceed(data: SpectractorsDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchSpectractors.createAction(result));
  }

}

export async function fetchSpectractorsThunk(dispatch, param: { id: Id }) {
  const thunk = new FetchSpectractorsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(param);
}

export default FetchSpectractorsThunk;
