import { Dispatch } from 'redux';
import { filterBlocksMap } from '@pages/search/form/filterBlocks/filterBlocks.map';
import { fetchJudges } from './fetchJudges';

import {
  GetJudgesSettings,
  httpFetchSearchCourtJudges,
} from './fetchJudges.http';

class FetchSearchJudgesThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(courtId: string) {
    const searchId = this.getState().searchResult.id;

    const {
      sortData,
      layout: { type },
    } = this.getState().searchResult.courts;

    const sortByType = filterBlocksMap.get(type).block.DTOType;

    const settings: GetJudgesSettings = {
      searchId,
      filterType: sortByType,
      valueId: sortData.id,
      sortDirection: sortData.type,
      courtId,
    };

    try {
      const searchResult = await httpFetchSearchCourtJudges(settings);
      this.dispatchGetJudgesSucceed(courtId, searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetJudgesSucceed(courtId, searchResult) {
    const actionData = {
      courtId,
      ...searchResult,
    };

    this.dispatch(fetchJudges.createAction(actionData));
  }

}

export function fetchSearchJudgesThunk(dispatch, courtId: string) {
  const thunk = new FetchSearchJudgesThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(courtId);
}

export default fetchSearchJudgesThunk;
