import {
  ActionData,
  setEventsLoadingReduce,
} from './setEventsLoading.reduce';

export const actionType = 'REGULATION/EVENTS/SET_EVENTS_LOADING';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setEventsLoading = {
  actionType,
  createAction,
  reduce: setEventsLoadingReduce,
};
