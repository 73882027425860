import styled, { css } from 'styled-components';

interface Props {
  isActive: boolean;
  theme: any;
}

const style: any = {};

style.Checkbox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 8px;

  :last-child {
    padding-bottom: 0;
  }

  ${(props: Props) => props.isActive && css`
    cursor: default;
  `}
`;

style.Icon = styled.div`
  ${(props: Props) => props.isActive && css`
    color: ${props.theme.colors.blue};
  `}
`;

style.Title = styled.div`

`;

export default style;
