import React, { ReactElement } from 'react';

import { Checkbox } from '@components/checkbox/checkbox';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './packageItem.style';

type Id = number;

interface PackageItemProps {
  id: Id;
  title: string | ReactElement;
  type?: string;
  selected: boolean;
  onItemClick?(id: Id): void;
}

export const PackageItem = (props: PackageItemProps) => {
  const onClick = () => {
    // @ts-ignore
    props.onItemClick(props.id);
  };

  const renderIcon = (path: string) => {
    const color = '#6c7b89';

    return <Icon path={path} color={color} />;
  };

  const renderCompanyIcon = () => {
    const isCompany = props.type === 'company';
    if (!isCompany) {
      return null;
    }

    const iconPath = iconsPath.get('companyPackage');

    return <s.Icon>{renderIcon(iconPath)}</s.Icon>;
  };

  return (
    <s.Item
      onClick={onClick}
    >
      <Checkbox
        checked={props.selected}
        onClick={onClick}
      />
      <s.ItemTitle>
        {props.title}
        {renderCompanyIcon()}
      </s.ItemTitle>
    </s.Item>
  );
};

PackageItem.defaultProps = {
  onItemClick: () => {},
};

export default PackageItem;
