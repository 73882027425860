import React from 'react';

import {
  ResetButton,
  SaveButton,
} from '@components/buttons';

import { Header } from '@components/popoverHeader/header';

import {
  Item,
  ItemTitle,
  ItemProps,
} from '@components/menu/item';

import s from '../remove.style';

interface ContentItem extends ItemProps {
  key: string;
}

interface RemoveSettingsProps {
  onGoBack: () => void;
  onClosePopover: () => void;
  onRemove: () => void;
}

export class Remove extends React.PureComponent<RemoveSettingsProps> {

  handleGoBack = () => {
    this.props.onGoBack();
  };

  handleRemove = () => {
    this.props.onRemove();
    this.props.onClosePopover();
  };

  renderHeader() {
    const title = 'Удалить фильтр';

    return (
      <Header
        onGoBack={this.handleGoBack}
        title={title}
      />
    );
  }

  renderItem = (item: ContentItem) => (
    <Item
      key={item.key}
      value={item.value}
    />
  );

  renderContent() {
    const renderDeleteValue = <> Вы уверены, что хотите удалить фильтр? </>;
    const title = 'Удаление фильтра';
    const contentData = [
      {
        key: 'title',
        value: <ItemTitle title={title} />,
      },
      {
        key: 'input',
        value: renderDeleteValue,
      },
    ];

    return contentData.map(this.renderItem);
  }

  renderResetButton() {
    return (
      <ResetButton
        onClick={this.handleGoBack}
      />
    );
  }

  renderSaveButton() {
    const title = 'Удалить';

    return (
      <SaveButton
        onClick={this.handleRemove}
        title={title}
      />
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        <s.List>
          {this.renderContent()}
        </s.List>
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Container>
    );
  }

}

export default Remove;
