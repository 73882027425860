import { RESTPath } from '@router/path.rest';
import http from '@http';

import { RequestParams } from './fetchHistory.http';

export function httpRemoveHistoryItem(searchId: number, params: RequestParams) {
  const request = {
    url: RESTPath.search.deleteHistoryItem(searchId, params),
  };

  return http
    .delete(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);

}

export default httpRemoveHistoryItem;
