import React from 'react';

import { RoutePath } from '@router/path';
import { UnauthRouteConnect } from '@router/unauthRoute.connect';

import { LandingWithBootstrap } from './landing.connect';

export const RouteLanding = (
  <UnauthRouteConnect
    exact
    path={RoutePath.landing}
    component={LandingWithBootstrap}
  />
);

export default RouteLanding;
