import React from 'react';

import { BlockType } from '../../components/blockType/blockType';
import { Contains } from '../../components/contains/contains';
import { Suggest } from '../../components/suggest/suggest';

import { PreviousCourtBlock as Block } from './redux/previousCourt';

import { Data } from './redux/updatePreviousCourt';

import s from './block.style';

export interface PreviousCourtBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: Block;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onChangeContains: (data: any) => void;
  onUpdatePreviousCourt: (data: Data) => void
}

export class PreviousCourtBlock extends React.PureComponent<PreviousCourtBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  handleChangePreviousCourt = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdatePreviousCourt(actualData);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderPreviousCourt() {
    const data = this.props.item.getData();
    const activeItem = {
      id: data.id,
      title: data.title,
    };

    return (
      <s.FilterItem isGrow>
        <Suggest
          useAnchorWidth
          activeItem={activeItem}
          placeholder="Вышестоящая инстанция"
          getItems={this.props.item.getItemsForSuggest}
          onChange={this.handleChangePreviousCourt}
        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderPreviousCourt()}
      </s.FilterBlock>
    );
  }

}

export default PreviousCourtBlock;
