import { subscriptionCreateReduce } from './subscriptionCreate.reduce';

import type { ActionData } from './subscriptionCreate.reduce';

const actionType = 'SEARCH/RESULT/SUBSCRIPTION_CREATE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const subscriptionCreate = {
  actionType,
  createAction,
  reduce: subscriptionCreateReduce,
};

export default subscriptionCreate;
