import { Dispatch } from 'redux';

import { Rating } from '../../annotation/redux/annotation';

import { DocumentId } from './state';

import { httpUpdateSummaryRating } from './updateSummaryRating.http';
import { updateSummaryRating } from './updateSummaryRating';

export interface RequestParams {
  documentId: DocumentId;
  value: Rating;
}

export class UpdateSummaryRatingThunk {

  getState;

  private readonly dispatch: Dispatch;

  private requestParams: RequestParams;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {
    const {
      documentId,
      value,
    } = params;

    if (!documentId) {
      return;
    }

    const state = this.getState().summary;
    const stateSummary = state.items.get(documentId);
    if (!stateSummary) {
      return;
    }

    const shouldReset = stateSummary.rating === value;
    const newValue = shouldReset ? null : value;

    this.requestParams = {
      documentId,
      value: newValue,
    };

    this.dispatchRatingUpdate();
    this.ratingUpdate();
  }

  private async ratingUpdate() {
    try {
      await httpUpdateSummaryRating(this.requestParams);

    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchRatingUpdate() {
    this.dispatch(updateSummaryRating.createAction(this.requestParams));
  }

}

export function updateSummaryRatingThunk(dispatch, params) {
  const thunk = new UpdateSummaryRatingThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  thunk.invoke(params);
}

export default UpdateSummaryRatingThunk;
