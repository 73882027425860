import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { definitionContentType } from '../redux/state';

import { FetchSettingsThunk } from './redux/fetchSettings.thunk';
import { FetchInitialSearchDefinitionContentTypeThunk } from './redux/fetchInitialDefinitionContentType.thunk';

import { DefinitionContentType } from './definitionContentType';

const mapStateToProps = (state) => {
  const shouldRenderBlock = !!state.searchResult.analysis.itemsMap[definitionContentType.getId()]
    .items.itemsList.length;

  return {
    shouldRenderBlock,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSettingsThunk(dispatch);
    thunk.invoke();
  },
  onFetchData() {
    const thunk = new FetchInitialSearchDefinitionContentTypeThunk(dispatch);

    const { sourceId } = getParamsFromUrl(ownProps);
    thunk.invoke(sourceId);

    return thunk;
  },
});

export const DefinitionContentTypeConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefinitionContentType);

export default DefinitionContentTypeConnect;
