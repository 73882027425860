import styled, { css } from 'styled-components';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  marginBottom: number;
  isActive: boolean;
  bold: boolean;
}

const style: any = {};

export const width = {
  document: '208px',
  text: '100%',
  more: '40px',
};

style.Root = styled.div`
  display: table-row;
  cursor: pointer;
  user-select: none;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  ${(props: Props) => props.isActive && css`
    background-color: ${props.theme.colors.polar};
  `}

  &:hover {
    background-color: ${(props: Props) => props.theme.colors.polar};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

style.Column = styled.div`
  width: ${width.document};
`;

style.Cell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const cellStyle = () => css`
  display: table-cell;
  vertical-align: top;
`;

style.CellDocument = styled(style.Column)`
  ${cellStyle()}
`;

style.BodyCell = styled(style.Cell)`
  padding: 13px 16px;
`;

style.BodyText = styled.div`
  font-size: 13px;
  line-height: 16px;

  ${(props: Props) => props.bold && css`
    font-weight: bold;
  `}

  ${(props: Props) => props.marginBottom && css`
    margin-bottom: ${props.marginBottom}px;
  `}
`;

style.TitleText = styled(style.BodyText)`
  color: ${(props: Props) => props.theme.colors.defaultText};
`;

style.TitleEvent = styled(style.BodyText)`
  margin-top: 10px;
  font: 13px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.defaultText};
  background: rgba(34, 49, 63, 0.05);
  border-radius: 14px;
  padding: 6px 12px;

  a {
    color: #4183d7;
  }
`;

style.TitleLabel = styled(style.BodyText)`
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.Date = styled.div`
  font: 11px Roboto-Bold;
`;

style.Folders = styled.div`
  display: flex;
`;

export default style;
