import React from 'react';

import { Loader } from '@components/loader';

import { Message } from './message';

import type { MessageProps } from './message';

import s from './chatView.style';

interface ChatViewProps {
  messages: MessageProps[];
  loading: boolean;
}

export class ChatView extends React.PureComponent<ChatViewProps> {

  rootRef:React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    this.scrollBottom();
  }

  componentDidUpdate(prevProps) {
    const sameLength = prevProps.messages.lenght === this.props.messages.length;
    if (!sameLength) {
      this.scrollBottom();
    }
  }

  scrollBottom() {
    const current = this.rootRef.current;
    current.scrollTop = current.scrollHeight;
  }

  renderMessage = (item: MessageProps) => (<Message {...item} key={item.id} />);

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderEmptyTitle() {
    return (
      <s.EmptyTitle>Комментариев еще нет</s.EmptyTitle>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    if (!this.props.messages.length) {
      return this.renderEmptyTitle();
    }

    return this.props.messages.map(this.renderMessage);
  }

  render() {
    return (
      <s.Root
        ref={this.rootRef}
      >
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default ChatView;
