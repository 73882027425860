import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  isActive: boolean;
  isDefault: boolean;
  theme: any;
}

style.Root = styled.div`
  height: auto;
  border-right: none;
  flex: 0 auto;
  float: none;

  font: 13px Roboto-Regular;

  color: ${(props: Props) => props.theme.colors.blue};

  ${(props: Props) => props.isDefault && css`
    color: ${props.theme.colors.stone};
  `}

  ${(props: Props) => props.isActive && css`
    background-color: rgba(65, 131, 215, 0.07);
  `}

  transition: all .3s;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  &:active {
    background-color: rgba(65, 131, 215, 0.12);
  }
`;

style.Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  padding: 0 8px;
`;

style.Title = styled.div`
  max-width: 256px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
`;

style.Counter = styled.div`
  color: ${(props: Props) => props.theme.colors.blue};
`;

export default style;
