import { connect } from 'react-redux';

import { saveFirstnameThunk } from '@pages/userPages/redux/saveFirstname.thunk';
import { saveLastnameThunk } from '@pages/userPages/redux/saveLastname.thunk';
import { savePositionThunk } from '@pages/userPages/redux/savePosition.thunk';

import { createAuthorPackageThunk } from '../redux/packages/createAuthorPackage.thunk';

import { CreatePackage } from './createPackage';

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id,
  description: state.packages.packages.itemsMap.get(ownProps.id).description,
  lastname: state.currentUser.lastName,
  firstname: state.currentUser.firstName,
  company: state.currentUser.company,
  position: state.currentUser.position,
});

const mapDispatchToProps = (dispatch) => ({
  async onSubmit(id, text, credentials, stateProps) {
    if (stateProps.firstname !== credentials.lastname) {
      await saveFirstnameThunk(dispatch, { text: credentials.firstname });
    }

    if (stateProps.lastname !== credentials.lastname) {
      await saveLastnameThunk(dispatch, { text: credentials.lastname });
    }

    if (stateProps.position !== credentials.position) {
      await savePositionThunk(dispatch, { text: credentials.position });
    }

    await createAuthorPackageThunk(dispatch, { id, text });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit(id, text, credentials) {
    dispatchProps.onSubmit(id, text, credentials, stateProps);
  },
});

export const CreatePackageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CreatePackage);

export default CreatePackageConnect;
