import React from 'react';

import { BlockType } from '../../components/blockType/blockType';
import { Contains } from '../../components/contains/contains';

import { ClaimAmountBlock as Block } from './redux/claimAmount';

import { Data as DataFrom } from './redux/updateFrom';
import { Data as DataTo } from './redux/updateTo';

import { SliderComponent } from './sliderComponent';

import s from '../block.style';

export interface ClaimAmountBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: Block;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onChangeContains: (data: any) => void;
  onUpdateFrom: (data: DataFrom) => void;
  onUpdateTo: (data: DataTo) => void;
  onSliderChange: (data: {
    id: string,
    value: number[],
  }) => void;
}

export class ClaimAmountBlock extends React.PureComponent<ClaimAmountBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  generateRangeValue = (value: string, maxLength: number): string => {
    const maxIndex = maxLength - 1;
    let indexOfLastNumber = 0;
    let result = '';
    const arrOfNumbers = value.split('');

    for (let i = 0; i < arrOfNumbers.length - 1; i += 1) {
      if (indexOfLastNumber > maxIndex) {
        break;
      }

      if (arrOfNumbers[i] !== ' ') {
        indexOfLastNumber += 1;
      }

      result += arrOfNumbers[i];
    }

    return result;
  };

  handleCheckingFormat = (value: string): string => {
    const maxLength = 15;
    const valueWithoutSpaces = value.replace(/\s/g, '');

    if (valueWithoutSpaces.length <= maxLength) {
      return value;
    }

    const result = this.generateRangeValue(value, maxLength);

    return result;
  };

  handleChangeFromValue = (value) => {
    const id = this.props.item.getId();
    const valueOfRange = this.handleCheckingFormat(value);

    const data = {
      id,
      data: {
        value: valueOfRange,
      },
    };

    this.props.onUpdateFrom(data);
  };

  handleChangeToValue = (value) => {
    const id = this.props.item.getId();
    const valueOfRange = this.handleCheckingFormat(value);

    const data = {
      id,
      data: {
        value: valueOfRange,
      },
    };

    this.props.onUpdateTo(data);
  };

  handleSliderChange = (value: number[]) => {
    const id = this.props.item.getId();
    const data = {
      id,
      value,
    };

    this.props.onSliderChange(data);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderAmount() {
    const data = this.props.item.getData();

    return (
      <s.FilterItem isGrow>
        <SliderComponent
          // @ts-ignore
          value={data.value}
          onChangeFromValue={this.handleChangeFromValue}
          onChangeToValue={this.handleChangeToValue}
          onChangeSliderValue={this.handleSliderChange}
          withSpaces
        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderAmount()}
      </s.FilterBlock>
    );
  }

}

export default ClaimAmountBlock;
