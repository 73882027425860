import React from 'react';

import { Loader } from '@components/loader';

import { SearchField } from '@components/searchField/searchField';

import s from '@pages/subscriptions/sidebar/sidebar.style';

import { ItemsConnect } from './items.connect';

type SidebarProps = any;

export class Sidebar extends React.PureComponent<SidebarProps> {

  private placeholder = 'Поиск';

  state = {
    searchQuery: '',
  };

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: e.target.value,
    });
  };

  renderLoader() {
    return (
      <s.Root>
        <s.Loader>
          <Loader size={32} />
        </s.Loader>
      </s.Root>
    );
  }

  renderSearchField = () => (
    <SearchField
      disabled={!this.props.hasPackages}
      value={this.state.searchQuery}
      placeholder={this.placeholder}
      onChange={this.handleSearchQuery}
    />
  );

  renderItems() {
    return (
      <>
        {this.renderSearchField()}
        <ItemsConnect
          query={this.state.searchQuery}
        />
      </>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return this.renderItems();
  }

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );

  }

}

export default Sidebar;
