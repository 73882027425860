import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { UserRoleType } from '@pages/userPages/redux/state';

import { fetchSummaryThunk } from '@layouts/documentHelpers/summary/redux/fetchSummary.thunk';
import { updateSummaryRatingThunk } from '@layouts/documentHelpers/summary/redux/updateSummaryRating.thunk';
import { toggleSummary } from '@layouts/documentHelpers/summary/redux/toggleSummary';
import { Summary } from '@layouts/documentHelpers/summary/summary';

const mapStateToProps = (state, ownProps) => {
  const isReal = state.currentUser.role !== UserRoleType.guest;
  const summary = state.summary.items.get(ownProps.documentId);

  const hasError = summary && summary.fetchError;
  const inProgress = summary && summary.inProgress;
  const isExpanded = summary && summary.isExpanded;
  const rating = summary && summary.rating;

  return {
    id: ownProps.documentId,
    rating,
    isReal,
    shouldRender: !!summary,
    inProgress,
    isExpanded,
    hasError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate() {
    fetchSummaryThunk(dispatch, { documentId: ownProps.documentId });

    sendAmplitudeData('caselook:summary create', { documentId: ownProps.documentId });
    Userpilot.track('caselook:summary create', { documentId: ownProps.documentId });
  },
  onRatingUpdate(data) {
    updateSummaryRatingThunk(dispatch, { documentId: ownProps.documentId, value: data.value });

    sendAmplitudeData('caselook:summary rating update', { documentId: ownProps.documentId, rating: data.value });
    Userpilot.track('caselook:summary rating update', { documentId: ownProps.documentId, rating: data.value });
  },
  onToggle() {
    dispatch(toggleSummary.createAction({ documentId: ownProps.documentId }));

    sendAmplitudeData('caselook:summary open', { documentId: ownProps.documentId });
    Userpilot.track('caselook:summary open', { documentId: ownProps.documentId });
  },
});

export const SummaryConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);

export default SummaryConnect;
