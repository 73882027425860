import {
  styled,
  css,
} from '@components/styled';

interface Props {
  theme: Record<any, any>;
  isAvatarHeightDominant: boolean;
}

const style: any = {};

style.Root = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: start;
  background: ${(props: Props) => props.theme.colors.white};
`;

style.Photo = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;

  > img {
    height: 100%;
    width: auto;

    ${(props: Props) => props.isAvatarHeightDominant && css`
      height: auto;
      width: 100%;
    `}
  }
`;

style.AvatarIcon = styled.div`
  background: ${(props: Props) => props.theme.colors.defaultButton};
  path {
    transform: scale(0.4) translateX(-8px);
  }
`;

style.Message = styled.div`
  padding: 0 8px;
`;

style.Credentials = styled.div`
  font-size: 13px;
  font-family: Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.defaultText};
`;

style.Date = styled.div`
  font-size: 11px;
  color: ${(props: Props) => props.theme.colors.stone}
`;

style.Body = styled.div`
  margin-top: 8px;
  white-space: pre-wrap;
  font-size: 13px;
  color: ${(props: Props) => props.theme.colors.defaultText};
  word-break: break-word;

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: #4183d7;
  }
`;

export default style;
