import React from 'react';
import { Range } from 'rc-slider';
import marks from './marks';

import s from './slider.style';

export const Slider = (props) => {

  const handleChange = (value) => {
    // eslint-disable-next-line react/prop-types
    props.onChange(value);
  };

  return (
    <s.Slider>
      <Range
        allowCross={false}
        defaultValue={[0, 110]}
        marks={marks}
        step={null}
        // eslint-disable-next-line react/prop-types
        value={props.value}
        min={0}
        max={110}
        onChange={handleChange}
      />
    </s.Slider>
  );
};

export default Slider;
