const actionType = 'SEARCH/RESULT/CASE_TYPE_ID/LAYOUT_SETTINGS__SAVE_SUCCEEDED';

const createAction = () => ({
  type: actionType,
});

export const saveCaseTypeLayoutSettingsSucceeded = {
  actionType,
  createAction,
  reduce: () => {},
};

export default saveCaseTypeLayoutSettingsSucceeded;
