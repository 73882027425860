import { Dispatch } from 'redux';

import { DateBlock } from '@components/filters/date/logic/date';

import { fetchFolder } from './fetchFolder';
import { fetchFolderLastPage } from './fetchFolderLastPage';
import { httpFetchFolder } from './fetchFolder.http';

export class FetchFolderThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(param) {
    if (this.getState().regulation.projects.lastPageReached) {
      return;
    }

    let id;

    if (!param) {
      id = this.getState().regulation.folders.activeItemId;
    } else {
      id = param.id;
    }

    const { currentPageNumber } = this.getState().regulation.projects;

    const page = Array.isArray(currentPageNumber) ? 1 : currentPageNumber + 1;

    const state = this.getState();
    const filters = { ...state.regulation.filters.filters };

    const dateFilter = new DateBlock();
    dateFilter.create(filters.date);

    filters.date = dateFilter.undecorate();

    try {
      const result = await httpFetchFolder(id, filters, page);
      if (!result.projects.length) {
        this.dispatchLastPageFetched();
      }

      this.dispatchFetchSucceed({ ...result, page });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(fetchFolder.createAction(result));
  }

  private dispatchLastPageFetched() {
    this.dispatch(fetchFolderLastPage.createAction());
  }

}

export function fetchFolderThunk(dispatch, param?) {
  const thunk = new FetchFolderThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(param);
}

export default FetchFolderThunk;
