import { connect } from 'react-redux';

import { plural } from '@components/plural';
import { separator } from '@components/separator';

import { Header } from './header';

function buildTitle(count) {
  const endings = ['проект', 'проекта', 'проектов'];
  const pluralize = plural(count, endings);
  const totalDocuments = separator(count);

  return `${totalDocuments} ${pluralize}`;
}

const mapStateToProps = (state) => {
  const {
    totalProjects,
  } = state.regulation.projects;

  return {
    title: buildTitle(totalProjects),
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
