import { connect } from 'react-redux';

import { UserRoleType } from '@pages/userPages/redux/state';

import { plural } from '@components/plural';
import { separator } from '@components/separator';

import { Header } from './header';

function buildTitle(count) {
  const endings = ['документ', 'документа', 'документов'];
  const pluralize = plural(count, endings);
  const totalDocuments = separator(count);

  return `${totalDocuments} ${pluralize} подборки`;
}

const mapStateToProps = (state) => {
  const {
    totalDocuments,
  } = state.authorPackages.documents;

  return {
    isReal: state.currentUser.role !== UserRoleType.guest,
    title: buildTitle(totalDocuments),
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
