import { markIsVisitedReduce } from './markIsVisited.reduce';

const actionType = 'SEARCH/RESULT/MARK_IS_VISITED';

const createAction = () => ({
  type: actionType,
});

export const markIsVisited = {
  actionType,
  createAction,
  reduce: markIsVisitedReduce,
};

export default markIsVisited;
