import { selectHistoryItemReduce } from './selectHistoryItem.reduce';

const actionType = 'SEARCH/HISTORY/SELECT_HISTORY_ITEM';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const selectHistoryItem = {
  actionType,
  createAction,
  reduce: selectHistoryItemReduce,
};

export default selectHistoryItem;
