import {
  CourtBlock, CourtBlockData,
} from '../../form/filterBlocks/court/redux/court';

export abstract class CourtBlockHistory extends CourtBlock {

  static transform(data: CourtBlockData) {
    return data.title || '';
  }

}

export default CourtBlockHistory;
