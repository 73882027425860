import { createEmptyProjectReduce } from './createEmptyProject.reduce';

import type { ActionData } from './createEmptyProject.reduce';

const actionType = 'REGULATION/PREVIEW/ADD_EMPTY_PROJECT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const createEmptyProject = {
  actionType,
  createAction,
  reduce: createEmptyProjectReduce,
};

export default createEmptyProject;
