import type { FetchedDocuments } from '@layouts/similar/redux/state';

export type ActionData = FetchedDocuments;

interface Action {
  type: string;
  data: ActionData;
}

function prepareSimilarItem(item) {
  return {
    documentId: item.document_id,
    title: `${item.type_title} от ${item.date}`,
    department: item.department,
  };
}

function prepareSimilar(items) {
  const data = items.map((item) => prepareSimilarItem(item));

  return data;
}

export const fetchSimilarReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    similar: {
      data: prepareSimilar(action.data),
      loading: false,
    },
  };
};

export default fetchSimilarReduce;
