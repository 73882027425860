import React from 'react';

import { Layout } from '../header/redux/state';

import { DocumentsConnect } from './documents/documents.connect';
import { Filters } from './filters/filters';

import { Empty } from './documents/empty';

import s from './workspace.style';

type WorkspaceProps = any;

export class Workspace extends React.PureComponent<WorkspaceProps> {

  renderContent() {
    const isDocuments = this.props.layout === Layout.documents;
    if (isDocuments) {
      return <DocumentsConnect />;
    }

    return <Filters />;
  }

  renderEmpty() {
    return <Empty />;
  }

  render() {
    if (this.props.noItems) {
      return this.renderEmpty();
    }

    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );

  }

}

export default Workspace;
