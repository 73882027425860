import { Id } from '@pages/regulation/sidebar/folders/redux/item';
import { StateBuilder } from '@pages/regulation/sidebar/folders/redux/state';

export interface ActionData {
  id: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const removeFolderReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.folders);
  newState.removeItem(action.data.id);
  const folders = newState.getState();

  return {
    ...state,
    folders,
  };
};

export default removeFolderReduce;
