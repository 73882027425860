import { ReducerBuilder } from '@redux/reducer.builder';

import { reducerBuilder as subscriptions } from '../sidebar/redux/reducer';
import { reducerBuilder as documents } from '../workspace/documents/redux/reducer';
import { reducerBuilder as header } from '../header/redux/reducer';

import { resetToInitial } from './resetToInitial';

import { StateBuilder } from './state';

export const reducerBuilder = new ReducerBuilder([
  resetToInitial,
]);

reducerBuilder.merge(subscriptions);
reducerBuilder.merge(documents);
reducerBuilder.merge(header);

const initialState = StateBuilder.createState();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
