import { connect } from 'react-redux';

import { ListItem } from '@components/menu/listItem';

function getTitle(itemsMap, id) {
  if (itemsMap.get(id).type !== 'author') {
    return 'Опубликовать подборку';
  }

  if (itemsMap.get(id).type === 'author') {
    return 'Обновить публикацию';
  }

  return null;

}

const mapStateToProps = (state, ownProps) => ({
  title: getTitle(state.packages.packages.itemsMap, ownProps.id),
  disable: state.packages.packages.itemsMap.get(ownProps.id).type === 'company',
});

export const ListItemConnect = connect(
  mapStateToProps,
)(ListItem);

export default ListItem;
