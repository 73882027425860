import React from 'react';

import s from './field.style';

type Value = string | number;

interface FieldProps {
  id?: string | number;
  autoFocus?: boolean;
  disabled?: boolean;
  value?: Value;
  defaultValue?: Value;
  name?: string;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  error?: string | boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: Value) => void;
  onFocus?: (value: Value) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
}

export class Field extends React.Component<FieldProps> {

  static defaultProps = {

    id: undefined,

    label: undefined,

    disabled: false,

    value: undefined,

    defaultValue: undefined,

    name: undefined,

    type: 'text',

    placeholder: undefined,

    autoComplete: undefined,

    error: undefined,

    inputRef: undefined,

    onChange: undefined,

    onFocus: undefined,

    onBlur: undefined,

    onKeyDown: undefined,
  };

  state = {
    focused: false,
    dirty: false,
    autoFill: false,
  };

  inputRef: any;

  tooltipRef: any;

  UNSAFE_componentWillMount() {
    if (this.isControlled()) {
      this.checkDirty(this.props);
    }
  }

  componentDidMount() {
    this.updateTooltipPosition();

    if (!this.isControlled()) {
      this.checkDirty(this.inputRef);
    }

    if (this.inputRef) {
      // https://github.com/klarna/ui/blob/v4.10.0/Field/index.jsx#L104-L114
      this.inputRef.addEventListener('animationstart', this.handleAnimationStart);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<FieldProps> & Readonly<{ children?: React.ReactNode; }>) {
    if (this.isControlled()) {
      this.checkDirty(nextProps);
    }
  }

  componentDidUpdate() {
    this.updateTooltipPosition();
  }

  componentWillUnmount() {
    if (this.inputRef) {
      this.inputRef.removeEventListener('animationstart', this.handleAnimationStart);
    }
  }

  setStateAutoFill(autoFill: boolean) {
    this.setState({ autoFill });
  }

  handleAnimationStart = (event: { animationName: any; }) => {
    switch (event.animationName) {
      case s.animationAutoFillStart: {
        this.setStateAutoFill(true);
        break;
      }

      case s.animationAutoFillCancel: {
        this.setStateAutoFill(false);
        break;
      }

      default: { break; }
    }
  };

  handleFocus = (event: Value) => {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleBlur = (event: Value) => {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.isControlled()) {
      this.checkDirty(this.inputRef);
    }

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  handleKeyDown = (event: KeyboardEvent) => {
    // @ts-ignore
    this.props.onKeyDown(event);
  };

  handleInputRef = (ref: any) => {
    this.inputRef = ref;

    if (!this.props.inputRef) {
      return;
    }

    const { inputRef } = this.props;
    if (typeof inputRef === 'function') {
      // @ts-ignore
      inputRef(ref);
    } else {
      // @ts-ignore
      inputRef.current = ref;
    }
  };

  handleTooltipRef = (node: any) => {
    this.tooltipRef = node;
  };

  checkDirty(obj: Readonly<FieldProps> & Readonly<{ children?: React.ReactNode; }>) {
    const hasValue = obj && obj.value && obj.value.toString().length > 0;
    this.setState({ dirty: hasValue });
  }

  isControlled() {
    return typeof this.props.value === 'string';
  }

  hasTooltip() {
    const { error } = this.props;
    const { focused } = this.state;

    return ((typeof error === 'string') && error.length && focused);
  }

  updateTooltipPosition() {
    if (!this.hasTooltip()) {
      return;
    }

    const inputWidth = this.inputRef.clientWidth;
    const tooltipWidth = this.tooltipRef.clientWidth;

    const left = (inputWidth / 2) - (tooltipWidth / 2);
    this.tooltipRef.style.left = `${left}px`;
  }

  renderLabel() {
    const { label } = this.props;
    if (!label) {
      return null;
    }

    const { dirty, autoFill } = this.state;
    const shrink = dirty || autoFill;

    return (
      <s.Label
        shrink={shrink}
        disabled={this.props.disabled}
        error={this.props.error}
      >
        <s.LabelText>{label}</s.LabelText>
      </s.Label>
    );
  }

  renderTooltip() {
    if (!this.hasTooltip()) {
      return null;
    }

    const { error } = this.props;

    return (
      <s.Tooltip ref={this.handleTooltipRef}>
        <s.TooltipContainer>{error}</s.TooltipContainer>
      </s.Tooltip>
    );
  }

  renderField() {
    const {
      disabled,
      value,
      defaultValue,
      name,
      type,
      placeholder,
      autoComplete,
      id,
      label,
      error,
      onChange,
      onFocus,
      onBlur,
      ...other
    } = this.props;

    const { dirty, autoFill } = this.state;
    const skewPadding = !!(label && (dirty || autoFill));

    return (
      <s.Input
        skewPadding={skewPadding}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        ref={this.handleInputRef}
        disabled={disabled}
        error={error}
        value={value}
        defaultValue={defaultValue}
        name={name}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        id={id}
        {...other}
      />
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderTooltip()}
        {this.renderLabel()}
        {this.renderField()}
      </s.Root>
    );
  }

}

export default Field;
