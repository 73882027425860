import React from 'react';

import { Loader } from '@components/loader';

import { OpenButton } from '@components/buttons';

import { TextField } from '@components/textField/textField';

import {
  AttributeField,
  FieldProps,
} from '@components/attributeField/attributeField';

import s from './attributes.style';

interface Data {
  attributes: {
    date: string;
    resolutionDate: string;
    department: string;
    judges: FieldProps[];
    caseType: string;
    caseCategory: string;
    caseResult: string;
    appealState: string;
    case: {
      number: string;
      external: string;
    };
    representatives: FieldProps[];
    plaintiffs: FieldProps[];
    defendants: FieldProps[];
    third: FieldProps[];
    others: FieldProps[];
  };
  commentary: string;
}

interface AttributesProps {
  data: Data;
  viewOnly: boolean;
  onCommentaryChange(): void;
  loading: boolean;
}

export class Attributes extends React.PureComponent<AttributesProps> {

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderExternalLink = () => {
    if (!this.props.data.attributes.case.external) {
      return null;
    }

    return (
      <OpenButton
        href={`https://casebook.ru/card/case/${this.props.data.attributes.case.external}`}
      />

    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    const {
      attributes,
      commentary,
    } = this.props.data;

    const renderCounter = (length) => {
      const isSimple = !length || length === 1;
      if (isSimple) {
        return '';
      }

      return ` (${length})`;
    };

    return (
      <s.Root>
        <s.Commentary>
          <TextField
            disabled={this.props.viewOnly}
            label="Примечание"
            value={commentary}
            onChange={this.props.onCommentaryChange}
          />
        </s.Commentary>
        <AttributeField
          placeholder="Дата принятия"
          value={attributes.date}
        />
        <AttributeField
          placeholder="Дата оглашения резолютивной части"
          value={attributes.resolutionDate}
        />
        <s.Link>
          <AttributeField
            placeholder="Номер дела"
            value={attributes.case.number}
          />
          {this.renderExternalLink()}
        </s.Link>
        <AttributeField
          placeholder="Вид спора"
          value={attributes.caseType}
        />
        <AttributeField
          placeholder="Категория спора"
          value={attributes.caseCategory}
        />
        <AttributeField
          placeholder="Принявший орган"
          value={attributes.department}
        />
        <AttributeField
          placeholder="Судьи"
          multiple
          values={attributes.judges}
        />
        <AttributeField
          placeholder="Исход рассмотрения"
          value={attributes.caseResult}
        />
        <AttributeField
          placeholder="Статус обжалования"
          value={attributes.appealState}
        />
        <AttributeField
          placeholder={`Истец / Кредитор${renderCounter(attributes.plaintiffs.length)}`}
          multiple
          values={attributes.plaintiffs}
        />
        <AttributeField
          placeholder={`Ответчик / Должник${renderCounter(attributes.defendants.length)}`}
          multiple
          values={attributes.defendants}
        />
        <AttributeField
          placeholder={`Третье лицо${renderCounter(attributes.third.length)}`}
          multiple
          values={attributes.third}
        />
        <AttributeField
          placeholder={`Иное лицо${renderCounter(attributes.others.length)}`}
          multiple
          values={attributes.others}
        />
        <AttributeField
          placeholder={`Представители${renderCounter(attributes.representatives.length)}`}
          multiple
          values={attributes.representatives}
        />
      </s.Root>
    );

  }

}

export default Attributes;
