import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { Settings } from '../../redux/settings';

export enum DTOType {
  caseResult = 'case_result',
  appealState = 'appeal_state',
}

export enum Type {
  caseResult = 'caseResult',
  appealState = 'appealState',
}

export enum Format {
  number = 'number',
  percent = 'percent',
}

interface PresetItem {
  id: string;
  title: string;
}

type ViewItemsMap = Map<string, PresetItem>;

export type Types = Type.caseResult | Type.appealState;

export type Formats = Format.number | Format.percent;

interface ColumnItem {
  itemsList: string[];
  itemsMap: ViewItemsMap;
}

interface Columns {
  caseResult: ColumnItem;
  appealState: ColumnItem;
}

export interface Layout {
  type: Types;
  format: Formats;
  columns: Columns;
  defaultColumns: Columns;
}

interface CourtsSettingsI {
  initial: () => Layout;
}

export class CourtsSettings extends Settings implements CourtsSettingsI {

  private buildDTOType(type) {
    const isCaseResult = type === Type.caseResult;
    if (isCaseResult) {
      return DTOType.caseResult;
    }

    return DTOType.appealState;
  }

  private buildDTOFormat(format) {
    const isNumber = format === Format.number;
    if (isNumber) {
      return Format.number;
    }

    return Format.percent;
  }

  private buildDTORenderKeys(columns) {
    const renderKeys = columns.itemsList.map((columnId) => {
      const column = columns.itemsMap.get(columnId);

      return {
        id: column.id,
        render: column.showColumn,
      };
    });

    return renderKeys;
  }

  updateSettings(currentLayout, settings) {
    const layout = cloneDeep(currentLayout);

    return merge(layout, settings);
  }

  initial(): Layout {
    return {
      type: Type.caseResult,
      format: Format.number,
      columns: {
        caseResult: {
          itemsList: [],
          itemsMap: Map(),
        },
        appealState: {
          itemsList: [],
          itemsMap: Map(),
        },
      },
      defaultColumns: {
        caseResult: {
          itemsList: [],
          itemsMap: Map(),
        },
        appealState: {
          itemsList: [],
          itemsMap: Map(),
        },
      },
    };
  }

  private prepareType(type) {
    const isCaseResult = type === DTOType.caseResult;
    if (isCaseResult) {
      return Type.caseResult;
    }

    return Type.appealState;
  }

  private prepareFormat(format) {
    const isNumber = format === Format.number;
    if (isNumber) {
      return Format.number;
    }

    return Format.percent;
  }

  private prepareItemsMap(presets, renderKeys) {
    let itemsMap = Map();

    renderKeys.forEach((column) => {
      const model = {
        ...presets[column.id],
        showColumn: column.render,
      };

      itemsMap = itemsMap.set(column.id, model);
    });

    return itemsMap;
  }

  private prepareItemsList(columns) {
    return columns.map((column) => column.id);
  }

  private prepareColumns(presets, renderKeys): Columns {
    const caseResult = {
      itemsList: this.prepareItemsList(renderKeys[DTOType.caseResult]),
      itemsMap: this.prepareItemsMap(presets, renderKeys[DTOType.caseResult]),
    } as ColumnItem;

    const appealState = {
      itemsList: this.prepareItemsList(renderKeys[DTOType.appealState]),
      itemsMap: this.prepareItemsMap(presets, renderKeys[DTOType.appealState]),
    } as ColumnItem;

    return {
      caseResult,
      appealState,
    };
  }

  public decorate(fetchSettings): Layout {
    const {
      presets,
      renderKeys,
      defaultRenderKeys,
    } = fetchSettings.layout;

    return {
      type: this.prepareType(fetchSettings.type),
      format: this.prepareFormat(fetchSettings.format),
      columns: this.prepareColumns(presets, renderKeys),
      defaultColumns: this.prepareColumns(presets, defaultRenderKeys),
    };
  }

  public undecorate(layout) {
    const type = this.buildDTOType(layout.type);
    const format = this.buildDTOFormat(layout.format);
    const renderKeys = {
      [DTOType.caseResult]: this.buildDTORenderKeys(layout.columns[Type.caseResult]),
      [DTOType.appealState]: this.buildDTORenderKeys(layout.columns[Type.appealState]),
    };

    return {
      format,
      type,
      renderKeys,
    };
  }

}

export const courtsSettings = new CourtsSettings();

export default courtsSettings;
