import { css } from 'styled-components';
import { styled } from '@components/styled';

interface Props {
  width: string;
  isFirst: boolean;
  isChild: boolean;
  active: boolean;
  disabled: boolean;
  theme: any;
}

const style: any = {};

style.List = styled.div`
  width: auto;
  max-height: 428px;
  overflow-y: auto;

  ${(props: Props) => props.width && css`
    width: ${props.width};
  `}
`;

style.Items = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

style.FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  ${(props: Props) => props.isFirst && css`
    borfder: none;
  `}
`;

style.FilterTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(166, 176, 186);
  padding: 0px 16px 13px;
`;

style.FilterItems = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  flex-wrap: wrap;
`;

style.FilterItem = styled.div<Props>`
  padding: 12px 16px 13px;
  position: relative;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;

    ${(props: Props) => props.disabled && css`
    background: transparent;
  `}
  }

  ${(props: Props) => props.isChild && css`
    padding-left: 44px;
  `}

  ${(props: Props) => props.active && css`
    background: #f6fafe;
  `}

  ${(props: Props) => props.disabled && css`
    background: transparent;
    cursor: default;
    color: ${props.theme.colors.silver};
  `}
`;

export default style;
