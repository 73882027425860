import { addSummaryReduce } from './addSummary.reduce';

import type { ActionData } from './addSummary.reduce';

const actionType = 'SUMMARY/ADD_SUMMARY';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addSummary = {
  actionType,
  createAction,
  reduce: addSummaryReduce,
};

export default addSummary;
