/* eslint-disable react/require-default-props */
import React, { PureComponent } from 'react';

import {
  Manager, Reference, Popper,
  // @ts-ignore
} from 'react-popper/lib/cjs';

// @ts-ignore
import type { PopperChildrenProps } from 'react-popper/lib/cjs';

import { RenderToLayer } from './renderToLayer';

import s from './popover.style';

export type Coords = {
  top: number,
  left: number,
  width: number,
  height: number,
  right: number,
  middle: number,
  bottom: number,
  center: number,
};

export type Placement =
  'top' |
  'right' |
  'bottom' |
  'left' |
  'top-start' |
  'top-end' |
  'right-start' |
  'right-end' |
  'bottom-start' |
  'bottom-end' |
  'left-start' |
  'left-end';

export interface PopoverProps {
  visible?: boolean;
  children?: React.ReactNode;
  anchor?: () => React.ReactNode;
  anchorRef?: HTMLElement;
  withShadow?: boolean;
  useAnchorWidth?: boolean;
  eventsEnabled?: boolean;
  level?: number;
  wide?: boolean;
  positionFixed?: boolean;
  coverAnchor?: boolean;
  width?: string | number;
  placement?: Placement;
  onClose?: () => void;
  onClickOutside?: () => void;
  useLayerForClickAway?: boolean;
  padding?: number;
  additionalWidth?: number;
  maxWidth?: number;
  replaceLeftToRight?: boolean;
  forceLeftRecalculate?: boolean;

  leftMargin?: number;
  usePortal?: boolean;
  modifiers?: any;
  style?: any;
}

export type PopoverState = {
  placement: Placement,
  animation: boolean,
};

export class Popover extends PureComponent<PopoverProps, PopoverState> {

  static defaultProps = {
    level: 0,
    visible: false,
    wide: false,
    withShadow: true,
    leftMargin: 64,
    forceLeftRecalculate: false,
    replaceLeftToRight: false,
    useAnchorWidth: false,
    width: 'auto',
    maxWidth: 320,
    padding: 40,
    additionalWidth: 0,
    eventsEnabled: true,
    positionFixed: true,
    placement: 'bottom-start',
    usePortal: false,
  };

  // @ts-ignore
  state = {
    placement: this.props.placement,
    animation: true,
  };

  // @ts-ignore
  anchor: HTMLElement | undefined;

  // @ts-ignore
  // eslint-disable-next-line react/no-unused-class-component-methods
  popover: HTMLElement | null;

  componentDidMount() {
    this.autoPosition();
  }

  componentDidUpdate() {
    this.autoPosition();
  }

  setAnimation = () => {
    setTimeout(() => this.setState({ animation: true }), 200);
  };

  /**
   * автопозиционирование в зависимости от размера поповера до края окна
   */
  autoPosition = () => {
    const {
      placement,
      maxWidth,
      padding,
      useAnchorWidth,
    } = this.props;

    if (!(this.anchor && !useAnchorWidth)) return;

    // @ts-ignore
    const basePlacement = placement.split('-')[0];
    // @ts-ignore
    const shiftvariation = placement.split('-')[1] || '';

    if (['top', 'bottom'].indexOf(basePlacement) === -1) return;

    // @ts-ignore
    const widthPopoverWithPadding = maxWidth + padding;
    const statePlacement = this.state.placement;

    if (shiftvariation === 'start') {
      const { left } = this.getAnchorPosition(this.anchor);
      // если значение превышает ширину окна справа
      if ((window.innerWidth - widthPopoverWithPadding - left) <= 0) {
        if (statePlacement !== `${basePlacement}-end`) {
          this.setState({ placement: `${basePlacement}-end` as Placement, animation: false }, this.setAnimation);
        }
      } else if (statePlacement !== placement) {
        // @ts-ignore
        this.setState({ placement, animation: false }, this.setAnimation);
      }
    } else if (shiftvariation === 'end') {
      const { right } = this.getAnchorPosition(this.anchor);
      // если значение превышает ширину окна слева
      if ((right - widthPopoverWithPadding) <= 0) {
        if (statePlacement !== `${basePlacement}-start`) {
          this.setState({ placement: `${basePlacement}-start` as Placement, animation: false }, this.setAnimation);
        }
      } else if (statePlacement !== placement) {
        // @ts-ignore
        this.setState({ placement, animation: false }, this.setAnimation);
      }
    }
  };

  getAnchorPosition = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    const a: Coords = {
      top: rect.top,
      left: rect.left,
      width: el.offsetWidth,
      height: el.offsetHeight,
      right: 0,
      middle: 0,
      bottom: 0,
      center: 0,
    };

    a.right = rect.right || a.left + a.width;
    a.middle = a.left + ((a.right - a.left) / 2);
    a.bottom = rect.bottom || a.top + a.height;
    a.center = a.top + ((a.bottom - a.top) / 2);

    return a;
  };

  onClosePopover = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  getReference = () => {
    const { anchor, anchorRef } = this.props;
    if (anchor) {
      return (
        // @ts-ignore
        // eslint-disable-next-line no-return-assign
        <Reference innerRef={(ref) => this.anchor = ref}>
          {anchor}
        </Reference>
      );
    }

    if (anchorRef) {
      this.anchor = anchorRef;
    }

    return null;
  };

  // @ts-ignore
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleInputKeyup = (e: KeyboardEvenleftMargint) => {
    const { onClose } = this.props;
    if (['Escape', 'Esc'].includes(e.key) && onClose) {
      onClose();
    }
  };

  getWidth = () => {
    const {
      useAnchorWidth,
      anchorRef,
      width,
      additionalWidth,
    } = this.props;

    if (useAnchorWidth) {
      const isSameWidth = anchorRef && width === Popover.defaultProps.width;
      if (isSameWidth) {
        if (additionalWidth) {
          // @ts-ignore
          return anchorRef.offsetWidth + additionalWidth;
        }

        // @ts-ignore
        return anchorRef.offsetWidth;
      }

      if (!!this.anchor && width === Popover.defaultProps.width) {
        // @ts-ignore
        return this.anchor.offsetWidth + additionalWidth;
      }
    }

    return width === Popover.defaultProps.width ? width : `${width}px`;
  };

  getAnchorHeight = () => {
    const { anchorRef } = this.props;
    if (anchorRef) {
      return anchorRef.offsetHeight;
    }

    if (this.anchor) {
      return this.anchor.offsetHeight;
    }

    return 0;
  };

  render() {
    const {
      children,
      visible,
      withShadow,
      modifiers,
      padding,
      eventsEnabled,
      positionFixed,
      replaceLeftToRight,
      anchorRef,
      onClickOutside,
      useLayerForClickAway,
      coverAnchor,
      usePortal,
    } = this.props;

    const { placement, animation } = this.state;
    // @ts-ignore
    const shiftvariation = placement.split('-')[1] || '';

    let axis = {};
    if (replaceLeftToRight) {
      axis = {
        y: (shiftvariation === 'start' ? 'right' : 'left'),
      };
    }

    const offset = coverAnchor ? { offset: { offset: `0, -${this.getAnchorHeight()}` } } : {};

    return (
      <Manager>
        {this.getReference()}
        {
          visible && (
            <Popper
              {...(anchorRef ? { referenceElement: anchorRef } : {})}
              positionFixed={positionFixed}
              eventsEnabled={eventsEnabled}
              // eslint-disable-next-line react/no-unused-class-component-methods
              innerRef={(ref: HTMLElement | null) => { this.popover = ref; }}
              placement={placement}
              modifiers={{
                preventOverflow: {
                  padding: 24,
                  priority: ['top', 'bottom'], // сдвиг относительно этих сторон, если не хватает места
                  boundariesElement: 'viewport',
                },
                computeStyle: {
                  gpuAcceleration: false,
                  ...axis,
                },
                flip: {
                  padding: {
                    bottom: padding,
                    top: padding,
                  },
                },
                ...offset,
                ...modifiers,
              }}
            >
              {(props: PopperChildrenProps) => {
                // eslint-disable-next-line
                const { ref, style, placement, outOfBoundaries } = props;
                if (typeof children === 'function') {
                  return children(props);
                }

                if (outOfBoundaries) this.onClosePopover();

                const shouldCalculatePopoverStyle = this.props.level && this.props.wide;
                if (shouldCalculatePopoverStyle) {
                  const defaultPadding = 8;
                  const wideWidth = 320;
                  const totalWidth = wideWidth + style.left + defaultPadding;

                  const overflow = totalWidth >= document.documentElement.clientWidth;

                  if (overflow || this.props.forceLeftRecalculate) {
                    style.left -= this.props.leftMargin;
                  }
                }

                return (
                  <RenderToLayer
                    onClickOutside={onClickOutside}
                    usePortal={usePortal}
                    useLayerForClickAway={useLayerForClickAway}
                    ignoreClickTo={this.anchor}
                  >
                    <s.Wrapper
                      ref={ref}
                      animation={animation}
                      style={{ ...style, width: this.getWidth() }}
                      data-placement={placement}
                      withShadow={withShadow}
                      className="fadeIn app-popover"
                    >
                      {children}
                    </s.Wrapper>
                  </RenderToLayer>
                );
              }}
            </Popper>
          )
        }
      </Manager>
    );
  }

}

export default Popover;
