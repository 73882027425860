import { Id } from './item';
import { StateBuilder } from './state';

export interface ActionData {
  id: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const removeFilterReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.smartFolders);
  newState.removeItem(action.data.id);
  const smartFolders = newState.getState();

  return {
    ...state,
    smartFolders,
  };
};

export default removeFilterReduce;
