import { styled } from '@components/styled';

const style: any = {};

style.Blocks = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px 0 16px 16px;
  min-height: 300px;
`;

style.BlocksEmpty = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;

  height: 100%;
  width: 100%;
`;

style.BlocksEmptyText = styled.p`
  color: rgb(108, 123, 137);
  margin: 0;
`;

style.Items = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 0 16px;

  :last-child {
    padding-bottom: 0;
  }
`;

style.LogicItem = styled.div`
  margin-right: 8px;
`;

style.FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

style.FilterTitle = styled.div`
  text-transform: uppercase;
  font: 11px Roboto-Medium, sans-serif;
  color: rgb(166, 176, 186);
  margin-bottom: 4px;
  line-height: 16px;
`;

style.FilterItems = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  flex-wrap: wrap;
`;

style.FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 10px 8px 12px;
  margin-top: 8px;
  line-height: 16px;

  position: relative;

  margin-right: 8px;

  border-radius: 2px;
  border-left-color: ${(props) => props.color};

  box-shadow: inset 0 0 0 1px rgb(223,226,230);

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 2;

    width: 4px;

    background: ${(props) => props.color};
  }
`;

export default style;
