import { Dispatch } from 'redux';

import {
  Id,
  Source,
} from '@router/path.rest';

import { addDocumentToPackage } from '../searchPackages/addDocumentToPackage';

import { httpAddDocumentsToPackage } from '../packages/addDocumentsToPackage.http';
import { httpGetPackageCount } from '../packages/getPackageCount.http';

import { fetchPackages } from '../packages/fetchPackages';
import { httpFetchPackages } from '../fetchPackages.http';

import { fetchDocumentsPackageCount } from './fetchDocumentsPackageCount';

export class AddDocumentsToPackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  private sourceId: Id = null;

  private readonly source: Source.package;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.source = Source.package;
  }

  public async invoke(params: { targetId: Id }) {
    this.sourceId = this.getState().packages.id;
    const { selected } = this.getState().packages.searchResult.itemsMap.get(params.targetId);
    this.dispatchAddToPackage(params.targetId);
    if (selected) {
      return;
    }

    await this.httpAddDocumentsToPackage(params);
    const packageCountResponse = await this.httpGetPackageCount();
    this.dispatchAddDocumentsToPackage(packageCountResponse);

    try {
      const packagesResponse = await httpFetchPackages({});
      this.dispatchFetchPackagesSucceed(packagesResponse);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchAddToPackage(packageId: number) {
    this.dispatch(addDocumentToPackage.createAction({ packageId }));
  }

  private dispatchAddDocumentsToPackage(response) {
    this.dispatch(fetchDocumentsPackageCount.createAction(response));
  }

  private async httpAddDocumentsToPackage(params) {
    const { selection } = this.getState().packages.documents;

    const ids = selection.ids.toArray().map((i) => i[0]);
    const requestParams = {
      targetId: params.targetId,
      source: this.source,
      sourceId: this.sourceId,
      documents: {
        rule: selection.rule,
        ids,
      },
    };

    try {
      await httpAddDocumentsToPackage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  // eslint-disable-next-line consistent-return
  private async httpGetPackageCount() {
    const requestParams = {
      source: this.source,
      sourceId: this.sourceId,
    };

    try {
      return await httpGetPackageCount(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchPackagesSucceed(result) {
    const actionData = {
      packages: result.packages,
    };

    this.dispatch(fetchPackages.createAction(actionData));
  }

}

export function addDocumentsToPackageThunk(dispatch, params) {
  const thunk = new AddDocumentsToPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default AddDocumentsToPackageThunk;
