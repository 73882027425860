import React, { useState } from 'react';

import { SettingsButton } from '@components/buttons/settingsButton';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { ItemMenu as ItemMenuFolders } from '../sidebar/folders/itemMenu';
import { ItemMenu as ItemMenuFilters } from '../sidebar/filters/itemMenu';

type Id = number;

interface SettingsPopoverProps {
  folderId: Id;
  filterId: Id;
  shouldRender: boolean;
  renderForFolders: boolean;
}

export const Settings = ({ folderId, filterId, shouldRender, renderForFolders }: SettingsPopoverProps) => {
  if (!shouldRender) {
    return null;
  }

  const [wide, setWide] = useState(false);
  const [level, setLevel] = useState(0);

  const handleLevelChange = (params: { wide: boolean; level: number }) => {
    setWide(params.wide);
    setLevel(params.level);
  };

  const renderMenu = (p) => {
    if (renderForFolders) {
      return <ItemMenuFolders id={folderId} {...p} onLevelChange={handleLevelChange} />;
    }

    return <ItemMenuFilters id={filterId} {...p} onLevelChange={handleLevelChange} />;
  };

  return (
    <BaseDropdown
      usePortal
      wide={wide}
      level={level}
      disabled={!shouldRender}
      forceLeftRecalculate
      onLevelChange={handleLevelChange}
      placement="bottom-end"
      renderInput={(p) => <SettingsButton {...p} disabled={!shouldRender} />}
      renderMenu={(p) => renderMenu(p)}
    />
  );
};

export default Settings;
