import styled from 'styled-components';

const style: any = {};

style.Search = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #f3f3f3;
`;

style.Workspace = styled.div`
  flex: 1;
  overflow: auto;
`;

style.RootContainer = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding: 16px 80px 16px 44px;
  min-width: 960px;
  max-width: 1152px;
  width: 100%;
`;

style.FilterContainer = styled.div`
  padding: 0;
`;

style.HistoryContainer = styled.div`
  padding: 55px 0 0;
`;

export default style;
