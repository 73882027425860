import { duplicateBlockReduce } from './duplicateBlock.reduce';
import { SEARCH_FORM_BLOCK } from '../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/DUPLICATE_BLOCK`;

const createAction = (data) => ({
  type: actionType,
  data,
});

export const duplicateBlock = {
  actionType,
  createAction,
  reduce: duplicateBlockReduce,
};

export default duplicateBlock;
