import { resetProjectsReduce } from './resetProjects.reduce';

const actionType = 'REGULATION/RESET_PROJECTS';

const createAction = () => ({
  type: actionType,
});

export const resetProjects = {
  actionType,
  createAction,
  reduce: resetProjectsReduce,
};

export default resetProjects;
