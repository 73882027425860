import styled from 'styled-components';

const style: any = {};

style.DatePickerWrapper = styled.div`

  display: flex;
  flex: 1;

  .react-datepicker-wrapper {
    display: flex;
    flex: 1;
  }

  .react-datepicker {
    box-shadow:  0 0 2px 0 rgba(0, 0, 0, 0.15),
    0 2px 8px 0 rgba(0, 0, 0, 0.15);
    padding: 4px;
    border: none;
    border-radius: 0;

    * {
      font-weight: normal;
    }
  }

  .react-datepicker__header {
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    height: 84px;
  }

  .react-datepicker__current-month {
    height: 32px;
    margin-top: 4px;
    margin-bottom: 8px;
    font-family: Roboto-Medium;
    font-size: 13px;
    color: #22313f;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__navigation {
    width: 32px;
    height: 32px;
    margin-top: -3px;
    border: none;

    transition: 0.3s ease-in-out;

    &--previous {
      left: 8px;
      background: url("/images/icon_chevron_left.svg") no-repeat center #fff;

      &:hover {
        background: url("/images/icon_chevron_left.svg") no-repeat center rgb(246, 250, 254);
      }
    }

    &--next {
      right: 8px;
      background: url("/images/icon_chevron_right.svg") no-repeat center #fff;

      &:hover {
        background: url("/images/icon_chevron_right.svg") no-repeat center rgb(246, 250, 254);
      }
    }
  }

  .react-datepicker__day-names {
    margin-top: 15px;
  }

  .react-datepicker__day-name {
    width: 32px;
    height: 32px;
    font-family: Roboto-Medium;
    font-size: 11px;
    letter-spacing: 0.2px;
    margin: 4px;
    color: #a6b0ba;
    text-transform: uppercase;
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    margin: 4px;

    color: #22313f;

    border-radius: 0;

    font-family: Roboto-Regular;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-radius: 0;
      background: rgb(246, 250, 254)
    }

    &--today {
      background-color: transparent;
      font-weight: normal;
      font-family: Roboto-Bold !important;
      color: #4183d7;
    }

    &--keyboard-selected {
      background: transparent;
      font-weight: normal;
      font-family: Roboto-Regular;
    }

    &--selected {
      border-radius: 0;
      background: #4183d7;
      color: #ffffff;

      &:hover {
        border-radius: 0;
        background: #4183d7;
      }
    }
  }

  .react-datepicker-popper {
    transform: translate3d(0px, 30px, 0px) !important;
    z-index: 999;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background: white;
  }

  .react-datepicker__week {
    display: flex;
    flex-direction: row;
  }

  .react-datepicker__day--outside-month {
    color: #d2d7dc;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
  }
`;

export default style;
