import { Source } from '@router/path.rest';

export interface Bookmark {
  id: string;
  title: string;
  text: string;
  source: Source.search | Source.package | Source.authorPackage | Source.subscription;
  sourceId: number;
  documentId: number;
  paragraphId: number;
  path: string;
  commentary: string;
}

type QueueItem = Bookmark & { id: string };

interface State {
  queue: QueueItem[];
  loading: boolean;
  downloading: boolean;
}

export class StateBuilder {

  queue: QueueItem[] = [];

  loading: boolean = true;

  downloading: boolean = false;

  static createState(): State {
    return {
      queue: [],
      loading: true,
      downloading: false,
    };
  }

  public createState(state: State) {
    this.queue = [...state.queue];
    this.loading = state.loading;
    this.downloading = state.downloading;
  }

  public cleanQueue() {
    this.queue = [];
  }

  public setQueue(queue: Bookmark[]) {
    this.queue = queue;
  }

  public addBookmark(item: Bookmark) {
    this.queue = [...this.queue, item];
  }

  public deleteBookmark(id) {
    this.queue = this.queue.filter((item) => item.id !== id);
  }

  public addCommentary(id, commentary) {
    this.queue = this.queue.map((item) => {
      if (item.id === id) {
        const newItem = { ...item, commentary };

        return newItem;
      }

      return item;
    });
  }

  public setLoading(value: boolean) {
    this.loading = value;
  }

  public setDownloading(value: boolean) {
    this.downloading = value;
  }

  public getState(): State {
    return {
      queue: this.queue,
      loading: this.loading,
      downloading: this.downloading,
    };
  }

}

export default StateBuilder;
