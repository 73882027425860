import styled from 'styled-components';

const style: any = {};

style.Root = styled.a`
  height: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

export default style;
