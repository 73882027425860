import { fetchDocumentsPackageCountReduce } from './fetchDocumentsPackageCount.reduce';

import type { ActionData } from './fetchDocumentsPackageCount.reduce';

const actionType = 'SEARCH/RESULT/FETCH_DOCUMENTS__PACKAGE_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDocumentsPackageCount = {
  actionType,
  createAction,
  reduce: fetchDocumentsPackageCountReduce,
};

export default fetchDocumentsPackageCount;
