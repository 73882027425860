import { connect } from 'react-redux';

import { NoteTab } from '@pages/regulation/workspace/projects/preview/noteTab';

import { getPreview } from './preview.connect';

const mapStateToProps = (state, ownProps) => {
  const preview = getPreview(ownProps.id, state.regulation.preview);

  return {
    body: preview.note.body,
    loading: preview.note.loading,
  };
};

export const NoteTabConnect = connect(
  mapStateToProps,
)(NoteTab);

export default NoteTabConnect;
