import { DateBlock } from '@components/filters/date/logic/date';

import {
  Filters,
  FilterType,
  StateBuilder as FiltersStateBuilder,
} from '../toolbar/redux/state';

interface Action {
  type: string;
  data: Filters;
}

export const fetchFiltersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const filters = new FiltersStateBuilder({
    filters: action.data,
    loading: false,
  });

  const date = new DateBlock();
  date.createFromScratch(action.data.date.data);
  filters.updateFilter(FilterType.date, date.decorate());

  return {
    ...state,
    filters: filters.getState(),
  };
};

export default fetchFiltersReduce;
