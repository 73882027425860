import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { theme } from '@components/styled';
import { Notifier } from '@components/notification';
import { ChatBot } from '@components/chatBot';
import ChatStyle from '@components/chatBot/chat.style';
import MarkersGlobalStyles from '@pages/document/workspace/markers.global.style';

import { Routes } from '@router/routes';
import { store } from '@redux/store';

import GlobalStyle from './root.style';

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        { Routes }
      </Router>
      <ChatBot />
      <Notifier />
      <GlobalStyle />
      <MarkersGlobalStyles />
      <ChatStyle />
    </ThemeProvider>
  </Provider>
);

export default App;
