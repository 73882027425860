import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  height: 100%;
  padding: 8px 0;
  overflow-y: auto;
`;

style.EmptyTitle = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props: Props) => props.theme.colors.stone};
`;

export default style;
