import {
  styled,
  css,
} from '@components/styled';

const style: any = {};

interface Props {
  hovered: boolean;
  theme: any;
}

style.Root = styled.div`
  position: relative;
  font: 13px Roboto-Regular;
  line-height: 16px;
  padding: 12px 0 5px;
  display: flex;

  ${(props: Props) => props.hovered && css`
    background: ${props.theme.colors.lightBlue};

    a {
      text-decoration: underline;
    }

    cursor: default;
  `}

  border-bottom: 1px solid #dfe2e6;

  &:last-of-type {
    border-bottom: none;
  }
`;

style.Item = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

style.Title = styled.a`
  color: ${(props) => props.theme.colors.blue};
  text-decoration: none;
  padding: 0 16px;
`;

style.Text = styled.div`
  margin-bottom: 4px;
  padding: 0 42px 0 16px;
`;

style.Menu = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export default style;
