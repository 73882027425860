import {
  styled,
  css,
} from '@components/styled';

interface Props {
  theme: any;
  fullWidth: boolean;
  linkLike: boolean;
  disabled: boolean;
}

const style: any = {};

export const iconButton = css`
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
`;

export const hover = css`
  transition: all .3s ease-out;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(65, 131, 215, 0.07);
    ${(props: { disabled: boolean }) => props.disabled && css`
      background-color: transparent;
    `}
  }

  &:active {
    background-color: rgba(65, 131, 215, 0.12);
    ${(props: { disabled: boolean }) => props.disabled && css`
      background-color: transparent;
    `}
  }
`;

style.Root = styled.button<Props>`
  height: 48px;
  padding: 0 24px;
  border: 0;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  outline: none;
  cursor: pointer;

  ${(props: Props) => props.fullWidth && css`
    width: 100%;
  `}

  ${(props: Props) => props.linkLike && css`
    background-color: transparent;
  `}

  &:focus {
    background-color: #4183d7;

    ${(props: Props) => props.linkLike && css`
      background-color: transparent;
    `}
  }

  &:hover {
    background-color: #4183d7;

    ${(props: Props) => props.linkLike && css`
      background-color: transparent;
    `}
  }

  &:active {
    background-color: #376fb6;

    ${(props: Props) => props.linkLike && css`
      background-color: transparent;
    `}
  }

  ${(props: Props) => props.disabled && css`
    cursor: default;
  `}
`;

style.Text = styled.span<Props>`
  font-family: 'Roboto-Medium';
  text-transform: uppercase;
  position: relative;
  color: ${(props) => props.theme.colors.white};

  ${(props: Props) => props.disabled && css`
    color: rgba(255,255,255, 0.5);
  `}

  ${(props: Props) => props.linkLike && css`
    color: ${props.theme.colors.blue};
  `}
`;

export default style;
