import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { UserRoleType } from '@pages/userPages/redux/state';

import { Information } from './information';

import { AttributesConnect } from './attributes.connect';
import { RelationsConnect } from './relations.connect';
import { ChronologyConnect } from './chronology.connect';
import { SimilarConnect } from './similar.connect';

import { setActiveTab } from './redux/setActiveTab';

import { TabId } from '../redux/state';

const mapStateToProps = (state) => {
  const tabMap = new Map();
  tabMap.set(TabId.attributes, AttributesConnect);
  tabMap.set(TabId.relations, RelationsConnect);
  const isReal = state.currentUser.role !== UserRoleType.guest;
  if (isReal) {
    tabMap.set(TabId.chronology, ChronologyConnect);
    tabMap.set(TabId.similar, SimilarConnect);
  }

  const { activeTabId } = state.document.layout.sidebar;

  const tabs = [
    {
      id: 'attributes',
      title: 'Атрибуты',
    },
    {
      id: 'relations',
      title: 'Нормы',
    },
  ];

  if (isReal) {
    tabs.push(
      {
        id: TabId.chronology,
        title: 'Хронология',
      },
    );

    tabs.push(
      {
        id: TabId.similar,
        title: 'Похожие',
      },
    );
  }

  return {
    Tab: tabMap.get(activeTabId),
    activeTabId,
    isReal,
    tabs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateActiveTab(tabId) {
    dispatch(setActiveTab.createAction({ tabId }));
    if (tabId === TabId.similar) {
      sendAmplitudeData('document: open similar tab');
      Userpilot.track('document: open similar tab');
    }

    if (tabId === TabId.chronology) {
      sendAmplitudeData('document: open chronology tab');
      Userpilot.track('document: open chronology tab');
    }
  },
});

export const InformationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Information);

export default InformationConnect;
