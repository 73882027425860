import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  padding: 15px 64px 16px;
`;

style.Separator = styled.div`
  height: 16px;
`;

export default style;
