export class Broadcaster {

  private static instance: Broadcaster;

  private channel = new BroadcastChannel('caselookChannel');

  private callbacks = new Map();

  constructor() {
    this.registerMessageHandler();
  }

  private registerMessageHandler = () => {
    if (!this.channel) {
      return;
    }

    this.channel.onmessage = (event) => {
      this.logMessage(event);
    };
  };

  static getInstance(): Broadcaster {
    if (!Broadcaster.instance) {
      Broadcaster.instance = new Broadcaster();
    }

    return Broadcaster.instance;
  }

  public setCallback(name, callback) {
    this.callbacks.set(name, callback);
  }

  public postMessage(message: Record<string, any>) {
    this.channel.postMessage(message);
  }

  public logMessage(event) {
    this.callbacks.get(event.data.type)(event.data);
  }

}

export default Broadcaster;
