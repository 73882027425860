import { StateBuilder } from '../../../sidebar/redux/state';

export interface ActionData {
  id: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const removeSubscriptionReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.subscriptions);
  newState.removeItem(action.data.id);
  const subscriptions = newState.getState();

  return {
    ...state,
    subscriptions,
  };
};

export default removeSubscriptionReduce;
