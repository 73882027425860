import { Dispatch } from 'redux';

import { httpFetchNotifications } from './fetchNotifications.http';

import { fetchNotifications } from './fetchNotifications';

export class FetchNotificationsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    const state = this.getState();
    const {
      page,
      totalPages,
    } = state.notificationsBuffer;

    if (page >= totalPages) {
      return;
    }

    const nextPage = page + 1;

    const response = await this.fetchNotifications(nextPage);
    await this.dispatchFetchNotificatioins(response);
  }

  private async fetchNotifications(page) {
    try {
      const response = await httpFetchNotifications({ page });

      return response;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async dispatchFetchNotificatioins(response) {
    const data = {
      notifications: response.result,
      hasUnread: response.hasUnread,
      page: response.page,
      total_pages: response.total_pages,
    };

    this.dispatch(fetchNotifications.createAction(data));
  }

}

export async function fetchNotificationsThunk(dispatch) {
  const thunk = new FetchNotificationsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke();
}

export default FetchNotificationsThunk;
