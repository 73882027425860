import {
  PreviousCourtBlock, PreviousCourtBlockData,
} from '../../form/filterBlocks/previousCourt/redux/previousCourt';

export abstract class PreviousCourtBlockHistory extends PreviousCourtBlock {

  static transform(data: PreviousCourtBlockData): string {
    return data.title || '';
  }

}

export default PreviousCourtBlockHistory;
