import { StateBuilder } from './state';

export interface PackageDTO {
  id: number;
  name: string;
  documents_count: number;
}

export interface Package {
  id: number;
  title: string;
  documentsCount: number;
}

export interface ActionData {
  packages: PackageDTO[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchPackagesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const initialState = {
    ...StateBuilder.createState(),
    activeItemId: state.packages.activeItemId,
  };

  const newState = new StateBuilder(initialState);
  newState.addPackages(action.data.packages);
  const packages = {
    ...newState.getState(),
    loading: false,
  };

  return {
    ...state,
    packages,
  };
};

export default fetchPackagesReduce;
