export function getDays(params: { year: number, month: number }) {
  return new Date(params.year, params.month, 0).getDate();
}

export function getCurrentDay() {
  return new Date().getDate();
}

export function getCurrentMonth() {
  return new Date().getMonth();
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getFirstDayNumber(params: { year: number, month: number }) {
  const dayNumber = new Date(params.year, params.month).getDay();

  return dayNumber || 7;
}

export function getPreviousMonth(year: number, month: number) {
  if (!month) {
    return {
      year: year - 1,
      month: 11,
    };
  }

  return {
    year,
    month: month - 1,
  };
}

export function getNextMonth(year: number, month: number) {
  if (month === 11) {
    return {
      year: year + 1,
      month: 0,
    };
  }

  return {
    year,
    month: month + 1,
  };
}

export function getLeadingZeroDate(date: number) {
  return (`0${date}`).slice(-2);
}

export function isEqualDates(firstDate, secondDate) {
  let yearIsEqual = false;
  let monthIsEqual = false;
  let dayIsEqual = false;
  if (firstDate.year === secondDate.year) {
    yearIsEqual = true;
  }

  if (firstDate.month === secondDate.month) {
    monthIsEqual = true;
  }

  if (firstDate.day === secondDate.day) {
    dayIsEqual = true;
  }

  if (yearIsEqual && monthIsEqual && dayIsEqual) {
    return true;
  }

  return false;
}

export default getDays;
