import { fetchBookmarksReduce } from './fetchBookmarks.reduce';

import type { ActionData } from './fetchBookmarks.reduce';

const actionType = 'BOOKMARKS/FETCH_SUCCESS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchBookmarks = {
  actionType,
  createAction,
  reduce: fetchBookmarksReduce,
};

export default fetchBookmarks;
