import http from '@http';
import { RESTPath } from '@router/path.rest';

export interface RequestParams {
  id: number;
  visited: boolean;
}

export interface SetVisitetResponseDTO {
  result: boolean;
}

export function httpSetNotificationVisited(requestParams: RequestParams): Promise<SetVisitetResponseDTO> {
  const request = http.put({
    url: RESTPath.notifications.setVisited(requestParams.id),
    data: {
      visited: requestParams.visited,
    },
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSetNotificationVisited;
