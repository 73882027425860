import React from 'react';

import { Loader } from '@components/loader';

import s from './licenseCassette.style';

interface ItemProps {
  title: string;
  value: string;
  alert: boolean;
}

const Item: React.FC<ItemProps> = (props: ItemProps) => (
  <s.Item>
    <s.ItemTitle>{props.title}</s.ItemTitle>
    <s.ItemValue alert={props.alert}>{props.value}</s.ItemValue>
  </s.Item>
);

interface LicenseCassetteProps {
  shouldRenderPaymentControl: boolean;
  items: ItemProps[];
  paymentInProgress?: boolean;
}

export class LicenseCassette extends React.PureComponent<LicenseCassetteProps> {

  static defaultProps = {
    paymentInProgress: false,
  };

  handleHeaderActionClick = () => {
    window.location.href = '#/paymentselect';
  };

  renderItem = (item) => <Item key={item.id} {...item} />;

  renderHeaderTitle = () => <s.HeaderTitle>Лицензия</s.HeaderTitle>;

  renderHeaderAction() {
    if (!this.props.shouldRenderPaymentControl) {
      return null;
    }

    if (this.props.paymentInProgress) {
      return (
        <s.HeaderLodaer>
          <s.Loader>
            <Loader size={16} />
          </s.Loader>
          <s.HeaderLoaderTitle>Проверка статуса платежа</s.HeaderLoaderTitle>
        </s.HeaderLodaer>
      );
    }

    return (
      <s.HeaderAction
        onClick={this.handleHeaderActionClick}
      >
        Оплатить
      </s.HeaderAction>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Header>
          {this.renderHeaderTitle()}
          <s.HeaderPayment>
            {this.renderHeaderAction()}
          </s.HeaderPayment>
        </s.Header>
        <s.Info>
          {this.props.items.map(this.renderItem)}
        </s.Info>
      </s.Root>
    );
  }

}

export default LicenseCassette;
