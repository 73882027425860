import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { ListItem } from '@components/menu/listItem';

import { CreatePersonalPackageConnect } from './createPersonalPackage.connect';
import { Chat } from './chat';

import s from './menu.style';

interface MainLevel {
  title: string;
  onClick: () => void;
  hasArrow: boolean;
}

interface Props {
  onLevelChange: (params: { wide: boolean; level: number }) => void;
}

enum ViewTypes {
  create = 'CREATE',
  chat = 'CHAT',
  default = '',
}

enum Level {
  main,
  second,
}

export class Menu extends React.PureComponent<Props> {

  state = {
    view: {
      type: ViewTypes.default,
      level: Level.main,
    },
  };

  setView(type: ViewTypes, level: number) {
    this.setState({
      view: {
        type,
        level,
      },
    });
  }

  handleSetViewToMain = () => {
    this.props.onLevelChange({ wide: false, level: Level.main });
    this.setView(ViewTypes.default, Level.main);
  };

  handleSetCreateLevelToModify = () => {
    sendAmplitudeData('practice:lawyer');
    Userpilot.track('practice:lawyer');
    this.props.onLevelChange({ wide: true, level: Level.second });
    this.setView(ViewTypes.create, Level.second);
  };

  handleSetChatLevelToModify = () => {
    sendAmplitudeData('AI:practice', { AI: 'Онлайн-юрист / Caselook', type: 'Открыт лейаут' });
    Userpilot.track('AI:practice');
    this.props.onLevelChange({ wide: true, level: Level.second });
    this.setView(ViewTypes.chat, Level.second);
  };

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  renderMainLevel(renderData: MainLevel) {
    const firstLevel = this.state.view.level === Level.main;

    if (!firstLevel) {
      return null;
    }

    return <ListItem {...renderData} />;
  }

  renderMainCreate() {
    const data = {
      title: 'Соберем практику за вас',
      onClick: this.handleSetCreateLevelToModify,
      hasArrow: true,
    };

    return this.renderMainLevel(data);
  }

  renderMainChat() {
    const data = {
      title: 'Спросить AI',
      onClick: this.handleSetChatLevelToModify,
      hasArrow: true,
    };

    return this.renderMainLevel(data);
  }

  renderModifyCreateLevel() {
    return (
      <CreatePersonalPackageConnect
        onGoBack={this.handleSetViewToMain}
      />
    );
  }

  renderModifyChatLevel() {
    return (
      <Chat
        onGoBack={this.handleSetViewToMain}
      />
    );
  }

  renderSecondLevel() {
    const isCreate = this.state.view.type === ViewTypes.create;
    if (isCreate) {
      return this.renderModifyCreateLevel();
    }

    const isChat = this.state.view.type === ViewTypes.chat;
    if (isChat) {
      return this.renderModifyChatLevel();
    }

    return null;
  }

  renderLevels() {
    const firstLevel = this.state.view.level === Level.main;
    if (firstLevel) {
      return (
        <s.List>
          {this.renderMainCreate()}
          {this.renderMainChat()}
        </s.List>
      );
    }

    return (
      <s.Edit>
        {this.renderSecondLevel()}
      </s.Edit>
    );
  }

  render() {
    return (
      <s.Root onClick={this.stopPropagation}>
        {this.renderLevels()}
      </s.Root>
    );
  }

}

export default Menu;
