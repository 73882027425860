import { RESTPath } from '@router/path.rest';
import http from '@http';

import {
  Id,
  Phase,
} from './state';

export interface PhasesDTO {
  result: Phase[];
}

export function httpFetchPhases(projectId: Id): Promise<PhasesDTO> {
  const url = RESTPath.regulation.projects.preview.phases(projectId);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchPhases;
