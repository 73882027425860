interface Scope {
  id: string;
  title: string;
}

type Value = string[];

export interface DateBlockData {
  value: Value;
  scope: Scope;
}

export interface ActionData {
  data: DateBlockData,
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateFilterReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    history: {
      ...state.history,
      filters: action.data,
    },
  };
};

export default updateFilterReduce;
