import { Dispatch } from 'redux';

import { caseType } from '../../redux/state';

import { fetchCaseType } from './fetchCaseType';

import { httpFetchSearchAnalyticCaseType } from './fetchCaseType.http';

export class FetchSearchCaseTypeThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
    dispatch((_, getState) => { this.getState = getState; });
  }

  public async invoke(searchId: number) {
    const { shouldLoadInitialData } = this.getState().searchResult.analysis.itemsMap[caseType.getId()];
    if (!shouldLoadInitialData) {
      return;
    }

    try {
      const searchResult = await httpFetchSearchAnalyticCaseType(searchId);

      this.dispatchGetTypeIdSucceed(searchId, searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetTypeIdSucceed(searchId, searchResult) {
    const actionData = {
      searchId,
      ...searchResult,
    };

    this.dispatch(fetchCaseType.createAction(actionData));
  }

}

export default FetchSearchCaseTypeThunk;
