import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpFetchDocumentType() {
  const request = {
    url: RESTPath.search.bootstrap.documentType,
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(http.parseResponse);
}

export default httpFetchDocumentType;
