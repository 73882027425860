/* eslint-disable global-require */

let createStore;
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  createStore = require('./store.prod').default;
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  createStore = require('./store.dev').default;
}

export const store = createStore();
export default store;
