import {
  ClaimAmountBlock, ClaimAmountBlockData,
} from '../../form/filterBlocks/claimAmount/redux/claimAmount';

export abstract class ClaimAmountBlockHistory extends ClaimAmountBlock {

  static transform({ value }: ClaimAmountBlockData): string {
    let result = '';

    const from = value[0];
    const to = value[1];

    let prefix = 'От';
    let middle = 'до';
    if (!from) {
      prefix = '';
      middle = 'До';
    }

    if (!to) {
      middle = '';
    }

    result = `${prefix} ${from} ${middle} ${to}`;

    return result.trim();
  }

}

export default ClaimAmountBlockHistory;
