import React from 'react';

import s from './header.style';

interface HeaderProps {
  title: string;
  menu: JSX.Element;
  children: JSX.Element[] | JSX.Element;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.Root>
        <s.Right>
          <s.Menu>
            {this.props.menu}
          </s.Menu>
          <s.Title>{this.props.title}</s.Title>
        </s.Right>
        <s.Left>
          <s.Actions>
            {this.props.children}
          </s.Actions>
        </s.Left>
      </s.Root>
    );
  }

}

export default Header;
