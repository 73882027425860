export const createPackageStartReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    searchResult: {
      ...state.searchResult,
      addingPackage: true,
    },
  };
};

export default createPackageStartReduce;
