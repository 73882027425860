import { connect } from 'react-redux';

import { UserRoleType } from '@pages/userPages/redux/state';
import { isSelected } from '@pages/searchResult/documents/list/item.connect';
import { toggleItemSelect } from '../redux/toggleItemSelect';
import { Item } from './item';

function prepareColumns(initialData, settings) {
  const columns = [];

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      columns.push({
        id: column,
        data: initialData[column] || '',
      });
    }
  });

  return columns;
}

const mapStateToProps = (state, ownProps) => {
  const {
    currentPageNumber,
    pages,
    selection,
    showCommentary,
  } = state.authorPackages.documents;

  const { initialData } = pages[currentPageNumber].itemsMap.get(ownProps.id);
  const item = pages[currentPageNumber].itemsMap.get(ownProps.id);

  const settings = state.authorPackages.documents.layout.tableView.columns;
  const columns = prepareColumns(initialData, settings);

  return {
    item,
    columns,
    showCommentary,
    isReal: state.currentUser.role !== UserRoleType.guest,
    selected: isSelected(ownProps.id, selection),
    sourceId: state.authorPackages.packages.activeItemId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onItemSelect(id: number) {
    dispatch(toggleItemSelect.createAction({ id }));
  },
});

export const ItemConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
