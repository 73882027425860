import {
  css,
  styled,
} from '@components/styled';

interface Props {
  theme: Record<any, any>;
  isAvatarHeightDominant: boolean;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor}
`;

style.Photo = styled.div`
  width: 32px;
  height: 32px;
  margin: 16px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;

  > img {
    height: 100%;
    width: auto;

    ${(props: Props) => props.isAvatarHeightDominant && css`
      height: auto;
      width: 100%;
    `}
  }
`;

style.AvatarIcon = styled.div`
  width: 32px;
  height: 32px;
  background: #f3f4f5;

  path {
    transform: scale(0.33);
  }
`;

style.Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  line-height: 16px;
  margin-right: 32px;
`;

style.Name = styled.div`
  font-size: 13px;
`;

style.Email = styled.div`
  font-size: 11px;
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.Status = styled.div`
  flex-shrink: 0;
  font-size: 13px;
  width: 160px;
  color: ${(props: Props) => props.theme.colors.silver};
`;

style.Familiarized = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 160px;
  margin-left: 16px;
  height: 56px;
`;

style.FamiliarizedIcon = styled.div`
  margin-right: 8px;
`;

style.IconCheck = styled.div`
  polygon {
    color: green;
  }
;`;

style.IconUncheck = styled.div`
  polygon {
    color: red;
  }
;`;

style.FamiliarizedTitle = styled.div``;

export default style;
