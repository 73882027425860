import { decreasePackageCountReduce } from './decreasePackageCount.reduce';

const actionType = 'DOCUMENT/DECREASE_PACKAGE_COUNT';

const createAction = () => ({
  type: actionType,
});

export const decreasePackageCount = {
  actionType,
  createAction,
  reduce: decreasePackageCountReduce,
};

export default decreasePackageCount;
