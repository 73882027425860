import { fetchPhasesReduce } from './fetchPhases.reduce';

import type { ActionData } from './fetchPhases.reduce';

const actionType = 'REGULATION/PREVIEW/FETCH_PHASES';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchPhases = {
  actionType,
  createAction,
  reduce: fetchPhasesReduce,
};

export default fetchPhases;
