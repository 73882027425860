import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Sidebar } from './sidebar';

const mapStateToProps = (state) => {
  const { itemsList, loading, activeItemId } = state.subscriptions.subscriptions;
  const hasSubscriptions = !isEmpty(itemsList);

  return {
    loading,
    activeItemId,
    hasSubscriptions,
  };
};

export const SidebarConnect = connect(
  mapStateToProps,
)(Sidebar);

export default SidebarConnect;
