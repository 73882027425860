import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  position: relative;
  padding: 0 64px 24px 64px;
`;

style.Title = styled.div`
  font: 15px Roboto-Bold;
`;

style.Content = styled.div``;

export default style;
