import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { httpLogout } from '../http/logout.http';

import { logout } from './logout';

class LogoutThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    sendAmplitudeData('logout');
    Userpilot.track('logout');
    await this.httpLogout();
    this.dispatchLogout();
  }

  private dispatchLogout() {
    this.dispatch(logout.createAction());
  }

  private async httpLogout() {
    try {
      await httpLogout();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

}

export function logoutThunk(dispatch) {
  const thunk = new LogoutThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default LogoutThunk;
