import { Id } from './item';
import { StateBuilder } from './state';

export interface ActionData {
  id: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const setPreviewIdReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state);
  newState.setPreviewId(action.data.id);

  return {
    ...newState.getState(),
  };
};

export default setPreviewIdReduce;
