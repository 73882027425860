import { connect } from 'react-redux';

import { RoutePath } from '@router/path';

import { CreateNewPasswordThunk } from './createNewPassword.thunk';
import { CreateNewPassword } from './createNewPassword';

const mapDispatchToProps = () => ({
  async onSend(params) {
    const newPasswordThunk = new CreateNewPasswordThunk();
    const result = await newPasswordThunk.invoke(params);

    if (result.result) {
      window.location.href = `#${RoutePath.createNewPasswordSuccess}`;
    }
  },
  async onVerifyCode(params) {
    const createNewPasswordThunk = new CreateNewPasswordThunk();
    await createNewPasswordThunk.verifyCode(params);
  },
});

export const CreateNewPasswordConnect = connect(
  null,
  mapDispatchToProps,
)(CreateNewPassword);

export default CreateNewPasswordConnect;
