import { connect } from 'react-redux';

import { AddTag } from '@components/addTag/addTag';

import {
  buildList,
  prepareTag,
} from '@components/addTag/utils';

import { setTagThunk } from '../../../redux/documents/setTag.thunk';

function getActiveTagId(documents, documentId) {
  return documents.pages[documents.currentPageNumber].itemsMap.get(documentId).tag.id;
}

const mapStateToProps = (state, ownProps) => {
  const tag = getActiveTagId(state.packages.documents, ownProps.documentId);
  const currentTag = prepareTag(tag);

  return {
    source: 'package',
    currentTag,
    tags: buildList(state.packages.presets.tags, currentTag),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectTag(tagId: string) {
    const documentId = ownProps.documentId;
    const params = {
      documentId,
      tagId,
    };

    setTagThunk(dispatch, params);
  },
});

export const AddTagConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTag);

export default AddTagConnect;
