import { Dispatch } from 'redux';

import { fetchHistory } from '@pages/search/history/redux/fetchHistory';
import { selectHistoryItem } from '@pages/search/history/redux/selectHistoryItem';

import { httpFetchFilters } from './fetchFilters.http';

export class FetchFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id?: number }) {
    const result = await this.httpFetchFilters(params.id);
    await this.dispatchFilters(result);
  }

  private async httpFetchFilters(id) {
    const state = this.getState().subscriptions;
    const subscriptionId = id || state.subscriptions.activeItemId;

    const requestParams = {
      subscriptionId,
    };

    try {
      const result = await httpFetchFilters(requestParams);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFilters(params) {
    this.dispatch(fetchHistory.createAction(params));

    const id = params.history[0] ? params.history[0].id : null;
    this.dispatch(selectHistoryItem.createAction({ id }));
  }

}

export function fetchFiltersThunk(dispatch, params) {
  const thunk = new FetchFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default fetchFiltersThunk;
