import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { HistoryProps } from '../../searchHistory';

import { PaginationConnect } from '../pagination/pagination.connect';

import { Header } from './header';

import { RemoveHistoryButton } from './removeHistoryButton';

import s from './list.styles';

type SelectedIdType = number;
type HandleType = (id: number) => void;

export interface ListProps {
  data: HistoryProps[]
  selectedId: SelectedIdType
  onRowClick: HandleType
  onRemoveHistoryItem: HandleType
}

interface RenderHistoryProps {
  filters: HistoryProps[],
  onRowClick: HandleType,
  onRemoveHistoryItem: HandleType,
  selectedId: SelectedIdType
}

interface RenderListProps {
  onRowClick: HandleType,
  onRemoveHistoryItem: HandleType,
  selectedId: SelectedIdType,
  filter: HistoryProps,
}

const renderItem = (filters: string[]) => (filters.map((filter, index) => {
  if (index === 1 && !filters[index + 1]) {
    return null;
  }

  return (
    <s.FilterItem key={filter}>
      {filter}
    </s.FilterItem>
  );
}));

type Filter = string[];

const renderSmartFilter = (smartFilter) => {
  if (!smartFilter) {
    return null;
  }

  const iconPath = iconsPath.get('smartSmallPoints');

  return (
    <s.Smart>
      <s.Icon>
        <Icon points={iconPath} color="#673ab7" />
      </s.Icon>
      <s.SmartText>
        <s.SmartTitle>
          AI-ассистент
        </s.SmartTitle>
        <s.SmartValue>
          {`= ${smartFilter.query}`}
        </s.SmartValue>
      </s.SmartText>
    </s.Smart>
  );
};

const renderRowItems = (filters: Filter[]) => filters.map((filter) => (
  <s.RowItem>
    {renderItem(filter)}
  </s.RowItem>
));

const renderEmptyBlock = () => {
  const emptyText = 'Здесь будут сохраняться ваши поисковые запросы';

  return (
    <s.EmptyContent>
      {emptyText}
    </s.EmptyContent>
  );
};

const renderList = ({
  onRowClick,
  onRemoveHistoryItem,
  selectedId,
}: RenderListProps, filter) => {
  const disable = selectedId === filter.id || filter.isRemoving;

  const onClick = disable ?
    null :
    // @ts-ignore
    () => onRowClick(filter.id);

  const onRemove = () => {
    // @ts-ignore
    onRemoveHistoryItem(filter.id);
  };

  return (
    <s.RowWrapper key={filter.id}>
      <s.Row onClick={onClick} disable={disable}>
        <s.RowTitle>
          { filter.created }
        </s.RowTitle>
        <s.RowContent>
          <s.RowItemWrapper>
            {renderSmartFilter(filter.smartFilter)}
            { renderRowItems(filter.filters) }
          </s.RowItemWrapper>
        </s.RowContent>
      </s.Row>
      <RemoveHistoryButton onRemove={onRemove} showLoader={filter.isRemoving} />
    </s.RowWrapper>
  );
};

const renderHistory = ({
  filters,
  onRowClick,
  onRemoveHistoryItem,
  selectedId,
}: RenderHistoryProps) => {
  if (!filters.length) {
    return renderEmptyBlock();
  }

  const param = {
    onRowClick,
    onRemoveHistoryItem,
    selectedId,
  };

  // @ts-ignore
  return filters.map(renderList.bind(null, param));
};

export const List: React.FC<ListProps> = ({ data, selectedId, onRowClick, onRemoveHistoryItem }: ListProps) => {
  const param = {
    filters: data,
    onRowClick,
    onRemoveHistoryItem,
    selectedId,
  };

  return (
    <s.Wrapper>
      <Header />
      {renderHistory(param)}
      <PaginationConnect />
    </s.Wrapper>
  );
};

export default List;
