import { createGlobalStyle } from 'styled-components';

const ChatStyle = createGlobalStyle`
  .form-one-widget__button {
    left: calc(100% - 80px) !important;
    top: calc(100% - 80px) !important;
  }

  .form-one-widget__button-icon--round {
    width: 64px;
    height: 64px;
    margin: auto;
  }


  .form-one-widget__button-icon--fox {
    background: url(/images/logo-chat.png) center center / 32px 32px no-repeat;
  }

  .form-one-widget__button-icon--fox * {
    display: none !important;
  }
`;

export default ChatStyle;

export {};
