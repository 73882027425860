import React from 'react';
import { RouteProps } from 'react-router-dom';

import s from './landing.style';

export class Landing extends React.PureComponent<RouteProps> {

  render() {
    const src = `https://about.caselook.ru${this.props.location.search}`;

    return (
      <s.Container>
        <iframe
          title="app"
          id="iframeId"
          src={src}
          frameBorder="0"
          style={{ width: '100%', height: '100%' }}
        />
      </s.Container>
    );
  }

}

export default Landing;
