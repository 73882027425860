import { createPackageStartReduce } from './createPackageStart.reduce';

const actionType = 'PACKAGE/CREATE__START';

const createAction = () => ({
  type: actionType,
});

export const createPackageStart = {
  actionType,
  createAction,
  reduce: createPackageStartReduce,
};

export default createPackageStart;
