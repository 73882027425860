export interface ActionData {
  text: string;
}

export const savePositionReduce = (state, action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    position: action.data.text,
  };
};

export default savePositionReduce;
