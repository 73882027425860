import React from 'react';
import { Loader } from '@components/loader';

import s from '../../../../../document/workspace/components/tooltipRelations.style';

interface TooltipLoaderProps {
  calculatePosition(): void;
}

export class TooltipLoader extends React.PureComponent<TooltipLoaderProps> {

  componentDidMount() {
    this.props.calculatePosition();
  }

  render() {
    return (
      <s.Loader>
        <Loader viewMode size={32} />
      </s.Loader>
    );
  }

}

export default TooltipLoader;
