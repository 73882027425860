import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { theme } from '@components/styled';

import s from './openButton.style';

interface Props {
  href: string;
  onClick?(): void;
}

export const OpenButton:React.FC<Props> = ({ ...props }) => {

  const iconPathName = 'open';

  const iconColor = theme.colors.blue;

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!props.onClick) {
      return;
    }

    props.onClick();
  };

  return (
    <s.Root
      onClick={handleClick}
      href={props.href}
      target="_blank"
    >
      <Icon
        color={iconColor}
        path={iconsPath.get(iconPathName)}
      />
    </s.Root>
  );
};

export default OpenButton;
