import http from '@http';
import { RESTPath } from '@router/path.rest';

import { NotificationItem } from './state';

interface RequestParams {
  page: number;
}

export interface NotificationsDTO {
  result: NotificationItem[];
  page: number;
  total_pages: number;
}

export function httpFetchNotifications(requestParams: RequestParams): Promise<NotificationsDTO> {
  const request = http.get({
    url: RESTPath.notifications.get(requestParams.page),
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchNotifications;
