import {
  ActionData,
  addCommentaryReduce,
} from './addCommentary.reduce';

export const actionType = 'BOOKMARKS/ADD_COMMENTARY';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addCommentary = {
  actionType,
  createAction,
  reduce: addCommentaryReduce,
};
