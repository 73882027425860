/* eslint-disable */
import React from 'react';
import {
  MenuButton,
  Navigation,
  Breadcrumbs,
  Breadcrumb,
  navigationItems,
} from '@components/header';

import { Bookmarks } from '@layouts/bookmarks/components/bookmarks';

import { SearchDrawerConnect } from './searchDrawer.connect';

import s from './header.style';

interface Props {
  sourceId: number;
}

export const Header = (props: Props) => {

const breadcrumbs: Breadcrumb[] = [
  {
    id: 0,
    title: 'Поиск',
    // @ts-ignore
    route: `#/search/${props.sourceId}`,
    loading: false,
  },
  {
    id: 1,
    title: 'Результаты поиска',
    // @ts-ignore
    route: null,
    loading: false,
  },
];

  return (
    <s.Header>
      <s.BreadcrumbsBar>
        <MenuButton/>
        <s.BreadcrumbsContainer>
          <Breadcrumbs breadcrumbs={breadcrumbs}/>
        </s.BreadcrumbsContainer>
        <SearchDrawerConnect id={props.sourceId} />
        <s.Actions>
          <Bookmarks/>
        </s.Actions>
      </s.BreadcrumbsBar>
      <s.NavigationBar>
        <Navigation
          id={props.sourceId}
          menuItems={navigationItems}
        />
      </s.NavigationBar>
    </s.Header>
  )
};

export default Header;
