import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { Loader } from '@components/loader';

import { EmptyLayout } from './components/layouts/emptyLayout';
import { DownloadLayout } from './components/layouts/downloadLayout';

import s from './comments.style';

interface CommentsProps {
  hasComments: boolean;
  loading: boolean;
  onFetchData(): void;
  onReset(): void;
}

export class Comments extends React.PureComponent<CommentsProps> {

  componentDidMount() {
    this.props.onFetchData();
  }

  componentWillUnmount() {
    this.props.onReset();
  }

  renderLayout() {
    if (this.props.loading) {
      return <Loader />;
    }

    if (!this.props.hasComments) {
      sendAmplitudeData('regulation:comments__open_empty_layout');
      Userpilot.track('regulation:comments__open_empty_layout');

      return <EmptyLayout />;
    }

    sendAmplitudeData('regulation:comments__open_download_layout');
    Userpilot.track('regulation:comments__open_download_layout');

    return <DownloadLayout />;
  }

  render() {
    return (
      <s.Root>
        {this.renderLayout()}
      </s.Root>
    );
  }

}

export default Comments;
