import { connect } from 'react-redux';

import { setNotificationVisitedThunk } from '../redux/setNotificationVisited.thunk';

import { NotificationsListItem } from './notificationsListItem';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onItemClick() {
    setNotificationVisitedThunk(dispatch, { id: ownProps.id });
  },
});

export const NotificationsListItemConnect = connect(
  null,
  mapDispatchToProps,
)(NotificationsListItem);

export default NotificationsListItemConnect;
