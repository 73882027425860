import React, {
  useState,
  useEffect,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { animateTopScroll } from '@components/scrollTo/animateScroll';
import { ScrollTo } from '@components/scrollTo/scrollTo';

import s from './highlightNavigation.style';

function renderArrowDown(direction = 'down') {
  const points = iconsPath.get('arrowDown');

  const transform = direction === 'down' ?
    '' :
    'translate(12.500000, 12.000000) rotate(180.000000) translate(-12.500000, -12.000000) ';

  return (
    <s.ArrowItem>
      <Icon
        transform={transform}
        points={points}
        color="#ffffff"
      />
    </s.ArrowItem>
  );
}

interface DocumentTabProps {
  title: string;
  targetId: string;
  firstParagraphId: number;
  ids: string[];
}

export const HighlightNavigation = (props: DocumentTabProps) => {

  const [index, setIndex] = useState(null);
  const [prevIndex, setPrevIndex] = useState(null);

  const [firstParagraphId, setFirstParagraphId] = useState(null);

  const handleDownClick = () => {
    // @ts-ignore
    setIndex((prevState) => {
      setPrevIndex(prevState);
      if (isNull(prevState)) {
        return 0;
      }

      const isLast = props.ids.length - 1 === prevState;
      if (isLast) {
        return prevState;
      }

      setPrevIndex(prevState);

      return prevState + 1;
    });
  };

  const handleUpClick = () => {
    if (index === null) {
      return;
    }

    if (index === 0) {
      setPrevIndex(index);
      setFirstParagraphId(null);
      setIndex(null);

      return;
    }

    // @ts-ignore
    setIndex((prevState) => {
      if (isNull(prevState)) {
        return 0;
      }

      setPrevIndex(prevState);

      return prevState - 1;
    });
  };

  const isLast = () => index === props.ids.length - 1;

  const isFirst = () => index === null;

  let offset = 100;

  const annotationBlock = document.getElementById('annotation');
  if (annotationBlock) {
    offset = -annotationBlock.offsetHeight + offset;
  }

  const summaryBlock = document.getElementById('summary');
  if (summaryBlock) {
    offset = -summaryBlock.offsetHeight + offset;
  }

  if (offset > 100) {
    offset += 100;
  }

  const scrollTo = new ScrollTo({ targetId: props.targetId, offset });

  useEffect(() => {
    const defaultValue = !index && index !== 0;
    if (defaultValue) {
      // @ts-ignore
      setFirstParagraphId(props.firstParagraphId);
    }

    const documentIsChange = props.firstParagraphId !== firstParagraphId;

    if (documentIsChange) {
      const container = document.getElementById(props.targetId);
      animateTopScroll(0, { duration: 1, targetElement: container });

      setIndex(null);

      return;
    }

    // @ts-ignore
    if (!props.ids[index]) {
      return;
    }

    const highlightIsChange = prevIndex !== index;
    if (!highlightIsChange) {
      return;
    }

    // @ts-ignore
    scrollTo.setTo(props.ids[index]);
    scrollTo.scrollTo();
    scrollTo.highlight();
  }, [index]);

  if (isEmpty(props.ids)) {
    return null;
  }

  return (
    <s.Root>
      <s.Navigation>
        <s.Title>{props.title}</s.Title>
        <s.Actions>
          <s.Action
            disabled={isFirst()}
            onClick={handleUpClick}
          >
            {renderArrowDown('up')}
          </s.Action>
          <s.Action
            disabled={isLast()}
            onClick={handleDownClick}
          >
            {renderArrowDown()}
          </s.Action>
        </s.Actions>
      </s.Navigation>
    </s.Root>
  );
};

export default HighlightNavigation;
