import React from 'react';

import { Icon } from '@components/icons';
import s from './checkbox.style';

export interface CheckboxProps {
  item: {
    id: string;
    title: string;
  };
  children: any;
  isActive: boolean;
  onClick: (id: string) => void;
}

export const Checkbox = (props: CheckboxProps) => {

  const getPath = () => {
    if (props.isActive) {
      return 'M12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 ' +
        '4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z M12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 ' +
        '8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 ' +
        'Z M12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 ' +
        '8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 Z';
    }

    return 'M12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 ' +
      '4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z M12,6 C8.6862915,6 6,8.6862915 6,12 C6,' +
      '15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915' +
      ' 15.3137085,6 12,6 Z';
  };

  const handleClick = () => {
    props.onClick(props.item.id);
  };

  return (
    <s.Checkbox
      isActive={props.isActive}
      onClick={handleClick}
    >
      <s.Icon isActive={props.isActive}>
        <Icon
          path={getPath()}
          color="currentColor"
        />
      </s.Icon>
      <s.Title>
        {props.children}
      </s.Title>
    </s.Checkbox>
  );
};

export default Checkbox;
