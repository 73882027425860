import { updateSummaryRatingReduce } from './updateSummaryRating.reduce';

import type { ActionData } from './updateSummaryRating.reduce';

const actionType = 'SUMMARY/UPDATE_RATING';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateSummaryRating = {
  actionType,
  createAction,
  reduce: updateSummaryRatingReduce,
};

export default updateSummaryRating;
