import React from 'react';

import { DocumentId } from './redux/state';

import { Header } from '../header';

import { SummaryContentConnect } from './summaryContent.connect';

import s from './summary.style';

interface SummaryProps {
  id: DocumentId
  rating: string;
  inProgress: boolean;
  isExpanded: boolean;
  shouldRender: boolean;
  hasError: boolean;
  onCreate(id: DocumentId): void;
  onToggle(id: DocumentId): void;
  onRatingUpdate(data: { documentId: DocumentId, value: string }): void;
}

export class Summary extends React.PureComponent<SummaryProps> {

  static title = 'сводка';

  static errorTitle = 'сводку';

  renderBody() {
    if (!this.props.shouldRender) {
      return null;
    }

    if (this.props.inProgress) {
      return null;
    }

    if (this.props.hasError) {
      return null;
    }

    if (!this.props.isExpanded) {
      return null;
    }

    return (
      <s.ContentContainer>
        <SummaryContentConnect id={this.props.id} />
      </s.ContentContainer>
    );
  }

  render() {
    return (
      <s.Root
        id="summary"
      >
        <Header
          title={Summary.title}
          errorTitle={Summary.errorTitle}
          {...this.props}
        />
        {this.renderBody()}
      </s.Root>
    );
  }

}

export default Summary;
