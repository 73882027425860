import { StateBuilder } from './state';

export const loadingStartReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state);
  newState.setLoading(true);

  return {
    ...state,
    ...newState.getState(),
  };
};

export const loadingEndReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state);
  newState.setLoading(false);

  return {
    ...state,
    ...newState.getState(),
  };
};
