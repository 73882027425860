import { Dispatch } from 'redux';

import { appHistory } from '@router/history';

import { fetchDocumentsThunk } from '../../workspace/documents/redux/fetchDocuments.thunk';
import { resetToInitial } from '../../workspace/documents/redux/resetToInitial';

import { selectPackage } from './selectPackage';

export class SelectPackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { idFromUrlParams: number, sourceId: number }) {
    const { packages } = this.getState().authorPackages;
    const sameId = params.sourceId === packages.activeItemId;

    if (sameId) {
      return;
    }

    try {
      const sourceId = params.idFromUrlParams || params.sourceId;
      await this.dispatchSelectPackage(sourceId);

      this.dispatchResetToInitial();
      if (!params.idFromUrlParams) {
        appHistory.push(`/authorpackages/${params.sourceId}`);
      }

      const originalPackageId = packages.itemsMap.get(sourceId).id;
      fetchDocumentsThunk(this.dispatch, { id: originalPackageId });

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  private dispatchResetToInitial() {
    this.dispatch(resetToInitial.createAction());
  }

  private dispatchSelectPackage(sourceId) {
    const actionData = {
      sourceId,
    };

    this.dispatch(selectPackage.createAction(actionData));
  }

}

export function selectPackageThunk(dispatch, params) {
  const thunk = new SelectPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SelectPackageThunk;
