import { connect } from 'react-redux';

import { removeSubscriptionThunk } from './redux/removeSubscription.thunk';

import { Remove } from './remove';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRemove() {
    removeSubscriptionThunk(dispatch, { id: ownProps.id });
  },
});

export const RemoveConnect = connect(
  null,
  mapDispatchToProps,
)(Remove);

export default RemoveConnect;
