import React from 'react';
import { Route } from 'react-router-dom';

import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

export const RouteAuthorPackageSimilarDocument = (
  <Route
    path={RoutePath.authorPackageSimilarDocument}
    component={DocumentConnect}
    exact
  />
);

export default RouteAuthorPackageSimilarDocument;
