import { Map } from 'immutable';

import {
  Id,
  Item,
} from './item';

interface State {
  itemsList: Id[];
  itemsMap: Map<Id, Item>;
  activeItemId: Id;
  filtersLoading: boolean;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      itemsList: [],
      itemsMap: Map(),
      activeItemId: null,
      filtersLoading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  public addItem(params: { id: number; name: string; filters: any; }) {
    const item = new Item();
    item.buildItem({
      id: params.id,
      title: params.name,
      filters: params.filters,
    });

    const itemsList = [...this.state.itemsList, item.getId()];
    const itemsMap = this.state.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
      itemsList,
      itemsMap,
    };
  }

  public addItems(items) {
    items.forEach((item) => this.addItem({
      id: item.id,
      name: item.name,
      filters: item.filters,
    }));
  }

  public changeActiveItemId(id: Id) {
    this.state = {
      ...this.state,
      activeItemId: Number(id),
    };
  }

  public updateItemTitle(params: { id: Id, title: string }) {
    const currentItem = this.state.itemsMap.get(params.id);
    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      filters: currentItem.getFilters(),
    });

    item.setTitle(params.title);

    this.state.itemsMap = this.state.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
    };
  }

  public updateItemFilters(params: { id: Id, filters: any }) {
    const currentItem = this.state.itemsMap.get(params.id);
    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      filters: currentItem.getFilters(),
    });

    item.setFilters(params.filters);

    this.state.itemsMap = this.state.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
    };
  }

  private createItem(id) {
    const currentItem = this.state.itemsMap.get(id);

    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      filters: currentItem.getFilters(),
    });

    return item;
  }

  public removeItem(id: Id): void {
    const index = this.state.itemsList.findIndex((item) => item === id);
    const itemsList = [...this.state.itemsList];
    itemsList.splice(index, 1);
    this.state.itemsList = itemsList;
    this.state.itemsMap = this.state.itemsMap.delete(id);
  }

  public setFiltersLoadingFalse() {
    this.state = {
      ...this.state,
      filtersLoading: false,
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
