import React from 'react';

import { Header } from '@components/popoverHeader';

import s from './chat.style';

interface Props {
  onGoBack: () => void
}

export class Chat extends React.PureComponent<Props> {

  componentWillUnmount() {
    this.props.onGoBack();
  }

  renderHeader() {
    const title = 'Спросить AI';

    return (
      <Header
        onGoBack={this.props.onGoBack}
        title={title}
      />
    );
  }

  render() {
    return (
      <s.Root>
        <s.Header>
          {this.renderHeader()}
        </s.Header>
        <iframe
          title="Chat"
          src="https://pravodigital.bot.one/embed/chat/32290"
          width="100%"
          height={576}
        />
      </s.Root>
    );
  }

}

export default Chat;
