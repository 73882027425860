import { styled } from '@components/styled';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  flex-grow: 1;
  overflow: auto;
  background: ${(props: Props) => props.theme.colors.white};
`;

style.Loader = styled.div`
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

style.EmptyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font: 13px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.stone};
`;

export default style;
