import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export default style;
