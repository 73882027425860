import moment from 'moment';

import { AbstractFilterBlock } from '@components/filters/abstract';

interface Scope {
  id: string;
  title: string;
}

type Value = string[];

export interface DateBlockData {
  value: Value;
  scope: Scope;
}

export enum ScopeIdDTO {
  allTime = 'all_time',
  today = 'today',
  yesterday = 'yesterday',
  dayBeforeYesterday = 'day_before_yesterday',
  threeDaysAgo = 'three_days_ago',
  thisWeek = 'this_week',
  lastWeek = 'last_week',
  currentMonth = 'current_month',
  lastMonth = 'last_month',
  range = 'range',
}

export enum ScopeId {
  allTime = 'allTime',
  today = 'today',
  yesterday = 'yesterday',
  dayBeforeYesterday = 'dayBeforeYesterday',
  threeDaysAgo = 'threeDaysAgo',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  currentMonth = 'currentMonth',
  lastMonth = 'lastMonth',
  date = 'date',
  range = 'range',
}

export enum ScopeTitle {
  allTime = 'За все время',
  today = 'Сегодня',
  yesterday = 'Вчера',
  dayBeforeYesterday = 'Позавчера',
  threeDaysAgo = 'Три дня назад',
  thisWeek = 'Текущая неделя',
  lastWeek = 'Прошлая неделя',
  currentMonth = 'Текущий месяц',
  lastMonth = 'Прошлый месяц',
  range = 'Зарегестрировано',
}

export interface DatePreset {
  id: ScopeId;
  DTOid: ScopeIdDTO.allTime;
}

export class DateBlock extends AbstractFilterBlock {

  static scopePresets = {
    [ScopeIdDTO.allTime]: {
      id: ScopeId.allTime,
      DTOid: ScopeIdDTO.allTime,
      title: ScopeTitle.allTime,
    },
    [ScopeIdDTO.today]: {
      id: ScopeId.today,
      DTOid: ScopeIdDTO.today,
      title: ScopeTitle.today,
    },
    [ScopeIdDTO.yesterday]: {
      id: ScopeId.yesterday,
      DTOid: ScopeIdDTO.yesterday,
      title: ScopeTitle.yesterday,
    },
    [ScopeIdDTO.dayBeforeYesterday]: {
      id: ScopeId.dayBeforeYesterday,
      DTOid: ScopeIdDTO.dayBeforeYesterday,
      title: ScopeTitle.dayBeforeYesterday,
    },
    [ScopeIdDTO.threeDaysAgo]: {
      id: ScopeId.threeDaysAgo,
      DTOid: ScopeIdDTO.threeDaysAgo,
      title: ScopeTitle.threeDaysAgo,
    },
    [ScopeIdDTO.thisWeek]: {
      id: ScopeId.thisWeek,
      DTOid: ScopeIdDTO.thisWeek,
      title: ScopeTitle.thisWeek,
    },
    [ScopeIdDTO.lastWeek]: {
      id: ScopeId.lastWeek,
      DTOid: ScopeIdDTO.lastWeek,
      title: ScopeTitle.lastWeek,
    },
    [ScopeIdDTO.currentMonth]: {
      id: ScopeId.currentMonth,
      DTOid: ScopeIdDTO.currentMonth,
      title: ScopeTitle.currentMonth,
    },
    [ScopeIdDTO.lastMonth]: {
      id: ScopeId.lastMonth,
      DTOid: ScopeIdDTO.lastMonth,
      title: ScopeTitle.lastMonth,
    },
    [ScopeIdDTO.range]: {
      id: ScopeId.range,
      DTOid: ScopeIdDTO.range,
      title: ScopeTitle.range,
    },
  };

  protected defaultData: DateBlockData = {
    scope: DateBlock.scopePresets[ScopeIdDTO.allTime],
    value: ['', ''],
  };

  constructor() {
    super();

    this.setData(this.defaultData);
  }

  public create(block) {
    this.setData(block.data);
  }

  public createFromScratch(props) {
    if (!props) {
      throw Error('data is required');
    }

    const getDate = (stringOfDate: string) => new Date(moment(stringOfDate, 'YYYYMMDD').toDate());

    const firstValue = props.value[0] ? getDate(props.value[0]) : '';
    const secondValue = props.value[1] ? getDate(props.value[1]) : '';

    const value = [
      firstValue,
      secondValue,
    ];

    const data = {
      scope: DateBlock.scopePresets[props.scope],
      value,
    };

    const block = {
      data,
    };

    this.create(block);
  }

  public decorate() {
    return {
      data: this.getData(),
    };
  }

  public undecorate() {
    const data = {
      scope: this.getData().scope.DTOid,
      value: [],
    };

    const formatString = 'YYYY-MM-DD';
    const isRange = this.getData().scope.id === DateBlock.scopePresets.range.id;

    if (!isRange) {
      data.value = [null, null];

      return {
        data,
      };
    }

    const firstDate = moment(this.getData().value[0]);
    if (firstDate.isValid()) {
      // @ts-ignore
      data.value[0] = (firstDate.format(formatString));
    } else {
      data.value[0] = null;
    }

    const secondDate = moment(this.getData().value[1]);
    if (secondDate.isValid()) {
      // @ts-ignore
      data.value[1] = (secondDate.format(formatString));
    } else {
      data.value[1] = null;
    }

    return {
      data,
    };
  }

  // eslint-disable-next-line
  public validate() { }

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

}

export default DateBlock;
