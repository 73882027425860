import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

interface Props {
  shouldRenderFromRight: boolean;
}

const style: any = {};

style.Root = styled.div`
  position: absolute;
  width: 256px;
  padding: 12px 16px;
  background: white;
  right: 32px;
  top: -4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15),
  0 2px 8px 0 rgba(0, 0, 0, 0.15);
  cursor: default;
  z-index: 90;

  ${(props: Props) => props.shouldRenderFromRight && css`
    right: -256px;
  `}

  div {
    padding: 0;
    font-size: 11px;
  }
`;

style.Label = styled.label`
  color: ${theme.colors.blue};
  cursor: pointer;
  display: flex;
  font-size: 11px;
`;

export default style;
