import { StateBuilder } from './state';

export const toggleSelectionReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.documents);

  return {
    ...state,
    documents: newState.toggleSelectionRule(),
  };
};

export default toggleSelectionReduce;
