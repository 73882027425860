import React from 'react';

import { CloseButton } from '@components/buttons';

import s from './header.style';

interface HeaderProps {
  isAuthorPackage: boolean;
  onClose(): void;
}

export class Header extends React.PureComponent<HeaderProps> {

  renderTitle() {
    if (this.props.isAuthorPackage) {
      return <s.HeaderTitle>Изменить параметры публикации</s.HeaderTitle>;
    }

    return <s.HeaderTitle>Опубликовать подборку</s.HeaderTitle>;
  }

  render() {
    return (
      <s.Root>
        <s.Action>
          <CloseButton
            color="#4183d7"
            onClick={this.props.onClose}
          />
        </s.Action>
        {this.renderTitle()}
      </s.Root>
    );
  }

}

export default Header;
