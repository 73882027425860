import { Map } from 'immutable';

export interface PackageDTO {
  id: number;
  name: string;
  contain_document: boolean;
  documents_count: number;
  type: string;
}

export interface Package {
  id: number;
  title: string;
  selected: boolean;
  type: string;
}

export const preparePackageItem = (packageItem: PackageDTO) : Package => ({
  id: packageItem.id,
  title: packageItem.name,
  selected: packageItem.contain_document,
  type: packageItem.type,
});

const prepareState = (fetchedPackages: PackageDTO[]) => {
  const itemsList = [];
  let itemsMap = Map();

  fetchedPackages.forEach((packageItem) => {
    itemsList.push(packageItem.id);
    itemsMap = itemsMap.set(packageItem.id, preparePackageItem(packageItem));
  });

  return {
    itemsList,
    itemsMap,
  };
};

export interface ActionData {
  packages: PackageDTO[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchPackagesSearchResultReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    searchResult: {
      ...state.searchResult,
      ...prepareState(action.data.packages),
      loading: false,
    },
  };
};

export default fetchPackagesSearchResultReduce;
