import React from 'react';

import { InputComponent } from '@pages/document/components/inputComponent/inputComponent';

import s from './download.style';

interface DownloadProps {
  id?: string;
  title: string;
  onDownload(id: string, title: string): void;
}

export const Download = (props: DownloadProps) => {

  const handleClick = () => props.onDownload(props.id, props.title);

  return (
    <s.Root
      onClick={handleClick}
    >
      <InputComponent isActive={false} iconName="download" />
    </s.Root>
  );
};

export default Download;
