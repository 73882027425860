import styled, { css } from 'styled-components';

interface Props {
  theme: Record<any, any>;
  checked: boolean;
}

const style: any = {};

style.Root = styled.div`
  height: 13px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 24px
`;

style.Check = styled.span`
  top: -1px;
  left: -1px;
  bottom: 0;
  right: 0;
  position: absolute;

  background: ${(props: Props) => props.theme.colors.white};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  height: 14px;
  width: 14px;
  border-radius: 50%;
  transform: translateX(0);

  transition: transform .2s;

  ${(props: Props) => props.checked && css`
    transform: translateX(12px);
  `}
`;

style.Slider = styled.span`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: ${(props: Props) => props.theme.colors.borderColor};
  border-radius: 6px;
  height: 12px;
  width: 24px;

  transition: .3s;

  ${(props: Props) => props.checked && css`
    background: ${props.theme.colors.blue};
  `}
`;

export default style;
