import React from 'react';

import { Icon } from '@components/icons';
import { theme } from '@components/styled';

import s from './layout.style';

interface ButtonProps {
  iconPath: string;
  loading: boolean;
  disabled: boolean;
  active: boolean;
  onClick: () => void;
}

export class Button extends React.PureComponent<ButtonProps> {

  handleClick = () => {
    if (this.props.loading) {
      return;
    }

    this.props.onClick();
  };

  render() {
    const color = this.props.active ? theme.colors.blue : theme.colors.stone;

    return (
      <s.Button
        onClick={this.handleClick}
        disabled={this.props.disabled}
        loading={this.props.loading}
        active={this.props.active}
      >
        <Icon path={this.props.iconPath} color={color} />
      </s.Button>
    );
  }

}

export default Button;
