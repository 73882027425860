import { Map } from 'immutable';

import { StateBuilder } from './state';

export const resetProjectsReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = StateBuilder.createState();
  newState.settingsLoading = state.projects.settingsLoading;
  newState.layout = state.projects.layout;

  return {
    ...state,
    projects: {
      ...state.projects,
      currentPageNumber: [],
      lastPageReached: false,
      loading: true,
      previewId: null,
      projects: {
        itemsList: [],
        itemsMap: Map(),
      },
      totalProjects: 0,
      withoutChange: 0,
    },
  };
};

export default resetProjectsReduce;
