import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpFetchCourtType() {
  const request = {
    url: RESTPath.search.bootstrap.courtType,
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(http.parseResponse);
}

export default httpFetchCourtType;
