import { paymentProcessStartReduce } from './paymentProcessStart.reduce';

export const actionType = 'USER/PAYMENT_START';

const createAction = () => ({
  type: actionType,
});

export const paymentProcessStart = {
  actionType,
  createAction,
  reduce: paymentProcessStartReduce,
};
