import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { processThunk } from '@providers/download/process.thunk';
import { Source } from '@providers/download/download.http';

import { Download } from './download';

const mapStateToProps = (state) => {
  const { id } = state.searchResult;

  return {
    id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDownload(data) {
    const params = {
      source: Source.search,
      id: data.id,
      format: data.format,
    };

    // @ts-ignore
    processThunk(dispatch, params);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onDownload(format) {

    sendAmplitudeData('document on download');
    Userpilot.track('document on download');
    dispatchProps.onDownload({ id: stateProps.id, format });
  },
});

export const DownloadConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Download);

export default DownloadConnect;
