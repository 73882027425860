import styled from 'styled-components';

import button from '@components/button/button.style';

const style: any = {};

interface Props {
  theme: any;
}

style.ResetButton = styled.div`

  ${button.Root} {
    padding: 0 8px 0 16px;
  }

  ${button.Text} {
    color: ${(props: Props) => props.theme.colors.stone};
  }
`;

export default style;
