import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { EmptyFolder } from '@components/emptyFolder';
import { Loader } from '@components/loader';

import { SimilarItem } from '../redux/state';

import s from './similar.style';

interface SimilarProps {
  items: SimilarItem[];
  loading: boolean;
  activeId: number;
  targetDocumentId: number;
  baseId: number;
  isSimilarDocument: boolean;
  isChronologyDocument: boolean;
  onChangeSimilarDocument(id: number, parentDocumentId: number, baseId: number): void;
}

export class Similar extends React.PureComponent<SimilarProps> {

  handleItemClick = () => {
    sendAmplitudeData('document: open similar');
    Userpilot.track('document: open similar');
  };

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderItem = (item) => {
    const params = {
      baseId: this.props.baseId,
      documentId: item.documentId,
      targetDocumentId: this.props.targetDocumentId,
    };

    let path = '';

    const basePath = window.location.hash
      .replace('#/', '');

    if (this.props.isSimilarDocument) {
      const pathStack = basePath.split('/');
      pathStack[pathStack.length - 1] = params.documentId;
      path = `#/${pathStack.join('/')}`;
    } else if (this.props.isChronologyDocument) {
      const pathStack = basePath.split('/');
      pathStack[pathStack.length - 2] = 'similar';
      pathStack[pathStack.length - 1] = params.documentId;
      path = `#/${pathStack.join('/')}`;
    } else {
      params.targetDocumentId = params.baseId;
      path = `#/${basePath}/similar/${params.documentId}`;
    }

    return (
      <s.Item
        onClick={this.handleItemClick}
        href={path}
        key={item.documentId}
        isActive={item.documentId === this.props.activeId}
      >
        <s.Title>{item.title}</s.Title>
        <s.Department>{item.department}</s.Department>
      </s.Item>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    const noDocuments = !this.props.loading && isEmpty(this.props.items);
    if (noDocuments) {
      return <EmptyFolder title="Нет похожих документов" />;
    }

    return (
      <s.Root>{this.props.items.map(this.renderItem)}</s.Root>
    );
  }

}

export default Similar;
