import { connect } from 'react-redux';

import { toggleItemSelect } from '../redux/toggleItemSelect';
import { markIsVisitedFromLink } from '../redux/markIsVisitedFromLink';
import { isSelected } from '../list/item.connect';

import { Item } from './item';

function prepareColumns(initialData, settings) {
  const columns = [];

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      columns.push({
        // @ts-ignore
        id: column,
        // @ts-ignore
        data: initialData[column] || '',
      });
    }
  });

  return columns;
}

const mapStateToProps = (state, ownProps) => {
  const {
    currentPageNumber,
    layout,
    pages,
    selection,
    showCommentary,
  } = state.searchResult.documents;

  const { initialData } = pages[currentPageNumber].itemsMap.get(ownProps.id);
  const item = pages[currentPageNumber].itemsMap.get(ownProps.id);

  const settings = layout.tableView.columns;
  const columns = prepareColumns(initialData, settings);

  return {
    columns,
    item,
    selected: isSelected(ownProps.id, selection),
    sourceId: state.searchResult.id,
    showCommentary,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onItemSelect(id: number) {
    dispatch(toggleItemSelect.createAction({ id }));
  },
  onMarkIsVisited(id) {
    dispatch(markIsVisitedFromLink.createAction({ id }));
  },
});

export const ItemConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
