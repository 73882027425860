import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.nav`
  display: inline-flex;
  align-items: center;
`;

style.Breadcrumb = styled.div`
  display: flex;
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.AnchorBreadcrumb = styled.a`
  height: 32px;
  align-items: center;
  padding: 0 2px;
  display: flex;
  text-decoration: none;
  color: ${(props: Props) => props.theme.colors.stone};

  border-radius: 2px;

  background-color: transparent;

  transition: background-color 0.2s ease;

  :hover {
    background-color: rgba(65, 131, 215, 0.07);
  }
`;

style.LoadingBreadcrumb = styled.div`
  width: 200px;
  height: 14px;
  margin-top: 3px;
  border-radius: 7px;
  background: #f5f6f7;

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeff0 8%, #f5f6f7 38%, #eeeff0 54%);
  background-size: 1000px 640px;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
  }
`;

style.Separator = styled.span`
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.CurrentBreadcrumb = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 2px;
`;

export default style;
