import styled, { css } from 'styled-components';

const style: any = {};

export type Anchor = 'right' | 'left' | 'top' | 'bottom';

export interface Props {
  anchor: Anchor;
  open: boolean;
}

function animate(settings) {
  if (!settings.shouldAnimate) {
    return '';
  }

  return css`
    background: transparent;

    ${(props: Props) => props.open && css`
      background: rgba(0, 0, 0, 0.4);
    `}

    transition: background 0.35s ease-out;

    > div {
      transition: transform 0.35s cubic-bezier(0.24, 1, 0.32, 1);

      transform: translateX(-100%);

      ${(props: Props) => props.anchor === 'right' && css`
        transform: translateX(100%);
      `}

      ${(props: Props) => props.open && css`
        transform: translateX(0%);
    `}
    }
  `;
}

style.Root = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: flex-start;
  z-index: 999;

  background: rgba(0, 0, 0, 0.4);

  ${(props: Props) => props.anchor === 'right' && css`
    justify-content: flex-end;
  `}

  ${(props: Props) => animate(props)};
`;

export default style;
