import React from 'react';

import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import s from './withSidebar.style';

export function WithSidebar(Sidebar: React.ElementType, Layout: React.ElementType) {
  return function withSidebar(props) {
    return (
      <WithHeaderNotification>
        <s.Container>
          <s.Navigation>
            <Sidebar {...props} />
          </s.Navigation>
          <s.Main>
            <Layout {...props} />
          </s.Main>
        </s.Container>
      </WithHeaderNotification>
    );
  };
}

export default WithSidebar;
