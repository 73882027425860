import React from 'react';

import { LayoutConnect } from './layout.connect';

import s from './toolbar.style';

export class Toolbar extends React.PureComponent {

  render() {
    return (
      <s.Root>
        <LayoutConnect />
      </s.Root>
    );
  }

}

export default Toolbar;
