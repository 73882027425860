import React, { Component } from 'react';

import { CellMore } from '@pages/searchResult/documents/list/cellMore';
import type { Document } from '@pages/searchResult/documents/redux/state';

import { Checkbox } from '@components/checkbox/checkbox';

import { AddPackageConnect } from '../list/addPackage.connect';
import { SettingsConnect } from '../list/settings.connect';

import { grid } from './table';

import s from './table.style';

type Id = number;

interface Props {
  id: string;
  sourceId: string;
  item: Document;
  selected: boolean;
  isActive: boolean;
  showCommentary: boolean;
  columns: any;
  onItemSelect: (id: Id) => void;
  onItemClick: (id: Id) => void;
}

interface DataItem {
  id: number;
  data: any;
}

interface DataItemsProps {
  data: DataItem[];
}

const DataItems = (props: DataItemsProps) => {

  const renderItem = (item) => (<div key={item.name}>{item.name}</div>);

  return (
    <div>
      {props.data.map(renderItem)}
    </div>
  );
};

export class Item extends Component<Props> {

  handleItemSelect = () => {
    this.props.onItemSelect(this.props.item.id);
  };

  handleItemClick = () => {
    this.props.onItemClick(this.props.item.id);
  };

  handleHrefClick = (e) => {
    e.stopPropagation();
  };

  renderTitle(item) {
    const TYPE_TITLE = 'type_title';
    const isLink = item.id === TYPE_TITLE;

    if (!isLink) {
      return (
        <s.TitleText>
          {item.data}
        </s.TitleText>
      );
    }

    const href = `#/subscription/${this.props.sourceId}/documents/${this.props.id}`;

    return (
      <s.TitleLink
        onClick={this.handleHrefClick}
        href={href}
        target="_blank"
      >
        {item.data}
      </s.TitleLink>
    );
  }

  renderItem = (item) => {
    if (item.id === 'judges') {
      return (
        <s.CellItem
          column={grid[item.id]}
          key={item.id}
        >
          <s.BodyCellContainer>
            <DataItems data={item.data} />
          </s.BodyCellContainer>
        </s.CellItem>
      );
    }

    return (
      <s.CellItem
        column={grid[item.id]}
        key={item.id}
      >
        <s.BodyCellContainer>
          {this.renderTitle(item)}
        </s.BodyCellContainer>
      </s.CellItem>
    );
  };

  renderComment(comment) {
    if (!this.props.showCommentary) {
      return null;
    }

    const hasComment = comment.trim().length !== 0;
    if (!hasComment) {
      return null;
    }

    const editCellCount = 3;
    const colspan = this.props.columns.length + editCellCount;

    return (
      <s.Comment>
        <s.CommentCell colSpan={colspan}>
          {comment}
        </s.CommentCell>
      </s.Comment>
    );
  }

  render() {
    const {
      item,
    } = this.props;

    return (
      <>
        <s.Item
          isActive={this.props.isActive}
          onClick={this.handleItemClick}
        >
          <s.CellIcon>
            <s.BodyCellContainer>
              <Checkbox
                checked={this.props.selected}
                onClick={this.handleItemSelect}
              />
            </s.BodyCellContainer>
          </s.CellIcon>
          {this.props.columns.map(this.renderItem)}
          <CellMore>
            <AddPackageConnect
              id={item.id}
            />
          </CellMore>
          <CellMore>
            <SettingsConnect
              id={item.id}
            />
          </CellMore>
        </s.Item>
        {this.renderComment(item.comment)}
      </>
    );
  }

}

export default Item;
