import React from 'react';

import VisibilitySensor from 'react-visibility-sensor';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Loader } from '@components/loader';
import { OpenDrawer } from '@components/openDrawer';

import {
  ViewType,
  Id,
} from '../redux/state';

import { PreviewConnect as StandalonePreview } from '../calendar/components/preview/preview.connect';

import { ViewTypes } from '../redux/saveLayoutSettings.thunk';

import { HeaderConnect } from './header/header.connect';
import { ListConnect } from './list/list.connect';
import { TableConnect } from './table/table.connect';
import { PreviewConnect } from './preview/preview.connect';

import s from './projects.style';

interface ProjectsProps {
  items: Id[];
  viewType: ViewTypes;
  previewId: Id;
  loading: boolean;
  onLoadNextPage(): void;
}

interface ProjectsState {
  showPreview: boolean;
  projectPreviewId: Id;
  standaloneId: Id;
}

export class Projects extends React.PureComponent<ProjectsProps, ProjectsState> {

  contentRef: React.RefObject<HTMLDivElement>;

  state = {
    showPreview: false,
    projectPreviewId: null,
    standaloneId: null,
  };

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidUpdate(prevProps: ProjectsProps) {
    this.checkViewChage(prevProps.viewType);

    if (prevProps.previewId) {
      return;
    }

    if (this.props.previewId && !this.state.showPreview && !this.state.standaloneId) {
      this.showStandalonePreview(this.props.previewId);
    }
  }

  showPreview = (id: Id) => {
    this.setState({
      showPreview: true,
      projectPreviewId: id,
    });
  };

  showStandalonePreview = (id: Id) => {
    this.setState({
      showPreview: true,
      projectPreviewId: null,
      standaloneId: id,
    });
  };

  hidePreview = () => {
    this.setState({
      showPreview: false,
      projectPreviewId: null,
    });
  };

  marketingEvent = () => {
    sendAmplitudeData('regulation:open_view');
    Userpilot.track('regulation:open_view');
  };

  handleItemClick = (id: Id) => {
    this.showPreview(id);
    this.marketingEvent();
  };

  handleCloseDrawer = () => {
    this.hidePreview();
  };

  onAfterChangeView = () => {
    this.contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleVisible = (isVisible) => {
    if (isVisible) {
      this.props.onLoadNextPage();
    }
  };

  contentSwitcher() {
    const isListLayout = this.props.viewType === ViewType.list;
    if (isListLayout) {
      return (
        <s.ContentContainer
          isList
        >
          <HeaderConnect />
          <s.ItemsContent>
            {this.renderList()}
            {this.renderBottomSensor()}
          </s.ItemsContent>
        </s.ContentContainer>
      );
    }

    return (
      <s.ContentContainer>
        <HeaderConnect />
        <s.ItemsContent>
          {this.renderTable()}
          {this.renderBottomSensor()}
        </s.ItemsContent>
      </s.ContentContainer>
    );
  }

  checkViewChage(viewType: ViewTypes) {
    if (!viewType) {
      return;
    }

    if (viewType === this.props.viewType) {
      return;
    }

    if (this.props.loading) {
      return;
    }

    this.onAfterChangeView();
  }

  renderList = () => (
    <ListConnect
      activeId={this.state.projectPreviewId}
      items={this.props.items}
      onItemClick={this.handleItemClick}
    />
  );

  renderTable = () => (
    <TableConnect
      activeId={this.state.projectPreviewId}
      items={this.props.items}
      onItemClick={this.handleItemClick}
    />
  );

  renderContent() {
    if (this.props.loading) {
      return <Loader />;
    }

    return this.renderLayout();
  }

  renderLayout() {
    return (
      <>
        {this.renderDocuments()}
        {this.renderDrawer()}
      </>
    );
  }

  renderBottomSensor() {
    if (this.props.loading) {
      return null;
    }

    return (
      <VisibilitySensor onChange={this.handleVisible} partialVisibility>
        <s.BottomSensor />
      </VisibilitySensor>
    );
  }

  renderDocuments = () => (
    <s.Content ref={this.contentRef}>
      {this.contentSwitcher()}
    </s.Content>
  );

  renderPreviewComponent = () => {
    if (this.state.projectPreviewId) {
      return (
        <PreviewConnect
          id={this.state.projectPreviewId}
          onClose={this.handleCloseDrawer}
        />
      );
    }

    if (this.state.standaloneId) {
      return (
        <StandalonePreview
          defaultShow="chat"
          id={this.state.standaloneId}
          onClose={this.handleCloseDrawer}
        />
      );
    }

    return null;
  };

  renderDrawer = () => {
    if (!this.state.showPreview) {
      return null;
    }

    sendAmplitudeData('regulation: preview');
    Userpilot.track('regulation: preview');

    return (
      <OpenDrawer
        anchor="right"
        withBackground={false}
        component={this.renderPreviewComponent()}
        onClickOutside={this.handleCloseDrawer}
      />
    );
  };

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default Projects;
