import styled from 'styled-components';

const style: any = {};

style.PageLoader = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 1;
`;

export default style;
