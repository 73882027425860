import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

export const RouteSubscriptionChronologyDocument = (
  <AuthRouteConnect
    path={RoutePath.subscriptionChronologyDocument}
    component={DocumentConnect}
    exact
  />
);

export default RouteSubscriptionChronologyDocument;
