import { ReducerBuilder } from '@redux/reducer.builder';

import { reducerBuilder as packageSearchResult } from './searchPackages/reducer';
import { reducerBuilder as packages } from './packages/reducer';
import { reducerBuilder as documents } from './documents/reducer';
import { reducerBuilder as layout } from '../header/redux/reducer';

import { resetToInitial } from './resetToInitial';
import { packagesBootstrap } from './packagesBootstrap';

import { StateBuilder } from './state';

export const reducerBuilder = new ReducerBuilder([
  resetToInitial,
  packagesBootstrap,
]);

const initialState = StateBuilder.createState();
reducerBuilder.setInitialState(initialState);

reducerBuilder.merge(packageSearchResult);
reducerBuilder.merge(packages);
reducerBuilder.merge(documents);
reducerBuilder.merge(layout);

export default reducerBuilder;
