import { toggleCommentaryReduce }
  from '@pages/searchResult/documents/header/settingsPopover/redux/toggleCommentary.reduce';

export const actionType = 'AUTHOR_PACKAGES/DOCUMENTS/TOGGLE_COMMENTARY';

const createAction = () => ({
  type: actionType,
});

export const toggleCommentary = {
  actionType,
  createAction,
  reduce: toggleCommentaryReduce,
};
