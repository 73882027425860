import { connect } from 'react-redux';

import { Relations } from './relations';

const mapStateToProps = (state) => {
  const { relations } = state.document;

  return {
    ...relations,
  };
};

export const RelationsConnect = connect(
  mapStateToProps,
)(Relations);

export default RelationsConnect;
