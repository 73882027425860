export interface ActionData {
  tagId: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const setTagReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    tag: action.data.tagId,
  };
};

export default setTagReduce;
