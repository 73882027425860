import { addFolderReduce } from './addFolder.reduce';

import type { ActionData } from './addFolder.reduce';

const actionType = 'REGULATION/FOLDERS/ADD_FOLDER';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addFolder = {
  actionType,
  createAction,
  reduce: addFolderReduce,
};

export default addFolder;
