import { DocumentId } from '@pages/searchResult/redux/state';

export interface ActionData {
  id: DocumentId;
}

interface Action {
  type: string;
  data: ActionData;
}

export const markIsVisitedFromLinkReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const { currentPageNumber } = state.documents;

  const page = state.documents.pages[currentPageNumber];

  const documentId = action.data.id;

  const item = page.itemsMap.get(documentId);

  const newItem = {
    ...item,
    isVisited: true,
  };

  const newItemsMap = page.itemsMap.set(documentId, newItem);

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap: newItemsMap,
        },
      },
    },
  };
};

export default markIsVisitedFromLinkReduce;
