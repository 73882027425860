import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Filters } from './state';

interface RequestParams {
  name: string;
  filters: Filters;
}

export function httpCreateFilter(requestParams: RequestParams) {
  const url = RESTPath.regulation.filters.origin;
  const request = http.post({
    url,
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpCreateFilter;
