import cloneDeep from 'lodash/cloneDeep';

import { AbstractFilterBlock } from './filterBlocks/abstract';

import { filterCreator } from './filterCreator';

import { DateBlock } from './filterBlocks/date/redux/date';

export class Filters {

  public blocksMap = new Map();

  public blocksList: string[] = [];

  private presets = {};

  createFilters(query: { blocksMap: Map<string, any>; blocksList: string[]; smartFilter: { query: string } }) {
    const clonedQuery = cloneDeep(query);
    this.blocksMap = clonedQuery.blocksMap;
    this.blocksList = [...clonedQuery.blocksList];
  }

  private createState(blocks: AbstractFilterBlock[]) {
    this.blocksList = [];
    blocks.forEach((block) => {
      const FilterBlock = filterCreator(block);
      const filterBlock = new FilterBlock();
      let preset = null;
      // @ts-ignore
      const hasPreset = this.presets && this.presets[block.type];
      if (hasPreset) {
        // @ts-ignore
        preset = this.presets[block.type];
      }

      filterBlock.createFromScratch(
        // @ts-ignore
        block.id,
        // @ts-ignore
        block.data,
        // @ts-ignore
        block.contains,
        preset,
      );

      this.addToBlocksMap(filterBlock);

      const index = this.calculateLastIndex(filterBlock);
      this.addToBlocksList(filterBlock.getId(), index);
    });
  }

  public removeFilterBlock(data: { id: string }) {
    const blocksList = [...this.blocksList];
    const findIndex = this.blocksList.findIndex((id) => id === data.id);
    blocksList.splice(findIndex, 1);
    this.blocksList = [...blocksList];
    this.blocksMap.delete(data.id);
  }

  // преобразуем блоки после получения с бэка
  // @ts-ignore
  public decorate(blocks: AbstractFilterBlock[], statePresets: StatePresets) {
    this.createPresets(statePresets);
    this.createState(blocks);
  }

  private createPresets(statePresets) {
    this.presets = statePresets;
  }

  // преобразуем блоки перед отправкой на бэк
  public undecorate() {
    return this.blocksList.map((id) => this.blocksMap.get(id).undecorate());
  }

  // По факту создается initial search.query state
  public createInitial() {
    // @ts-ignore
    const LawBlockConstructor = filterCreator({ type: 'law' });
    // @ts-ignore
    const DateBlockConstructor = filterCreator({ type: 'date' });
    // @ts-ignore
    const TextBlockConstructor = filterCreator({ type: 'text' });

    const lawBlock = new LawBlockConstructor();
    lawBlock.createNewBlock();
    const lawBlockId = lawBlock.getId();
    this.blocksList.push(lawBlockId);
    this.blocksMap.set(lawBlockId, lawBlock);

    const dateBlock = new DateBlockConstructor();
    dateBlock.createNewBlock();
    const dateBlockId = dateBlock.getId();
    this.blocksList.push(dateBlockId);
    this.blocksMap.set(dateBlockId, dateBlock);

    const textBlock = new TextBlockConstructor();
    textBlock.createNewBlock();
    const textBlockId = textBlock.getId();
    this.blocksList.push(textBlockId);
    this.blocksMap.set(textBlockId, textBlock);
  }

  private getBlockIndex(type) {
    let lastBlockIndex;
    this.blocksList.forEach((id, index) => {
      const currentBlockType = this.blocksMap.get(id).getType().id;
      if (currentBlockType === type) {
        lastBlockIndex = index;
      }
    });

    return lastBlockIndex;
  }

  private createNewBlock(data, pinned = false) {
    const Block = filterCreator(data);
    const block = new Block();
    block.createNewBlock();
    if (data.defaultData) {
      const currentData = block.getData();
      const nextData = {
        ...currentData,
        ...data.defaultData,
      };

      block.setData(nextData);
    }

    block.setPinned(pinned);

    return block;
  }

  private addToBlocksMap(block) {
    this.blocksMap.set(block.getId(), block);
  }

  private calculateLastIndex(block) {
    const index = this.getBlockIndex(block.getType().id);

    return index;
  }

  private addToBlocksList(id, index) {
    let blocksList = [...this.blocksList];
    const hasIndex = Number.isInteger(index);

    if (!hasIndex) {
      blocksList = blocksList.concat(id);
    } else {
      const pasteIndex = index + 1;
      blocksList.splice(pasteIndex, 0, id);
    }

    this.blocksList = [...blocksList];
  }

  public duplicateFilterBlock(
    data: {
      id: string,
      type: string,
      defaultData?: Record<string, unknown>
    },
  ) {
    const block = this.createNewBlock(data);
    this.addToBlocksMap(block);

    const index = this.blocksList.findIndex((id) => id === data.id);
    this.addToBlocksList(block.getId(), index);
  }

  public addFilterBlock(
    data: {
      type: string,
      defaultData?: Record<string, unknown>
    },
    pinned = false,
  ) {
    const block = this.createNewBlock(data);
    this.addToBlocksMap(block);

    block.setPinned(pinned);

    const index = this.calculateLastIndex(block);
    this.addToBlocksList(block.id, index);
  }

  // Обновляем поле contains у блока фильтра
  public updateContains(data) {
    const currentBlock = this.blocksMap.get(data.id);

    // @ts-ignore
    const Block = filterCreator({
      type: currentBlock.getType().id,
    });

    const block = new Block();
    block.createNewBlock();
    block.setId(currentBlock.getId());
    block.setData(currentBlock.getData());
    block.setContains(data.contains);
    block.setPinned(currentBlock.pinned);

    this.blocksMap.set(block.id, block);
  }

  public updatePin(data) {
    const currentBlock = this.blocksMap.get(data.id);

    // @ts-ignore
    const Block = filterCreator({
      type: currentBlock.getType().id,
    });

    const block = new Block();
    block.createNewBlock();
    block.setId(currentBlock.getId());
    block.setData(currentBlock.getData());

    if (currentBlock.getType().id !== DateBlock.type) {
      block.setContains(currentBlock.getContains());
    }

    block.setPinned(!currentBlock.pinned);

    this.blocksMap.set(block.id, block);
  }

  // обновляем поле data у блока фильтра
  public updateData(data) {
    const currentBlock = this.blocksMap.get(data.id);
    // @ts-ignore
    const Block = filterCreator({
      type: currentBlock.getType().id,
    });

    const block = new Block();
    block.createNewBlock();
    block.setId(currentBlock.getId());

    const currentData = currentBlock.getData();
    const nextData = {
      ...currentData,
      ...data.data,
    };

    block.setData(nextData);
    block.setPinned(currentBlock.pinned);

    if (currentBlock.getType().id !== DateBlock.type) {
      block.setContains(currentBlock.getContains());
    }

    const newBlocksMap = new Map(this.blocksMap);
    newBlocksMap.set(block.id, block);

    return newBlocksMap;
  }

}

export default Filters;
