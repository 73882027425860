import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchFolderFilters() {
  const url = RESTPath.regulation.filters.folder;
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchFolderFilters;
