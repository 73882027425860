import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import http from '@http';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

interface Document {
  id: string;
  title: string;
  hasChildren: boolean;
}

interface Article {
  id: string;
  title: string;
  hasChildren: boolean;
}

interface Clause {
  id: string;
  title: string;
}

export interface LawBlockData {
  document: Document;
  article: Article;
  clause: Clause;
}

interface LawSuggestItem {
  id: string;
  name: string;
  has_articles: boolean;
  has_clauses: boolean;
  is_active: boolean;
  is_child: boolean;
}

interface LawSuggestResponse {
  result: LawSuggestItem[];
}

interface ArticleSuggestItem {
  id: string;
  name: string;
  document_id: string;
  has_clauses: boolean;
}

interface ArticleSuggestResponse {
  result: ArticleSuggestItem[];
}

interface ClauseSuggestItem {
  id: string;
  name: string;
  document_id: string;
}

interface ClauseSuggestResponse {
  result: ClauseSuggestItem[];
}

export class LawBlock extends AbstractFilterBlock {

  static type = 'law';

  static DTOType = 'law';

  static blockTitle = 'Норма права';

  static category = category.document;

  static SuggestLawAPI = '/autocomplete/law';

  static SuggestArticleAPI = '/autocomplete/article';

  static SuggestClauseAPI = '/autocomplete/clause';

  protected defaultData: LawBlockData = {
    document: {
      // @ts-ignore
      id: null,
      title: '',
      hasChildren: false,
    },
    article: {
      // @ts-ignore
      id: null,
      title: '',
      hasChildren: false,
    },
    clause: {
      // @ts-ignore
      id: null,
      title: '',
    },
  };

  constructor() {
    super();

    this.setCategory(LawBlock.category);
    this.setType({
      id: LawBlock.type,
      DTOType: LawBlock.DTOType,
      title: LawBlock.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const block = {
      id,
      data,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      type: LawBlock.DTOType,
      data: this.getData(),
    };
  }

  public getItemsForLawSuggest = async (query = 'а') => {
    const getItems = async (): Promise<LawSuggestResponse> => {
      const request = {
        url: LawBlock.SuggestLawAPI,
        body: {
          query,
        },
      };

      const response = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.result.map((item) => ({
        id: item.id,
        title: item.name,
        isDisabled: !item.is_active,
        hasArticles: item.has_articles,
        hasClauses: item.has_clauses,
      })),
    };
  };

  public getItemsForArticleSuggest = async (query, documentId) => {
    const getItems = async (): Promise<ArticleSuggestResponse> => {
      const request = {
        url: LawBlock.SuggestArticleAPI,
        body: {
          query,
          page: 1,
          parent: documentId,
        },
      };

      const response = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.result.map((item) => ({
        id: item.id,
        title: item.name,
        isDisabled: false,
        hasClauses: item.has_clauses,
      })),
    };
  };

  public getItemsForClauseSuggest = async (query, documentId, articleId) => {
    const getItems = async (): Promise<ClauseSuggestResponse> => {
      const request = {
        url: LawBlock.SuggestClauseAPI,
        body: {
          query,
          page: 1,
          article_id: articleId,
          document_id: documentId,
        },
      };

      const response = await http.get(request)
        .then(http.handleResponse)
        .then(http.parseResponse);

      return response;
    };

    const response = await getItems();

    return {
      items: response.result.map((item) => ({
        id: item.id,
        isDisabled: false,
        title: item.name,
      })),
    };
  };

  // eslint-disable-next-line
  public validate() {}

  getItemsForSuggest(): Promise<any> {
    return Promise.resolve(undefined);
  }

}

export default LawBlock;
