import { RESTPath } from '@router/path.rest';
import http from '@http';

interface RequestParams {
  message: string;
}

export function httpCreatePersonalPackage(requestParams: RequestParams = { message: '' }) {
  const request = http.post({
    url: RESTPath.packages.createPersonalPackage,
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpCreatePersonalPackage;
