import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { UpdateTitleConnect } from './updateTitle/updateTitle.connect';
import { RemoveConnect } from './remove/remove.connect';

import s from '../itemMenu.style';

interface MainLevel {
  title: string
  onClick: () => void
  hasArrow: boolean
}

export type Id = number;

interface Props {
  id: Id;
  onLevelChange: (params: { wide: boolean; level: number }) => void;
  closePopover: () => void;
}

enum ViewTypes {
  rename = 'RENAME',
  remove = 'REMOVE',
  default = '',
}

enum Level {
  main,
  edit,
}

export class ItemMenu extends React.PureComponent<Props> {

  state = {
    view: {
      type: '',
      level: Level.main,
    },
  };

  setView(type: ViewTypes, level: number) {
    this.setState({
      view: {
        type,
        level,
      },
    });
  }

  handleSetViewToMain = () => {
    this.props.onLevelChange({ wide: false, level: Level.main });
    this.setView(ViewTypes.default, Level.main);
  };

  handleSetRenameLevelToModify = () => {
    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.rename, Level.edit);
  };

  handleSetRemoveLevelToModify = () => {
    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.remove, Level.edit);
  };

  renderArrowRight = () => {
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
    const points = iconsPath.get('arrowRight');

    return (
      <s.ArrowItem>
        <Icon
          points={points}
          transform={transform}
          color="currentColor"
        />
      </s.ArrowItem>
    );
  };

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  renderMainLevel(renderData: MainLevel) {
    const firstLevel = this.state.view.level === Level.main;
    if (!firstLevel) {
      return null;
    }

    const renderArrow = () => {
      if (!renderData.hasArrow) {
        return null;
      }

      return this.renderArrowRight();
    };

    return (
      <s.ItemWrapper>
        <s.Item
          onClick={renderData.onClick}
        >
          <s.ItemTitle>{renderData.title}</s.ItemTitle>
          {renderArrow()}
        </s.Item>
      </s.ItemWrapper>
    );
  }

  renderMainRenameLevel() {
    const renameData = {
      title: 'Переименовать папку',
      onClick: this.handleSetRenameLevelToModify,
      hasArrow: true,
    };

    return this.renderMainLevel(renameData);
  }

  renderModifyRenameLevel() {
    const isRename = this.state.view.type === ViewTypes.rename;
    if (!isRename) {
      return null;
    }

    return (
      <UpdateTitleConnect
        id={this.props.id}
        onGoBack={this.handleSetViewToMain}
      />
    );
  }

  renderModifyRemoveLevel() {
    const isRemove = this.state.view.type === ViewTypes.remove;
    if (!isRemove) {
      return null;
    }

    return (
      <RemoveConnect
        onGoBack={this.handleSetViewToMain}
        id={this.props.id}
        onClosePopover={this.props.closePopover}
      />
    );
  }

  renderMainRemoveLevel() {
    const removeData = {
      title: 'Удалить папку',
      onClick: this.handleSetRemoveLevelToModify,
      hasArrow: true,
    };

    return this.renderMainLevel(removeData);
  }

  renderLevels() {
    const firstLevel = this.state.view.level === Level.main;
    if (firstLevel) {
      return (
        <s.List>
          {this.renderMainRenameLevel()}
          {this.renderMainRemoveLevel()}
        </s.List>
      );
    }

    return (
      <s.Edit>
        {this.renderModifyRenameLevel()}
        {this.renderModifyRemoveLevel()}
      </s.Edit>
    );

  }

  render() {
    return (
      <s.Root
        onClick={this.stopPropagation}
      >
        {this.renderLevels()}
      </s.Root>
    );
  }

}

export default ItemMenu;
