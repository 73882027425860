import {
  Id,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

interface Params {
  documentId: Id;
  packageId: Id;
  tagId: string;
}

export function httpSetTag(params: Params) {
  const request = http.post({
    url: RESTPath.packages.setTag({ documentId: params.documentId, packageId: params.packageId }),
    data: {
      tag_id: params.tagId,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSetTag;
