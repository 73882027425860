import { setSearchIdReduce } from './setSearchId.reduce';

import type { ActionData } from './setSearchId.reduce';

const actionType = 'PACKAGE/SET_SEARCH_ID';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setSearchId = {
  actionType,
  createAction,
  reduce: setSearchIdReduce,
};

export default setSearchId;
