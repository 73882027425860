import { changeRoleReduce } from './changeRole.reduce';

import type { ActionData } from './changeRole.reduce';

const actionType = 'REGULATION/FOLDER/USERS/CHANGE_ROLE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const changeRole = {
  actionType,
  createAction,
  reduce: changeRoleReduce,
};

export default changeRole;
