import { connect } from 'react-redux';

import { AnnotationContent } from './annotationContent';

const mapStateToProps = (state, ownProps) => {
  const annotation = state.annotations.items.get(ownProps.id);

  return {
    ...annotation,
  };
};

export const AnnotationContentConnect = connect(
  mapStateToProps,
)(AnnotationContent);

export default AnnotationContentConnect;
