/* eslint-disable */
import React from 'react';

import s from './tooltipRelationsClauses.style';

export const TooltipRelationsClauses = (props = { items: [], onClick: () => {} }) => (
  <s.Root>
    {
        props.items.map((item) => (
          <s.AnchorItem
            onClick={props.onClick}
            href={item.link}
            target="_blank"
          >
            <s.Title>
              {item.title}
            </s.Title>
          </s.AnchorItem>
        ))
      }
  </s.Root>
);

export default TooltipRelationsClauses;
