import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  height: number;
  shrink: boolean;
  skewPadding: boolean;
  value: string;
  focused: boolean;
}

function textEllipsis() {
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

style.Root = styled.div`
  position: relative;
  background-color: ${theme.colors.white};
  width: 100%;
  display: flex;

  :hover,
  :focus {
    background-color: #4183D712;
  }

  ${(props: Props) => props.focused && css`
    background-color: #4183D712;
  `}
`;

style.TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: ${(props: Props) => props.height}px;
  padding: 16px;
  margin-top: 0;
  border: 0;
  outline: 0;
  overflow-y: auto;
  resize: none;
  background-color: ${theme.colors.transparent};
  box-sizing: content-box;

  ${(props: Props) => props.skewPadding && css`
    padding: 24px 16px 7px;
  `}

  :not(:focus) {
    cursor: pointer;
  }

  ${(props: Props) => !!props.value && css`
    margin-top: 25px;
    padding-top: 0;
  `}

  &[disabled] {
    background-color: ${theme.colors.white};
    cursor: default;
  }
`;

style.Label = styled.label`
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  color: ${theme.colors.stone};
  transform: translate(0, 1px) scale(1);
  transform-origin: top left;
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: none;

  ${(props: Props) => props.shrink && css`
    color: ${theme.colors.blue};
    transform: translate(0, -7px) scale(0.85);
  `}

  &[disabled] {
    color: ${theme.colors.disabled};
  }
`;

style.LabelText = styled.div`
  ${textEllipsis};
`;

style.TextMeasureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
`;

style.TextMeasure = styled.div`
  min-height: 16px;
  padding: 24px 16px 7px;
  white-space: pre-line;
  word-wrap: break-word;
  background-color: ${theme.colors.white};
`;

export default style;
