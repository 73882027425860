import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Cell } from './itemDetail.cell';

import s from './item.style';

import { BlockData } from './itemDetail.connect';

interface Item {
  id: string;
  title: string;
}

interface Props {
  shouldLoadMonth: boolean;
  items: Item[];
  onFetch: () => void;
  id: string;
  onAddBlock(data: BlockData): void;
}

interface State {
  currentPage: number;
  items: Item[];
}

export class ItemDetail extends React.PureComponent<Props, State> {

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    if (this.props.shouldLoadMonth) {
      this.props.onFetch();
    }
  }

  renderRow = (column) => (
    <s.InnerRow>
      {column.map((cell) => {
        const handleItemClick = () => {
          const data = {
            year: this.props.id,
            month: column[0].title,
            columnId: cell.id,
          };

          this.props.onAddBlock(data);
        };

        return (
          <Cell
            key={cell.id}
            cell={cell}
            onClick={handleItemClick}
          />
        );
      })}
    </s.InnerRow>
  );

  renderArticles() {
    if (this.props.shouldLoadMonth) {
      return null;
    }

    if (isEmpty(this.props.items)) {
      return null;
    }

    return this.props.items.map(this.renderRow);
  }

  render() {
    return (
      <s.Table>
        {this.renderArticles()}
      </s.Table>
    );
  }

}

export default ItemDetail;
