import React from 'react';

import { DocumentId } from './redux/state';

import { Header } from '../header';

import { AnnotationContentConnect } from './annotationContent.connect';

import s from './annotation.style';

interface AnnotationProps {
  id: DocumentId
  rating: string;
  inProgress: boolean;
  isExpanded: boolean;
  shouldRender: boolean;
  hasError: boolean;
  onCreate(id: DocumentId): void;
  onToggle(id: DocumentId): void;
  onRatingUpdate(data: { documentId: DocumentId, value: string }): void;
}

export class Annotation extends React.PureComponent<AnnotationProps> {

  static title = 'аннотация';

  static errorTitle = 'аннотацию';

  renderBody() {
    if (!this.props.shouldRender) {
      return null;
    }

    if (this.props.inProgress) {
      return null;
    }

    if (this.props.hasError) {
      return null;
    }

    if (!this.props.isExpanded) {
      return null;
    }

    return (
      <s.ContentContainer>
        <AnnotationContentConnect id={this.props.id} />
      </s.ContentContainer>
    );
  }

  render() {
    return (
      <s.Root
        id="annotation"
      >
        <Header
          title={Annotation.title}
          errorTitle={Annotation.errorTitle}
          {...this.props}
        />
        {this.renderBody()}
      </s.Root>
    );
  }

}

export default Annotation;
