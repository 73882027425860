import moment from 'moment';
import { historyBlocksMap } from './blocks/blocks.map';
import { containsMap } from '../form/components/contains/contains';

type ValueOfFilter = string | string[];

interface SmartFilter {
  queyry: string;
  hasChange: boolean;
  preparing: boolean;
}

export interface HistoryProps {
  id: number
  created: string
  moreFiltersCount?: string
  filters: string[]
  smartFilter?: SmartFilter;
  isRemoving?: boolean
}

export class SearchHistory {

  private history: HistoryProps[] | undefined;

  public getHistory() {
    return this.history;
  }

  private setHistory(history) {
    this.history = history;
  }

  private getFilters(filters, presets) {
    const currentFilters = filters.map((filter) => this.getFilter(filter, presets));

    return currentFilters;
  }

  private getFilter(data, presets): string[] {
    const dataType = data ? data.DTOType : '';
    const block = historyBlocksMap.get(dataType);
    let result: string[] = [];

    if (block) {
      const type: string = block.blockTitle;
      const contains: string = data.contains !== null ?
        containsMap.get(data.contains).historyTitle :
        containsMap.get(true).historyTitle;

      let value: ValueOfFilter = block.transform(data.data, presets);

      const valueIsArray = Array.isArray(value);

      if (!valueIsArray) {
        value = [`${value}`];
      }

      // @ts-ignore
      result = [type, contains, ...value].filter((element) => element !== '');
    }

    return result;
  }

  private createCreatedDate = function (created: string): string {
    if (!created) {
      return '';
    }

    const MOSCOW_TIME_ZONE = '+03:00';
    const createdAt = `${created}${MOSCOW_TIME_ZONE}`;
    const createdAtInLocalTimezone = moment(createdAt).format('DD.MM.YYYY');

    return createdAtInLocalTimezone;
  };

  private buildSearchItem = (presets, historyElement) => {
    const searchItem: HistoryProps = {
      // @ts-ignore
      id: null,
      filters: [],
      created: '',
      smartFilter: null,
      isRemoving: false,
    };

    searchItem.id = historyElement.id;
    searchItem.filters = this.getFilters(historyElement.filters, presets);
    searchItem.created = this.createCreatedDate(historyElement.created);
    searchItem.isRemoving = historyElement.isRemoving;
    searchItem.smartFilter = historyElement.smartFilter;

    return searchItem;
  };

  private generateHistoryElement = (filter) => {
    const filters = [];

    filter.query.blocksList.forEach((id) => {
      const block = filter.query.blocksMap.get(id);
      if (block) {
        const filterElement = block.decorate();

        // @ts-ignore
        filters.push(filterElement);
      }
    });

    const isRemoving = Boolean(filter.isRemoving);
    const historyElement: HistoryProps = {
      id: filter.id,
      created: filter.created,
      filters,
      smartFilter: filter.smartFilter,
      isRemoving,
    };

    return historyElement;
  };

  public transform(filters, presets): void {
    const history = filters.map(this.generateHistoryElement);

    let data = [];
    const isHistories = history.length > 0;

    if (isHistories) {
      data = history.map(this.buildSearchItem.bind(this, presets));
    }

    this.setHistory(data);
  }

}

export default SearchHistory;
