/* eslint-disable max-len */

import {
  keyframes,
  css,
} from 'styled-components';

import { styled } from '@components/styled';

export interface LoaderProps {
  size?: number;
  viewMode?: boolean;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const margin = '-16px';

const getSize = (size: number) => `${size}px`;

const style: any = {};

style.Loader = styled.div<LoaderProps>`
  width: ${(props) => getSize(props.size)};
  height: ${(props) => getSize(props.size)};
  border: 3px solid transparent;
  border-color: ${(props) => `${props.theme.colors.globalLines} ${props.theme.colors.globalLines} ${props.theme.colors.blue}`};
  border-radius: 50%;
  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;

  ${(p: LoaderProps) => !p.viewMode && css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin: ${margin} 0 0 ${margin};
  `}
`;

export default style;
