import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Loader } from '@components/loader';
import { SearchField } from '@components/searchField/searchField';

import { BaseFoldersConnect } from './folders/baseFolders.connect';
import { FoldersConnect } from './folders.connect';

import { FiltersConnect } from './filters.connect';

import s from './sidebar.style';

interface SidebarProps {
  activeItemId: string;
  loading: boolean;
}

interface State {
  searchQuery: string;
}

export class Sidebar extends React.PureComponent<SidebarProps, State> {

  private placeholder = 'Поиск';

  state = {
    searchQuery: '',
  };

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.marketingEvent(e.target.value);
    this.setState({
      searchQuery: e.target.value,
    });
  };

  private marketingEvent(query = '') {
    sendAmplitudeData('regulation:search_folder', { query });
    Userpilot.track('regulation:search_folder', { query });
  }

  renderLoader() {
    return (
      <s.Root>
        <s.Loader>
          <Loader size={32} />
        </s.Loader>
      </s.Root>
    );
  }

  renderSearchField = () => (
    <SearchField
      value={this.state.searchQuery}
      placeholder={this.placeholder}
      onChange={this.handleSearchQuery}
    />
  );

  renderItems() {
    return (
      <>
        {this.renderSearchField()}
        <s.Folders>
          <BaseFoldersConnect />
          <FoldersConnect
            query={this.state.searchQuery}
          />
          <FiltersConnect
            query={this.state.searchQuery}
          />
        </s.Folders>
      </>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return this.renderItems();
  }

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default Sidebar;
