import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchProjects(searchAfter: string, filters: any, filterId?: number) {
  const url = RESTPath.regulation.projects.get(searchAfter);
  const data = {
    filters,
    id: filterId,
  };

  if (!data.id) {
    delete data.id;
  }

  const request = http.post({
    url,
    data,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchProjects;
