import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { httpSaveFirstname } from './saveFirstname.http';

import { saveFirstname } from './saveFirstname';

class SaveFirstnameThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { text: string }) {
    sendAmplitudeData('user: changeFirstname');
    Userpilot.track('user: changeFirstname');
    this.dispatchSetFirstname(params);
    await this.httpSaveFirstname(params);

  }

  private dispatchSetFirstname(data) {
    this.dispatch(saveFirstname.createAction(data));
  }

  private async httpSaveFirstname(params) {
    try {
      await httpSaveFirstname(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export async function saveFirstnameThunk(dispatch, params) {
  const thunk = new SaveFirstnameThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default SaveFirstnameThunk;
