import React from 'react';

import s from './loader.style';

import type { LoaderProps } from './loader.style';

const DEFAULT_SIZE = 32;

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const size = props.size || DEFAULT_SIZE;
  const viewMode = props.viewMode || false;

  return (
    <s.Loader
      size={size}
      viewMode={viewMode}
    />
  );
};

export default Loader;
