import {
  styled,
  css,
} from '@components/styled';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: row;
`;

style.Button = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f4f5;
  height: 32px;
  padding: 8px 16px 8px 8px;
  border-radius: 16px;
  cursor: pointer;
`;

style.Divider = styled.div`
  width: 8px;
`;

style.GoodButton = styled(style.Button)`
  ${(props) => props.isActive && css`
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.green};

    > i {
      color: ${props.theme.colors.white};
    }
  `}
`;

style.BadButton = styled(style.Button)`
  ${(props) => props.isActive && css`
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.brightOrange};

    > i {
      color: ${props.theme.colors.white};
    }
  `}
`;

export default style;
