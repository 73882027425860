import { Id } from '@pages/regulation/sidebar/folders/redux/item';
import { StateBuilder } from '@pages/regulation/sidebar/folders/redux/state';

export interface ActionData {
  id: Id;
  title: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateFolderTitleReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const params = {
    id: action.data.id,
    title: action.data.title,
  };

  const newState = new StateBuilder(state.folders);
  newState.updateItemTitle(params);
  const subscriptions = newState.getState();

  return {
    ...state,
    subscriptions,
  };
};

export default updateFolderTitleReduce;
