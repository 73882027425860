import {
  processStartReduce,
  ActionData,
} from './processStart.reduce';

export const actionType = 'DOWNLOADS/START';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const processStart = {
  actionType,
  createAction,
  reduce: processStartReduce,
};
