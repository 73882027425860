import { StateBuilder } from './state';

import { AttributesDTO } from './fetchAttributes.http';

export interface ActionData {
  id: number;
  result: AttributesDTO;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchAttributesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.preview);
  newState.buildPreviewAttributes(action.data.id, action.data.result);

  const preview = {
    ...newState.getState(),
  };

  return {
    ...state,
    preview,
  };
};

export default fetchAttributesReduce;
