import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { Settings } from './settings';

import s from '../item.style';

interface ItemProps {
  id: string;
  title: string;
  active: boolean;
  count: number;
  onSelectItem(id:string): void;
}

interface ItemState {
  openConfigPopover: boolean;
  hovered: boolean;
}

export class Item extends React.PureComponent<ItemProps, ItemState> {

  state = {
    openConfigPopover: false,
    hovered: false,
  };

  handleMouseOver = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  handleSettingsMenuVisible = (visible: boolean) => {
    this.setState({
      openConfigPopover: visible,
    });
  };

  handleSelectItem = () => this.props.onSelectItem(this.props.id);

  renderConfig() {
    const showConfigElement = this.state.hovered || this.state.openConfigPopover;
    if (!showConfigElement) {
      return null;
    }

    return (
      <s.Settings>
        <Settings onMenuVisible={this.handleSettingsMenuVisible} id={this.props.id} />
      </s.Settings>
    );
  }

  renderTitle() {
    return (
      <s.Title>
        {this.renderIcon()}
        {this.props.title}
      </s.Title>
    );
  }

  renderIcon() {
    const iconPath = iconsPath.get('packageFilter');
    const color = this.props.active ? 'white' : '#22313f';

    return (
      <s.Icon>
        <Icon path={iconPath} color={color} />
      </s.Icon>
    );
  }

  render() {
    return (
      <s.Root
        active={this.props.active}
        hovered={this.state.hovered}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleSelectItem}
      >
        {this.renderConfig()}
        {this.renderTitle()}
      </s.Root>
    );
  }

}

export default Item;
