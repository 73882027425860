export const fetchCourtsStartReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    courts: {
      ...state.courts,
      pageLoading: true,
    },
  };
};

export default fetchCourtsStartReduce;
