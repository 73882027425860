import { connect } from 'react-redux';

import { Settings } from './settings';

const mapStateToProps = (state) => {
  const {
    activeItemId,
    itemsMap,
    itemsList,
  } = state.packages.packages;

  let isOwner = false;

  if (itemsMap.get(activeItemId)) {
    isOwner = itemsMap.get(activeItemId).isOwner;
  }

  const isExist = !!itemsList.find((item) => item === activeItemId);

  return {
    id: activeItemId,
    disabled: !activeItemId || !isExist,
    isOwner,
  };
};

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
