import { Dispatch } from 'redux';

import { selectPackageThunk } from '../../../redux/packages/selectPackage.thunk';
import { resetToInitial as resetPackages } from '../../../redux/packages/resetToInitial';
import { resetToInitial as resetDocuments } from '../../../redux/documents/resetToInitial';
import { fetchDocuments } from '../../../redux/documents/fetchDocuments';

import { removePackage } from './removePackage';

import { httpRemovePackage } from './removePackage.http';

export class RemovePackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { packageId: number }) {

    this.dispatchChangePackageSelection(params.packageId);
    this.dispatchRemovePackage(params);

    try {
      await httpRemovePackage(params);
    } catch (error) {
      throw Error(error.status);
    }

  }

  private dispatchRemovePackage(params) {
    this.dispatch(removePackage.createAction({ packageId: params.packageId }));
  }

  private dispatchChangePackageSelection(packageId) {
    const isActive = this.getState().packages.packages.activeItemId === packageId;
    if (!isActive) {
      return;
    }

    const { itemsList } = this.getState().packages.packages;
    const isLastItem = itemsList.length === 1;
    if (isLastItem) {
      this.dispatchResetToInitial();
    }

    const findIndex = itemsList.findIndex((id) => id === packageId);
    const hasPreviousPackage = !!findIndex;
    if (hasPreviousPackage) {
      const nextPackageId = itemsList[findIndex - 1];
      selectPackageThunk(this.dispatch, { sourceId: nextPackageId });

      return;
    }

    const hasNextPackage = !!itemsList[findIndex + 1];
    if (hasNextPackage) {
      const nextPackageId = itemsList[findIndex + 1];
      selectPackageThunk(this.dispatch, { sourceId: nextPackageId });
    }
  }

  private dispatchResetToInitial() {
    this.dispatch(resetPackages.createAction());
    this.dispatch(resetDocuments.createAction());

    const emptyDocuments = {
      sourceId: 0,
      pageNumber: 0,
      result: {
        page: 0,
        total_pages: 0,
        total_count: 0,
        documents: [],
      },
    };

    this.dispatch(fetchDocuments.createAction(emptyDocuments));
  }

}

export function removePackageThunk(dispatch, params) {
  const thunk = new RemovePackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default RemovePackageThunk;
