import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Id } from '@pages/regulation/workspace/redux/state';

export interface SpectractorDTO {
  user_id: number;
  name: string;
  email: string;
  avatar: string;
  status: {
    title: string;
    date: string;
  }
}

export type SpectractorsDTO = SpectractorDTO[];

export function httpFetchSpectractors(folderId: Id, projectId: Id): Promise<SpectractorsDTO> {
  const url = RESTPath.regulation.projects.preview.spectractors(folderId, projectId);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSpectractors;
