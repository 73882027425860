import { connect } from 'react-redux';

import { fetchSearchThunk } from '../../redux/fetchSearch.thunk';

import { SearchDrawer } from './searchDrawer';

const mapStateToProps = (state) => ({
  renderSmartFilters: state.currentUser.accessTo.includes('caselook_pro'),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMount() {
    fetchSearchThunk(dispatch, ownProps.id);
  },
  onClose() {
    fetchSearchThunk(dispatch, ownProps.id);
  },
});

export const SearchDrawerConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchDrawer);

export default SearchDrawerConnect;
