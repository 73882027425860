import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  background: white;
  width: 320px;
  max-height: 400px;
  overflow-y: auto;
`;

style.Loader = styled(style.Root)`
  position: relative;
  display: flex;
  height: 45px;

  > div {
    margin: -12px 0 0 -16px;
  }
`;

style.SelectedContainer = styled.div`
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor}
`;

style.EmptyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;

  color: ${(props: Props) => props.theme.colors.stone};
`;

style.CreateItem = styled.div`
  padding: 12px 16px 13px 12px;
  position: relative;
  user-select: none;

  display: flex;
  align-items: center;

  color: ${(props: Props) => props.theme.colors.defaultText};
`;

style.CreateItemLoader = styled.div`
  position: relative;
  display: flex;
  height: 24px;
  width: 24px;
  margin-right: 8px;

  > div {
    top: 19px;
    left: 19px;
  }
`;

style.CreatePrefix = styled.span`
  color: ${(props: Props) => props.theme.colors.stone}
`;

export default style;
