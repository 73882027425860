import { connect } from 'react-redux';

import { Filters } from './filters';

const mapStateToProps = (state) => ({
  disabled: state.regulation.filters.loading,
});

export const FiltersConnect = connect(
  mapStateToProps,
)(Filters);

export default FiltersConnect;
