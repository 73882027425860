import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.white};
  z-index: 99999;
`;

style.Content = styled.div`
  padding: 15px 64px 16px;
  margin: 0 auto;
  min-width: 960px;
  max-width: 1152px;
`;

style.HeaderCassette = styled.div`
  > div {
    border: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`;

style.TextField = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.Separator = styled.div`
  height: 16px;
`;

export default style;
