import { Dispatch } from 'redux';

import { httpSavePinnedFilters } from './savePinnedFilters.http';

export class SavePinnedFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    this.saveFilters();
  }

  private saveFilters() {
    const requestParams = this.prepareRequest();
    httpSavePinnedFilters(requestParams);
  }

  private prepareRequest() {
    const { query } = this.getState().search;

    return query.blocksList.reduce((acc, id) => {
      const filter = query.blocksMap.get(id);
      if (filter.pinned) {
        acc.push(filter.type.DTOType);
      }

      return acc;
    }, []);
  }

}

export function savePinnedFiltersThunk(dispatch) {
  const thunk = new SavePinnedFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default savePinnedFiltersThunk;
