import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Source } from '@providers/download/download.http';
import { processThunk } from '@providers/download/process.thunk';
import { isNaN } from 'lodash';
import { fetchProjectThunk } from './redux/fetchProject.thunk';
import { fetchChatThunk } from './redux/fetchChat.thunk';
import { fetchSpectractorsThunk } from './redux/fetchSpectractors.thunk';

import { Preview } from './preview';

export function getPreview(id, projects) {
  const project = projects.itemsMap.get(id);
  if (!project) {
    return {
      id: null,
      information: {
        type: null,
      },
      project: {
        loading: false,
      },
    };
  }

  return {
    id,
    ...projects.itemsMap.get(id).preview,
  };
}

const mapStateToProps = (state, ownProps) => {
  const preview = getPreview(ownProps.id, state.regulation.projects.projects);

  const { activeItemId, folders } = state.regulation.folders;

  const isNumber = typeof (activeItemId) === 'number';
  const isFolder = isNumber && !isNaN(activeItemId);
  let role = state.currentUser.licenses.regulation;
  if (isFolder) {
    role = folders.itemsMap.get(activeItemId).role;
  }

  return {
    id: preview.id,
    isFolder,
    role,
    title: preview.information.type,
    projectLoading: preview.project.loading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchPreview() {
    fetchProjectThunk(dispatch, { id: ownProps.id });
  },
  onFetchChat() {
    fetchChatThunk(dispatch, { id: ownProps.id });
  },
  onFetchSpectractors() {
    fetchSpectractorsThunk(dispatch, { id: ownProps.id });
  },
  onDownload(id: string, name: string) {
    const params = {
      source: Source.regulationProject,
      id,
    };

    sendAmplitudeData('regulation:download_project', { id, name });
    Userpilot.track('regulation:download_project');
    processThunk(dispatch, params);
  },
});

export const PreviewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Preview);

export default PreviewConnect;
