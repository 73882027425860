import {
  toggleSummaryReduce,
  ActionData,
} from './toggleSummary.reduce';

const actionType = 'SUMMARY/TOGGLE_EXPANDED';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const toggleSummary = {
  actionType,
  createAction,
  reduce: toggleSummaryReduce,
};

export default toggleSummary;
