import { connect } from 'react-redux';

import { RoutePath } from '@router/path';

import { PasswordRecoveryThunk } from './passwordRecovery.thunk';
import { PasswordRecovery } from './passwordRecovery';

const mapDispatchToProps = () => ({
  async onSend(email) {
    const passwordRecovery = new PasswordRecoveryThunk();
    const result = await passwordRecovery.invoke(email);

    if (result.result) {
      window.location.href = `#${RoutePath.passwordRecoveryEmailSent}`;
    }
  },
});

export const PasswordRecoveryConnect = connect(
  null,
  mapDispatchToProps,
)(PasswordRecovery);

export default PasswordRecoveryConnect;
