import { ReducerBuilder } from '@redux/reducer.builder';

import { toggleLayoutType } from './toggleLayoutType';
import { updateTableSettings } from './updateTableSettings';
import { toggleCommentary } from './toggleCommentary';

export const reducerBuilder = new ReducerBuilder([
  toggleLayoutType,
  updateTableSettings,
  toggleCommentary,
]);

export default reducerBuilder;
