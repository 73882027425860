import { updateFindInReduce } from './updateFindIn.reduce';
import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/TEXT/UPDATE_FIND_IN`;

export interface Data {
  findIn: string;
}

export interface ActionData {
  id: string;
  data: Data;
}

export interface Action {
  type: typeof actionType;
  data: ActionData;
}

const createAction = (data: ActionData): Action => ({
  type: actionType,
  data,
});

export const updateFindIn = {
  actionType,
  createAction,
  reduce: updateFindInReduce,
};

export default updateFindIn;
