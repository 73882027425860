import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchLaws } from './fetchLaws';
import { fetchArticles } from './fetchArticles';
import { fetchSettings } from './fetchSettings';
import { changeSortColumn } from './changeSortColumn';
import { resetDocuments } from './resetDocuments';
import { updateTableSettings } from '../header/settingsPopover/redux/updateTableSettings';
import {
  updateView,
  updateViewReset,
} from '../header/editViewPopover/redux/updateView';

export const reducerBuilder = new ReducerBuilder([
  fetchLaws,
  fetchArticles,
  fetchSettings,
  changeSortColumn,
  resetDocuments,
  updateTableSettings,
  updateView,
  updateViewReset,
]);

export default reducerBuilder;
