import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchAuthorPackageDocuments(requestParams: { sourceId: number; pageNumber: number }) {
  const request = http.get({
    url: RESTPath.authorPackage.getDocuments(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchAuthorPackageDocuments;
