import { documentType } from '../../redux/state';

const prepareItem = (item) => ({
  id: item.id,
  title: item.name,
  appealState: item.appeal_state,
  caseResult: item.case_result,
});

const prepareItems = (fetchItems, currentItems) => fetchItems.reduce((accumulator, currentValue) => {
  accumulator.itemsList = accumulator.itemsList.concat(currentValue.id);
  accumulator.itemsMap = accumulator.itemsMap.set(currentValue.id, prepareItem(currentValue));

  return accumulator;
}, currentItems);

export interface ActionData {
  searchId: string | number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchDocumentTypeReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const items = action.data.result;

  const transformItems = prepareItems(items, state.analysis.itemsMap[documentType.getId()].items);

  return {
    ...state,
    id: action.data.searchId,
    analysis: {
      ...state.analysis,
      itemsMap: {
        ...state.analysis.itemsMap,
        [documentType.getId()]: {
          ...state.analysis.itemsMap[documentType.getId()],
          shouldLoadInitialData: false,
          items: transformItems,
        },
      },
    },
  };
};

export default fetchDocumentTypeReduce;
