import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

style.Content = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

style.ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 14px 32px 0 32px;
  margin: 0 auto;
  min-width: 960px;
  max-width: 1152px;
`;

style.ListWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export default style;
