import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Id } from '@pages/regulation/workspace/redux/state';

import { fetchFoldersThunk } from '@pages/regulation/sidebar/folders/redux/fetchFolders.thunk';
import { updateFoldersCount } from '@pages/regulation/workspace/redux/updateFoldersCount';

import { addProject } from './addProject';
import { removeProject } from './removeProject';

import { httpAddProjects } from './addProjects.http';

interface AddProjectsToFolderParams {
  folderId: Id;
}

export class AddProjectsToFolderThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: AddProjectsToFolderParams) {
    const state = this.getState().regulation.searchResult;
    const { selected } = state.itemsMap.get(params.folderId);

    if (!selected) {
      await this.addProjects(params);

      return;
    }

    this.dispatchRemoveProjectSucceed(params);
  }

  private async addProjects(params) {
    try {
      this.dispatchAddProjectsSucceed(params);

      await this.addToFolder(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async addToFolder(params) {
    let ids = this.getState().regulation.projects.selectProjects;
    if (!ids.length) {
      const projectsIds = this.getState().regulation.projects.projects.itemsList;
      ids = projectsIds.slice(0, 100);
    }

    const requestParams = {
      folderId: params.folderId,
      ids,
    };

    this.marketingEvent(requestParams);
    const response = await httpAddProjects(requestParams);
    this.dispatchUpdateFoldersCount(response);
    this.fetchFolders();
  }

  private marketingEvent(params) {
    sendAmplitudeData('regulation:add_projects_to_folder', params);
    Userpilot.track('regulation:add_projects_to_folder', params);
  }

  private fetchFolders() {
    fetchFoldersThunk(this.dispatch);
  }

  private dispatchUpdateFoldersCount(params) {
    this.dispatch(updateFoldersCount.createAction({ projects: params }));
  }

  private dispatchAddProjectsSucceed(params) {
    this.dispatch(addProject.createAction(params));
  }

  private dispatchRemoveProjectSucceed(params) {
    this.dispatch(removeProject.createAction(params));
  }

}

export async function addProjectsToFolderThunk(dispatch, params) {
  const thunk = new AddProjectsToFolderThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default AddProjectsToFolderThunk;
