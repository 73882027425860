import React from 'react';

import { FieldProps } from './attributeField';

import field from './attributeField.style';

import s from './multipleField.style';

interface AdditionalControlProps {
  title: string;
  onClick: () => void;
}

const AdditionalControl: React.FC<AdditionalControlProps> = (props: AdditionalControlProps) => (
  <s.ShowMore
    onClick={props.onClick}
  >
    {props.title}
  </s.ShowMore>
);

interface MultipleFieldProps {
  values: FieldProps[];
}

export class MultipleField extends React.PureComponent<MultipleFieldProps, MultipleFieldProps> {

  state = {
    values: [],
  };

  private shouldShowMore = this.props.values.length > 3;

  componentDidMount() {
    this.addInitialValues();
  }

  componentDidUpdate(prevProps: Readonly<MultipleFieldProps>) {
    if (prevProps.values[0].id !== this.props.values[0].id) {
      this.addInitialValues();
    }
  }

  setValues(values) {
    this.setState({
      values,
    });
  }

  addInitialValues = () => {
    if (this.props.values.length === 3) {
      this.setValues(this.props.values.slice(0, 3));
    } else {
      this.setValues(this.props.values.slice(0, 2));
    }
  };

  addValues = () => {
    this.setValues(this.props.values);
  };

  handleShowMoreClick = () => {
    if (this.shouldShowMore) {
      this.addValues();
    }
  };

  handleShowLessClick = () => {
    this.addInitialValues();
  };

  renderAction = () => {
    const shouldShowLess = this.props.values.length === this.state.values.length && this.state.values.length > 3;
    if (shouldShowLess) {
      return (
        <AdditionalControl
          onClick={this.handleShowLessClick}
          title="Свернуть"
        />
      );
    }

    return (
      <AdditionalControl
        onClick={this.handleShowMoreClick}
        title="Показать еще"
      />
    );
  };

  renderActions = () => {
    if (!this.shouldShowMore) {
      return null;
    }

    return (
      <s.Actions>
        {this.renderAction()}
      </s.Actions>
    );
  };

  render() {
    return (
      <>
        {this.state.values.map((item) => <field.Value key={item.id}>{item.name}</field.Value>)}
        {this.renderActions()}
      </>
    );
  }

}

export default MultipleField;
