import { addAnnotationReduce } from './addAnnotation.reduce';

import type { ActionData } from './addAnnotation.reduce';

const actionType = 'ANNOTATIONS/ADD_ANNOTATION';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addAnnotation = {
  actionType,
  createAction,
  reduce: addAnnotationReduce,
};

export default addAnnotation;
