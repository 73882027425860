import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateAppealStateReduce } from './updateAppealState.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/APPEAL_STATE/UPDATE`;

export interface Data {
  id: string;
  data: {
    id: number;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateAppealState = {
  actionType,
  createAction,
  reduce: updateAppealStateReduce,
};

export default updateAppealState;
