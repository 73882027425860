import styled from 'styled-components';

interface Props {
  color: string;
}

export const iconDragStyle: any = {};

iconDragStyle.Root = styled.div`
  position: absolute;
  top: 0px;
  left: -3px;
  color: #6c7b89;
`;

iconDragStyle.Icon = styled.i<Props>`
  display: inline-block;
  fill: currentColor;
  color: ${(props) => (props.theme.colors[props.color] || props.color)};

  svg {
    display: block;
  }
`;

export default iconDragStyle;
