import { updateFolderTitleReduce } from './updateFolderTitle.reduce';

import type { ActionData } from './updateFolderTitle.reduce';

const actionType = 'REGULATION/FOLDERS/UPDATE_TITLE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateFolderTitle = {
  actionType,
  createAction,
  reduce: updateFolderTitleReduce,
};

export default updateFolderTitle;
