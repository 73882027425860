import { Dispatch } from 'redux';

import { date } from '../../redux/state';

import { fetchMonth } from './fetchMonth';

import { httpFetchSearchAnalyticMonth } from './fetchMonth.http';

export class FetchSearchMonthThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
    dispatch((_, getState) => { this.getState = getState; });
  }

  public async invoke(yearId: number) {
    const searchId = this.getState().searchResult.id;

    const { items } = this.getState().searchResult.analysis.itemsMap[date.getId()];
    const { shouldLoadMonth } = items.itemsMap.get(yearId);
    if (!shouldLoadMonth) {
      return;
    }

    try {
      const searchResult = await httpFetchSearchAnalyticMonth(searchId, yearId);

      this.dispatchGetMonthSucceed(searchId, yearId, searchResult);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  private dispatchGetMonthSucceed(searchId, yearId, searchResult) {
    const actionData = {
      searchId,
      yearId,
      ...searchResult,
    };

    this.dispatch(fetchMonth.createAction(actionData));
  }

}

export default FetchSearchMonthThunk;
