import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpPasswordResetCodeVerification(data: { code: string }) {
  const request = http.get({
    url: RESTPath.user.passwordResetCodeVerification(data),
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpPasswordResetCodeVerification;
