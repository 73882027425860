import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'redux';

import { fetchAuthorPackages } from './fetchAuthorPackages';
import { selectPackageThunk } from './selectPackage.thunk';

import { httpFetchAuthorPackages } from './fetchAuthorPackages.http';

export class FetchAuthorPackagesThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: string }) {
    try {
      const result = await httpFetchAuthorPackages();

      this.dispatchFetchSucceed(result);

      if (isEmpty(result.packages)) {
        return;
      }

      let idFromUrlParams = null;
      if (params.id) {
        idFromUrlParams = Number(params.id);
      }

      const sourceId = result.packages[0].id;
      selectPackageThunk(this.dispatch, { idFromUrlParams, sourceId });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      items: result.packages,
    };

    this.dispatch(fetchAuthorPackages.createAction(actionData));
  }

}

export function fetchAuthorPackagesThunk(dispatch, params?) {
  const thunk = new FetchAuthorPackagesThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchAuthorPackagesThunk;
