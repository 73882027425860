import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateThemeOfDefinitionReduce } from './updateThemeOfDefinition.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/DOCUMENT_TYPE/UPDATE_THEME_OF_DEFINITION`;

export interface Data {
  id: string;
  data: {
    id: string;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateThemeOfDefinition = {
  actionType,
  createAction,
  reduce: updateThemeOfDefinitionReduce,
};

export default updateThemeOfDefinition;
