import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './pinButton.style';

type Props = {
  onClick: () => void,
  isPinned: boolean,
};

export const PinButton = ({ onClick, isPinned }: Props) => {

  let pinPath = iconsPath.get('activePin');

  if (!isPinned) {
    pinPath = iconsPath.get('unactivePin');
  }

  return (
    <s.PinButton
      isPinned={isPinned}
      onClick={onClick}
      className="filter-pin-button"
    >
      <Icon path={pinPath} color="#6c7b89" />
    </s.PinButton>
  );
};

export default PinButton;
