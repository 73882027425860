import styled, { css } from 'styled-components';

interface Props {
  isActive: boolean;
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: #4183D712;
  }

  :active {
    background: #4183D726;
  }

  ${(props: Props) => props.isActive && css`
    :hover {
      background: #4183D726;
    }

    i {
      color: ${props.theme.colors.blue};
    }
  `}
`;

style.IconCheck = styled.div`
  polygon {
    color: green;
  }
;`;

style.IconUncheck = styled.div`
  polygon {
    color: red;
  }
;`;

export default style;
