import React from 'react';

import { ScrollTo } from '../components/scrollTo';

import { LAW_DOCUMENT_ID } from '../lawDocument';

import s from './item.style';

export interface ItemProps {
  id: string;
  title: string;
}

export class Item extends React.PureComponent<ItemProps> {

  handleClick = () => {
    const scrollTo = new ScrollTo();
    scrollTo.setRootNode(LAW_DOCUMENT_ID);
    scrollTo.setTargetNode(this.props.id);
    scrollTo.execute();
  };

  render() {
    return (
      <s.Root
        onClick={this.handleClick}
      >
        {this.props.title}
      </s.Root>
    );
  }

}

export default Item;
