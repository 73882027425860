import React from 'react';

import { Button } from '@components/button';

import s from './resetButton.style';

/**
 * @param {function} onClick - mouse event arg & return void
 */

interface ResetButtonProps {
  onClick: (e) => void;
}

export const ResetButton = (props: ResetButtonProps) => (
  <s.ResetButton>
    <Button
      linkLike
      disabled={false}
      fullWidth={false}
      onClick={props.onClick}
    >
      Отмена
    </Button>
  </s.ResetButton>
);

export default ResetButton;
