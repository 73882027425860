import isEmpty from 'lodash/isEmpty';

import { theme } from '@components/styled';

export const tags = {
  highPositive: {
    id: 'highPositive',
    path: 'tag',
    color: theme.colors.green,
  },
  positive: {
    id: 'positive',
    path: 'tag',
    color: theme.colors.blue,
  },
  neutral: {
    id: 'neutral',
    path: 'tag',
    color: theme.colors.stone,
  },
  negative: {
    id: 'negative',
    path: 'tag',
    color: theme.colors.orange,
  },
  highNegative: {
    id: 'highNegative',
    path: 'tag',
    color: theme.colors.red,
  },
  notSuit: {
    id: 'notSuit',
    path: 'denied',
    color: theme.colors.grey,
  },
  empty: {
    id: 'empty',
    path: 'tagEmpty',
    color: theme.colors.grey,
  },
};

export function prepareTag(tagId) {
  return {
    ...tags[tagId],
  };
}

export function buildList(presets, activeTagId) {
  const viewTags = [];

  if (isEmpty(presets)) {
    return viewTags;
  }

  const list = [
    {
      ...tags.highPositive,
      title: presets.find((item) => item.id === tags.highPositive.id).title,
    },
    {
      ...tags.positive,
      title: presets.find((item) => item.id === tags.positive.id).title,
    },
    {
      ...tags.neutral,
      title: presets.find((item) => item.id === tags.neutral.id).title,
    },
    {
      ...tags.negative,
      title: presets.find((item) => item.id === tags.negative.id).title,
    },
    {
      ...tags.highNegative,
      title: presets.find((item) => item.id === tags.highNegative.id).title,
    },
    {
      ...tags.empty,
      title: presets.find((item) => item.id === tags.empty.id).title,
    },
    {
      ...tags.notSuit,
      title: presets.find((item) => item.id === tags.notSuit.id).title,
    },
  ];

  list.forEach((item) => {
    viewTags.push(
      {
        ...item,
        selected: item.id === activeTagId,
      },
    );
  });

  return viewTags;

}

export default buildList;
