/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Logo } from '../../components/logo/logo';

import { RoutePath } from '../../router/path';
import s from './container.style';

export var Container = (props) => {
  let logoProps = {};
  if (props.withLink) {
    logoProps = {
      to: RoutePath.login,
      as: Link,
    };
  }

  return (
    <s.Root>
      <s.Header>
        <s.Logo {...logoProps}>
          <Logo />
        </s.Logo>
      </s.Header>

      <s.Body>
        <s.Content>
          {props.children}
        </s.Content>
      </s.Body>
    </s.Root>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  withLink: PropTypes.bool,
};

Container.defaultProps = {
  children: null,
  withLink: true,
};

export default Container;
