import styled from 'styled-components';

const style: any = {};

style.Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export default style;
