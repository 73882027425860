import { fetchSettingsReduce } from '@pages/searchResult/documents/redux/fetchSettings.reduce';

import type { ActionData } from '@pages/searchResult/documents/redux/fetchSettings.reduce';

const actionType = 'PACKAGES/DOCUMENTS/FETCH_SETTINGS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSettings = {
  actionType,
  createAction,
  reduce: fetchSettingsReduce,
};

export default fetchSettings;
