import { fetchArticlesReduce } from './fetchArticles.reduce';

import type { ActionData } from './fetchArticles.reduce';

const actionType = 'SEARCH/RESULT/FETCH_ARTICLES';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchArticles = {
  actionType,
  createAction,
  reduce: fetchArticlesReduce,
};

export default fetchArticles;
