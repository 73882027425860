import { connect } from 'react-redux';

import { BootstrapThunk } from '@bootstrap/bootstrap.thunk';

import { Source } from '../../document/redux/state';

import { DocumentConnect as DocumentRoute } from '../../document/document.connect';

const mapStateToProps = () => ({
  source: Source.authorPackage,
});

const mapDispatchToProps = (dispatch) => ({
  onBootstrap() {
    const bootstrap = new BootstrapThunk(dispatch);
    bootstrap.invoke();
  },
});

export const DocumentConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentRoute);

export default DocumentConnect;
