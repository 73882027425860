import { uncheckProjectReduce } from './uncheckProject.reduce';

import type { ActionData } from './uncheckProject.reduce';

const actionType = 'REGULATION/WORKSPACE/UNCHECK_PROJECT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const uncheckProject = {
  actionType,
  createAction,
  reduce: uncheckProjectReduce,
};

export default uncheckProject;
