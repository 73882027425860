import styled, { css } from 'styled-components';

interface Props {
  alert: boolean;
  theme: Record<any, any>;
}

const style: any = {};

style.Root = styled.div`
  background: ${(props: Props) => props.theme.colors.white};
`;

style.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  box-shadow: 0 1px 0 0 ${(props: Props) => props.theme.colors.borderColor};
  padding: 0 16px;
`;

style.HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font: 17px Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.defaultText};
`;

style.HeaderPayment = styled.div`
  display: flex;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
  margin-right: 11px;
  margin-top: 8px;

  > div {
    border: 2px solid transparent;
    border-color: #e3e3e3 #e3e3e3 #4183d7;
  }
`;

style.HeaderLodaer = styled.div`
  color: ${(props) => props.theme.colors.stone};
  font-size: 13px;
  font-family: 'Roboto-Regular';
  display: flex;
  flex-direction: row;
`;

style.HeaderLoaderTitle = styled.div`
  color: ${(props) => props.theme.colors.stone};
  font-size: 13px;
  font-family: 'Roboto-Regular';
`;

style.HeaderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-right: -8px;
  padding: 0 8px;
  height: 32px;
  border: none;
  border-radius: 2px;

  font-size: 13px;
  font-family: 'Roboto-Medium';
  text-transform: uppercase;

  color: ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.white};

  cursor: pointer;

  transition: all .3s;

  user-select: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  &:active {
    background-color: rgba(65, 131, 215, 0.12);
  }
`;

style.Info = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  box-shadow: 0 1px 0 0 #dfe2e6;
`;

style.Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 16px;
`;

style.ItemTitle = styled.div`
  padding-top: 14px;
  font: 11px Roboto-Regular;
  line-height: 16px;
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.ItemValue = styled.div`
  padding-top: 3px;
  font: 19px Roboto-Regular;
  line-height: 20px;
  color: ${(props: Props) => props.theme.colors.defaultText};

  ${(props: Props) => props.alert && css`
    color: ${props.theme.colors.red};
  `}
`;

export default style;
