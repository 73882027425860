import { Dispatch } from 'redux';

import { appHistory } from '@router/history';

import { resetForm } from '@pages/search/form/redux/resetForm';

import { resetToInitial } from '../../workspace/documents/redux/resetToInitial';
import { fetchDocumentsThunk } from '../../workspace/documents/redux/fetchDocuments.thunk';

import { fetchFiltersThunk } from '../../workspace/filters/redux/fetchFilters.thunk';

import { selectSubscription } from './selectSubscription';

export class SelectSubscriptionThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { idFromUrlParams: number, sourceId: number }) {
    const { subscriptions } = this.getState().subscriptions;
    const sameId = params.sourceId === subscriptions.activeItemId;

    if (sameId) {
      return;
    }

    try {
      const sourceId = params.idFromUrlParams || params.sourceId;
      await this.dispatchSelectSubscription(sourceId);

      this.dispatchResetToInitial();
      if (!params.idFromUrlParams) {
        appHistory.push(`/subscriptions/${params.sourceId}`);
      }

      fetchDocumentsThunk(this.dispatch, { id: sourceId });
      fetchFiltersThunk(this.dispatch, { id: sourceId });

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  private dispatchResetToInitial() {
    this.dispatch(resetToInitial.createAction());
    this.dispatch(resetForm.createAction());
  }

  private dispatchSelectSubscription(sourceId) {
    const actionData = {
      id: sourceId,
    };

    this.dispatch(selectSubscription.createAction(actionData));
  }

}

export function selectSubscriptionThunk(dispatch, params) {
  const thunk = new SelectSubscriptionThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SelectSubscriptionThunk;
