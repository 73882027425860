import React from 'react';

import { HistoryProps } from '@pages/search/history/searchHistory';

import s from '@pages/search/history/components/list/list.styles';

import {
  Header,
  Title,
} from './header';

type SelectedIdType = number;
type HandleType = (id: number) => void;

export interface ListProps {
  data: HistoryProps[]
  title: Title
  selectedId?: SelectedIdType
  onRowClick?: HandleType
}

interface RenderHistoryProps {
  filters: HistoryProps[],
  onRowClick?: HandleType,
  selectedId: SelectedIdType
}

interface RenderListProps {
  onRowClick?: HandleType,
  selectedId: SelectedIdType,
  filter: HistoryProps,
}

const renderRowItems = (filters: string[]) => filters.map((filter) => (
  <s.RowItem key={filter}>
    { filter }
  </s.RowItem>
));

const renderEmptyBlock = () => {
  const emptyText = 'Здесь будут сохраняться ваши поисковые запросы';

  return (
    <s.EmptyContent>
      {emptyText}
    </s.EmptyContent>
  );
};

const renderList = ({ onRowClick, selectedId }: RenderListProps, filter) => {
  const disable = selectedId === filter.id || filter.isRemoving;

  const onClick = disable ?
    null :
    () => onRowClick(filter.id);

  return (
    <s.RowWrapper key={filter.id}>
      <s.Row onClick={onClick} disable={disable}>
        <s.RowTitle>
          { filter.created }
        </s.RowTitle>
        <s.RowContent>
          <s.RowItemWrapper>
            { renderRowItems(filter.filters) }
          </s.RowItemWrapper>
        </s.RowContent>
      </s.Row>
    </s.RowWrapper>
  );
};

const renderHistory = ({ filters, onRowClick, selectedId }: RenderHistoryProps) => {
  if (!filters.length) {
    return renderEmptyBlock();
  }

  const param = {
    onRowClick,
    selectedId,
  };

  return filters.map(renderList.bind(null, param));
};

export const List: React.FC<ListProps> = ({ data, title, selectedId, onRowClick }: ListProps) => {
  const param = {
    filters: data,
    onRowClick,
    selectedId,
  };

  return (
    <s.Wrapper>
      <Header title={title} />
      {renderHistory(param)}
    </s.Wrapper>
  );
};

export default List;
