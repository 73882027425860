import React from 'react';

import { Loader } from '@components/loader';

import { Switch } from '../../components/switch';

import { HeaderSettings } from './headerSettings';

import s from './notificationCassette.style';

type Id = number;

interface ItemProps {
  id: Id,
  title: string;
  hasNotification: boolean;
  onClick: (id: Id) => void;
}

const Item: React.FC<ItemProps> = (props: ItemProps) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    props.onClick(props.id);
  };

  return (
    <s.Item
      key={props.id}
    >
      <s.ItemTitleContainer>
        <s.ItemTitle
          target="_blank"
          href={`#/subscriptions/${props.id}`}
        >
          {props.title}
        </s.ItemTitle>
      </s.ItemTitleContainer>
      <s.Switch
        onClick={handleClick}
      >
        <Switch checked={props.hasNotification} />
      </s.Switch>
    </s.Item>
  );
};

interface NotificationCassetteProps {
  items: ItemProps[];
  loading: boolean;
  onInit(): void;
  onToggle(id: Id): void;
  onResetToInitial(): void;
}

export class NotificationCassette extends React.PureComponent<NotificationCassetteProps> {

  componentDidMount() {
    this.props.onInit();
  }

  componentWillUnmount() {
    this.props.onResetToInitial();
  }

  renderItem = (item) => <Item key={item.id} {...item} onClick={this.props.onToggle} />;

  renderList() {
    return (
      <s.List>
        {this.props.items.map(this.renderItem)}
      </s.List>
    );
  }

  renderLoader() {
    return (
      <s.LoaderContainer>
        <s.Loader>
          <Loader size={32} />
        </s.Loader>
      </s.LoaderContainer>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return this.renderList();
  }

  render() {
    return (
      <s.Root>
        <s.Header>
          <s.HeaderTitle>
            Подписки, включаемые в рассылку
          </s.HeaderTitle>
          <s.HeaderActions>
            <HeaderSettings />
          </s.HeaderActions>
        </s.Header>
        <s.Caption>
          Название подписки
        </s.Caption>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default NotificationCassette;
