import React, {
  useState, useEffect,
} from 'react';

import { SearchField } from '@components/searchField/searchField';

import { Blocks } from './blocks';

import {
  BlockProps, blocks,
} from './utils';

import s from './blocksMenu.style';

export interface BlocksMenuProps {
  onHandleItemSelect: (type: string) => void
  closePopover: () => void
}

export const BlocksMenu = (props: BlocksMenuProps) => {
  const [filterValue, setFilterValue] = useState('');
  const [filterBlocks, setFilterBlocks] = useState(blocks);
  const searchPlaceholder = 'Поиск';

  const isPartOfString = (filterBlock: BlockProps) => {
    const filterBlockTitle = filterBlock.block.blockTitle.toLowerCase();
    const filterValueLower = filterValue.toLowerCase();

    return filterBlockTitle.indexOf(filterValueLower) !== -1;
  };

  const getFilteredBlocks = () => {
    const isNonEmptyValue = filterValue !== '';

    if (isNonEmptyValue) {
      return blocks.filter((filterBlock) => {
        if (isPartOfString(filterBlock)) {
          return filterBlock;
        }

        return false;
      });
    }

    return [...blocks];

  };

  const changeFilterBlocks = () => {
    const newBlocks = getFilteredBlocks();

    setFilterBlocks(newBlocks);
  };

  useEffect(changeFilterBlocks, [filterValue]);

  const handleFilterValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  return (
    <s.BlocksMenu>
      <SearchField
        value={filterValue}
        placeholder={searchPlaceholder}
        onChange={handleFilterValue}
      />
      <Blocks {...props} blocks={filterBlocks} />
    </s.BlocksMenu>
  );
};

export default BlocksMenu;
