import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  isFirst: boolean;
  disabled: boolean;
}

const style: any = {};

style.Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${(props: Props) => props.isFirst && css`
    border: none;
  `}
`;

export default style;
