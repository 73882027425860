import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { Format } from '@providers/download/download.http';

import s from './download.style';

interface DownloadProps {
  onGoBack: () => void;
  onDownload(format: Format.xlsx | Format.docx | Format.pdf): void;
  onClosePopover: () => void;
}

export class Download extends React.PureComponent<DownloadProps> {

  handleDownloadXLS = () => {
    this.props.onDownload(Format.xlsx);
    this.props.onClosePopover();
  };

  handleDownloadDOCX = () => {
    this.props.onDownload(Format.docx);
    this.props.onClosePopover();
  };

  handleDownloadOriginal = () => {
    this.props.onDownload(Format.pdf);
    this.props.onClosePopover();
  };

  renderHeader() {
    const arrowBackIcon = iconsPath.get('arrowBack');

    return (
      <s.Header>
        <s.Back
          onClick={this.props.onGoBack}
        >
          <Icon points={arrowBackIcon} color="currentColor" />
        </s.Back>
        <s.HeaderTitle>
          Скачать
        </s.HeaderTitle>
      </s.Header>
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        <s.List>
          <s.Item
            onClick={this.handleDownloadXLS}
          >
            Таблица документов в XLSX
          </s.Item>
          <s.Item
            onClick={this.handleDownloadDOCX}
          >
            Документы в DOCX
          </s.Item>
          <s.Item
            onClick={this.handleDownloadOriginal}
          >
            Оригиналы документов
          </s.Item>
        </s.List>
      </s.Container>
    );
  }

}

export default Download;
