import { StateBuilder } from './state';

export interface Action {
  type: string;
}

export const resetEventsReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.events);
  newState.resetEvents();

  const events = newState.getState();

  return {
    ...state,
    events,
  };
};

export default resetEventsReduce;
