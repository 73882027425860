import styled from 'styled-components';

interface Props {
  theme: any;
  width: number;
}

const style: any = {};

style.Root = styled.div`
  width: ${(props: Props) => props.width}px;
  height: 256px;
  margin-right: 16px;
  padding: 16px;
  flex-shrink: 0;
  background-color: ${(props: Props) => props.theme.colors.white};
  box-sizing: border-box;
  border-bottom: 1px solid #dfe2e6;
`;

style.Title = styled.div`
  height: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  font: 11px Roboto-Bold;
`;

style.Days = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font: 11px Roboto-Bold;
`;

style.Date = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export default style;
