import {
  Id,
  StateBuilder,
} from './state';

import { PhasesDTO } from './fetchPhases.http';

export interface ActionData {
  id: Id;
  result: PhasesDTO;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchPhasesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.preview);
  newState.buildPreviewPhases(action.data.id, action.data.result.result);

  const preview = {
    ...newState.getState(),
  };

  return {
    ...state,
    preview,
  };
};

export default fetchPhasesReduce;
