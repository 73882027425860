import { Dispatch } from 'redux';

import isEmpty from 'lodash/isEmpty';

import { DateBlock } from '@components/filters/date/logic/date';

import { FolderType } from '@pages/regulation/sidebar/folders/redux/state';
import {
  getDays,
  getCurrentDay,
  getCurrentMonth,
  getCurrentYear,
  getLeadingZeroDate,
  isEqualDates,
} from '../utils';

import { httpFetchFolder } from '../../redux/fetchFolder.http';
import { resetFilters } from '../../toolbar/redux/resetFilters';

import { setActiveDate } from './setActiveDate';
import { setNearestActiveDate } from './setNearestActiveDate';
import { setCalendarsLoading } from './setCalendarsLoading';
import { setHasProjects } from './setHasProjects';

import { httpFetchCalendar } from './fetchCalendar.http';
import { setEventsLoading } from './setEventsLoading';

export interface Params {
  year: number;
  month: number;
}

export class FetchCalendarsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    this.dispatch(resetFilters.createAction());
    const hasProjects = await this.fetchUnderControlProject();
    this.dispatch(setHasProjects.createAction({ hasProjects }));

    const requestParams = this.buildRequest();
    const data = await this.fetchCalendar(requestParams);
    const events = data.items;

    const actionData = {
      events,
    };

    this.setActionParams(actionData);
  }

  private async fetchUnderControlProject() {
    const state = this.getState();
    const filters = { ...state.regulation.filters.filters };

    const dateFilter = new DateBlock();
    dateFilter.create(filters.date);

    filters.date = dateFilter.undecorate();
    const data = await httpFetchFolder(FolderType.underControl, filters, 1);

    return !!data.total_projects;
  }

  private buildRequest() {
    const currentYear = getCurrentYear();
    const month = getCurrentMonth();
    const currentMonth = getLeadingZeroDate(month + 1);
    const currentDay = getLeadingZeroDate(getCurrentDay());

    const from = `${currentYear}-${currentMonth}-${currentDay}`;

    const toYear = currentYear + 1;
    const lastDay = getDays({ year: toYear, month: month + 1 });
    const to = `${toYear}-${currentMonth}-${lastDay}`;

    return {
      from,
      to,
    };
  }

  private async fetchCalendar(requestParams) {
    try {
      return await httpFetchCalendar(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private setActionParams(data) {
    if (isEmpty(data.events)) {
      this.dispatch(setCalendarsLoading.createAction({ loading: false }));
      this.dispatch(setEventsLoading.createAction({ loading: false }));

      return;
    }

    const events = Object.keys(data.events);
    const firstDate = events[0];
    const activeDate = this.buildActiveDate(firstDate);

    const currentDate = this.buildCurrentDate();

    if (isEqualDates(activeDate, currentDate)) {
      this.dispatch(setCalendarsLoading.createAction({ loading: false }));
      this.dispatch(setActiveDate.createAction(activeDate));

      return;
    }

    this.dispatch(setNearestActiveDate.createAction(activeDate));
    this.dispatch(setEventsLoading.createAction({ loading: false }));
    this.dispatch(setCalendarsLoading.createAction({ loading: false }));
  }

  private buildCurrentDate() {
    const year = getCurrentYear();
    const month = getCurrentMonth();
    const day = getCurrentDay();

    return {
      year,
      month,
      day,
    };
  }

  private buildActiveDate(date) {
    const splitDate = date.split('-');
    const activeDate = splitDate.map((item) => Number(item));

    return {
      year: activeDate[0],
      month: activeDate[1] - 1,
      day: activeDate[2],
    };
  }

}

export async function fetchCalendarsThunk(dispatch) {
  const thunk = new FetchCalendarsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke();
}

export default FetchCalendarsThunk;
