import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { prepareFiltersEndReduce } from './prepareFiltersEnd.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/FILTERS/PREPARE_COMPLETE`;

const createAction = () => ({
  type: actionType,
});

export const prepareFiltersEnd = {
  actionType,
  createAction,
  reduce: prepareFiltersEndReduce,
};

export default prepareFiltersEnd;
