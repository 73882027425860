import React from 'react';

import { Counter } from '../../packages/sidebar/counter';
import { Settings } from './settings';

import s from '../../packages/sidebar/item.style';

interface ItemProps {
  id: number;
  packageId: number;
  title: string;
  active: boolean;
  documentsCount: number;
  onSelectPackage(): void;
}

interface ItemState {
  openConfigPopover: boolean;
  hovered: boolean;
}

export class Item extends React.PureComponent<ItemProps, ItemState> {

  state = {
    openConfigPopover: false,
    hovered: false,
  };

  handleMouseOver = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  handleSettingsMenuVisible = (visible: boolean) => {
    this.setState({
      openConfigPopover: visible,
    });
  };

  renderConfig() {
    const showConfigElement = this.state.hovered || this.state.openConfigPopover;
    if (!showConfigElement) {
      return <Counter count={this.props.documentsCount} />;
    }

    return (
      <s.Settings>
        <Settings onMenuVisible={this.handleSettingsMenuVisible} id={this.props.id} />
      </s.Settings>
    );
  }

  renderTitle() {
    return <s.Title>{this.props.title}</s.Title>;
  }

  render() {
    return (
      <s.Root
        active={this.props.active}
        hovered={this.state.hovered}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.props.onSelectPackage}
      >
        {this.renderConfig()}
        {this.renderTitle()}
      </s.Root>
    );
  }

}

export default Item;
