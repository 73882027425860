import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';

import middlewareThunk from 'redux-thunk';

import { resetActiveItemMiddleware } from '@pages/search/form/middleware/resetActiveItemMiddleware';

import rootReducer from './reducer';

export default function create() {
  const middlewares = [
    middlewareThunk,
    resetActiveItemMiddleware,
  ];

  return compose(applyMiddleware(...middlewares))(createStore)(rootReducer);
}
