import { Dispatch } from 'redux';

import { lawSettings } from './settings';

import { saveLawLayoutSettingsSucceeded } from './saveLayout';

import { httpSaveLawLayoutSettings } from './saveLayout.http';

export class SaveLawLayoutSettingsThunk {

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(layout) {

    const dto = lawSettings.undecorate(layout);

    try {
      await httpSaveLawLayoutSettings(dto);
    } catch (error) {
      throw Error(error.status);
    }
  }

  dispatchSaveSucceeded() {
    this.dispatch(saveLawLayoutSettingsSucceeded.createAction());
  }

}

export function saveLawLayoutSettingsThunk(dispatch) {
  const thunk = new SaveLawLayoutSettingsThunk(dispatch);

  dispatch((_, getState) => {
    const { searchResult } = getState();
    thunk.invoke(searchResult.law.layout);
  });

}
