import { connect } from 'react-redux';

import { toggleLayoutType } from './redux/toggleLayoutType';

import { LayoutSwitcher } from './layoutSwitcher';

const mapStateToProps = (state) => ({
  layout: state.subscriptions.layout.type,
  disabled: !state.subscriptions.subscriptions.activeItemId,
});

const mapDispatchToProps = (dispatch) => ({
  onToggle() {
    dispatch(toggleLayoutType.createAction());
  },
});

export const LayoutSwitcherConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutSwitcher);

export default LayoutSwitcherConnect;
