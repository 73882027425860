import { RESTPath } from '@router/path.rest';
import http from '@http';

export interface RequestParams {
  id: number,
  data: {
    title: string,
    is_notify: boolean,
  },
}

export function httpNotifyAllSubscriptions() {
  const request = http.put({
    url: RESTPath.subscriptions.notifyAll,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpNotifyAllSubscriptions;
