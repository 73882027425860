import { fetchDocumentReduce } from './fetchDocument.reduce';

import { DocumentDTO } from './document';

const actionType = 'SEARCH/RESULT/FETCH_DOCUMENT__PREVIEW';

const createAction = (data: DocumentDTO) => ({
  type: actionType,
  data,
});

export const fetchDocument = {
  actionType,
  createAction,
  reduce: fetchDocumentReduce,
};

export default fetchDocument;
