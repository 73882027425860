export interface State {
  hasComments: boolean;
  loading: boolean;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      hasComments: false,
      loading: false,
    };
  }

  constructor(state: State) {
    this.state = state;
  }

  public setComments(result): void {
    this.state = {
      ...this.state,
      hasComments: result,
    };
  }

  public updateLoading(loading: boolean): void {
    this.state = {
      ...this.state,
      loading,
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
