import React from 'react';

import { AddPackageConnect } from './addPackage/addPackage.connect';
import { SettingsPopover } from './settingsPopover/settingsPopover';

import s from './header.style';

export interface HeaderProps {
  title: string;
  isReal: boolean;
}

export class Header extends React.PureComponent<HeaderProps> {

  renderAddToPackage() {
    if (!this.props.isReal) {
      return null;
    }

    return (
      <s.ActionIcon>
        <AddPackageConnect />
      </s.ActionIcon>
    );
  }

  render() {
    return (
      <s.TopHeader>
        <s.Title>
          {this.props.title}
        </s.Title>
        <s.Actions>
          {this.renderAddToPackage()}
          <s.OptionsKebab>
            <SettingsPopover />
          </s.OptionsKebab>
        </s.Actions>
      </s.TopHeader>
    );
  }

}

export default Header;
