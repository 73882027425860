import styled from 'styled-components';

import IsolatedScroll from 'react-isolated-scroll';

const style = {};

style.Root = styled.div`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  min-height: 55px;
  max-height: 320px;
  flex-direction: column;
  width: 448px;
  background-color: #fff;
  z-index: 100;
  border-radius: 2px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

style.Scrollable = styled(IsolatedScroll)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

style.Loader = styled.div`
  > div {
    border-color: #fff #fff #4183d7;
  }
`;

export default style;
