import { DateBlock } from '@components/filters/date/logic/date';
import {
  StateBuilder, FilterType,
} from '../../../workspace/toolbar/redux/state';

import { Id } from './item';

export interface ActionData {
  id: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateFiltersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const id = action.data.id;

  const filters = state.smartFolders.itemsMap.get(id);

  const newState = new StateBuilder(filters);
  const date = new DateBlock();
  date.createFromScratch(filters.filters.date.data);
  newState.updateFilter(FilterType.date, date.decorate());

  const filterState = newState.getState().filters;

  return {
    ...state,
    filters: {
      ...state.filters,
      filters: filterState,
      loading: false,
    },
  };
};

export default updateFiltersReduce;
