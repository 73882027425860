import React from 'react';

import { BlockType } from '../../components/blockType/blockType';
import { Contains } from '../../components/contains/contains';
import { Select } from '../../components/select/select';
import { Suggest } from '../../components/suggest/suggest';
import { Field } from '../../components/field/field';

import { ParticipantBlock as Block } from './redux/participant';

import { Data as DataMode } from './redux/updateMode';
import { Data as DataParticipant } from './redux/updateParticipant';

import s from './block.style';

export interface ParticipantBlockProps {
  roles: any;
  item: Block;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onChangeContains: (data: any) => void;
  onUpdateMode: (data: DataMode) => void;
  onUpdateParticipant: (data: DataParticipant) => void
}

export class ParticipantBlock extends React.PureComponent<ParticipantBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  handleChangeMode = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdateMode(actualData);
  };

  handleChangeParticipantFromTextField = (e) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        id: null,
        address: null,
        title: e.target.value,
      },
    };

    // @ts-ignore
    this.props.onUpdateParticipant(data);
  };

  handleChangeParticipantFromSuggest = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdateParticipant(actualData);
  };

  handleRemoveValue = () => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        id: null,
        address: null,
        title: '',
      },
    };

    // @ts-ignore
    this.props.onUpdateParticipant(data);
  };

  renderSuggest() {
    const data = this.props.item.getData();
    const activeItem = {
      id: data.participant.id,
      title: data.participant.title,
    };

    return (
      <s.FilterItem size="288px">
        <Suggest
          useAnchorWidth
          activeItem={activeItem}
          placeholder="ИНН, ОГРН или название"
          getItems={this.props.item.getItemsForSuggest}
          onChange={this.handleChangeParticipantFromSuggest}
        />
      </s.FilterItem>
    );
  }

  renderInput() {
    const data = this.props.item.getData();
    if (data.mode.id === Block.presetsMap.precise.id) {
      return this.renderSuggest();
    }

    return this.renderTextField();

  }

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderMode() {
    const data = this.props.item.getData();

    return (
      <s.FilterItem isGrow>
        <Select
          useAnchorWidth
          activeItem={data.mode}
          list={this.props.roles}
          onChange={this.handleChangeMode}
        />
      </s.FilterItem>
    );
  }

  renderTextField() {
    const data = this.props.item.getData();

    return (
      <s.FilterItem size="288px">
        <Field
          placeholder="Название"
          value={data.participant.title}
          onChange={this.handleChangeParticipantFromTextField}
          onRemove={this.handleRemoveValue}
        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderMode()}
        {this.renderInput()}
      </s.FilterBlock>
    );
  }

}

export default ParticipantBlock;
