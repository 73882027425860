import { RESTPath } from '@router/path.rest';
import http from '@http';

type Query = string;
type DocumentId = number;
type PageNumber = number;

export interface GetPackagesParams {
  query?: Query;
  pageNumber?: PageNumber;
  documentId?: DocumentId;
}

export function httpFetchPackages(requestParams: GetPackagesParams) {
  const url = RESTPath.packages.getPackages(requestParams);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchPackages;
