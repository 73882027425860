import { fetchAuthorPackagesReduce } from './fetchAuthorPackages.reduce';

import type { ActionData } from './fetchAuthorPackages.reduce';

const actionType = 'AUTHOR_PACKAGES/FETCH_PACKAGES';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchAuthorPackages = {
  actionType,
  createAction,
  reduce: fetchAuthorPackagesReduce,
};

export default fetchAuthorPackages;
