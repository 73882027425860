import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpSaveDocumentsLayoutSettings(layout) {
  const request = {
    url: RESTPath.search.result.settings,
    data: layout,
  };

  return http
    .put(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSaveDocumentsLayoutSettings;
