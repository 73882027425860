import React from 'react';
import type { Map } from 'immutable';

import { ItemConnect } from './item.connect';

import table from './item.style';

type Id = string;

type Item = string;

interface ItemValue {
  id: string;
  showColumn: boolean;
  title: string;
}

interface Props {
  items: Id[];
  currentPageNumber: number;
  totalPages: number;
  columns: {
    itemsList: string[];
    itemsMap: Map<Item, ItemValue>
  };
  onFetchData: () => void;
  onResetItems: () => void;
}

export class List extends React.PureComponent<Props> {

  componentWillUnmount() {
    this.props.onResetItems();
  }

  handleShowMoreClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.props.onFetchData();
  };

  renderShowMore() {
    if (!this.props.totalPages) {
      return null;
    }

    if (this.props.currentPageNumber === this.props.totalPages) {
      return null;
    }

    return (
      <table.ShowMore
        onClick={this.handleShowMoreClick}
      >
        Показать еще
      </table.ShowMore>
    );
  }

  renderColumnInGroup() {
    return this.props.columns.itemsList.map((column) => {
      const isTitle = column === 'title';
      if (isTitle) {
        return (
          <table.ColumnTitle key={column} />
        );
      }

      return (
        <table.ColumnItem key={column} />
      );
    });
  }

  renderHeaderItems() {
    return this.props.columns.itemsList.map((column) => {
      // @ts-ignore
      const { title } = this.props.columns.itemsMap.get(column);

      const isTitle = column === 'title';
      if (isTitle) {
        return (
          <table.CellTitle
            key={column}
          >
            <table.ColumnWidthTitle>
              <table.CellContainer>
                <table.HeaderText>{title}</table.HeaderText>
              </table.CellContainer>
            </table.ColumnWidthTitle>
          </table.CellTitle>
        );
      }

      return (
        <table.CellItem
          key={column}
        >
          <table.ColumnWidthItem>
            <table.CellContainer>
              <table.HeaderText
                textAlign="end"
              >
                {title}
              </table.HeaderText>
            </table.CellContainer>
          </table.ColumnWidthItem>
        </table.CellItem>
      );
    });
  }

  renderItem(item) {
    return (
      <ItemConnect
        key={item}
        id={item}
      />
    );
  }

  renderRows() {
    return this.props.items.map(this.renderItem);
  }

  render() {
    return (
      <>
        <table.Table>
          <table.Group>
            {this.renderColumnInGroup()}
          </table.Group>
          <table.Row>
            {this.renderHeaderItems()}
          </table.Row>
        </table.Table>
        <table.Body>
          {this.renderRows()}
        </table.Body>
        {this.renderShowMore()}
      </>
    );
  }

}

export default List;
