import { Map } from 'immutable';
import { Filters } from '@pages/search/form/filters';
import { normalizeType } from '@pages/search/form/utils/blocksTypesMap';

export function getQuery(filters, presets) {
  const data = filters.map((filter) => {
    const item = { ...filter };
    item.type = normalizeType(item.type);

    return item;
  });

  const updatedFilters = new Filters();

  updatedFilters.decorate(data, presets);

  return {
    blocksList: updatedFilters.blocksList,
    blocksMap: updatedFilters.blocksMap,
  };
}

export const prepareDocument = (historyItem) => ({
  id: historyItem.id,
});

export const prepareDocuments = (fetchedHistory) => {
  const items = [];
  let itemsMap = Map();

  fetchedHistory.forEach((historyItem) => {
    items.push(historyItem.id);
    itemsMap = itemsMap.set(historyItem.id, prepareDocument(historyItem));
  });

  return {
    items,
    itemsMap,
  };
};

export const prepareSmartFilter = (filter) => {
  const noFilter = !filter || !filter.query;
  if (noFilter) {
    return null;
  }

  return {
    query: filter.query,
    hasChange: filter.is_changed,
    preparing: false,
  };

};

export const getHistoryReduce = (state, action) => {
  const data = [...action.data.history];

  const fetchedHistory = action.data.history;

  const currentPageNumber = Number(action.data.page);
  const totalPages = action.data.total_pages;

  const historyData = data.map((story) => ({
    id: story.id,
    created: story.created_at,
    query: getQuery(story.conditions.filter, state.presets),
    smartFilter: prepareSmartFilter(story.wizard_filters),
  }));

  const history = {
    ...state.history,
    data: historyData,
    loading: false,
    totalPages,
    [currentPageNumber]: prepareDocuments(fetchedHistory),
  };

  return {
    ...state,
    history,
  };
};

export default getHistoryReduce;
