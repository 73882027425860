import { fetchDisputeCategoryReduce } from './fetchDisputeCategory.reduce';

import type { ActionData } from './fetchDisputeCategory.reduce';

const actionType = 'SEARCH/RESULT/FETCH_DISPUTE_CATEGORY';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDisputeCategory = {
  actionType,
  createAction,
  reduce: fetchDisputeCategoryReduce,
};

export default fetchDisputeCategory;
