import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

type Item = number | '';

export interface ClaimAmountBlockData {
  value: Item[];
}

export class ClaimAmountBlock extends AbstractFilterBlock {

  static type = 'claimAmount';

  static DTOType = 'claim_amount';

  static blockTitle = 'Сумма иска';

  static category = category.dispute;

  protected defaultData: ClaimAmountBlockData = {
    value: ['', ''],
  };

  constructor() {
    super();

    this.setCategory(ClaimAmountBlock.category);
    this.setType({
      id: ClaimAmountBlock.type,
      DTOType: ClaimAmountBlock.DTOType,
      title: ClaimAmountBlock.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const block = {
      id,
      data,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    const data = this.getData();
    if (!data.value[0]) {
      data.value[0] = '';
    }

    if (!data.value[1]) {
      data.value[1] = '';
    }

    return {
      id: this.getId(),
      contains: this.getContains(),
      data,
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    const data = this.getData();
    if (!data.value[0]) {
      data.value[0] = null;
    }

    if (!data.value[1]) {
      data.value[1] = null;
    }

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: ClaimAmountBlock.DTOType,
      data,
    };
  }

  // eslint-disable-next-line
  public validate() {}

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

}

export default ClaimAmountBlock;
