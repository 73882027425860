import styled from 'styled-components';

const style: any = {};

style.TopHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 8px 16px 16px;
  font-size: 17px;
  font-weight: 500;
  height: 57px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  position: sticky;
  top: 0;
  z-index: 1;
`;

style.Title = styled.div`
  display: flex;
  flex: 1;
  font-family: Roboto-medium;
  position: sticky;
  left: 12px;
`;

style.Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  position: sticky;
  right: 16px;
`;

style.ActionIcon = styled.div`
  margin-right: 8px;
`;

style.OptionsKebab = styled.div`

`;

export default style;
