import { AnalysisSettings } from '../../../../redux/settings';
import { documentType } from '../../../../redux/state';

export const updateViewReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const {
    type,
    format,
  } = action.data;

  const newSettings = {
    type,
    format,
  };

  const settings = new AnalysisSettings();
  const layout = settings.updateSettings(state.analysis.itemsMap[documentType.getId()].layout, newSettings);

  return {
    ...state,
    analysis: {
      ...state.analysis,
      itemsMap: {
        ...state.analysis.itemsMap,
        [documentType.getId()]: {
          ...state.analysis.itemsMap[documentType.getId()],
          layout,
        },
      },
    },
  };
};

export default updateViewReduce;
