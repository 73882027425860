import { Dispatch } from 'redux';

import { resetToInitial as resetForm } from '@pages/search/redux/resetToInitial';
import { resetChronologyDocuments } from '@layouts/chronology/redux/resetChronologyDocuments';

import { resetToInitial } from './resetToInitial';

export class ResetToInitialThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    this.dispatchResetToInitialPackages();
    this.dispatchResetToInitialSearchFilters();
    this.dispatchResetChronologyDocuments();
  }

  private dispatchResetToInitialPackages() {
    this.dispatch(resetToInitial.createAction());
  }

  private dispatchResetToInitialSearchFilters() {
    this.dispatch(resetForm.createAction());
  }

  private dispatchResetChronologyDocuments() {
    this.dispatch(resetChronologyDocuments.createAction());
  }

}

export async function resetToInitialThunk(dispatch) {
  const thunk = new ResetToInitialThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke();
}

export default ResetToInitialThunk;
