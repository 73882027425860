import { connect } from 'react-redux';

import { List } from './list';

const mapStateToProps = (state) => {
  const {
    currentPageNumber,
    pages,
  } = state.similar;

  const hasPage = pages[currentPageNumber];

  if (!hasPage) {
    return {
      items: [],
    };
  }

  const items = pages[currentPageNumber].items;

  return {
    items,
  };

};

export const ListConnect = connect(
  mapStateToProps,
)(List);

export default ListConnect;
