import React, { Component } from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { Checkbox } from '@components/checkbox/checkbox';
import { CellMore } from '@pages/searchResult/documents/list/cellMore';

import { grid } from '@pages/regulation/workspace/projects/table/table';

import {
  Id,
  Project,
} from '../../redux/state';

import { CommentConnect } from '../../projects/list/comment.connect';
import { AddFolderConnect } from '../../projects/list/addFolder.connect';
import { FamiliarizeConnect } from '../../projects/list/familiarize.connect';

import { prepareMarkup } from '../../projects/list/item';
import s from './table.style';

interface State {
  showPreview: boolean;
}

interface Props {
  item: Project;
  isSelect: boolean;
  isCheckDisable: boolean;
  isActive: boolean;
  columns: any;
  onItemClick: (id: Id) => void;
  onItemCheck(): void;
  onItemUncheck(): void;
}

export class Item extends Component<Props, State> {

  handleItemSelect = () => {
    if (!this.props.isSelect) {
      this.props.onItemCheck();

      sendAmplitudeData('regulation: project selected', { projectNumber: this.props.item.projectNumber });
      Userpilot.track('regulation: project selected', { projectNumber: this.props.item.projectNumber });

      return;
    }

    this.props.onItemUncheck();
  };

  handleItemClick = () => {
    this.props.onItemClick(this.props.item.id);
  };

  renderTitle = (item) => (
    <s.TitleText
      dangerouslySetInnerHTML={prepareMarkup(item)}
    />
  );

  renderIsNew() {
    if (!this.props.item.isNew) {
      return null;
    }

    return <s.Dot />;
  }

  renderItem = (item) => {
    const key = `${this.props.item.id}-${item.id}`;

    return (
      <s.CellItem
        column={grid[item.columnId]}
        key={key}
      >
        <s.BodyCellContainer>
          {this.renderTitle(this.props.item[item.id])}
        </s.BodyCellContainer>
      </s.CellItem>
    );
  };

  renderIsNewCell() {
    return (
      <s.CellIsNew>
        <s.IsNewCellContainer>
          {this.renderIsNew()}
        </s.IsNewCellContainer>
      </s.CellIsNew>
    );
  }

  renderCheckbox() {
    return (
      <s.CellCheckbox>
        <s.CheckboxCellContainer>
          <Checkbox
            checked={this.props.isSelect}
            disable={this.props.isCheckDisable}
            onClick={this.handleItemSelect}
          />
        </s.CheckboxCellContainer>
      </s.CellCheckbox>
    );
  }

  render() {
    return (
      <s.Item
        isActive={this.props.isActive}
        onClick={this.handleItemClick}
      >
        {this.renderIsNewCell()}
        {this.renderCheckbox()}
        {this.props.columns.map(this.renderItem)}
        <CellMore>
          <FamiliarizeConnect id={this.props.item.id} />
        </CellMore>
        <CellMore>
          <CommentConnect id={this.props.item.id} />
        </CellMore>
        <CellMore>
          <AddFolderConnect id={this.props.item.id} from="list" />
        </CellMore>
      </s.Item>
    );
  }

}

export default Item;
