import { Dispatch } from 'redux';

import { Tag } from '@pages/packages/redux/state';
import { httpSetTag } from '@pages/packages/redux/documents/setTag.http';

import { setTag } from './setTag';

export class SetTagThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Record<any, any>) {
    const activeItemId = this.getState().authorPackages.id;

    this.dispatchSetTag(params);

    try {
      const requestParams = {
        documentId: params.documentId,
        tagId: Tag[params.tagId],
        packageId: activeItemId,
      };

      await httpSetTag(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchSetTag(params) {
    this.dispatch(setTag.createAction(params));
  }

}

export function setTagThunk(dispatch, params) {
  const thunk = new SetTagThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SetTagThunk;
