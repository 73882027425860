import { connect } from 'react-redux';
import {
  getCurrentMonth,
  getCurrentYear,
} from '../../utils';

import { Carousel } from './carousel';

const mapStateToProps = (state) => ({
  currentYear: state.regulation.events.activeDate.year || getCurrentYear(),
  currentMonth: state.regulation.events.activeDate.month || getCurrentMonth(),
  currentDay: state.regulation.events.activeDate.day,
});

export const CarouselConnect = connect(
  mapStateToProps,
)(Carousel);

export default CarouselConnect;
