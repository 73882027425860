import { connect } from 'react-redux';

import { fetchNotificationsThunk } from '../redux/fetchNotifications.thunk';
import { resetNotifications } from '../redux/resetToDefault';

import { NotificationsList } from './notificationsList';

const mapStateToProps = (state) => ({
  items: state.notificationsBuffer.queue,
  loading: state.notificationsBuffer.loading,
  shouldFetchMore: state.notificationsBuffer.page < state.notificationsBuffer.totalPages,
  disabled: !state.notificationsBuffer.queue.length,
});

const mapDispatchToProps = (dispatch) => ({
  async onFetchNotifications() {
    await fetchNotificationsThunk(dispatch);
  },
  onReset() {
    dispatch(resetNotifications.createAction());
    fetchNotificationsThunk(dispatch);
  },
});

export const NotificationsListConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsList);

export default NotificationsListConnect;
