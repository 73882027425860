import styled from 'styled-components';

interface Props {
  alert: boolean;
  theme: Record<any, any>;
}

const style: any = {};

style.Root = styled.div`
  background: ${(props: Props) => props.theme.colors.white};
`;

style.Header = styled.div`
  display: flex;
  padding: 0 8px 0 16px;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  box-shadow: 0 1px 0 0 ${(props: Props) => props.theme.colors.borderColor};
`;

style.HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font: 17px Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.defaultText};
`;

style.HeaderActions = styled.div`
  padding-top: 1px;
`;

style.Caption = styled.div`
  display: flex;
  height: 40px;
  padding: 0 16px;
  align-items: center;
  font: 11px Roboto-Bold;
  color: ${(props: Props) => props.theme.colors.defaultText};
  box-shadow: 0 1px 0 0 ${(props: Props) => props.theme.colors.borderColor};
`;

style.List = styled.div`
  display: flex;
  flex-direction: column;
`;

style.LoaderContainer = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: center;
`;

style.Loader = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

style.Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 12px 0 16px;
  box-shadow: 0 1px 0 0 ${(props: Props) => props.theme.colors.borderColor};
`;

style.ItemTitleContainer = styled.div`
  display: flex;
  flex: 1;
  padding-right: 8px;
  overflow: hidden;
`;

style.ItemTitle = styled.a`
  flex: 1;
  text-decoration: none;
  font: 13px Roboto-Regular;
  line-height: 16px;
  padding-top: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props: Props) => props.theme.colors.blue};
`;

style.Switch = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 34px;
  cursor: pointer;
`;

export default style;
