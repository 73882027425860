import {
  styled,
  css,
} from '@components/styled';

const style: any = {};

interface Props {
  disabled: boolean;
}

style.Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 64px 0 32px;
`;

style.Title = styled.div`
  display: flex;
  align-items: center;

  > span {
    font: 17px Roboto-Medium;
    color: ${(props) => props.theme.colors.defaultText};
  }
`;

style.TitleContent = styled.span`
  text-transform: capitalize;
`;

style.Status = styled.div`
  display: flex;
  align-items: center;
`;

style.BetaIcon = styled.div`
  margin-left: 8px;
`;

style.ArrowIcon = styled.div`
  margin-right: 8px;
  display: flex;
  cursor: pointer;

  ${(props: Props) => props.disabled && css`
    pointer-events: none;
  `}
`;

style.Icon = styled.div`
  position: relative;
`;

style.Loader = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  > div {
    top: 16px;
    left: 16px;
    border-width: 2px;
  }
`;

style.Text = styled.span`
  margin-left: 8px;
  font: 11px Roboto-Regular;
  color: ${(props) => props.theme.colors.stone};
`;

export default style;
