import React from 'react';

import { DateConnect } from './date.connect';
import { AuthorConnect } from './author.connect';
import { BranchOfLegislationConnect } from './branchOfLegislation.connect';
import { ProjectTypeConnect } from './projectType.connect';
import { PhaseConnect } from './phase.connect';
import { ProjectNumberConnect } from './projectNumber.connect';

import s from './filters.style';

interface FiltersProps {
  disabled: boolean;
}

export class Filters extends React.PureComponent<FiltersProps> {

  render() {
    return (
      <s.Root>
        <DateConnect disabled={this.props.disabled} />
        <AuthorConnect disabled={this.props.disabled} />
        <BranchOfLegislationConnect disabled={this.props.disabled} />
        <ProjectTypeConnect disabled={this.props.disabled} />
        <PhaseConnect disabled={this.props.disabled} />
        <ProjectNumberConnect disabled={this.props.disabled} />
      </s.Root>
    );
  }

}

export default Filters;
