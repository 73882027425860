interface BodyBlock {
  id: string;
  body: string;
}

interface TitleItem {
  id: string;
  title: string;
}

interface Document {
  bodyBlocks: BodyBlock[];
  titles: TitleItem[];
  loading: boolean;
}

interface Edition {
  id: string;
  title: string;
}

export interface Editions {
  items: Edition[];
  loading: boolean;
}

interface State {
  documentId: string;
  title: string;
  type: string;
  isActual: boolean;
  edition: string;
  document: Document;
  editions: Editions;
  layout: {
    sidebar: {
      activeTabId: TabIds;
    }
  }
}

export enum TabId {
  tableOfContents = 'tableOfContents',
  editions = 'editions',
}

export type TabIds = TabId.tableOfContents | TabId.editions;

export interface Layout {
  sidebar: {
    activeTabId: TabIds;
  }
}

export class StateBuilder {

  documentId: string = null;

  title: string = null;

  type: string = null;

  edition: string = null;

  isActual: boolean = true;

  document: Document = StateBuilder.createInitialDocument();

  editions: Editions = StateBuilder.createInitialEditions();

  layout: Layout = StateBuilder.createInitialLayout();

  public createState(state) {
    this.documentId = state.documentId;
    this.title = state.title;
    this.type = state.type;
    this.edition = state.edition;
    this.isActual = state.isActual;
    this.document = { ...state.document };
    this.editions = { ...state.editions };
    this.layout = { ...state.layout };
  }

  static createInitialLayout() {
    return {
      sidebar: {
        activeTabId: TabId.tableOfContents,
      },
    };
  }

  static createInitialDocument() {
    return {
      bodyBlocks: [],
      titles: [],
      loading: true,
    };
  }

  static createInitialEditions() {
    return {
      items: [],
      loading: true,
    };
  }

  static createInitialState(): State {
    return {
      documentId: null,
      title: null,
      type: null,
      edition: null,
      isActual: true,
      document: StateBuilder.createInitialDocument(),
      editions: StateBuilder.createInitialEditions(),
      layout: {
        sidebar: {
          activeTabId: TabId.tableOfContents,
        },
      },
    };
  }

  public prepareTitles(body) {
    body.forEach((block) => {
      if (block.title) {
        this.document.titles.push({
          id: block.id,
          title: block.title,
        });
      }
    });
  }

  public prepareBasicInformation(params) {
    this.title = params.title;
    this.type = params.type;
    this.documentId = params.id;
    this.edition = params.edition;
    this.isActual = params.is_actual;
  }

  public prepareDocumentBody(body) {
    this.document.bodyBlocks = body.map((block) => ({
      id: block.id,
      title: block.title,
      body: block.body,
    }));
  }

  public prepareEditions(data) {
    this.editions.items = data.map((item) => ({
      id: item.link,
      title: item.title,
    }));
  }

  public setDocumentLoading(loading = true) {
    this.document.loading = loading;
  }

  public setEditionsLoading(loading = true) {
    this.editions.loading = loading;
  }

  public getState() {
    return {
      documentId: this.documentId,
      title: this.title,
      type: this.type,
      isActual: this.isActual,
      edition: this.edition,
      document: this.document,
      editions: this.editions,
      layout: this.layout,
    };
  }

}

export default StateBuilder;
