import { Dispatch } from 'redux';

import { httpFetchAnnotation } from './fetchAnnotation.http';
import { httpFetchAnnotationRating } from './fetchAnnotationRating.http';
import { updateFetchError } from './updateFetchError';
import { startAnnotationProgress } from './startAnnotationProgress';

import { addAnnotation } from './addAnnotation';

export interface RequestParams {
  documentId: number;
}

export class FetchAnnotationThunk {

  getState;

  private readonly dispatch: Dispatch;

  private requestParams: RequestParams;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {

    if (!params.documentId) {
      return;
    }

    const state = this.getState().annotations;
    const stateAnnotation = state.items.get(params.documentId);
    if (stateAnnotation && !stateAnnotation.inProgress) {
      return;
    }

    this.requestParams = {
      documentId: params.documentId,
    };

    this.dispatchFetchStart(params.documentId);
    this.getAnnotation();
  }

  private async getAnnotation() {
    const annotation = await this.fetchAnnotation();
    const { value } = await this.fetchRating();

    this.dispatchFetchSucceed({ annotation, rating: value, documentId: this.requestParams.documentId });
  }

  private async fetchAnnotation() {
    try {
      const result = await httpFetchAnnotation(this.requestParams);

      return result;
    } catch (error) {
      this.dispatchFetchError(this.requestParams.documentId);
      throw Error(error.status);
    }
  }

  private async fetchRating() {
    try {
      const result = await httpFetchAnnotationRating(this.requestParams);

      return result;
    } catch (error) {
      this.dispatchFetchError(this.requestParams.documentId);
      throw Error(error.status);
    }
  }

  private dispatchFetchError(id) {
    this.dispatch(updateFetchError.createAction({ id }));
  }

  private dispatchFetchStart(id) {
    this.dispatch(startAnnotationProgress.createAction({ documentId: id }));
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(addAnnotation.createAction(result));
  }

}

export async function fetchAnnotationThunk(dispatch, params) {
  const thunk = new FetchAnnotationThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default FetchAnnotationThunk;
