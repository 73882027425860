import type { FetchedDocuments } from '@layouts/chronology/redux/state';

export type ActionData = FetchedDocuments;

interface Action {
  type: string;
  data: ActionData;
}

function prepareChronologyItem(item) {
  return {
    documentId: item.document_id,
    title: `${item.type_title} от ${item.date}`,
    department: item.department,
  };
}

function prepareChronology(items) {
  const data = items.map((item) => prepareChronologyItem(item));

  return data;
}

export const fetchChronologyReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    chronology: {
      data: prepareChronology(action.data),
      loading: false,
    },
  };
};

export default fetchChronologyReduce;
