import {
  loadingStartReduce,
  loadingEndReduce,
} from './loading.reduce';

export const actionTypeStart = 'SEARCH/RESULT/DOCUMENTS/LOADING_START';
export const actionTypeEnd = 'SEARCH/RESULT/DOCUMENTS/LOADING_END';

const createStartAction = () => ({
  type: actionTypeStart,
});

const createEndAction = () => ({
  type: actionTypeEnd,
});

export const loadingStart = {
  actionType: actionTypeStart,
  createAction: createStartAction,
  reduce: loadingStartReduce,
};

export const loadingEnd = {
  actionType: actionTypeEnd,
  createAction: createEndAction,
  reduce: loadingEndReduce,
};
