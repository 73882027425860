import { toggleCommentaryReduce } from './toggleCommentary.reduce';

export const actionType = 'SEARCH/RESULT/DOCUMENTS/TOGGLE_COMMENTARY';

const createAction = () => ({
  type: actionType,
});

export const toggleCommentary = {
  actionType,
  createAction,
  reduce: toggleCommentaryReduce,
};
