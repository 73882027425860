import styled from 'styled-components';

import button from '@components/button/button.style';

const style: any = {};

style.Button = styled(button.Root)`
  display: inline-flex;
  align-items: center;
  padding: 0;

  > i {
    margin: 0 8px 0 -4px;
  }
`;

style.Text = styled(button.Text)`
`;

export default style;
