import { fetchSimilarReduce } from './fetchSimilar.reduce';

import type { ActionData } from './fetchSimilar.reduce';

const actionType = 'DOCUMENT/FETCH_SIMILAR';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSimilar = {
  actionType,
  createAction,
  reduce: fetchSimilarReduce,
};

export default fetchSimilar;
