import styled from 'styled-components';

interface Props {
  theme: Record<any, any>;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
`;

style.Title = styled.div`
  font: 17px Roboto-Medium;
  line-height: 20px;
`;

style.Description = styled.div`
`;

style.Theme = styled.div`
  margin-bottom: 16px;
  padding: 16px 16px 0 16px;
  background: ${(props: Props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

export default style;
