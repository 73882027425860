import React, { useState } from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { LayoutSettings } from './layoutSettings';

import s from './settingPopover.style';

interface InputComponentProps {
  isActive: boolean;
}

const InputComponent = (props: InputComponentProps) => {
  const iconPath = iconsPath.get('kebab');

  return (
    <s.IconBlock>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color="currentColor" />
      </s.Button>
    </s.IconBlock>
  );
};

export const SettingsPopover = () => {
  const [wide, setWide] = useState(false);
  const [level, setLevel] = useState(0);

  const handleLevelChange = (params: { wide: boolean; level: number }) => {
    setWide(params.wide);
    setLevel(params.level);
  };

  const renderMenu = (p) => (
    <LayoutSettings {...p} onLevelChange={handleLevelChange} />
  );

  return (
    <BaseDropdown
      wide={wide}
      level={level}
      forceLeftRecalculate
      onLevelChange={handleLevelChange}
      placement="bottom-end"
      renderInput={(p) => <InputComponent {...p} />}
      renderMenu={(p) => renderMenu(p)}
    />
  );
};

export default SettingsPopover;
