import React from 'react';

import { theme } from '@components/styled';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './settingsButton.style';

interface InputComponentProps {
  isActive: boolean;
  disabled?: boolean;
}

export const SettingsButton = (props: InputComponentProps) => {
  const color = props.disabled ? theme.colors.disabled : theme.colors.stone;
  const iconPath = iconsPath.get('kebab');

  return (
    <s.Root>
      <s.Button
        isActive={props.isActive}
      >
        <Icon path={iconPath} color={color} />
      </s.Button>
    </s.Root>
  );
};

export default SettingsButton;
