import { Filters } from '../filters';
import { normalizeType } from '../utils/blocksTypesMap';

function getQuery(blocks, state) {
  if (!blocks.length) {
    return {
      blocksList: state.defaultQuery.blocksList,
      blocksMap: state.defaultQuery.blocksMap,
      smartFilter: {
        query: null,
        hasChange: false,
        preparing: false,
      },
    };
  }

  const filters = new Filters();

  blocks.forEach((blockDTO) => {
    const block = normalizeType(blockDTO);
    const data = {
      type: block,
      defaultData: undefined,
    };

    const presetExist = !!state.presets[block];
    if (presetExist) {
      const defaultData = {
        ...state.presets[block][0],
      };

      data.defaultData = defaultData;
    }

    filters.addFilterBlock(data, true);
  });

  return {
    blocksList: filters.blocksList,
    blocksMap: filters.blocksMap,
    smartFilter: {
      query: null,
      hasChange: false,
      preparing: false,
    },
  };
}

export const resetFormReduce = (state) => {
  if (!state) {
    return state;
  }

  const pinnedfilters = state.query.blocksList.reduce((acc, filterId) => {
    const filter = state.query.blocksMap.get(filterId);
    if (filter.pinned) {
      acc.push(filter.type.DTOType);
    }

    return acc;
  }, []);

  const query = getQuery(pinnedfilters, state);

  return {
    ...state,
    query,
  };
};

export default resetFormReduce;
