import React from 'react';
import {
  Icon,
  iconsPath,
} from '@components/icons';

export enum SortDirection {
  DESC = 'desc',
  ASC = 'asc',
}

export type SortType = SortDirection.DESC | SortDirection.ASC;

export interface SortDataProps {
  id: string;
  type: SortType;
}

export const sortDataDefault: SortDataProps = {
  id: 'all',
  type: SortDirection.DESC,
};

interface SortIconProps {
  sortType: SortType;
  color: string;
}

export const SortIcon: React.FC<SortIconProps> = ({ sortType = sortDataDefault.type, color }: SortIconProps) => {
  const path = iconsPath.get(sortType);

  return (
    <Icon path={path} color={color} />
  );
};

export default SortIcon;
