import {
  DocumentId,
  Id,
  Source,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

import type { Marker } from './state';

export interface RequestParams {
  source: Source.search | Source.package | Source.subscription;
  sourceId: Id;
  documentId: DocumentId;
  data: {
    entities: Marker[];
  };
}

export function httpSaveMarkers(requestParams: RequestParams) {
  const request = http.post({
    url: RESTPath.document.saveEntities(requestParams),
    data: requestParams.data,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSaveMarkers;
