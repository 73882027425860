import { styled } from '@components/styled';

import {
  hover,
  iconButton,
} from '@components/button/button.style';

const style: any = {};

interface Props {
  theme: any;
}

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 384px;
`;

style.Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-shrink: 0;

  height: 48px;
  padding: 0 8px;

  font-family: Roboto-Medium;
  font-size: 13px;

  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Annotation = styled(style.Header)`
  height: auto;
  padding: 0 16px;
  font-family: Roboto-Regular;
  display: block;

  > p {
    padding: 12px 0;
    margin: 0;
  }

  > ol {
    padding: 0 16px;
    margin: 0;
  }

  > p:first-of-type {
    padding-bottom: 4px;
  }

  > p:nth-of-type(2) {
    padding-bottom: 0;
  }

  > p:last-of-type {
    padding-bottom: 11px;
  }
`;

style.HeaderAction = styled.div`
  z-index: 1;

  > div {
    ${iconButton};
    ${hover};
  }
`;

style.TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

style.HeaderTitle = styled.div``;

style.Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: white;
  width: 100%;
`;

style.Actions = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  height: 48px;
`;

style.CancelButton = styled.div`
  margin-right: 4px;
`;

export default style;
