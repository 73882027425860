import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

style.Container = styled.div`
  padding: 0 0 56px 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgb(249, 249, 249);
`;

style.Page = styled.div`
  padding: 16px 64px;
  margin-bottom: 16px;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid rgb(223, 226, 230);

  .composite {
    height: auto !important;
  }
`;

style.Paragraph = styled.div`
  line-height: 25px;
  position: relative;
  font-size: 0;
  padding: 0;

  .text * {
    white-space: pre;
  }

  .block * {
    position: absolute;
    padding: 0;
    margin: 0;
    font-size: 0;
    z-index: 2;
  }
`;

style.Row = styled.div``;

style.Loader = styled.div`
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
