import React from 'react';

import copy from 'copy-to-clipboard';

import { Source } from '@router/path.rest';

import {
  SaveButton,
  ResetButton,
} from '@components/buttons';

import { Loader } from '@components/loader';

import { httpGetSharedLink } from './getSharedLink.http';

import s from './sharedLinkCopy.style';

interface SharedLinkCopyProps {
  id: number,
  source: string,
  sourceId: number,
  onClick?: () => void;
  closePopover: () => void;
}

interface SharedLinkCopyState {
  link: string;
  loading: boolean;
}

export class SharedLinkCopy extends React.PureComponent<SharedLinkCopyProps, SharedLinkCopyState> {

  static defaultProps = {
    onClick: () => {},
  };

  state = {
    link: null,
    loading: true,
  };

  componentDidMount() {
    this.fetchSharedLink();
  }

  setLink = (link) => {
    this.setState({
      link,
    });
  };

  setLoadingFalse = () => {
    this.setState({
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.onClick();
    this.props.closePopover();
  };

  handleCopyRequisites = () => {
    copy(this.state.link);
    this.props.onClick();
    this.props.closePopover();
  };

  async fetchSharedLink() {
    const requestParams = {
      id: this.props.sourceId,
      source: this.props.source,
      documentId: this.props.id,
    };

    if (this.props.source === Source.authorPackage) {
      // eslint-disable-next-line max-len
      const link = `${window.location.origin}/#/${Source.authorPackage}s/${this.props.sourceId}/documents/${this.props.id}`;
      this.setLink(link);
      this.setLoadingFalse();

      return;
    }

    const result = await httpGetSharedLink(requestParams);
    this.setLink(result.link);
    this.setLoadingFalse();
  }

  renderHeader() {
    return (
      <s.Header>
        Ссылка на документ
      </s.Header>
    );
  }

  renderContent() {
    if (!this.state.loading) {
      return this.renderRequisites();
    }

    return this.renderLoader();
  }

  renderRequisites() {
    return (
      <s.Requisites>
        {this.state.link}
      </s.Requisites>
    );
  }

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderAnnotation() {
    return (
      <s.Annotation>
        Данная ссылка будет активна в течение суток. Доступ к документу по этой ссылке не требует авторизации в системе.
      </s.Annotation>
    );
  }

  renderResetButton() {
    return (
      <ResetButton
        onClick={this.handleCancel}
      />
    );
  }

  renderSaveButton() {
    const title = 'Копировать';

    return (
      <SaveButton
        onClick={this.handleCopyRequisites}
        title={title}
      />
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderAnnotation()}
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Container>
    );
  }

}

export default SharedLinkCopy;
