import React, { Component } from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import type { Document } from '@layouts/chronology/redux/state';

import { SettingsConnect } from './settings.connect';

import { CellMore } from './cellMore';

import s from './list.style';

interface State {
  showPreview: boolean;
}

type Id = string | number;

interface Props {
  documentId: Id;
  item: Document;
  baseHref: string;
}

export class Item extends Component<Props, State> {

  handleHrefClick = (e) => {
    sendAmplitudeData('chronology: open chronology document');
    Userpilot.track('chronology: open chronology document');
    e.stopPropagation();

    const href = `${this.props.baseHref}/chronology/${this.props.item.id}`;
    window.open(href, '_blank');
  };

  render() {
    const {
      item,
    } = this.props;

    return (
      <s.Item
        onClick={this.handleHrefClick}
      >
        <s.CellDocument>
          <s.BodyCellContainer>
            <s.TitleText bold>{item.date}</s.TitleText>
            <s.TitleLink>
              {item.documentType}
            </s.TitleLink>
            <s.TitleLabel>{item.instanceNumber}</s.TitleLabel>
          </s.BodyCellContainer>
        </s.CellDocument>
        <s.CellText>
          <s.BodyCellContainer>
            <s.TitleText bold>{item.department}</s.TitleText>
            <s.SimpleTitleText>{item.documentTitle}</s.SimpleTitleText>
            <s.TitleAdditionalText>{item.caseResult}</s.TitleAdditionalText>
          </s.BodyCellContainer>
        </s.CellText>
        <CellMore>
          <SettingsConnect
            documentId={this.props.documentId}
            id={item.id}
          />
        </CellMore>
      </s.Item>
    );
  }

}

export default Item;
