import { fetchNotificationsReduce } from './fetchNotifications.reduce';

import type { ActionData } from './fetchNotifications.reduce';

const actionType = 'REGULATION/FETCH_NOTIFICATIONS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchNotifications = {
  actionType,
  createAction,
  reduce: fetchNotificationsReduce,
};

export default fetchNotifications;
