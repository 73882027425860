import {
  JudgeBlock, JudgeBlockData,
} from '../../form/filterBlocks/judge/redux/judge';

export abstract class JudgeBlockHistory extends JudgeBlock {

  static transform(data: JudgeBlockData): string {
    return data.title || '';
  }

}

export default JudgeBlockHistory;
