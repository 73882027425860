import { updateFoldersCountReduce } from './updateFoldersCount.reduce';

import type { ActionData } from './updateFoldersCount.reduce';

const actionType = 'REGULATION/PROJECTS/UPDATE_FOLDERS_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateFoldersCount = {
  actionType,
  createAction,
  reduce: updateFoldersCountReduce,
};

export default updateFoldersCount;
