import { RESTPath } from '@router/path.rest';
import http from '@http';

export interface RequestParams {
  clientId: string;
  projectId: number;
  body: string;
}

export function httpSendMessage(requestParams: RequestParams): Promise<any> {
  const url = RESTPath.regulation.chat.comment;
  const request = http.post({
    url,
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSendMessage;
