import { StateBuilder } from './state';

export const resetAttributesReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    attributes: StateBuilder.createInitialAttributes(),
  };
};

export default resetAttributesReduce;
