import { StateBuilder } from './state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return StateBuilder.createInitialState();
};

export default resetToInitialReduce;
