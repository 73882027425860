import { fetchCaseTypeReduce } from './fetchCaseType.reduce';

import type { ActionData } from './fetchCaseType.reduce';

const actionType = 'SEARCH/RESULT/FETCH_CASE_TYPE_ID';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchCaseType = {
  actionType,
  createAction,
  reduce: fetchCaseTypeReduce,
};

export default fetchCaseType;
