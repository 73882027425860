import {
  ActionData,
  deleteBookmarkInBufferReduce,
} from './deleteBookmark.reduce';

export const actionType = 'BOOKMARKS_BUFFER/DELETE_ITEM';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const deleteBookmarkInBuffer = {
  actionType,
  createAction,
  reduce: deleteBookmarkInBufferReduce,
};
