import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { FetchSettingsThunk } from './redux/fetchSettings.thunk';
import { FetchSearchYearThunk } from './redux/fetchYear.thunk';

import { Date } from './date';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSettingsThunk(dispatch);
    thunk.invoke();
  },
  onFetchData() {
    const thunk = new FetchSearchYearThunk(dispatch);

    const { sourceId } = getParamsFromUrl(ownProps);
    thunk.invoke(sourceId);

    return thunk;
  },
});

export const DateConnect = connect(
  null,
  mapDispatchToProps,
)(Date);

export default DateConnect;
