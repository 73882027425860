import { Rating } from '@layouts/documentHelpers/annotation/redux/annotation';

import {
  StateBuilder,
  DocumentId,
} from './state';

import { SummaryItem } from './summary';

export interface ActionData {
  summary: SummaryItem,
  documentId: DocumentId
  rating: Rating;
}

interface Action {
  type: string;
  data: ActionData;
}

export const addSummaryReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.items);

  const summary = {
    rating: action.data.rating,
    ...action.data.summary,
  };

  newState.addItem(action.data.documentId, summary);
  newState.updateFetchError(action.data.documentId, false);
  newState.updateExpanded(action.data.documentId);
  newState.updateItemProgress(action.data.documentId, false);

  return newState.getState();
};

export default addSummaryReduce;
