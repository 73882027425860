import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  min-height: 26px;
`;

style.RootLabel = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
`;

style.Label = styled.label`
  color: ${theme.colors.blue};
  cursor: pointer;
  font-size: 11px;
  padding: 0 16px;
  :hover {
    text-decoration: underline;
  }
`;

export default style;
