import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Control } from './control';
import { Layout } from './layout';

import 'react-datepicker/dist/react-datepicker.css';

import s from './dateBlock.style';

export interface DateBlockProps {
  disabled: boolean;
  isDefault: boolean;
  data: any;
  onUpdateValue: (data: any) => void;
  onUpdateScope: (data: any) => void;
}

export class DateBlock extends React.PureComponent<DateBlockProps> {

  handleFromChange = (value) => {
    const data = [value, this.props.data.value[1]];

    this.props.onUpdateValue(data);
  };

  handleToChange = (value) => {
    const data = [this.props.data.value[0], value];

    this.props.onUpdateValue(data);
  };

  handleScopeChange = (scopeId) => {
    this.props.onUpdateScope(scopeId);
  };

  render() {
    return (
      <s.Root>
        <BaseDropdown
          usePortal
          disabled={this.props.disabled}
          placement="bottom-start"
          renderInput={(p) => (
            <Control
              isDefault={this.props.isDefault}
              {...p}
            />
          )}
          renderMenu={(p) => (
            <Layout
              {...this.props.data}
              onFromChange={this.handleFromChange}
              onToChange={this.handleToChange}
              onScopeChange={this.handleScopeChange}
              {...p}
            />
          )}
        />
      </s.Root>
    );
  }

}

export default DateBlock;
