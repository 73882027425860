import { Map } from 'immutable';

export type LayoutType = 'list' | 'table';

type Id = 'type_title' | 'date' | 'case_result' | 'department' | 'judges' | 'category_dispute_title' |
'case_type_title' | 'instance' | 'case_number' | 'appeal_state';

type Title = 'Результат обжалования' | 'Номер дела' | 'Исход рассмотрения' | 'Вид спора' | 'Категория спора' |
'Дата принятия' | 'Суд' | 'Инстанция рассмотрения' | 'Судья' | 'Тип документа';

interface Item {
  id: Id;
  showColumn: boolean;
  title: Title;
}

export class Layout {

  private viewType: LayoutType;

  private tableView: {
    columns: {
      itemsList: string[];
      itemsMap: Map<Id, Item>;
    };
    defaultColumns: {
      itemsList: string[];
      itemsMap: Map<Id, Item>;
    }
  };

  constructor() {
    this.viewType = 'list';
    this.tableView = {
      columns: {
        itemsList: [],
        itemsMap: Map(),
      },
      defaultColumns: {
        itemsList: [],
        itemsMap: Map(),
      },
    };
  }

  static createState() {
    return {
      viewType: 'list',
      tableView: {
        columns: {
          itemsList: [],
          itemsMap: Map(),
        },
        defaultColumns: [],
      },
    };
  }

}

export default Layout;
