import styled from 'styled-components';

const style: any = {};

style.Title = styled.div`
  align-items: center;
  display: flex;
`;

export default style;
