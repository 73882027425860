import React, { useState } from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { BookmarkButtonConnect as BookmarkButton } from './bookmarkButton.connect';

import { BookmarksListConnect as BookmarksList } from './bookmarksList.connect';

import s from './bookmarks.style';

export const Bookmarks = (props: { disabled?: boolean; shouldRender?: true }) => {
  if (!props.shouldRender) {
    return null;
  }

  const [shouldUpdate, changeShouldUpdate] = useState(true);

  const handleMount = () => {
    changeShouldUpdate(false);
  };

  const handleUnmount = () => {
    changeShouldUpdate(true);
  };

  return (
    <s.Root>
      <BaseDropdown
        placement="bottom-end"
        disabled={props.disabled}
        forceMenuVisible
        renderInput={(p) => <BookmarkButton disabled={props.disabled} shouldUpdate={shouldUpdate} {...p} />}
        renderMenu={(p) => <BookmarksList onMount={handleMount} onUnmount={handleUnmount} {...p} />}
      />
    </s.Root>
  );
};

Bookmarks.defaultProps = {
  shouldRender: true,
};

export default Bookmarks;
