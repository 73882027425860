import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { httpDownloadComments } from './downloadComments.http';

export class DownloadCommentsThunk {

  getState;

  static notificationId = 'DOWNLOAD_COMMENTARY';

  static showNotification = () => {
    const message = 'Подготовка комментариев к скачиванию.\n' +
      'Пожалуйста, не закрывайте вкладку.';

    notifierManager.pending({ id: DownloadCommentsThunk.notificationId, message });
  };

  static removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: DownloadCommentsThunk.notificationId });
    }, 1000);
  };

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    DownloadCommentsThunk.showNotification();
    await this.downloadComments();
    DownloadCommentsThunk.removeNotification();
  }

  private async downloadComments() {
    try {
      return await httpDownloadComments();
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export async function downloadCommentsThunk(dispatch) {
  const thunk = new DownloadCommentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke();
}

export default DownloadCommentsThunk;
