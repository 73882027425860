import { DateBlock } from '../form/filterBlocks/date/redux/date';
import { ParticipantBlock } from '../form/filterBlocks/participant/redux/participant';

import {
  ScopeIdDTO,
  ScopeId,
} from '../history/components/filters/date/logic/date';

const datePresets = DateBlock.presets;
const participantPresets = [
  ParticipantBlock.presetsMap.exactly,
  ParticipantBlock.presetsMap.precise,
  ParticipantBlock.presetsMap.same,
];

export const initial = () => ({
  query: {
    blocksList: [],
    blocksMap: new Map(),
    smartFilter: {
      query: null,
      preparing: false,
      hasChange: false,
      prepareFail: false,
    },
  },
  defaultQuery: {
    blocksList: [],
    blocksMap: new Map(),
    smartFilter: {
      query: null,
      preparing: false,
      hasChange: false,
    },
    loading: true,
  },
  history: {
    data: [],
    loading: true,
    activeHistoryId: null,
    totalPages: null,
    currentPageNumber: 1,
    filters: {
      data: {
        scope: {
          DTOid: ScopeIdDTO.allTime,
          id: ScopeId.allTime,
        },
        value: ['', ''],
      },
    },
  },
  presets: {
    date: datePresets,
    participantModes: participantPresets,
  },
  subscription: {
    id: null,
    process: false,
  },
});

export default initial();
