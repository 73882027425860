import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

.document-view {
  background: #fff;
  position: relative;
  padding: 32px 0;
  margin-bottom: 32px;
  border-bottom: 1px solid #dadfe1;
  transition: all .1s linear;
}

.document-view a mark {
  color: inherit;
}

.document-view__meta {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 64px;
}

.document-view__articles {
  position: relative;
  overflow-wrap: break-word;
  max-width: 100%;
  color: transparent;
}

.document-view__articles > div:first-child {
  position: relative;
  z-index: 2;
}

.document-view mark {
  padding: 2px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.document-view mark.color-a {
  background-color: #ffd687;
}

.document-view mark.color-b {
  background-color: #cbef8d;
}

.document-view mark.color-c {
  background-color: #fec4db;
}

.document-view__clone-articles {
  position: absolute;
  top: -16px;
  overflow-wrap: break-word;
  max-width: 100%;
  color: #1c2935;
  z-index: 1;
}

.document-view__clone-articles .caselook__bookmark {
  display: none;
}

.article {
  margin: 16px 0;
  font: 13px Arial,Helvetica,sans-serif;
  line-height: 24px;
}

.g-highlight {
  background: #fcef9a;
  padding: 2px 0;
}

.mf-relations-links {
  cursor: pointer;
  color: #4183d7;
  text-decoration: none;
  outline: none;
}

.mf-relations-links, .transitioned-bg {
  transition: all 2s cubic-bezier(.23,1,.32,1);
}

.link-law-not-actual {
  text-decoration: line-through;
}

.article table {
  white-space: normal;
  table-layout: fixed;
  width: 100%;
}

.article table td{
  padding: 8px 16px;
  border: 1px solid #7F7F8E;
  overflow-wrap: break-word;
  word-break: break-all;
}

.article table td pre{
  display: block;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-all;
}

.document-view__appendix__type {
  font: 14px Arial,Helvetica,sans-serif;
  font-size: 12px;
  color: #74777e;
  padding: 0 96px;
}

.document-view__appendix__title {
  font-weight: 500;
  padding: 10px 96px 0;
}

mark {
  padding: 2px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

mark div.mark-delete-pivot {
  display: inline;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
}

mark div.mark-delete-helper {
  display: inline-block;
  padding: 0;
  border: none;
  position: absolute;
  width: 32px;
  height: 32px;
  background: url("/images/document-marker-delete-helper.svg") no-repeat center #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 3;
  margin: 20px 0 0;
}

mark div.mark-delete-helper:focus {
  outline: none;
}

mark div.mark-delete-helper:hover {
  background: url("/images/document-marker-delete-helper.svg") no-repeat center #f2f6fc;
}


mark div.mark-delete-helper:after {
  content: ' ';
  position: absolute;
  left: -10px;
  top: -20px;
  width: 35px;
  height: 40px;
}

.transitioned-bg {
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1);
}

.highlighted {
  background: #ffeb8a;
}
`;

export default GlobalStyle;
