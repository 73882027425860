import React from 'react';

import { Table } from './table';

import s from './definitionContentType.style';

interface Props {
  shouldRenderBlock: boolean;
  onFetchData: () => void;
  onFetchSettings: () => void;
}

export class DefinitionContentType extends React.PureComponent<Props> {

  componentDidMount() {
    this.props.onFetchSettings();
    this.props.onFetchData();
  }

  render() {
    if (!this.props.shouldRenderBlock) {
      return null;
    }

    return (
      <s.Root>
        <Table />
      </s.Root>
    );
  }

}

export default DefinitionContentType;
