import { Dispatch } from 'redux';

import { store } from '@redux/store';

import { paymentProcessStart } from './paymentProcessStart';

import { httpPaymentCreate } from './paymentCreate.http';

export interface Params {
  period: string;
}

export class PaymentProcessThunk {

  getState: ReturnType<typeof store.getState>;

  private readonly dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    await this.startProcess(params);
  }

  private async startProcess(params: Params) {
    const response = await httpPaymentCreate(params);
    if (response.error) {
      return;
    }

    this.dispatchProgressStart();
    window.location.href = response.link;
  }

  private dispatchProgressStart() {
    this.dispatch(paymentProcessStart.createAction());
  }

}

export function paymentProcessThunk(dispatch: Dispatch<any>, params: Params) {
  const thunk = new PaymentProcessThunk(dispatch);

  dispatch((_: any, getState: any) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default PaymentProcessThunk;
