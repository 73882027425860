import {
  CaseTypeBlock, CaseTypeBlockData,
} from '../../form/filterBlocks/caseType/redux/caseType';

import { PresetsData } from './presets';

interface CaseTypeBlockDataTransform {
  id: number,
  presetsData: PresetsData
}

export abstract class CaseTypeBlockHistory extends CaseTypeBlock {

  static transform({ id }, presetsData: CaseTypeBlockDataTransform): string {
    const presets = presetsData;

    const data = presets[this.type].reduce((acc: string, el: CaseTypeBlockData) => {
      let result = acc;
      const isEqual = el.id === id;

      if (isEqual) {
        result += el.title;
      }

      return result;
    }, '');

    return data;
  }

}

export default CaseTypeBlockHistory;
