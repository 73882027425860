import { connect } from 'react-redux';

import { sendMessageThunk } from '@pages/regulation/redux/chat/sendMessage.thunk';
import { httpFetchUsers } from '@pages/regulation/redux/chat/fetchUsers.http';

import { Chat } from '@layouts/chat/chat';

function buildMessages(items) {
  return items.itemsList.map((id) => items.itemsMap.get(id));
}

const mapStateToProps = (state) => ({
  messages: buildMessages(state.regulation.chat.messages),
  loading: state.regulation.chat.loading,
  avatar: state.currentUser.avatar,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSend(body: string) {
    const params = {
      body,
      projectId: ownProps.id,
    };

    sendMessageThunk(dispatch, params);
  },
  async mentionAPI(query: string) {
    const { result } = await httpFetchUsers(query);

    return result;
  },
});

export const ChatTabConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Chat);

export default ChatTabConnect;
