import { resetActiveHistoryItemReduce } from './resetActiveHistoryItem.reduce';

const actionType = 'SEARCH/HISTORY/RESET_ACTIVE_HISTORY_ITEM';

const createAction = () => ({
  type: actionType,
});

export const resetActiveHistoryItem = {
  actionType,
  createAction,
  reduce: resetActiveHistoryItemReduce,
};

export default resetActiveHistoryItem;
