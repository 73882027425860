import { StateBuilder } from './state';

export const fetchFolderLastPageReduce = (state) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.setLastPageReached();

  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default fetchFolderLastPageReduce;
