import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchFolder(folderId: string, filters: any, pageNumber = 1) {
  const url = RESTPath.regulation.folders.getFolder(folderId, pageNumber);
  const data = {
    filters,
  };

  const request = http.post({
    url,
    data,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchFolder;
