import { increaseFoldersCountReduce } from './increaseFoldersCount.reduce';

import type { ActionData } from './increaseFoldersCount.reduce';

const actionType = 'REGULATION/PROJECTS/INCREASE_FOLDER_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const increaseFoldersCount = {
  actionType,
  createAction,
  reduce: increaseFoldersCountReduce,
};

export default increaseFoldersCount;
