import styled from 'styled-components';

const style: any = {};

style.Container = styled.div`
  width: 256px;
  height: 100%;
  background-color: #21303f;
  display: flex;
  flex-direction: column;
`;

style.Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 55px;
`;

export default style;
