import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  padding: 8px 0;
`;

style.Title = styled.span`
  font: 13px Roboto-Regular;
  line-height: 16px;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

style.Description = styled.div`
  font: 11px Roboto-Regular;
  color: ${(props) => props.theme.colors.stone};
`;

export default style;
