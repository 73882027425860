export interface ActionData {
  text: string;
}

export const saveLastnameReduce = (state, action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    lastName: action.data.text,
  };
};

export default saveLastnameReduce;
