import {
  ScopeIdDTO,
  ScopeId,
} from '../components/filters/date/logic/date';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    history: {
      data: [],
      loading: true,
      activeHistoryId: null,
      totalPages: null,
      currentPageNumber: 1,
      filters: {
        data: {
          scope: {
            DTOid: ScopeIdDTO.allTime,
            id: ScopeId.allTime,
          },
          value: ['', ''],
        },
      },
    },
  };
};

export default resetToInitialReduce;
