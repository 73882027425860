import {
  Id,
  StateBuilder,
} from './state';

export interface ActionData {
  id: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const uncheckProjectReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.uncheckProject(action.data.id);
  const projects = newState.getState();

  return {
    ...state,
    projects,
  };
};

export default uncheckProjectReduce;
