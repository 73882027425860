import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { definitionContentType } from '../redux/state';

import { HeaderConnect } from './header/header.connect';
import { ListConnect } from './list/list.connect';

import s from './table.style';

export interface State {
  open: boolean;
}

export class Table extends React.PureComponent<any, State> {

  id = definitionContentType.getId();

  state = {
    open: false,
  };

  handleSetOpen = () => {
    this.setState((previousState) => ({
      ...previousState,
      open: !previousState.open,
    }));
  };

  renderIcon() {
    const iconPoints = iconsPath.get('arrowDown');
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';

    if (!this.state.open) {
      return (
        <Icon
          points={iconPoints}
          transform={transform}
          color="#4183d7"
        />
      );
    }

    return (
      <Icon
        points={iconPoints}
        color="#4183d7"
      />
    );
  }

  renderList() {
    if (!this.state.open) {
      return null;
    }

    return (
      <s.List>
        <ListConnect id={this.id} />
      </s.List>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Toggle
          isOpen={this.state.open}
          onClick={this.handleSetOpen}
        >
          {this.renderIcon()}
          <HeaderConnect id={this.id} />
        </s.Toggle>
        {this.renderList()}
      </s.Root>
    );
  }

}

export default Table;
