import {
  RESTPath,
  Id,
} from '@router/path.rest';

import http from '@http';

import { Filters } from '../../../workspace/toolbar/redux/state';

interface RequestParams {
  name: string;
  filters: Filters;
  id: Id;
}

export function httpUpdateTitle(requestParams: RequestParams) {
  const request = http.put({
    url: RESTPath.regulation.filters.work(requestParams),
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUpdateTitle;
