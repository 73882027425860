import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchSubscriptions } from './fetchSubscriptions';
import { selectSubscription } from './selectSubscription';
import { updateSubscriptionTitle } from './updateSubscriptionTitle';
import { removeSubscription } from '../../itemMenu/remove/redux/removeSubscription';
import { resetToInitial } from './resetToInitial';

import { toggleNotification } from '../../header/redux/toggleNotification';
import { notifyAllSubscriptions } from '../../header/redux/notifyAllSubscriptions';
import { unNotifyAllSubscriptions } from '../../header/redux/unNotifyAllSubscriptions';

export const reducerBuilder = new ReducerBuilder([
  fetchSubscriptions,
  selectSubscription,
  updateSubscriptionTitle,
  removeSubscription,
  toggleNotification,
  notifyAllSubscriptions,
  unNotifyAllSubscriptions,
  resetToInitial,
]);

export default reducerBuilder;
