import { connect } from 'react-redux';

import { addBlock } from '@pages/search/form/redux/addBlock';
import { duplicateBlock } from '@pages/search/form/redux/duplicateBlock';
import { removeBlock } from '@pages/search/form/redux/removeBlock';
import { resetForm } from '@pages/search/form/redux/resetForm';
import { updateFiltersThunk } from './redux/updateFilters.thunk';

import { Block } from './block';

const mapStateToProps = (state) => {
  const { query } = state.search;
  const items = query.blocksList.map((item) => (
    query.blocksMap.get(item)
  ));

  return {
    items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddBlock(type) {
    dispatch(addBlock.createAction({ type }));
  },
  onDuplicateBlock(data) {
    dispatch(duplicateBlock.createAction(data));
  },
  onRemoveBlock(data) {
    dispatch(removeBlock.createAction(data));
  },
  onResetForm() {
    dispatch(resetForm.createAction());
  },
  onUpdateFilters() {
    updateFiltersThunk(dispatch);
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
