import React from 'react';

import { Table } from './table';

import s from './disputeCategory.style';

interface Props {
  onFetchData: () => void;
  onFetchSettings: () => void;
}

export class DisputeCategory extends React.PureComponent<Props> {

  componentDidMount() {
    this.props.onFetchSettings();
    this.props.onFetchData();
  }

  render() {
    return (
      <s.Root>
        <Table />
      </s.Root>
    );
  }

}

export default DisputeCategory;
