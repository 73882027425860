import { connect } from 'react-redux';

import { separator } from '@components/separator';

import { ItemDetail } from './itemDetail';

import { Format } from '../redux/settings';
import { fetchSearchJudgesThunk } from '../redux/fetchJudges.thunk';

import { addJudgeBlockThunk } from '../redux/addJudgeBlock.thunk';

export interface DataFromView {
  columnId: string;
  judge: {
    itemId: number;
    title: string;
  };
}

const transformItem = (data, settings, options) => {
  const columns = [];

  const title = {
    id: 'title',
    itemId: data.id,
    title: data.title || '',
  };

  // @ts-ignore
  columns.push(title);

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      const isPercent = options.format === Format.percent;
      const value = separator(data[options.type][column][options.format]);
      let cellData = value;
      const tooltipData = separator(data[options.type][column][Format.number]);
      if (isPercent) {
        cellData = `${value}%`;
      }

      columns.push({
        // @ts-ignore
        id: column,
        // @ts-ignore
        title: cellData,
        // @ts-ignore
        tooltip: tooltipData,
        // @ts-ignore
        isPercent,
      });
    }
  });

  return columns;
};

const prepareItems = (items, settings, options) => {
  const data = [];

  if (!items.items.length) {
    return data;
  }

  items.items.forEach((itemId) => {
    const item = items.itemsMap.get(itemId);
    // @ts-ignore
    data.push(transformItem(item, settings, options));
  });

  return data;
};

const mapStateToProps = (state, ownProps) => {
  const {
    pages,
    currentPageNumber,
  } = state.searchResult.courts;

  const {
    type,
    format,
  } = state.searchResult.courts.layout;

  const { judges } = pages[currentPageNumber].itemsMap.get(ownProps.item.id);
  const settings = state.searchResult.courts.layout.columns[state.searchResult.courts.layout.type];
  const items = prepareItems(judges, settings, { type, format });

  return {
    shouldLoadJudges: judges.shouldLoadJudges,
    items,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    const courtId = ownProps.item.id;

    return fetchSearchJudgesThunk(dispatch, courtId);
  },
  onAddJudge(itemData: DataFromView) {
    const data = {
      columnId: itemData.columnId,
      court: {
        id: ownProps.item.id,
        title: ownProps.item.title,
      },
      judge: {
        id: itemData.judge.itemId,
        title: itemData.judge.title,
      },
    };

    addJudgeBlockThunk(dispatch, data);
  },
});

export const ItemDetailConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemDetail);

export default ItemDetailConnect;
