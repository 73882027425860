import { fetchSettingsReduce } from '@pages/packages/redux/documents/fetchSettings.reduce';

import type { ActionData } from '@pages/packages/redux/documents/fetchSettings.reduce';

const actionType = 'AUTHOR_PACKAGES/DOCUMENTS/FETCH_SETTINGS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSettings = {
  actionType,
  createAction,
  reduce: fetchSettingsReduce,
};

export default fetchSettings;
