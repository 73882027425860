import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateDocumentReduce } from './updateDocument.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/LAW/UPDATE_DOCUMENT`;

export interface Data {
  id: string;
  data: {
    id: string;
    title: string;
    hasArticles: boolean;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateDocument = {
  actionType,
  createAction,
  reduce: updateDocumentReduce,
};

export default updateDocument;
