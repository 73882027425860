import { connect } from 'react-redux';

import {
  Title,
  BranchOfLegislationBlock,
} from '@components/filters/branchOfLegislation/logic';

import { Block } from '@components/filters/components/block';

import { updateBranchThunk } from './redux/updateBranch.thunk';

import { FilterType } from './redux/state';

import {
  buildTitle,
  buildCounter,
} from './utils';

const mapStateToProps = (state) => {
  const { data } = state.regulation.filters.filters[FilterType.branchOfLegislation];
  const selectAllTitle = 'Все виды деятельности';

  return {
    title: buildTitle(data, Title.default),
    selectAllTitle,
    counter: buildCounter(data),
    isDefault: !data.length,
    data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdate(data: any) {
    updateBranchThunk(dispatch, data);
  },
  onFetchData(query, page): Promise<any> {
    const block = new BranchOfLegislationBlock();

    const result = block.getItemsForSuggest(query, page);

    return result;
  },
});

export const BranchOfLegislationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BranchOfLegislationConnect;
