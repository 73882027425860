import { AttributesBuilder } from '@pages/document/sidebar/redux/attributes';

export interface ActionData {
  comment: string;
  documentId: number;
}

interface Action {
  type: string;
  data: ActionData;
}

export const saveCommentaryReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const {
    currentPageNumber,
  } = state.documents.previewDocument;

  const item = state.documents.pages[currentPageNumber].itemsMap.get(action.data.documentId);

  // @ts-ignore
  const attributes = new AttributesBuilder();
  attributes.createFromModel(item.details.attributes);
  attributes.updateCommentary(action.data.comment);

  const attributesState = attributes.getState();

  const newItem = {
    ...item,
    comment: action.data.comment,
    attributesLoaded: true,
    details: {
      ...item.details,
      attributes: attributesState,
    },
  };

  const newItemsMap = state.documents.pages[currentPageNumber].itemsMap.set(action.data.documentId, newItem);

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap: newItemsMap,
        },
      },
    },
  };
};

export default saveCommentaryReduce;
