import React from 'react';

import s from './breadcrumbs.style';

type Id = number;

export interface Breadcrumb {
  id: Id;
  title: string;
  route: string;
  loading: boolean;
}

interface Props {
  breadcrumbs: Breadcrumb[];
}

const renderBreadcrumb = (item, index, array) => {
  const lastIndex = array.length - 1;
  const isLastItem = index === lastIndex;
  if (item.loading) {
    return <s.LoadingBreadcrumb key={item.id} />;
  }

  if (isLastItem) {
    return (
      <s.CurrentBreadcrumb
        key={item.id}
      >
        {item.title}
      </s.CurrentBreadcrumb>
    );
  }

  const renderBreadCrumb = (breadcrumb: Breadcrumb) => {
    if (!breadcrumb.route) {
      return <s.Breadcrumb>{breadcrumb.title}</s.Breadcrumb>;
    }

    return <s.AnchorBreadcrumb key={breadcrumb.id} href={item.route}>{breadcrumb.title}</s.AnchorBreadcrumb>;
  };

  return (
    <React.Fragment
      key={item.id}
    >
      {renderBreadCrumb(item)}
      <s.Separator>&nbsp;/&nbsp;</s.Separator>
    </React.Fragment>
  );
};

export const Breadcrumbs = (props: Props) => (
  <s.Root>
    {props.breadcrumbs.map(renderBreadcrumb)}
  </s.Root>
);

export default Breadcrumbs;
