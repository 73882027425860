import { StateBuilder } from './state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...StateBuilder.createState(),
    presets: state.presets,
  };
};

export default resetToInitialReduce;
