import { fetchHistory } from './fetchHistory';

import { changePageNumber } from './changePageNumber';

import { DateBlock } from '../components/filters/date/logic/date';

import {
  httpGetHistory,
  RequestParams,
} from './fetchHistory.http';

interface Response {
  history: any
}

export class FetchSearchHistoryThunk {

  getState;

  private response: Response | undefined;

  private requestParams: RequestParams;

  private dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(page) {
    const state = this.getState();
    const filters = { ...state.search.history.filters };
    const dateFilter = new DateBlock();
    dateFilter.create(filters);
    const { scope, value } = dateFilter.undecorate().data;
    const pageNumber = page || state.search.history.currentPageNumber;

    this.requestParams = {
      page: pageNumber,
      scope,
      range: value,
    };

    this.dispatchChangePageNumber(pageNumber);

    await this.httpGetHistory();
    this.dispatchFetchHistorySucceeded();
  }

  async httpGetHistory() {
    try {
      this.response = await httpGetHistory(this.requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
  }

  dispatchFetchHistorySucceeded() {
    this.dispatch(fetchHistory.createAction(this.response));
  }

}

export function fetchSearchHistoryThunk(dispatch, page?) {
  const thunk = new FetchSearchHistoryThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(page);
}
