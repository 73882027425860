export const changeTotalPagesReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const {
    totalPages,
  } = action.data;

  return {
    ...state,
    history: {
      ...state.history,
      totalPages,
    },
  };
};

export default changeTotalPagesReduce;
