import {
  ActionData,
  setNearestActiveDateReduce,
} from './setNearestActiveDate.reduce';

export const actionType = 'REGULATION/EVENTS/SET_NEAREST_ACTIVE_DATE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setNearestActiveDate = {
  actionType,
  createAction,
  reduce: setNearestActiveDateReduce,
};
