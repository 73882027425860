import { saveLastnameReduce } from './saveLastname.reduce';

import type { ActionData } from './saveLastname.reduce';

const actionType = 'USER/SAVE_LASTNAME';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const saveLastname = {
  actionType,
  createAction,
  reduce: saveLastnameReduce,
};

export default saveLastname;
