import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  disable?: boolean;
}

const style: any = {};

style.Root = styled.div`
  padding: 0  8px 0 16px;
  position: relative;
  height: 40px;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }
`;

style.Title = styled.div`
  font: 13px Roboto-Regular;

  ${(props: Props) => props.disable && css`
    color: ${props.theme.colors.silver};
  `}
`;

style.Arrow = styled.div`
  display: flex;
  height: 32px;
  width: 32px;

  cursor: pointer;
  color: ${(props) => props.theme.colors.disabled};

  align-items: center;
  justify-content: center;
`;

export default style;
