import styled, { css } from 'styled-components';

interface Props {
  shouldRender: boolean;
}

const style: any = {};

style.Root = styled.div`
  background: rgba(34, 49, 63, 0.75);
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  ${(props: Props) => props.shouldRender && css`
    opacity: 1;
  `}
`;

function createActions() {
  return `
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `;
}

style.Actions = styled.div`
  ${createActions()};
`;

style.ActionsSimple = styled.label`
  ${createActions()};
  cursor: pointer;
`;

function createAction() {
  return `
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 4px;
  `;
}

style.Action = styled.label`
  ${createAction()}
`;

style.ActionSimple = styled.div`
  ${createAction()}
`;

style.Input = styled.input`
  display: none;
`;

export default style;
