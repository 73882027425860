import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateFrom } from './redux/updateFrom';
import { updateTo } from './redux/updateTo';

import convertFromRealValue from './convertFromRealValue';
import convertToRealValue from './convertToRealValue';

import { ClaimAmountBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onSliderChange(data) {
    const valueFrom = convertFromRealValue(data.value[0]);
    if (valueFrom !== ownProps.item.getData().value[0]) {
      // @ts-ignore
      dispatch(updateFrom.createAction({ id: data.id, data: { value: valueFrom } }));
    }

    const valueTo = convertToRealValue(data.value[1]);
    if (valueTo !== ownProps.item.getData().value[1]) {
      // @ts-ignore
      dispatch(updateTo.createAction({ id: data.id, data: { value: valueTo } }));
    }
  },
  onUpdateFrom(data) {
    dispatch(updateFrom.createAction(data));
  },
  onUpdateTo(data) {
    dispatch(updateTo.createAction(data));
  },
});

export const ClaimAmountBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimAmountBlock);

export default ClaimAmountBlockConnect;
