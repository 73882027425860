import { Map } from 'immutable';
import { date } from '../../redux/state';

const prepareItem = (item) => ({
  id: item.id,
  title: item.name,
  appealState: item.appeal_state,
  caseResult: item.case_result,
  shouldLoadMonth: true,
  items: {
    itemsList: [],
    itemsMap: Map(),
  },
});

const prepareItems = (fetchItems, currentItems) => fetchItems.reduce((accumulator, currentValue) => {
  accumulator.itemsList = accumulator.itemsList.concat(currentValue.id);
  accumulator.itemsMap = accumulator.itemsMap.set(currentValue.id, prepareItem(currentValue));

  return accumulator;
}, currentItems);

export interface ActionData {
  searchId: string | number;
  yearId: string | number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchMonthReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const items = action.data.result;
  const { yearId } = action.data;
  const currentItems = state.analysis.itemsMap[date.getId()].items.itemsMap.get(yearId).items;
  const transformItems = prepareItems(items, currentItems);

  let itemsMap = state.analysis.itemsMap[date.getId()].items.itemsMap;

  const currentData = itemsMap.get(yearId);
  const data = {
    ...currentData,
    shouldLoadMonth: false,
    items: transformItems,
  };

  itemsMap = itemsMap.set(yearId, data);

  return {
    ...state,
    id: action.data.searchId,
    analysis: {
      ...state.analysis,
      itemsMap: {
        ...state.analysis.itemsMap,
        [date.getId()]: {
          ...state.analysis.itemsMap[date.getId()],
          items: {
            ...state.analysis.itemsMap[date.getId()].items,
            itemsMap,
          },
        },
      },
    },
  };
};

export default fetchMonthReduce;
