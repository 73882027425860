import { Map } from 'immutable';

type SearchId = number;
type PackageId = number;
type Title = string;

interface PackageInformation {
  status: string;
  hasChanges: boolean;
}

interface Item {
  id: PackageId;
  title: string;
  documentsCount: number;
  description: string;
  type: string;
  isNew: boolean;
  isOwner: boolean;
  information: PackageInformation
}

interface State {
  itemsList: PackageId[];
  itemsMap: Map<number, Item>;
  activeItemId: PackageId;
  searchId: SearchId;
  loading: boolean;
}

interface InformationDTO {
  status: 'onModeration' | 'published';
  has_changes: boolean;
}

interface ItemDTO {
  id: number;
  name: string;
  documents_count: number;
  is_new?: boolean;
  is_owner: boolean;
  description: string;
  type: string;
  information: InformationDTO;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      itemsList: [],
      itemsMap: Map(),
      activeItemId: null,
      searchId: null,
      loading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  public addPackage(item: ItemDTO, push = false) {
    const documentsCount = item.documents_count || 0;

    const packageItem = {
      id: item.id,
      title: item.name,
      documentsCount,
      isNew: item.is_new || false,
      type: item.type,
      description: item.description,
      isOwner: item.is_owner,
      information: {
        status: item.information.status,
        hasChanges: item.information.has_changes,
      },
    };

    let itemsList = null;
    if (push) {
      itemsList = [...this.state.itemsList, packageItem.id];
    } else {
      itemsList = [packageItem.id, ...this.state.itemsList];
    }

    const itemsMap = this.state.itemsMap.set(packageItem.id, packageItem);

    this.state = {
      ...this.state,
      itemsList,
      itemsMap,
    };
  }

  public changeActiveItemId(activeItemId: PackageId) {
    this.state = {
      ...this.state,
      activeItemId,
    };
  }

  public setSearchId(searchId: SearchId = null) {
    this.state = {
      ...this.state,
      searchId,
    };
  }

  public addPackages(packages) {
    packages.forEach((packageItem) => this.addPackage(packageItem, true));
  }

  public updatePackageName(params: { id: PackageId, title: Title }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      title: params.title,
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public updatePackageDescription(params: { id: PackageId, description: string }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      description: params.description,
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public increaseCount(params: { id: PackageId }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      documentsCount: packageItem.documentsCount + 1,
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public decreaseCount(params: { id: PackageId }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      documentsCount: packageItem.documentsCount - 1,
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public createAuthorPackage(params: { id: PackageId, text: string }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      type: 'author',
      description: params.text,
      information: {
        hasChanges: false,
        status: 'onModeration',
      },
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public unpublishAuthorPackage(params: { id: PackageId }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      type: 'private',
      information: {
        hasChanges: false,
        status: null,
      },
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public createCompanyPackage(params: { id: PackageId }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      type: 'company',
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public unpublishCompanyPackage(params: { id: PackageId }) {
    const packageItem = this.state.itemsMap.get(params.id);
    const newPackageItem = {
      ...packageItem,
      type: 'private',
    };

    this.state.itemsMap = this.state.itemsMap.set(params.id, newPackageItem);

    this.state = {
      ...this.state,
    };
  }

  public removePackage(packageId) {
    const packageIndex = this.state.itemsList.findIndex((item) => item === packageId);
    const itemsList = [...this.state.itemsList];
    itemsList.splice(packageIndex, 1);
    this.state.itemsList = itemsList;
    this.state.itemsMap = this.state.itemsMap.delete(packageId);
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
