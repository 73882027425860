import { StateBuilder as DocumentsStateBuilder } from './state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    documents: {
      ...DocumentsStateBuilder.createState(),
      layout: state.documents.layout,
      shouldLoadSettings: state.documents.shouldLoadSettings,
    },
  };
};

export default resetToInitialReduce;
