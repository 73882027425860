import isEmpty from 'lodash/isEmpty';
import React from 'react';
import type { Map } from 'immutable';

import { ItemConnect } from './item.connect';

import table from './item.style';

type Id = string;

type Item = string;

interface ItemValue {
  id: string;
  showColumn: boolean;
  title: string;
}

interface Props {
  items: Id[];
  columns: {
    itemsList: string[];
    itemsMap: Map<Item, ItemValue>
  }
}

interface State {
  currentPage: number;
  items: Id[];
}

export class List extends React.PureComponent<Props, State> {

  static createPages(items) {
    const perPage = 10;
    const pages = [];
    for (let i = 0; i < Math.ceil(items.length / perPage); i += 1) {
      // @ts-ignore
      pages[i] = items.slice((i * perPage), (i * perPage) + perPage);
    }

    return pages;
  }

  state = {
    currentPage: 1,
    items: [],
  };

  componentDidMount() {
    const pages = List.createPages(this.props.items);
    this.setStateItems(pages[0]);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {} as State;

    const nextPages = List.createPages(nextProps.items);

    if (prevState.currentPage === 1) {
      state.items = nextPages[0] || [];
    }

    if (prevState.currentPage !== 1) {
      state.items = [];

      for (let i = 0; i <= prevState.currentPage - 1; i += 1) {
        state.items = state.items.concat(nextPages[i]);
      }
    }

    return isEmpty(state) ? null : state;
  }

  setStateItems(items) {
    this.setState({
      items,
    });
  }

  setCurrentPage(page) {
    this.setState({
      currentPage: page,
    });
  }

  handleShowMoreClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.setCurrentPage(this.state.currentPage + 1);
  };

  renderShowMore() {
    const perPage = 10;
    const pages = Math.ceil(this.props.items.length / perPage);
    let showMore = true;

    if (!pages) {
      showMore = false;
    }

    if (this.state.currentPage === pages) {
      showMore = false;
    }

    if (!showMore) {
      return null;
    }

    return (
      <table.ShowMore
        onClick={this.handleShowMoreClick}
      >
        Показать еще
      </table.ShowMore>
    );
  }

  renderColumnInGroup() {
    return this.props.columns.itemsList.map((column) => {
      const isTitle = column === 'title';
      if (isTitle) {
        return (
          <table.ColumnTitle key={column} />
        );
      }

      return (
        <table.ColumnItem key={column} />
      );
    });
  }

  renderHeaderItems() {
    return this.props.columns.itemsList.map((column) => {
      // @ts-ignore
      const { title } = this.props.columns.itemsMap.get(column);

      const isTitle = column === 'title';
      if (isTitle) {
        return (
          <table.CellTitle
            key={column}
          >
            <table.ColumnWidthTitle>
              <table.CellContainer>
                <table.HeaderText>{title}</table.HeaderText>
              </table.CellContainer>
            </table.ColumnWidthTitle>
          </table.CellTitle>
        );
      }

      return (
        <table.CellItem
          key={column}
        >
          <table.ColumnWidthItem>
            <table.CellContainer>
              <table.HeaderText
                textAlign="end"
              >
                {title}
              </table.HeaderText>
            </table.CellContainer>
          </table.ColumnWidthItem>
        </table.CellItem>
      );
    });
  }

  renderItem(item) {
    return (
      <ItemConnect
        key={item}
        id={item}
      />
    );
  }

  renderRows() {
    return this.state.items.map(this.renderItem);
  }

  render() {
    return (
      <>
        <table.Table>
          <table.Group>
            {this.renderColumnInGroup()}
          </table.Group>
          <table.Row>
            {this.renderHeaderItems()}
          </table.Row>
        </table.Table>
        <table.Body>
          {this.renderRows()}
        </table.Body>
        {this.renderShowMore()}
      </>
    );
  }

}

export default List;
