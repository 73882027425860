import { connect } from 'react-redux';

import { AttributesTab } from '@pages/regulation/workspace/projects/preview/attributesTab';

import { getPreview } from './preview.connect';

const mapStateToProps = (state, ownProps) => {
  const preview = getPreview(ownProps.id, state.regulation.preview);

  return {
    ...preview.attributes,
  };
};

export const AttributesTabConnect = connect(
  mapStateToProps,
)(AttributesTab);

export default AttributesTabConnect;
