import { saveCommentaryReduce } from '@pages/searchResult/documents/preview/redux/saveCommentary.reduce';

import type { ActionData } from '@pages/searchResult/documents/preview/redux/saveCommentary.reduce';

const actionType = 'SUBSCRIPTION/DOCUMENTS/PREVIEW_SAVE_COMMENTARY';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const saveCommentary = {
  actionType,
  createAction,
  reduce: saveCommentaryReduce,
};

export default saveCommentary;
