import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpFetchDefaultFilters() {
  const request = {
    url: RESTPath.search.bootstrap.defaultFilters,
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(http.parseResponse);
}

export default httpFetchDefaultFilters;
