export type Id = number;
export type Title = string;
export type Counter = number;
export type Notify = boolean;

export class Item {

  private id: Id = null;

  private title: Title = '';

  private newDocumentsCount: Counter = 0;

  private hasNotification: Notify = false;

  public buildItem(item: { id: Id, title: Title, newDocumentsCount: Counter, hasNotification: Notify }): void {
    this.setId(item.id);
    this.setTitle(item.title);
    this.setCounter(item.newDocumentsCount);
    this.setNotify(item.hasNotification);
  }

  public setId(id: Id): void {
    this.id = id;
  }

  public getId(): Id {
    return this.id;
  }

  public setTitle(title: Title): void {
    this.title = title || '';
  }

  public getTitle(): Title {
    return this.title;
  }

  public setCounter(newDocumentsCount: Counter): void {
    this.newDocumentsCount = newDocumentsCount || 0;
  }

  public getCounter(): Counter {
    return this.newDocumentsCount;
  }

  public setNotify(hasNotification: Notify): void {
    this.hasNotification = hasNotification || false;
  }

  public getNotify(): Notify {
    return this.hasNotification;
  }

  public getItem(): Item {
    return this;
  }

}

export default Item;
