import { Map } from 'immutable';

import { lawSettings } from './settings';

import type { Layout } from './settings';

import {
  Document,
  DocumentId,
  Items,
} from '../../redux/state';

import {
  SortType,
  SortDirection,
} from '../../components/sortIcon/sortIcon';

export interface Documents {
  shouldLoadDocuments: boolean;
  items: Items;
  itemsMap: Record<DocumentId, Document> | Record<string, unknown>;
}

export interface SortDataProps {
  id: string;
  type: SortType;
}

export interface LawState {
  layout: Layout;
  initialLoading: boolean;
  shouldLoadSettings: boolean;
  sortData: SortDataProps;
  documents: Documents;
}

export const sortDataDefault = {
  id: 'all',
  type: SortDirection.DESC,
};

export const getDefaultDocuments = () => ({
  shouldLoadDocuments: true,
  items: [],
  itemsMap: Map(),
});

export function buildLawState(): LawState {
  const layout = lawSettings.initial();

  return {
    shouldLoadSettings: true,
    initialLoading: true,
    layout,
    sortData: sortDataDefault,
    documents: getDefaultDocuments(),
  };
}

export default buildLawState;
