import styled from 'styled-components';

const style: any = {};

style.Input = styled.textarea`
  width: 100%;
  border: 0;
  padding: 0;
  line-height: 16px;
  outline: none;
  resize: none;
  height: 50px;
`;

export default style;
