import { Dispatch } from 'redux';
import { Id } from '@router/path.rest';

import { appHistory } from '@router/history';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchLayoutSettingsThunk } from '@pages/regulation/workspace/redux/fetchLayoutSettings.thunk';
import { selectFolderThunk } from '@pages/regulation/sidebar/folders/redux/selectFolder.thunk';
import { selectFilterThunk } from './selectFilter.thunk';
import { selectFilter } from './selectFilter';

import { removeFilter } from './removeFilter';

import { httpRemoveFilter } from './removeFilter.http';

export class RemoveFilterThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: Id }) {
    try {
      await httpRemoveFilter(params);
    } catch (error) {
      throw Error(error.status);
    }

    this.marketingEvent(params);
    this.dispatchChangeFilterSelection(params.id);
    this.dispatchRemoveFilter(params);

  }

  private marketingEvent(params) {
    const filterName = this.getState().regulation.smartFolders.itemsMap.get(params.id).title;

    sendAmplitudeData('regulation:delete_filter', filterName);
    Userpilot.track('regulation:delete_filter');
  }

  private dispatchRemoveFilter(params) {
    this.dispatch(removeFilter.createAction({ id: params.id }));
  }

  private dispatchChangeFilterSelection(currentId) {
    const isActive = this.getState().regulation.smartFolders.activeItemId === currentId;
    if (!isActive) {
      return;
    }

    const { itemsList } = this.getState().regulation.smartFolders;
    const isLastItem = itemsList.length === 1;

    if (isLastItem) {
      this.dispatchResetToInitial();
    }

    const findIndex = itemsList.findIndex((id) => id === currentId);
    const hasPrevious = !!findIndex;
    if (hasPrevious) {
      const nextId = itemsList[findIndex - 1];
      selectFilterThunk(this.dispatch, { id: nextId });

      return;
    }

    const hasNext = !!itemsList[findIndex + 1];
    if (hasNext) {
      const nextId = itemsList[findIndex + 1];
      selectFilterThunk(this.dispatch, { id: nextId });
    }
  }

  private dispatchResetToInitial() {
    selectFolderThunk(this.dispatch, { id: null });
    this.dispatch(selectFilter.createAction({ id: null }));
    appHistory.push('/regulation');
    fetchLayoutSettingsThunk(this.dispatch);
  }

}

export function removeFilterThunk(dispatch, params) {
  const thunk = new RemoveFilterThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default RemoveFilterThunk;
