/* eslint-disable */
import React from 'react';

export function withClickOutside(Component) {
  return class extends React.Component {

    refRoot = React.createRef();

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
      if (this.refRoot && !this.refRoot.current.contains(event.target)) {
        this.props.onClickOutside();
      }
    };

    render() {
      return (
        <div ref={this.refRoot}>
          <Component {...this.props} />
        </div>
      );
    }

  };

}

export default withClickOutside;
