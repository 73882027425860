import { connect } from 'react-redux';

import { toggleLayoutType } from './redux/toggleLayoutType';

import { LayoutSwitcher } from './layoutSwitcher';

const mapStateToProps = (state) => {
  const {
    packages,
    documents,
    layout,
  } = state.packages;

  const noDocuments = !documents.totalDocuments && !packages.searchId;
  const disabled = !packages.activeItemId || noDocuments;

  return {
    layout: layout.type,
    disabled,
  };

};

const mapDispatchToProps = (dispatch) => ({
  onToggle() {
    dispatch(toggleLayoutType.createAction());
  },
});

export const LayoutSwitcherConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutSwitcher);

export default LayoutSwitcherConnect;
