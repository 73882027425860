import { resetActiveHistoryItem } from '../../history/redux/resetActiveHistoryItem';
import { actionType as SEARCH_HISTORY_DISPATCH_REMOVING } from '../../history/redux/dispatchRemovingStatus';

export const SEARCH_FORM_BLOCK = 'SEARCH/FORM/BLOCK';

class ResetActiveItem {

  private dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  private resetActiveHistoryItem(isReset: boolean) {
    if (isReset) {
      this.dispatch(resetActiveHistoryItem.createAction());
    }
  }

  public invoke(next, getState) {
    return (action) => {
      const isDispatchRemoving = action.type === SEARCH_HISTORY_DISPATCH_REMOVING;
      const isIncluded = action.type.includes(SEARCH_FORM_BLOCK) ||
        isDispatchRemoving;

      if (isIncluded) {
        const { activeHistoryId } = getState().search.history;
        let isReset = activeHistoryId !== null;

        if (isDispatchRemoving) {
          const { id } = action.data;
          isReset = activeHistoryId === id;
        }

        this.resetActiveHistoryItem(isReset);
      }

      const returnValue = next(action);

      return returnValue;
    };
  }

}

export function resetActiveItemMiddleware({ dispatch, getState }) {
  const middleware = new ResetActiveItem(dispatch);

  return (next) => middleware.invoke(next, getState);
}
