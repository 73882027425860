import styled, { css } from 'styled-components';

import { width } from './list.style';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  marginBottom: number;
  isActive: boolean;
  bold: boolean;
}

const style: any = {};

style.Root = styled.div`
  display: table-row;
  cursor: pointer;
  user-select: none;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  ${(props: Props) => props.isActive && css`
    background-color: ${props.theme.colors.polar};
  `}

  &:hover {
    background-color: ${(props: Props) => props.theme.colors.polar};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

style.Column = styled.div`
  width: ${width.document};
`;

style.IsNewItem = styled.div`
  width: ${width.isNew};
  display: table-cell;
  vertical-align: top;
`;

style.Dot = styled.div`
  margin-top: 36px;
  margin-left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props: Props) => props.theme.colors.blue};
`;

style.ColumnCheckbox = styled.div`
  width: ${width.checkbox};
`;

style.Cell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const cellStyle = () => css`
  display: table-cell;
  vertical-align: top;
`;

style.CellCheckbox = styled(style.ColumnCheckbox)`
  ${cellStyle()}
  padding-top: 8px;
  vertical-align: baseline;
`;

style.CellDocument = styled(style.Column)`
  ${cellStyle()}
`;

style.BodyCell = styled(style.Cell)`
  padding: 13px 0;
`;

style.Actions = styled.div`
  width: 64px;
  display: table-cell;
  vertical-align: top;
`;

style.BodyActions = styled.div`
  padding-top: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.CellLogo = styled(style.Cell)`
  align-items: center;
  padding: 13px 0;
`;

style.Logo = styled.div`
  width: 53px;
  height: 53px;

  > svg {
    opacity: .2;
  }

  > img {
    object-fit: contain;
    width: 53px;
  }
`;

style.BodyText = styled.div`
  font-size: 13px;
  line-height: 16px;

  ${(props: Props) => props.bold && css`
    font-weight: bold;
  `}

  ${(props: Props) => props.marginBottom && css`
    margin-bottom: ${props.marginBottom}px;
  `}
`;

style.TitleText = styled(style.BodyText)`
  color: ${(props: Props) => props.theme.colors.defaultText};
`;

style.TitleEvent = styled(style.BodyText)`
  margin-top: 10px;
  font: 13px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.defaultText};
  background: rgba(34, 49, 63, 0.05);
  border-radius: 14px;
  padding: 6px 12px;

  a {
    color: #4183d7;
  }
`;

style.TitleLabel = styled(style.BodyText)`
  color: ${(props: Props) => props.theme.colors.stone};
`;

export default style;
