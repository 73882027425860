import styled from 'styled-components';

const style: any = {};

style.Item = styled.div`
  width: 100%;
  padding-bottom: 1px;

  &:last-child {
    padding: 0;
  }
`;

style.ItemTitle = styled.div`
  font: 11px Roboto-Medium;
  color: #4183d7;
`;

export default style;
