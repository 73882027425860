import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './emptyLayout.style';

export class EmptyLayout extends React.PureComponent {

  private renderIcon() {
    const iconPath = iconsPath.get('shadowFolder');

    return (
      <s.Icon>
        <Icon viewBoxSize={72} size={72} path={iconPath} color="#6c7b89" />
      </s.Icon>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderIcon()}
        <s.Title>
          <s.Paragraph>
            Комментариев еще нет
          </s.Paragraph>
        </s.Title>
      </s.Root>
    );
  }

}

export default EmptyLayout;
