interface Abstract {
  decorate: (history: Record<string, any>) => void;
  undecorate: (history: Record<string, any>) => void;
}

export abstract class Settings implements Abstract {

  abstract decorate(history): void;

  abstract undecorate(history): void;

}

export default Settings;
