import { getDefaultDocuments } from './state';

export const resetDocumentsReduce = (state) => {
  const documents = getDefaultDocuments();

  return {
    ...state,
    law: {
      ...state.law,
      documents,
      initialLoading: true,
    },
  };
};

export default resetDocumentsReduce;
