import React from 'react';

import {
  TextInput,
  TextInputProps,
} from '@components/textInput/index';

import { SearchIcon } from './icon/search-icon';

import s from './searchField.style';

interface SearchProps extends TextInputProps {
  placeholder: string;
  disabled?: boolean;
}

export const SearchField = (props: SearchProps) => (
  <s.SearchWrapper>
    <s.IconWrapper
      disabled={props.disabled}
    >
      <SearchIcon />
    </s.IconWrapper>
    <TextInput
      {...props}
    />
  </s.SearchWrapper>
);

SearchField.defaultProps = {
  disabled: false,
};

export default SearchField;
