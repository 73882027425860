import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updatePreviousCourt } from './redux/updatePreviousCourt';

import { PreviousCourtBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdatePreviousCourt(data) {
    dispatch(updatePreviousCourt.createAction(data));
  },
});

export const PreviousCourtBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviousCourtBlock);

export default PreviousCourtBlockConnect;
