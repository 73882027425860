import { connect } from 'react-redux';

import { selectFolderThunk } from './folders/redux/selectFolder.thunk';
import { Items } from './folders/items';

function buildItems(params) {
  const query = params.query.trim().toLowerCase();

  return params.folders.folders.itemsList.reduce((acc, id) => {
    const currentItem = params.folders.folders.itemsMap.get(id);
    const currentItemTitle = currentItem.title ? currentItem.title.toLowerCase() : '';
    const contains = currentItemTitle.includes(query);
    if (!contains) {
      return acc;
    }

    acc.push({
      ...params.folders.folders.itemsMap.get(id),
      active: id === params.activeItemId,
    });

    return acc;
  }, []);
}

const mapStateToProps = (state, ownProps: { query: string }) => {
  const { folders } = state.regulation;

  const items = buildItems({ folders, activeItemId: folders.activeItemId, query: ownProps.query });

  return {
    title: 'Мои папки',
    items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelectItem(id) {
    selectFolderThunk(dispatch, { id });
  },
});

export const FoldersConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Items);

export default FoldersConnect;
