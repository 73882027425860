import { Filters } from '../filters';

export const removeBlockReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);
  filters.removeFilterBlock(action.data);
  const query = {
    blocksList: filters.blocksList,
    blocksMap: filters.blocksMap,
  };

  const hasChange = !!state.query.smartFilter.query;

  return {
    ...state,
    query: {
      ...state.query,
      ...query,
      smartFilter: {
        ...state.query.smartFilter,
        hasChange,
      },
    },
  };
};

export default removeBlockReduce;
