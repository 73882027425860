import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { LogoControlConnect } from './logoControl.connect';

import { AvatarControlConnect } from './avatarControl.connect';

import s from './headerCassette.style';

export interface HeaderCassetteProps {
  name: string;
  disable?: boolean;
  position: string;
  avatar: string;
  logo: string;
}

export interface HeaderCassetteState {
  isAvatarHeightDominant: boolean;
  isLogoHeightDominant: boolean;
  isAvatarHovered: boolean;
  isLogoHovered: boolean;
}

export class HeaderCassette extends React.PureComponent<HeaderCassetteProps, HeaderCassetteState> {

  state = {
    isAvatarHeightDominant: true,
    isLogoHeightDominant: true,
    isAvatarHovered: false,
    isLogoHovered: false,
  };

  onAvatarLoad = ({ target: img }) => {
    const height = img.offsetHeight;
    const width = img.offsetWidth;

    if (height >= width) {
      this.setState({ isAvatarHeightDominant: true });
    } else {
      this.setState({ isAvatarHeightDominant: false });
    }
  };

  handleAvatarMouseOver = () => {
    this.setState({
      isAvatarHovered: true,
    });
  };

  handleAvatarMouseLeave = () => {
    this.setState({
      isAvatarHovered: false,
    });
  };

  onLogoLoad = ({ target: img }) => {
    const height = img.offsetHeight;
    const width = img.offsetWidth;

    if (height >= width) {
      this.setState({ isLogoHeightDominant: true });
    } else {
      this.setState({ isLogoHeightDominant: false });
    }
  };

  handleLogoMouseOver = () => {
    this.setState({
      isLogoHovered: true,
    });
  };

  handleLogoMouseLeave = () => {
    this.setState({
      isLogoHovered: false,
    });
  };

  renderAvatar() {
    if (!this.props.avatar) {
      const avatarPath = iconsPath.get('avatar');

      return <s.AvatarIcon><Icon path={avatarPath} size={96} viewBoxSize={96} color="#dfe2e6" /></s.AvatarIcon>;
    }

    return <img onLoad={this.onAvatarLoad} src={this.props.avatar} alt="avatar" />;
  }

  renderAvatarControl() {
    if (this.props.disable) {
      return null;
    }

    return <AvatarControlConnect shouldRender={this.state.isAvatarHovered} />;
  }

  renderLogo() {
    if (!this.props.logo) {
      return <s.EmptyLogo>Лого компании</s.EmptyLogo>;
    }

    return <img onLoad={this.onLogoLoad} src={this.props.logo} alt="logo" />;
  }

  renderLogoControl() {
    if (this.props.disable) {
      return null;
    }

    return <LogoControlConnect shouldRender={this.state.isLogoHovered} />;
  }

  render() {
    return (
      <s.Root>
        <s.Photo
          isAvatarHeightDominant={this.state.isAvatarHeightDominant}
          onMouseOver={this.handleAvatarMouseOver}
          onMouseLeave={this.handleAvatarMouseLeave}
        >
          {this.renderAvatarControl()}
          {this.renderAvatar()}
        </s.Photo>
        <s.Credentials>
          <s.Author>{this.props.name}</s.Author>
          <s.Position>{this.props.position}</s.Position>
        </s.Credentials>
        <s.Logo
          isLogoHeightDominant={this.state.isLogoHeightDominant}
          onMouseOver={this.handleLogoMouseOver}
          onMouseLeave={this.handleLogoMouseLeave}
        >
          {this.renderLogoControl()}
          {this.renderLogo()}
        </s.Logo>
      </s.Root>
    );
  }

}

export default HeaderCassette;
