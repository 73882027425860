import { store } from '@redux/store';

import { Source } from './state';

export interface ActionData {
  format: string;
  type: string;
  name :string;
  percent: number;
  source: Source.search | Source.package | Source.subscription | Source.chronology;
  id: number;
  url: string;
}

interface Action {
  type: string;
  data: ActionData;
}

function prepareItem(data: ActionData) {
  return {
    [data.format]: {
      id: data.id,
      name: null,
      url: null,
    },
  };
}

export const processStartReduce = (state: ReturnType<typeof store.getState>, action: Action) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    [action.data.source]: {
      ...state[action.data.source],
      [action.data.id]: {
        ...state[action.data.source][action.data.id],
        ...prepareItem(action.data),
      },
    },
  };
};

export default processStartReduce;
