import { connect } from 'react-redux';

import { SharedLinkCopyPopover } from '@pages/document/components/sharedLinkCopy/sharedLinkCopyPopover';

import { Source } from '@pages/document/redux/state';

const mapStateToProps = (state) => ({
  source: Source.authorPackage,
  sourceId: state.authorPackages.packages.activeItemId,
  disabled: false,
});

export const SharedLinkCopyConnect = connect(
  mapStateToProps,
)(SharedLinkCopyPopover);

export default SharedLinkCopyConnect;
