import { Map } from 'immutable';

import {
  Id,
  Item,
} from './item';

interface State {
  itemsList: Id[];
  itemsMap: Map<number, Item>;
  activeItemId: number;
  loading: boolean;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      itemsList: [],
      itemsMap: Map(),
      activeItemId: null,
      loading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  public addItem(params: { id: number; title: string; new_count: number; is_notify: boolean }) {

    const item = new Item();
    item.buildItem({
      id: params.id,
      title: params.title,
      hasNotification: params.is_notify,
      newDocumentsCount: params.new_count,
    });

    const itemsList = [...this.state.itemsList, item.getId()];
    const itemsMap = this.state.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
      itemsList,
      itemsMap,
    };
  }

  public addItems(items) {
    items.forEach((item) => this.addItem(item));
  }

  public changeActiveItemId(id: Id) {
    this.state = {
      ...this.state,
      activeItemId: id,
    };
  }

  public updateItemTitle(params: { id: number, title: string }) {
    const currentItem = this.state.itemsMap.get(params.id);
    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      newDocumentsCount: currentItem.getCounter(),
      hasNotification: currentItem.getNotify(),
    });

    item.setTitle(params.title);

    this.state.itemsMap = this.state.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
    };
  }

  private createItem(id) {
    const currentItem = this.state.itemsMap.get(id);

    const item = new Item();
    item.buildItem({
      id: currentItem.getId(),
      title: currentItem.getTitle(),
      newDocumentsCount: currentItem.getCounter(),
      hasNotification: currentItem.getNotify(),
    });

    return item;
  }

  public toggleNotification(params: { id: number }) {
    const item = this.createItem(params.id);
    item.setNotify(!item.getNotify());

    this.state.itemsMap = this.state.itemsMap.set(item.getId(), item.getItem());

    this.state = {
      ...this.state,
    };
  }

  public toggleNotifications(switchOn: boolean) {
    let itemsMap: Map<number, Item> = Map();
    this.state.itemsList.forEach((id) => {
      const item = this.createItem(id);
      item.setNotify(switchOn);

      // this.state.itemsMap = this.state.itemsMap.delete(item.getId());
      itemsMap = itemsMap.set(item.getId(), item.getItem());
    });

    this.state = {
      ...this.state,
      itemsMap,
    };
  }

  public removeItem(id: Id): void {
    const index = this.state.itemsList.findIndex((item) => item === id);
    const itemsList = [...this.state.itemsList];
    itemsList.splice(index, 1);
    this.state.itemsList = itemsList;
    this.state.itemsMap = this.state.itemsMap.delete(id);
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
