import { StateBuilder } from './state';

import type { FetchedDocuments } from './state';

export type ActionData = FetchedDocuments;

interface Action {
  type: string;
  data: ActionData;
}

export const fetchSimilarDocumentsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state);
  newState.setDocumentId(action.data.documentId);
  newState.setPageInfo(action.data);
  newState.createPage(action.data);

  return {
    ...state,
    ...newState.getState(),
  };
};

export default fetchSimilarDocumentsReduce;
