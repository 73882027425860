import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Id } from '@pages/regulation/workspace/redux/state';

import type { MessageDTO } from '@pages/regulation/redux/chat/state';

export interface ChatDTO {
  items: MessageDTO[];
}

export function httpFetchMessages(projectId: Id): Promise<ChatDTO> {
  const url = RESTPath.regulation.chat.messages(projectId);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchMessages;
