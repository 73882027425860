import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { InputComponent } from '../inputComponent/inputComponent';

import { Download } from './download';

import s from '../inputComponent/inputComponent.style';

interface Item {
  title: string;
  onClick(): void;
}

interface RequisitesCopyPopoverProps {
  items: Item[];
  usePortal?: boolean;
  disabled: boolean;
}

export const DownloadPopover = (props: RequisitesCopyPopoverProps) => (
  <s.IconBlock>
    <BaseDropdown
      usePortal={props.usePortal}
      disabled={props.disabled}
      placement="bottom-end"
      renderInput={(p) => <InputComponent {...p} iconName="download" />}
      renderMenu={(p) => <Download {...p} {...props} />}
    />
  </s.IconBlock>
);

DownloadPopover.defaultProps = {
  usePortal: true,
};

export default DownloadPopover;
