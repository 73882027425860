import { updateProximityRangeReduce } from './updateProximityRange.reduce';
import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/TEXT/UPDATE_PROXIMITY_RANGE`;

export interface Data {
  data: {
    proximityRange: number;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateProximityRange = {
  actionType,
  createAction,
  reduce: updateProximityRangeReduce,
};

export default updateProximityRange;
