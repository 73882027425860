import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchChronologyDocuments } from './fetchChronologyDocuments';
import { changePageNumber } from './changePageNumber';

import { resetChronologyDocuments } from './resetChronologyDocuments';

import { httpFetchChronologyDocuments } from './fetchChronologyDocuments.http';

import { loadingEnd } from './loading';
import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export class FetchChronologyDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(documentId?: number, pageNumber?: number) {
    const state = this.getState();
    const chronologyDocumentId = state.chronology.documentId;
    const fetchDocumentId = documentId || chronologyDocumentId;
    let fetchPageNumber = pageNumber;

    const sameDocument = chronologyDocumentId === fetchDocumentId;

    if (!sameDocument) {
      this.dispatchResetChronologyState();
    }

    if (!fetchPageNumber) {
      sendAmplitudeData('chronology: open tab');
      Userpilot.track('chronology: open tab');
      fetchPageNumber = this.getState().chronology.currentPageNumber;
    }

    sendAmplitudeData('chronology: change page', { page: fetchPageNumber });
    Userpilot.track('chronology: change page', { page: fetchPageNumber });

    const hasPage = this.getState().chronology.pages[fetchPageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber({ page: fetchPageNumber });

      return;
    }

    this.fetchDocuments(fetchDocumentId, fetchPageNumber);
  }

  private async fetchDocuments(documentId, pageNumber) {
    this.dispatch(pageLoadingStart.createAction());

    try {
      const result = await httpFetchChronologyDocuments({ documentId, pageNumber });

      this.dispatchGetDocumentsSucceed(documentId, result);
      this.dispatch(loadingEnd.createAction());
      this.dispatch(pageLoadingEnd.createAction());
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchResetChronologyState() {
    this.dispatch(resetChronologyDocuments.createAction());
  }

  private dispatchGetDocumentsSucceed(documentId, params) {
    const actionData = {
      documentId,
      ...params,
    };

    this.dispatch(fetchChronologyDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchChronologyDocumentsThunk(dispatch, documentId?, pageNumber?) {
  const thunk = new FetchChronologyDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(documentId, pageNumber);
}

export default fetchChronologyDocumentsThunk;
