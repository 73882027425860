import {
  StateBuilder,
  ProjectDTO,
  RouteType,
} from './state';

interface Action {
  type: string;
  data: {
    page: number;
    total_projects: number;
    new_projects: number;
    without_change: number;
    projects: ProjectDTO[];
  };
}

export const fetchFolderReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.setRouteType(RouteType.folder);
  newState.addProjects(action.data.projects);
  newState.setTotalProjects(action.data.total_projects);
  newState.setNewProjects(action.data.new_projects);
  newState.setWithoutChangeCounter(action.data.without_change);
  newState.setCurrentPageNumber(action.data.page);
  newState.setLoading(false);

  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default fetchFolderReduce;
