import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Layout } from './state';

export function httpSaveLayoutSettings(requestParams: Layout) {
  const url = RESTPath.regulation.settings;
  const request = http.put({
    url,
    data: { layout: requestParams },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSaveLayoutSettings;
