import { ActionType } from '@redux/reducer.builder';
import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';

import { updateCourtTypeReduce } from './updateCourtType.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/COURT_TYPE/UPDATE` as ActionType;

export interface Data {
  id: string;
  data: {
    id: number;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateCourtType = {
  actionType,
  createAction,
  reduce: updateCourtTypeReduce,
};

export default updateCourtType;
