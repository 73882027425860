import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';
import { CourtsConnect } from './courts.connect';

import { withFetchSearch } from '../withFetchSearch';

export const RouteSearchCourts = (
  <AuthRouteConnect
    path={RoutePath.courts}
    component={withFetchSearch(CourtsConnect)}
  />
);

export default RouteSearchCourts;
