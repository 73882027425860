import { styled } from '@components/styled';

import { css } from 'styled-components';

interface Props {
  disabled: boolean;
}

const style: any = {};

style.Input = styled.input<Props>`
  width: 100%;
  padding: 0 16px;
  border: none;

  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-overflow: ellipsis;
  background: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    text-overflow: ellipsis !important;
    color: ${(props) => props.theme.colors.stone};

    ${(props) => props.disabled && css`
      color: #a6b0ba;
    `}
  }
`;

export default style;
