import { connect } from 'react-redux';
import moment from 'moment';

import { Block } from '@components/filters/date/block';

import {
  ScopeId,
  ScopeIdDTO,
} from '@components/filters/date/logic/date';

import { updateDateThunk } from './redux/updateDate.thunk';

function formatDate(date) {
  return moment(date).format('DD.MM.yyyy');
}

function makeTitle(filter) {
  const { title } = filter.scope;

  const isRange = filter.scope.id === ScopeId.range;
  if (!isRange) {
    return title;
  }

  const noValues = !filter.value[0] && !filter.value[1];

  if (noValues) {
    return title;
  }

  if (filter.value[0] && filter.value[1]) {
    return `${title} с ${formatDate(filter.value[0])} по ${formatDate(filter.value[1])}`;
  }

  if (!filter.value[1]) {
    return `${title} с ${formatDate(filter.value[0])}`;
  }

  return `${title} по ${formatDate(filter.value[1])}`;
}

const mapStateToProps = (state) => {
  const { filters } = state.regulation.filters;
  const title = makeTitle(filters.date.data);

  return {
    title,
    isDefault: filters.date.data.scope.id === ScopeId.allTime,
    data: filters.date.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateValue(value) {
    if (value[0] || value[1]) {
      updateDateThunk(dispatch, { value });
    } else {

      updateDateThunk(dispatch, { scope: ScopeIdDTO.allTime });
    }
  },
  onUpdateScope(scopeId) {
    const scopeModify = {
      [ScopeId.allTime]: ScopeIdDTO.allTime,
      [ScopeId.year]: ScopeIdDTO.year,
      [ScopeId.twoYears]: ScopeIdDTO.twoYears,
      [ScopeId.fiveYears]: ScopeIdDTO.fiveYears,
      [ScopeId.range]: ScopeIdDTO.range,
    };

    const transferScope = scopeModify[scopeId];
    updateDateThunk(dispatch, { scope: transferScope });
  },
});

export const DateConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default DateConnect;
