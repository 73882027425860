import styled, { css } from 'styled-components';

import Checkbox from '@components/checkbox/checkbox.style';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  isActive: boolean;
  bold: boolean;
  visible: boolean;
  highlight: boolean;
  isVisited: boolean;
}

function textEllipsis() {
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

const style: any = {};

export const width = {
  checkbox: '48px',
  document: '208px',
  text: '100%',
  more: '40px',
};

style.Root = styled.div`
  font-size: 13px;
  background-color: ${(props: Props) => props.theme.colors.white};
`;

style.Table = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
`;

style.Group = styled.div`
  display: table-column-group;
`;

style.ColumnWidthCheckbox = styled.div`
  height: 100%;
  width: ${width.checkbox};
`;

style.ColumnWidthDocument = styled.div`
  width: ${width.document};
`;

style.ColumnWidthText = styled.div`
  width: ${width.text};
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

style.BodyCellContainer = styled(style.CellContainer)`
  padding: 13px 0;
`;

style.ColumnCheckbox = styled(style.ColumnWidthCheckbox)`
  display: table-column;
`;

style.ColumnDocument = styled(style.ColumnWidthDocument)`
  display: table-column;
`;

style.ColumnText = styled(style.ColumnWidthText)`
  display: table-column;
`;

export const cellStyle = () => css`
  display: table-cell;
  vertical-align: top;
`;

style.CellCheckbox = styled(style.ColumnWidthCheckbox)`
  ${cellStyle()}
  vertical-align: baseline;
`;

style.CellDocument = styled(style.ColumnWidthDocument)`
  ${cellStyle()}
`;

style.CellText = styled(style.ColumnWidthText)`
  ${cellStyle()}
`;

style.TopHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 16px 16px 16px;
  font-size: 17px;
  font-weight: 500;
  height: 57px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Header = styled.div`
  display: table-header-group;
  line-height: 38px;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  ${style.CellCheckbox} {
    ${Checkbox.Root} {
      padding-top: 8px;
    }
  }
`;

style.HeaderText = styled.div`
  font-size: 11px;
  padding: 1px 0 0;
  font-family: Roboto-Bold;
`;

style.BodyText = styled.div`
  font-size: 11px;
  line-height: 16px;
  ${textEllipsis};
`;

style.SimpleTitleText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.defaultText : props.theme.colors.disabledText)};
`;

style.TitleText = styled(style.BodyText)`
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.defaultText : props.theme.colors.disabledText)};

  ${(props:Props) => props.bold && css`
    font-weight: bold;
  `}
`;

style.TitleAdditionalText = styled(style.BodyText)`
  font-size: 11px;
  font-family:  Roboto-Regular;
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.stone : props.theme.colors.disabledText)};
`;

style.TitleLabel = styled(style.BodyText)`
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.stone : props.theme.colors.disabledText)};
`;

style.TitleLink = styled.a`
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  ${textEllipsis};
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.blue : props.theme.colors.disabledText)};
`;

style.Body = styled.div`
  display: table-row-group;
`;

style.MoreVisibility = styled.div`
  height: 100%;
  opacity: 0;

  ${(props: Props) => props.visible && css`
    opacity: 1;
  `}
`;

style.Item = styled.div`
  display: table-row;
  cursor: pointer;
  user-select: none;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  ${(props: Props) => props.isActive && css`
    background-color: ${props.theme.colors.polar};
  `}

  &:hover {
    background-color: ${(props: Props) => props.theme.colors.polar};
  }

  &:last-of-type {
    border-bottom: none;
  }

  ${style.CellCheckbox} {
    ${Checkbox.Root} {
      margin-top: -5px;
    }
  }
`;

style.Comment = styled.div`
  display: table-row;
  background: ${(props: Props) => props.theme.colors.backgroundGrey};
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.CommentCell = styled.td`
  display: table-cell;
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

export default style;
