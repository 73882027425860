import React from 'react';

import { RoutePath } from '@router/path';

import { UnauthRouteConnect } from '@router/unauthRoute.connect';

import { PasswordRecoveryConnect } from './passwordRecovery.connect';

export const RoutePasswordRecovery = (
  <UnauthRouteConnect
    exact
    path={RoutePath.passwordRecovery}
    component={PasswordRecoveryConnect}
  />
);

export default RoutePasswordRecovery;
