import { ReducerBuilder } from '@redux/reducer.builder';

import { updateScope } from './updateScope';
import { updateFirstDate } from './updateFirstDate';
import { updateSecondDate } from './updateSecondDate';

export const reducerBuilder = new ReducerBuilder([
  updateScope,
  updateFirstDate,
  updateSecondDate,
]);

export default reducerBuilder;
