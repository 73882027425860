import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const style: any = {};

interface Props {
  theme: any;
}

style.Root = styled.nav`
  padding: 0 8px 1px 8px;
  display: flex;
  flex-direction: row;
  font: 13px Roboto-Medium,sans-serif;
  text-transform: uppercase;
  font-weight: 500;
`;

style.Item = styled(NavLink)`
  margin: 0 8px;
  font: 13px Roboto-Medium;
  font-weight: 500;
  letter-spacing: 0.2px;
  height: 48px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props: Props) => props.theme.colors.stone};
  position: relative;

  transition: color 0.2s ease;

  &:hover {
    color: ${(props: Props) => props.theme.colors.defaultText};
  }

  &.active {
    color: ${(props: Props) => props.theme.colors.defaultText};
    ::after {
      content: '';
      background: ${(props: Props) => props.theme.colors.orange};
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
    }
  }
`;

style.ItemText = styled.span`
  padding-left: 0;
`;

export default style;
