import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { AddPackageButton } from './addPackageButton';
import { AddPackagePopover } from './addPackagePopover';

import s from './addPackage.style';

interface AddPackageProps {
  count: number;
  packages: [];
  usePortal?: boolean;
  disabled?: boolean;
  onInit(): void;
  onResetToInitial(): void;
  onPackageToggle(id: number): void;
  onPackageCreate(query: string): void;
}

export const AddPackage = (props: AddPackageProps) => (
  <s.Root>
    <BaseDropdown
      // @ts-ignore
      usePortal={props.usePortal}
      disabled={props.disabled}
      placement="bottom-end"
      renderInput={(p) => <AddPackageButton disabled={props.disabled} count={props.count} {...p} />}
      renderMenu={(p) => (
        <AddPackagePopover
          packages={props.packages}
          onInit={props.onInit}
          onResetToInitial={props.onResetToInitial}
          onPackageCreate={props.onPackageCreate}
          onPackageToggle={props.onPackageToggle}
          {...p}
        />
      )}
    />
  </s.Root>
);

AddPackage.defaultProps = {
  disabled: false,
  usePortal: true,
};

export default AddPackage;
