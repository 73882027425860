import React from 'react';

import { SettingsPopover } from './settingsPopover/settingsPopover';
import { EditViewPopover } from './editViewPopover/editViewPopover';

import s from './header.style';

export interface HeaderProps {
  title: string;
  id: string;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.Header>
        <s.Title>
          {this.props.title}
        </s.Title>
        <s.Actions>
          <s.OptionsKebab>
            <EditViewPopover
              id={this.props.id}
            />
          </s.OptionsKebab>
          <s.OptionsKebab>
            <SettingsPopover
              id={this.props.id}
            />
          </s.OptionsKebab>
        </s.Actions>
      </s.Header>
    );
  }

}

export default Header;
