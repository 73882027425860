import { connect } from 'react-redux';
import { SearchHistory } from '@pages/search/history/searchHistory';
import { selectHistoryItem } from '@pages/search/history/redux/selectHistoryItem';

import { History } from './history';

const mapStateToProps = (state) => {
  const historyFromState = [...state.search.history.data];
  const presetsFromState = { ...state.search.presets };

  const searchHistory = new SearchHistory();
  searchHistory.transform(historyFromState, presetsFromState);
  const data = searchHistory.getHistory();

  const history = {
    ...state.search.history,
    data,
  };

  return {
    history,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelectHistoryItem(data) {
    dispatch(selectHistoryItem.createAction(data));
  },
});

export const HistoryConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(History);

export default HistoryConnect;
