import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchPackagesSearchResult } from './fetchPackagesSearchResult';
import { searchPackagesResultResetToInitial } from './searchPackagesResultResetToInitial';
import { createPackageStart } from './createPackageStart';
import { createPackageSucceed } from './createPackageSucceed';
import { addDocumentToPackage } from './addDocumentToPackage';
import { removeDocumentFromPackage } from './removeDocumentFromPackage';

export const reducerBuilder = new ReducerBuilder([
  fetchPackagesSearchResult,
  searchPackagesResultResetToInitial,
  createPackageStart,
  createPackageSucceed,
  addDocumentToPackage,
  removeDocumentFromPackage,
]);

export default reducerBuilder;
