import { ActionData as DefaultActionData } from '@pages/document/redux/fetchMarkers.reduce';

interface DocumentId {
  documentId: number
}

export type ActionData = DefaultActionData & DocumentId;

interface Action {
  type: string;
  data: ActionData;
}

export const fetchMarkersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const documentId = action.data.documentId;
  const { currentPageNumber } = state.documents;
  const item = state.documents.pages[currentPageNumber].itemsMap.get(documentId);

  const newItem = {
    ...item,
    details: {
      ...item.details,
      entities: action.data.entities,
    },
  };

  const newItemsMap = state.documents.pages[currentPageNumber].itemsMap.set(action.data.documentId, newItem);

  return {
    ...state,
    documents: {
      ...state.documents,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: {
          ...state.documents.pages[currentPageNumber],
          itemsMap: newItemsMap,
        },
      },
    },
  };
};

export default fetchMarkersReduce;
