import { Dispatch } from 'redux';

import { updateSubscriptionTitle } from '@pages/subscriptions/sidebar/redux/updateSubscriptionTitle';

import { httpUpdateSubscription } from '@pages/subscriptions/sidebar/redux/updateSubscription.http';

export class UpdateSubscriptionTitleThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: number, title: string }) {

    this.dispatchEditPackageName(params);

    await this.updateSubscriptionHttp(params);

  }

  private async updateSubscriptionHttp(params) {
    const item = this.getState().subscriptions.subscriptions.itemsMap.get(params.id);

    try {
      const requestParams = {
        id: params.id,
        data: {
          title: params.title,
          is_notify: item.hasNotification,
        },
      };

      await httpUpdateSubscription(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchEditPackageName(params) {
    this.dispatch(updateSubscriptionTitle.createAction(params));
  }

}

export function updateSubscriptionTitleThunk(dispatch, params) {
  const thunk = new UpdateSubscriptionTitleThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default UpdateSubscriptionTitleThunk;
