import styled, { css } from 'styled-components';

import Checkbox from '@components/checkbox/checkbox.style';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  isActive: boolean;
  isVisited: boolean;
  visible: boolean;
  highlight: boolean;
  isBlue?: string;
  column?: string
}

const table = {
  MINIMAL_COLUMN: 1,
  REGULAR_COLUMN: 2,
  MIDDLE_COLUMN: 3,
  LARGE_COLUMN: 4,
};

function textEllipsis() {
  return `
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

function getColor(props: Props) {
  if (props.isBlue) {
    return props.theme.colors.blue;
  }

  return props.theme.colors.defaultText;
}

function getWidth(props: Props, isLarger: boolean) {
  const MAX_SIZE = 96;
  const MIN_SIZE = 80;
  const size = isLarger ? MAX_SIZE : MIN_SIZE;
  // @ts-ignore
  const result = size * table[props.column];

  return result || size * 2;
}

const style: any = {};

const width = {
  checkbox: '48px',
  item: '100%',
  additional: '40px',
};

style.Root = styled.div`
  font-size: 13px;
  background-color: ${(props: Props) => props.theme.colors.white};
`;

style.Table = styled.div`
  display: table;
  border-collapse: collapse;
  width: 100%;
`;

style.Group = styled.div`
  display: table-column-group;
`;

style.ColumnWidthCheckbox = styled.div`
  height: 100%;
  width: ${width.checkbox};
`;

style.ColumnWidthAdditional = styled.div`
  height: 100%;
  width: ${width.additional};
`;

style.ColumnWidthItem = styled.div`
  min-width: ${(props: Props) => getWidth(props, false)}px;
  max-width: 100%;

  @media (min-width: 1408px) {
    min-width: ${(props: Props) => getWidth(props, true)}px;
  }
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

style.BodyCellContainer = styled(style.CellContainer)`
  padding: 16px 12px;

  ${(props: Props) => props.isVisited && css`
    color: ${props.theme.colors.disabledText};
  `};
`;

style.ColumnCheckbox = styled(style.ColumnWidthCheckbox)`
  display: table-column;
`;

style.ColumnAdditional = styled(style.ColumnWidthAdditional)`
  display: table-column;
`;

style.ColumnItem = styled(style.ColumnWidthItem)`
  display: table-column;
`;

const cellStyle = () => css`
  display: table-cell;
  vertical-align: top;
`;

style.CellCheckbox = styled(style.ColumnWidthCheckbox)`
  ${cellStyle()}
  vertical-align: baseline;
`;

style.CellAdditional = styled(style.ColumnWidthAdditional)`
  ${cellStyle()}
  vertical-align: baseline;
`;

style.CellItem = styled(style.ColumnWidthItem)`
  ${cellStyle()}
`;

style.TopHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 16px 16px 16px;
  font-size: 17px;
  font-weight: 500;
  height: 57px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Header = styled.div`
  display: table-header-group;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  line-height: 38px;

  ${style.CellCheckbox} {
    ${Checkbox.Root} {
      padding-top: 8px;
    }
  }
`;

style.HeaderText = styled.div`
  ${1};
  font-size: 11px;
  font-family: Roboto-Bold;
`;

style.BodyText = styled.div`
  ${textEllipsis};
`;

style.TitleText = styled(style.BodyText)`
  color: ${(props: Props) => (!props.isVisited ? getColor(props) : props.theme.colors.disabledText)};
`;

style.TitleLabel = styled(style.BodyText)`
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.DataItems = styled(style.BodyText)`
  ${(props: Props) => props.isVisited && css`
    color: ${props.theme.colors.disabledText};
  `};;
`;

style.TitleLink = styled.a`
  text-decoration: none;
  ${textEllipsis};
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.blue : props.theme.colors.disabledText)};
`;

style.Body = styled.div`
  display: table-row-group;
`;

style.MoreVisibility = styled.div`
  height: 100%;
  opacity: 0;

  ${(props: Props) => props.visible && css`
    opacity: 1;
  `}
`;

style.Item = styled.div`
  display: table-row;
  cursor: pointer;
  user-select: none;
  color: inherit;
  text-decoration: none;

  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  ${(props: Props) => props.isActive && css`
    background-color: ${props.theme.colors.polar};
  `}

  &:hover {
    background-color: ${(props: Props) => props.theme.colors.polar};
  }

  &:hover ${style.MoreVisibility} {
    opacity: 1;
  }

  ${(props: Props) => props.highlight && css`
    background-color: ${props.theme.colors.polar};
  `}

  ${style.CellCheckbox} {
    ${Checkbox.Root} {
      margin-top: -5px;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:last-child {
    ${style.ColumnWidthItem},
    ${style.ColumnWidthCheckbox} {
      border-bottom: none;
    }
  }
`;

style.Comment = styled.div`
  display: table-row;
  background: ${(props: Props) => props.theme.colors.backgroundGrey};
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.CommentCell = styled.td`
  display: table-cell;
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

export default style;
