import { StateBuilder } from './state';

import { DocumentId } from '../../redux/state';

export interface ActionData {
  id: DocumentId;
}

interface Action {
  type: string;
  data: ActionData;
}

export const toggleItemSelectReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.documents);

  return {
    ...state,
    documents: newState.toggleItemSelect(action.data.id),
  };
};

export default toggleItemSelectReduce;
