import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const style: any = {};

interface Props {
  visible: boolean;
}

style.MoreVisibility = styled.div`
  height: 100%;
  width: 48px;
  display: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: -16px;

  ${(props: Props) => props.visible && css`
    display: flex;
  `}
`;

style.Item = styled(NavLink)`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  text-decoration: none;
  background: transparent;

  :hover {
    background: rgba(255, 255, 255, 0.07);
  }

  :hover ${style.MoreVisibility} {
    display: flex;
  }

  &.active {
    > div {
      opacity: 1;
    }

    > div > span {
      opacity: 1;
    }
  }
`;

style.ItemIcon = styled.div`
  opacity: 0.5;
`;

style.TextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

style.ItemText = styled.span`
  padding-left: 8px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.5;
`;

export default style;
