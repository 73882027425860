import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './logoControl.style';

interface LogoControlProps {
  hasImage: boolean;
  onUpload(file: File): void;
  onDelete(): void;
  shouldRender: boolean;
}

export class LogoControl extends React.PureComponent<LogoControlProps> {

  handleInputFileChange = (event) => {
    const { target } = event;
    const [file] = target.files;
    if (!file) {
      return;
    }

    this.props.onUpload(file);
    target.value = '';
  };

  handleDelete = () => {
    this.props.onDelete();
  };

  renderInput() {
    return (
      <s.Input
        type="file"
        accept=".jpeg, .jpg, .png, .tiff, .bmp"
        onChange={this.handleInputFileChange}
      />
    );
  }

  renderControls() {
    if (this.props.hasImage) {
      const editIcon = iconsPath.get('imageEdit');
      const deleteIcon = iconsPath.get('imageDelete');

      return (
        <s.Actions>
          <s.Action>
            <Icon path={editIcon} color="#ffffff" />
            {this.renderInput()}
          </s.Action>
          <s.Action
            onClick={this.handleDelete}
          >
            <Icon path={deleteIcon} color="#ffffff" />
          </s.Action>
        </s.Actions>
      );
    }

    const uploadIcon = iconsPath.get('imageUpload');

    return (
      <s.ActionsSimple>
        <s.ActionSimple>
          <Icon path={uploadIcon} color="#ffffff" />
          {this.renderInput()}
        </s.ActionSimple>
      </s.ActionsSimple>
    );
  }

  render() {
    return (
      <s.Root shouldRender={this.props.shouldRender}>
        {this.renderControls()}
      </s.Root>
    );
  }

}

export default LogoControl;
