import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 0 64px;
`;

style.Title = styled.div`
  display: flex;
  flex-direction: row;

  padding: 48px 0 24px 0;

  font: 17px Roboto-Medium;
  line-height: 20px;
`;

style.List = styled.div`
  padding-bottom: 64px;
`;

style.Divider = styled.div`
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  display: flex;
  flex-shrink: 0;
`;

export default style;
