import { connect } from 'react-redux';
import {
  ViewTypes,
  saveLayoutSettingsThunk,
} from '../redux/saveLayoutSettings.thunk';

import { Layout } from './layout';

const mapStateToProps = (state) => {
  const {
    settingsLoading,
    layout,
  } = state.regulation.projects;

  return {
    viewTypeId: layout.viewType,
    loading: settingsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onViewTypeChange(viewTypeId: ViewTypes) {
    saveLayoutSettingsThunk(dispatch, viewTypeId);
  },
});

export const LayoutConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);

export default LayoutConnect;
