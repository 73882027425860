import { fetchDocumentsPackageCountReduce }
  from '@pages/searchResult/documents/redux/fetchDocumentsPackageCount.reduce';

import type { ActionData } from '@pages/searchResult/documents/redux/fetchDocumentsPackageCount.reduce';

const actionType = 'AUTHOR_PACKAGES/DOCUMENTS/FETCH_DOCUMENTS__PACKAGE_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDocumentsPackageCount = {
  actionType,
  createAction,
  reduce: fetchDocumentsPackageCountReduce,
};

export default fetchDocumentsPackageCount;
