import styled from 'styled-components';

import Logo from '../../components/logo/logo.style';

const style = {};

// color: #22313f;
// color: #ffbe25;

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 432px;
  padding: 0 43px 129px;
  overflow-y: auto;
  background-color: ${(p) => p.theme.colors.white};
`;

style.Header = styled.div`
  padding: 43px 0;
  flex-shrink: 0;
`;

style.Logo = styled.span`
  font-family: 'Roboto-Medium';
  font-size: 28px;
  text-decoration: none;
  color: inherit;

   > div {
     font-size: 29px;
   }

  ${Logo.LogoCase} {
    color: #22313f;
  }
`;

style.Body = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

style.Content = styled.div`
  width: 385px;
`;

export default style;
