import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';

import { DocumentConnect } from './document.connect';

import { withFetchSearch } from '../withFetchSearch';

export const RouteSearchDocument = (
  <AuthRouteConnect
    path={RoutePath.searchDocument}
    component={withFetchSearch(DocumentConnect)}
    exact
  />
);

export default RouteSearchDocument;
