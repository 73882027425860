import { Dispatch } from 'redux';

import { DateBlock } from '@components/filters/date/logic/date';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { createFilter } from '@pages/regulation/sidebar/filters/redux/createFilter';
import { saveFilters } from '@pages/regulation/sidebar/filters/redux/saveFilters';
import { httpCreateFilter } from './createFilter.http';
import { httpSaveFilters } from './saveFilters.http';

export class SaveFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  private title: string;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.title = null;
  }

  public invoke(title) {
    this.title = title;

    const requestParams = this.requestParamsBuilder();
    const { smartFolders } = this.getState().regulation;
    const id = smartFolders.activeItemId;
    if (!id) {
      this.createFilter(requestParams);
    } else {
      this.saveFilters({ ...requestParams, name: smartFolders.itemsMap.get(id).title, id });
    }

    this.marketingEvent(requestParams);

  }

  private marketingEvent(requestParams) {
    sendAmplitudeData('regulation:save_filters', requestParams);
    Userpilot.track('regulation:save_filters');
  }

  private requestParamsBuilder() {
    const state = this.getState();
    const filters = { ...state.regulation.filters.filters };

    const dateFilter = new DateBlock();
    dateFilter.create(filters.date);

    filters.date = dateFilter.undecorate();

    return {
      name: this.title,
      filters,
    };
  }

  private async createFilter(requestParams) {
    try {
      const response = await httpCreateFilter(requestParams);
      this.dispatchCreateFilter({ title: requestParams.name, id: response.id });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async saveFilters(requestParams) {
    try {
      await httpSaveFilters(requestParams);
      this.dispatchSaveFiltersDone({ title: requestParams.name, id: requestParams.id });
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchCreateFilter(params) {
    const filters = this.getState().regulation.filters.filters;
    this.dispatch(createFilter.createAction({ id: params.id, name: params.title, filters }));
  }

  private dispatchSaveFiltersDone(params) {
    this.dispatch(saveFilters.createAction({ id: params.id }));
  }

}

export function saveFiltersThunk(dispatch, title) {
  const thunk = new SaveFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(title);
}
