import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpRemoveDocument(requestParams) {
  const url = RESTPath.packages.removeDocumentFromPackage(requestParams);
  const request = http.delete({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpRemoveDocument;
