import React from 'react';

import { SharedLinkCopy } from '@pages/document/components/sharedLinkCopy/sharedLinkCopy';
import { RequisitesCopy } from '@pages/document/components/requisitesCopy/requisitesCopy';
import {
  Download,
  Item,
} from './download';

import { MenuItem } from './menuItem';

import s from './settingsMenu.style';

interface SettingsMenuProps {
  id: number;
  onLevelChange: (params: { wide: boolean; level: number }) => void;
  source: string;
  sourceId: number;
  requisites: string;
  downloadItems: Item[];
  closePopover: () => void;
}

enum ViewTypes {
  shared = 'SHARED',
  requisites = 'REQUISITES',
  download = 'DOWNLOAD',
  default = '',
}

enum Level {
  main,
  edit,
}

interface SettingsMenuState {
  view: {
    type: ViewTypes.shared | ViewTypes.requisites | ViewTypes.download | ViewTypes.default;
    level: number;
  };
}

export class SettingsMenu extends React.PureComponent<SettingsMenuProps, SettingsMenuState> {

  state = {
    view: {
      type: ViewTypes.default,
      level: Level.main,
    },
  };

  setView(type: ViewTypes, level: number) {
    this.setState({
      view: {
        type,
        level,
      },
    });
  }

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  setLevelToMain = () => {
    this.props.onLevelChange({ wide: false, level: Level.main });
    this.setView(ViewTypes.default, Level.main);
  };

  setLevelToShared = () => {
    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.shared, Level.edit);
  };

  setLevelToRequisites = () => {
    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.requisites, Level.edit);
  };

  setLevelToDownload = () => {
    this.props.onLevelChange({ wide: false, level: Level.edit });
    this.setView(ViewTypes.download, Level.edit);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderMainSharedLink() {
    return (
      <MenuItem
        title="Скопировать ссылку на документ"
        onClick={this.setLevelToShared}
      />
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderSecondSharedLink() {
    const isShared = this.state.view.type === ViewTypes.shared;
    if (isShared) {
      return (<SharedLinkCopy {...this.props} onClick={this.setLevelToMain} />);
    }

    return null;
  }

  renderMainRequisites() {
    return (
      <MenuItem
        title="Скопировать реквизиты"
        onClick={this.setLevelToRequisites}
      />
    );
  }

  renderSecondRequisites() {
    const shouldRenderNextLevel = this.state.view.type === ViewTypes.requisites;
    if (shouldRenderNextLevel) {
      return (<RequisitesCopy {...this.props} onClick={this.setLevelToMain} />);
    }

    return null;
  }

  renderMainDownload() {
    return (
      <MenuItem
        title="Скачать"
        onClick={this.setLevelToDownload}
        withArrow
      />
    );
  }

  renderSecondDownload() {
    const shouldRenderNextLevel = this.state.view.type === ViewTypes.download;
    if (shouldRenderNextLevel) {
      return (
        <Download
          onGoBack={this.setLevelToMain}
          items={this.props.downloadItems}
          closePopover={this.props.closePopover}
        />
      );
    }

    return null;
  }

  renderList() {
    const isFirstLevel = this.state.view.level === Level.main;
    if (isFirstLevel) {
      return (
        <s.List>
          {/* Todo: [sharing] Вернуть с функционалом открытия шаренных документов */}
          {/* {this.renderMainSharedLink()} */}
          {this.renderMainRequisites()}
          {this.renderMainDownload()}
        </s.List>
      );
    }

    const isSecondDownload = this.state.view.type === ViewTypes.download;
    if (isSecondDownload) {
      return (
        <s.List>
          {this.renderSecondDownload()}
        </s.List>
      );
    }

    return (
      <s.Edit>
        {/* Todo: [sharing] Вернуть с функционалом открытия шаренных документов */}
        {/* {this.renderSecondSharedLink()} */}
        {this.renderSecondRequisites()}
      </s.Edit>
    );
  }

  render() {
    return (
      <s.Root
        onClick={this.stopPropagation}
      >
        {this.renderList()}
      </s.Root>
    );
  }

}

export default SettingsMenu;
