import { ActionTypeApp } from './action.type';

export class ActionApp {

  static initialState = () => ({
    type: ActionTypeApp.INITIAL_STATE,
  });

}

export default ActionApp;
