export const dispatchRemovingStatusReduce = (state, action) => {
  const data = state.history.data.map((item) => {
    if (item.id !== action.data.id) {
      return item;
    }

    return {
      ...item,
      isRemoving: true,
    };
  });

  const history = {
    ...state.history,
    data,
  };

  return {
    ...state,
    history,
  };
};

export default dispatchRemovingStatusReduce;
