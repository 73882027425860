import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';
import { prepareHighlightIds } from '@pages/searchResult/documents/utils';

import { props } from '@pages/searchResult/documents/preview/documentPreview.connect';

import { fetchDocumentThunk } from './redux/fetchDocument.thunk';
import { saveMarkersThunk } from './redux/saveMarkers.thunk';
import { saveCommentaryThunk } from './redux/saveCommentary.thunk';

import { DocumentPreview } from './documentPreview';

const mapStateToProps = (state, ownProps) => {
  const { currentPageNumber } = state.subscriptions.documents.previewDocument;
  const document = state.subscriptions.documents.pages[currentPageNumber].itemsMap.get(ownProps.documentId);
  if (!document) {
    return props;
  }

  const title = `${document.documentType} от ${document.date}`;

  const source = Source.subscription;
  const sourceId = state.subscriptions.subscriptions.activeItemId;
  const documentId = ownProps.documentId;

  return {
    baseHref: `#/${source}/${sourceId}/documents/${documentId}`,
    attributesLoaded: document.attributesLoaded,
    documentLoaded: document.documentLoaded,
    source,
    sourceId,
    documentId,
    data: {
      title,
      attributes: document.details.attributes,
      document: {
        title: document.details.title,
        department: document.details.attributes.department,
        body: document.details.document,
        highlightIds: prepareHighlightIds(document.details.document),
      },
      resolution: {
        body: document.details.resolution,
        highlightIds: prepareHighlightIds(document.details.resolution),
      },
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    const params = {
      documentId: ownProps.documentId,
      source: Source.subscription,
    };

    fetchDocumentThunk(dispatch, params);
  },
  onCommentaryChange(commentary) {
    saveCommentaryThunk(dispatch, { commentary, documentId: ownProps.documentId });
  },
  onSaveMarkers(entities, sourceId) {
    const requestParams = {
      documentId: ownProps.documentId,
      source: Source.subscription,
      sourceId,
      data: {
        entities,
      },
    };

    saveMarkersThunk(dispatch, requestParams);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSaveMarkers(entities) {
    dispatchProps.onSaveMarkers(entities, stateProps.sourceId);
  },
});

export const DocumentPreviewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DocumentPreview);

export default DocumentPreviewConnect;
