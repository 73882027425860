import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  pointer-events: none;
`;

export default style;
