import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { FolderConnect } from './folder.connect';

import s from './item.style';

interface State {
  showPreview: boolean;
}

export type Id = number;

interface EventInfo {
  date: string;
  dateEnd: string;
  title: string;
  eventTitle: string;
  projectTitle: string;
}

interface FolderItem {
  id: number;
  title: string;
}

export interface ItemProps {
  projectId: number;
  event: EventInfo;
  folders: FolderItem[];
}

interface Props {
  item: ItemProps;
  activeId: Id;
  onItemClick: (id: Id) => void;
}

export class Item extends React.PureComponent<Props, State> {

  handleItemClick = () => {
    sendAmplitudeData('regulation:calendar_project__click', { id: this.props.item.projectId });
    Userpilot.track('regulation:calendar_project__click');

    this.props.onItemClick(this.props.item.projectId);
  };

  renderFolders() {
    return this.props.item.folders.map((item) => <FolderConnect id={item.id} title={item.title} />);
  }

  render() {
    const {
      item,
    } = this.props;

    const isActive = this.props.activeId === item.projectId;

    return (
      <s.Root
        isActive={isActive}
        onClick={this.handleItemClick}
      >
        <s.CellDocument>
          <s.BodyCell>
            <s.TitleText bold marginBottom={8}>{item.event.eventTitle}</s.TitleText>
            <s.TitleText marginBottom={8}>{item.event.projectTitle}</s.TitleText>
            <s.Folders>
              {this.renderFolders()}
            </s.Folders>
          </s.BodyCell>
        </s.CellDocument>
      </s.Root>
    );
  }

}

export default Item;
