import { StateBuilder as PackagesStateBuilder } from '../sidebar/redux/state';
import { StateBuilder as DocumentsStateBuilder } from '../workspace/documents/redux/state';

export enum Source {
  package = 'package',
}

export class StateBuilder {

  static createState() {
    return {
      packages: PackagesStateBuilder.createState(),
      documents: DocumentsStateBuilder.createState(),
      id: null,
    };
  }

}

export default StateBuilder;
