import { StateBuilder } from './state';

export const resetStateReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    comments: StateBuilder.createState(),
  };
};

export default resetStateReduce;
