import React from 'react';

import s from '@pages/search/search.style';

import { FormConnect } from './form/form.connect';
import { HistoryConnect } from './history/history.connect';

import filters from './filters.style';

export class Filters extends React.PureComponent {

  componentWillUnmount() {}

  render() {
    return (
      <filters.Root>
        <s.Workspace>
          <s.RootContainer>
            <s.FilterContainer>
              <FormConnect />
            </s.FilterContainer>
            <s.HistoryContainer>
              <HistoryConnect />
            </s.HistoryContainer>
          </s.RootContainer>
        </s.Workspace>
      </filters.Root>
    );
  }

}

export default Filters;
