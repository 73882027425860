import React from 'react';

import {
  File as FileProps,
  Phase as PhaseProps,
} from '../../redux/state';

import { File } from './file';

import s from './phase.style';

interface PhasesTabProps {
  item: PhaseProps;
}

export class Phase extends React.PureComponent<PhasesTabProps> {

  renderFiles(event) {
    if (!event.files) {
      return null;
    }

    return (
      <s.Files>
        {event.files.map(this.renderFile)}
      </s.Files>
    );
  }

  renderFile = (file: FileProps) => <File {...file} />;

  renderEvent = (event) => {
    const date = event.date || 'Нет даты';

    return (
      <s.Container>
        <s.Date hasDate={!!event.date}>{date}</s.Date>
        <s.Item hasDate={!!event.date}>
          <s.Name>{event.title}</s.Name>
          <s.Description>{event.description}</s.Description>
          {this.renderFiles(event)}
        </s.Item>
      </s.Container>
    );
  };

  render() {
    return (
      <s.Root>
        <s.Title>{this.props.item.title}</s.Title>
        {this.props.item.events.map(this.renderEvent)}
      </s.Root>
    );
  }

}

export default Phase;
