import styled from 'styled-components';

const style: any = {};

style.Slider = styled.div`
  display: flex;
  height: 34px;

  .rc-slider {
    position: relative;
    height: 2px;
    width: 100%;
    border-radius: 6px;
    background-color: #e9e9e9;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 2;
    margin: 17px 17px 17px 31px;
  }

  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }


  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 2px;
    border-radius: 6px;
    background-color: #4183d7;
  }

  .rc-slider-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -7px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #4183d7;
    outline: none;
  }

  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }

  .rc-slider-handle-active:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
  }

  .rc-slider-mark {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }

  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
  }

  .rc-slider-mark-text-active {
    color: #666;
  }

  .rc-slider-step {
    display: none;
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  .rc-slider-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
  }


  .rc-slider-dot:first-child {
    margin-left: -4px;
  }


  .rc-slider-dot:last-child {
    margin-left: -4px;
  }

  .rc-slider-dot-active {
    border-color: #96dbfa;
  }

  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
`;

export default style;
