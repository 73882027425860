import React from 'react';
import { theme } from '@components/styled';
import {
  SortIcon,
  SortType,
  SortDirection,
  SortDataProps,
} from '../../components/sortIcon/sortIcon';
import s from './item.style';

interface ColumnSortProps {
  isActive: boolean;
  sortData: SortDataProps;
}

type Settings = [SortType, string];

export const ColumnSort = (props: ColumnSortProps): JSX.Element => {
  const { isActive, sortData: { type } } = props;

  const getSettings = (): Settings => {
    if (!isActive) {
      const nonActiveSortType = SortDirection.DESC;
      const nonActiveSettings: Settings = [nonActiveSortType, theme.colors.defaultText];

      return nonActiveSettings;
    }

    const activeSettings: Settings = [type, theme.colors.blue];

    return activeSettings;
  };

  const [sortType, color] = getSettings();

  return (
    <s.ColumnSort isActive={isActive}>
      <SortIcon sortType={sortType} color={color} />
    </s.ColumnSort>
  );
};

export default ColumnSort;
