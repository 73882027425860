import { connect } from 'react-redux';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { resetState } from './redux/resetState';
import { fetchCommentsThunk } from './redux/fetchComments.thunk';

import { Comments } from './comments';

const mapStateToProps = (state) => {
  const hasComments = state.regulation.comments.hasComments;
  const loading = state.regulation.comments.loading;

  return {
    hasComments,
    loading,
  };

};

const mapDispatchToProps = (dispatch) => ({
  onFetchData() {
    sendAmplitudeData('regulation:comments__open_layout');
    Userpilot.track('regulation:comments__open_layout');
    fetchCommentsThunk(dispatch);
  },
  onReset() {
    dispatch(resetState.createAction());
  },
});

export const CommentsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comments);

export default CommentsConnect;
