import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  isActive: boolean;
  marginRight: number;
}

style.Root = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: end;
  align-items: center;
`;

style.Tool = styled.div`
  margin-right: ${(props: Props) => props.marginRight || 8}px;
`;

style.Icon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: #4183D712;
  }

  :active {
    background: #4183D726;
  }

  ${(props: Props) => props.isActive && css`
    background: #4183D726;

    :hover {
      background: #4183D726;
    }

    i {
      color: ${theme.colors.defaultText};
    }
  `}
`;

export default style;
