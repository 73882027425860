import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Loader } from '@components/loader';

import s from './editions.style';

interface Item {
  id: string;
  title: string;
  isActive: boolean;
}

interface EditionProps {
  loading: boolean;

  items: Item[];
}

export class Editions extends React.PureComponent<EditionProps> {

  checkIsEmptyList = () => {
    const editions = this.props.items.filter((item) => !item.isActive);

    return isEmpty(editions);
  };

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderEmptyTitle() {
    return (
      <s.EmptyTitle>
        Редакции отсутствуют
      </s.EmptyTitle>
    );
  }

  renderItems() {
    return (
      <s.Items>
        {this.props.items.map(this.renderItem)}
      </s.Items>
    );
  }

  renderItem = (item) => {
    let path = '';
    const basePath = window.location.hash
      .replace('#/', '')
      .split('?')[0];

    path = `#/${basePath}?editionDate=${item.id}`;

    return (
      <s.Item
        href={path}
        key={item.id}
        isActive={item.isActive}
      >
        <s.Title>{item.title}</s.Title>
      </s.Item>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    if (this.checkIsEmptyList()) {
      return this.renderEmptyTitle();
    }

    return (
      <s.Root
        isEmpty={this.checkIsEmptyList()}
      >
        {this.renderItems()}
      </s.Root>
    );
  }

}

export default Editions;
