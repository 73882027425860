import { getHistoryReduce } from './fetchHistory.reduce';

const actionType = 'SEARCH/HISTORY/GET_HISTORY';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const fetchHistory = {
  actionType,
  createAction,
  reduce: getHistoryReduce,
};

export default fetchHistory;
