import { DefendantBlock } from '../../form/filterBlocks/defendant/redux/defendant';
import {
  ParticipantBlock,
  ParticipantBlockData,
} from '../../form/filterBlocks/participant/redux/participant';

export abstract class DefendantBlockHistory extends DefendantBlock {

  static transform(data: ParticipantBlockData) {
    let result = ParticipantBlock.presetsMap[data.mode.id].title;

    if (data.participant.title) {
      result = `${result} • ${data.participant.title}`;
    }

    return result;
  }

}

export default DefendantBlockHistory;
