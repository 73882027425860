import http from '@http';
import { RESTPath } from '@router/path.rest';

export interface RequestData {
  period: string;
}

export function httpPaymentCreate(data: RequestData) {
  const request = {
    url: RESTPath.user.createPayment,
    data,
  };

  return http.post(request)
    .then(http.handleResponse)
    .then(http.parseResponse);
}

export default httpPaymentCreate;
