export const loadingStartReduce = (state) => ({
  ...state,
  documents: {
    ...state.documents,
    loading: true,
  },
});

export const loadingEndReduce = (state) => ({
  ...state,
  documents: {
    ...state.documents,
    loading: false,
  },
});
