import { Dispatch } from 'redux';

import { httpFetchBookmarks } from './fetchBookmarks.http';
import { fetchBookmarks } from './fetchBookmarks';

export class FetchBookmarksThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    await this.fetchBookmarksRequest();
  }

  private async fetchBookmarksRequest() {
    try {
      const response = await httpFetchBookmarks();
      this.dispatchFetchBookmarks(response.result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchBookmarks(params) {
    this.dispatch(fetchBookmarks.createAction(params));
  }

}

export function fetchBookmarksThunk(dispatch) {
  const thunk = new FetchBookmarksThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default FetchBookmarksThunk;
