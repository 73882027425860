import { RESTPath } from '@router/path.rest';
import http from '@http';

// eslint-disable-next-line max-len
export function httpFetchSubscriptionDocuments(requestParams: { sourceId: number; pageNumber: number; searchId?: number }) {
  const request = http.get({
    url: RESTPath.subscriptions.getDocuments(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSubscriptionDocuments;
