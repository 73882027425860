import { RESTPath } from '@router/path.rest';
import http from '@http';

export interface User {
  id: number;
  name: string;
  email: string;
}

export interface UsersDTO {
  result: User[];
}

export function httpFetchUsers(query: string): Promise<UsersDTO> {
  const url = RESTPath.regulation.chat.users(query);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchUsers;
