import React from 'react';

import { Tabs } from '@pages/document/sidebar/tabs';

import s from './sidebar.style';

interface Tab {
  id: string;
  title: string;
}

interface InformationProps {
  Tab: new() => React.Component;
  tabs: Tab[];
  activeTabId: string;
  onUpdateActiveTab(tabId: string): void;
}

export class Sidebar extends React.PureComponent<InformationProps> {

  render() {
    return (
      <s.Root>
        <s.Header>
          <Tabs
            tabs={this.props.tabs}
            activeId={this.props.activeTabId}
            onClick={this.props.onUpdateActiveTab}
          />
        </s.Header>
        <s.Content>
          <this.props.Tab />
        </s.Content>
      </s.Root>
    );
  }

}

export default Sidebar;
