import {
  styled,
  css,
} from '@components/styled';

const style: any = {};

interface Props {
  hovered: boolean;
  theme: any;
}

style.Root = styled.div`
  position: relative;
  font: 13px Roboto-Regular;
  line-height: 16px;
  padding: 12px 0 5px;
  display: flex;

  ${(props: Props) => props.hovered && css`
    background: ${props.theme.colors.lightBlue};
  `}
`;

style.Item = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

style.Date = styled.div`
  text-decoration: none;
  padding: 0 16px;
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.Text = styled.div`
  margin-bottom: 4px;
  padding: 0 42px 0 16px;

  a {
    color: ${(props: Props) => props.theme.colors.blue};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

style.Circle = styled.span`
  position: absolute;
  display: inline-block;
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props: Props) => props.theme.colors.blue};
  box-sizing: content-box;
`;

export default style;
