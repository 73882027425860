import styled from 'styled-components';

import {
  width,
  cellStyle,
} from './list.style';

const style: any = {};

style.ColumnWidthMore = styled.div`
  width: ${width.more};
`;

style.ColumnMore = styled(style.ColumnWidthMore)`
  display: table-column;
`;

style.CellMore = styled(style.ColumnWidthMore)`
  ${cellStyle()}

  padding-right: 24px;

  > div {
    padding-top: 8px;
  }
`;

export default style;
