import React from 'react';

import { Loader } from '@components/loader';

import {
  Id,
  Loading,
} from '@pages/regulation/redux/users/state';

import { UserConnect } from './user.connect';

import s from './users.style';

interface UsersProps {
  items: Id[];
  folderId: Id;
  loading: Loading;
  onFetch(): void;
  onReset(): void;
}

export class Users extends React.PureComponent<UsersProps> {

  componentDidMount() {
    this.props.onFetch();
  }

  componentWillUnmount() {
    this.props.onReset();
  }

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderItem = (item) => (
    <UserConnect
      key={item}
      id={item}
      folderId={this.props.folderId}
    />
  );

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return (
      <s.Root>{this.props.items.map(this.renderItem)}</s.Root>
    );
  }

}

export default Users;
