import React from 'react';

import { BaseFolder } from './baseFolder';

import s from '../items.style';

interface Item {
  id: number;
  title: string;
  active: boolean;
}

interface ItemsProps {
  items: Item[];
  onSelectItem(id): void;
}

export class BaseFolders extends React.PureComponent<ItemsProps> {

  handleClick = (id) => this.props.onSelectItem(id);

  renderItems = () => this.props.items.map(this.renderItem);

  renderItem = (item) => <BaseFolder key={item.id} {...item} onSelectItem={this.handleClick} />;

  render() {
    return (
      <s.BaseFoldersRoot>
        {this.renderItems()}
      </s.BaseFoldersRoot>
    );
  }

}

export default BaseFolders;
