import styled from 'styled-components';

const style: any = {};

style.Root = styled.p`
  color: rgb(34, 49, 63);
  margin: auto 0;
  font-size: 17px;
`;

export default style;
