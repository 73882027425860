import { TagDTO } from '../../packages/redux/state';

interface Block {
  body: string;
  id: string;
}

interface Body {
  title: string;
  date: string;
  blocks: Block[];
}

interface Addition {
  id: string;
  title: string;
  description: string;
  blocks: Block[];
}

export interface ActionData {
  id: number;
  department: string;
  department_name_genitive: string;
  body: Body;
  additions: Addition[];
  is_shared: boolean;
  is_owner: boolean;
  is_practice: boolean;
  packages_count: number;
  tag_id: string;
}

interface Action {
  type: string;
  data: ActionData;
}

function prepareDocument(data) {
  return {
    body: data.body,
    additions: data.additions,
    department: data.department,
    departmentNameGenitive: data.department_name_genitive,
    isPractice: !!data.is_practice,
    loading: false,
  };
}

function prepareViewer(data) {
  return {
    isOwner: !!data.is_owner,
  };
}

export const fetchDocumentReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const id = Number(action.data.id);

  const tag = TagDTO[action.data.tag_id];

  return {
    ...state,
    id,
    packagesCount: action.data.packages_count,
    tag,
    isShared: !!action.data.is_shared,
    viewer: prepareViewer(action.data),
    document: {
      ...state.document,
      document: prepareDocument(action.data),
    },
  };
};

export default fetchDocumentReduce;
