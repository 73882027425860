/* eslint-disable */
import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Loader } from '@components/loader';

import { TooltipRelationsTitle } from './tooltipRelationsTitle';
import { TooltipRelationsArticles } from './tooltipRelationsArticles';
import { TooltipRelationsClauses } from './tooltipRelationsClauses';

import { withClickOutside } from './clickOutsideHandler';

import s from './tooltipRelations.style';

interface Props {
  fields: any;
  top?: number;
}

class TooltipRelations extends React.PureComponent<Props> {

  refRoot = React.createRef();

  state = {
    style: {
      top: 0,
      left: 0,
    },
    data: {
      document: null,
      articles: [],
      clauses: [],
    },
    loading: true,
  };

  constructor(props) {
    super(props);

    const callback = () => {
      this.setStyles();
    };

    // @ts-ignore
    this.observer = new MutationObserver(callback);
  }

  componentDidMount() {
    sendAmplitudeData('click law link in text', { source: 'document' });
    Userpilot.track('click law link in text', { source: 'document' });
    this.fetchRelation();
    this.setStyles();
    this.setObserver();
  }

  componentWillUnmount() {
    // @ts-ignore
    this.observer.disconnect();
  }

  setObserver = () => {
    const target = this.refRoot.current;
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    // @ts-ignore
    this.observer.observe(target, config);
  };

  fetchRelation = async () => {
    // @ts-ignore
    const data = await this.props.onFetchRelation();
    this.setState({
      data,
      loading: false,
    });
  };

  setStyles = () => {
    const reference = this.refRoot.current;
    if (!reference) {
      return;
    }

    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

    // @ts-ignore
    const offsetTop = this.props.elementOffsetTop || this.props.tooltipOffsets.top + scrollTop;

    // @ts-ignore
    const top = offsetTop - reference.offsetHeight - 8;

    // @ts-ignore
    const left = this.props.tooltipAlign === 'center' ?
    // @ts-ignore
      this.props.tooltipOffsets.left - reference.offsetWidth / 2 :
    // @ts-ignore
      this.props.tooltipOffsets.left + window.scrollX - reference.offsetWidth;

    this.setState({
      style: {
        top,
        left,
      },
    });
  };

  handleLawClick = () => {
    sendAmplitudeData('click law link in pop-up', { source: 'document' });
    Userpilot.track('click law link in pop-up', { source: 'document' });
  };

  renderTitle() {
    return (
      <s.Title>
        <s.Link>
          {this.props.fields.number}
        </s.Link>
      </s.Title>
    );
  }

  renderArticles() {
    if (isEmpty(this.state.data.articles)) {
      return null;
    }

    return <TooltipRelationsArticles
      onClick={this.handleLawClick}
      items={this.state.data.articles}
    />;
  }

  renderClauses() {
    if (isEmpty(this.state.data.clauses)) {
      return null;
    }

    return <TooltipRelationsClauses
      onClick={this.handleLawClick}
      items={this.state.data.clauses}
    />;
  }

  renderTooltip() {
    if (this.state.loading) {
      return (
        <s.Loader>
          <Loader viewMode size={32} />
        </s.Loader>
      );
    }

    return (
      <>
        <TooltipRelationsTitle
          onClick={this.handleLawClick}
          {...this.state.data.document}
        />
        <s.Scrollable>
          {this.renderArticles()}
          {this.renderClauses()}
        </s.Scrollable>
      </>
    );
  }

  render() {
    return (
      <s.Root
        top={this.state.style.top}
        left={this.state.style.left}
        // @ts-ignore
        ref={this.refRoot}
      >
        {this.renderTooltip()}
      </s.Root>
    );
  }

}

export const TooltipRelationsWithClickOutside = withClickOutside(TooltipRelations);

export default TooltipRelationsWithClickOutside;
