import {
  StateBuilder,
  Id,
  BodyBlock,
} from './state';

interface Document {
  body: BodyBlock[];
}

export interface ActionData {
  id: Id;
  result: Document;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchProjectReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.preview);
  newState.buildPreviewProject(action.data.id, action.data.result.body);

  const preview = {
    ...newState.getState(),
  };

  return {
    ...state,
    preview,
  };
};

export default fetchProjectReduce;
