import { updateFilterReduce } from './updateFilter.reduce';

import type { ActionData } from './updateFilter.reduce';

const actionType = 'HISTORY/UPDATE_FILTER';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const updateFilter = {
  actionType,
  createAction,
  reduce: updateFilterReduce,
};

export default updateFilter;
