export type Id = number;
export type Title = string;
export type Role = string;
export type Counter = number;

export class Item {

  private id: Id = null;

  private title: Title = '';

  private role = 'owner';

  private count: Counter = 0;

  public buildItem(item: { id: Id, title: Title, count: Counter, role: Role, }): void {
    this.setId(item.id);
    this.setTitle(item.title);
    this.setRole(item.role);
    this.setCounter(item.count);
  }

  public setId(id: Id): void {
    this.id = id;
  }

  public getId(): Id {
    return this.id;
  }

  public setTitle(title: Title): void {
    this.title = title || '';
  }

  public getTitle(): Title {
    return this.title;
  }

  public setRole(role: Role): void {
    this.role = role || 'owner';
  }

  public getRole(): Role {
    return this.role;
  }

  public setCounter(count: Counter): void {
    this.count = count || 0;
  }

  public getCounter(): Counter {
    return this.count;
  }

  public getItem(): Item {
    return this;
  }

}

export default Item;
