import { connect } from 'react-redux';

import { Sidebar } from './sidebar';

const mapStateToProps = (state) => {
  const { activeItemId, foldersLoading, underControlLoading } = state.regulation.folders;

  const loading = foldersLoading || underControlLoading;

  return {
    activeItemId,
    loading,
  };
};

export const SidebarConnect = connect(
  mapStateToProps,
)(Sidebar);

export default SidebarConnect;
