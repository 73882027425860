import { StateBuilder } from './state';

export interface FilterDTO {
  id: number;
  title: string;
  filters: any;
}

export interface Folder {
  id: number;
  title: string;
  filters: any;
}

export interface ActionData {
  items: FilterDTO[];
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchFiltersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder({ ...state.smartFolders });
  newState.addItems(action.data.items);
  newState.setFiltersLoadingFalse();

  return {
    ...state,
    smartFolders: newState.getState(),
  };
};

export default fetchFiltersReduce;
