import { connect } from 'react-redux';

import { RequisitesCopyPopover } from '@pages/document/components/requisitesCopy/requisitesCopyPopover';

import { buildRequisitesForClipboard } from '@pages/searchResult/documents/utils';

const mapStateToProps = (state) => ({
  requisites: buildRequisitesForClipboard(state.packages.documents.previewDocument.documentId, {
    ...state.packages.documents,
    currentPageNumber: state.packages.documents.previewDocument.currentPageNumber,
  }),
});

export const RequisitesCopyConnect = connect(
  mapStateToProps,
)(RequisitesCopyPopover);

export default RequisitesCopyConnect;
