import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { unpublishAuthorPackage } from './unpublishAuthorPackage';

import { httpUnpublishAuthorPackage } from './unpublishAuthorPackage.http';

export class UnpublishAuthorPackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(id: number) {
    sendAmplitudeData('Public collection: delete');
    Userpilot.track('Public collection: delete');
    this.dispatchUnpublishAuthorPackage({ id });

    const requestParams = {
      packageId: id,
    };

    this.httpUnpublishAuthorPackage(requestParams);
  }

  private async httpUnpublishAuthorPackage(requestParams) {
    try {
      await httpUnpublishAuthorPackage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchUnpublishAuthorPackage(params) {
    this.dispatch(unpublishAuthorPackage.createAction(params));
  }

}

export function unpublishAuthorPackageThunk(dispatch, params) {
  const thunk = new UnpublishAuthorPackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default unpublishAuthorPackageThunk;
