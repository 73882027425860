import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { TooltipRelationsTitle } from '@pages/document/workspace/components/tooltipRelationsTitle';

import { TooltipRelationsArticles } from '@pages/document/workspace/components/tooltipRelationsArticles';
import { TooltipRelationsClauses } from '@pages/document/workspace/components/tooltipRelationsClauses';

import s from '@pages/document/workspace/components/tooltipRelations.style';

interface TooltipProps {
  articles: any[];
  clauses: any[];
  document: any;
  calculatePosition(): void;
}

export class Tooltip extends React.PureComponent<TooltipProps> {

  componentDidMount() {
    sendAmplitudeData('click law link in text', { source: 'preview' });
    Userpilot.track('click law link in text', { source: 'preview' });
    this.props.calculatePosition();
  }

  handleLawClick = () => {
    sendAmplitudeData('click law link in pop-up', { source: 'preview' });
    Userpilot.track('click law link in pop-up', { source: 'preview' });
  };

  renderArticles() {
    if (isEmpty(this.props.articles)) {
      return null;
    }

    return (
      <TooltipRelationsArticles
        onClick={this.handleLawClick}
        items={this.props.articles}
      />
    );
  }

  renderClauses() {
    if (isEmpty(this.props.clauses)) {
      return null;
    }

    return (
      <TooltipRelationsClauses
        onClick={this.handleLawClick}
        items={this.props.clauses}
      />
    );
  }

  render() {
    return (
      <>
        <TooltipRelationsTitle
          onClick={this.handleLawClick}
          {...this.props.document}
        />
        <s.Scrollable>
          {this.renderArticles()}
          {this.renderClauses()}
        </s.Scrollable>
      </>
    );
  }

}

export default Tooltip;
