import React from 'react';

import {
  RouteType,
  RouteTypes,
} from './redux/state';

import { Toolbar } from './toolbar/toolbar';
import { ProjectsConnect } from './projects/projects.connect';
import { FolderConnect } from './folder/folder.connect';

import { CalendarConnect as Calendar } from './calendar/calendar.connect';
import { CommentsConnect as Comments } from './comments/comments.connect';

import s from './workspace.style';
import { FolderType } from '../sidebar/folders/redux/state';

interface WorkspaceProps {
  routeType: RouteTypes;
  folderId: number | string;
}

export class Workspace extends React.PureComponent<WorkspaceProps> {

  renderToolbar() {
    const shouldRenderToolbarForCalendar = this.props.folderId !== FolderType.calendar;
    const shouldRenderToolbarForComments = this.props.folderId !== FolderType.comments;
    if (!shouldRenderToolbarForCalendar || !shouldRenderToolbarForComments) {
      return null;
    }

    return <Toolbar />;
  }

  renderDocuments() {
    if (this.props.folderId === FolderType.calendar) {
      return <Calendar />;
    }

    if (this.props.folderId === RouteType.comments) {
      return <Comments />;
    }

    if (this.props.routeType === RouteType.projects) {
      return <ProjectsConnect />;
    }

    return <FolderConnect />;
  }

  render() {
    return (
      <s.Root>
        {this.renderToolbar()}
        {this.renderDocuments()}
      </s.Root>
    );

  }

}

export default Workspace;
