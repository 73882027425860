import { RESTPath } from '@router/path.rest';
import http from '@http';

export interface CounterDTO {
  counter: number;
}

export function httpFetchFolderCounter(id: string) {
  const url = RESTPath.regulation.folders.getCounter(id);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchFolderCounter;
