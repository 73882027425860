import { connect } from 'react-redux';

import { ChronologyTab } from './chronologyTab';

const mapStateToProps = (state) => {
  const {
    loading,
    pageLoading,
    currentPageNumber,
    pages,
  } = state.chronology;

  return {
    loading,
    pageLoading,
    currentPageNumber,
    pages,
  };
};

export const ChronologyTabConnect = connect(
  mapStateToProps,
)(ChronologyTab);

export default ChronologyTabConnect;
