import { Filters } from '../../filters';

export const changeContainsReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const block = state.query.blocksMap.get(action.data.id);

  if (block.getContains() === action.data.contains) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);
  filters.updateContains(action.data);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap: filters.blocksMap,
    },
  };
};

export default changeContainsReduce;
