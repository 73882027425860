import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  position: relative;

  width: 32px;
  height: 32px;
`;

export default style;
