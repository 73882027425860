import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  position: absolute;
  top: 4px;
  right: -40px;
`;

const loaderWidth = 24;

style.LoaderWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: -36px;
  width: ${loaderWidth}px;
  height: ${loaderWidth}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default style;
