import { ReducerBuilder } from '@redux/reducer.builder';
import { UserState } from './state';
import { fetchUser } from './fetchUser';
import { saveFirstname } from './saveFirstname';
import { saveLastname } from './saveLastname';
import { savePosition } from './savePosition';
import { paymentProcessStart } from './paymentProcessStart';
import { paymentProcessEnd } from './paymentProcessEnd';
import { logout } from './logout';

export const reducerBuilder = new ReducerBuilder([
  fetchUser,
  saveFirstname,
  saveLastname,
  savePosition,
  paymentProcessStart,
  paymentProcessEnd,
  logout,
]);

const state = new UserState();
const initial = state.getState();

reducerBuilder.setInitialState(initial);

export default reducerBuilder;
