import { connect } from 'react-redux';

import { Header } from './header';

const mapStateToProps = (state) => {
  const {
    title,
    isActual,
    document: {
      loading,
    },
  } = state.lawDocument;

  return {
    title,
    isActual,
    loading,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
