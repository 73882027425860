import { fetchPackagesSearchResultReduce } from './fetchPackagesSearchResult.reduce';

import type { ActionData } from './fetchPackagesSearchResult.reduce';

const actionType = 'PACKAGES/SEARCH_RESULT/FETCH';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchPackagesSearchResult = {
  actionType,
  createAction,
  reduce: fetchPackagesSearchResultReduce,
};

export default fetchPackagesSearchResult;
