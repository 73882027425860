import React from 'react';

import { Editor } from '@components/editor/editor';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import {
  SaveButton,
  ResetButton,
} from '@components/buttons';

import s from './inputView.style';

type Body = string;

interface InputViewProps {
  avatar: string;
  mentionAPI: (query: string) => any;
  onSend(body: Body): void;
}

interface InputViewState {
  value: Body;
  inputIsActive: boolean;
  isAvatarHeightDominant: boolean;
}

export class InputView extends React.PureComponent<InputViewProps, InputViewState> {

  state = {
    value: null,
    isAvatarHeightDominant: true,
    inputIsActive: false,
  };

  handleInputChange = (value): void => {
    this.setValue(value);
  };

  handleSend = () => {
    this.props.onSend(this.state.value);
    this.resetStateValue();
  };

  handleCancel = () => {
    this.resetStateValue();
  };

  resetStateValue = () => {
    this.setState({
      value: '',
    });
  };

  onAvatarLoad = ({ target: img }) => {
    const height = img.offsetHeight;
    const width = img.offsetWidth;

    if (height >= width) {
      this.setState({ isAvatarHeightDominant: true });
    } else {
      this.setState({ isAvatarHeightDominant: false });
    }
  };

  setValue = (value: Body) => {
    this.setState({
      value,
    });
  };

  setActiveTrue = () => {
    this.setState({
      inputIsActive: true,
    });
  };

  setActiveFalse = () => {
    this.setState({
      inputIsActive: false,
    });
  };

  checkValue() {
    if (!this.state.value) {
      return false;
    }

    const value = this.state.value.replace('<p><br></p>', '');

    return !!value;
  }

  renderAvatar() {
    if (!this.props.avatar) {
      const avatarPath = iconsPath.get('avatar');

      return <s.AvatarIcon><Icon path={avatarPath} size={32} viewBoxSize={32} color="#dfe2e6" /></s.AvatarIcon>;
    }

    return <img onLoad={this.onAvatarLoad} src={this.props.avatar} alt="avatar" />;
  }

  renderSaveButton() {
    return (
      <SaveButton
        title="Отправить"
        onClick={this.handleSend}
      />
    );
  }

  renderResetButton() {
    return (
      <ResetButton onClick={this.handleCancel} />
    );
  }

  render() {
    const label = 'Оставить комментарий';

    return (
      <s.Root>
        <s.Message>
          <s.Photo
            isAvatarHeightDominant={this.state.isAvatarHeightDominant}
          >
            {this.renderAvatar()}
          </s.Photo>
          <s.TextArea
            className="quill-container"
          >
            <Editor
              value={this.state.value}
              onChange={this.handleInputChange}
              onFocus={this.setActiveTrue}
              onBlur={this.setActiveFalse}
              theme="bubble"
              placeholder={label}
              mentionAPI={this.props.mentionAPI}
            />
          </s.TextArea>
        </s.Message>
        <s.Actions
          shouldRender={this.checkValue()}
          isActive={this.state.inputIsActive}
        >
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Root>
    );
  }

}

export default InputView;
