import { StateBuilder } from './state';

export interface ActionData {
  hasProjects: boolean;
}

export interface Action {
  type: string;
  data: ActionData;
}

export const setHasProjectsReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.events);
  newState.setHasProjects(action.data.hasProjects);

  const events = newState.getState();

  return {
    ...state,
    events,
  };
};

export default setHasProjectsReduce;
