import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchFilters(requestParams: { subscriptionId: number }) {
  const request = http.get({
    url: RESTPath.subscriptions.getFilters(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchFilters;
