import { store } from '@redux/store';

import { StateBuilder } from './state';

export interface ActionData {
  id: string;
  commentary: string;
}

export interface Action {
  type: string;
  data: ActionData;
}

export const addCommentaryReduce = (state: ReturnType<typeof store.getState>, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.addCommentary({ id: action.data.id, commentary: action.data.commentary });

  return newState.getState();
};

export default addCommentaryReduce;
