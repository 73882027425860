import React from 'react';
import { HistoryProps as StoryProps } from '@pages/search/history/searchHistory';

import { List } from './list';

export interface SearchHistoryProps {
  data: StoryProps[],
  activeHistoryId: number
}

interface HistoryProps {
  onSelectHistoryItem?(data): (data) => void
  history: SearchHistoryProps
}

export class History extends React.Component<HistoryProps> {

  private title = 'История изменений';

  onRowClick = (id: number) => {
    const data = { id };
    this.props.onSelectHistoryItem(data);
  };

  render() {
    return (
      <List
        data={this.props.history.data}
        selectedId={this.props.history.activeHistoryId}
        title={this.title}
        onRowClick={this.onRowClick}
      />
    );
  }

}

export default History;
