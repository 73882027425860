import { dispatchRemovingStatus } from './dispatchRemovingStatus';
import { fetchHistory } from './fetchHistory';
import { httpRemoveHistoryItem } from './removeHistoryItem.http';

import { DateBlock } from '../components/filters/date/logic/date';
import { changePageNumber } from './changePageNumber';
import { changeTotalPages } from './changeTotalPages';

import { RequestParams } from './fetchHistory.http';

export class RemoveHistoryItemThunk {

  getState;

  private response;

  private requestParams: RequestParams;

  private dispatch;

  private id: number;

  constructor(dispatch, id) {
    this.dispatch = dispatch;
    this.id = id;
  }

  private dispatchRemovingStatusOfItem(id) {
    const data = {
      id,
    };

    this.dispatch(dispatchRemovingStatus.createAction(data));
  }

  private updateHistory(data) {
    const {
      history,
      page,
    } = data;

    this.dispatch(fetchHistory.createAction({ history }));

    const shouldChangePageNumber = data.total_pages < this.getState().search.history.currentPageNumber;
    if (shouldChangePageNumber) {
      this.dispatch(changePageNumber.createAction({ pageNumber: page }));
    }

    const shouldChangeTotalPages = data.total_pages !== this.getState().search.history.totalPages;
    if (shouldChangeTotalPages) {
      this.dispatch(changeTotalPages.createAction({ totalPages: data.total_pages }));
    }
  }

  public async invoke(page) {

    const state = this.getState();
    const filters = { ...state.search.history.filters };
    const dateFilter = new DateBlock();
    dateFilter.create(filters);
    const { scope, value } = dateFilter.undecorate().data;
    let pageNumber = page || state.search.history.currentPageNumber;

    const isLastItem = state.search.history.data.length === 1;
    if (isLastItem && pageNumber !== 1) {
      pageNumber -= 1;
    }

    this.requestParams = {
      page: pageNumber,
      scope,
      range: value,
    };

    this.dispatchRemovingStatusOfItem(this.id);

    try {
      this.response = await httpRemoveHistoryItem(this.id, this.requestParams);
      this.updateHistory(this.response);
    } catch (error) {
      throw Error(error.status);
    }
  }

}

export function removeHistoryItemThunk(dispatch, id, page?) {
  const thunk = new RemoveHistoryItemThunk(dispatch, id);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(page);
}
