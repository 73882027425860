import { connect } from 'react-redux';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { selectFolderThunk } from '@pages/regulation/sidebar/folders/redux/selectFolder.thunk';

import { Folder } from './folder';

const mapDispatchToProps = (dispatch) => ({
  onSelectItem(id) {
    sendAmplitudeData('regulation:calendar__go_to_folder', { id });
    Userpilot.track('regulation:calendar__go_to_folder');

    selectFolderThunk(dispatch, { id });
  },
});

export const FolderConnect = connect(
  null,
  mapDispatchToProps,
)(Folder);

export default FolderConnect;
