import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateCaseNumberReduce } from './updateCaseNumber.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/CASE_NUMBER/UPDATE`;

export interface Data {
  id: string;
  data: {
    value: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateCaseNumber = {
  actionType,
  createAction,
  reduce: updateCaseNumberReduce,
};

export default updateCaseNumber;
