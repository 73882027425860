import { fetchSettingsReduce } from './fetchSettings.reduce';

import type { ActionData } from './fetchSettings.reduce';

const actionType = 'SEARCH/RESULT/FETCH_DOCUMENTS_SETTINGS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchSettings = {
  actionType,
  createAction,
  reduce: fetchSettingsReduce,
};

export default fetchSettings;
