import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

interface CaseDTO {
  number: string;
  external: string;
}

interface JudgeDTO {
  judge_id: number;
  name: string;
}

interface ParticipantDTO {
  name: string;
}

export interface AttributesDTO {
  added_date: string;
  appeal_state: string;
  case: CaseDTO;
  case_type: string;
  category: string;
  comment: string;
  date: string;
  department: string;
  department_name_genitive: string;
  judges: JudgeDTO[];
  participants: {
    representatives: ParticipantDTO[];
    defendants: ParticipantDTO[];
    others: ParticipantDTO[];
    plaintiffs: ParticipantDTO[];
    third: ParticipantDTO[];
  };
  publish_date: string;
  resolution_date: string;
  result: string;
}

interface Participant {
  id: string;
  name: string;
}

export interface Attributes {
  date: string;
  resolutionDate: string;
  comment: string;
  case: {
    number: string;
    external: string;
  },
  caseType: string
  caseCategory: string;
  caseResult: string;
  department: string;
  judges: Participant[];
  appealState: string;
  representatives: Participant[];
  plaintiffs: Participant[];
  defendants: Participant[];
  third: Participant[];
  others: Participant[];
}

export class AttributesBuilder {

  static decorateParticipant(participants): Participant[] {
    const data = [];

    participants.forEach((p) => {
      if (p.name) {
        const participant = {
          id: p.id || uuid(),
          name: p.name,
        };

        data.push(participant);
      }
    });

    return data;
  }

  private data = {
    date: null,
    resolutionDate: null,
    case: {
      number: null,
      external: null,
    },
    caseType: null,
    caseCategory: null,
    caseResult: null,
    comment: '',
    department: null,
    representatives: [],
    judges: [],
    appealState: null,
    plaintiffs: [],
    defendants: [],
    third: [],
    others: [],
  };

  private dto: AttributesDTO = null;

  public createFromDTO(props: AttributesDTO) {
    this.dto = props;
  }

  public createFromModel(props: Attributes) {
    this.data = props;
  }

  public updateCommentary(comment) {
    if (comment) {
      this.data.comment = comment;
    }
  }

  private setCommentary() {
    if (this.dto.comment) {
      this.data.comment = this.dto.comment;
    }
  }

  private setDate() {
    if (this.dto.date) {
      this.data.date = this.dto.date;
    }
  }

  private setResolutionDate() {
    if (this.dto.resolution_date) {
      this.data.resolutionDate = this.dto.resolution_date;
    }
  }

  private setCaseNumber() {
    if (this.dto.case.number) {
      this.data.case.number = this.dto.case.number;
    }

    if (this.dto.case.external) {
      this.data.case.external = this.dto.case.external;
    }
  }

  private setCaseType() {
    if (this.dto.case_type) {
      this.data.caseType = this.dto.case_type;
    }
  }

  private setCaseCategory() {
    if (this.dto.category) {
      this.data.caseCategory = this.dto.category;
    }
  }

  private setCaseResult() {
    if (this.dto.result) {
      this.data.caseResult = this.dto.result;
    }
  }

  private setDepartment() {
    if (this.dto.department) {
      this.data.department = this.dto.department;
    }
  }

  private setJudges() {
    const hasJudge = this.dto.judges && !isEmpty(this.dto.judges);
    if (hasJudge) {
      this.data.judges = AttributesBuilder.decorateParticipant(this.dto.judges);
    }
  }

  private setAppealState() {
    if (this.dto.appeal_state) {
      this.data.appealState = this.dto.appeal_state;
    }
  }

  private setPlaintiffs() {
    const hasParticipants = this.dto.participants && !isEmpty(this.dto.participants.plaintiffs);
    if (hasParticipants) {
      this.data.plaintiffs = AttributesBuilder.decorateParticipant(this.dto.participants.plaintiffs);
    }
  }

  private setRepresentatives() {
    const hasRepresentatives = this.dto.participants && !isEmpty(this.dto.participants.representatives);
    if (hasRepresentatives) {
      this.data.representatives = AttributesBuilder.decorateParticipant(this.dto.participants.representatives);
    }
  }

  private setDefendants() {
    const hasParticipants = this.dto.participants && !isEmpty(this.dto.participants.defendants);
    if (hasParticipants) {
      this.data.defendants = AttributesBuilder.decorateParticipant(this.dto.participants.defendants);
    }
  }

  private setThird() {
    const hasParticipants = this.dto.participants && !isEmpty(this.dto.participants.third);
    if (hasParticipants) {
      this.data.third = AttributesBuilder.decorateParticipant(this.dto.participants.third);
    }
  }

  private setOthers() {
    const hasParticipants = this.dto.participants && !isEmpty(this.dto.participants.others);
    if (hasParticipants) {
      this.data.others = AttributesBuilder.decorateParticipant(this.dto.participants.others);
    }
  }

  public buildState() {
    this.setDate(); // Дата принятия
    this.setResolutionDate(); // Дата оглашения резолютивной части
    this.setCaseNumber(); // Номер дела
    this.setCaseType(); // Вид спора
    this.setCaseCategory(); // Категория спора
    this.setCommentary(); // Комментарий
    this.setDepartment(); // Принявший орган
    this.setJudges(); // Судьи
    this.setCaseResult(); // Исход рассотрения
    this.setAppealState(); // Статус обжалования
    this.setPlaintiffs(); // Истец кредитор
    this.setDefendants(); // Ответчик должник
    this.setThird(); // Третье лицо
    this.setOthers(); // Иное лицо
    this.setRepresentatives(); // Представители
  }

  public createState(state) {
    this.data = { ...state };
  }

  public getState(): Attributes {
    return this.data;
  }

}

export default AttributesBuilder;
