import { notifierManager } from '@components/notification';
import { processThunk as bookmarksProcessThunk } from '@layouts/bookmarks/redux/process.thunk';

import { processThunk } from '@providers/download/process.thunk';

import { Source } from '@providers/download/download.http';

import { DownloadCommentsThunk } from '@pages/regulation/workspace/comments/redux/downloadComments.thunk';

export enum Events {
  documentsDownloadProgress = 'documents:download:progress',
  bookmarksDownloadProgress = 'bookmarksFile:download:progress',
  projectsDownloadProgress = 'projects:download:progress',
  projectDownloadProgress = 'project:download:progress',
  commentsDownloadProgress = 'regulation:comments:download:progress',
  personalPackageCreated = 'personalPackage:created',
  regulationChatMention = 'regulation:chat:message:mention',
  regulationFarmiliarize = 'regulation:oject:farmiliarize',
}

export class CaselookSocketManager {

  private static instance: CaselookSocketManager;

  private dispatch;

  private strategies;

  private constructor(dispatch) {
    this.dispatch = dispatch;

    this.initStrategies();
  }

  static getInstance(dispatch) {
    if (!CaselookSocketManager.instance) {
      CaselookSocketManager.instance = new CaselookSocketManager(dispatch);
    }

    return CaselookSocketManager.instance;
  }

  public getStrategy(event: Events) {
    return this.strategies.get(event);
  }

  private initStrategies() {
    this.strategies = new Map();

    this.strategies.set(Events.documentsDownloadProgress, this.documentsDownloadProgressStrategy);
    this.strategies.set(Events.bookmarksDownloadProgress, this.bookmarksDownloadProgressStrategy);
    this.strategies.set(Events.projectDownloadProgress, this.projectDownloadProgressStrategy);
    this.strategies.set(Events.projectsDownloadProgress, this.projectsDownloadProgressStrategy);
    this.strategies.set(Events.commentsDownloadProgress, this.regulationDownloadCommentsStrategy);
    this.strategies.set(Events.personalPackageCreated, this.personalPackageCreatedStrategy);
    this.strategies.set(Events.regulationChatMention, this.regulationChatMentionStrategy);
    this.strategies.set(Events.regulationFarmiliarize, this.regulationFarmiliarizeStrategy);
  }

  private documentsDownloadProgressStrategy = (response) => processThunk(this.dispatch, response);

  private bookmarksDownloadProgressStrategy = (response) => bookmarksProcessThunk(this.dispatch, response);

  private projectDownloadProgressStrategy = (response) => {
    const data = {
      ...response,
      source: Source.regulationProject,
      url: response.link,
    };

    return processThunk(this.dispatch, data);
  };

  private projectsDownloadProgressStrategy = (response) => {
    const data = {
      ...response,
      source: Source.regulationFolder,
      url: response.link,
    };

    return processThunk(this.dispatch, data);
  };

  private personalPackageCreatedStrategy = (response) => {
    const url = `#/packages/${response.id}`;
    const linkTitle = 'ОТКРЫТЬ';
    notifierManager.done({ id: 11, message: 'Подготовлена подборка практики', url, linkTitle });

    return null;
  };

  private regulationChatMentionStrategy = (response) => {
    const id = 'REGULATION_CHAT_MENTION';
    const url = `#/regulation/project_preview/${response.projectId}`;
    const linkTitle = 'ОТКРЫТЬ';
    const message = `Вас упомянули в комментариях к проекту №${response.projectNumber}`;
    notifierManager.done({ id, message, url, linkTitle });

    return null;
  };

  private regulationFarmiliarizeStrategy = (response) => {
    const id = 'REGULATION_FARMILIARIZE';
    const url = `#/regulation/project_preview/${response.projectId}`;
    const linkTitle = 'ОТКРЫТЬ';
    const message = `${response.lastName} ${response.firstName} ознакомился с проектом НПА ${response.number}`;
    notifierManager.done({ id, message, url, linkTitle });

    return null;
  };

  private regulationDownloadCommentsStrategy = (response) => {
    const id = 'REGULATION_COMMENTS_DOWNLOAD';
    const url = response.link;
    const linkTitle = 'СКАЧАТЬ';
    const message = 'Комментарии подготовлены к скачиванию';
    notifierManager.done({ id, message, url, linkTitle });

    DownloadCommentsThunk.removeNotification();

    return null;
  };

}

export function caselookSocketManager(dispatch, response) {
  const manager = CaselookSocketManager.getInstance(dispatch);

  const strategy = manager.getStrategy(response.type);
  if (!strategy) {
    return;
  }

  strategy(response);
}

export default CaselookSocketManager;
