import React from 'react';

import { Icon } from '@components/icons';

import s from './arrow.style';

interface Props {
  disabled?: boolean,
  onClick?: () => void,
}

export const Arrow = ({ disabled, onClick }: Props) => (
  <s.Wrapper
    className="cl-arrow"
    isDisabled={disabled}
    onClick={onClick}
  >
    <Icon
      path="M16.6 9L12 13.6 7.4 9 6 10.4l6 6 6-6z"
      color="currentColor"
    />
  </s.Wrapper>
);

Arrow.defaultProps = {
  onClick: null,
  disabled: false,
};

export default Arrow;
