import { connect } from 'react-redux';

import { toggleSelectionRule } from '../../redux/documents/toggleSelectionRule';

import { Documents } from './documents';

const mapStateToProps = (state) => {
  const { activeItemId } = state.packages.packages;
  const {
    layout,
    loading,
    totalDocuments,
    pageLoading,
  } = state.packages.documents;

  return {
    id: activeItemId,
    isEmpty: !totalDocuments,
    layout,
    loading,
    pageLoading,
    processingStatus: state.packages.processingStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeSelectionRule() {
    dispatch(toggleSelectionRule.createAction());
  },
});

export const DocumentsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents);

export default DocumentsConnect;
