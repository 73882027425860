import styled, { css } from 'styled-components';

interface Props {
  color: string;
  size: number | string;
  isGrow: boolean;
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
`;

style.FilterBlock = styled.div`
  position: relative;

  display: inline-flex;
  width: 100%;
  height: 40px;

  margin: 0 8px;

  font-size: 13px;
  color: ${(props: Props) => props.theme.colors.defaultText};

  background: ${(props: Props) => props.theme.colors.white};

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    height: 1px;

    background: ${(props: Props) => props.theme.colors.globalLines};

    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 2;

    width: 4px;

    background: ${(props: Props) => props.color};
  }
`;

const UNIT = 80;

style.FilterItem = styled.div`
  position: relative;
  align-items: center;
  flex-shrink: 0;
  display: flex;
  min-width: 0%;
  ${(props: Props) => props.isGrow && css`
    flex: 1;
  `}

  flex-basis: ${(props: Props) => {
    if (typeof props.size === 'string') return props.size;

    return `${UNIT * props.size}px`;
  }};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    display: none;
    width: 1px;

    background: ${(props: Props) => props.theme.colors.globalLines};

    pointer-events: none;
  }

  &:not(:last-of-type) {
    &::after {
      display: block;
    }
  }
`;

export default style;
