import { ReducerBuilder } from '@redux/reducer.builder';

import {
  fetchCourtsStart,
  fetchCourtsSucceed,
} from './fetchCourts';

import { fetchJudges } from './fetchJudges';
import { fetchSettings } from './fetchSettings';
import { changePageNumber } from './changePageNumber';
import { changeSortColumn } from './changeSortColumn';
import { resetDocuments } from './resetDocuments';
import { updateTableSettings } from '../header/settingsPopover/redux/updateTableSettings';
import {
  updateView,
  updateViewReset,
} from '../header/editViewPopover/redux/updateView';

export const reducerBuilder = new ReducerBuilder([
  fetchCourtsStart,
  fetchCourtsSucceed,
  fetchJudges,
  fetchSettings,
  changePageNumber,
  changeSortColumn,
  resetDocuments,
  updateTableSettings,
  updateView,
  updateViewReset,
]);

export default reducerBuilder;
