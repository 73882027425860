import { SEARCH_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateDisputeCategoryReduce } from './updateDisputeCategory.reduce';

const actionType = `${SEARCH_FORM_BLOCK}/DISPUTE_CATEGORY/UPDATE`;

export interface Data {
  id: string;
  data: {
    id: string;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateDisputeCategory = {
  actionType,
  createAction,
  reduce: updateDisputeCategoryReduce,
};

export default updateDisputeCategory;
