import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';
import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

import { httpFetchPackageDocuments } from './fetchDocuments.http';

export class FetchPackageDocumentsThunk {

  getState;

  private notificationId = 'FETCH_PACKAGE_DOCUMENTS';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { sourceId: number, pageNumber?: number }) {
    const state = this.getState().packages;
    const sourceId = params.sourceId || state.packages.activeItemId;
    const pageNumber = params.pageNumber || state.documents.currentPageNumber;

    const hasPage = state.documents.pages[pageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber(pageNumber);

      return;
    }

    const requestParams = {
      sourceId,
      pageNumber,
      searchId: state.packages.searchId,
    };

    this.dispatch(pageLoadingStart.createAction());

    try {
      const result = await httpFetchPackageDocuments(requestParams);
      this.dispatchGetDocumentsSucceed(sourceId, pageNumber, result);
      this.dispatchChangePageNumber(pageNumber);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchGetDocumentsSucceed(sourceId, pageNumber, result) {
    const actionData = {
      sourceId,
      pageNumber,
      result,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchPackageDocumentsThunk(dispatch, params) {
  const thunk = new FetchPackageDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default fetchPackageDocumentsThunk;
