import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateRegion } from './redux/updateRegion';

import { RegionBlock } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateRegion(data) {
    dispatch(updateRegion.createAction(data));
  },
});

export const RegionBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegionBlock);

export default RegionBlockConnect;
