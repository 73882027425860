import {
  AppealStateBlock, AppealStateBlockData,
} from '../../form/filterBlocks/appealState/redux/appealState';

import { PresetsData } from './presets';

interface AppealStateBlockDataTransform {
  id: number
  presetsData: PresetsData
}

export abstract class AppealStateBlockHistory extends AppealStateBlock {

  static transform({ id }, presetsData: AppealStateBlockDataTransform): string {
    const presets = presetsData;

    const data = presets[this.type].reduce((acc: string, el: AppealStateBlockData) => {
      let result = acc;

      if (el.id === id) {
        result += el.title;
      }

      return result;
    }, '');

    return data;
  }

}

export default AppealStateBlockHistory;
