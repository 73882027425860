import {
  StateBuilder,
  DocumentId,
} from './state';

export interface ActionData {
  documentId: DocumentId;
}

interface Action {
  type: string;
  data: ActionData;
}

export const startAnnotationProgressReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.items);
  newState.addEmptyItem(action.data.documentId);
  newState.updateItemProgress(action.data.documentId, true);

  return newState.getState();
};

export default startAnnotationProgressReduce;
