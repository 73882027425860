import { connect } from 'react-redux';

import { Item } from './item';
import { toggleItemSelect } from '../redux/toggleItemSelect';
import { markIsVisitedFromLink } from '../redux/markIsVisitedFromLink';
import { SelectionRule } from '../redux/state';

export function isSelected(id, selectionState) {
  const isInclude = selectionState.rule === SelectionRule.include;

  if (isInclude) {
    return !!selectionState.ids.has(id);
  }

  return !selectionState.ids.has(id);
}

const mapStateToProps = (state, ownProps) => {
  const {
    currentPageNumber,
    pages,
    selection,
    showCommentary,
  } = state.searchResult.documents;

  const item = pages[currentPageNumber].itemsMap.get(ownProps.id);

  return {
    item,
    selected: isSelected(ownProps.id, selection),
    sourceId: state.searchResult.id,
    showCommentary,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onItemSelect(id) {
    dispatch(toggleItemSelect.createAction({ id }));
  },
  onMarkIsVisited(id) {
    dispatch(markIsVisitedFromLink.createAction({ id }));
  },
});

export const ItemConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);

export default ItemConnect;
