import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { notifierManager } from '@components/notification';
import { CreatePersonalPackageMenu } from './createPersonalPackageMenu';
import { httpCreatePersonalPackage } from './redux/createPersonalPackage.http';

const id = 1;

function removeNotification() {
  setTimeout(() => {
    notifierManager.remove({ id });
  }, 5000);
}

const mapDispatchToProps = () => ({
  onSend(message) {
    sendAmplitudeData('practice:createRequest');
    Userpilot.track('practice:createRequest');
    httpCreatePersonalPackage({ message })
      .then(() => {
        notifierManager.done({ id, message: 'Запрос на подбор практики отправлен' });
        removeNotification();
      })
      .catch((data) => {
        const tooManyRequestCode = 429;
        if (data.status === tooManyRequestCode) {
          notifierManager.fail({ id, message: 'Достигнут лимит запросов практики в этом месяце' });
        }

        removeNotification();
      });
  },
});

export const CreatePersonalPackageConnect = connect(
  null,
  mapDispatchToProps,
)(CreatePersonalPackageMenu);

export default CreatePersonalPackageConnect;
