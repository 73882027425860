import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { Broadcaster } from '@utils/broadcaster';

import { pageLoadingStart } from '@pages/bookmarks/redux/pageLoading';
import { fetchBookmarksThunk } from '@pages/bookmarks/redux/fetchBookmarks.thunk';

import { httpDeleteBookmark } from './deleteBookmark.http';
import { deleteBookmarkInBuffer } from './deleteBookmark';

interface Params {
  paragraphId: number;
}

export class DeleteBookmarkFromBufferThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    sendAmplitudeData('delete bookmark');
    Userpilot.track('delete bookmark');

    const state = this.getState();
    const { id } = state.bookmarksBuffer.queue.find((item) => item.paragraphId === params.paragraphId);

    const bookmarkInPage = Number(this.findBookmark(id));

    const { currentPageNumber } = state.bookmarks;

    const shouldReload = bookmarkInPage <= currentPageNumber;
    if (shouldReload) {
      this.dispatchPageLoading();
    }

    this.dispatchDeleteBookmarkInBuffer({ id });
    this.broadcastDeleteBookmark({ id });
    await this.deleteBookmarkRequest({ id });

    const nextLength = state.bookmarks.pages[currentPageNumber].items.length - 1;

    if (shouldReload && !nextLength) {
      await fetchBookmarksThunk(this.dispatch, bookmarkInPage - 1, shouldReload);

      return;
    }

    if (shouldReload) {
      await fetchBookmarksThunk(this.dispatch, currentPageNumber, shouldReload);
    }
  }

  private broadcastDeleteBookmark(params) {
    const broadcaster = Broadcaster.getInstance();

    const message = {
      type: deleteBookmarkInBuffer.actionType,
      data: params,
    };

    broadcaster.postMessage(message);
  }

  private async deleteBookmarkRequest(params) {
    try {
      await httpDeleteBookmark(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchPageLoading() {
    this.dispatch(pageLoadingStart.createAction());
  }

  private dispatchDeleteBookmarkInBuffer(params) {
    this.dispatch(deleteBookmarkInBuffer.createAction(params));
  }

  private findBookmark = (id: string) => {
    let page = null;
    const state = this.getState();
    const statePages = state.bookmarks.pages;
    const pages = Object.keys(statePages);
    const pagesLength = pages.length - 1;
    for (let i = 0; i <= pagesLength; i += 1) {
      const currentPage = pages[i];
      if (statePages[currentPage].itemsMap.get(id)) {
        page = currentPage;
      }
    }

    return page;
  };

}

export function deleteBookmarkFromBufferThunk(dispatch, params) {
  const thunk = new DeleteBookmarkFromBufferThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default DeleteBookmarkFromBufferThunk;
