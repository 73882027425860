import http from '@http';

import {
  Id, RESTPath,
} from '@router/path.rest';

export function httpUnpublishCompanyPackage(packageId: Id) {
  const request = http.post({
    url: RESTPath.companyPackage.unpublish,
    data: {
      packageId,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpUnpublishCompanyPackage;
