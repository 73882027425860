import { StateBuilder } from './state';

export type ActionData = {
  loading: boolean;
};

export interface Action {
  type: string;
  data: ActionData;
}

export const setLoadingReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.users);
  newState.setLoading(action.data.loading);

  return {
    ...state,
    users: newState.getState(),
  };
};

export default setLoadingReduce;
