import { saveFirstnameReduce } from './saveFirstname.reduce';

import type { ActionData } from './saveFirstname.reduce';

const actionType = 'USER/SAVE_FIRSTNAME';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const saveFirstname = {
  actionType,
  createAction,
  reduce: saveFirstnameReduce,
};

export default saveFirstname;
