export const subscriptionPath = `M16.0700005,2.85995507 C16.5744724,3.08473619 16.8012066,3.67591256
16.5764255,4.18038446 C16.3516444,4.68485635 15.760468,4.91159053 15.2559961,4.6868094 C12.0197671,
3.24481988 8.22054621,4.10320964 5.91861681,6.79648297 C3.61668471,9.48975945 3.36040673,13.3763399
 5.28876079,16.3485625 C7.21711276,19.3207819 10.870726,20.6706126 14.2682782,19.6660555 C17.5787159
 ,18.6872556 19.8776769,15.7004418 19.9952696,12.2710732 L20,11.9994426 L20,11.1714365 C20,10.6191518
  20.4477153,10.1714365 21,10.1714365 C21.5128358,10.1714365 21.9355072,10.5574767 21.9932723,11.0548154
   L22,11.1714365 L22,12.0000144 C21.9974677,16.4287278 19.0822948,20.3282809 14.8353521,21.5839781
   C10.5884071,22.8396759 6.0213866,21.1523861 3.61094798,17.437114 C1.20051145,13.721845 1.52085793,
   8.8636345 4.39826681,5.49704621 C7.27567838,2.13045476 12.0247101,1.05746629 16.0700005,2.85995507
    Z M20.706665,5.29245169 C21.067374,5.65271042 21.0954579,6.21992405 20.7906781,6.6124057 L20.7075483,
    6.70666498 L12.7075483,14.716665 C12.3471305,15.0775334 11.7796142,15.105461 11.3871431,14.8003287
    L11.2928932,14.7171068 L8.29289322,11.7171068 C7.90236893,11.3265825 7.90236893,10.6934175 8.29289322,
    10.3028932 C8.65337718,9.94240926 9.22060824,9.91467972 9.61289944,10.2197046 L9.70710678,10.3028932
    L12,12.595 L19.2924517,5.29333502 C19.682732,4.90256688 20.3158968,4.9021714 20.706665,5.29245169 Z`;

export default subscriptionPath;
