import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpRecovery(data: { email: string }) {
  const request = http.post({
    url: RESTPath.user.recovery,
    data,
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpRecovery;
