import React from 'react';
import { AddTagConnect } from './addTag.connect';

import list from './list.style';

import s from './cellTag.style';

interface CellMoreProps {
  id: number;
}

export const CellTag = (props: CellMoreProps) => (
  <s.CellTag>
    <list.BodyCellContainer>
      <AddTagConnect
        documentId={props.id}
      />
    </list.BodyCellContainer>
  </s.CellTag>
);

export default CellTag;
