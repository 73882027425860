import React from 'react';

import { SimilarItemPromoConnect } from './similarItemPromo.connect';

import s from './similarListPromo.style';

interface SimilarListPromoProps {
  source: string;
  sourceId: number;
  documentId: number | string;
  currentPageNumber: number;
  items: number[];
}

export class SimilarListPromo extends React.PureComponent<SimilarListPromoProps> {

  title = 'Похожие документы';

  renderItem = (item) => (
    <SimilarItemPromoConnect
      key={item}
      source={this.props.source}
      sourceId={this.props.sourceId}
      currentPageNumber={this.props.currentPageNumber}
      similarDocumentId={item}
      documentId={this.props.documentId}
    />
  );

  render() {
    if (!this.props.items.length) {
      return null;
    }

    return (
      <>
        <s.Divider />
        <s.Root>
          <s.Title>
            {this.title}
          </s.Title>
          <s.List>
            {this.props.items.map(this.renderItem)}
          </s.List>
        </s.Root>
      </>
    );
  }

}

export default SimilarListPromo;
