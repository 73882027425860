/* eslint-disable */

import React from 'react';

import { iconsPath } from '@components/icons';

import { Loader } from '@components/loader';

import { BlockConnect as Block } from './block.connect';

import { TooltipRelationWithClickConnect } from './components/tooltipRelations.connect';
import { DocumentMarkersConnect } from './documentMarkers.connect';
import { SimilarConnect } from './similar.connect';

import { AnnotationConnect } from './annotation.connect';
import { SummaryConnect } from './summary.connect';

import s from './workspace.style';

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export const _DOCUMENT_VIEW_CLASS = 'document-view';
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export const _HIGHLIGHT_CLASS = 'g-highlight';
export const halfTooltipWidth = 230;

/**
 * удаляет обрамляющий тег: `<p> <span> foo </span> bar</p>` ->  `<span> foo </span bar`
 * @param dom
 */
function unwrap(dom) {
  const nodes = Array.prototype.slice.call(dom.childNodes);
  let wrapper;

  nodes.forEach((node) => {
    wrapper = node.parentNode;
    dom.parentNode.insertBefore(node, dom);
    wrapper.parentNode.removeChild(wrapper);
    wrapper = null;
  });
}

interface Props {
  body: any;
  isReal: boolean;
}

export class Workspace extends React.PureComponent<Props> {

  state = {
    tooltipIdRelation: '',
    tooltipAlign: 'center',
    tooltipOffsets: {},
    tooltipData: {},
    tooltipShow: false,
    height: 'auto',
  };

  // удаляет теги выделений из DOM под-дерева, содержающее текст документа
  // (иначе будет конфликт с маркерными выделениями при добавлении документа в подборку:
  // в подборке поисковой подсветки уже не будет и макерные выделения разввлятся,
  // т.к. они рендерятся по пути в dom дереве)
  reRenderSearchHighlight() {
    setTimeout(() => {
      const documentSections = document.querySelectorAll(`section.${_DOCUMENT_VIEW_CLASS}`);
      let searchHighlights;
      let documentWrapper;
      let articles;
      let cloneText = '';
      let clArticleTmp;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < documentSections.length; ++i) {
        documentWrapper = documentSections[i].querySelector(`.${_DOCUMENT_VIEW_CLASS}__articles`);
        searchHighlights = documentWrapper ?
          // eslint-disable-next-line max-len
          documentWrapper.querySelectorAll(`:not(.${_DOCUMENT_VIEW_CLASS}__clone-articles)>div>.article span.${_HIGHLIGHT_CLASS}`) :
          [];

        if (documentWrapper && documentWrapper.firstElementChild) {
          cloneText = documentWrapper.firstElementChild.cloneNode(true);
        }

        clArticleTmp = documentSections[i].getElementsByClassName(`${_DOCUMENT_VIEW_CLASS}__clone-articles`)[0];
        if (clArticleTmp && clArticleTmp.children.length === 0) {
          clArticleTmp.appendChild(cloneText);
        }

        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < searchHighlights.length; ++j) {
          unwrap(searchHighlights[j]);
        }

        if (documentWrapper && documentWrapper.firstElementChild) {
          articles = documentWrapper.firstElementChild.childNodes;
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < articles.length; ++k) {
            articles[k].normalize();
          }
        }
      }
    }, 0);
  }

  handleOnClick = (event) => {
    event.stopPropagation();

    if (!this.props.isReal) {
      event.preventDefault();
      return;
    }

    const tagNameInLowerCase = event.target.tagName.toLowerCase();
    const elementAttribute = event.target.getAttribute('data-id-relation');
    const parentElementAttribute = event.target.parentNode.getAttribute('data-id-relation');
    const isRelationTag =
      // eslint-disable-next-line no-mixed-operators
      tagNameInLowerCase === 'a' && elementAttribute ||
      // eslint-disable-next-line no-mixed-operators
      tagNameInLowerCase === 'mark' && parentElementAttribute;

    const DomRect = event.target.getBoundingClientRect();
    // eslint-disable-next-line max-len
    const isSmallWidthWindow = window.innerWidth - (halfTooltipWidth + DomRect.left + DomRect.width / 2) < halfTooltipWidth;

    if (!isRelationTag) {
      return;
    }

    event.preventDefault();
    const relationId = elementAttribute || parentElementAttribute;
    const tooltipOffsets = {
      top: DomRect.top,
      left: isSmallWidthWindow ? DomRect.right : DomRect.left + DomRect.width / 2,
    };

    if (!this.state.tooltipShow) {
      this.setState({
        tooltipIdRelation: relationId,
        tooltipAlign: isSmallWidthWindow ? 'left' : 'center',
        tooltipOffsets: { ...tooltipOffsets },
        tooltipShow: true,
      });
    } else {
      this.setState({
        tooltipIdRelation: '',
        tooltipAlign: 'center',
        tooltipOffsets: {},
        tooltipData: {},
        tooltipShow: false,
      });
    }
  };

  hideTooltip = () => {
    if (this.state.tooltipShow) {
      this.setState({
        tooltipIdRelation: '',
        tooltipAlign: 'center',
        tooltipOffsets: {},
        tooltipData: {},
        tooltipShow: false,
      });
    }
  };

  renderTooltipRelationContainer() {
    if (!this.state.tooltipShow) {
      return null;
    }

    return (
      <TooltipRelationWithClickConnect
        tooltipIdRelation={this.state.tooltipIdRelation}
        tooltipAlign={this.state.tooltipAlign}
        tooltipOffsets={this.state.tooltipOffsets}
        tooltipData={this.state.tooltipData}
        onClickOutside={this.hideTooltip}
        {...this.props}
      />
    );
  }

  renderBody(blocks) {
    return (
      <div className={`${_DOCUMENT_VIEW_CLASS}__articles`}>
        <div>
          {
            (blocks || []).map((block) => (<Block key={block.id} block={block} {...this.props} />))
          }
        </div>
        <div className={`${_DOCUMENT_VIEW_CLASS}__clone-articles`} />
      </div>
    );

  }

  renderCrest() {
    const iconPath = iconsPath.get('crest');

    return (
      <s.Crest>
        <svg
          viewBox="0 0 72 72"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={iconPath} />
        </svg>
      </s.Crest>
    );
  }

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderHeader() {
    // @ts-ignore
    if (this.props.isPractice) {
      // @ts-ignore
      return <s.Header>{this.props.department}</s.Header>;
    }

    return <s.Header>Российская Федерация</s.Header>;
  }

  renderTitle() {
    // @ts-ignore
    if (!this.props.body.title) {
      return null;
    }

    // @ts-ignore
    return <s.Title>{this.props.body.title}</s.Title>;
  }

  renderSimilar() {
    if (!this.props.isReal) {
      return null;
    }

    return <SimilarConnect />;
  }

  render() {

    // @ts-ignore
    if (this.props.loading) {
      return this.renderLoader();
    }

    // @ts-ignore
    if (this.props.body.blocks && this.props.body.blocks.length > 0) {
      this.reRenderSearchHighlight();
    }

    return (
      <s.Root
        id="workspace"
      >
        <AnnotationConnect />
        <SummaryConnect />
        <DocumentMarkersConnect
          // @ts-ignore
          onSaveMarkers={this.props.onSaveMarkers}
          // @ts-ignore
          isAuthorPackage={this.props.isAuthorPackage}
        />
        <div onClick={this.handleOnClick}>
          {this.renderTooltipRelationContainer()}
          <section className={_DOCUMENT_VIEW_CLASS} key="body">
            <div className={`${_DOCUMENT_VIEW_CLASS}__meta`}>
              {this.renderCrest()}
              {this.renderHeader()}
              {this.renderTitle()}
            </div>
            {this.renderBody(this.props.body.blocks)}
          </section>
          {
            // @ts-ignore
            this.props.additions.map((addition) => (
              <section
                className={_DOCUMENT_VIEW_CLASS}
                key={addition.id}
                style={{
                  height: 'auto',
                }}
              >
                <div className={`${_DOCUMENT_VIEW_CLASS}__meta`}>
                  <h5 className={`${_DOCUMENT_VIEW_CLASS}__addition__title`}>{addition.title}</h5>
                  <h4 className={`${_DOCUMENT_VIEW_CLASS}__addition__description`}>{addition.description}</h4>
                </div>
                {this.renderBody(addition.blocks)}
              </section>
            ))
          }
          {this.renderSimilar()}
        </div>
      </s.Root>
    );
  }

}

export default Workspace;
