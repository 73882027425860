import { Dispatch } from 'redux';

import { toggleNotification } from './toggleNotification';

import { httpUpdateSubscription } from '../../sidebar/redux/updateSubscription.http';

export class ToggleNotificationThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params?: { id: number }) {
    const { activeItemId } = this.getState().subscriptions.subscriptions;
    const id = params && params.id ? params.id : activeItemId;

    const item = this.getState().subscriptions.subscriptions.itemsMap.get(id);

    const requestParams = {
      id,
      data: {
        title: item.title,
        is_notify: !item.hasNotification,
      },
    };

    this.dispatchToggleNotification(requestParams.id);

    try {
      await this.httpToggleNotification(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private hasNotification(id: string) {
    const { itemsMap } = this.getState().subscriptions.subscriptions;

    const item = itemsMap.get(id);

    return (item.hasNotification);
  }

  private dispatchToggleNotification(id) {
    const actionData = {
      id,
    };

    this.dispatch(toggleNotification.createAction(actionData));
  }

  private async httpToggleNotification(requestParams) {
    await httpUpdateSubscription(requestParams);
  }

}

export function toggleNotificationThunk(dispatch, params?) {
  const thunk = new ToggleNotificationThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default ToggleNotificationThunk;
