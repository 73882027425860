import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

style.IconBlock = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

style.Icon = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.IconTitle = styled.div`
  padding-top: 16px;
  color: ${(props) => props.theme.colors.stone};
`;

export default style;
