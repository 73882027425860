import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  flex-direction: row;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  height: 56px;
  padding: 0 8px;
  line-height: 30px;

  background-color: ${(props: Props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Menu = styled.div`
  flex-grow: 0;
`;

style.Breadcrumbs = styled.div`
  margin-left: 4px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font: 17px Roboto-Medium, sans-serif;
  font-weight: 500;
`;

style.Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

style.Action = styled.div`
  margin-left: 8px;
`;

export default style;
