import { connect } from 'react-redux';

import {
  Title,
  PhaseBlock,
} from '@components/filters/phase/logic';

import { Block } from '@components/filters/components/block';

import { updatePhaseThunk } from './redux/updatePhase.thunk';

import { FilterType } from './redux/state';

import {
  buildTitle,
  buildCounter,
} from './utils';

const mapStateToProps = (state) => {
  const { data } = state.regulation.filters.filters[FilterType.phase];
  const selectAllTitle = 'Все стадии';

  return {
    title: buildTitle(data, Title.default),
    selectAllTitle,
    counter: buildCounter(data),
    isDefault: !data.length,
    data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdate(data: any) {
    updatePhaseThunk(dispatch, data);
  },
  onFetchData(query, page): Promise<any> {
    const block = new PhaseBlock();

    const result = block.getItemsForSuggest(query, page);

    return result;
  },
});

export const PhaseConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default PhaseConnect;
