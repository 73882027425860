import React from 'react';

import {
  SaveButton,
  ResetButton,
} from '@components/buttons';

import { Header } from '@components/popoverHeader';

import {
  Item,
  ItemTitle,
  ItemProps,
} from '@components/menu/item';

import {
  Value as ChangeValue,
  Input,
} from '../components/input';

import s from './updatePackageName.style';

export type PackageName = string;

interface StateItems {
  packageName: PackageName
}

interface ContentItem extends ItemProps {
  key: string
}

interface RenameSettingsProps {
  onGoBack: () => void
  onSavePackageName: (name: PackageName) => void
  packageName: PackageName
}

export class UpdatePackageName extends React.PureComponent<RenameSettingsProps, StateItems> {

  state = {
    packageName: this.props.packageName,
  };

  setPackageName = (packageName: PackageName) => {
    this.setState({
      packageName,
    });
  };

  handleGoBack = () => {
    this.props.onGoBack();
  };

  handleSavePackageName = () => {
    const oldPackageNameLength = this.state.packageName.trim().length;
    const shouldUploadPackageName = oldPackageNameLength > 0 && oldPackageNameLength < 81;

    if (!shouldUploadPackageName) {
      return;
    }

    this.props.onSavePackageName(this.state.packageName);
    this.props.onGoBack();
  };

  handleInputChange = (value: ChangeValue): void => {
    this.setPackageName(value);
  };

  renderHeader() {
    const title = 'Переименовать подборку';

    return (
      <Header
        onGoBack={this.handleGoBack}
        title={title}
      />
    );
  }

  renderItem = (item: ContentItem) => (
    <Item
      key={item.key}
      value={item.value}
    />
  );

  renderContent() {
    const title = 'Название подборки';
    const contentData = [
      {
        key: 'title',
        value: <ItemTitle title={title} />,
      },
      {
        key: 'input',
        value: <Input
          textContent={this.state.packageName}
          onChange={this.handleInputChange}
        />,
      },
    ];

    return contentData.map(this.renderItem);
  }

  renderResetButton() {
    return (
      <ResetButton
        onClick={this.handleGoBack}
      />
    );
  }

  renderSaveButton() {
    const title = 'Применить';

    return (
      <SaveButton
        onClick={this.handleSavePackageName}
        title={title}
      />
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        <s.List>
          {this.renderContent()}
        </s.List>
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Container>
    );
  }

}

export default UpdatePackageName;
