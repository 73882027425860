import http from '@http';

import { httpPasswordReset } from '../http/passwordReset.http';
import { httpPasswordResetCodeVerification } from '../http/passwordResetCodeVerification.http';

export interface PasswordResetParams {
  password: string;
  repeatPassword: string;
  code: string;
}

export interface CodeParams {
  code: string;
}

export class CreateNewPasswordThunk {

  async invoke(params: PasswordResetParams) {
    const password = params.password.trim();
    const repeatPassword = params.repeatPassword.trim();
    this.validate(password, repeatPassword);

    const response = await this.httpPasswordReset(params);

    return response;
  }

  async verifyCode(params: CodeParams) {
    let result;

    // eslint-disable-next-line no-useless-catch
    try {
      result = await httpPasswordResetCodeVerification(params);
    } catch (error) {

      throw error;
    }

    return result;
  }

  async httpPasswordReset(data) {
    const request = {
      password: data.password,
      retype_password: data.repeatPassword,
      code: data.code,
    };

    let result;
    try {
      result = await httpPasswordReset(request);
    } catch (error) {
      if ([400, 423, 422].includes(error.status)) {
        throw await http.parseResponse(error);
      }
    }

    return result;
  }

  validate(password, repeatPassword) {
    const errors = {
      password: [],
    };

    const isEqual = password === repeatPassword;

    const isEmpty = !password && !repeatPassword;

    if (!isEqual) {
      errors.password = ['Введенные пароли не совпадают'];
    }

    if (isEmpty) {
      errors.password = ['Поля не могут быть пустыми'];
    }

    const hasErrors = !!errors.password.length;
    if (hasErrors) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw {
        errors,
      };
    }
  }

}

export default CreateNewPasswordThunk;
