import { DateBlock } from '@components/filters/date/logic/date';
import { Id } from './item';
import { StateBuilder } from './state';

import {
  StateBuilder as FilterStateBuilder,
  FilterType,
} from '../../../workspace/toolbar/redux/state';

export interface ActionData {
  id: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const saveFiltersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const id = action.data.id;

  const filters = state.filters;

  const newFilters = new FilterStateBuilder(filters);
  const date = new DateBlock();
  date.create(filters.filters.date);
  const newDate = date.undecorate();
  newFilters.updateFilter(FilterType.date, newDate);

  const filtersState = newFilters.getState().filters;

  const newState = new StateBuilder(state.smartFolders);
  newState.updateItemFilters({ id, filters: filtersState });
  const smartFolders = newState.getState();

  return {
    ...state,
    smartFolders,
  };
};

export default saveFiltersReduce;
