import styled from 'styled-components';

export interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  overflow-y: auto;
`;

style.ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;

  display: table;
  padding: 16px 64px 64px 64px;
  overflow-x: auto;
  width: 100%;
`;

style.Notification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    color: ${(props: Props) => props.theme.colors.stone};
  }
`;

style.Title = styled.div`
  font-size: 13px;
  margin-top: 11px;
  color: ${(props: Props) => props.theme.colors.stone};
`;

export default style;
