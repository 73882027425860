import {
  styled,
  css,
} from '@components/styled';

import {
  hover,
  iconButton,
} from '@components/button/button.style';

import iconDragStyle from '@components/iconDrag/iconDrag.style';

import item from './layoutSettings.style';

const style: any = {};

interface Props {
  theme: any;
  isFirst: boolean;
  disabled: boolean;
}

style.List = styled.div`
  background: white;
  padding: 8px 0;
`;

style.Items = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

style.DragIcon = styled.div`
  position: absolute;
  top: 4px;
  left: -3px;
  ${iconDragStyle.Root} {
    color: #6c7b89;
  }
`;

style.Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 320px;

  ${(props: Props) => props.isFirst && css`
    border: none;
  `}
`;

style.Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 48px;
  padding: 0 8px;

  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Back = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;

  border-radius: 2px;

  cursor: pointer;

  ${iconButton};
  ${hover};

  i {
    color: ${(props: Props) => props.theme.colors.blue};
  }
`;

style.HeaderTitle = styled.div`
  font-family: Roboto-Medium;
  font-size: 13px;

  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

style.Reset = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;

  ${iconButton};
  ${hover};

  ${(props: Props) => props.disabled && css`
    user-select: none;
    cursor: default;
    color: ${props.theme.colors.disabled};
  `}
`;

style.Actions = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  height: 48px;
`;

style.Item = styled(item.Item)`
  height: 32px;
  padding: 0 0 0 12px;
  justify-content: start;

  ${iconDragStyle.Root} {
    opacity: 0;
  }

  &:hover ${iconDragStyle.Root} {
    opacity: 1;
  }
`;

style.Checkbox = styled.div`
  align-items: center;
`;

style.ItemTitle = styled.div`
  padding-left: 8px;
  font-size: 13px;
`;

export default style;
