import { connect } from 'react-redux';

import { fetchFiltersThunk } from './redux/fetchFilters.thunk';

import { SmartBlock } from './block';

const mapStateToProps = (state) => ({
  value: state.search.query.smartFilter.query,
  loading: state.search.query.smartFilter.preparing,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchFilters(value: string) {
    const params = {
      value,
    };

    fetchFiltersThunk(dispatch, params);
  },
});

export const SmartBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartBlock);

export default SmartBlockConnect;
