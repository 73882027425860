import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { FetchSettingsThunk } from './redux/fetchSettings.thunk';
import { FetchInitialSearchDisputeCategoryThunk } from './redux/fetchInitialDisputeCategory.thunk';

import { DisputeCategory } from './disputeCategory';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSettingsThunk(dispatch);
    thunk.invoke();
  },
  onFetchData() {
    const thunk = new FetchInitialSearchDisputeCategoryThunk(dispatch);

    const { sourceId } = getParamsFromUrl(ownProps);
    thunk.invoke(sourceId);

    return thunk;
  },
});

export const DisputeCategoryConnect = connect(
  null,
  mapDispatchToProps,
)(DisputeCategory);

export default DisputeCategoryConnect;
