import styled from 'styled-components';

import {
  hover,
  iconButton,
} from '@components/button/button.style';

const style: any = {};

interface Props {
  theme: any;
}

style.Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 48px;
  padding: 0 8px;

  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.Back = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;

  position: absolute;

  cursor: pointer;

  ${iconButton};
  ${hover};

  i {
    color: ${(props: Props) => props.theme.colors.blue};
  }
`;

style.HeaderTitle = styled.div`
  font-family: Roboto-Medium;
  font-size: 13px;

  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export default style;
