import { getQuery } from './fetchDefaultFilters.reduce';

export type ActionData = any[];

interface Action {
  type: string;
  data: ActionData;
}

export const fetchPinnedFiltersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  if (!action.data.length) {
    return {
      ...state,
      query: {
        ...state.defaultQuery,
      },
    };
  }

  const query = getQuery(action.data, state, true);
  const defaultQuery = getQuery(action.data, state, true);

  return {
    ...state,
    query: {
      ...state.query,
      ...query,
    },
    defaultQuery,
  };
};

export default fetchPinnedFiltersReduce;
