import { connect } from 'react-redux';

import { HeaderCassette } from './headerCassette';

export function createName(state): string {
  const {
    firstName,
    lastName,
  } = state.currentUser;

  let text = '';

  if (firstName) {
    text = `${firstName} `;
  }

  if (lastName) {
    text = `${text}${lastName}`;
  }

  return text;
}

export function createPosition(state): string {
  const {
    position,
    company,
  } = state.currentUser;

  if (company && position) {
    return `${position} в ${company}`;
  }

  if (!!company && !position) {
    return company;
  }

  if (!company && !!position) {
    return position;
  }

  return null;
}

const mapStateToProps = (state) => ({
  avatar: state.currentUser.avatar,
  logo: state.currentUser.logo,
  name: createName(state),
  position: createPosition(state),
});

export const HeaderCassetteConnect = connect(
  mapStateToProps,
)(HeaderCassette);

export default HeaderCassetteConnect;
