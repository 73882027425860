import {
  StateBuilder,
  Layout,
} from './state';

export interface ActionData {
  layout: Layout;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchLayoutSettingsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.setSettingsLoading(false);
  newState.setSettings(action.data.layout);
  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default fetchLayoutSettingsReduce;
