import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

style.Header = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 12px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.HeaderAction = styled.div`
  width: 24px;
  height: 24px;
`;

style.HeaderTitle = styled.div`
  font: 17px Roboto-Medium;
  margin-left: 8px;
`;

style.Payment = styled.div`
  display: flex;
  flex-direction: row;
  width: 896px;
  justify-content: space-evenly;
`;

style.Ribbon = styled.div`
  width: 115px;
  height: 84px;
  overflow: hidden;
  position: absolute;
  top: -8px;
  right: -7px;

  ::before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #d9a52b;
    top: 2px;
    left: 31px;
    transform: rotate(-45deg);
  }

  ::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #d9a52b;
    bottom: 0px;
    right: 2px;
    transform: rotate(-45deg);
  }
`;

style.RibbonTitle = styled.span`
  position: absolute;
  display: block;
  width: 167px;
  padding: 6px 0;
  background-color: #ffbe25;
  color: #fff;
  font: 700 11px/1 'Lato',sans-serif;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  text-align: center;
  left: 1px;
  top: 22px;
  transform: rotate(45deg);
  box-shadow: 0 1px 5px rgba(0,0,0,.1);
`;

style.Item = styled.div`
  position: relative;
  max-width: 600px;
  width: 256px;
  height: 360px;
  background: #fff;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 2px;
  padding: 32px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.ItemTitle = styled.div`
  font: 17px Roboto-Medium;
`;

style.ItemStars = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 27px;
`;

style.ItemCost = styled.div`
  font: 40px Roboto-Bold;
  margin-top: 26px;
  letter-spacing: 2px;
`;

style.ItemDescription = styled.div`
  font: 11px Roboto-Regular;
  color: ${(props) => props.theme.colors.stone};
  margin-top: 20px;
  text-align: center;
  display: flex;
  height: 100%;

  line-height: 16px;
  white-space: break-spaces;
`;

style.ItemAction = styled.div`
  width: 100%;
`;

style.Icon = styled.div`
  width: 32px;
  height: 32px;
`;

export default style;
