import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchRelation(requestParams) {
  const request = http.get({
    url: RESTPath.document.getRelation(requestParams),
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpFetchRelation;
