const actionType = 'PACKAGES/SAVE_DOCUMENTS_SETTINGS';

const createAction = () => ({
  type: actionType,
});

export const savePackagesDocumentsSettingsSucceeded = {
  actionType,
  createAction,
  reduce: () => {},
};

export default savePackagesDocumentsSettingsSucceeded;
