import { Dispatch } from 'redux';

import { httpFetchSummaryRating } from './fetchSummaryRating.http';
import { httpFetchSummary } from './fetchSummary.http';

import { addSummary } from './addSummary';
import { createSummary } from './createSummary';
import { updateFetchError } from './updateFetchError';

export interface RequestParams {
  documentId: number;
}

export class FetchSummaryThunk {

  getState;

  private readonly dispatch: Dispatch;

  private requestParams: RequestParams;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {

    if (!params.documentId) {
      return;
    }

    const state = this.getState().summary;
    const summary = state.items.get(params.documentId);

    if (!summary) {
      this.dispatchCreate(params.documentId);
    }

    this.requestParams = {
      documentId: params.documentId,
    };

    this.getSummary();
  }

  private async getSummary() {
    const summary = await this.fetchSummary();
    const { value } = await this.fetchRating();

    this.dispatchFetchSucceed({ summary, rating: value, documentId: this.requestParams.documentId });
  }

  private async fetchSummary() {
    try {
      const result = await httpFetchSummary(this.requestParams);

      return result;
    } catch (error) {
      this.dispatchFetchError(this.requestParams.documentId);
      throw Error(error.status);
    }
  }

  private async fetchRating() {
    try {
      const result = await httpFetchSummaryRating(this.requestParams);

      return result;
    } catch (error) {
      this.dispatchFetchError(this.requestParams.documentId);
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(addSummary.createAction(result));
  }

  private dispatchFetchError(id) {
    this.dispatch(updateFetchError.createAction({ id }));
  }

  private dispatchCreate(id) {
    this.dispatch(createSummary.createAction({ id }));
  }

}

export async function fetchSummaryThunk(dispatch, params) {
  const thunk = new FetchSummaryThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default FetchSummaryThunk;
