import { connect } from 'react-redux';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { resetState } from './redux/resetState';
import { fetchCalendarsThunk } from './redux/fetchCalendars.thunk';

import { Calendar } from './calendar';

const mapStateToProps = (state) => ({
  calendarsLoading: state.regulation.events.calendarsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchCalendars() {
    sendAmplitudeData('regulation:calendar__open_layout');
    Userpilot.track('regulation:calendar__open_layout');
    fetchCalendarsThunk(dispatch);
  },
  onReset() {
    dispatch(resetState.createAction());
  },
});

export const CalendarConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calendar);

export default CalendarConnect;
