import { Dispatch } from 'redux';

import { Id } from '@pages/regulation/workspace/redux/state';

import { fetchMessages } from '@pages/regulation/redux/chat/fetchMessages';

import { resetToDefault } from '@pages/regulation/redux/chat/resetToDefault';

import {
  httpFetchMessages,
  ChatDTO,
} from '@pages/regulation/redux/chat/fetchMessages.http';

import { createEmptyProject } from './createEmptyProject';

import {
  httpFetchProject,
  ProjectDTO,
} from './fetchProject.http';
import { fetchProject } from './fetchProject';

import {
  httpFetchNote,
  NoteDTO,
} from './fetchNote.http';
import { fetchNote } from './fetchNote';

import {
  httpFetchPhases,
  PhasesDTO,
} from './fetchPhases.http';
import { fetchPhases } from './fetchPhases';

import {
  httpFetchAttributes,
  AttributesDTO,
} from './fetchAttributes.http';
import { fetchAttributes } from './fetchAttributes';

export class FetchProjectPreviewThunk {

  getState;

  private readonly dispatch: Dispatch;

  private id: Id = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(param: { id: Id }) {
    this.id = param.id;

    this.dispatchCreateEmptyProject();

    this.dispatchChatResetToDefault();
    const chat = await this.fetchChat();
    this.dispatchFetchChatSucceed(chat);

    const project = await this.fetchProject();
    this.dispatchFetchProjectSucceed(project);

    const note = await this.fetchNote();
    this.dispatchFetchNoteSucceed(note);

    const phases = await this.fetchPhases();
    this.dispatchFetchPhasesSucceed(phases);

    const attributes = await this.fetchAttributes();
    this.dispatchFetchAttributesSucceed(attributes);
  }

  private async fetchProject(): Promise<ProjectDTO> {
    try {
      const result = await httpFetchProject(this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchNote(): Promise<NoteDTO> {
    try {
      const result = await httpFetchNote(this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchPhases(): Promise<PhasesDTO> {
    try {
      const result = await httpFetchPhases(this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchAttributes(): Promise<AttributesDTO> {
    try {
      const result = await httpFetchAttributes(this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchChat(): Promise<ChatDTO> {
    try {
      const result = await httpFetchMessages(this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchCreateEmptyProject() {
    const result = {
      id: this.id,
    };

    this.dispatch(createEmptyProject.createAction(result));
  }

  private dispatchFetchProjectSucceed(data: ProjectDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchProject.createAction(result));
  }

  private dispatchFetchNoteSucceed(data: NoteDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchNote.createAction(result));
  }

  private dispatchFetchPhasesSucceed(data: PhasesDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchPhases.createAction(result));
  }

  private dispatchFetchAttributesSucceed(data: AttributesDTO) {
    const result = {
      id: this.id,
      result: data,
    };

    this.dispatch(fetchAttributes.createAction(result));
  }

  private dispatchChatResetToDefault() {
    this.dispatch(resetToDefault.createAction());
  }

  private dispatchFetchChatSucceed(data: ChatDTO) {
    this.dispatch(fetchMessages.createAction(data.items));
  }

}

export function fetchProjectPreviewThunk(dispatch, param: { id: Id }) {
  const thunk = new FetchProjectPreviewThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(param);
}

export default FetchProjectPreviewThunk;
