import styled from 'styled-components';

const style: any = {};

style.TextFitCalculationComponentWrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;

  white-space: nowrap;
`;

export default style;
