import { fetchFoldersReduce } from './fetchFolders.reduce';

import type { ActionData } from './fetchFolders.reduce';

const actionType = 'REGULATION/FETCH_FOLDERS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchFolders = {
  actionType,
  createAction,
  reduce: fetchFoldersReduce,
};

export default fetchFolders;
