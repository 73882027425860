import { Dispatch } from 'redux';

import { fetchDefaultFilters } from './fetchDefaultFilters';
import { fetchPinnedFilters } from './fetchPinnedFilters';
import { resetForm } from './resetForm';

import { httpFetchDefaultFilters } from './fetchDefaultFilters.http';
import { httpFetchPinnedFilters } from './fetchPinnedFilters.http';

interface Params {
  withPinned: boolean;
}

export class FetchDefaultFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    const state = this.getState();

    if (!state.search.defaultQuery.loading) {
      this.dispatchResetForm();

      return;
    }

    if (!params.withPinned) {
      const defaultFilters = await this.fetchDefaultFilters();
      this.dispatchFetchDefaultFiltersSucceed(defaultFilters.filters);
    } else {
      const defaultFilters = await this.fetchDefaultFilters();
      const pinnedFilters = await this.fetchPinnedFilters();
      this.dispatchFetchDefaultFiltersSucceed(defaultFilters.filters);
      this.dispatchFetchPinnedFiltersSucceed(pinnedFilters.value);
    }

  }

  private async fetchDefaultFilters() {
    try {
      return await httpFetchDefaultFilters();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchPinnedFilters() {
    try {
      return await httpFetchPinnedFilters();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchDefaultFiltersSucceed(data) {
    this.dispatch(fetchDefaultFilters.createAction(data));
  }

  private dispatchFetchPinnedFiltersSucceed(data) {
    this.dispatch(fetchPinnedFilters.createAction(data));
  }

  private dispatchResetForm() {
    this.dispatch(resetForm.createAction());
  }

}

export function fetchDefaultFiltersThunk(dispatch, params) {
  const thunk = new FetchDefaultFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default fetchDefaultFiltersThunk;
