import { connect } from 'react-redux';

import { Workspace } from './workspace';

const mapStateToProps = (state) => {
  const { packages, documents } = state.authorPackages;

  const loading = packages.loading || documents.loading || !packages.activeItemId;

  return {
    loading,
  };

};

export const WorkspaceConnect = connect(
  mapStateToProps,
)(Workspace);

export default WorkspaceConnect;
