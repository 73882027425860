import { fetchAttributesReduce } from './fetchAttributes.reduce';

import type { ActionData } from './fetchAttributes.reduce';

const actionType = 'DOCUMENT/FETCH_ATTRIBUTES';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchAttributes = {
  actionType,
  createAction,
  reduce: fetchAttributesReduce,
};

export default fetchAttributes;
