import { addMessageFromClientReduce } from './addMessageFromClient.reduce';

import type { ActionData } from './addMessageFromClient.reduce';

const actionType = 'REGULATION/CHAT/ADD_MESSAGE__FROM_CLIENT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addMessageFromClient = {
  actionType,
  createAction,
  reduce: addMessageFromClientReduce,
};

export default addMessageFromClient;
