import {
  styled,
  css,
} from '@components/styled';

const style:any = {};

interface Props {
  shouldRender: boolean;
}

style.Root = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  pointer-events: none;
  z-index: 1;

  background: ${(props) => props.theme.colors.white};

  ${(props: Props) => !props.shouldRender && css`
    background: transparent;
  `}

  transition: background 0.1s ease-out;
`;

export default style;
