import {
  FetchLawDocument,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

export function httpFetchLawDocument(requestParams: FetchLawDocument) {
  const request = http.get({
    url: RESTPath.lawDocument.getBody(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchLawDocument;
