import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { Settings } from '@pages/searchResult/documents/components/settings/settings';

import {
  buildRequisitesForClipboard,
  buildDownloadItems,
} from '@pages/searchResult/documents/utils';

const mapStateToProps = (state, ownProps) => {

  const documentId = ownProps.id;
  const source = Source.package;
  const sourceId = state.packages.packages.activeItemId;

  return {
    source,
    sourceId,
    requisites: buildRequisitesForClipboard(documentId, state.packages.documents),
    downloadItems: buildDownloadItems({
      documentId,
      sourceId,
      source,
    }),
  };
};

export const SettingsConnect = connect(
  mapStateToProps,
)(Settings);

export default SettingsConnect;
