import { initial } from '../../redux/state';

export const resetDocumentsReduce = (state) => {
  if (!state) {
    return state;
  }

  return initial();
};

export default resetDocumentsReduce;
