import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  margin-top: 16px;
  background: ${(props) => props.theme.colors.white};
`;

style.Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 18px 16px;
  font: 17px Roboto-Medium;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  z-index: 1;
`;

style.List = styled.div``;

style.Loader = styled.div`
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
