import React from 'react';

import { InputMock } from './inputMock';
import { Input } from './input';

interface BlockProps {
  placeholder: string;
  disabled?: boolean;
  initialValue: any;
  loading: boolean;
  onUpdate(data: any): void;
}

export class Block extends React.PureComponent<BlockProps> {

  render() {
    if (this.props.loading) {
      return <InputMock {...this.props} />;
    }

    return <Input {...this.props} />;

  }

}

export default Block;
