import React from 'react';

import { TagItem } from './tagItem';

import s from './tagList.style';

export interface Item {
  id: string;
  path: string;
  color: string;
  title: string;
}

export interface PackageListProps {
  items: Item[];
  onSelectTag(id: string): void;
  closePopover(): void;
}

export class TagList extends React.PureComponent<PackageListProps> {

  state = {
    selectedId: '',
  };

  getSelected(selected, id) {
    if (!this.state.selectedId) {
      return selected;
    }

    return this.state.selectedId === id;
  }

  handleMouseOver = (id: string) => {
    this.setState({
      selectedId: id,
    });
  };

  handleMouseOut = () => {
    this.setState({
      selectedId: '',
    });
  };

  handleClick = (id) => {
    this.props.onSelectTag(id);
    this.props.closePopover();
  };

  renderTagItem = (item) => (
    <TagItem
      key={item.id}
      id={item.id}
      title={item.title}
      color={item.color}
      path={item.path}
      selected={this.getSelected(item.selected, item.id)}
      onClick={this.handleClick}
      onMouseOver={this.handleMouseOver}
      onMouseOut={this.handleMouseOut}
    />
  );

  renderList() {
    return (
      <s.List>
        {this.props.items.map(this.renderTagItem)}
      </s.List>
    );
  }

  render() {

    return (
      <s.Root>
        {this.renderList()}
      </s.Root>
    );
  }

}

export default TagList;
