import styled from 'styled-components';

const style: any = {};

style.Tooltip = styled.div`
  background: ${(props) => props.theme.colors.defaultText};
  color: ${(props) => props.theme.colors.white};
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 11px;
  font-family: Roboto-Medium;
  line-height: 16px;
  opacity: 0.8;
  text-align: center;
  white-space: nowrap;
  width: 100%;
`;

style.TooltipWrapper = styled.div`
  display: none;
  position: absolute;
  justify-content: flex-end;
  top: -20px;

  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

export default style;
