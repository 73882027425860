import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  width: 192px;
  padding: 8px 0;
  background: white;
`;

style.Item = styled.div`
  padding: 12px 8px 12px 16px;
  position: relative;
  height: 40px;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }
`;

export default style;
