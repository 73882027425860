import styled, {
  keyframes,
  css,
} from 'styled-components';

interface Props {
  theme: any;
  loading: boolean;
  disabled: boolean;
}

const style: any = {};

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0.1;
    transform: scale(3);
  }
`;

const loading = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.4;
  }
`;

style.Root = styled.div`
  width: 100%;
`;

style.Row = styled.div`
  display: flex;
  width: 100%;
`;

style.Button = styled.div`
  position: relative;

  transition: all .3s;

  user-select: none;

  width: 32px;
  height: 32px;

  color: ${(props) => props.theme.colors.stone};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  &:active {
    background-color: rgba(65, 131, 215, 0.12);
  }

  user-select: none;

  ${(props: Props) => props.loading && css`
    animation: ${loading} 1.5s linear infinite;
  `}

  .animate {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: 50%;
      z-index: 1;
      animation: ${ripple} 0.3s ease-out;
      animation-delay: 0.1s;
    }
  }

  ${(props: Props) => props.disabled && css`
    cursor: default;
    > i {
      color: ${props.theme.colors.disabled};
    }

    &:hover {
      background-color: transparent;
    }
  `}
`;

export default style;
