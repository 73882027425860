import { cleanBookmarksReduce } from './cleanBookmarks.reduce';

export const actionType = 'BOOKMARKS/CLEAN';

const createAction = () => ({
  type: actionType,
});

export const cleanBookmarks = {
  actionType,
  createAction,
  reduce: cleanBookmarksReduce,
};
