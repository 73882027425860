import { MultiChoice } from '@components/filters/abstract';

import {
  DateBlock,
  DateBlockData,
} from '@components/filters/date/logic/date';

import {
  TextBlock,
  TextBlockData,
} from '@components/filters/text/logic/logic';

import { PhaseBlock } from '@components/filters/phase/logic';
import { AuthorBlock } from '@components/filters/author/logic';
import { ProjectTypeBlock } from '@components/filters/projectType/logic';
import { BranchOfLegislationBlock } from '@components/filters/branchOfLegislation/logic';
import { ProjectNumberBlock } from '@components/filters/projectNumber/logic';

type Loading = boolean;

export enum FilterType {
  date = 'date',
  branchOfLegislation = 'branchOfLegislation',
  phase = 'phase',
  author = 'author',
  projectType = 'projectType',
  text = 'text',
  projectNumber = 'number',
}

export type FilterData = DateBlockData | MultiChoice | TextBlockData;

export interface Filters {
  [FilterType.date]: {
    data: DateBlockData;
  };
  [FilterType.phase]: {
    data: MultiChoice;
  };
  [FilterType.author]: {
    data: MultiChoice;
  };
  [FilterType.projectType]: {
    data: MultiChoice;
  };
  [FilterType.branchOfLegislation]: {
    data: MultiChoice;
  };
  [FilterType.text]: {
    data: TextBlockData;
  };
  [FilterType.projectNumber]: {
    data: MultiChoice;
  }
}

interface State {
  filters: Filters;
  loading: Loading;
}

export class StateBuilder {

  private state: State;

  static createState(): State {
    return {
      filters: {
        [FilterType.date]: new DateBlock().decorate(),
        [FilterType.phase]: new PhaseBlock().decorate(),
        [FilterType.author]: new AuthorBlock().decorate(),
        [FilterType.projectType]: new ProjectTypeBlock().decorate(),
        [FilterType.branchOfLegislation]: new BranchOfLegislationBlock().decorate(),
        [FilterType.text]: new TextBlock().decorate(),
        [FilterType.projectNumber]: new ProjectNumberBlock().decorate(),
      },
      loading: true,
    };
  }

  constructor(state: State) {
    this.state = state;
  }

  getState() {
    return this.state;
  }

  public updateFilter(filter, data) {
    this.state = {
      ...this.state,
      filters: {
        ...this.state.filters,
        [filter]: data,
      },
    };
  }

}

export default StateBuilder;
