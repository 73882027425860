import {
  StateBuilder,
  DocumentId,
} from './state';

import { Summary } from './summary';

export interface ActionData {
  id: DocumentId
}

interface Action {
  type: string;
  data: ActionData;
}

export const createSummaryReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.items);
  const summary = new Summary();

  newState.addItem(action.data.id, summary.getData());
  newState.updateItemProgress(action.data.id, true);

  return newState.getState();
};

export default createSummaryReduce;
