export const subscriptionProcessStartReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    subscription: {
      ...state.subscription,
      process: true,
    },
  };
};

export default subscriptionProcessStartReduce;
