import { Dispatch } from 'redux';

import { httpSaveMarkers } from '@pages/document/redux/saveMarkers.http';

import { saveMarkers } from './saveMarkers';

export class SaveMarkersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params) {
    const dispatchParams = {
      documentId: params.documentId,
      entities: params.data.entities,
    };

    this.dispatchSaveMarkers(dispatchParams);

    try {
      await httpSaveMarkers(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchSaveMarkers(params) {
    this.dispatch(saveMarkers.createAction(params));
  }

}

export function saveMarkersThunk(dispatch, params) {
  const thunk = new SaveMarkersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SaveMarkersThunk;
