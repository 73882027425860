import { StateBuilder } from './state';

export const resetChronologyDocumentsReduce = (state) => {
  if (!state) {
    return state;
  }

  return StateBuilder.createInitialState();
};

export default resetChronologyDocumentsReduce;
