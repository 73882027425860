import {
  DocumentId,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

export interface RequestParams {
  documentId: DocumentId;
}

export function httpFetchRelations(requestParams: RequestParams) {
  const request = http.get({
    url: RESTPath.document.getRelations(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchRelations;
