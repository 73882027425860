import { Dispatch } from 'redux';

import type { Id } from './state';

import { httpSetNotificationVisited } from './setNotificationVisited.http';

import { setNotificationVisited } from './setNotificationVisited';

interface Params {
  id: Id;
}

export class SetNotificationVisitedThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    this.dispatchSetNotificationVisited(params);
    await this.setNotificationVisited(params);
  }

  private async setNotificationVisited(params) {

    try {
      const requestParams = { ...params, visited: true };
      const response = await httpSetNotificationVisited(requestParams);

      return response;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchSetNotificationVisited(params) {
    this.dispatch(setNotificationVisited.createAction(params));
  }

}

export function setNotificationVisitedThunk(dispatch, params) {
  const thunk = new SetNotificationVisitedThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SetNotificationVisitedThunk;
