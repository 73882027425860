import { setSourceReduce } from './setParentDocument.reduce';

import type { ActionData } from '../sidebar/redux/fetchAttributes.reduce';

const actionType = 'DOCUMENT/SET_PARENT_DOCUMENT';

const createAction = (data: ActionData & { id: number }) => ({
  type: actionType,
  data,
});

export const setParentDocument = {
  actionType,
  createAction,
  reduce: setSourceReduce,
};

export default setParentDocument;
