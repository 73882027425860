import { Dispatch } from 'redux';

import { documentType } from '../../redux/state';

import { fetchDocumentType } from './fetchDocumentType';

import { httpFetchSearchAnalyticDocumentType } from './fetchDocumentType.http';

export class FetchSearchDocumentTypeThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
    dispatch((_, getState) => { this.getState = getState; });
  }

  public async invoke(searchId: number) {
    const { shouldLoadInitialData } = this.getState().searchResult.analysis.itemsMap[documentType.getId()];
    if (!shouldLoadInitialData) {
      return;
    }

    try {
      const searchResult = await httpFetchSearchAnalyticDocumentType(searchId);
      this.dispatchGetTypeIdSucceed(searchId, searchResult);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetTypeIdSucceed(searchId, searchResult) {
    const actionData = {
      searchId,
      ...searchResult,
    };

    this.dispatch(fetchDocumentType.createAction(actionData));
  }

}

export default FetchSearchDocumentTypeThunk;
