import { styled } from '@components/styled';

const style: any = {};

style.BaseDropdown = styled.div`
  width: 100%;
  min-width: 0;
`;

style.Toggle = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  cursor: pointer;
`;

style.MenuWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
`;

export default style;
