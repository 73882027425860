import {
  StateBuilder,
  DocumentId,
} from './state';

export interface ActionData {
  documentId: DocumentId;
}

interface Action {
  type: string;
  data: ActionData;
}

export const toggleAnnotationReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.items);
  newState.updateExpanded(action.data.documentId);

  return newState.getState();
};

export default toggleAnnotationReduce;
