import { connect } from 'react-redux';

import { Workspace } from './workspace';

const mapStateToProps = (state) => {
  const {
    itemsList,
    loading,
  } = state.subscriptions.subscriptions;

  const noItems = !itemsList.length && !loading;

  return {
    layout: state.subscriptions.layout.type,
    noItems,
  };
};

export const WorkspaceConnect = connect(
  mapStateToProps,
)(Workspace);

export default WorkspaceConnect;
