import { Dispatch } from 'redux';

import { filterBlocksMap } from '@pages/search/form/filterBlocks/filterBlocks.map';

import { fetchLaws } from './fetchLaws';
import {
  httpFetchSearchLaws,
  GetDocumentsSettings,
} from './fetchLaws.http';

export class FetchSearchLawsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(id?: number) {
    const searchId = id || this.getState().searchResult.id;
    const searchResult = await this.httpFetchSearchLaws(searchId);
    this.dispatchGetLawsSucceed(searchId, searchResult);
  }

  private async httpFetchSearchLaws(searchId) {
    const {
      sortData,
      layout: { type },
    } = this.getState().searchResult.law;

    const sortByType = filterBlocksMap.get(type).block.DTOType;

    const settings: GetDocumentsSettings = {
      searchId,
      filterType: sortByType,
      valueId: sortData.id,
      sortDirection: sortData.type,
    };

    try {
      return await httpFetchSearchLaws(settings);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetLawsSucceed(searchId, searchResult) {
    const actionData = {
      searchId,
      ...searchResult,
    };

    this.dispatch(fetchLaws.createAction(actionData));
  }

}

export function fetchSearchLawsThunk(dispatch, searchId?) {
  const thunk = new FetchSearchLawsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(searchId);
}

export default FetchSearchLawsThunk;
