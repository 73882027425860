import { Dispatch } from 'redux';

import http from '@http';

interface ThunkParams {
  actionSucceeded: any;
  restPath: string;
}

class DeleteImageThunk {

  getState;

  private readonly dispatch: Dispatch;

  private restPath: string;

  private actionSucceeded: any;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: ThunkParams) {
    this.actionSucceeded = params.actionSucceeded;
    this.restPath = params.restPath;

    try {
      await this.deleteImage();
      this.runActionSuccees();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private deleteImage() {
    const request = http.delete({
      url: this.restPath,
    });

    return http.fetch(request)
      .then(http.parseResponse);
  }

  runActionSuccees() {
    if (!this.actionSucceeded) {
      return;
    }

    this.actionSucceeded();
  }

}

export function deleteImageThunk(dispatch, params: ThunkParams) {
  const thunk = new DeleteImageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default DeleteImageThunk;
