import {
  AttributesBuilder,
  AttributesDTO,
} from './attributes';

export interface ActionData {
  title: string;
  attributes: AttributesDTO;
  comment: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchAttributesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const attributes = new AttributesBuilder();
  attributes.createFromDTO({ ...action.data.attributes, comment: action.data.comment });
  attributes.buildState();

  const attributesState = attributes.getState();

  return {
    ...state,
    attributes: {
      ...state.attributes,
      data: {
        title: action.data.title,
        attributes: attributesState,
        commentary: action.data.comment,
      },
      loading: false,

    },
  };
};

export default fetchAttributesReduce;
