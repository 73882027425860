import { decreasePackageCountReduce } from './decreasePackageCount.reduce';

import type { ActionData } from './decreasePackageCount.reduce';

const actionType = 'PACKAGES/DOCUMENTS/DOCUMENT__DECREASE_PACKAGE_COUNT';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const decreasePackageCount = {
  actionType,
  createAction,
  reduce: decreasePackageCountReduce,
};

export default decreasePackageCount;
