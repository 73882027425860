import {
  RESTPath,
  Source,
  Id,
} from '@router/path.rest';
import http from '@http';

interface RequestParams {
  documentId: Id;
  source: Source;
  sourceId: Id;
  commentary: string;
}

export function httpSaveCommentary(requestParams: RequestParams) {
  const request = http.post({
    url: RESTPath.document.saveCommentary(requestParams),
    data: {
      comment: requestParams.commentary,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSaveCommentary;
