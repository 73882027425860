import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;
  max-height: 100%;
  width: 100%;

  background-color: #f3f3f3;
`;

style.Content = styled.section`
  display: flex;
  flex: 1;

  overflow: hidden;
`;

export default style;
