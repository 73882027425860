import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid rgb(223, 226,230);
  font: 17px Roboto-Medium, sans-serif;
  font-weight: 500;
  padding: 8px;
`;

export default style;
