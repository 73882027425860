import { Dispatch } from 'redux';

import { httpCreatePackage } from '@pages/packages/redux/packages/createPackage.http';

import { createPackageStart } from '@pages/packages/redux/searchPackages/createPackageStart';
import { createPackageSucceed } from '@pages/packages/redux/searchPackages/createPackageSucceed';

import { addDocumentToPackageThunk } from './addDocumentToPackage.thunk';

export class CreatePackageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { query: string }) {
    const documentId = this.getState().document.id;

    const packageId = await this.createPackage(params.query);
    if (packageId) {
      const requestParams = {
        packageId,
        documentId,
      };

      addDocumentToPackageThunk(this.dispatch, requestParams);
    }
  }

  private async createPackage(query) {
    this.dispatchCreatePackageStart();

    try {
      const response = await httpCreatePackage(query);

      if (!response.id) {
        return null;
      }

      this.dispatchCreatePackageSucceed(response);

      return response.id;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchCreatePackageStart() {
    this.dispatch(createPackageStart.createAction());
  }

  private dispatchCreatePackageSucceed(params) {
    const actionData = {
      ...params,
    };

    this.dispatch(createPackageSucceed.createAction(actionData));
  }

}

export function createPackageThunk(dispatch, params) {
  const thunk = new CreatePackageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default CreatePackageThunk;
