import { connect } from 'react-redux';

import { updatePackageDescriptionThunk } from '../redux/packages/updatePackageDescription.thunk';

import { Description } from './description';

const mapStateToProps = (state) => {
  const { activeItemId, itemsMap } = state.packages.packages;

  const { loading } = state.packages.documents;

  const item = itemsMap.get(activeItemId);

  return {
    activeItemId,
    loading,
    data: item ? item.description : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDescriptionUpdate(data) {
    updatePackageDescriptionThunk(dispatch, data);
  },
});

export const DescriptionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Description);

export default DescriptionConnect;
