import { Filters } from '../filters';

export const updatePinReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);
  filters.updatePin(action.data);
  const query = {
    blocksList: filters.blocksList,
    blocksMap: filters.blocksMap,
  };

  return {
    ...state,
    query: {
      ...state.query,
      ...query,
    },
  };
};

export default updatePinReduce;
