import http from '@http';
import { Dispatch } from 'redux';

import { BootstrapThunk } from '@bootstrap/bootstrap.thunk';

import { httpLogin } from '../http/login.http';
import { httpFetchUser } from '../http/user.http';
import { fetchUser } from '../redux/fetchUser';

export class LoginThunk {

  dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async invoke(email, password) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    email = email.trim();
    this.validate(email);

    const data = {
      login: email || '',
      password,
    };

    const loginInfo = await this.httpLogin(data);
    if (loginInfo && loginInfo.result) {
      const thunk = new BootstrapThunk(this.dispatch);

      thunk.invoke();
    }
  }

  async httpLogin(data) {
    let result;
    try {
      result = await httpLogin(data);
    } catch (error) {

      if ([400, 423, 422].includes(error.status)) {
        throw await http.parseResponse(error);
      }
    }

    return result;
  }

  async httpProfile() {
    let user;
    try {
      user = await httpFetchUser();
    } catch (error) {
      if ([400, 423].includes(error.status)) {
        throw await http.parseResponse(error);
      }
    }

    return user;
  }

  validate(email) {
    const errors = {};

    if (!email) {
      // @ts-ignore
      errors.email = 'email error';
    }

    const hasErrors = !!Object.keys(errors).length;
    if (hasErrors) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw errors;
    }
  }

  dispatchUserFetchSucceeded = (user) => {
    const action = fetchUser.createAction(user);
    // @ts-ignore
    this.dispatch(action);
  };

}

export default LoginThunk;
