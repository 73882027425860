import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 12px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Action = styled.div`
  width: 24px;
  height: 24px;
`;

style.HeaderTitle = styled.div`
  font: 17px Roboto-Medium;
  margin-left: 8px;
`;

export default style;
