import React from 'react';

import { ProfileButtonConnect as Profile } from '@pages/userPages/components/profileButton.connect';

import { Logo } from '../logo/logo';
import { NavigationConnect } from './navigation/navigation.connect';
import s from './staticSidebar.style';

export const StaticSidebar = () => (
  <s.Container>
    <s.Header>
      <Logo />
    </s.Header>
    <NavigationConnect />
    <Profile />
  </s.Container>
);

export default StaticSidebar;
