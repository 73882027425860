import { connect } from 'react-redux';

import {
  createName,
  createPosition,
} from '@pages/userPages/profile/profileTab/headerCassette.connect';

import { TitleGroup } from './titleGroup';

function prepareDescription(text) {
  return text.split('\n');
}

const mapStateToProps = (state) => {
  const { packages } = state.authorPackages;

  const currentPackage = packages.itemsMap.get(packages.activeItemId);

  if (!currentPackage) {
    return {};
  }

  return {
    name: createName({ currentUser: currentPackage.authorInformation }),
    position: createPosition({ currentUser: currentPackage.authorInformation }),
    logo: currentPackage.authorInformation.logo,
    avatar: currentPackage.authorInformation.avatar,
    description: prepareDescription(currentPackage.description),
    title: currentPackage.title,
  };
};

export const TitleGroupConnect = connect(
  mapStateToProps,
)(TitleGroup);

export default TitleGroupConnect;
