import React from 'react';

import isEqual from 'lodash/isEqual';

import { Draggable } from 'react-beautiful-dnd';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import {
  SaveButton,
  ResetButton,
} from '@components/buttons';

import { IconDrag } from '@components/iconDrag';
import { DragAndDrop } from '@components/dragAndDrop/dragAndDrop';
import { Checkbox } from '@components/checkbox/checkbox';

import s from '@pages/searchResult/documents/header/settingsPopover/tableSettings.style';

interface Item {
  id: string;
  shouldRender: boolean;
  title: string;
}

interface DragItemProps {
  index: number;
  onToggleRender: (id: string) => void;
  item: Item;
}

const DragItem = (props: DragItemProps) => {
  const handleItemRenderToggle = () => {
    props.onToggleRender(props.item.id);
  };

  const renderSortItem = (provider) => (
    <s.Item
      key={props.item.id}
      ref={provider.innerRef}
      {...provider.draggableProps}
      {...provider.dragHandleProps}
    >
      <s.DragIcon>
        <IconDrag />
      </s.DragIcon>
      <Checkbox
        checked={props.item.shouldRender}
        onClick={handleItemRenderToggle}
      />
      <s.ItemTitle>{props.item.title}</s.ItemTitle>
    </s.Item>
  );

  return (
    <Draggable
      key={props.item.id}
      draggableId={props.item.id}
      index={props.index}
    >
      {renderSortItem}
    </Draggable>
  );

};

interface StateItems {
  items: Item[];
}

interface TableSettingsProps {
  onGoBack: () => void;
  onSaveSort: (items: Item[]) => void;
  columns: Item[];
  defaultColumns: string[];
}

export class TableSettings extends React.PureComponent<TableSettingsProps, StateItems> {

  state = { items: this.props.columns };

  handleSort(indexStart, indexEnd) {
    const item = this.state.items[indexStart];
    const items = [...this.state.items];

    items.splice(indexStart, 1);
    items.splice(indexEnd, 0, item);

    this.setStateItems(items);
  }

  setStateItems(items) {
    this.setState({
      items,
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const indexStart = result.source.index;
    const indexEnd = result.destination.index;

    this.handleSort(indexStart, indexEnd);
  };

  handleGoBack = () => {
    this.props.onGoBack();
  };

  handleSaveSort = () => {
    this.props.onSaveSort(this.state.items);
    this.handleGoBack();
  };

  private isDocumentType = (id) => id === 'type_title';

  private isDate = (id) => id === 'date';

  private isStaticColumn = (id) => (this.isDocumentType(id) || this.isDate(id));

  renderDraggableItems = () => this.state.items.map((item, index) => {
    if (this.isStaticColumn(item.id)) {
      return null;
    }

    return (
      <DragItem
        key={item.id}
        item={item}
        index={index}
        onToggleRender={this.handleToggleRender}
      />
    );
  });

  resetToDefault = () => {
    if (this.isDefaultSort()) {
      return;
    }

    this.setStateItems(this.props.defaultColumns);
  };

  handleToggleRender = (id) => {
    const items = this.state.items.map((item) => {
      const renderItem = { ...item };
      if (renderItem.id === id) {
        renderItem.shouldRender = !item.shouldRender;
      }

      return renderItem;
    });

    this.setStateItems(items);
  };

  isDefaultSort() {
    return isEqual(this.state.items, this.props.defaultColumns);
  }

  renderHeader() {
    const arrowBackIcon = iconsPath.get('arrowBack');
    const resetIcon = iconsPath.get('reset');

    return (
      <s.Header>
        <s.Back
          onClick={this.handleGoBack}
        >
          <Icon points={arrowBackIcon} color="currentColor" />
        </s.Back>
        <s.HeaderTitle>
          Настроить колонки
        </s.HeaderTitle>
        <s.Reset
          onClick={this.resetToDefault}
          disabled={this.isDefaultSort()}
        >
          <Icon path={resetIcon} color="currentColor" />
        </s.Reset>
      </s.Header>
    );
  }

  renderStaticItem() {
    return (
      <s.Item
        key="title"
        disabled
        id="title"
      >
        <Checkbox
          checked
        />
        <s.ItemTitle>Наименование</s.ItemTitle>
      </s.Item>
    );
  }

  renderResetButton() {
    return (
      <ResetButton onClick={this.handleGoBack} />
    );
  }

  renderSaveButton() {
    return (
      <SaveButton
        onClick={this.handleSaveSort}
      />
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        <s.List>
          {this.renderStaticItem()}
          <DragAndDrop
            droppableId="list"
            onDragEnd={this.onDragEnd}
            // @ts-ignore
            renderContent={this.renderDraggableItems}
          />
        </s.List>
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Container>
    );
  }

}

export default TableSettings;
