import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { DateBlock } from '../components/filters/date/logic/date';

import { updateFilter } from './updateFilter';

import { resetToInitial } from './resetToInitial';

import { fetchSearchHistoryThunk } from './fetchHistory.thunk';

export class UpdateDateThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public invoke(filter) {
    this.dispatchResetHistory();
    this.dispatchUpdateFilterDate(filter);

    fetchSearchHistoryThunk(this.dispatch);
  }

  private dispatchUpdateFilterDate(filter) {
    const filterData = this.getState().search.history.filters.data;

    if (filter.scope) {
      const scope = DateBlock.scopePresets[filter.scope];
      const data = {
        value: ['', ''],
        scope,
      };

      sendAmplitudeData('search:history_filter', { data });
      Userpilot.track('search:history_filter', { data });
      this.dispatch(updateFilter.createAction({ data }));
    }

    if (filter.value) {
      const data = {
        ...filterData,
        scope: DateBlock.scopePresets.range,
        value: filter.value,
      };

      sendAmplitudeData('search:history_filter', { data });
      Userpilot.track('search:history_filter', { data });
      this.dispatch(updateFilter.createAction({ data }));
    }
  }

  private dispatchResetHistory() {
    this.dispatch(resetToInitial.createAction());
  }

}

export function updateDateThunk(dispatch, filter) {
  const thunk = new UpdateDateThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(filter);
}
