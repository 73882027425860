import React from 'react';
import { Map } from 'immutable';

import {
  TotalCheckbox,
  Status as TotalCheckStatus,
} from '@pages/searchResult/documents/components/totalCheckbox';

import { DocumentId } from '../../../redux/documents/state';

import { ItemConnect } from './item.connect';
import s from './table.style';

interface ColumnItem {
  id: string;
  showColumn: boolean;
  title: string;
}

interface Columns {
  itemsList: string[];
  itemsMap: Map<string, ColumnItem>
}

interface Props {
  items: DocumentId[];
  totalCheckStatus: TotalCheckStatus;
  documentPreviewId: number;
  columns: Columns;
  onItemClick(id: DocumentId): void;
  onChangeSelectionRule(): void;
}

export const grid = {
  type_title: 'MINIMAL_COLUMN',
  date: 'MINIMAL_COLUMN',
  case_result: 'REGULAR_COLUMN',
  department: 'REGULAR_COLUMN',
  judges: 'MINIMAL_COLUMN',
  category_dispute_title: 'LARGE_COLUMN',
  case_type_title: 'REGULAR_COLUMN',
  instance: 'REGULAR_COLUMN',
  case_number: 'MINIMAL_COLUMN',
  appeal_state: 'REGULAR_COLUMN',
};

export class Table extends React.PureComponent<Props> {

  renderItem = (item) => (
    <ItemConnect
      key={item}
      id={item}
      isActive={this.props.documentPreviewId === item}
      onItemClick={this.props.onItemClick}
    />
  );

  renderColumnInGroup() {
    return this.props.columns.itemsList.map((column) => (<s.ColumnItem key={column} />));
  }

  renderHeaderItems() {
    return this.props.columns.itemsList.map((column) => {
      const { title } = this.props.columns.itemsMap.get(column);

      return (
        <s.CellItem
          key={column}
        >
          <s.ColumnWidthItem
            column={grid[column]}
          >
            <s.CellContainer>
              <s.HeaderText>{title}</s.HeaderText>
            </s.CellContainer>
          </s.ColumnWidthItem>
        </s.CellItem>
      );
    });
  }

  render() {
    return (
      <s.Root>
        <s.Table>
          <s.Group>
            <s.ColumnIcon />
            {this.renderColumnInGroup()}
            <s.ColumnAdditional />
            <s.ColumnAdditional />
            <s.ColumnAdditional />
          </s.Group>
          <s.Header>
            <s.CellIcon>
              <s.ColumnWidthIcon>
                <s.CellContainer>
                  <TotalCheckbox
                    status={this.props.totalCheckStatus}
                    onClick={this.props.onChangeSelectionRule}
                  />
                </s.CellContainer>
              </s.ColumnWidthIcon>
            </s.CellIcon>
            {this.renderHeaderItems()}
            <s.CellAdditional>
              <s.ColumnWidthAdditional />
            </s.CellAdditional>
            <s.CellAdditional>
              <s.ColumnWidthAdditional />
            </s.CellAdditional>
            <s.CellAdditional>
              <s.ColumnWidthAdditional />
            </s.CellAdditional>
          </s.Header>
          <s.Body>{this.props.items.map(this.renderItem)}</s.Body>
        </s.Table>
      </s.Root>
    );
  }

}

export default Table;
