import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Id } from '@pages/regulation/sidebar/folders/redux/item';

import type { UserDTO } from './state';

export interface UsersDTO {
  items: UserDTO[];
}

export function httpFetchUsers(folderId: Id): Promise<UsersDTO> {
  const url = RESTPath.regulation.folders.getUsers(folderId);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchUsers;
