import { Dispatch } from 'redux';

import { Id } from '@pages/regulation/workspace/redux/state';

import { fetchMessages } from '@pages/regulation/redux/chat/fetchMessages';
import { resetToDefault } from '@pages/regulation/redux/chat/resetToDefault';
import {
  httpFetchMessages,
  ChatDTO,
} from '@pages/regulation/redux/chat/fetchMessages.http';

export class FetchChatThunk {

  getState;

  private readonly dispatch: Dispatch;

  private id: Id = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(param: { id: Id }) {
    this.id = param.id;

    this.dispatchChatResetToDefault();
    const chat = await this.fetchChat();
    this.dispatchFetchChatSucceed(chat);
  }

  private async fetchChat(): Promise<ChatDTO> {
    try {
      const result = await httpFetchMessages(this.id);

      return result;
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchChatResetToDefault() {
    this.dispatch(resetToDefault.createAction());
  }

  private dispatchFetchChatSucceed(data: ChatDTO) {
    this.dispatch(fetchMessages.createAction(data.items));
  }

}

export async function fetchChatThunk(dispatch, param: { id: Id }) {
  const thunk = new FetchChatThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(param);
}

export default FetchChatThunk;
