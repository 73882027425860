import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchSearchAnalyticDocumentType(searchId: number) {
  const request = http.get({
    url: RESTPath.search.analytic.documentType.getData(searchId),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSearchAnalyticDocumentType;
