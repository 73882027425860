import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchDocument } from './fetchDocument';

import { fetchMarkers } from './fetchMarkers';
import { fetchSourceTitle } from './fetchSourceTitle';

import { resetToInitial } from './resetToInitial';
import { resetDocument } from './resetDocument';
import { resetAttributes } from './resetAttributes';
import { resetRelations } from './resetRelations';

import { setSource } from './setSource';
import { setDocumentId } from './setDocumentId';
import { setParentDocument } from './setParentDocument';
import { setIsChronologyDocument } from './setIsChronologyDocument';
import { setIsSimilarDocument } from './setIsSimilarDocument';

import { reducerBuilder as Sidebar } from '../sidebar/redux/reducer';

import { reducerBuilder as Header } from '../header/redux/reducer';

import { StateBuilder } from './state';

export const reducerBuilder = new ReducerBuilder([
  fetchDocument,
  fetchMarkers,
  fetchSourceTitle,
  setSource,
  setDocumentId,
  setParentDocument,
  setIsChronologyDocument,
  setIsSimilarDocument,
  resetToInitial,
  resetDocument,
  resetAttributes,
  resetRelations,
]);

reducerBuilder.merge(Sidebar);
reducerBuilder.merge(Header);

reducerBuilder.setInitialState(StateBuilder.createState());

export default reducerBuilder;
