import { connect } from 'react-redux';

import { addCommentaryThunk } from '@layouts/bookmarks/redux/addCommentary.thunk';

import { Commentary } from './commentary';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddCommentary(commentary) {
    const params = {
      paragraphId: Number(ownProps.paragraphId),
      commentary,
    };

    addCommentaryThunk(dispatch, params);
  },
});

export const CommentaryConnect = connect(
  null,
  mapDispatchToProps,
)(Commentary);

export default CommentaryConnect;
