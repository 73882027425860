import React from 'react';

import { RoutePath } from '@router/path';

import { UnauthRouteConnect } from '@router/unauthRoute.connect';

import { PasswordRecoveryEmailSent } from './passwordRecoveryEmailSent';

export const RoutePasswordRecoveryEmailSent = (
  <UnauthRouteConnect
    exact
    path={RoutePath.passwordRecoveryEmailSent}
    component={PasswordRecoveryEmailSent}
  />
);

export default RoutePasswordRecoveryEmailSent;
