import { connect } from 'react-redux';

import { Source } from '@providers/download/download.http';
import { processThunk } from '@providers/download/process.thunk';

import { fetchProjectPreviewThunk } from '../../../../redux/preview/fetchProject.thunk';

import { Preview } from './preview';

export function getPreview(id, projects) {
  const project = projects[id];
  if (!project) {

    return {
      id,
      attributes: {
        type: null,
        source: {
          title: null,
        },
      },
      note: {
        loading: true,
      },
      phases: {
        loading: true,
      },
      project: {
        loading: true,
      },
    };
  }

  return {
    id,
    ...projects[id],
  };
}

const mapStateToProps = (state, ownProps) => {
  const preview = getPreview(ownProps.id, state.regulation.preview);

  return {
    id: preview.id,
    title: preview.attributes.type,
    projectLoading: preview.project.loading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    fetchProjectPreviewThunk(dispatch, { id: ownProps.id });
  },
  onDownload(id: string) {
    const params = {
      source: Source.regulationProject,
      id,
    };

    processThunk(dispatch, params);
  },
});

export const PreviewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Preview);

export default PreviewConnect;
