import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { DocumentMarkers } from '@pages/document/workspace/documentMarkers';

const mapStateToProps = (state, ownProps) => {
  let currentPageNumber;
  let document;
  let enable = true;

  if (ownProps.source === Source.search) {
    currentPageNumber = state.searchResult.documents.previewDocument.currentPageNumber;
    document = state.searchResult.documents.pages[currentPageNumber].itemsMap.get(ownProps.documentId);
  }

  if (ownProps.source === Source.package) {
    currentPageNumber = state.packages.documents.previewDocument.currentPageNumber;
    document = state.packages.documents.pages[currentPageNumber].itemsMap.get(ownProps.documentId);
  }

  if (ownProps.source === Source.subscription) {
    currentPageNumber = state.subscriptions.documents.previewDocument.currentPageNumber;
    document = state.subscriptions.documents.pages[currentPageNumber].itemsMap.get(ownProps.documentId);
  }

  if (ownProps.source === `${Source.authorPackage}s`) {
    currentPageNumber = state.authorPackages.documents.previewDocument.currentPageNumber;
    document = state.authorPackages.documents.pages[currentPageNumber].itemsMap.get(ownProps.documentId);
    enable = false;
  }

  if (!document) {
    return {};
  }

  return {
    selections: document.details.entities,
    enable,
    right: '-10px',
  };
};

export const DocumentMarkersConnect = connect(
  mapStateToProps,
)(DocumentMarkers);

export default DocumentMarkersConnect;
