import { cleanBookmarksBufferReduce } from './cleanBookmarks.reduce';

export const actionType = 'BOOKMARKS_BUFFER/CLEAN';

const createAction = () => ({
  type: actionType,
});

export const cleanBookmarksBuffer = {
  actionType,
  createAction,
  reduce: cleanBookmarksBufferReduce,
};
