import React, { PureComponent } from 'react';

import { ItemConnect } from './item.connect';

import s from './list.style';

interface Props {
  loading: boolean;
  pageLoading: boolean;
  items: number[],
}

export class List extends PureComponent<Props> {

  renderPageLoader() {
    if (this.props.loading) {
      return null;
    }

    if (!this.props.pageLoading) {
      return null;
    }

    return <s.PageLoader />;
  }

  renderItem = (item) => (
    <ItemConnect
      key={item}
      id={item}
    />
  );

  render() {
    return (
      <s.Root>
        {this.renderPageLoader()}
        {this.props.items.map(this.renderItem)}
      </s.Root>
    );
  }

}

export default List;
