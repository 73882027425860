import { styled } from '@components/styled/index';

const style: any = {};

style.Root = styled.div`
  position: relative;
  font-size: 13px;
  background-color: ${(props) => props.theme.colors.white};

  :last-child {
    border-bottom: none;
  }
`;

style.PageLoader = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 1;
`;

export default style;
