import React from 'react';

import {
  MenuButton,
  Breadcrumbs,
} from '@components/header';

import { Bookmarks } from '@layouts/bookmarks/components/bookmarks';

import { SettingsConnect } from './settings.connect';
import { LayoutSwitcherConnect } from './layoutSwitcher.connect';
import { NotifyConnect } from './notify.connect';

import s from './header.style';

interface Path {
  id: number;
  title: string;
  route: string;
  loading: boolean;
}

interface HeaderProps {
  path: Path[];
}

export class Header extends React.PureComponent<HeaderProps> {

  renderActions() {
    return (
      <s.Actions>
        <s.Action>
          <Bookmarks />
        </s.Action>
        <s.Action>
          <LayoutSwitcherConnect />
        </s.Action>
        <s.Action>
          <NotifyConnect />
        </s.Action>
        <s.Action>
          <SettingsConnect />
        </s.Action>
      </s.Actions>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Menu>
          <MenuButton />
        </s.Menu>
        <s.Breadcrumbs>
          <Breadcrumbs breadcrumbs={this.props.path} />
        </s.Breadcrumbs>
        {this.renderActions()}
      </s.Root>
    );
  }

}

export default Header;
