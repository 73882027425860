import {
  RESTPath,
  DocumentId,
  PageNumber,
} from '@router/path.rest';

import http from '@http';

interface Request {
  documentId: DocumentId;
  pageNumber: PageNumber;
}

export async function httpFetchChronologyDocuments(httpRequest: Request) {
  const request = http.get({
    url: RESTPath.chronology.documents(httpRequest),
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpFetchChronologyDocuments;
