import styled, { css } from 'styled-components';

interface Props {
  checkWidth?: number;
}

const style: any = {};

style.Navigation = styled.aside`
  flex-shrink: 0;
  width: 256px;
`;

style.Root = styled.div`
  cursor: pointer;
  height: 32px;
  width: 32px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  background-color: transparent;

  transition: background-color 0.2s ease;

  :hover {
    background-color: rgba(65, 131, 215, 0.07);
  }

  ${(props: Props) => props.checkWidth && css`
    @media (min-width: ${props.checkWidth}px) {
      display: none;
    }
  `}
`;

export default style;
