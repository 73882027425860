import { Dispatch } from 'redux';

import { fetchFolders } from './fetchFolders';
import { fetchUnderControlCounter } from './fetchUnderControlCounter';
import { httpFetchFolders } from './fetchFolders.http';
import { httpFetchFolderCounter } from './fetchFolderCounter.http';

import { selectFolderThunk } from './selectFolder.thunk';

export class FetchFoldersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    try {
      const counter = await httpFetchFolderCounter('under_control');
      this.dispatchFetchUnderControlCounter(counter);
      const result = await httpFetchFolders();

      this.dispatchFetchSucceed(result);
      this.getPermissions();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private getPermissions() {
    const hasPermission = this.getState().currentUser.licenses.regulation !== 'visitor';

    if (!hasPermission) {
      selectFolderThunk(this.dispatch, { id: 'under_control' });
    }
  }

  private dispatchFetchSucceed(result) {
    const actionData = {
      items: result.result,
    };

    this.dispatch(fetchFolders.createAction(actionData));
  }

  private dispatchFetchUnderControlCounter(counter) {
    this.dispatch(fetchUnderControlCounter.createAction(counter));
  }

}

export async function fetchFoldersThunk(dispatch) {
  const thunk = new FetchFoldersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke();
}

export default FetchFoldersThunk;
