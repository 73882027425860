import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchFoldersThunk } from '@pages/regulation/sidebar/folders/redux/fetchFolders.thunk';
import { selectFolderThunk } from '@pages/regulation/sidebar/folders/redux/selectFolder.thunk';
import { fetchLayoutSettingsThunk } from '@pages/regulation/workspace/redux/fetchLayoutSettings.thunk';
import { resetToInitial } from '@pages/regulation/sidebar/folders/redux/resetToInitial';

import { removeFolder } from './removeFolder';

import { httpRemoveFolder } from './removeFolder.http';

export class RemoveFolderThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { id: string }) {
    try {
      await httpRemoveFolder(params);
    } catch (error) {
      throw Error(error.status);
    }

    this.marketingEvent(params);
    this.dispatchChangeFolderSelection(params.id);
    this.dispatchRemoveFolder(params);

  }

  private marketingEvent(params) {
    const folderName = this.getState().regulation.folders.folders.itemsMap.get(params.id).title;

    sendAmplitudeData('regulation:delete_folder', folderName);
    Userpilot.track('regulation:delete_folder');
  }

  private dispatchRemoveFolder(params) {
    this.dispatch(removeFolder.createAction({ id: params.id }));
  }

  private dispatchChangeFolderSelection(currentId) {
    const isActive = this.getState().regulation.folders.activeItemId === currentId;
    if (!isActive) {
      return;
    }

    const { itemsList } = this.getState().regulation.folders.folders;
    const isLastItem = itemsList.length === 1;

    if (isLastItem) {
      this.dispatchResetToInitial();
    }

    const findIndex = itemsList.findIndex((id) => id === currentId);
    const hasPreviousPackage = !!findIndex;
    if (hasPreviousPackage) {
      const nextId = itemsList[findIndex - 1];
      selectFolderThunk(this.dispatch, { id: nextId });

      return;
    }

    const hasNext = !!itemsList[findIndex + 1];
    if (hasNext) {
      const nextId = itemsList[findIndex + 1];
      selectFolderThunk(this.dispatch, { id: nextId });
    }
  }

  private dispatchResetToInitial() {
    this.dispatch(resetToInitial.createAction());
    fetchFoldersThunk(this.dispatch);
    selectFolderThunk(this.dispatch, { id: '' });
    fetchLayoutSettingsThunk(this.dispatch);
  }

}

export function removeFolderThunk(dispatch, params) {
  const thunk = new RemoveFolderThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default RemoveFolderThunk;
