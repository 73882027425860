import { connect } from 'react-redux';

import { resetToInitial } from './redux/resetToInitial';

import { fetchBookmarksThunk } from './redux/fetchBookmarks.thunk';

import { Bookmarks } from './bookmarks';

const mapStateToProps = (state) => ({
  loading: state.bookmarks.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
  },
  onInit() {
    fetchBookmarksThunk(dispatch, null);
  },
});

export const BookmarksConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Bookmarks);

export default BookmarksConnect;
