import { Id } from './item';
import { StateBuilder } from './state';

export interface ActionData {
  folderId: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const decreaseProjectsCountReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.folders);
  newState.decreaseCounter({ id: action.data.folderId });

  const folders = {
    ...newState.getState(),
  };

  return {
    ...state,
    folders,
  };
};

export default decreaseProjectsCountReduce;
