import styled from 'styled-components';

import s from '../block.style';

const style: any = {};

style.FilterItem = styled(s.FilterItem)`
  align-items: center;

  input {
    background: transparent;
    border: none;
    padding: 0 16px;
    outline: none;
    width: 100%;
  }
`;

export default style;
