import { caseType } from '../../redux/state';
import { AnalysisSettings } from '../../redux/settings';

export interface ActionData {
  result: {
    layout: any;
  };
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchSettingsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const fetchLayout = action.data.result;
  const settings = new AnalysisSettings();
  const layout = settings.decorate(fetchLayout);

  const newState = {
    shouldLoadSettings: false,
    layout,
  };

  return {
    ...state,
    analysis: {
      ...state.analysis,
      itemsMap: {
        ...state.analysis.itemsMap,
        [caseType.getId()]: {
          ...state.analysis.itemsMap[caseType.getId()],
          ...newState,
        },
      },
    },
  };
};

export default fetchSettingsReduce;
