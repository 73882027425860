export function plural(n: number, forms: string[]): string {
  if (n % 10 === 1 && n % 100 !== 11) {
    return forms[0];
  }

  if (
    n % 10 >= 2 &&
    n % 10 <= 4 &&
    (
      n % 100 < 10 ||
      n % 100 >= 20
    )
  ) {
    return forms[1];
  }

  return forms[2];
}

export default plural;
