import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { CalendarConnect } from './calendar.connect';

import {
  getCurrentYear,
  getCurrentMonth,
  getPreviousMonth,
  getNextMonth,
} from '../../utils';

import s from './carousel.style';

interface CarouselProps {
  currentYear: number;
  currentMonth: number;
}

export class Carousel extends React.PureComponent<CarouselProps> {

  WIDGET_WIDTH = 280;

  POSITION_X = -560;

  refRoot: React.RefObject<HTMLDivElement> = React.createRef();

  state = {
    positionX: this.POSITION_X,
    currentYear: getCurrentYear(),
    currentMonth: getCurrentMonth(),
    numberOfWidgets: 1,
  };

  componentDidMount() {
    window.addEventListener('resize', this.setNumberOfWidgets, false);
    this.setNumberOfWidgets();
    this.setCurrentDateParams();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setNumberOfWidgets, false);
  }

  setCurrentDateParams() {
    this.setState({
      currentYear: this.props.currentYear,
      currentMonth: this.props.currentMonth,
    });
  }

  setNumberOfWidgets = () => {
    const numbers = this.refRoot.current.offsetWidth / this.WIDGET_WIDTH;
    this.setState({
      numberOfWidgets: numbers + 1,
    });
  };

  setLeftAnimate = () => {
    this.setState((prevState: { positionX: number }) => ({
      positionX: prevState.positionX - 296,
    }));
  };

  setRightAnimate = () => {
    this.setState((prevState: { positionX: number }) => ({
      positionX: prevState.positionX + 296,
    }));
  };

  handleNextMonth = () => {
    this.setLeftAnimate();
    setTimeout(() => {
      this.resetPosition();
      const nextDate = getNextMonth(this.state.currentYear, this.state.currentMonth);
      this.changeCurrentMonth(nextDate.year, nextDate.month);
    }, 500);
  };

  handlePrevMonth = () => {
    this.setRightAnimate();
    setTimeout(() => {
      this.resetPosition();
      const nextDate = getPreviousMonth(this.state.currentYear, this.state.currentMonth);
      this.changeCurrentMonth(nextDate.year, nextDate.month);
    }, 500);
  };

  private changeCurrentMonth = (currentYear: number, currentMonth: number) => {
    this.setState({
      currentYear,
      currentMonth,
    });
  };

  private resetPosition() {
    this.setState({
      positionX: this.POSITION_X,
    });
  }

  private buildCalendars(year: number, month: number, length: number) {
    const items = [];
    for (let i = 0; i <= Math.floor(length) + 2; i += 1) {
      let currentMonth = month + i;
      let currentYear = year;
      if (currentMonth > 11) {
        currentMonth -= 12;
        currentYear += 1;
      }

      const item = {
        month: currentMonth,
        year: currentYear,
      };

      items.push(item);
    }

    return items;
  }

  renderLeftDirectionIcon() {
    const icon = iconsPath.get('directionLeft');

    return (
      <Icon points={icon} color="#4183d7" />
    );
  }

  renderRightDirectionIcon() {
    const icon = iconsPath.get('directionRight');

    return (
      <Icon points={icon} color="#4183d7" />
    );
  }

  renderCalendars() {
    const prevDate = getPreviousMonth(this.state.currentYear, this.state.currentMonth);
    const startDate = getPreviousMonth(prevDate.year, prevDate.month);
    const items = this.buildCalendars(startDate.year, startDate.month, this.state.numberOfWidgets);

    return items.map((item) => {
      const key = `${item.month}-${item.year}`;

      return (
        <CalendarConnect
          key={key}
          month={item.month}
          year={item.year}
          width={this.WIDGET_WIDTH}
        />
      );
    });
  }

  renderLayout() {
    if (!this.props.currentYear) {
      return null;
    }

    return (
      <>
        <s.Container
          positionX={this.state.positionX}
        >
          {this.renderCalendars()}
        </s.Container>
        <s.ArrowLeft onClick={this.handlePrevMonth}>{this.renderLeftDirectionIcon()}</s.ArrowLeft>
        <s.ArrowRight onClick={this.handleNextMonth}>{this.renderRightDirectionIcon()}</s.ArrowRight>
      </>
    );
  }

  render() {
    return (
      <s.Root ref={this.refRoot}>
        {this.renderLayout()}
      </s.Root>
    );
  }

}

export default Carousel;
