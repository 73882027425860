import { Map } from 'immutable';
import isEqual from 'lodash/isEqual';
import {
  sortDataDefault,
  SortDataProps,
} from './components/sortIcon/sortIcon';

export function prepareColumns(settings) {
  const columns = {
    itemsList: ['title'],
    itemsMap: settings.itemsMap,
  };

  columns.itemsMap = columns.itemsMap.set('title', { id: 'title', title: 'Наименование' });

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      columns.itemsList.push(column);
    }
  });

  return columns;
}

export function prepareFirstPage(currentItems) {
  const items = {
    itemsList: [],
    itemsMap: Map(),
  };

  currentItems.itemsList.forEach((item, index) => {
    if (index < 10 && item) {
      // @ts-ignore
      items.itemsList.push(item);
      items.itemsMap = items.itemsMap.set(item, currentItems.itemsMap.get(item));
    }
  });

  return items;
}

export function isDefault(sortData: SortDataProps) {
  return isEqual(sortData, sortDataDefault);
}

export default prepareColumns;
