import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import {
  Type,
  File as FileProps,
} from '../../redux/state';

import s from './file.style';

export class File extends React.PureComponent<FileProps> {

  handleClick = () => {
    const name = this.props.name;

    sendAmplitudeData('regulation:download_stage', name);
    Userpilot.track('regulation:download_stage');
  };

  renderIcon() {
    const iconPath = iconsPath.get(this.props.type);

    let color = '#6c7b89';
    if (this.props.type === Type.word) {
      color = '#4183d7';
    } else if (this.props.type === Type.pdf) {
      color = '#e74c3c';
    } else if (this.props.type === Type.zip) {
      color = '#673ab7';
    }

    return (
      <s.Icon>
        <Icon path={iconPath} color={color} />
      </s.Icon>
    );
  }

  render() {
    return (
      <s.Root href={this.props.link} target="__blank" onClick={this.handleClick}>
        {this.renderIcon()}
        <s.Name>{this.props.name}</s.Name>
      </s.Root>
    );
  }

}

export default File;
