import React from 'react';

import { TooltipRelationWithClickConnect } from '@pages/document/workspace/components/tooltipRelations.connect';
import { halfTooltipWidth } from '@pages/document/workspace/workspace';

import {
  Block,
  BlockProps,
} from './block';

import s from './documentBody.style';

export interface DocumentBodyProps {
  bodyBlocks: BlockProps[];
  onBodyMounted(): void;
}

export class DocumentBody extends React.PureComponent<DocumentBodyProps> {

  state = {
    tooltipIdRelation: '',
    tooltipAlign: 'center',
    tooltipOffsets: {},
    elementOffsetTop: null,
    tooltipData: {},
    tooltipShow: false,
  };

  componentDidMount() {
    this.props.onBodyMounted();
  }

  handleOnClick = (event) => {
    event.stopPropagation();

    const tagNameInLowerCase = event.target.tagName.toLowerCase();
    const elementAttribute = event.target.getAttribute('data-id-relation');
    const parentElementAttribute = event.target.parentNode.getAttribute('data-id-relation');
    const isRelationTag =
      // eslint-disable-next-line no-mixed-operators
      tagNameInLowerCase === 'a' && elementAttribute ||
      // eslint-disable-next-line no-mixed-operators
      tagNameInLowerCase === 'mark' && parentElementAttribute;

    const DomRect = event.target.getBoundingClientRect();
    const ParentDomScrollTop = document.getElementById('law-document-view-container').scrollTop;
    // eslint-disable-next-line max-len
    const isSmallWidthWindow = window.innerWidth - (halfTooltipWidth + DomRect.left + DomRect.width / 2) < halfTooltipWidth;

    if (!isRelationTag) {
      return;
    }

    event.preventDefault();
    const relationId = elementAttribute || parentElementAttribute;
    const tooltipOffsets = {
      left: event.target.offsetLeft + halfTooltipWidth / 2,
    };

    const elementOffsetTop = DomRect.top + ParentDomScrollTop - 70;

    if (!this.state.tooltipShow) {
      this.setState({
        tooltipIdRelation: relationId,
        tooltipAlign: isSmallWidthWindow ? 'left' : 'center',
        tooltipOffsets: { ...tooltipOffsets },
        elementOffsetTop,
        tooltipShow: true,
      });
    } else {
      this.setState({
        tooltipIdRelation: '',
        tooltipAlign: 'center',
        tooltipOffsets: {},
        elementOffsetTop: null,
        tooltipData: {},
        tooltipShow: false,
      });
    }
  };

  hideTooltip = () => {
    if (this.state.tooltipShow) {
      this.setState({
        tooltipIdRelation: '',
        tooltipAlign: 'center',
        tooltipOffsets: {},
        tooltipData: {},
        tooltipShow: false,
      });
    }
  };

  renderTooltipRelationContainer() {
    if (!this.state.tooltipShow) {
      return null;
    }

    return (
      <TooltipRelationWithClickConnect
        tooltipIdRelation={this.state.tooltipIdRelation}
        tooltipAlign={this.state.tooltipAlign}
        tooltipOffsets={this.state.tooltipOffsets}
        elementOffsetTop={this.state.elementOffsetTop}
        tooltipData={this.state.tooltipData}
        onClickOutside={this.hideTooltip}
        {...this.props}
      />
    );
  }

  render() {
    return (
      <s.Root onClick={this.handleOnClick}>
        {this.renderTooltipRelationContainer()}
        {this.props.bodyBlocks.map((block) => <Block key={block.id} {...block} />)}
      </s.Root>
    );
  }

}

export default DocumentBody;
