import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font: 13px/1.23 'Roboto-Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #22313f;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  input,
  button,
  textarea {
    font-family: inherit;
    font-size: 100%;
    color: inherit;
  }

  h1, h2, h3,
  h4, h4, h5 {
    font-weight: inherit;
  }
`;

export default GlobalStyle;

export {};
