import { connect } from 'react-redux';

import { buildItems } from '../../packages/sidebar/items.connect';

import { Items } from './items';

const mapStateToProps = (state, ownProps: { query: string }) => {
  const { packages } = state.authorPackages;

  const items = buildItems({ packages, activeItemId: packages.activeItemId, query: ownProps.query });

  return {
    items,
  };
};

export const ItemsConnect = connect(
  mapStateToProps,
)(Items);

export default ItemsConnect;
