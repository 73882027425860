import {
  LawBlock, LawBlockData,
} from '../../form/filterBlocks/law/redux/law';

export abstract class LawBlockHistory extends LawBlock {

  static transform(data: LawBlockData): string[] {
    const arrOfTitles = [data.document.title, data.article.title, data.clause.title];

    const result = arrOfTitles.reduce((accumulator: string[], item: string, currentIndex) => {
      let currentItem = '';
      if (currentIndex) {
        currentItem = ' • ';
      }

      if (item) {
        accumulator.push(`${currentItem}${item}`);
      }

      return accumulator;
    }, []);

    return result;
  }

}

export default LawBlockHistory;
