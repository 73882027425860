import { ReducerBuilder } from '@redux/reducer.builder';

import { resetState } from './resetState';
import { fetchComments } from './fetchComments';
import { fetchCommentsStart } from './fetchCommentsStart';

export const reducerBuilder = new ReducerBuilder([
  resetState,
  fetchComments,
  fetchCommentsStart,
]);

export default reducerBuilder;
