import { StateBuilder } from './state';

export interface ActionData {
  id: number;
  text: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const createAuthorPackageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.packages);
  const data = {
    id: action.data.id,
    text: action.data.text,
  };

  newState.createAuthorPackage(data);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default createAuthorPackageReduce;
