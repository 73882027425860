import { connect } from 'react-redux';

import { SimilarTab } from './similarTab';

const mapStateToProps = (state) => {
  const {
    loading,
    pageLoading,
    currentPageNumber,
    pages,
  } = state.similar;

  return {
    loading,
    pageLoading,
    currentPageNumber,
    pages,
  };
};

export const SimilarTabConnect = connect(
  mapStateToProps,
)(SimilarTab);

export default SimilarTabConnect;
