import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div``;

style.Title = styled.div`
  min-width: 176px;
  max-width: 176px;
  font: 11px Roboto-Regular;
  font-weight: bold;
  color: #22313f;
`;

style.Description = styled.div`
  font: 13px Roboto-Regular;
  color: ${(props) => props.theme.colors.stone};
  font-weight: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

style.Container = styled.div`
  display: flex;
  padding-top: 20px;
  gap: 32px;
`;

style.CaseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

style.TableItem = styled.div`
`;

export default style;
