import {
  Id,
  StateBuilder,
} from './state';

export interface ActionData {
  projectId: Id;
}

interface Action {
  type: string;
  data: ActionData;
}

export const increaseFoldersCountReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.increaseFolderCount(action.data.projectId);

  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default increaseFoldersCountReduce;
