import { StateBuilder } from '../../../redux/packages/state';

export interface ActionData {
  id: number;
  title: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updatePackageNameReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const params = {
    id: action.data.id,
    title: action.data.title,
  };

  const newState = new StateBuilder(state.packages);
  newState.updatePackageName(params);
  const packages = newState.getState();

  return {
    ...state,
    packages,
  };
};

export default updatePackageNameReduce;
