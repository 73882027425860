import { Id } from './item';
import { StateBuilder } from './state';

export interface ActionData {
  id: Id;
  name: string;
  count: number;
  role: string;
}

interface Action {
  type: string;
  data: ActionData;
}

export const addFolderReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.folders);
  newState.addItem(action.data);
  const folders = {
    ...newState.getState(),
  };

  return {
    ...state,
    folders,
  };
};

export default addFolderReduce;
