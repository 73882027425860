import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

type Id = number;
type Title = string;

export interface CaseResultBlockData {
  id: Id;
  title: Title;
}

export class CaseResultBlock extends AbstractFilterBlock {

  static type = 'caseResult';

  static DTOType = 'case_result';

  static blockTitle = 'Исход рассмотрения';

  static category = category.dispute;

  protected defaultData: CaseResultBlockData = {
    // @ts-ignore
    id: null,
    // @ts-ignore
    title: null,
  };

  constructor() {
    super();

    this.setCategory(CaseResultBlock.category);
    this.setType({
      id: CaseResultBlock.type,
      DTOType: CaseResultBlock.DTOType,
      title: CaseResultBlock.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains, presets) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const title = presets.find((preset) => preset.id === data.id).title;

    const updateData = {
      ...data,
      title,
    };

    const block = {
      id,
      data: updateData,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    const dataId = this.getData().id;

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: CaseResultBlock.DTOType,
      data: {
        id: dataId,
      },
    };
  }

  // eslint-disable-next-line
  public validate() {}

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

}

export default CaseResultBlock;
