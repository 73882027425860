export interface AppealState {
  id: string;
  title: string;
}

export interface CaseResult {
  id: string;
  title: string;
}

export interface CaseType {
  id: string;
  title: string;
}

export interface CourtType {
  id: string;
  title: string;
  isChild: boolean;
}

export interface DocumentType {
  id: string;
  title: string;
}

export interface SideMode {
  id: string;
  title: string;
}

export const searchBootstrappedReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const presets = action.data;

  const appealState = presets.appealState.result.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  const caseResult = presets.caseResult.result.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  const caseType = presets.caseType.result.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  const courtType = presets.courtType.result.map(
    (d) => (
      { id: d.id, title: d.name, isChild: d.is_child }
    ),
  );

  const documentType = presets.documentType.result.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  const sideMode = presets.sideMode.result.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  return {
    ...state,
    presets: {
      ...state.presets,
      appealState,
      caseResult,
      caseType,
      courtType,
      documentType,
      sideMode,
    },
  };
};

export default searchBootstrappedReduce;
