import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.article`
  display: flex;
  flex-direction: column;
  width: 320px;

  border-left: solid 1px ${theme.colors.borderColor};
  background-color: #f9f9f9;

  z-index: 4;
`;

style.Header = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
`;

style.Content = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export default style;
