import { StateBuilder } from './state';

export interface ActionData {
  result: boolean;
}

export interface Action {
  type: string;
  data: ActionData;
}

export const fetchCommentsReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.comments);
  newState.setComments(action.data.result);
  newState.updateLoading(false);

  const comments = newState.getState();

  return {
    ...state,
    comments,
  };
};

export default fetchCommentsReduce;
