import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  height: number;
  shrink: boolean;
  skewPadding: boolean;
}

style.Root = styled.div`
  position: relative;
  background-color: ${theme.colors.white};
  width: 100%;
`;

style.TextArea = styled.textarea`
  display: block;
  width: 100%;
  font-size: 11px;
  line-height: 16px;
  color: ${theme.colors.stone};
  height: ${(props: Props) => props.height}px;
  padding: 0;
  border: 0;
  outline: 0;
  overflow-y: hidden;
  resize: none;
  background-color: ${theme.colors.white};

  :not(:focus) {
    cursor: text;
  }

  :hover,
  :focus,
  :active {
    cursor: text;
    background: transparent;
  }

  :focus {
    color: ${theme.colors.black};
  }
`;

style.TextMeasureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
`;

style.TextMeasure = styled.div`
  min-height: 16px;
  padding: 24px 16px 7px;
  line-height: 16px;
  white-space: pre-line;
  word-wrap: break-word;
  background-color: ${theme.colors.white};
`;

style.ShowMore = styled.div`
  cursor: pointer;
  color: ${theme.colors.blue};
  font-size: 11px;
  margin-top: 8px;
  line-height: normal;
`;

export default style;
