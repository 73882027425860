import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';
import { httpSaveMarkers } from '@pages/document/redux/saveMarkers.http';

import { saveMarkers } from './saveMarkers';

export class SaveMarkersThunk {

  getState;

  private notificationId = 'SAVE_MARKERS_IN_PACKAGE_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params) {
    const dispatchParams = {
      documentId: params.documentId,
      entities: params.data.entities,
    };

    this.dispatchSaveMarkers(dispatchParams);

    try {
      await httpSaveMarkers(params);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchSaveMarkers(params) {
    this.dispatch(saveMarkers.createAction(params));
  }

}

export function saveMarkersThunk(dispatch, params) {
  const thunk = new SaveMarkersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SaveMarkersThunk;
