import { createAuthorPackageReduce } from './createAuthorPackage.reduce';

import type { ActionData } from './createAuthorPackage.reduce';

const actionType = 'PACKAGES/CREATE_AUTHOR_PACKAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const createAuthorPackage = {
  actionType,
  createAction,
  reduce: createAuthorPackageReduce,
};

export default createAuthorPackage;
