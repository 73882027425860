import { StateBuilder } from './state';

import type { Loading } from './state';

export interface ActionData {
  loading: Loading;
}

export interface Action {
  type: string;
  data: ActionData,
}

export const setLoadingStatusReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.chat);

  newState.setLoadingStatus(action.data.loading);

  return {
    ...state,
    chat: newState.getState(),
  };
};

export default setLoadingStatusReduce;
