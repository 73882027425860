import {
  Id,
  StateBuilder,
} from '../../../redux/state';

import { SpectractorsDTO } from './fetchSpectractors.http';

export interface ActionData {
  id: Id;
  result: SpectractorsDTO;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchSpectractorsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.projects);
  newState.buildPreviewSpectractors(action.data.id, action.data.result);

  const projects = {
    ...newState.getState(),
  };

  return {
    ...state,
    projects,
  };
};

export default fetchSpectractorsReduce;
